<template>
  <Dialog
    :header="$t('header_select_member')"
    :visible.sync="show"
    :modal="true"
    :style="{ zIndex: 1060 }"
    :contentStyle="{
      width: dialogWidth + 'px',
      overflowY: 'inherit!important',
    }"
    @hide="onClose"
  >
    <div>
      <div class="row no-gutters">
        <div class="col-12 col-md-6 pr-1">
          <div class="row no-gutters">
            <label class="col-12 col-md-4 col-form-label"
              >{{ $t("label_full_name") }}&nbsp;<span class="required"
                >*</span
              ></label
            >
            <div class="col-12 col-md-8">
              <Dropdown
                class="new-style w-100"
                :options="propItems"
                v-model="entity.user_id"
                :showClear="false"
                :filter="true"
                optionValue="id"
                optionLabel="full_name"
                dataKey="id"
              >
                <template #option="slotProps">
                  <span
                    >{{ slotProps.option.full_name }} ({{
                      slotProps.option.username
                    }})</span
                  >
                </template>
              </Dropdown>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 pl-1">
          <div class="row no-gutters">
            <label class="col-12 col-md-4 col-form-label"
              >{{ $t("label_user_role") }}&nbsp;<span class="required"
                >*</span
              ></label
            >
            <div class="col-12 col-md-8">
              <Dropdown
                class="new-style w-100"
                :options="propRoles"
                v-model="entity.role_id"
                :showClear="false"
                :filter="true"
                optionValue="id"
                optionLabel="description"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <!-- <Button
        :label="$t('button_text_close')"
        icon="pi pi-times"
        class="p-button-danger p-button-sm"
        autofocus
        @click="onClose"
      /> -->
      <!-- <Button
        @click="onSelected"
        :label="$t('button_text_select')"
        icon="pi pi-check"
        class="p-button-success p-button-sm mr-0"
      /> -->
      <button
        @click="onSelected"
        :label="$t('button_text_select')"
        icon="pi pi-check"
        class="btn btn-geostore2 btn-select btn-w96-h36 mr-0"
      >
        <i class="fas fa-check"></i>&nbsp; {{ $t("button_text_select") }}
      </button>
    </template>
  </Dialog>
</template>
<script>
import Button from "@/components/commons/button/Button";
import Dialog from "@/components/commons/dialog/Dialog";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import Dropdown from "@/components/commons/dropdown/Dropdown";
export default {
  components: {
    Button,
    Dialog,
    DataTable,
    Column,
    Dropdown,
  },
  props: {
    propItems: {
      type: Array,
      default: () => [],
    },
    propRoles: {
      type: Array,
      default: () => [],
    },
    propShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      entity: {
        role_id: null,
        user_id: null,
      },
      show: false,
    };
  },
  created() {
    this.show = this.propShow;
    if (this.propItems && this.propItems.length === 0) {
      this.$emit("getAllUser");
    }
    if (this.propRoles && this.propRoles.length === 0) {
      this.$emit("getAllRoleGroup");
    }
  },
  watch: {
    propShow(val) {
      this.show = val;
    },
  },
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    dialogWidth() {
      if (this.documentWidth >= 992) return 720;
      else if (this.documentWidth >= 768 && this.documentWidth < 992)
        return 720;
      else return this.documentWidth - 32;
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    onSelected() {
      let msg = [];
      if (!this.entity.user_id) msg.push(this.$t("message_member_is_required"));
      if (!this.entity.role_id) msg.push(this.$t("message_role_is_required"));
      if (msg.length > 0) {
        this.$emit("message", msg);
        return;
      }
      this.$emit("addMember", this.entity);
    },
  },
};
</script>
<style lang="scss" scoped>
button {
  &.btn-select {
    font-size: 14px;
  }
}
</style>