import axios from 'axios';
import basicApi from "@/utils/apis/basic";
import app from './../../main'
import { userServiceApiURL,geoServiceApiURL } from "@/utils/commons/environment"
export default {
    async getList(params,searchDto,access_token) {
      let headers = basicApi.user.search.headers;
      headers["Authorization"] = "bearer " + access_token;
      headers['Accept-Language'] = app.$store.getters.getLang;
      if (!params) params = {}
      params.lang = app.$store.getters.getLang;
      const object = axios({
        method: basicApi.user.search.method,
        url: userServiceApiURL + basicApi.user.search.url,
        headers:headers,
        params: params,
        data:searchDto
      });
      let response = await object;
      return response;
    },
    async getAll(access_token) {
        let headers = basicApi.user.get_all.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
          method: basicApi.user.get_all.method,
          url: userServiceApiURL + basicApi.user.get_all.url,
          headers:headers,
          params:{
            page:0,
            size: 2147483647,
            lang : app.$store.getters.getLang
          }
        });
        let response = await object;
        return response;
    },
    async getById(id,access_token) {
        let headers = basicApi.customer.get_by_id.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
          method: basicApi.customer.get_by_id.method,
          url: geoServiceApiURL + basicApi.customer.get_by_id.url.replace("{id}",id),
          headers: headers,
          params: {
            lang : app.$store.getters.getLang            
          }
        });
        let response = await object;
        return response;
    },
    async create(entity,access_token){
        let headers = basicApi.customer.create.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
          method: basicApi.customer.create.method,
          url:  geoServiceApiURL + basicApi.customer.create.url,
          data: entity,
          headers: headers,
          params: {
            lang : app.$store.getters.getLang            
          }
        });
        let response = await object;
        return response;
    },
    async update(entity, access_token) {
      let headers = basicApi.customer.update.headers;
      headers["Authorization"] = "bearer " + access_token;
      headers['Accept-Language'] = app.$store.getters.getLang;
      const object = axios({
        method: basicApi.customer.update.method,
        url: geoServiceApiURL + basicApi.customer.update.url.replace("{id}",entity.id),
        data: entity,
        headers:headers,
        params: {
          lang : app.$store.getters.getLang            
        }
      });
      let response = await object;
      return response;
    },
  
    async delete(id, access_token) {
      let headers = basicApi.customer.delete.headers;
      headers["Authorization"] = "bearer " + access_token;
      headers['Accept-Language'] = app.$store.getters.getLang;
      const object = axios({
        method: basicApi.customer.delete.method,
        url: geoServiceApiURL + basicApi.customer.delete.url.replace("{id}",id),
        headers:headers,
        params: {
          lang : app.$store.getters.getLang            
        }
      });
      let response = await object;
      return response;
    },
}