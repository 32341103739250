import { Module } from "vuex";
const cryptoFunc = require("@/utils/functions/crypto")
const SET_TOKEN = "SET_TOKEN";
const CLEAR_TOKEN = "CLEAR_TOKEN";
const SET_USER = "SET_USER";
const UPDATE_INFO = "UPDATE_INFO";
const SET_ACCESS_PATHS = "SET_ACCESS_PATHS";
const tokenModule= {
  state: {
    token:{},
    loggedIn: false,
    user: {},
    accessPaths:[],
    sentinelData:{}
  },
  mutations: {
    [SET_TOKEN](state, payload) {
      state.loggedIn = true;
      state.token = payload;
    },
    [CLEAR_TOKEN](state) {
      state.loggedIn = false;
      state.token = {};
      state.user = {};
      state.accessPaths =[]
    },
    [SET_USER](state, payload) {
      state.user = payload;
    },
    [UPDATE_INFO](state, payload) {
      if(payload && Object.keys(payload).length > 0){
        for (const [key, value] of Object.entries(payload)) {
          state.user[key] = value
        }
      }
    },
    [SET_ACCESS_PATHS](state, payload) {
      state.accessPaths = payload;
    },
  },
  actions: {
    setToken({ commit }, payload) {
      commit(SET_TOKEN, payload);
    },
    clearToken({ commit }) {
      localStorage.removeItem("data");      
      localStorage.removeItem("lockRefresh");
      commit(CLEAR_TOKEN);
    },
    setUser({ commit }, payload) {
      commit(SET_USER,payload);
    },
    updateInfo({ commit }, payload) {
      commit(UPDATE_INFO,payload);
    },
    setAccessPaths({ commit }, payload) {
      commit(SET_ACCESS_PATHS,payload);
    },
  },
  getters: {
    getAccessToken(state){
      let accessToken = "";
      if(Object.keys(state.token).length > 0){
        accessToken = state.token.access_token ? state.token.access_token : ""
      }
      return accessToken;
    },
    getRefreshToken(state){
      let refreshToken = "";
      if(Object.keys(state.token).length > 0){
        refreshToken = state.token.refresh_token ? state.token.refresh_token : ""
      }
      return refreshToken;
    },    
    getExpiresIn: state => {
      let result = 0;
      if(state.token && state.token.expires_in){
        try {
          result = parseInt(state.token.expires_in)
        } catch (error) {}
      }
      return result;
    },
    loggedIn(state){
      return state.loggedIn;
    },
    getUserName(state){
      return state.user.username ? state.user.username :''
    },
    getUser(state){
      return state.user && Object.keys(state.user).length > 0 ? state.user : {}
    },
    getUserId(state){
      return state.user.id ? state.user.id :''
    },
    getAccessPaths(state){
      return state.accessPaths ? state.accessPaths :[]
    },
    getPermissions(state){
      let results = [];
      if(state.user && state.user.roles && state.user.roles.length > 0){
        for (let i = 0; i < state.user.roles.length; i++) {
          if(state.user.roles[i].permissions && state.user.roles[i].permissions.length > 0){
            for (let j = 0; j < state.user.roles[i].permissions.length; j++) {
              if(!results.includes(state.user.roles[i].permissions[j].name))
              results.push(state.user.roles[i].permissions[j].name);
            }
          }          
        }
      }
      return results
    }
  }
};
export default tokenModule;