<template>
  <main-layout
    :isToolbar="false"
    :isBreadcrumb="true"
    :breadcrumbitems="[
      { label: $t('menu_share_management') },
      { label: $t('menu_my_shared') },
    ]"
    :propButtonsState="{
      save: {
        visible: false,
      },
      add: {
        visible: false,
      },
      edit: {
        visible: false,
      },
      reload: buttons.reload,
      print: {
        visible: false,
      },
    }"
    :propTitle="$t('menu_my_shared')"
    @emitFunction="menuHeaderClick"
  >
    <div class="shared-by-me">
      <div class="shared-by-me__dialog">
        <DialogMetadata
          v-if="dialog.metadata.show"
          :prop-style="{ width: dialog.metadata.width }"
          :prop-show="dialog.metadata.show"
          :prop-title="dialog.metadata.title"
          :prop-item="dialog.metadata.value"
          @close="
            dialog.metadata.show = false;
            dialog.metadata.value = null;
          "
          @initSuccessPreviewImange="initSuccessPreviewImange"
        />

        <DialogShare
          v-if="dialog.share.show"
          :prop-style="{ width: dialog.share.width }"
          :prop-show="dialog.share.show"
          :prop-title="dialog.share.title"
          :prop-item="dialog.share.value.image"
          :propUsers="
            user.items.filter(
              (x) =>
                x.username != username &&
                !dialog.share.value.member.map((x) => x.id).includes(x.id)
            )
          "
          :propButtonShare="buttons.share"
          @close="
            dialog.share.show = false;
            dialog.share.value = null;
          "
          @onShare="onShare"
        />
        <DialogCancelShare
          v-if="dialog.cancelSharing.show"
          :prop-style="{ width: dialog.cancelSharing.width }"
          :prop-show="dialog.cancelSharing.show"
          :propItem="dialog.cancelSharing.value.image"
          :prop-member="dialog.cancelSharing.value.member"
          @close="
            dialog.cancelSharing.show = false;
            dialog.cancelSharing.value = null;
          "
          @onRemoveShare="onRemoveShare"
        />
        <DialogMessage
          v-if="message.length > 0"
          :propTitle="$t('title_message_info')"
          :prop-show="message.length > 0"
          :prop-message="message"
          @close="message = []"
        />
      </div>
      <div class="shared-by-me__search" id="elementSearch">
        <SharedFilter
          :propSearch="sharedByMe.search"
          @onSearch="onSearch"
          @message="message = $event"
          @accordionHeaderClick="accordionHeaderClick"
        />
      </div>
      <div class="shared-by-me__datatable">
        <SharedDatatable
          :prop-items="sharedByMe.items"
          :prop-table="sharedByMe.table"
          :prop-total="sharedByMe.total_records"
          :prop-loading="sharedByMe.loading"
          @showDialogMetadata="showDialogMetadata"
          @showDialogShare="showDialogShare"
          @showDialogCancelShare="showDialogCancelShare"
          @downloadImage="downloadImage"
          @nextPage="nextPage"
        />
      </div>
    </div>
  </main-layout>
</template>
<script>
import MainLayout from "@/components/pages/layouts/admin/Main";
import SharedFilter from "./sharebyme/Filter.vue";
import SharedDatatable from "./sharebyme/DataTable.vue";
import DialogMessage from "@/components/commons/dialog/MessageBox";
import DialogMetadata from "@/components/commons/metadata/Metadata";
import DialogShare from "./dialog/DialogShare.vue";
import DialogCancelShare from "./dialog/DialogCancelShare.vue";
import meControllerFunc from "@/utils/functions/mecontroller";
import imageSharingFunc from "@/utils/functions/imagesharing";
import userFunc from "@/utils/functions/user";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import imageFunc from "@/utils/functions/image";
import { change_alias, string_all_trim } from "@/utils/commons/common";
export default {
  components: {
    MainLayout,
    SharedDatatable,
    SharedFilter,
    DialogMetadata,
    DialogMessage,
    DialogShare,
    DialogCancelShare,
  },
  data() {
    return {
      message: [],
      user: {
        items: [],
      },
      sharedByMe: {
        items: [],
        search: {
          page: 0,
          size: 10,
          from: null,
          to: null,
          image_name: null,
        },
        table: {
          selected: null,
          height: 400,
        },
        loading: false,
        is_bottom: false,
        page_number: 0,
        page_size: 0,
        total_page: 0,
        total_records: 0,
      },
      dialog: {
        metadata: {
          show: false,
          title: this.$t("label_metadata"),
          value: null,
          width: "480px",
        },
        share: {
          show: false,
          title: this.$t("label_share"),
          value: null,
          width: "480px",
        },
        cancelSharing: {
          show: false,
          title: this.$t("button_text_cancel_share"),
          value: null,
          width: "480px",
        },
      },

      buttons: {
        reload: {
          disabled: false,
          processing: false,
          visible: true,
        },
        share: {
          disabled: false,
          processing: false,
        },
      },
    };
  },
  created() {
    var vm = this;
    if (vm.documentWidth < 576) {
      this.dialog.metadata.width = this.documentWidth + "px";
      this.dialog.share.width = this.documentWidth + "px";
      this.dialog.cancelSharing.width = this.documentWidth + "px";
    } else if (vm.documentWidth >= 576 && vm.documentWidth < 768) {
      this.dialog.metadata.width = "540px";
      this.dialog.share.width = "540px";
      this.dialog.cancelSharing.width = "540px";
    } else if (vm.documentWidth >= 768 && vm.documentWidth < 992) {
      this.dialog.metadata.width = "720px";
      this.dialog.share.width = "720px";
      this.dialog.cancelSharing.width = "720px";
    } else {
      this.dialog.metadata.width = "960px";
      this.dialog.share.width = "720px";
      this.dialog.cancelSharing.width = "720px";
    }
    this.sharedByMe.search.size = Math.ceil(this.tableHeight / 45) + 5;
    this.getAllUser();
    this.getSharedByMe();
  },
  mounted() {
    setTimeout(() => {
      this.sharedByMe.table.height = this.calcTableHeight();
    }, 100);
  },
  methods: {
    accordionHeaderClick() {
      setTimeout(() => {
        this.sharedByMe.table.height = this.calcTableHeight();
      }, 100);
    },
    calcTableHeight() {
      let element = document.getElementById("elementSearch");
      return (
        this.documentHeight -
        3 * 0.5 * 16 -
        56 -
        (element ? element.offsetHeight : 0) -
        24 -
        2 * 0.5 * 16
      );
    },
    initSuccessPreviewImange(data) {
      let index = this.sharedByMe.items.findIndex((x) => x.id === data.id);
      if (index >= 0) {
        this.sharedByMe.items[index].preview_src = data.val;
      }
    },
    showDialogMetadata(item) {
      this.dialog.metadata.value = item;
      this.dialog.metadata.show = true;
    },

    showDialogShare(item) {
      this.dialog.share.value = item;
      this.dialog.share.show = true;
    },
    showDialogCancelShare(item) {
      this.dialog.cancelSharing.value = item;
      this.dialog.cancelSharing.show = true;
    },

    nextPage() {
      if (!this.sharedByMe.is_bottom) {
        this.sharedByMe.search.page = this.sharedByMe.search.page + 1;
        this.getSharedByMe();
      }
    },
    onSearch(data) {
      this.sharedByMe.search.from = data.from;
      this.sharedByMe.search.to = data.to;
      this.sharedByMe.search.image_name = data.image_name;
      this.sharedByMe.search.page = 0;
      this.sharedByMe.is_bottom = false;
      this.sharedByMe.items = [];
      this.getSharedByMe();
    },

    menuHeaderClick(val) {
      switch (val.code) {
        case "RELOAD":
          let size = this.sharedByMe.search.size;
          this.sharedByMe.search = {};
          this.sharedByMe.search.page = 0;
          this.sharedByMe.search.size = size;
          this.sharedByMe.search.from = null;
          this.sharedByMe.search.to = null;
          this.sharedByMe.search.image_name = null;
          this.sharedByMe.items = [];
          this.sharedByMe.page_number = 0;
          this.sharedByMe.page_size = 0;
          this.sharedByMe.total_page = 0;
          this.sharedByMe.total_records = 0;
          this.sharedByMe.is_bottom = false;
          this.getSharedByMe();
          break;
      }
    },

    async onRemoveShare(data) {
      if (data && data.length > 0) {
        if (this.dialog.cancelSharing.value.member.length === data.length) {
          let check = await this.deleteSharing(
            this.dialog.cancelSharing.value.id
          );
          if (check) {
            for (let i = 0; i < data.length; i++) {
              this.$toast.success({
                title: this.$t("title_message_info"),
                message: this.$t("message_remove_user_sharing_success", null, {
                  name: this.dialog.cancelSharing.value.image.name,
                  full_name: data[i].full_name,
                }),
                position: "top right",
              });
            }

            this.dialog.cancelSharing.show = false;
            this.dialog.cancelSharing.value = null;

            this.sharing.search.page = 0;
            this.sharing.items = [];
            this.sharing.page_number = 0;
            this.sharing.page_size = 0;
            this.sharing.total_page = 0;
            this.sharing.total_records = 0;
            this.sharing.is_bottom = false;
            this.getSharing();
          } else {
            this.message.push(
              this.$t("message_remove_user_sharing_error", null, {
                name: this.dialog.cancelSharing.value.image.name,
                full_name: data.map((x) => x.full_name).join(","),
              })
            );
          }
        } else {
          let arrayError = [],
            arraySuccess = [];
          for (let i = 0; i < data.length; i++) {
            let check = await this.removeUserFromSharing({
              share_id: this.dialog.cancelSharing.value.id,
              user_id: data[i].id,
            });
            if (!check) {
              arrayError.push({
                id: data[i].id,
                full_name: data[i].full_name,
                image_name: this.dialog.cancelSharing.value.image.name,
              });
            } else {
              arraySuccess.push({
                id: data[i].id,
                full_name: data[i].full_name,
                image_name: this.dialog.cancelSharing.value.image.name,
              });
            }
          }
          if (arrayError.length > 0) {
            for (let i = 0; i < arraySuccess.length; i++) {
              this.$toast.success({
                title: this.$t("title_message_info"),
                message: this.$t("message_remove_user_sharing_success", null, {
                  name: arraySuccess[i].image_name,
                  full_name: arraySuccess[i].full_name,
                }),
                position: "top right",
              });
            }
            for (let i = 0; i < arrayError.length; i++) {
              this.message.push(
                this.$t("message_remove_user_sharing_error", null, {
                  name: arrayError[i].image_name,
                  full_name: arrayError[i].full_name,
                })
              );
            }
            if (arraySuccess.length > 0) {
              this.dialog.cancelSharing.show = false;
              this.dialog.cancelSharing.value = null;

              this.sharing.search.page = 0;
              this.sharing.items = [];
              this.sharing.page_number = 0;
              this.sharing.page_size = 0;
              this.sharing.total_page = 0;
              this.sharing.total_records = 0;
              this.sharing.is_bottom = false;
              this.getSharing();
            }
          } else {
            this.$toast.success({
              title: this.$t("title_message_info"),
              message: this.$t("message_remove_user_sharing_success", null, {
                name: this.dialog.cancelSharing.value.image.name,
                full_name: data.map((x) => x.full_name).join(","),
              }),
              position: "top right",
            });

            this.dialog.cancelSharing.show = false;
            this.dialog.cancelSharing.value = null;

            this.sharing.search.page = 0;
            this.sharing.items = [];
            this.sharing.page_number = 0;
            this.sharing.page_size = 0;
            this.sharing.total_page = 0;
            this.sharing.total_records = 0;
            this.sharing.is_bottom = false;
            this.getSharing();
          }
        }
      }
    },
    async deleteSharing(id) {
      return new Promise(async (resolve, reject) => {
        try {
          let response = await imageSharingFunc.delete(
            id,
            this.$store.getters.getAccessToken
          );
          if (response && response.status === 200) {
            resolve(true);
          } else resolve(false);
        } catch (error) {
          if (error.response && error.response.status === 401)
            this.refreshToken(this.deleteSharing, id);
          else resolve(false);
        }
      });
    },
    async removeUserFromSharing(data) {
      return new Promise(async (resolve, reject) => {
        try {
          let response = await imageSharingFunc.removeUser(
            data.share_id,
            data.user_id,
            this.$store.getters.getAccessToken
          );
          if (response && response.status === 200) {
            resolve(true);
          } else resolve(false);
        } catch (error) {
          if (error.response && error.response.status === 401)
            this.refreshToken(this.removeUserFromSharing, data);
          else resolve(false);
        }
      });
    },

    async downloadImage(item) {
      if (!item || Object.keys(item).length === 0) return;
      try {
        this.$store.dispatch("setImagesDownload", [
          {
            is_add: true,
            image: item,
          },
        ]);
        let response = await imageSharingFunc.download2(
          item.password ? item.password : null,
          item.token,
          item.id,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          //if (response.data.type.toLowerCase() === "file") {
          if (response.data.url) {
            window.open(response.data.url, "_blank");
          } else if (response.data.type.toLowerCase() === "folder") {
            this.downloadFolderAsZip({
              url: response.data.url,
              // .replace("http://", "http:/")
              // .replace("https://", "https:/")
              // .replace("//", "/")
              // .replace("http:/", "http://")
              // .replace("https:/", "https://"),
              method: "post",
              data: response.data.data,
              image: item,
            });
          }
        } else {
          this.message.push(
            this.$t("message_download_file_error", null, { name: item.name })
          );
        }
        this.$store.dispatch("setImagesDownload", [
          {
            is_add: false,
            image: item,
          },
        ]);
      } catch (error) {
        this.$store.dispatch("setImagesDownload", [
          {
            is_add: false,
            image: item,
          },
        ]);
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.downloadImage, item);
        } else {
          let msg =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push(
            msg
              ? msg
              : this.$t("message_download_file_error", null, {
                  name: item.name,
                })
          );
        }
      }
    },
    async downloadFolderAsZip(data) {
      this.$store.dispatch("setImagesDownload", [
        {
          is_add: true,
          image: data.image,
        },
      ]);
      try {
        let response = await imageFunc.downloadFolderAsZip(data.url, data.data);
        if (response.status === 200) {
          let contentType =
            response.headers && response.headers["content-type"]
              ? response.headers["content-type"]
              : "application/zip";
          let url,
            blob = new Blob([response.data], { type: contentType });
          let extension = "";
          try {
            extension = contentType.substring(
              contentType.indexOf("/") + 1,
              contentType.length
            );
          } catch (error2) {}
          if (blob.size === 0) {
            this.message.push(this.$t("message_file_dose_not_exist"));
          } else {
            try {
              if (!window.navigator.msSaveOrOpenBlob) {
                // BLOB NAVIGATOR
                url = window.URL.createObjectURL(blob);
              } else {
                // BLOB FOR EXPLORER 11
                url = window.navigator.msSaveOrOpenBlob(
                  blob,
                  change_alias(data.image.name) + ".zip" //(extension ? "."+extension :".zip")
                );
              }
              const linkdown = document.createElement("a");
              linkdown.href = url;
              linkdown.setAttribute(
                "download",
                change_alias(data.image.name) + ".zip"
              ), //(extension ? "."+extension :".zip"));
                document.body.appendChild(linkdown);
              linkdown.click();
              document.body.removeChild(linkdown);
              window.URL.revokeObjectURL(url);
            } catch (error3) {}
          }
        } else {
          this.message.push(
            this.$t("message_download_file_error", null, {
              name: data.image.name,
            })
          );
        }
        this.$store.dispatch("setImagesDownload", [
          {
            is_add: false,
            image: data.image,
          },
        ]);
      } catch (error) {
        this.$store.dispatch("setImagesDownload", [
          {
            is_add: false,
            image: data.image,
          },
        ]);
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.downloadFolderAsZip, data);
        } else {
          //let responseObj = await error.response.data.text();
          if (
            error.request &&
            error.request.responseType === "blob" &&
            error.response.data instanceof Blob &&
            error.response.data.type &&
            error.response.data.type.toLowerCase().indexOf("json") != -1
          ) {
            let reader = new FileReader();
            reader.onload = () => {
              let responseObj = JSON.parse(reader.result);
              let msg =
                responseObj && responseObj.message ? responseObj.message : null;
              this.message.push(
                msg
                  ? msg
                  : this.$t("message_download_file_error", null, {
                      name: data.image.name ? data.image.name : null,
                    })
              );
            };
            reader.readAsText(error.response.data);
          } else {
            let msg =
              error.response &&
              error.response.data &&
              error.response.data.message
                ? error.response.data.message
                : null;
            this.message.push(
              msg
                ? msg
                : this.$t("message_download_file_error", null, {
                    name: data.image.name,
                  })
            );
          }
        }
      }
    },
    async downloadMyZipFile(val) {
      this.$store.dispatch("setImagesDownload", [
        {
          is_add: true,
          image: val,
        },
      ]);
      try {
        let response = await imageSharingFunc.download2(
          val.password ? val.password : null,
          val.token,
          val.id,
          this.$store.getters.getAccessToken
        );
        if (response.status === 200) {
          let contentType =
            response.headers && response.headers["content-type"]
              ? response.headers["content-type"]
              : "application/zip";
          let url,
            blob = new Blob([response.data], { type: contentType });
          let extension = "";
          try {
            extension = contentType.substring(
              contentType.indexOf("/") + 1,
              contentType.length
            );
          } catch (error2) {}
          if (blob.size === 0) {
            this.message.push(this.$t("message_file_dose_not_exist"));
          } else {
            if (!window.navigator.msSaveOrOpenBlob) {
              // BLOB NAVIGATOR
              url = window.URL.createObjectURL(blob);
            } else {
              // BLOB FOR EXPLORER 11
              url = window.navigator.msSaveOrOpenBlob(
                blob,
                change_alias(val.name) + ".zip" //(extension ? "."+extension :".zip")
              );
            }
            const linkdown = document.createElement("a");
            linkdown.href = url;
            linkdown.setAttribute("download", change_alias(val.name) + ".zip"), //(extension ? "."+extension :".zip"));
              document.body.appendChild(linkdown);
            linkdown.click();
            document.body.removeChild(linkdown);
            window.URL.revokeObjectURL(url);
          }
        } else {
          this.message.push(
            this.$t("message_download_file_error", null, { name: val.name })
          );
        }
        this.$store.dispatch("setImagesDownload", [
          {
            is_add: false,
            image: val,
          },
        ]);
      } catch (error) {
        this.$store.dispatch("setImagesDownload", [
          {
            is_add: false,
            image: val,
          },
        ]);
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.downloadMyZipFile, val);
        } else {
          //let responseObj = await error.response.data.text();
          if (
            error.request.responseType === "blob" &&
            error.response.data instanceof Blob &&
            error.response.data.type &&
            error.response.data.type.toLowerCase().indexOf("json") != -1
          ) {
            let reader = new FileReader();
            reader.onload = () => {
              let responseObj = JSON.parse(reader.result);
              let msg =
                responseObj && responseObj.message ? responseObj.message : null;
              this.message.push(
                msg
                  ? msg
                  : this.$t("message_download_file_error", null, {
                      name: val.name ? val.name : null,
                    })
              );
            };
            reader.readAsText(error.response.data);
          } else {
            let msg =
              error.response &&
              error.response.data &&
              error.response.data.message
                ? error.response.data.message
                : null;
            this.message.push(
              msg
                ? msg
                : this.$t("message_download_file_error", null, {
                    name: val.name,
                  })
            );
          }
        }
      }
    },

    async onShare(entity) {
      let message = [];
      entity.description = entity.description
        ? entity.description.trim().replace(/\s\s+/g, " ")
        : "";
      if (!entity.member_ids || entity.member_ids.length === 0) {
        message.push(this.$t("message_receiver_required"));
      }
      if (!entity.description)
        message.push(this.$t("message_content_required"));

      if (entity.isExpired) {
        if (entity.expiredTime) {
          entity.expiredTime = new Date(entity.expiredTime).setHours(
            23,
            59,
            59,
            0
          );

          if (entity.expiredTime - new Date().getTime() < 0)
            message.push(
              this.$t("message_expired_time_must_greater_than_or_equal_today")
            );
        } else {
          message.push(this.$t("message_expired_time_required"));
        }
      }
      if (entity.isPass) {
        if (!entity.password) {
          message.push(this.$t("message_password_required"));
        }
      }
      if (entity.isNum) {
        if (!entity.download_number) {
          message.push(
            this.$t("message_numberdownload_must_greater_than_zero")
          );
        }
      }
      if (message.length > 0) {
        this.message = message;
        return;
      } else {
        await this.handleSharing(entity);
      }
    },
    async handleSharing(entity) {
      if (
        this.dialog.share.value &&
        Object.keys(this.dialog.share.value).length > 0
      ) {
        let dto = {
          description: entity.description ? entity.description.trim() : "",
          download_number: entity.download_number,
          expired_time: entity.isExpired
            ? new Date(entity.expiredTime).setHours(23, 59, 59, 0)
            : null,
          image_id: this.dialog.share.value.image.id,
          is_public: false,
          member_ids: entity.member_ids,
          password: entity.isPass && entity.password ? entity.password : null,
          order_id: null,
        };

        this.buttons.share.disabled = true;
        this.buttons.share.processing = true;
        try {
          let response = await meControllerFunc.createShare(
            dto,
            this.$store.getters.getAccessToken
          );
          if (response.status === 200 || response.status === 201) {
            this.sharedByMe.search.page = 0;
            this.sharedByMe.items = [];
            this.sharedByMe.page_number = 0;
            this.sharedByMe.page_size = 0;
            this.sharedByMe.total_page = 0;
            this.sharedByMe.total_records = 0;
            this.sharedByMe.is_bottom = false;
            this.getSharedByMe();
            this.$toast.success({
              title: this.$t("title_message_info"),
              message: this.$t("message_create_image_sharing_susscess", null, {
                name: this.dialog.share.value.image.name
                  ? this.dialog.share.value.image.name
                  : "",
              }),
              position: "top right",
            });

            setTimeout(() => {
              this.dialog.share.show = false;
              this.dialog.share.value = null;
            }, 100);
          } else {
            this.message.push(
              this.$t("message_create_image_sharing_error", null, {
                name: this.dialog.share.value.image.name
                  ? this.dialog.share.value.image.name
                  : "",
              })
            );
          }
          this.buttons.share.disabled = false;
          this.buttons.share.processing = false;
        } catch (error) {
          this.buttons.share.disabled = false;
          this.buttons.share.processing = false;
          if (error.response && error.response.status === 401)
            await this.refreshToken(this.handleSharing, entity);
          else {
            let msg =
              error.response.data && error.response.data.message
                ? error.response.data.message
                : null;
            this.message.push(
              msg
                ? msg
                : this.$t("message_create_image_sharing_error", null, {
                    name: this.dialog.share.value.image.name
                      ? this.dialog.share.value.image.name
                      : "",
                  })
            );
          }
        }
      }
    },
    /*---------------- Get Data -------------------*/
    async getSharedByMe() {
      if (this.sharedByMe.is_bottom) return;
      let params = {
        page: this.sharedByMe.search.page,
        size: this.sharedByMe.search.size,
      };
      this.sharedByMe.loading = true;
      try {
        let response = await meControllerFunc.getSharedByMe(
          params,
          this.$store.getters.getAccessToken,
          {
            create_time_from: this.sharedByMe.search.from
              ? new Date(this.sharedByMe.search.from)
              : null,
            create_time_to: this.sharedByMe.search.to
              ? new Date(this.sharedByMe.search.to)
              : null,
            image_name: this.sharedByMe.search.image_name
              ? this.sharedByMe.search.image_name
              : null,
          }
        );
        if (response && response.status === 200) {
          this.sharedByMe.items = [
            ...this.sharedByMe.items,
            ...(response.data.content_page ? response.data.content_page : []),
          ];
          this.sharedByMe.page_number = response.data.page_number;
          this.sharedByMe.page_size = response.data.page_size;
          this.sharedByMe.total_page = response.data.total_page;
          this.sharedByMe.total_records = response.data.total_records;
          if (
            (this.sharedByMe.items.length === this.sharedByMe.total_records &&
              response.data.total_page > 1) ||
            (response.data.total_page === 1 &&
              this.sharedByMe.items.length <= response.data.page_size)
          )
            this.sharedByMe.is_bottom = true;
          else this.sharedByMe.is_bottom = false;
        }
        this.sharedByMe.items.sort(function (a, b) {
          if (new Date(a.created_at) < new Date(b.created_at)) return 1;
          else if (new Date(a.created_at) > new Date(b.created_at)) return -1;
          return 0;
        });

        this.sharedByMe.loading = false;
      } catch (error) {
        this.sharedByMe.loading = false;
        if (error.response && error.response.status === 401)
          this.refreshToken(this.getSharedByMe);
      }
    },
    async getAllUser() {
      try {
        let response = await userFunc.search(
          {
            page: 0,
            size: 2147483647,
            order: "full_name",
            sort: "asc",
          },
          {
            is_active: true,
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.user.items = response.data.content_page
            ? response.data.content_page.filter((x) => x.is_active)
            : [];
          this.user.items.sort(function (a, b) {
            if (a.full_name < b.full_name) return -1;
            else if (a.full_name > b.full_name) return 1;
            return 0;
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.getAllUser);
        }
      }
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          this.$router.push({
            name: "Login",
            query: {
              next: btoa(this.$route.path),
            },
          });
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    username() {
      return this.$store.getters.getUserName;
    },
    tableHeight() {
      return this.documentHeight - 56 - 48 - 105 - 38 - 1 * 4 - 4;
    },
  },
};
</script>
<style lang="scss" scoped>
.shared-by-me {
  padding: 0.5rem;
  .shared-by-me__search {
    padding: 0;
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .shared-by-me__datatable {
    padding: 0;
    margin-top: 0.5rem;
    .accordion {
      border-radius: 0.5rem;
    }
  }
}
</style>