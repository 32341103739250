<template>
    <div class="" slot="slot-content">
        <spinner :propLoading="loading.show" :propShape="loading.shape" v-if="loading.show"  :prop-text="loading.text"></spinner>
        <order-page></order-page>
    </div>
</template>
<script>

import LayoutPage from "./Layout"
import OrderPage from "@/components/pages/order/Index"
import Spinner from "@/components/commons/spinners/Index";
export default {
    name: 'order',
    components: {
        LayoutPage,OrderPage,Spinner
    },
    created(){},
    computed:{
        loading(){
            return this.$store.getters.getSpinnerApp
        }
    }
}
</script>