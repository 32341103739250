import axios from 'axios';
import basicApi from "@/utils/apis/basic";
import app from './../../main'
import { geoServiceApiURL } from "@/utils/commons/environment"
export default {
    async getListOrdered(params, access_token) {
        let headers = basicApi.order.get_list_ordered.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        if (!params) params = {};
        params.lang = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.order.get_list_ordered.method,
            url: geoServiceApiURL + basicApi.order.get_list_ordered.url,
            params: params,
            headers: headers,
        });
        let response = await object;
        return response;
    },
    async approve(id, access_token) {
        let headers = basicApi.order.approve.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.order.approve.method,
            url: geoServiceApiURL + basicApi.order.approve.url.replace("{id}", id),
            headers: headers,
            params: {
                lang: app.$store.getters.getLang
            }
        });
        let response = await object;
        return response;
    },
    async getAllPage(params, access_token) {
        let headers = basicApi.order.get_all.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        if (!params) params = {};
        params.lang = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.order.get_all.method,
            url: geoServiceApiURL + basicApi.order.get_all.url,
            params: params,
            headers: headers,
        });
        let response = await object;
        return response;
    },

    async getByUserPage(params, access_token) {
        let headers = basicApi.order.get_by_user.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        if (!params) params = {};
        params.lang = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.order.get_by_user.method,
            url: geoServiceApiURL + basicApi.order.get_by_user.url,
            params: params,
            headers: headers,
        });
        let response = await object;
        return response;
    },
    async getById(id, access_token) {
        let headers = basicApi.order.get_by_id.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.order.get_by_id.method,
            url: geoServiceApiURL +
                basicApi.order.get_by_id.url.replace("{id}", id),
            headers: headers,
            params: {
                lang: app.$store.getters.getLang
            }
        });
        let response = await object;
        return response;
    },


    async addStatus(params, access_token) {
        let headers = basicApi.order.add_status.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        if (!params) params = {};
        params.lang = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.order.add_status.method,
            url: geoServiceApiURL + basicApi.order.add_status.url,
            params: params,
            headers: headers,
        });
        let response = await object;
        return response;
    },

    async updateImagesOrder(data, access_token) {
        let headers = basicApi.order.update_images_order.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        let params = {};
        params.lang = app.$store.getters.getLang;
        if (data && data.image_ids && data.image_ids) {
            data.image_ids = [...new Set(data.image_ids)]
        }
        const object = axios({
            method: basicApi.order.update_images_order.method,
            url: geoServiceApiURL + basicApi.order.update_images_order.url,
            data: data,
            headers: headers,
        });
        let response = await object;
        return response;
    },

}