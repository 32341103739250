<template>
  <CommonAccordion :propTitle="$t('title_role_list')">
    <div slot="content" class="accordion">
      <div class="datatable">
        <DataTable
          :value="propData.items"
          ref="dt"
          :paginator="propData.items && propData.items.length > 10"
          :loading="!propData.items"
          :rows="10"
          :resizableColumns="true"
          columnResizeMode="expand"
          selectionMode="single"
          dataKey="id"
          :selection.sync="propTable.selected"
          @row-click="rowClick"
          :first="propFirst"
          @page="pageChange"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll"
          :currentPageReportTemplate="$t('label_showing') + ' {first} '+$t('label_to').toLowerCase()+' {last} ' +$t('label_of').toLowerCase()+ ' {totalRecords}'"
        >
          <template #header v-if="propData.items && propData.items.length <= 10">
            <div class="text-left">
              <div>
                <div v-if="propData.items.length" class="font-weight-normal">
                  {{
                    $t("label_show_from_to", null, {
                      from: 1,
                      to: propData.items.length,
                      total: propData.total_records,
                    })
                  }}
                </div>
              </div>
            </div>
          </template>
          <template #empty v-if="propData.items && propData.items.length === 0">
            <span class="text-center">{{ $t("label_no_data") }}</span>
          </template>
          <Column
            header="No"
            headerStyle="width: 3rem; text-align: center!important"
            bodyStyle="text-align: center;"
          >
            <template #body="slotProps">
              {{ propTable.page * propTable.size + slotProps.index + 1 }}
            </template></Column
          >
          <Column field="name" :header="$t('label_role_code')"></Column>
          <Column :header="$t('label_name_vietnamese')">
            <template #body="slotProps">
              {{
                slotProps.data.translation && slotProps.data.translation.vi
                  ? slotProps.data.translation.vi.description
                  : ""
              }}
            </template>
          </Column>
          <Column :header="$t('label_name_english')">
            <template #body="slotProps">
              {{
                slotProps.data.translation && slotProps.data.translation.en
                  ? slotProps.data.translation.en.description
                  : ""
              }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import Pagination from "@/components/commons/pagination/Index";
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import Button from "@/components/commons/button/Button";
export default {
  components: { Pagination, CommonAccordion, DataTable, Column, Button },
  props: {
    propData: {
      type: Object,
      default: () => {},
    },
    propFirst: {
      type: Number,
      default: 0,
    },
    propTable: {
      type: Object,
      default: () => {
        return {
          selected: null,
        };
      },
    },
    propStatus: {
      type: Object,
      default: () => {
        return {
          isAdd: false,
          isEdit: false,
        };
      },
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  created() {},
  methods: {
    rowClick(val) {
      this.$emit("rowClick", val);
    },
    pageChange(e) {
      this.propTable.page = e.page;
      this.propTable.size = e.rows;
    },
  },
};
</script>

<style lang="scss">
.authority-content__datatable {
  @import "@/assets/scss/_datatable.scss";
}
</style>