<template>
  <main-layout
    :isToolbar="false"
    :isBreadcrumb="true"
    :breadcrumbitems="[
      { label: $t('menu_order_management') },
      { label: $t('menu_order_detail') },
    ]"
    :propButtonsState="{
      save: {
        visible: false,
      },
      add: {
        visible: false,
      },
      edit: {
        visible: false,
      },
      reload: {
        visible: false,
      },
      print: {
        visible: false,
      },
    }"
    :propTitle="$t('menu_order_detail')"
  >
    <div class="order-detail-content">
      <div v-if="!loading">
        <div
          class="empty-data"
          v-if="!id || !data || Object.keys(data).length === 0"
        >
          <div id="notfound">
            <div class="notfound">
              <div class="notfound-404">
                <h3>No data found</h3>
                <h1><span>4</span><span>0</span><span>4</span></h1>
              </div>
              <h6>{{ $t("message_cound_not_looking_for") }}</h6>
            </div>
          </div>
        </div>
        <div v-else>
          <Detail
            :prop-item="data"
            :propGranularities="granularity.items"
            :propLookDirection="lookDirection.items"
            :propOrbitDirection="orbitDirection.items"
            :propProgramingServices="programingService.items"
            :propSatelliteType="satelliteType.items"
            @getAllDirection="getAllDirection"
            @getAllTaskingProgrammingService="getAllTaskingProgrammingService"
            @getAllTaskingGranularity="getAllTaskingGranularity"
            @getAllSatelliteType="getAllSatelliteType"
          />
        </div>
      </div>
    </div>
  </main-layout>
</template>
<script>
import MainLayout from "@/components/pages/layouts/admin/Main";
import Detail from "./Detail.vue";
import metaKeyFunc from "@/utils/functions/metakey";
import orderFunc from "@/utils/functions/order";
export default {
  components: { MainLayout, Detail },

  data() {
    return {
      loading: false,
      id: null,
      data: {},
      granularity: {
        items: [],
      },
      programingService: {
        items: [],
      },
      lookDirection: {
        items: [],
      },
      orbitDirection: {
        items: [],
      },
      satelliteType: {
        items: [],
      },
    };
  },
  async created() {
    this.loading = true;

    this.$store.dispatch("setSpinnerApp", { loading: true });
    this.id = this.$route.params ? this.$route.params.id : null;
    // await this.getAllSatelliteType();
    // await this.getAllDirection();
    // await this.getAllTaskingGranularity();
    // await this.getAllTaskingProgrammingService();
    if (this.id) await this.getDetail();
    this.loading = false;
    this.$store.dispatch("setSpinnerApp", { loading: false });
  },
  methods: {
    async getDetail() {
      try {
        let response = await orderFunc.getById(
          this.id,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success) {
            this.data = response.data.data ? response.data.data : {};
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getDetail);
        }
      }
    },

    async getAllTaskingGranularity() {
      try {
        let response = await metaKeyFunc.getByKey(
          "GRANULARITY",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.granularity.items = response.data.data ? response.data.data : [];
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllTaskingGranularity);
        }
      }
    },

    async getAllTaskingProgrammingService() {
      try {
        let response = await metaKeyFunc.getByKey(
          "PROGRAMMING_SERVICE",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.programingService.items = response.data.data
            ? response.data.data
            : [];
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllTaskingProgrammingService);
        }
      }
    },

    async getAllDirection() {
      try {
        let response = await metaKeyFunc.getByKey(
          "DIRECTION",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          let data =
            response.data && response.data.data ? response.data.data : [];
          this.lookDirection.items = data.filter(
            (x) => x.name != "ASCENDING" && x.name != "DESCENDING"
          );
          this.orbitDirection.items = data.filter(
            (x) => x.name != "LEFT" && x.name != "RIGHT"
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllDirection);
        }
      }
    },

    async getAllSatelliteType() {
      try {
        let response = await metaKeyFunc.getByKey(
          "IMAGE_TYPES",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.satelliteType.items =
            response.data && response.data.data ? response.data.data : [];
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllSatelliteType);
        }
      }
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
};
</script>
<style lang="scss" scoped>
.order-detail-content {
  background-color: white;
  padding: 1rem 0.75rem;
  #notfound {
    position: relative;
    height: calc(100vh - 4rem);
    background: white;
  }

  #notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .notfound {
    max-width: 520px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
  }

  .notfound .notfound-404 {
    position: relative;
    height: 240px;
  }

  .notfound .notfound-404 h1 {
    font-family: "Montserrat", sans-serif;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 14rem;
    font-weight: 900;
    margin: 0px;
    color: #262626;
    text-transform: uppercase;
    letter-spacing: -40px;
    margin-left: -20px;
  }

  .notfound .notfound-404 h1 > span {
    text-shadow: -8px 0px 0px #fff;
  }

  .notfound .notfound-404 h3 {
    position: relative;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #262626;
    margin: 0px;
    letter-spacing: 3px;
    padding-left: 6px;
  }

  .notfound h6 {
    font-weight: 400;
    color: #000;
    margin-top: 0px;
  }

  @media only screen and (max-width: 767px) {
    .notfound .notfound-404 {
      height: 200px;
    }
    .notfound .notfound-404 h1 {
      font-size: 200px;
    }
  }

  @media only screen and (max-width: 480px) {
    .notfound .notfound-404 {
      height: 162px;
    }
    .notfound .notfound-404 h1 {
      font-size: 162px;
      height: 150px;
      line-height: 162px;
    }
    .notfound h2 {
      font-size: 16px;
    }
  }
}
</style>
