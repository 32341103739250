
<template>
  <div class="huGHgeKd82d">
        <Chart :prop-data="propData"/>
  </div>
</template>
<script>
import Chart from "./datachart/Chart.vue"
export default {
  components: {
    Chart
  },
  props: {
    propData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
    }
  },
  computed: {
    provider(){
      let results = [];
      let array = [...new Set( this.propData.map(x=>x.provider))];
      for (let i = 0; i < array.length; i++) {
        results.push({
          id: new Date().getTime() + Math.floor(Math.random() * 99999) - Math.floor(Math.random() * 10000),
          label:array[i],
          children:this.propData.filter(x=>x.provider === array[i])
        })
        
      }
      return results
    }
  },
};
</script>
<style lang="scss" scoped>
.huGHgeKd82d {
  position: relative;
  height: 200px;
  width: 100%;
  h6.title{
    font-size: 14px;
    padding-left: .25rem;
    font-weight: 700;
  }
}
</style>
