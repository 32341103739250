<template>
  <div>
    <div v-if="propItem && !propItem.is_order_request">
      <Detail4Images :prop-item="propItem" />
    </div>
    <div v-else-if="propItem && propItem.is_order_request">
      <Detail4Hub
        :prop-item="propItem"
        :propGranularities="propGranularities"
        :propLookDirection="propLookDirection"
        :propOrbitDirection="propOrbitDirection"
        :propProgramingServices="propProgramingServices"
        :propSatelliteType="propSatelliteType"
      />
    </div>
  </div>
</template>
<script>
import Detail4Images from "./Detail4Images.vue";
import Detail4Hub from "./Detail4Hub.vue";
export default {
  components: { Detail4Images, Detail4Hub },
  props: {
    propItem: {
      type: Object,
      default: () => {},
    },

    propGranularities: { type: Array, default: () => [] },
    propProgramingServices: {
      type: Array,
      default: () => [],
    },
    propLookDirection: {
      type: Array,
      default: () => [],
    },
    propOrbitDirection: {
      type: Array,
      default: () => [],
    },
    propSatelliteType: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    // if (!this.propSatelliteType || this.propSatelliteType.length === 0) {
    //   this.$emit("getAllSatelliteType");
    // }
    // if (!this.propGranularities || this.propGranularities.length === 0) {
    //   this.$emit("getAllTaskingGranularity");
    // }
    // if (
    //   !this.propProgramingServices ||
    //   this.propProgramingServices.length === 0
    // ) {
    //   this.$emit("getAllTaskingProgrammingService");
    // }
    // if (!this.propLookDirection || this.propLookDirection.length === 0) {
    //   this.$emit("getAllDirection");
    // }
  },
  methods: {},
};
</script>
<style lang="scss" scoped></style>
