<template>
  <div
    class="map__openlayer"
    id="map__openlayer"
    :style="{ height: mapHeight + 'px' }"
  ></div>
</template>
<script>
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import { Tile as TileLayer } from "ol/layer";
import XYZ from "ol/source/XYZ";
import { TileImage } from "ol/source";
import { defaults as defaultControls, FullScreen } from "ol/control";
import { fromLonLat, transform } from "ol/proj";
import Feature from "ol/Feature";
import Polygon from "ol/geom/Polygon";
import { Vector as VectorLayer, Image as ImageLayer } from "ol/layer";
import { Vector as VectorSource, ImageCanvas } from "ol/source";
import {
  Style,
  Fill,
  Stroke,
  Circle,
  Icon,
  Text,
  RegularShape,
} from "ol/style.js";

export default {
  data() {
    return {
      map: Object,
      layer: Object,
      layerDrawImage: Object,
      styles: {
        footprint: new Style({
          fill: new Fill({
            color: "rgba(0,0,255,0.3)",
          }),
          stroke: new Stroke({
            color: "rgba(0,0,255,0.8)",
            width: 1.25,
          }),
        }),
        image: new Style({
          fill: new Fill({
            color: "rgba(0,0,0,0)",
          }),
          stroke: new Stroke({
            color: "rgba(0,0,0,0.8)",
            width: 1.25,
          }),
        }),
      },
    };
  },
  mounted() {
    this.initMapOpenlayer();
  },
  created() {},
  methods: {
    initMapOpenlayer: function () {
      var source = new VectorSource({ wrapX: false, id: "sourcedraw" });

      this.layer = new VectorLayer({
        source: source,
      });
      this.layer.set("id", "layerdraw");
      this.map = new Map({
        controls: defaultControls({ attribution: false, zoom: false }).extend([
          new FullScreen(),
        ]),
        layers: [
          new TileLayer({
            title: "Basemap",
            id: "vgbasemap",
            source: new XYZ({
              url:
                vm.basemap &&
                Object.keys(vm.basemap).length > 0 &&
                vm.basemap.source &&
                vm.basemap.source.url
                  ? vm.basemap.source.url
                  : process.env.VUE_APP_BASEMAP_URL,
            }),
            show: true,
            opacity: 1,
          }),
          this.layer,
        ],
        target: "map__openlayer",
        view: new View({
          center: fromLonLat([
            104.519602089 - (104.519602089 - 103.944650753) / 2,
            19.5052943669 - (19.5052943669 - 18.96769173) / 2,
          ]),
          zoom: 9,
          minZoom: 5,
          maxZoom: 16,
        }),
      });
      this.$emit("initMap", this.map);
      this.addImage2Map();
    },

    addFootprint: async function (val) {
      try {
        this.addFeature(
          this.layer,
          val.boundary.coordinates[0],
          this.styles.footprint
        );
      } catch (error) {
        //console.error("addFootprint", error);
      }
    },
    addFeature(layer, coordinates, style) {
      let id = new Date().getTime();
      let feature = layer.getSource().getFeatureById(id);
      if (!feature) {
        var points = [];
        try {
          for (var i = 0; i < coordinates.length; i++) {
            points[i] = transform(coordinates[i], "EPSG:4326", "EPSG:3857");
          }
          feature = new Feature({
            geometry: new Polygon([points]),
            id: id,
          });
          feature.set("id", id);
          feature.id_ = id;
          feature.setStyle(style);
          layer.getSource().addFeature(feature);
        } catch (error) {
          //console.error("addFeature", error);
        }
      } else {
        feature.style = { visibility: "visible" };
      }
    },
    addImage2Map: function () {
      var vm = this;
      let val = {
        boundary: {
          type: "Polygon",
          coordinates: [
            [
              [103.943981978, 19.5052943669],
              [104.519926696, 19.5025704097],
              [104.519602089, 18.96769173],
              [103.944650753, 18.9720950323],
              [103.943981978, 19.5052943669],
            ],
          ],
        },
      };
      var corners = [
        { x: 100, y: 100 }, // ul
        { x: 520, y: 20 }, // ur
        { x: 520, y: 380 }, // br
        { x: 100, y: 380 }, // bl
      ];

      var lcoordinates = [],
        pixels = [];
      lcoordinates.push([
        val.boundary.coordinates[0][0][0],
        val.boundary.coordinates[0][0][1],
      ]);
      lcoordinates.push([
        val.boundary.coordinates[0][1][0],
        val.boundary.coordinates[0][1][1],
      ]);
      lcoordinates.push([
        val.boundary.coordinates[0][2][0],
        val.boundary.coordinates[0][2][1],
      ]);
      lcoordinates.push([
        val.boundary.coordinates[0][3][0],
        val.boundary.coordinates[0][3][1],
      ]);
      var bbox = {
        WKID: "EPSG:4326",
        coord: lcoordinates,
      };
      var canvas = document.createElement("canvas");
      var context = canvas.getContext("2d");
      var img = new Image();
      img.src = "/img/PREVIEW_SPOT6_MS_201804210317029_SEN_4419716101.jpg";
      img.onload = function () {};
      var canvasFunction = function (
        extent,
        resolution,
        pixelRatio,
        size,
        projection
      ) {
        var canvasWidth = size[0],
          canvasHeight = size[1];
        canvas.setAttribute("width", canvasWidth);
        canvas.setAttribute("height", canvasHeight);
        var mapExtent = vm.map.getView().calculateExtent(vm.map.getSize());
        var canvasOrigin = vm.map.getPixelFromCoordinate([
          extent[0],
          extent[3],
        ]);
        var mapOrigin = vm.map.getPixelFromCoordinate([
          mapExtent[0],
          mapExtent[3],
        ]);
        var delta = [
          pixelRatio * mapOrigin[0] - pixelRatio * canvasOrigin[0],
          pixelRatio * mapOrigin[1] - pixelRatio * canvasOrigin[1],
        ];

        var points = bbox.coord;
        if (bbox.WKID != "EPSG:3857") {
          // Verifier egalité sur chaine
          for (var i = 0; i < points.length; i++) {
            points[i] = transform(points[i], bbox.WKID, "EPSG:3857");
          }
          bbox.WKID = "EPSG:3857";
        }
        for (var i = 0; i < points.length; i++) {
          pixels[i] = vm.map.getPixelFromCoordinate(points[i]);
          pixels[i][0] = pixelRatio * pixels[i][0] + delta[0];
          pixels[i][1] = pixelRatio * pixels[i][1] + delta[1];
          corners[i].x = pixels[i][0];
          corners[i].y = pixels[i][1];
        }
        // draw a box
        function drawBBox(mybbox) {
          context.save();
          context.moveTo(pixels[0][0], pixels[0][1]);
          context.lineTo(pixels[1][0], pixels[1][1]);
          context.lineTo(pixels[2][0], pixels[2][1]);
          context.lineTo(pixels[3][0], pixels[3][1]);
          context.closePath();
          context.lineWidth = 1;
          context.stroke();
          context.restore();
        }

        var p = new Perspective(context, img);
        if (p) {
          p.draw([
            [corners[0].x, corners[0].y],
            [corners[1].x, corners[1].y],
            [corners[2].x, corners[2].y],
            [corners[3].x, corners[3].y],
          ]);
        }
        //drawBBox(bbox);
        return canvas;
      };
      var canvasLayer = new ImageLayer({
        source: new ImageCanvas({
          canvasFunction: canvasFunction,
          projection: "EPSG:3857",
        }),
      });
      canvasLayer.setZIndex(vm.map.getLayers().array_.length);
      canvasLayer.set("id", val._id);
      vm.map.addLayer(canvasLayer);
      vm.map.render();
      this.addFootprint(val);
    },
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    mapHeight() {
      let dom = document.getElementById("tableMetadata");
      if (dom) {
        return dom.offsetHeight;
      } else return 500;
    },
    basemap() {
      return this.$store.getters.getBasemap;
    },
  },
};
</script>
<style lang="scss" scoped>
.map__openlayer {
  position: absolute;
  width: 100%;
  padding: 0;
  border: 1px solid #eee;
}
</style>
