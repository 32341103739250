var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-layout',{attrs:{"isToolbar":false,"isBreadcrumb":true,"breadcrumbitems":[
    { label: _vm.$t('menu_administration') },
    { label: _vm.$t('menu_management_common_config') } ],"propButtonsState":{
    save: {
      visible: false,
    },
    add: {
      visible: false,
    },
    edit: {
      visible: false,
    },
    reload: {
      visible: true,
    },
    print: {
      visible: false,
    },
  },"propTitle":_vm.$t('menu_management_common_config')},on:{"emitFunction":function($event){return _vm.menuBarFunction($event)}}},[_c('div',{staticClass:"common-config-content"},[_c('div',{staticClass:"common-config__dialog"},[_c('ValidationObserver',{ref:"basemapObserver"},[(_vm.dialog.basemaps.show)?_c('DialogBasemapDetail',{attrs:{"prop-show":_vm.dialog.basemaps.show,"prop-entity":_vm.dialog.basemaps.value,"prop-style":{ width: _vm.dialog.basemaps.width },"prop-title":_vm.dialog.basemaps.title},on:{"close":function($event){_vm.dialog.basemaps.show = false;
            _vm.dialog.basemaps.value = null;
            _vm.dialog.basemaps.title = null;},"onSave":_vm.saveBasemap,"message":function($event){_vm.message = $event}}}):_vm._e()],1),(_vm.dialog.confirmDelete.show)?_c('DialogConfirmation',{attrs:{"prop-show":_vm.dialog.confirmDelete.show,"prop-title":_vm.$t('title_message_confirm'),"prop-icon":_vm.dialog.confirmDelete.icon,"prop-message":_vm.dialog.confirmDelete.message,"textNo":_vm.$t('button_text_close'),"textYes":_vm.$t('button_text_delete')},on:{"cancel":_vm.onCancelConfirmDelete,"confirmed":_vm.onConfirmedDelete}}):_vm._e(),(_vm.message.length > 0)?_c('DialogMessage',{attrs:{"propTitle":_vm.$t('title_message_info'),"prop-show":_vm.message.length > 0,"prop-message":_vm.message},on:{"close":function($event){_vm.message = []}}}):_vm._e()],1),_c('div',{staticClass:"common-config__accordion"},[_c('div',{staticClass:"common-config__accordion--basemaps"},[_c('Basemaps',{attrs:{"propItems":_vm.basemaps.items,"prop-loading":_vm.basemaps.loading},on:{"showDialogAddBasemaps":_vm.showDialogAddBasemaps,"showDialogConfirmDelete":_vm.showDialogConfirmDelete}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }