<template>
  <div class="huGHgeKd82d">
    <CustomChart
      :chartdata="data"
      :options="options"
      :type="'bar'"
      :ref="'customchart' + id"
    />
  </div>
</template>
<script>
import CustomChart from "@/components/commons/chart/CustomChart";
export default {
  components: {
    CustomChart,
  },
  props: {
    propData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      id:
        new Date().getTime() -
        Math.floor(Math.random() * 9999999) +
        Math.floor(Math.random() * 1000000),
      loading: true,
      data: {
        labels: [],
        datasets: [],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              display: true,
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        showLines: true,
        legend: {
          display: true,
        },
        plugins: {
          labels: [
            {
              render: function(args) {
                return args.value > 0 ? args.value : "";
              },
            },
          ],
        },
      },
    };
  },
  created() {
    this.id =
      this.id -
      Math.floor(Math.random() * 5000) +
      Math.floor(Math.random() * 9999);
  },
  watch: {
    propData: {
      handler(val) {
        if (!val || val.length === 0) return;
        this.data.labels = [];
        this.data.datasets = [];
        let color = "#009EF7"; //this.getRandomColor();
        this.data.datasets.push({
          label: "",
          data: [],
          backgroundColor: color,
          borderColor: color,
        });
        for (let i = 0; i < val.length; i++) {
          this.data.labels.push(val[i].label);
          this.data.datasets[0].data.push(val[i].value);
        }

        setTimeout(() => {
          if (this.$refs["customchart" + this.id]) {
            this.$refs["customchart" + this.id].updateChart();
          }
        }, 800);
      },
      immediate: true,
    },
  },
  methods: {
    getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    hexToRgbA(hex, opacity) {
      if (!opacity) opacity = "0.4";
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.huGHgeKd82d {
  position: relative;
  height: 22rem;
  width: 100%;
}
</style>
