<template>
    <div class="modal fade" tabindex="-1" role="dialog" :id="propId"  @click="onClose">
        <div class="modal-dialog"  ref="modal" role="document"
            :class="[propIsCentered ?'modal-dialog-centered' : '',propSize?propSize:'']">
            <div class="modal-content">
                <slot name="modal-header">
                    <div class="modal-header">
                        <h5 class="modal-title">Modal title</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                </div>
                </slot>
                <div class="modal-body">
                    <slot></slot>
                </div>
                <slot name="modal-footer">
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary" @click="onSave">Save changes</button>
                    </div>
                </slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        propId:{
            type:String,
            default:"modalwebc08"
        },
        propIsCentered:{
            type:Boolean,
            default:false
        },
        propSize:{
            type:String,
            default:""
        }
    },
    data(){
        return{
            step:1
        }
    },
    methods:{
        onSave(){
            this.$emit("onSave");
            $('#'+this.propId).modal('hide')
        },
        onClose () {
            setTimeout(() => {
                const element = document.querySelector("#"+this.propId);
                if(!element.classList.contains("show"))
                    this.$emit("closeModal");
            }, 100);
        }

    },
    directives: {
        'closable': {
            bind (el, binding, vnode) {
                let handleOutsideClick = (e) => {
                e.stopPropagation()
                const { handler, exclude } = binding.value
                let clickedOnExcludedEl = false;
                exclude.forEach(refName => {
                    if (!clickedOnExcludedEl) {
                    const excludedEl = vnode.context.$refs[refName]
                    clickedOnExcludedEl = excludedEl.contains(e.target)
                    }
                })
                if (!el.contains(e.target) && !clickedOnExcludedEl) {
                    vnode.context[handler]()
                }
                }
                document.addEventListener('click', handleOutsideClick)
                document.addEventListener('touchstart', handleOutsideClick)
            },

            unbind () {
                document.removeEventListener('click', handleOutsideClick)
                document.removeEventListener('touchstart', handleOutsideClick)
            }
        },
        'click-outside':{ 
            bind: function (el, binding, vnode) {
                el.eventSetDrag = function () {
                    el.setAttribute('data-dragging', 'yes');
                }
                el.eventClearDrag = function () {
                    el.removeAttribute('data-dragging');
                }
                el.eventOnClick = function (event) {
                    var dragging = el.getAttribute('data-dragging');
                    // Check that the click was outside the el and its children, and wasn't a drag
                    if (!(el == event.target || el.contains(event.target)) && !dragging) {
                        // call method provided in attribute value
                        vnode.context[binding.expression](event);
                    }
                };
                document.addEventListener('touchstart', el.eventClearDrag);
                document.addEventListener('touchmove', el.eventSetDrag);
                document.addEventListener('click', el.eventOnClick);
                document.addEventListener('touchend', el.eventOnClick);
            }, 
            unbind: function (el) {
                document.removeEventListener('touchstart', el.eventClearDrag);
                document.removeEventListener('touchmove', el.eventSetDrag);
                document.removeEventListener('click', el.eventOnClick);
                document.removeEventListener('touchend', el.eventOnClick);
                el.removeAttribute('data-dragging');
            },
        }
    },
    beforeDestroy(){
    }
}
</script>
<style lang="scss" scoped>

</style>