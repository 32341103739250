<template>
  <div class="p-radiobutton p-component" @click="onClick">
    <div class="p-hidden-accessible">
      <input
        :ref="'input' + id"
        type="radio"
        :checked="checked"
        @focus="onFocus($event)"
        @blur="onBlur($event)"
        :disabled="disabled"
      />
    </div>
    <div
      ref="box"
      :class="[
        'p-radiobutton-box p-component',
        { 'p-highlight': checked, 'p-disabled': disabled, 'p-focus': focused },
      ]"
      role="radio"
      :aria-checked="checked"
    >
      <div class="p-radiobutton-icon"></div>
    </div>
  </div>
</template>

<script>
import UniqueComponentId from "@/utils/commons/primevue/UniqueComponentId";
export default {
  inheritAttrs: false,
  props: {
    value: null,
    disabled: null,
    checked: null,
  },
  data() {
    return {
      focused: false,
      id: "rchk" + UniqueComponentId(),
    };
  },
  methods: {
    onClick(event) {
      if (!this.disabled) {
        if (!this.checked) {
          this.$emit("change", {
            originalEvent: event,
            data: this.value,
          });
        }
        if (this.$refs["input" + this.id])
          this.$refs["input" + this.id].focus();
      }
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
  },
};
</script>