<template>
    <modal-default :propId="propData.id ? propData.id :'modalconfirmgeostore'"
        :propIsCentered="propData.isCenter ? propData.isCenter : false"
        :propSize="propData.size ? propData.size :'modal-sm'">
        <div class="modal-header" slot="modal-header">
            <h5 class="modal-title">{{ propData.title ? propData.title : 'Xác nhận' }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <p v-html="propData.message ? propData.message : ''"></p>
        <div class="modal-footer" slot="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal"><i class="fas fa-times"></i>&nbsp;Hủy bỏ</button>
            <button type="button" class="btn btn-primary" @click="handleConfirm"><i class="fas fa-times"></i>&nbsp;Đồng ý</button>
        </div>
    </modal-default>
</template>
<script>
import ModalDefault from "@/components/commons/modal/Default"
export default {
    name:"ModalConfirm",
    props:{
        propData:{
            type:Object,
            default:()=> {
                return{
                    title:"Xác nhận",
                    message:"",
                    id:"modalconfirmgeostore",
                    size:"modal-sm"
                }
            }
        }
    },
    components:{
        ModalDefault
    },
    methods:{
        handleConfirm(){
            this.$emit("confirmed");
        }
    }
}
</script>
<style lang="scss" scoped>
    p{
        margin-bottom: 0
    }
</style>