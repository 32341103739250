import axios from 'axios';
import basicApi from "@/utils/apis/basic";
import app from './../../main'
import { geoServiceApiURL } from "@/utils/commons/environment"
export default {
    async createModel(entity,access_token){
        let headers = basicApi.processing_data.create_model.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
          method: basicApi.processing_data.create_model.method,
          url:  geoServiceApiURL + basicApi.processing_data.create_model.url,
          data: entity,
          headers:headers,
          params: {
          }
        });
        let response = await object;
        return response;
    },
    async updateModel(entity, access_token) {
      let headers = basicApi.processing_data.update_model.headers;
      headers["Authorization"] = "bearer " + access_token;
      headers['Accept-Language'] = app.$store.getters.getLang;
      const object = axios({
        method: basicApi.processing_data.update_model.method,
        url: geoServiceApiURL + basicApi.processing_data.update_model.url.replace("{id}",entity.id),
        data: entity,
        headers:headers,
        params: {
        }
      });
      let response = await object;
      return response;
    },
    
    async deleteModel(id, access_token) {
      let headers = basicApi.processing_data.delete_model.headers;
      headers["Authorization"] = "bearer " + access_token;
      headers['Accept-Language'] = app.$store.getters.getLang;
      const object = axios({
        method: basicApi.processing_data.delete_model.method,
        url: geoServiceApiURL + basicApi.processing_data.delete_model.url.replace("{id}",id),
        headers:headers,
        params: {
        }
      });
      let response = await object;
      return response;
    },
    async getModelByPageSize(params,data, access_token) {
        let headers = basicApi.processing_data.get_all_model.headers;
        if(!headers)
          headers= {}
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object =  axios({
          method: basicApi.processing_data.get_all_model.method,
          url: geoServiceApiURL + basicApi.processing_data.get_all_model.url,
          params: params,
          data: data,
          headers: headers,
        });
    
        let response = await object;
        return response;
      },
      async getAllModel(data, access_token) {
        let headers = basicApi.processing_data.get_all_model.headers;
        if(!headers)
          headers= {}
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object =  axios({
          method: basicApi.processing_data.get_all_model.method,
          url: geoServiceApiURL + basicApi.processing_data.get_all_model.url,
          params: {
            page: 0,
            size: 2147483647,
          },
          data: data,
          headers: headers,
        });
    
        let response = await object;
        return response;
      },

}