<template>
  <main-layout
    :isToolbar="false"
    :isBreadcrumb="true"
    :breadcrumbitems="[
      { label: $t('menu_statistic_report') },
      { label: $t('menu_statistic_upload') },
    ]"
    :propButtonsState="{
      save: {
        visible: false,
      },
      add: {
        visible: false,
      },
      edit: {
        visible: false,
      },
      reload: {
        visible: true,
      },
      print: {
        visible: false,
      },
    }"
    :propTitle="$t('menu_statistic_upload')"
    @emitFunction="menuHeaderClick"
  >
    <div class="statistic-upload">
      <div class="statistic-upload__dialog">
        <DialogDetail
          v-if="dialog.detail.show"
          :prop-style="{ width: dialog.detail.width }"
          :prop-show="dialog.detail.show"
          :prop-title="dialog.detail.title"
          :prop-items="dialog.detail.items"
          @close="
            dialog.detail.show = false;
            dialog.detail.items = [];
          "
        />

        <DialogMessage
          v-if="message.length > 0"
          :propTitle="$t('title_message_info')"
          :prop-show="message.length > 0"
          :prop-message="message"
          @close="message = []"
        />
      </div>
      <div class="statistic-upload__search">
        <DownloadFilter
          :prop-search="statisticUpload.search"
          @onSearch="onSearch"
          @message="message = $event"
        />
      </div>
      <div class="statistic-upload__datatable">
        <DownloadDataTable
          :prop-items="statisticUpload.items"
          @shoDialogDetail="shoDialogDetail"
        />
      </div>
      <div class="statistic-upload__chart">
        <DownloadChart :prop-data="statisticUpload.items" />
      </div>
    </div>
  </main-layout>
</template>
<script>
import MainLayout from "@/components/pages/layouts/admin/Main";
import DialogMessage from "@/components/commons/dialog/MessageBox";
import DownloadFilter from "./statisticupload/Filter.vue";
import DownloadDataTable from "./statisticupload/DataTable";
import DownloadChart from "./statisticupload/DataChart";
import DialogDetail from "./statisticupload/dialog/DialogDetail.vue";
import statisticFunc from "@/utils/functions/statistic";
import metaKeyFunc from "@/utils/functions/metakey";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import imageFunc from "@/utils/functions/image";
export default {
  components: {
    MainLayout,
    DownloadFilter,
    DownloadDataTable,
    DownloadChart,
    DialogDetail,
    DialogMessage,
  },
  props: {},
  data() {
    return {
      message: [],
      provider: {
        items: [],
      },
      statisticUpload: {
        items: [],
        search: {
          from: null,
          to: null,
          page: 0,
          size: 2147483647,
        },
        table: {
          selected: null,
        },
        is_bottom: false,
      },
      dialog: {
        detail: {
          show: false,
          title: this.$t("header_detail"),
          items: [],
          width: "480px",
        },
      },
    };
  },
  async created() {
    var vm = this;
    if (vm.documentWidth < 576) {
      this.dialog.detail.width = this.documentWidth + "px";
    } else if (vm.documentWidth >= 576 && vm.documentWidth < 768) {
      this.dialog.detail.width = "540px";
    } else if (vm.documentWidth >= 768 && vm.documentWidth < 992) {
      this.dialog.detail.width = "720px";
    } else {
      this.dialog.detail.width = "720px";
    }
    let today = new Date(),
      dateMinus7 = new Date();
    today.setHours(23);
    today.setMinutes(59);
    today.setSeconds(59);
    dateMinus7.setHours(0);
    dateMinus7.setMinutes(0);
    dateMinus7.setSeconds(0);
    this.statisticUpload.search.to = today;
    this.statisticUpload.search.from = new Date(
      dateMinus7.setDate(dateMinus7.getDate() - 7)
    );
    this.statisticUpload.items = [];
    await this.getAllProvider();
    this.getSummary();
  },
  methods: {
    menuHeaderClick(val) {
      switch (val.code) {
        case "RELOAD":
          this.getSummary();
          break;
      }
    },
    onSearch(data) {
      this.statisticUpload.search.from = data.from;
      this.statisticUpload.search.to = data.to;
      this.getSummary();
    },
    async shoDialogDetail(item) {
      if (item.items && item.items.length > 0) {
        this.dialog.detail.items = item.items;
        this.dialog.detail.show = true;
      } else {
        try {
          let response = await imageFunc.search(
            {
              page: 0,
              size: 2147483647,
            },
            {
              create_time_from: this.statisticUpload.search.from
                ? new Date(this.statisticUpload.search.from)
                : null,
              create_time_to: this.statisticUpload.search.to
                ? new Date(this.statisticUpload.search.to)
                : null,
              provider_ids: [item.provider_id],
              satellite_ids: [item.satellite_id],
            },
            this.$store.getters.getAccessToken
          );
          if (response && response.status === 200) {
            let index = this.statisticUpload.items.findIndex(
              (x) => x.satellite_id === item.satellite_id
            );
            if (index >= 0)
              this.statisticUpload.items[index].items =
                response.data.content_page;
            this.dialog.detail.items = response.data.content_page;
            this.dialog.detail.show = true;
          }
        } catch (error) {
          if (error.response && error.response.status === 401)
            this.refreshToken(this.shoDialogDetail, item);
        }
      }
    },
    /*---------------------- Get data ---------------------------*/
    async getAllProvider() {
      try {
        let response = await metaKeyFunc.getByKey(
          "PROVIDER",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.provider.items =
            response.data && response.data.data ? response.data.data : [];

          this.provider.items.sort(function (a, b) {
            if (a.name < b.name) return -1;
            else if (a.name > b.name) return 1;
            return 0;
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 401)
          this.refreshToken(this.getAllProvider);
      }
    },
    async getSummary() {
      this.statisticUpload.items = [];
      try {
        let response = await statisticFunc.getSummary(
          {
            from: this.statisticUpload.search.from
              ? new Date(this.statisticUpload.search.from)
              : null,
            to: this.statisticUpload.search.to
              ? new Date(this.statisticUpload.search.to)
              : null,
            type: "upload",
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data && response.data.success) {
            this.initData(response.data.data);
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401)
          this.refreshToken(this.getAllProvider);
      }
    },
    initData(data) {
      if (data && data.length > 0) {
        if (this.provider.items && this.provider.items.length > 0) {
          for (let i = 0; i < this.provider.items.length; i++) {
            if (
              this.provider.items[i].sub_meta_key &&
              this.provider.items[i].sub_meta_key.length > 0
            ) {
              for (
                let j = 0;
                j < this.provider.items[i].sub_meta_key.length;
                j++
              ) {
                let index = data.findIndex(
                  (x) =>
                    x.satellite &&
                    x.satellite.name ===
                      this.provider.items[i].sub_meta_key[j].name
                );
                if (index >= 0) {
                  this.statisticUpload.items.push({
                    provider: this.provider.items[i].value,
                    satellite: this.provider.items[i].sub_meta_key[j].value,
                    total: data[index].total,
                    satellite_id: this.provider.items[i].sub_meta_key[j].id,
                    satellite_name: this.provider.items[i].sub_meta_key[j].name,
                    provider_id: this.provider.items[i].id,
                    provider__name: this.provider.items[i].name,
                    items: [],
                  });
                }
              }
            }
          }
        }
      }
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          callBack();
        } else {
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
    /*---------------------- End get data -------------------------*/
  },
};
</script>
<style lang="scss" scoped>
.statistic-upload {
  padding: 0.5rem;
  .statistic-upload__search {
    padding: 0;
    border-radius: 0.5rem;
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .statistic-upload__datatable {
    padding: 0;
    margin-top: 0.5rem;
    background: white;
    border-radius: 0.5rem;
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .statistic-upload__chart {
    padding: 0.5rem;
    margin-top: 0.5rem;
    background: white;
    border-radius: 0.5rem;
    .accordion {
      border-radius: 0.5rem;
    }
  }
}
</style>