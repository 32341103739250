<template>
  <div class="datatable">
    <ContextMenu
      :model="menuModel"
      ref="cmMembersDatatable"
      :appendTo="'kUbw7Hg2Jg'"
    />
    <DataTable
      :value="items.filter((x) => x.is_delete != true)"
      ref="dt"
      :resizableColumns="true"
      columnResizeMode="expand"
      selectionMode="single"
      dataKey="user.id"
      contextMenu
      :selection.sync="selected"
      @row-contextmenu="onRowContextMenu"
    >
      <template #empty v-if="items && items.length === 0">
        <span class="text-center">{{ $t("no_data") }}</span>
      </template>
      <Column
        header=""
        headerStyle="width: 3rem; text-align: center!important"
        bodyStyle="text-align: center;"
      >
        <template #body="slotProps">
          {{ slotProps.index + 1 }}
        </template></Column
      >
      <Column :header="$t('label_full_name')">
        <template #body="slotProps">
          {{ slotProps.data.user.full_name }}
        </template>
      </Column>
      <Column :header="$t('label_username')">
        <template #body="slotProps">
          {{ slotProps.data.user.username }}
        </template>
      </Column>

      <Column :header="$t('label_user_role')">
        <template #body="slotProps">
          {{ slotProps.data.role ? slotProps.data.role.description : "" }}
        </template>
      </Column>
      <Column
        headerStyle="width: 3.5rem"
        v-if="propStatus.isAdd || propStatus.isEdit"
      >
        <template #body="slotProps">
          <button
            icon="fas fa-trash"
            type="button"
            class="btn btn-danger btn-sm"
            :title="$t('button_text_remove')"
            @click="removeOrgMember(slotProps.data)"
          >
            <i class="fas fa-trash"></i></button></template
      ></Column>
    </DataTable>
  </div>
</template>
<script>
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import ContextMenu from "@/components/commons/contextmenu/ContextMenu";
export default {
  components: {
    DataTable,
    Column,
    ContextMenu,
  },
  props: {
    propItems: {
      type: Array,
      default: () => [],
    },
    propStatus: {
      type: Object,
      default: () => {
        return {
          isAdd: false,
          isEdit: false,
        };
      },
    },
  },
  data() {
    return {
      items: [],
      selected: null,
      menuModel: [
        {
          label: this.$t("button_text_remove"),
          icon: "fas fa-trash",
          command: () => this.removeOrgMember(this.selected),
          visible: true,
        },
      ],
    };
  },
  watch: {
    propItems: {
      handler(val) {
        this.initData();
        this.selected = null;
      },
      immediate: true,
    },
  },
  methods: {
    initData() {
      if (this.propItems && this.propItems.length > 0)
        this.items = Object.assign(
          [],
          this.propItems.filter((x) => x.is_delete != true)
        );
      else this.items = [];
    },
    onRowContextMenu(event) {
      if (this.propStatus.isAdd || this.propStatus.isEdit) {
        if (this.selected && Object.keys(this.selected).length > 0) {
          this.$refs.cmMembersDatatable.show(event.originalEvent);
        }
      }
    },
    removeOrgMember(data) {
      let index = this.propItems.findIndex(
        (x) =>
          x.role.role_id === data.role.role_id &&
          x.user.user_id === data.user.user_id
      );
      if (index >= 0) {
        this.propItems[index].is_delete = true;
        this.propItems[index].is_add = false;
        this.propItems.splice(index, 1);
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
