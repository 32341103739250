<template>
  <div class="row no-gutters">
    <div class="col-12 col-md-6 person-info">
      <CommonAccordion :propTitle="$t('label_user_info')">
        <div slot="content" class="">
          <div class="p-grid m-0">
            <div class="p-field p-col-12">
              <label class="p-col-fixed" :style="styleLabel">
                {{ $t("label_full_name") }}&nbsp;
                <span class="font-weight-bold required">*</span>
              </label>
              <ValidationProvider
                :name="$t('label_full_name')"
                rules="required"
              >
                <InputText
                  type="text"
                  :style="styleControl"
                  maxlength="150"
                  v-model="propEntity.full_name"
                  aria-autocomplete="off"
                  :disabled="!propStatus.isAdd && !propStatus.isEdit"
                  class="new-style"
                />
              </ValidationProvider>
            </div>

            <div class="p-field p-col-12">
              <label class="p-col-fixed" :style="styleLabel">
                {{ $t("label_organisation_name") }}&nbsp;
                <span class="font-weight-bold required">*</span></label
              >

              <ValidationProvider
                :name="$t('label_organisation_name')"
                rules="required"
              >
                <AutoComplete
                  :style="styleControl"
                  v-model="propEntity.organization"
                  :suggestions="suggestionsOrgansisation"
                  @complete="searchOrgansisation($event)"
                  :dropdown="false"
                  field="name"
                  :inputClass="'w-100 organisation-input'"
                  :disabled="!propStatus.isAdd && !propStatus.isEdit"
                  @item-select="selectOrganisation"
                  @clear="unSelectOrganisation"
                  class="new-style"
                />
              </ValidationProvider>
            </div>
            <div class="p-field p-col-12 d-flex">
              <label class="p-col-fixed" :style="styleLabel">
                {{ $t("label_gender") }}&nbsp;
                <span class="font-weight-bold required">*</span>
              </label>
              <div class="d-flex" :style="styleControl">
                <div
                  class="p-field p-col pr-3"
                  v-for="(item, index) in dataGender"
                  :key="item.id"
                >
                  <label
                    for="male"
                    class="p-radiobutton-label pr-2"
                    :class="index === 0 ? 'mr-0 pl-0' : ''"
                  >
                    {{ $t("label_" + item.code) }}
                  </label>
                  <RadioButton
                    :id="'radio' + item.id"
                    :name="item.code"
                    :value="item.id"
                    v-model="propEntity.gender"
                    :disabled="!propStatus.isAdd && !propStatus.isEdit"
                  />
                </div>
              </div>
            </div>
            <div class="p-field p-col-12">
              <label class="p-col-fixed" :style="styleLabel">
                {{ $t("label_email") }}&nbsp;
                <span class="font-weight-bold required">*</span></label
              >

              <ValidationProvider
                :name="$t('label_email')"
                rules="required|email"
              >
                <InputText
                  type="text"
                  :style="styleControl"
                  maxlength="150"
                  v-model="propEntity.email"
                  :disabled="!propStatus.isAdd && !propStatus.isEdit"
                  class="new-style"
                />
              </ValidationProvider>
            </div>
            <div class="p-field p-col-12">
              <label class="p-col-fixed" :style="styleLabel"
                >{{ $t("label_phone_number") }}&nbsp;
                <span class="font-weight-bold required">*</span></label
              >
              <ValidationProvider
                :name="$t('label_phone_number')"
                rules="required"
              >
                <InputText
                  type="tel"
                  :style="styleControl"
                  maxlength="10"
                  v-model="propEntity.phone_number"
                  :disabled="!propStatus.isAdd && !propStatus.isEdit"
                  class="new-style"
                />
              </ValidationProvider>
            </div>
            <div class="p-field p-grid p-col-12 m-0">
              <label class="p-col-fixed" :style="styleLabel">{{
                $t("label_address")
              }}</label>
              <Textarea
                :autoResize="true"
                rows="2"
                cols="30"
                :style="styleControl"
                maxlength="250"
                v-model="propEntity.address"
                :disabled="!propStatus.isAdd && !propStatus.isEdit"
                class="new-style"
              />
            </div>
          </div>
        </div>
      </CommonAccordion>
    </div>

    <div class="col-12 col-md-6 login-security">
      <CommonAccordion :propTitle="$t('title_login_and_security')">
        <div slot="content">
          <div class="p-grid m-0">
            <div class="p-field p-col-12" v-if="propStatus.isAdd">
              <label class="p-col-fixed" :style="styleLabel">
                {{ $t("label_username") }}&nbsp;
                <span class="font-weight-bold required">*</span>
              </label>
              <ValidationProvider :name="$t('label_username')" rules="required">
                <InputText
                  id="loginname"
                  type="text"
                  :style="styleControl"
                  maxlength="50"
                  v-model="propEntity.username"
                  aria-autocomplete="off"
                  autocomplete="off"
                  :disabled="!propStatus.isAdd && !propStatus.isEdit"
                  class="new-style"
                />
              </ValidationProvider>
            </div>
            <div class="p-field p-col-12" v-else>
              <label class="p-col-fixed" :style="styleLabel">{{
                $t("label_username")
              }}</label>
              <InputText
                id="loginname"
                type="text"
                :style="styleControl"
                maxlength="50"
                v-model="propEntity.username"
                aria-autocomplete="off"
                autocomplete="off"
                :disabled="true"
                class="new-style"
              />
            </div>
            <div class="p-field p-col-12" v-if="propStatus.isAdd">
              <label class="p-col-fixed" :style="styleLabel">
                {{ $t("label_password") }}&nbsp;
                <span class="font-weight-bold required">*</span>
              </label>

              <ValidationProvider :name="$t('label_password')" rules="required">
                <InputText
                  type="password"
                  :style="styleControl"
                  maxlength="50"
                  aria-autocomplete="off"
                  autocomplete="off"
                  v-model="propEntity.password"
                  :disabled="
                    (!propStatus.isAdd && !propStatus.isEdit) ||
                    (propStatus.isEdit && !isChangePass)
                  "
                  class="new-style"
                />
              </ValidationProvider>
              <Button
                v-if="propStatus.isEdit"
                icon="fas fa-key"
                class="btn-active-changepass"
                :class="
                  isChangePass ? 'p-button-primary' : 'p-button-secondary'
                "
                @click="btnChangePassClick"
              ></Button>
            </div>
            <div class="p-field p-col-12 password" v-else>
              <label class="p-col-fixed" :style="styleLabel">
                {{ $t("label_password") }}&nbsp;
                <span class="font-weight-bold required" v-if="isChangePass"
                  >*</span
                >
              </label>
              <InputText
                type="password"
                :style="styleControl"
                maxlength="50"
                aria-autocomplete="off"
                autocomplete="off"
                v-model="propEntity.password"
                :disabled="
                  (!propStatus.isAdd && !propStatus.isEdit) ||
                  (propStatus.isEdit && !isChangePass)
                "
                class="new-style"
              />
              <Button
                v-if="propStatus.isEdit"
                icon="fas fa-key"
                class="btn-active-changepass"
                :class="
                  isChangePass ? 'p-button-primary' : 'p-button-secondary'
                "
                @click="btnChangePassClick"
              ></Button>
            </div>
            <div class="p-field p-col-12">
              <label class="p-col-fixed" :style="styleLabel"
                >{{ $t("label_user_role") }}
                <span class="font-weight-bold required">*</span></label
              >
              <ValidationProvider
                :name="$t('label_user_role')"
                rules="required"
              >
                <Dropdown
                  :style="styleControl"
                  v-model="propEntity.role_id"
                  :showClear="!(!propStatus.isAdd && !propStatus.isEdit)"
                  :options="roles"
                  :filter="true"
                  optionValue="id"
                  optionLabel="description"
                  :disabled="!propStatus.isAdd && !propStatus.isEdit"
                  class="new-style"
                >
                </Dropdown>
              </ValidationProvider>
            </div>
            <div class="p-field p-col-12">
              <div class="p-field p-col">
                <label class="p-checkbox-label" :style="styleLabelCheckbox"
                  >{{ $t("label_is_active") }} </label
                >&nbsp;
                <Checkbox
                  :binary="true"
                  v-model="propEntity.is_active"
                  :disabled="!propStatus.isAdd && !propStatus.isEdit"
                />
              </div>
            </div>
          </div>
        </div>
      </CommonAccordion>
    </div>
  </div>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import MultiSelect from "@/components/commons/multiselect/MultiSelect";
import InputText from "@/components/commons/inputtext/InputText";
import Textarea from "@/components/commons/textarea/Textarea";
import Button from "@/components/commons/button/Button";
import Checkbox from "@/components/commons/checkbox/Checkbox";
import RadioButton from "@/components/commons/radiobutton/RadioButton";
import Calendar from "@/components/commons/calendar/Calendar";
import AttachFile from "@/components/commons/upload/AttachFile";
import Treeselect from "@riophae/vue-treeselect";
import DatePicker from "vue2-datepicker";
import AutoComplete from "@/components/commons/autocomplete/AutoComplete";
import { change_alias } from "@/utils/commons/common";
export default {
  props: {
    propEntity: {
      type: Object,
      default: () => {},
    },
    propState: {
      type: Object,
      default: () => {},
    },
    propOrganisation: { type: Array, default: () => [] },

    propRole: {
      type: Array,
      default: () => [],
    },
    propStatus: {
      type: Object,
      default: () => {
        return {
          isAdd: false,
          isEdit: false,
          isChangePass: false,
        };
      },
    },
  },
  components: {
    CommonAccordion,
    Dropdown,
    Treeselect,
    Button,
    InputText,
    Checkbox,
    MultiSelect,
    RadioButton,
    Calendar,
    AttachFile,
    Textarea,
    DatePicker,
    AutoComplete,
  },
  data() {
    return {
      isChangePass: false,

      dataGender: [
        {
          id: "1",
          code: "male",
        },
        {
          id: "2",
          code: "female",
        },
        {
          id: "3",
          code: "other",
        },
      ],
      suggestionsOrgansisation: [],
      roles: [],
    };
  },
  created() {
    this.isChangePass = this.propStatus.isChangePass;
  },
  watch: {
    "propStatus.isChangePass": {
      handler(val) {
        this.isChangePass = val;
      },
      immediate: true,
    },
    propRole: {
      handler(val) {
        this.initRoles();
      },
      immediate: true,
    },
    lang: {
      handler(val) {
        this.initRoles();
      },
      immediate: true,
    },
  },

  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    styleLabel() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:150px";
    },
    styleLabelCheckbox() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:138px";
    },
    styleControl() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:calc(100% - 150px)";
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
  methods: {
    initRoles() {
      this.roles = [];
      if (this.propRole && this.propRole.length > 0) {
        for (let i = 0; i < this.propRole.length; i++) {
          this.roles.push({
            id: this.propRole[i].id,
            description:
              this.propRole[i].translation &&
              this.propRole[i].translation[this.lang] &&
              this.propRole[i].translation[this.lang].description
                ? this.propRole[i].translation[this.lang].description
                : this.propRole[i].description,
          });
        }
      }
    },
    selectOrganisation(event) {
      if (event.value) {
        this.propEntity.organization =
          event && event.value ? event.value.name : null;
      }
    },
    unSelectOrganisation() {
      this.propEntity.organization = null;
    },
    btnChangePassClick() {
      this.isChangePass = !this.isChangePass;
      this.$emit("isChangePassChange", this.isChangePass);
    },
    searchOrgansisation(e) {
      this.suggestionsOrgansisation = this.propOrganisation.filter(
        (x) =>
          x.name &&
          (change_alias(x.name.trim().split(" ").join("")).indexOf(
            change_alias(e.query.toLowerCase().split(" ").join(""))
          ) >= 0 ||
            change_alias(x.name.trim().split(" ").join("")).search(
              change_alias(e.query.toLowerCase().split(" ").join(""))
            ) >= 0)
      );
    },
  },
};
</script>

<style lang="scss">
.user-content__structure {
  .p-md-1,
  .p-md-2,
  .p-md-3,
  .p-md-4,
  .p-md-5,
  .p-md-6,
  .p-md-7,
  .p-md-8,
  .p-md-9,
  .p-md-10,
  .p-md-11,
  .p-md-11 {
    padding: 0.375rem !important;
  }
  .p-calendar {
    &.p-calendar-w-btn {
      .p-inputtext {
        width: calc(100% - 2.357em);
      }
    }
    .p-inputtext {
      width: 100%;
    }
  }
  .p-autocomplete-input {
    width: 100% !important;
  }
}
</style>
<style lang="scss" scoped>
.user-content__structure {
  .person-info {
    padding-right: 0.25rem;
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .login-security {
    padding-left: 0.25rem;
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .person-info,
  .employment,
  .login-security {
    .p-grid {
      margin: 0;
      label {
        margin: 0;
      }

      .group-radio {
        display: flex;
        label {
          padding: 0.5em;
        }
        .p-col {
          padding: 0;
        }
      }
    }
    .form-group {
      margin: 0;
      margin-bottom: 0.5rem;
      label {
        padding-left: 0.25rem;
      }
    }
    .password {
      position: relative;
      .btn-active-changepass {
        position: absolute;
        right: 0.5rem;
        padding: 0.375rem 0;
      }
    }
  }
}
</style>

<style lang="sass">
.user-content__structure
.person-info
  .p-radiobutton .p-radiobutton-box
    background: #eef3f7
    border-color: #eef3f7
</style>