<template>
  <main-layout
    :isToolbar="false"
    :isBreadcrumb="true"
    :breadcrumbitems="[
      { label: $t('menu_statistic_report') },
      { label: $t('menu_statistic_general') },
    ]"
    :propButtonsState="{
      save: {
        visible: false,
      },
      add: {
        visible: false,
      },
      edit: {
        visible: false,
      },
      reload: {
        visible: true,
      },
      print: {
        visible: false,
      },
    }"
    :propTitle="$t('menu_statistic_general')"
    @emitFunction="menuHeaderClick"
  >
    <div class="general-report">
      <div class="general-report__data">
        <table class="table">
          <tbody>
            <tr colspan="2">
              <td>
                <span class="font-weight-bold">{{
                  $t("menu_statistic_general")
                }}</span>
              </td>
            </tr>
            <tr>
              <td style="width: 14rem">{{ $t("label_total_images_2") }}:</td>
              <td>
                <strong>{{ statistic.data.total_images }}</strong>
              </td>
            </tr>
            <tr>
              <td>{{ $t("lable_download_total") }}:</td>
              <td>
                <strong>{{ statistic.data.total_download }}</strong>
              </td>
            </tr>
            <tr>
              <td>
                {{
                  $t("label_most_download_top_num", null, {
                    num: 10,
                  })
                }}:
              </td>
              <td>
                <div class="HsJhe782hf">
                  <ol>
                    <li v-for="(name, index) in imagesName" :key="index">
                      {{ name }}
                    </li>
                  </ol>
                  <button
                    label=""
                    icon="fas fa-file-csv"
                    class="btn btn-geostore2 btn-export btn-w120-h36"
                    @click="exportCsv"
                  >
                    <i class="fas fa-file-csv"></i>&nbsp; Export CSV
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                {{
                  $t("label_user_most_download_top_num", null, {
                    num: 10,
                  })
                }}:
              </td>
              <td>
                <ol>
                  <li v-for="(name, index) in usersName" :key="index">
                    {{ name }}
                  </li>
                </ol>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="general-report__datatable">
        <DataTable
          :propItems="statistic.data.most_satellite_image"
          :prop-provider="provider.items"
        />
      </div>
      <div
        class="general-report__chart"
        v-if="statistic.data.most_satellite_image"
      >
        <DataChart :prop-data="statistic.data.most_satellite_image" />
      </div>
    </div>
  </main-layout>
</template>
<script>
import Button from "@/components/commons/button/Button";
import MainLayout from "@/components/pages/layouts/admin/Main";
import DataTable from "./generalreport/DataTable";
import DataChart from "./generalreport/DataChart";
import statisticFunc from "@/utils/functions/statistic";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import metaKeyFunc from "@/utils/functions/metakey";
import {
  formatUnixDateTime2String,
  change_alias,
} from "@/utils/commons/common";
export default {
  components: {
    MainLayout,
    DataTable,
    DataChart,
    Button,
  },
  props: {},
  data() {
    return {
      statistic: {
        data: {},
      },
      provider: {
        items: [],
      },
    };
  },
  async created() {
    await this.getAllProvider();
    this.getStatistic();
  },
  methods: {
    formatUnixDateTime2String(value) {
      return formatUnixDateTime2String(value);
    },
    menuHeaderClick(val) {
      switch (val.code) {
        case "RELOAD":
          this.getStatistic();
          break;
      }
    },

    exportCsv() {
      if (this.imagesName && this.imagesName.length > 0) {
        let dataExport = [];
        for (let i = 0; i < this.imagesName.length; i++) {
          if (dataExport.findIndex((x) => x.name === this.imagesName[i]) < 0) {
            dataExport.push({
              name: this.imagesName[i],
            });
          }
        }
        var vm = this;

        const convertArrayOfObjectsToCSV = (args) => {
          const data = args.data;
          if (!data || !data.length) return;

          const columnDelimiter = args.columnDelimiter || ",";
          const lineDelimiter = args.lineDelimiter || "\n";

          const keys = Object.keys(data[0]);

          let result = "",
            ctr = 0;
          result += keys.join(columnDelimiter);
          result += lineDelimiter;

          data.forEach((item) => {
            ctr = 0;
            keys.forEach((key) => {
              if (ctr > 0) result += columnDelimiter;
              result += item[key];
              ctr++;
            });
            result += lineDelimiter;
          });

          return result;
        };
        const downloadCSV = (args) => {
          let csv = convertArrayOfObjectsToCSV({
            data: dataExport,
          });
          if (!csv) return;

          const filename = args.filename || "export.csv";

          if (!csv.match(/^data:text\/csv/i)) {
            csv = "data:text/csv;charset=utf-8," + csv;
          }

          const data = encodeURI(csv);

          const link = document.createElement("a");
          link.setAttribute("href", data);
          link.setAttribute("download", filename);
          link.click();
          //document.body.removeChild(link);
        };
        downloadCSV({
          filename:
            "export_" +
            change_alias(
              vm
                .formatUnixDateTime2String(new Date().getTime() / 1000)
                .split("/")
                .join("_")
            ) +
            ".csv",
        });
      }
    },
    /*---------------------- Get data ---------------------------*/
    async getAllProvider() {
      try {
        let response = await metaKeyFunc.getByKey(
          "PROVIDER",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.provider.items =
            response.data && response.data.data ? response.data.data : [];

          this.provider.items.sort(function (a, b) {
            if (a.name < b.name) return -1;
            else if (a.name > b.name) return 1;
            return 0;
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 401)
          this.refreshToken(this.getAllProvider);
      }
    },
    async getStatistic() {
      this.statistic.data = {};
      try {
        let response = await statisticFunc.getStatistic(
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.statistic.data = response.data;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getStatistic);
        }
      }
    },

    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
    /*---------------------- End get data -------------------------*/
  },
  computed: {
    imagesName() {
      let results = [];
      if (this.statistic.data && this.statistic.data.most_images_download) {
        for (
          let i = 0;
          i < this.statistic.data.most_images_download.length;
          i++
        ) {
          if (this.statistic.data.most_images_download[i].image)
            results.push(
              this.statistic.data.most_images_download[i].image.name
            );
        }
      }
      return results;
    },
    usersName() {
      let results = [];
      if (this.statistic.data && this.statistic.data.most_user_download) {
        for (
          let i = 0;
          i < this.statistic.data.most_user_download.length;
          i++
        ) {
          if (this.statistic.data.most_user_download[i].user)
            results.push(
              this.statistic.data.most_user_download[i].user.full_name
            );
        }
      }
      return results;
    },
  },
};
</script>
<style lang="scss" scoped>
.general-report {
  padding: 0.5rem;

  .general-report__data {
    padding: 0.5rem;
    background: white;
    border-radius: 0.5rem;
    table {
      margin: 0;
      border: 1px solid #dee2e6;
      td {
        .HsJhe782hf {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
        }
        ol {
          margin: 0;
          padding-left: 0.875rem;
        }
        .btn-export {
          font-size: 14px;
        }
      }
    }
  }
  .general-report__datatable {
    padding: 0;
    padding-top: 0.5rem;
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .general-report__chart {
    padding: 0;
    padding-top: 0.5rem;
    margin-top: 0.5rem;
    background: white;
    border-radius: 0.5rem;
  }
}
</style>
<style lang="scss">
.general-report__data {
  .HsJhe782hf {
    button.p-button-sm {
      padding: 0.375rem 0.875rem;
    }
  }
}
</style>