<template>
  <main-layout
    :isToolbar="false"
    :isBreadcrumb="true"
    :propButtonsState="{
      save: {
        visible: false,
      },
      add: {
        visible: false,
      },
      edit: {
        visible: false,
      },
      reload: {
        visible: false,
      },
      print: {
        visible: false,
      },
    }"
    :propTitle="'Dashboard'"
  >
    <div class="dash-board" slot="default">
      <div class="row no-gutters">
        <div class="col-12 col-sm-6 col-md-3 item-dash-board">
          <div class="item-total-images">
            <div class="d-flex align-items-center item-border-radius">
              <div class="background-logo align-self-center">
                <i class="fas fa-folder align-self-center"></i>
              </div>
              <div class="item-values">
                <div class="value-number">
                  <span>{{
                    formatNumber(
                      statistic.data ? statistic.data.total_images : 0
                    )
                  }}</span>
                </div>
                <div class="value-text">
                  <span>{{ $t("label_images_hub") }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-3 item-dash-board">
          <div class="item-total-ordered">
            <div class="d-flex align-items-center item-border-radius">
              <div class="background-logo">
                <i class="fas fa-shopping-cart"></i>
              </div>
              <div class="item-values">
                <div class="value-number">
                  <span>{{
                    formatNumber(
                      statistic.data ? statistic.data.total_order : 0
                    )
                  }}</span>
                </div>
                <div class="value-text">
                  <span>{{ $t("label_order") }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3 item-dash-board">
          <div class="item-total-shared">
            <div class="d-flex align-items-center item-border-radius">
              <div class="background-logo">
                <i class="fas fa-share-alt"></i>
              </div>
              <div class="item-values">
                <div class="value-number">
                  <span>{{
                    formatNumber(
                      statistic.data ? statistic.data.total_share_image : 0
                    )
                  }}</span>
                </div>
                <div class="value-text">
                  <span>{{ $t("label_share") }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-3 item-dash-board">
          <div class="item-total-customers">
            <div class="d-flex align-items-center item-border-radius">
              <div class="background-logo"><i class="fas fa-user"></i></div>
              <div class="item-values">
                <div class="value-number">
                  <span>{{
                    formatNumber(
                      statistic.data ? statistic.data.total_customer : 0
                    )
                  }}</span>
                </div>
                <div class="value-text">
                  <span>{{ $t("label_customers") }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters w-100">
        <div class="chart-dash-board">
          <div class="bar-chart" v-if="data.chart && data.chart.length > 0">
            <div class="chart__header">
              <span class="chart__header-title">{{
                $t("label_request_statistic")
              }}</span>
              <div class="chart__header-selection">
                <div class="d-flex">
                  <div class="col-dropdown">
                    <Dropdown
                      class="w-100"
                      v-model="selectedType"
                      :showClear="false"
                      :filter="false"
                      :options="types"
                      optionValue="id"
                      optionLabel="label"
                    />
                  </div>
                  <div
                    class="col-dropdown col-month"
                    v-if="selectedType === 'month'"
                  >
                    <Dropdown
                      class="w-100"
                      v-model="selectedMonth"
                      :showClear="false"
                      :filter="false"
                      :options="arrayMonth"
                      optionValue="id"
                      optionLabel="label"
                      @change="monthChange"
                    />
                  </div>

                  <div class="col-dropdown">
                    <Dropdown
                      class="w-100"
                      v-model="selectedYear"
                      :showClear="false"
                      :filter="false"
                      :options="arrayYear"
                      optionValue="id"
                      optionLabel="label"
                      @change="yearChange"
                    />
                  </div>
                </div>
              </div>
            </div>
            <BarChart :prop-data="data.chart" />
          </div>

          <div class="pie-chart">
            <div class="row no-gutters w-100">
              <div
                class="col-12 col-md-6"
                v-if="
                  statistic.data.most_satellite_image &&
                  statistic.data.most_satellite_image.length > 0
                "
              >
                <div class="pie-chart__left">
                  <div class="chart__header">
                    <span class="chart__header-title">{{
                      $t("label_statistics_of_images_in_hub")
                    }}</span>
                  </div>
                  <div class="pt-3">
                    <DataChartLeft
                      :prop-data="statistic.data.most_satellite_image"
                      :prop-satellite-type="satelliteType.items"
                    />
                  </div>
                </div>
              </div>
              <div
                class="col-12 col-md-6"
                v-if="
                  statistic.data.most_satellite_image &&
                  statistic.data.most_satellite_image.length
                "
              >
                <div class="pie-chart__right">
                  <div class="chart__header">
                    <span class="chart__header-title">{{
                      $t("label_satellite_image_data_chart")
                    }}</span>
                  </div>
                  <div class="pt-3">
                    <DataChartRight
                      :prop-data="statistic.data.most_satellite_image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main-layout>
</template>
<script>
/**
 * Number.prototype.format(n, x)
 *
 * @param integer n: length of decimal
 * @param integer x: length of sections
 */
Number.prototype.format = function (n, x) {
  var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
  return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$&,");
};
import MainLayout from "@/components/pages/layouts/admin/Main";
import BarChart from "./dashboard/BarChart.vue";
import PieChart from "./dashboard/PieChart.vue";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import DataChartRight from "./dashboard/piechart/DataChartRight.vue";
import DataChartLeft from "./dashboard/piechart/DataChartLeft.vue";
import statisticFunc from "@/utils/functions/statistic";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import metaKeyFunc from "@/utils/functions/metakey";
export default {
  components: {
    MainLayout,
    BarChart,
    PieChart,
    Dropdown,
    DataChartRight,
    DataChartLeft,
  },
  props: {},
  data() {
    return {
      statistic: {
        data: {},
      },
      now: {
        month: null,
        year: null,
      },
      selectedType: "year",
      types: [
        // {
        //   id: "month",
        //   code: "label_month",
        //   label: "",
        // },
        {
          id: "year",
          code: "label_year",
          label: "",
        },
      ],
      arrayYear: [],
      arrayMonth: [],
      selectedMonth: null,
      selectedYear: null,
      provider: {
        items: [],
      },
      satelliteType: {
        items: [],
      },
      monthsOfYear: [
        {
          code: "label_january",
          label: "",
          id: 1,
        },
        {
          code: "label_february",
          label: "",
          id: 2,
        },
        {
          code: "label_march",
          label: "",
          id: 3,
        },
        {
          code: "label_april",
          label: "",
          id: 4,
        },
        {
          code: "label_may",
          label: "",
          id: 5,
        },
        {
          code: "label_june",
          label: "",
          id: 6,
        },
        {
          code: "label_july",
          label: "",
          id: 7,
        },
        {
          code: "label_august",
          label: "",
          id: 8,
        },
        {
          code: "label_september",
          label: "",
          id: 9,
        },
        {
          code: "label_october",
          label: "",
          id: 10,
        },
        {
          code: "label_november",
          label: "",
          id: 11,
        },
        {
          code: "label_december",
          label: "",
          id: 12,
        },
      ],
      data: {
        chart: [],
        bar: [
          {
            value: 0,
            code: "label_january",
            label: "",
            id: 1,
          },
          {
            value: 0,
            code: "label_february",
            label: "",
            id: 2,
          },
          {
            value: 0,
            code: "label_march",
            label: "",
            id: 3,
          },
          {
            value: 0,
            code: "label_april",
            label: "",
            id: 4,
          },
          {
            value: 0,
            code: "label_may",
            label: "",
            id: 5,
          },
          {
            value: 0,
            code: "label_june",
            label: "",
            id: 6,
          },
          {
            value: 0,
            code: "label_july",
            label: "",
            id: 7,
          },
          {
            value: 0,
            code: "label_august",
            label: "",
            id: 8,
          },
          {
            value: 0,
            code: "label_september",
            label: "",
            id: 9,
          },
          {
            value: 0,
            code: "label_october",
            label: "",
            id: 10,
          },
          {
            value: 0,
            code: "label_november",
            label: "",
            id: 11,
          },
          {
            value: 0,
            code: "label_december",
            label: "",
            id: 12,
          },
        ],
        pie: [],
      },
    };
  },
  created() {
    for (let i = 0; i < this.types.length; i++) {
      this.types[i].label = this.$t(this.types[i].code);
    }
    for (let i = 0; i < this.monthsOfYear.length; i++) {
      this.monthsOfYear[i].label = this.$t(this.monthsOfYear[i].code);
    }
    let today = new Date();
    this.now.month = today.getMonth();
    this.selectedYear = this.now.year = today.getFullYear();

    this.arrayYear.push({
      id: today.getFullYear(),
      label: today.getFullYear(),
    });
    this.arrayMonth = this.monthsOfYear.filter(
      (x) => x.id <= this.now.month + 1
    );
    this.getAllSatelliteType();
    this.getStatistic();
  },
  watch: {
    lang(val) {
      for (let i = 0; i < this.types.length; i++) {
        this.types[i].label = this.$t(this.types[i].code);
      }
      for (let i = 0; i < this.monthsOfYear.length; i++) {
        this.monthsOfYear[i].label = this.$t(this.monthsOfYear[i].code);
      }
    },
    selectedType(val) {
      switch (val) {
        case "month":
          this.selectedMonth = this.now.month + 1;
          this.selectedYear = this.now.year;
          let itemYear = this.arrayYear.find(
            (x) =>
              (x.id + "").toLowerCase() === (this.now.year + "").toLowerCase()
          );
          if (itemYear && itemYear.months) {
            this.arrayMonth = this.monthsOfYear.filter((x) =>
              itemYear.months.includes(x.id)
            );
          } else {
            this.arrayMonth = this.monthsOfYear.filter(
              (x) => x.id <= this.now.month + 1
            );
          }
          break;
        case "year":
          this.selectedMonth = null;
          this.arrayMonth = [];
          break;
        default:
          this.selectedMonth = null;
          this.selectedYear = null;
          break;
      }
    },
  },
  methods: {
    formatNumber(value) {
      try {
        return value ? value.format() : 0;
      } catch (error) {
        return null;
      }
    },
    monthChange() {},
    yearChange() {
      if (parseInt(this.selectedYear) < this.now.year) {
        this.arrayMonth = this.monthsOfYear;
        this.selectedMonth = this.now.month + 1;
      } else if (parseInt(this.selectedYear) === this.now.year) {
        this.arrayMonth = this.monthsOfYear.filter(
          (x) => x.id <= this.now.month + 1
        );
        this.selectedMonth = this.now.month + 1;
      } else {
        this.arrayMonth = [];
        this.selectedMonth = null;
      }
    },
    initDataBarChart() {
      let dataChart = [];
      if (this.selectedType === "year") {
        this.data.chart = [];
        if (
          this.statistic.data &&
          Object.keys(this.statistic.data).length > 0
        ) {
          if (
            this.statistic.data.order &&
            this.statistic.data.order.length > 0
          ) {
            let data = this.statistic.data.order.filter(
              (x) =>
                (x.time.year + "").toLowerCase() ===
                (this.selectedYear + "").toLowerCase()
            );
            if (data && data.length > 0) {
              for (let i = 0; i < this.monthsOfYear.length; i++) {
                let items = data.filter(
                  (x) => x.time.month === this.monthsOfYear[i].id
                );
                dataChart.push({
                  id: this.monthsOfYear[i].id,
                  code: this.monthsOfYear[i].code,
                  label: this.monthsOfYear[i].label,
                  value:
                    items && items.length > 0
                      ? items.reduce(
                          (sum, record) => sum + parseFloat(record["total"]),
                          0
                        )
                      : 0,
                });
              }
              // let months = [...new Set(data.map((x) => x.time.month))];
              // if (months && months.length > 0) {
              //   months.sort(function (a, b) {
              //     if (a < b) return -1;
              //     else if (a > b) return 1;
              //     return 0;
              //   });

              // for (let i = 0; i < months.length; i++) {
              //   let items = data.filter((x) => x.time.month === months[i]);
              //   let month = this.monthsOfYear.find((x) => x.id === months[i]);
              //   dataChart.push({
              //     id: months[i],
              //     code: month ? month.code : null,
              //     label: month && month.code ? this.$t(month.code) : null,
              //     value:
              //       items && items.length > 0
              //         ? items.reduce(
              //             (sum, record) => sum + parseFloat(record["total"]),
              //             0
              //           )
              //         : 0,
              //   });
              // }
              //}
            }
          }
        }
      }
      if (dataChart && dataChart.length > 0) this.data.chart = dataChart;
    },
    initDataPieChart() {},
    async getStatistic() {
      this.statistic.data = {};
      try {
        let response = await statisticFunc.getStatistic(
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.statistic.data = response.data ? response.data : {};
          this.initDataBarChart();
          if (
            this.statistic.data &&
            Object.keys(this.statistic.data).length > 0
          ) {
            if (
              this.statistic.data.order &&
              this.statistic.data.order.length > 0
            ) {
              let years = [
                ...new Set(this.statistic.data.order.map((x) => x.time.year)),
              ];
              if (years && years.length > 0) {
                years.sort(function (a, b) {
                  if (a < b) return 1;
                  else if (a > b) return -1;
                  return 0;
                });
                for (let i = 0; i < years.length; i++) {
                  let months = [
                    ...new Set(
                      this.statistic.data.order
                        .filter(
                          (x) =>
                            (x.time.year + "").toLowerCase() ===
                            (years[i] + "").toLowerCase()
                        )
                        .map((x) => x.time.month)
                    ),
                  ];
                  if (months && months.length > 0) {
                    months.sort(function (a, b) {
                      if (a < b) return -1;
                      else if (a > b) return 1;
                      return 0;
                    });
                  }
                  if (
                    (years[i] + "").toLowerCase() !=
                    (this.now.year + "").toLowerCase()
                  ) {
                    this.arrayYear.push({
                      id: years[i],
                      label: years[i],
                      months: months,
                    });
                  } else {
                    let index = this.arrayYear.findIndex(
                      (x) =>
                        (years[i] + "").toLowerCase() !=
                        (x.id + "").toLowerCase()
                    );
                    if (index >= 0) {
                      this.arrayYear[index].months = months;
                    }
                  }
                }
              }
            }
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getStatistic);
        }
      }
    },

    async getAllSatelliteType() {
      try {
        let response = await metaKeyFunc.getByKey(
          "IMAGE_TYPES",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.satelliteType.items =
            response.data && response.data.data ? response.data.data : [];
        }
      } catch (error) {
        if (error.response && error.response.status === 401)
          this.refreshToken(this.getAllSatelliteType);
      }
    },
    async getAllProvider() {
      try {
        let response = await metaKeyFunc.getByKey(
          "PROVIDER",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.provider.items =
            response.data && response.data.data ? response.data.data : [];

          this.provider.items.sort(function (a, b) {
            if (a.name < b.name) return -1;
            else if (a.name > b.name) return 1;
            return 0;
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 401)
          this.refreshToken(this.getAllProvider);
      }
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    style() {
      let card = {
        width: 576 - 2 * 16,
      };
      if (this.documentWidth < 576) {
        card.width = this.documentWidth - 1.5 * 16;
      } else if (this.documentWidth >= 576 && this.documentWidth < 768) {
        card.width = Math.round(this.documentWidth / 2 - 2 * 16);
      } else {
        let width = this.documentWidth / 5;
        if (width - 2 * 16 > 21 * 16) width = 21 * 16;
        else width = Math.round(width) - 2 * 16;
        card.width = width;
      }
      return card;
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
.dash-board {
  width: 100%;
  height: 100%;
  padding: 1rem 0.5rem;
  background: #f3f6f8;
  .item-dash-board {
    height: 6rem;
    padding: 0 0.5rem;
    .item-total-images {
      height: 100%;
      border-radius: 0.5rem;
      background-color: #f1416c;
      padding: 1rem;
      .background-logo {
        background-color: #f95980;
        border-radius: 8px;
      }
    }
    .item-total-shared {
      height: 100%;
      border-radius: 0.5rem;
      background-color: #6993ff;
      padding: 1rem;
      .background-logo {
        background-color: #88a9ff;
        border-radius: 8px;
      }
    }
    .item-total-ordered {
      height: 100%;
      border-radius: 0.5rem;
      background-color: #50cd89;
      padding: 1rem;
      .background-logo {
        background-color: #63dc9a;
        border-radius: 8px;
      }
    }

    .item-total-customers {
      height: 100%;
      border-radius: 0.5rem;
      background-color: #7239ea;
      padding: 1rem;
      .background-logo {
        background-color: #864efb;
        border-radius: 8px;
      }
    }
    .background-logo {
      width: 64px;
      height: 64px;
      color: white;
      text-align: center;
      display: table;
      vertical-align: middle;
      i {
        font-size: 1.5rem;

        text-align: center;
        display: table-cell;
        vertical-align: middle;
      }
    }
    .item-values {
      color: white;
      padding-left: 1rem;
      .value-number {
        font-family: Helvetica Neue;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 16px;
        padding-bottom: 0.5rem;
      }
    }
  }
  .chart-dash-board {
    padding: 1rem 0.5rem;
    width: 100%;
    .bar-chart {
      width: 100%;
      background-color: white;
      padding: 1.5rem 1rem;
      border-radius: 0.5rem;
    }
    .pie-chart {
      margin-top: 1rem;
      width: 100%;
      .pie-chart__left {
        background-color: white;
        padding: 1.5rem 1rem;
        border-radius: 0.5rem;
        margin-right: 0.5rem;
      }
      .pie-chart__right {
        background-color: white;
        padding: 1.5rem 1rem;
        border-radius: 0.5rem;
        margin-left: 0.5rem;
      }
    }

    .chart__header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      .chart__header-title {
        font-size: 18px;
        font-weight: 600;
      }
      .chart__header-selection {
        .col-dropdown {
          width: 5rem;
          padding-right: 0.25rem;
          &:last-child {
            padding-right: 0;
          }
          &.col-month {
            width: 7rem;
          }
        }
      }
    }
  }
}
</style>
