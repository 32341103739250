import axios from 'axios';
import basicApi from "@/utils/apis/basic";
import app from './../../main'
import { geoServiceApiURL } from "@/utils/commons/environment"

export default {
    async create(entity, access_token) {
      let headers = basicApi.image_sharing.create.headers;
      if(!headers)
        headers= {}
      headers["Authorization"] = "bearer " + access_token;
      headers['Accept-Language'] = app.$store.getters.getLang;
      const object =  axios({
        method: basicApi.image_sharing.create.method,
        url: geoServiceApiURL + basicApi.image_sharing.create.url,
        data: entity,
        headers: headers,
        params: {
          lang:app.$store.getters.getLang
        }
      });
  
      let response = await object;
      return response;
    },
    async downloadPreview(key, access_token) {
      let headers = basicApi.file.download_preview.headers;
      if(!headers)
        headers= {}
      headers["Authorization"] = "bearer " + access_token;
      headers["Access-Control-Expose-Headers"] = "Content-Disposition";
      headers['Accept-Language'] = app.$store.getters.getLang;
      const object =  axios({
        method: basicApi.file.download_preview.method,
        url:geoServiceApiURL + basicApi.file.download_preview.url.replace("{key}",key),
        responseType:'blob',
        headers: headers,
        params: {
          lang:app.$store.getters.getLang
        }
      });
  
      let response = await object;
      return response;
    },
    
    
    async download(password ,token, access_token) {
      let headers = basicApi.image_sharing.download.headers;
      if(!headers)
        headers= {}
      headers["Authorization"] = "bearer " + access_token;
      headers['Accept-Language'] = app.$store.getters.getLang;
      const object =  axios({
        method:basicApi.image_sharing.download.method,
        url:geoServiceApiURL + basicApi.image_sharing.download.url,
        responseType: 'blob',
        headers: headers,
        params:{
          password :password ,
          token:token,
          lang:app.$store.getters.getLang
        }
      });
  
      let response = await object;
      return response;
    },
    async download2(password ,token,image_id, access_token) {
      let headers = basicApi.image_sharing.download.headers;
      if(!headers)
        headers= {}
      headers["Authorization"] = "bearer " + access_token;
      headers['Accept-Language'] = app.$store.getters.getLang;
      const object =  axios({
        method:basicApi.image_sharing.download.method,
        url:geoServiceApiURL + basicApi.image_sharing.download.url,
        //responseType: 'blob',
        headers: headers,
        params:{
          password :password ,
          token:token,
          image_id:image_id,
          lang:app.$store.getters.getLang
        }
      });
  
      let response = await object;
      return response;
  },
    
  async getAll(access_token,data){
        let headers = basicApi.image_sharing.get_all.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
          method: basicApi.image_sharing.get_all.method,
          url:  geoServiceApiURL + basicApi.image_sharing.get_all.url,
          params: {
            page: 0,
            size:2147483647,
            lang:app.$store.getters.getLang
          },
          data:data,
          headers:headers,
        });
        let response = await object;
        return response;
    },
    
  async getByPage(params,access_token, data){
    let headers = basicApi.image_sharing.get_by_page.headers;
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    if (!params) params = {}
    params.lang = app.$store.getters.getLang;
        const object = axios({
          method: basicApi.image_sharing.get_by_page.method,
          url:  geoServiceApiURL + basicApi.image_sharing.get_by_page.url,
          params: params,
          data:data,
          headers:headers,
        });
        let response = await object;
        return response;
    },
    async getById(id,access_token){
        let headers = basicApi.image_sharing.get_by_id.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
          method: basicApi.image_sharing.get_by_id.method,
          url:  geoServiceApiURL + basicApi.image_sharing.get_by_id.url.replace('{id}',id),
          headers:headers,
        params: {
          lang:app.$store.getters.getLang
        }
        });
        let response = await object;
        return response;
  },
    
    async delete(id,access_token){
      let headers = basicApi.image_sharing.delete.headers;
      headers["Authorization"] = "bearer " + access_token;
      headers['Accept-Language'] = app.$store.getters.getLang;
      const object = axios({
        method: basicApi.image_sharing.delete.method,
        url:  geoServiceApiURL + basicApi.image_sharing.delete.url.replace('{id}',id),
        headers:headers,
        params: {
          lang:app.$store.getters.getLang
        }
      });
      let response = await object;
      return response;
    },
    async removeUser(share_id,user_id,access_token){
      let headers = basicApi.image_sharing.remove_user.headers;
      headers["Authorization"] = "bearer " + access_token;
      headers['Accept-Language'] = app.$store.getters.getLang;
      const object = axios({
        method: basicApi.image_sharing.remove_user.method,
        url: geoServiceApiURL + basicApi.image_sharing.remove_user.url,
        params: {
          share_id: share_id,
          user_id :user_id ,
          lang:app.$store.getters.getLang
        },
        headers:headers,
      });
      let response = await object;
      return response;
    },
}