<template>
  <Dialog
    :header="$t('title_update_status')"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :modal="true"
    :contentStyle="propStyle"
    @hide="onClose"
  >
    <div class="p-grid m-0">
      <div class="p-field p-col-12 form-row m-0">
        <!-- <label class="p-col-fixed" :style="styleLabel">{{ propTitle }}</label>
        <Textarea
          :value="propValue"
          :autoResize="true"
          :style="styleControl"
          rows="5"
          maxLength="255"
          readonly
        /> -->
        <p class="mb-0">{{ propValue }}</p>
      </div>
    </div>
    <template #footer>
      <Button
        :label="$t('button_text_close')"
        :icon="'fas fa-times'"
        :class="'p-button-danger mr-0'"
        @click="onClose"
      />
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
import Button from "@/components/commons/button/Button";
import Textarea from "@/components/commons/textarea/Textarea";
export default {
  components: {
    Dialog,
    Button,
    Textarea,
  },
  props: {
    propValue: {
      type: String,
      default: "",
    },
    propTitle: {
      type: String,
      default: "",
    },
    propStyle: {
      type: Object,
      default: () => {
        return {
          width: "480px",
        };
      },
    },
    propShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  created() {
    this.show = this.propShow;
  },
  watch: {
    propShow: {
      handler(val) {
        this.show = this.propShow;
      },
      immediate: true,
    },
  },
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    styleLabel() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:135px";
    },
    styleLabelCheckbox() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:128px";
    },
    styleControl() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:calc(100% - 135px)";
    },
  },
  methods: {
    onClose() {
      this.show = false;
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
</style>