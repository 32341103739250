<template>
  <div class="shapefile-map" id="shapefilemap"></div>
</template>
<script>
import Map from "ol/Map";
import View from "ol/View";
import { Tile as TileLayer } from "ol/layer";
import { TileImage } from "ol/source";
import { defaults as defaultControls, FullScreen } from "ol/control";
import { fromLonLat, transform } from "ol/proj";
import Feature from "ol/Feature";
import Polygon from "ol/geom/Polygon";
import { Vector as VectorLayer, Image as ImageLayer } from "ol/layer";
import { Vector as VectorSource, ImageCanvas } from "ol/source";
import { getCenter, getTopLeft } from "ol/extent";
import {
  Style,
  Fill,
  Stroke,
  Circle,
  Icon,
  Text,
  RegularShape,
} from "ol/style.js";
import GeoJSON from "ol/format/GeoJSON";

export default {
  props: {
    propEntity: {
      type: Object,
      default: () => {
        return {
          fill: "#ffffff",
          fill_opacity: 1,
          stroke: "#a6a6a6",
          stroke_opacity: 1,
        };
      },
    },
  },
  data() {
    return {
      map: Object,
      layer: Object,
      tileLayer: Object,
    };
  },
  created() {},
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      var vm = this;
      var source = new VectorSource({ wrapX: false, id: "sourcedraw" });

      this.layer = new VectorLayer({
        source: source,
        style: new Style({
          fill: new Fill({
            color: vm.hexToRgbA(
              vm.propEntity.fill,
              parseFloat(vm.propEntity.fill_opacity)
            ),
          }),
          stroke: new Stroke({
            color: vm.hexToRgbA(
              vm.propEntity.stroke,
              parseFloat(vm.propEntity.stroke_opacity)
            ),
            width: 1.5,
          }),
        }),
      });
      this.layer.set("id", "layerdraw");
      this.tileLayer = new TileLayer({
        title: "Basemap",
        id: "tileLayer",
        source: new TileImage({
          url: null,
        }),
        show: false,
        visible: false,
      });
      this.map = new Map({
        controls: defaultControls({ attribution: false, zoom: false }),
        layers: [this.tileLayer, this.layer],
        target: "shapefilemap",
        view: new View({
          center: fromLonLat(
            vm.propPoints && vm.propPoints.length > 0
              ? vm.propPoints[0]
              : [108.222848, 16.062833]
          ),
          zoom: 8,
          minZoom: 0,
          maxZoom: 20,
        }),
      });
    },
    updateTileUrl(url) {
      if (this.layer && Object.keys(this.layer).length > 0) {
        this.layer.setVisible(false);
      }
      if (this.tileLayer && Object.keys(this.tileLayer).length > 0) {
        this.tileLayer.setVisible(true);
        this.tileLayer.getSource().setUrl(url);
      }
    },
    addFeatures(features) {
      if (this.layer && Object.keys(this.layer).length > 0) {
        this.layer.setVisible(true);
        for (let i = 0; i < features.length; i++) {
          this.layer.getSource().addFeature(features[i]);
          if (i === features.length - 1) {
            var ext = features[i].getGeometry().getExtent();
            this.map.getView().fit(ext, {
              size: this.map.getSize(),
            });
            var center = getCenter(ext);
            this.map.getView().animate({
              center: [center[0], center[1]],
              duration: 100,
              zoom: this.map.getView().getZoom(),
            });
            this.map.updateSize();
          }
        }
      }
      if (this.tileLayer && Object.keys(this.tileLayer).length > 0) {
        this.tileLayer.setVisible(false);
      }
    },
    changeStyle(color) {
      var vm = this;
      if (this.layer && Object.keys(this.layer).length > 0) {
        this.layer.setVisible(true);
        this.layer.setStyle(
          new Style({
            fill: new Fill({
              color: vm.hexToRgbA(color.fill, parseFloat(color.fill_opacity)),
            }),
            stroke: new Stroke({
              color: vm.hexToRgbA(
                color.stroke,
                parseFloat(color.stroke_opacity)
              ),
              width: 1.5,
            }),
          })
        );
      }

      if (this.tileLayer && Object.keys(this.tileLayer).length > 0) {
        this.tileLayer.setVisible(false);
      }
    },
    hexToRgbA(hex, opacity) {
      if (opacity === null || opacity === undefined) opacity = "0.4";
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.shapefile-map {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>