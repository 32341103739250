import axios from 'axios';
import basicApi from "@/utils/apis/basic";
import app from './../../main'
import { userServiceApiURL } from "@/utils/commons/environment"
export default {
    async save(entity,access_token){
        const object = axios({
            method:basicApi.sharing.save.method,
            url: userServiceApiURL + basicApi.sharing.save.url,
            data:entity,
            headers: {
                "Authorization": "bearer " + access_token,
                'Accept-Language':app.$store.getters.getLang
            },
            params: {
                lang : app.$store.getters.getLang
            }
        });

        let response = await object;
        return response;
    },
    async findByImage(id,access_token){
        const object = axios({
            method:basicApi.sharing.find_by_image_id.method,
            url: userServiceApiURL + basicApi.sharing.find_by_image_id.url.replace("{id}",id),
            headers: {
                "Authorization": "bearer " + access_token,
                'Accept-Language':app.$store.getters.getLang
            },
            params: {
                lang : app.$store.getters.getLang
            }
        });
        let response = await object;
        return response;
    },
    async delete(id,access_token){
        const object = axios({
            method:basicApi.sharing.delete.method,
            url: userServiceApiURL + basicApi.sharing.delete.url.replace("{id}",id),
            headers: {
                "Authorization": "bearer " + access_token,
                'Accept-Language':app.$store.getters.getLang
            },
            params: {
                lang : app.$store.getters.getLang
            }
        });
        let response = await object;
        return response;
    },
    async myShareSatellite(search,access_token){
        const object = axios({
            method:basicApi.sharing.my_shared_satellite.method,
            url: userServiceApiURL + basicApi.sharing.my_shared_satellite.url,
            params:{
                page:search.page,
                size:search.size,
                image_name: search.image_name,                
                lang : app.$store.getters.getLang
            },  
            headers: {
                "Authorization": "bearer " + access_token,
                'Accept-Language':app.$store.getters.getLang
            },
        });
        let response = await object;
        return response;
    },
    async myShareOrthoImage(search,access_token){
        const object = axios({
            method:basicApi.sharing.my_shared_orthoimage.method,
            url: userServiceApiURL + basicApi.sharing.my_shared_orthoimage.url,
            params:{
                page:search.page,
                size:search.size,
                image_name: search.image_name,
                lang : app.$store.getters.getLang
            },  
            headers: {
                "Authorization": "bearer " + access_token,
                'Accept-Language':app.$store.getters.getLang
            },
        });
        let response = await object;
        return response;
    },
    async myShareRadar(search,access_token){
        const object = axios({
            method:basicApi.sharing.my_shared_radar.method,
            url: userServiceApiURL + basicApi.sharing.my_shared_radar.url,
            params:{
                page:search.page,
                size:search.size,
                image_name: search.image_name,
                lang : app.$store.getters.getLang
            },  
            headers: {
                "Authorization": "bearer " + access_token,
                'Accept-Language':app.$store.getters.getLang
            },
        });
        let response = await object;
        return response;
    },
}