<template>
  <error401></error401>
</template>
<script>
import Error401 from "@/components/pages/error/401";
export default {
  name: "NotAuthorized",
  components: {
    Error401,
  },
  created() {},
};
</script>
