<template>
  <Dialog
    :header="propEntity.id ? $t('title_edit_field') : $t('title_add_field')"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :modal="true"
    :contentStyle="{
      width: propStyle.width,
      overflowY: 'inherit!important',
    }"
    @hide="onClose"
  >
    <div class="specifications__detail">
      <ValidationObserver ref="specificationObserver">
        <form>
          <ValidationProvider
            :name="$t('label_field_name')"
            :rules="'required'"
            persist
          >
            <div class="form-group">
              <label
                >{{ $t("label_field_name")
                }}<span class="required">&nbsp;*</span></label
              >
              <InputText
                type="text"
                class="form-control new-style w-100"
                v-model="entity.name"
                maxlength="50"
              />
            </div>
          </ValidationProvider>
          <ValidationProvider
            :name="$t('label_key')"
            :rules="'required'"
            persist
          >
            <div class="form-group">
              <label
                >{{ $t("label_key")
                }}<span class="required">&nbsp;*</span></label
              >
              <InputText
                type="text"
                class="form-control new-style w-100"
                v-model="entity.key"
                maxlength="50"
              />
            </div>
          </ValidationProvider>
          <ValidationProvider
            :name="$t('label_pattern')"
            :rules="'required'"
            persist
          >
            <div class="form-group">
              <label
                >{{ $t("label_pattern")
                }}<span class="required">&nbsp;*</span></label
              >
              <input
                type="text"
                class="form-control new-style w-100"
                v-model="entity.pattern"
                maxlength="512"
              />
            </div>
          </ValidationProvider>
          <div class="form-group">
            <label>{{ $t("label_data_type") }}</label>

            <Dropdown
              class="w-100 new-style"
              v-model="entity.format_data"
              :showClear="false"
              :options="dataTypes"
              optionValue="value"
              optionLabel="label"
            />
          </div>

          <div class="form-group">
            <label>{{ $t("label_unit") }}</label>
            <InputText
              type="text"
              class="form-control new-style w-100"
              v-model="entity.unit"
              maxlength="50"
            />
          </div>
          <div class="form-group">
            <Checkbox v-model="entity.active" :binary="true" />
            <label>&nbsp;{{ $t("label_is_active") }}</label>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <template #footer>
      <Button
        :disabled="propButton && propButton.disabled"
        :label="propEntity.id ? $t('title_edit_field') : $t('title_add_field')"
        :icon="
          propButton && propButton.processing ? 'fas fa-spinner fa-spin' : ''
        "
        :class="'p-button-success p-button-sm mr-0'"
        @click="onSaveSpecification"
      />
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
import Button from "@/components/commons/button/Button";
import InputText from "@/components/commons/inputtext/InputText";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import Checkbox from "@/components/commons/checkbox/Checkbox";
export default {
  components: {
    Dialog,
    Button,
    InputText,
    Dropdown,
    Checkbox,
  },
  props: {
    propEntity: {
      type: Object,
      required: true,
    },
    propShow: {
      type: Boolean,
      default: false,
    },
    propStyle: {
      type: Object,
      required: true,
    },
    propButton: {
      type: Object,
    },
  },
  data() {
    return {
      show: false,
      entity: {
        active: true,
        format_data: null,
        key: null,
        name: null,
        pattern: null,
        unit: null,
        id: null,
        gen_id: null,
      },
      dataTypes: [
        {
          value: "String",
          label: "String",
        },
        {
          value: "Decimal",
          label: "Decimal",
        },
        {
          value: "Datetime",
          label: "Datetime",
        },
        {
          value: "Object",
          label: "Object",
        },
        {
          value: "Array",
          label: "Array",
        },
      ],
    };
  },
  created() {
    this.show = this.propShow;
    if (this.propEntity && Object.keys(this.propEntity).length > 0) {
      this.entity.id = this.propEntity.id;
      this.entity.active = this.propEntity.active
        ? this.propEntity.active
        : false;

      this.entity.name = this.propEntity.name
        ? this.propEntity.name.trim().replace(/\s\s+/g, " ")
        : null;
      this.entity.key = this.propEntity.key
        ? this.propEntity.key.trim().replace(/\s\s+/g, " ")
        : null;
      this.entity.pattern = this.propEntity.pattern
        ? this.propEntity.pattern.trim().replace(/\s\s+/g, " ")
        : null;
      this.entity.format_data = this.propEntity.format_data
        ? this.propEntity.format_data.trim().replace(/\s\s+/g, " ")
        : null;
      this.entity.unit = this.propEntity.unit
        ? this.propEntity.unit.trim().replace(/\s\s+/g, " ")
        : null;
      this.entity.gen_id = this.propEntity.gen_id
        ? this.propEntity.gen_id
        : new Date().getTime();
    }
  },
  watch: {
    propShow(val) {
      this.show = this.propShow;
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
      this.show = false;
    },
    onSaveSpecification() {
      var vm = this,
        message = [];
      this.$refs.specificationObserver.validate().then(async (success) => {
        if (!success) {
          setTimeout(() => {
            Object.keys(vm.$refs.specificationObserver.errors).forEach(
              function (key) {
                if (
                  vm.$refs.specificationObserver.errors[key] &&
                  vm.$refs.specificationObserver.errors[key].length
                )
                  message.push(
                    vm.$refs.specificationObserver.errors[key].join(",\n")
                  );
              }
            );
            if (message && message.length > 0) this.$emit("message", message);
          }, 100);
          return;
        } else {
          this.entity.name = this.entity.name
            ? this.entity.name.trim().replace(/\s\s+/g, " ")
            : null;

          this.entity.key = this.entity.key
            ? this.entity.key.trim().replace(/\s\s+/g, " ")
            : null;

          this.entity.format_data = this.entity.format_data
            ? this.entity.format_data.trim().replace(/\s\s+/g, " ")
            : null;

          this.entity.unit = this.entity.unit
            ? this.entity.unit.trim().replace(/\s\s+/g, " ")
            : null;

          this.entity.pattern = this.entity.pattern
            ? this.entity.pattern.trim().replace(/\s\s+/g, " ")
            : null;

          this.$emit("saveSpecification", this.entity);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.form-group {
  margin-bottom: 0.5rem;
  label {
    margin: 0;
  }
}
</style>