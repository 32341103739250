<template>
  <CommonAccordion :propTitle="$t('title_data_specification_structure')">
    <div slot="content">
      <div class="p-grid m-0">
        <div class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed" :style="styleLabel">
            {{ $t("label_name") }}&nbsp;
            <span class="font-weight-bold required">*</span>
          </label>
          <ValidationProvider :name="$t('label_name')" rules="required">
            <InputText
              type="text"
              :style="styleControl"
              maxlength="100"
              v-model="propEntity.name"
              :disabled="!propStatus.isAdd && !propStatus.isEdit"
              class="new-style"
            />
          </ValidationProvider>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed" :style="styleLabel">
            {{ $t("label_script") }}&nbsp;
          </label>
          <InputText
            type="text"
            :style="styleControl"
            maxlength="100"
            v-model="propEntity.script"
            :disabled="!propStatus.isAdd && !propStatus.isEdit"
            class="new-style"
          />
        </div>
        <div class="p-field p-col-12 p-md-12">
          <CommonAccordion2
            :isChevronEnd="true"
            :propTitle="$t('label_fields')"
            :isCollapse="false"
          >
            <div class="datatable" slot="content">
              <DataTable
                :value="propEntity.data"
                ref="dt"
                :scrollable="true"
                :resizableColumns="false"
                contextMenu
                selectionMode="single"
                dataKey="id"
              >
                <template #header>
                  <div class="text-left">
                    <div>
                      <div
                        v-if="propEntity.data.length"
                        class="font-weight-normal"
                      >
                        {{
                          $t("label_show_from_to", null, {
                            from: 1,
                            to: propEntity.data.length,
                            total: propEntity.data.length,
                          })
                        }}
                      </div>
                    </div>
                  </div>
                </template>
                <template
                  #empty
                  v-if="propEntity.data && propEntity.data.length === 0"
                >
                  <span class="text-center">{{ $t("label_no_data") }}</span>
                </template>
                <Column
                  header="No"
                  headerStyle="width: 3rem; text-align: center!important"
                  bodyStyle="width: 3rem; text-align: center;"
                >
                  <template #body="slotProps">
                    {{ slotProps.index + 1 }}
                  </template></Column
                >
                <Column
                  field="active"
                  :header="$t('label_active')"
                  headerStyle="width: 6rem;"
                  bodyStyle="width: 6rem;text-align: center;"
                  ><template #body="slotProps">
                    <i
                      class="fas fa-check text-success"
                      v-if="slotProps.data.active"
                    ></i>
                  </template>
                </Column>
                <Column
                  field="key"
                  :header="$t('label_key')"
                  headerStyle="width: 15rem;"
                  bodyStyle="width: 15rem"
                >
                </Column>
                <Column
                  field="name"
                  :header="$t('label_field_name')"
                  headerStyle="width: 15rem;"
                  bodyStyle="width: 25rem"
                ></Column>
                <Column
                  field="pattern"
                  :header="$t('label_pattern')"
                  headerStyle="width: 35rem;"
                  bodyStyle="width: 35rem"
                >
                  <template #body="slotProps">
                    <span
                      :title="
                        slotProps.data.pattern &&
                        slotProps.data.pattern.length > 70
                          ? slotProps.data.pattern
                          : null
                      "
                      >{{
                        slotProps.data.pattern &&
                        slotProps.data.pattern.length > 70
                          ? slotProps.data.pattern.substring(0, 70) + "..."
                          : slotProps.data.pattern
                      }}</span
                    ></template
                  ></Column
                >
                <Column
                  field="format_data"
                  :header="$t('label_data_type')"
                  headerStyle="width: 15rem;"
                  bodyStyle="width: 15rem"
                ></Column>
                <Column
                  field="unit"
                  :header="$t('label_unit')"
                  headerStyle="width: 15rem;"
                  bodyStyle="width: 15rem"
                ></Column>
                <Column
                  headerStyle="width: 6rem;text-align: center!important "
                  bodyStyle="width: 6rem;text-align: center"
                  :headerClass="'p-frozen-column right'"
                  :bodyClass="'p-frozen-column right'"
                >
                  <template #header>
                    <button
                      v-if="propStatus.isAdd || propStatus.isEdit"
                      icon="pi pi-plus"
                      class="btn btn-primary btn-sm"
                      @click="addSpecification"
                      :disabled="!propStatus.isAdd && !propStatus.isEdit"
                    >
                      <i class="fas fa-plus"></i>
                    </button>
                  </template>
                  <template #body="slotProps">
                    <button
                      :disabled="!propStatus.isAdd && !propStatus.isEdit"
                      icon="fas fa-edit"
                      type="button"
                      class="btn btn-primary btn-sm mr-1"
                      :title="$t('button_text_edit')"
                      @click="editSpecification(slotProps.data)"
                      v-if="propStatus.isAdd || propStatus.isEdit"
                    >
                      <i class="fas fa-edit"></i>
                    </button>
                    <button
                      :disabled="!propStatus.isAdd && !propStatus.isEdit"
                      icon="fas fa-trash"
                      type="button"
                      class="btn btn-danger btn-sm"
                      :title="$t('button_text_remove')"
                      @click="removeSpecification(slotProps.data)"
                      v-if="propStatus.isAdd || propStatus.isEdit"
                    >
                      <i class="fas fa-trash"></i></button></template
                ></Column>
              </DataTable>
            </div>
          </CommonAccordion2>
        </div>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import CommonAccordion2 from "@/components/commons/accordion/Accordion2";
import InputText from "@/components/commons/inputtext/InputText";
import Checkbox from "@/components/commons/checkbox/Checkbox";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import Button from "@/components/commons/button/Button";
export default {
  components: {
    CommonAccordion,
    InputText,
    Checkbox,
    DataTable,
    Column,
    Button,
    CommonAccordion2,
  },
  props: {
    propEntity: {
      type: Object,
      default: () => {},
    },

    propStatus: {
      type: Object,
      default: () => {
        return {
          isAdd: false,
          isEdit: false,
        };
      },
    },
  },
  methods: {
    removeSpecification(item) {
      this.$emit("removeSpecification", item);
    },
    editSpecification(item) {
      this.$emit("showDialogSpecification", item);
    },
    addSpecification() {
      this.$emit("showDialogSpecification");
    },
  },
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    styleLabel() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:120px";
    },
    styleLabelCheckbox() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:128px";
    },
    styleControl() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:calc(100% - 120px)";
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>

<style lang="scss">
.data-specification__structure {
  @import "@/assets/scss/_datatable.scss";
}
</style>