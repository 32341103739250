<template>
  <CommonAccordion :propTitle="$t('title_action_logs_list')">
    <div slot="content">
      <div class="datatable">
        <DataTable
          :value="propItems"
          ref="dt"
          :paginator="propItems && propItems.length > 10"
          :loading="propLoading"
          :rows="10"
          :resizableColumns="true"
          columnResizeMode="expand"
          selectionMode="single"
          dataKey="id"
          :selection.sync="propTable.selected"
          @row-click="rowClick"
          :first="propFirst"
          @page="pageChange"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll"
          :currentPageReportTemplate="$t('label_showing') + ' {first} '+$t('label_to').toLowerCase()+' {last} ' +$t('label_of').toLowerCase()+ ' {totalRecords}'"
        >
          <template #header v-if="propItems && propItems.length <= 10">
            <div class="text-left">
              <div>
                <div v-if="propItems.length" class="font-weight-normal">
                  {{
                    $t("label_show_from_to", null, {
                      from: 1,
                      to: propItems.length,
                      total: propItems.length,
                    })
                  }}
                </div>
              </div>
            </div>
          </template>
          <template #empty v-if="propItems && propItems.length === 0">
            <span class="text-center">{{ $t("label_no_data") }}</span>
          </template>
          <Column
            header="No"
            headerStyle="width: 3rem; text-align: center!important"
            bodyStyle="text-align: center;"
          >
            <template #body="slotProps">
              {{ propTable.page * propTable.size + slotProps.index + 1 }}
            </template></Column
          >
          <Column field="name" :header="$t('label_code')"></Column>
          <Column :header="$t('label_name_vietnamese')">
            <template #body="slotProps">
              {{
                slotProps.data.translation && slotProps.data.translation.vi
                  ? slotProps.data.translation.vi.value
                  : ""
              }}
            </template>
          </Column>
          <Column :header="$t('label_name_english')">
            <template #body="slotProps">
              {{
                slotProps.data.translation && slotProps.data.translation.en
                  ? slotProps.data.translation.en.value
                  : ""
              }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import Button from "@/components/commons/button/Button";
export default {
  components: {
    DataTable,
    Column,
    Button,
    CommonAccordion,
  },
  props: {
    propItems: {
      type: Array,
      default: () => [],
    },
    propLoading: {
      type: Boolean,
      default: false,
    },
    propTable: {
      type: Object,
      default: () => {},
    },
    propFirst: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    rowClick(val) {
      this.$emit("rowClick", val);
    },
    pageChange(e) {
      this.propTable.page = e.page;
      this.propTable.size = e.rows;
    },
  },
};
</script>
<style lang="scss">
.actions-log__datatable {
  .datatable {
    @import "@/assets/scss/_datatable.scss";
    .p-paginator {
      border: transparent;
      background: transparent;
    }
  }
}
</style>
