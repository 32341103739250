<template>
  <div class="" slot="slot-content">
    <spinner
      :propLoading="loading.show"
      :propShape="loading.shape"
      v-if="loading.show"  :prop-text="loading.text"
    ></spinner>
    <organisation-page></organisation-page>
  </div>
</template>
<script>
import Spinner from "@/components/commons/spinners/Index";
import OrganisationPage from "@/components/pages/organisation/Organisation";
export default {
  name: "organisation",
  components: {
    OrganisationPage,
    Spinner,
  },
  created() {},
  computed: {
    loading() {
      return this.$store.getters.getSpinnerApp;
    },
  },
};
</script>