<template>
  <Dialog
    :header="$t('title_basemaps')"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :maximizable="false"
    :modal="true"
    :contentStyle="propStyle"
    @hide="onClose"
  >
    <div>
      <div class="form-group d-flex">
        <label :style="styleLabel">{{ $t("label_type") }}</label>
        <Dropdown
          style="width: calc(100% - 100px)"
          v-model="entity.type"
          :options="types"
          optionValue="id"
          optionLabel="label"
          datakey="id"
        />
      </div>
      <div class="form-group">
        <label :style="styleLabel"
          >{{ $t("label_layer_name") }}&nbsp;<span class="required"
            >*</span
          ></label
        >
        <ValidationProvider :name="$t('label_layer_name')" rules="required">
          <InputText
            v-model="entity.name"
            :style="styleControl"
            maxLength="150"
          />
        </ValidationProvider>
      </div>
      <div class="form-group d-flex" v-if="entity.type === 'shapefile'">
        <label :style="styleLabel" class="pt-2"
          >{{ $t("label_fill") }}&nbsp;<span class="required">*</span></label
        >
        <div style="width: calc(100% - 100px)">
          <ValidationProvider :name="$t('label_fill')" rules="required">
            <InputText
              v-model="entity.fill"
              :style="styleControl"
              type="color"
              class="form-control w-100"
              @change="colorChanged"
            />
          </ValidationProvider>
        </div>
      </div>
      <div class="form-group d-flex" v-if="entity.type === 'shapefile'">
        <label :style="styleLabel"
          >{{ $t("label_fill_opacity") }}&nbsp;<span class="required"
            >*</span
          ></label
        >
        <div style="width: calc(100% - 100px)">
          <ValidationProvider :name="$t('label_fill_opacity')" rules="required">
            <input
              v-model="entity.fill_opacity"
              :style="styleControl"
              type="range"
              min="0"
              max="1"
              step="0.01"
              class="w-100"
              @change="colorChanged"
            />
          </ValidationProvider>
        </div>
      </div>
      <div class="form-group d-flex" v-if="entity.type === 'shapefile'">
        <label :style="styleLabel" class="pt-2"
          >{{ $t("label_stroke") }}&nbsp;<span class="required">*</span></label
        >
        <div style="width: calc(100% - 100px)">
          <ValidationProvider :name="$t('label_stroke')" rules="required">
            <InputText
              v-model="entity.stroke"
              :style="styleControl"
              type="color"
              class="form-control w-100"
              @change="colorChanged"
            />
          </ValidationProvider>
        </div>
      </div>
      <div class="form-group d-flex" v-if="entity.type === 'shapefile'">
        <label :style="styleLabel"
          >{{ $t("label_stroke_opacity") }}&nbsp;<span class="required"
            >*</span
          ></label
        >
        <div style="width: calc(100% - 100px)">
          <ValidationProvider
            :name="$t('label_stroke_opacity')"
            rules="required"
          >
            <input
              v-model="entity.stroke_opacity"
              :style="styleControl"
              type="range"
              min="0"
              max="1"
              step="0.01"
              class="w-100"
              @change="colorChanged"
            />
          </ValidationProvider>
        </div>
      </div>
      <div class="form-group" v-if="entity.type === 'tile'">
        <label :style="styleLabel"
          >{{ $t("label_path") }}&nbsp;<span class="required">*</span></label
        >
        <ValidationProvider :name="$t('label_path')" rules="required">
          <InputText
            v-model.lazy="entity.path"
            :style="styleControl"
            maxLength="255"
            @change="pathChanged"
          />
        </ValidationProvider>
      </div>
      <div class="form-group d-flex" v-else-if="entity.type === 'shapefile'">
        <label :style="styleLabel" class="pt-2"
          >{{ $t("label_path") }}&nbsp;<span class="required">*</span></label
        >
        <div style="width: calc(100% - 100px)">
          <ShapeFile @fileChanged="fileChanged" :prop-name="entity.path" />
        </div>
      </div>
      <div class="form-group">
        <label :style="styleLabelCheckbox">{{ $t("label_show") }}</label>
        <Checkbox v-model="entity.is_show" :binary="true" />
      </div>
      <div class="form-group config-map">
        <MapOpenlayer ref="shapefileMap" :propEntity="entity" />
      </div>
    </div>
    <template #footer>
      <Button
        :label="$t('button_text_close')"
        :icon="'fas fa-times'"
        :class="'p-button-danger p-button-sm '"
        @click="onClose"
      />
      <Button
        :label="$t('button_text_save')"
        :icon="'fas fa-times'"
        :class="'p-button-success p-button-sm mr-0'"
        @click="onSave"
      />
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
import Button from "@/components/commons/button/Button";
import InputText from "@/components/commons/inputtext/InputText";
import Checkbox from "@/components/commons/checkbox/Checkbox";
import ShapeFile from "./detail/ShapeFile";
import MapOpenlayer from "./detail/MapOpenlayer";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import GeoJSON from "ol/format/GeoJSON";
import { getFileExtension3 } from "@/utils/commons/common";
export default {
  components: {
    Dialog,
    Button,
    InputText,
    Checkbox,
    ShapeFile,
    MapOpenlayer,
    Dropdown,
  },
  props: {
    propShow: {
      type: Boolean,
      default: false,
    },
    propEntity: {
      type: Object,
      default: () => {},
    },
    propStyle: {
      type: Object,
      default: () => {},
    },
    propTitle: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      entity: {
        id: null,
        name: null,
        path: null,
        is_show: false,
        type: "tile",
        geojson: null,
        fill: "#ffffff",
        fill_opacity: 1,
        stroke: "#a6a6a6",
        stroke_opacity: 1,
      },
      types: [
        {
          id: "tile",
          label: "Tile map",
        },
        {
          id: "shapefile",
          label: "Shape file",
        },
      ],
    };
  },
  created() {
    this.show = this.propShow;
    this.entity = this.generateEntity();
    if (this.propEntity && Object.keys(this.propEntity).length > 0) {
      this.entity.id = this.propEntity.id ? this.propEntity.id : null;
      this.entity.type = this.propEntity.type ? this.propEntity.type : "tile";
      this.entity.name =
        this.propEntity.obj_layer && this.propEntity.obj_layer.name
          ? this.propEntity.obj_layer.name.trim()
          : null;
      this.entity.path =
        this.propEntity.obj_layer && this.propEntity.obj_layer.path
          ? this.propEntity.obj_layer.path.trim()
          : null;
      this.entity.is_show =
        (this.propEntity.obj_layer &&
          this.propEntity.obj_layer.is_show != null) ||
        this.propEntity.obj_layer.is_show
          ? this.propEntity.obj_layer.is_show
          : false;

      this.entity.fill = this.propEntity.obj_layer.fill
        ? this.propEntity.obj_layer.fill
        : "#ffffff";
      try {
        this.entity.fill_opacity = this.propEntity.obj_layer.fill_opacity
          ? parseFloat(this.propEntity.obj_layer.fill_opacity)
          : 1;
      } catch (error) {}

      this.entity.stroke = this.propEntity.obj_layer.stroke
        ? this.propEntity.obj_layer.stroke
        : "#a6a6a6";
      this.entity.geojson = this.propEntity.obj_layer.geojson
        ? this.propEntity.obj_layer.geojson
        : null;
      try {
        this.entity.stroke_opacity = this.propEntity.obj_layer.stroke_opacity
          ? parseFloat(this.propEntity.obj_layer.stroke_opacity)
          : 1;
      } catch (error) {}
      if (this.entity.type === "tile") {
        setTimeout(() => {
          if (this.$refs.shapefileMap)
            this.$refs.shapefileMap.updateTileUrl(this.entity.path);
        }, 200);
      } else if (this.entity.type === "shapefile") {
        setTimeout(() => {
          if (this.propEntity.obj_layer.geojson) {
            if (this.$refs.shapefileMap) {
              this.$refs.shapefileMap.addFeatures(
                new GeoJSON().readFeatures(this.propEntity.obj_layer.geojson, {
                  featureProjection: "EPSG:3857",
                })
              );
            }
          }
          if (this.$refs.shapefileMap)
            this.$refs.shapefileMap.changeStyle({
              fill: this.entity.fill,
              fill_opacity: this.entity.fill_opacity,
              stroke: this.entity.stroke,
              stroke_opacity: this.entity.stroke_opacity,
            });
        }, 200);
      }
    }
  },
  watch: {
    propShow: {
      handler(val) {
        this.show = val;
      },
      immediate: true,
    },
  },
  methods: {
    onClose() {
      this.show = false;
      this.$emit("close");
    },
    onSave() {
      this.$emit("onSave", this.entity);
    },
    pathChanged() {
      if (this.entity.type === "tile") {
        if (this.$refs.shapefileMap)
          this.$refs.shapefileMap.updateTileUrl(this.entity.path);
      }
    },
    colorChanged() {
      if (this.$refs.shapefileMap)
        this.$refs.shapefileMap.changeStyle({
          fill: this.entity.fill,
          fill_opacity: this.entity.fill_opacity,
          stroke: this.entity.stroke,
          stroke_opacity: this.entity.stroke_opacity,
        });
    },
    async fileChanged(e) {
      try {
        if (e) {
          this.entity.path = e.name;
          let extension = getFileExtension3(e.name);
          if (extension && extension.toLowerCase() === "zip") {
            try {
              let geojsonObject = await this.readZipFile(e);
              let features = new GeoJSON().readFeatures(geojsonObject, {
                featureProjection: "EPSG:3857",
              });
              if (features && features.length > 0) {
                this.entity.geojson = geojsonObject;
                if (this.$refs.shapefileMap)
                  this.$refs.shapefileMap.addFeatures(features);
              }
            } catch (error) {
              this.$emit("message", [
                this.$t("message_can_not_read_file", null, {
                  name: e.name ? e.name : null,
                }),
              ]);
            }
          } else {
            this.$emit("message", [
              this.$t("message_no_support_extension", null, {
                name: extension ? extension : null,
              }),
            ]);
          }
        }
      } catch (error) {}
    },

    async readZipFile(file) {
      return new Promise(function (resolve, reject) {
        try {
          var vm = this;
          var reader = new FileReader();
          reader.onload = function () {
            shp(this.result).then(function (geojson) {
              if (geojson) {
                resolve(geojson);
              } else resolve(null);
            });
          };
          reader.readAsArrayBuffer(file);
        } catch (error) {
          resolve(null);
        }
      });
    },
    generateEntity() {
      return {
        id: null,
        name: null,
        path: null,
        is_show: false,
        type: "tile",
        geojson: null,
        fill: "#ffffff",
        fill_opacity: 1,
        stroke: "#a6a6a6",
        stroke_opacity: 1,
      };
    },
  },
  computed: {
    styleLabel() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:100px";
    },
    styleControl() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:calc(100% - 100px)";
    },
    styleLabelCheckbox() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:100px";
    },
  },
  beforeDestroy() {
    this.entity = this.generateEntity();
  },
};
</script>
<style lang="scss" scoped>
.config-map {
  width: 100%;
  height: 300px;
  overflow-y: auto;
  border: 1px solid #eee;
  border-radius: 0.25rem;
  position: relative;
}
</style>