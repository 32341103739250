<template>
  <CommonAccordion :propTitle="$t('title_history')">
    <div slot="content">
      <div class="datatable">
        <DataTable
          :value="propItems"
          :sortOrder="1"
          :loading="propLoading"
          ref="dt"
          :resizableColumns="false"
          columnResizeMode="expand"
          selectionMode="single"
          dataKey="id"
          :scrollable="true"
          :scrollHeight="propTableHeight - 40 - 38 + 'px'"
          :virtualScroll="true"
          @virtual-scroll="onVirtualScroll"
        >
          <template #header>
            <div class="text-left">
              <div>
                <div v-if="propItems.length" class="font-weight-normal">
                  {{
                    $t("label_show_from_to", null, {
                      from: 1,
                      to: propItems.length,
                      total: propTotal,
                    })
                  }}
                </div>
              </div>
            </div>
          </template>
          <template #empty v-if="propItems && propItems.length === 0">
            <span class="text-center">{{ $t("label_no_data") }}</span>
          </template>
          <Column
            header="No"
            headerStyle="width: 3rem; text-align: center!important"
            bodyStyle="text-align: center;"
          >
            <template #body="slotProps">
              {{ slotProps.index + 1 }}
            </template></Column
          >

          <Column :header="$t('label_username')" headerStyle="width: 12rem">
            <template #body="slotProps">
              {{ slotProps.data.created_by }}
            </template></Column
          >
          <Column :header="$t('lable_date_time')" headerStyle="width: 12rem">
            <template #body="slotProps">
              {{ formatUnixDateTime2String(slotProps.data.created_at) }}
            </template>
          </Column>
          <Column :header="$t('label_action')">
            <template #body="slotProps">
              {{
                slotProps.data.obj_action
                  ? slotProps.data.obj_action.translation &&
                    slotProps.data.obj_action.translation[lang] &&
                    slotProps.data.obj_action.translation[lang].value
                    ? slotProps.data.obj_action.translation[lang].value
                    : slotProps.data.obj_action.value
                  : ""
              }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import Button from "@/components/commons/button/Button";
import ContextMenu from "@/components/commons/contextmenu/ContextMenu";
import moment from "moment";
import { formatSizeFile } from "@/utils/commons/common";
export default {
  components: { CommonAccordion, DataTable, Column, Button, ContextMenu },
  props: {
    propItems: {
      type: Array,
      default: () => [],
    },
    propLoading: {
      type: Boolean,
      default: false,
    },
    propTableHeight: {
      type: Number,
      default: 400,
    },
    propTotal: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {
    onVirtualScroll(e) {
      this.$emit("nextPage");
    },
    async onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.$emit("nextPage");
      }
    },
    calculateGroupTotal(name) {
      let total = 0;
      if (this.propItems) {
        for (let item of this.propItems) {
          if (item.date_str === name) {
            total++;
          }
        }
      }
      return total;
    },
    rowClass(data) {
      return data.data.is_metadata === "bg-success " ? "bg-danger" : "";
    },
    columnClass(val) {
      let result = "";
      if (val && Object.keys(val).length > 0) {
        if (val.data.is_metadata) return "bg-success txt-success";
        else return "bg-danger txt-error";
      }
      return;
    },
    formatUnixDateTime2String(value) {
      let result = "";
      if (value) {
        try {
          let date = new Date(value);
          result = moment
            .unix(date.getTime() / 1000)
            .format("HH:mm DD/MM/YYYY");
          result = result
            .replace(":", this.lang === "vi" ? "h" : ":")
            .replace(" ", this.lang === "vi" ? " ngày " : " ");
        } catch (error) {}
      }
      return result;
    },
    formatSizeFile(bytes, si = false) {
      return formatSizeFile(bytes, si);
    },
    shoDialogMetadata(item) {
      this.$emit("shoDialogMetadata", item);
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss">
.logs__datatable {
  .datatable {
    @import "@/assets/scss/_datatable.scss";
    .p-datatable-scrollable-header {
      background: white !important;
    }
    .text-status {
      color: white;
      margin: 0 auto;
    }
  }
}
</style>