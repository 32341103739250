<template>
  <CommonAccordion :propTitle="$t('title_image_list')">
    <div slot="content">
      <div class="datatable">
        <ContextMenu :model="menuModel" ref="cmGeoImgDatatable" />
        <DataTable
          :value="propItems"
          ref="dt"
          :loading="propLoading"
          :scrollable="true"
          :resizableColumns="false"
          :scrollHeight="propTable.height - 40 - 38 + 'px'"
          contextMenu
          :selection.sync="selected"
          @row-contextmenu="onRowContextMenu"
          selectionMode="single"
          dataKey="id"
          :virtualScroll="true"
          @virtual-scroll="onVirtualScroll"
        >
          <template #header>
            <div class="text-left">
              <div>
                <div v-if="propItems.length" class="font-weight-normal">
                  {{
                    $t("label_show_from_to", null, {
                      from: 1,
                      to: propItems.length,
                      total: propTotal,
                    })
                  }}
                </div>
              </div>
            </div>
          </template>
          <template #empty v-if="propItems && propItems.length === 0">
            <span class="text-center">{{ $t("no_data") }}</span>
          </template>
          <Column
            header="No"
            headerStyle="width: 3rem; text-align: center!important"
            bodyStyle="text-align: center;"
          >
            <template #body="slotProps">
              {{ slotProps.index + 1 }}
            </template></Column
          >
          <Column
            field="name"
            :header="$t('label_image_name')"
            bodyStyle="width: 32rem"
            headerStyle="width: 32rem"
          >
            <template #body="slotProps">
              <div :title="slotProps.data.name ? slotProps.data.name : null">
                {{ slotProps.data.name ? slotProps.data.name : null }}
              </div>
            </template></Column
          >
          <Column
            :header="$t('label_source_id')"
            bodyStyle="width: 32rem"
            headerStyle="width: 32rem"
          >
            <template #body="slotProps">
              <div
                :title="
                  slotProps.data.scene_id
                    ? slotProps.data.scene_id
                    : slotProps.data.metadata &&
                      slotProps.data.metadata.source_id
                    ? slotProps.data.metadata.source_id
                    : slotProps.data.metadata &&
                      slotProps.data.metadata.product_id
                    ? slotProps.data.metadata.product_id
                    : ''
                "
              >
                {{
                  slotProps.data.scene_id
                    ? slotProps.data.scene_id
                    : slotProps.data.metadata &&
                      slotProps.data.metadata.source_id
                    ? slotProps.data.metadata.source_id
                    : slotProps.data.metadata &&
                      slotProps.data.metadata.product_id
                    ? slotProps.data.metadata.product_id
                    : ""
                }}
              </div>
            </template>
          </Column>
          <Column
            field="product_time"
            :header="$t('label_image_time')"
            headerStyle="width: 12rem"
            bodyStyle="width: 12rem"
          >
            <template #body="slotProps">
              {{
                formatUnixDateTime2String(
                  slotProps.data.info && slotProps.data.info.imaging_date
                    ? slotProps.data.info.imaging_date
                    : slotProps.data.metadata
                    ? slotProps.data.metadata.imaging_date
                    : ""
                )
              }}
            </template>
          </Column>
          <Column
            headerStyle="width: 18rem"
            bodyStyle="width: 18rem ;text-align: center;"
            :headerClass="'p-frozen-column right'"
            :bodyClass="'p-frozen-column right'"
          >
            <template #body="slotProps">
              <InputSwitch
                v-model="slotProps.data.is_public"
                @change="updateIsPublicImage(slotProps.data)"
              />

              <Button
                icon="fas fa-list-alt"
                type="button"
                class="p-button-success p-button-sm mr-1"
                :title="$t('button_text_metadata')"
                @click="showDialogMetadata(slotProps.data)"
              >
              </Button
              ><Button
                icon="fas fa-info-circle"
                type="button"
                class="p-button-info p-button-sm mr-1"
                :title="$t('title_image_info')"
                @click="showDialogInfo(slotProps.data)"
              >
              </Button>

              <Button
                :icon="'fas fa-share-alt'"
                type="button"
                class="p-button-sm p-button-primary mr-1"
                :title="$t('button_text_share')"
                @click="showDialogShare(slotProps.data)"
              >
              </Button>

              <Button
                :icon="'fas fa-download'"
                type="button"
                class="p-button-sm p-button-primary mr-1"
                :title="$t('button_text_download')"
                @click="downloadImage(slotProps.data)"
              >
              </Button>
              <Button
                :icon="'fas fa-pen'"
                type="button"
                class="p-button-sm p-button-primary mr-1"
                :title="$t('button_text_edit')"
                @click="showDialogUpdateMetadata(slotProps.data)"
              >
              </Button>
              <Button
                :icon="'fas fa-trash'"
                type="button"
                class="p-button-sm p-button-danger"
                :title="$t('button_text_delete')"
                @click="showDialogQuestionDelete(slotProps.data)"
              >
              </Button>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import Pagination from "@/components/commons/pagination/Index";
import Button from "@/components/commons/button/Button";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import InputSwitch from "@/components/commons/inputswitch/InputSwitch";
import Menu from "@/components/commons/menu/Menu";
import ContextMenu from "@/components/commons/contextmenu/ContextMenu";
import moment from "moment";
export default {
  props: {
    propItems: {
      type: Array,
      default: () => [],
    },
    propLoading: {
      type: Boolean,
      default: false,
    },
    propTable: {
      type: Object,
      default: () => {},
    },
    propTotal: {
      type: Number,
      default: 0,
    },
  },
  components: {
    CommonAccordion,
    Pagination,
    Button,
    DataTable,
    Column,
    Menu,
    ContextMenu,
    InputSwitch,
  },
  data() {
    return {
      selected: null,
      menuModel: [
        {
          label: this.$t("button_text_metadata"),
          icon: "fas fa-list-alt",
          command: () => this.showDialogMetadata(this.selected),
          visible: true,
        },
        {
          label: this.$t("title_image_info"),
          icon: "fas fa-info-circle",
          command: () => this.showDialogInfo(this.selected),
          visible: true,
        },
        {
          label: this.$t("button_text_share"),
          icon: "fas fa-share-alt",
          command: () => this.showDialogShare(this.selected),
          visible: true,
        },
        {
          label: this.$t("button_text_download"),
          icon: "fas fa-download",
          command: () => this.downloadImage(this.selected),
          visible: true,
        },
        {
          label: this.$t("button_text_edit"),
          icon: "fas fa-pen",
          command: () => this.showDialogUpdateMetadata(this.selected),
          visible: true,
        },
        {
          label: this.$t("button_text_delete"),
          icon: "fas fa-trash",
          command: () => this.showDialogQuestionDelete(this.selected),
          visible: true,
        },
      ],
    };
  },
  created() {
    this.$emit("pagechanged", 1);
  },
  watch: {
    propItems(val) {
      this.selected = null;
    },
  },
  methods: {
    updateIsPublicImage(data) {
      this.$emit("updateIsPublicImage", data);
    },
    onVirtualScroll(e) {
      this.$emit("nextPage");
    },
    async onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.$emit("nextPage");
      }
    },
    onRowContextMenu(event) {
      if (this.selected && Object.keys(this.selected).length > 0) {
        this.$refs.cmGeoImgDatatable.show(event.originalEvent);
      }
    },
    showDialogUpdateMetadata(item) {
      this.$emit("showDialogUpdateMetadata", item);
    },
    showDialogInfo(item) {
      this.$emit("showDialogInfo", item);
    },
    showDialogMetadata(item) {
      this.$emit("showDialogMetadata", item);
    },
    downloadImage(item) {
      this.$emit("downloadImage", item);
    },
    showDialogShare(item) {
      this.$emit("showDialogShare", item);
    },
    showDialogQuestionDelete(item) {
      this.$emit("showDialogQuestionDelete", item);
    },
    formatUnixDateTime2String(value) {
      let result = "";
      if (value) {
        try {
          let date = new Date(value);
          result = moment
            .unix(date.getTime() / 1000)
            .format("HH:mm DD/MM/YYYY");
          result = result
            .replace(":", this.lang === "vi" ? "h" : ":")
            .replace(" ", this.lang === "vi" ? " ngày " : " ");
        } catch (error) {}
      }
      return result;
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
.satellite-image__datatable {
  @import "@/assets/scss/_datatable.scss";
  .p-datatable-scrollable-header {
    background: white !important;
  }
  .p-inputswitch {
    margin-right: 0.25rem;
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: #34a835;
  }
  .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
    .p-inputswitch-slider {
    background: #34a835;
  }
}
</style>
