<template>
  <nav id="sidebar" class="sidebar-wrapper">
    <div class="sidebar-content">
      <div class="sidebar-brand" id="sidebarBrand">
        <a class="text-left" @click="go2Home">
          <img src="/img/logo.png" class="d-inline-block align-top" alt />
          <span class="text-white">Geospatial Hub</span>
        </a>
        <div id="close-sidebar" @click="closeSidebar">
          <i class="fas fa-times"></i>
        </div>
      </div>
      <div class="sidebar-menu">
        <ul>
          <li
            id="dropdownDataMng"
            v-if="
              checkShowSidebarDropdown([
                '/satellite-image',
                '/upload',
                '/upload-folder',
                '/upload-history',
              ])
            "
            class="sidebar-dropdown"
            :class="
              [
                'SATELLITE_IMAGE',
                'UPLOAD_SATELLITE',
                'UPLOAD_FOLDER',
                'UPLOAD_HISTORY',
              ].includes(menuActive)
                ? 'active'
                : ''
            "
          >
            <a>
              <i class="fas fa-cloud-upload-alt"></i>
              <span>{{ $t("menu_data_management") }}</span>
            </a>
            <div
              class="sidebar-submenu menu-children"
              :style="{
                display: [
                  'SATELLITE_IMAGE',
                  'UPLOAD_SATELLITE',
                  'UPLOAD_FOLDER',
                  'UPLOAD_HISTORY',
                ].includes(menuActive)
                  ? 'block'
                  : '',
              }"
            >
              <ul class="sidebar-dropdown__sub">
                <li
                  @click="goGeoImageSatellitePage"
                  :class="menuActive === 'SATELLITE_IMAGE' ? 'active' : ''"
                  v-if="accessPaths.includes('/satellite-image')"
                >
                  <a>{{ $t("menu_data_list") }}</a>
                </li>
                <li
                  @click="goUploadSatellitePage"
                  :class="menuActive === 'UPLOAD_SATELLITE' ? 'active' : ''"
                  v-if="accessPaths.includes('/upload')"
                >
                  <a>{{ $t("menu_data_upload") }}</a>
                </li>
                <li
                  @click="goUploadFolderPage"
                  :class="menuActive === 'UPLOAD_FOLDER' ? 'active' : ''"
                  v-if="accessPaths.includes('/upload-folder')"
                >
                  <a>{{ $t("menu_data_upload_folder") }}</a>
                </li>
                <li
                  @click="goUploadHistoryPage"
                  :class="menuActive === 'UPLOAD_HISTORY' ? 'active' : ''"
                  v-if="accessPaths.includes('/upload-history')"
                >
                  <a>{{ $t("menu_upload_history") }}</a>
                </li>
              </ul>
            </div>
          </li>
          <li
            id="dropdownRequestMng"
            v-if="checkShowSidebarDropdown(['/order', '/order-tasking'])"
            class="sidebar-dropdown"
            :class="
              ['ORDER', 'ORDER_TASKING', 'ORDER_LIST'].includes(menuActive)
                ? 'active'
                : ''
            "
          >
            <a>
              <i class="fas fa-shopping-cart"></i>
              <span>{{ $t("menu_order_management") }}</span>
            </a>
            <div
              class="sidebar-submenu menu-children"
              :style="{
                display: ['ORDER', 'ORDER_TASKING', 'ORDER_LIST'].includes(
                  menuActive
                )
                  ? 'block'
                  : '',
              }"
            >
              <ul class="sidebar-dropdown__sub">
                <li
                  v-if="accessPaths.includes('/order-tasking')"
                  :class="menuActive === 'ORDER_TASKING' ? 'active' : ''"
                  @click="goOrderTaskingPage"
                >
                  <a>{{ $t("menu_order_tasking") }}</a>
                </li>
                <li
                  v-if="accessPaths.includes('/order')"
                  :class="menuActive === 'ORDER_LIST' ? 'active' : ''"
                  @click="goOrderListPage"
                >
                  <a>{{ $t("menu_order_list") }}</a>
                </li>
              </ul>
            </div>
          </li>
          <li
            id="dropdownShareMng"
            v-if="checkShowSidebarDropdown(['/sharing', '/shared-by-me'])"
            class="sidebar-dropdown"
            :class="
              ['SHARED_BY_ME', 'SHARING'].includes(menuActive) ? 'active' : ''
            "
          >
            <a>
              <i class="fas fa-share-alt"></i>
              <span>{{ $t("menu_share_management") }}</span>
            </a>
            <div
              class="sidebar-submenu menu-children"
              :style="{
                display: ['SHARED_BY_ME', 'SHARING'].includes(menuActive)
                  ? 'block'
                  : '',
              }"
            >
              <ul class="sidebar-dropdown__sub">
                <li
                  v-if="accessPaths.includes('/sharing')"
                  :class="menuActive === 'SHARING' ? 'active' : ''"
                  @click="goSharingPage"
                >
                  <a>{{ $t("menu_sharing_list") }}</a>
                </li>
                <li
                  v-if="accessPaths.includes('/shared-by-me')"
                  :class="menuActive === 'SHARED_BY_ME' ? 'active' : ''"
                  @click="goShareByMePage"
                >
                  <a>{{ $t("menu_my_shared") }}</a>
                </li>
              </ul>
            </div>
          </li>
          <li
            id="dropdownStatisticalMng"
            v-if="
              checkShowSidebarDropdown([
                '/statistical-report/general',
                '/statistical-report/upload',
              ])
            "
            class="sidebar-dropdown"
            :class="
              [
                'STATISTICAL_REPORT',
                'STATISTIC_UPLOAD',
                'STATISTIC_DOWNLOAD',
                'GENERAL_REPORT',
              ].includes(menuActive)
                ? 'active'
                : ''
            "
          >
            <a>
              <i class="fas fa-chart-bar"></i>
              <span>{{ $t("menu_statistic_report") }}</span>
            </a>
            <div
              class="sidebar-submenu menu-children"
              :style="{
                display: [
                  'STATISTICAL_REPORT',
                  'STATISTIC_UPLOAD',
                  'STATISTIC_DOWNLOAD',
                  'GENERAL_REPORT',
                ].includes(menuActive)
                  ? 'block'
                  : '',
              }"
            >
              <ul class="sidebar-dropdown__sub">
                <li
                  v-if="accessPaths.includes('/statistical-report/general')"
                  :class="menuActive === 'GENERAL_REPORT' ? 'active' : ''"
                  @click="goGeneralReportPage"
                >
                  <a>{{ $t("menu_statistic_general") }}</a>
                </li>
                <li
                  v-if="accessPaths.includes('/statistical-report/upload')"
                  :class="menuActive === 'STATISTIC_UPLOAD' ? 'active' : ''"
                  @click="goStatisticUploadPage"
                >
                  <a>{{ $t("menu_statistic_upload") }}</a>
                </li>
                <li
                  v-if="accessPaths.includes('/statistical-report/download')"
                  :class="menuActive === 'STATISTIC_DOWNLOAD' ? 'active' : ''"
                  @click="goStatisticDownloadPage"
                >
                  <a>{{ $t("menu_statistic_download") }}</a>
                </li>
              </ul>
            </div>
          </li>
          <li
            id="dropdownProjectMng"
            v-if="
              checkShowSidebarDropdown(['/project', '/customer', '/contact'])
            "
            class="sidebar-dropdown"
            :class="
              ['PROJECTS', 'CUSTOMER', 'CONTACT'].includes(menuActive)
                ? 'active'
                : ''
            "
          >
            <a>
              <i class="fas fa-list"></i>
              <span>{{ $t("menu_project_and_customer") }}</span>
            </a>
            <div
              class="sidebar-submenu menu-children"
              :style="{
                display: ['PROJECTS', 'CUSTOMER', 'CONTACT'].includes(
                  menuActive
                )
                  ? 'block'
                  : '',
              }"
            >
              <ul class="sidebar-dropdown__sub">
                <li
                  v-if="accessPaths.includes('/customer')"
                  :class="menuActive === 'CUSTOMER' ? 'active' : ''"
                  @click="goCustomerPage"
                >
                  <a>{{ $t("menu_customer") }}</a>
                </li>
              </ul>
            </div>
          </li>
          <li
            id="dropdownAdminMng"
            v-if="
              checkShowSidebarDropdown([
                '/user',
                '/role',
                '/permission',
                '/common-config',
                'logs',
              ])
            "
            class="sidebar-dropdown"
            :class="
              [
                'USERS',
                'AUTHORITIES',
                'PERMISSIONS',
                'COMMON_CONFIG',
                'LOGS',
              ].includes(menuActive)
                ? 'active'
                : ''
            "
          >
            <a>
              <i class="fas fa-user-tag"></i>
              <span>{{ $t("menu_administration") }}</span>
            </a>
            <div
              class="sidebar-submenu menu-children"
              :style="{
                display: [
                  'USERS',
                  'AUTHORITIES',
                  'PERMISSIONS',
                  'COMMON_CONFIG',
                  'LOGS',
                ].includes(menuActive)
                  ? 'block'
                  : '',
              }"
            >
              <ul class="sidebar-dropdown__sub">
                <li
                  v-if="accessPaths.includes('/user')"
                  :class="menuActive === 'USERS' ? 'active' : ''"
                  @click="goUserPage"
                >
                  <a>{{ $t("menu_management_user") }}</a>
                </li>
                <li
                  v-if="accessPaths.includes('/role')"
                  :class="menuActive === 'AUTHORITIES' ? 'active' : ''"
                  @click="goAuthorityPage"
                >
                  <a>{{ $t("menu_management_role_user") }}</a>
                </li>
                <li
                  v-if="accessPaths.includes('/permission')"
                  :class="menuActive === 'PERMISSIONS' ? 'active' : ''"
                  @click="goPermissionPage"
                >
                  <a>{{ $t("menu_management_permission") }}</a>
                </li>
                <li
                  v-if="accessPaths.includes('/common-config')"
                  :class="menuActive === 'COMMON_CONFIG' ? 'active' : ''"
                  @click="goCommonConfigPage"
                >
                  <a>{{ $t("menu_management_common_config") }}</a>
                </li>
                <li
                  v-if="accessPaths.includes('/logs')"
                  :class="menuActive === 'LOGS' ? 'active' : ''"
                  @click="goLogsPage"
                >
                  <a>{{ $t("menu_management_log") }}</a>
                </li>
              </ul>
            </div>
          </li>
          <li
            id="dropdownCategoryMng"
            v-if="
              checkShowSidebarDropdown([
                '/category/organisation',
                '/category/provider',
                '/category/satellite',
                '/category/image-types',
                '/category/data-catalog',
                'category/processing-level',
                '/category/imaging-mode',
                '/category/direction',
                '/category/pol-layer',
                '/category/priority-level',
                '/category/order-status',
                '/category/project-status',
                '/category/organisation-status',
                '/category/actions-log',
                '/category/contact-status',
                '/category/order-types',
                '/category/product',
                '/category/bands',
                '/category/bit-depth',
                '/category/pixel-resolution',
                '/category/dra-contrast',
                '/category/orthor-options',
                '/category/resampling-kernel',
                '/category/license',
                '/category/file-format-optical',
                '/category/file-format-radar',
                '/category/tiling',
                '/category/delivery-media',
                '/category/delivery-service',
                '/category/product-level',
                '/category/granularity',
                '/category/data-specification',
              ])
            "
            class="sidebar-dropdown"
            :class="
              [
                'CATOGORY',
                'ORGANISATIONS',
                'PROVIDER',
                'SATELLITE',
                'PRIORITY_LEVEL',
                'ORDER_STATUS',
                'IMAGE_TYPES',
                'DATA_LIST',
                'PROCESSING_LEVEL',
                'DIRECTION',
                'BEAM_MODE',
                'POL_LAYER',
                'PROJECT_STATUS',
                'ORGANISATION_STATUS',
                'ACTIONS_LOG',
                'CONTACT_STATUS',
                'ORDER_TYPES',
                'DATA_SPECIFICATION',
              ].includes(menuActive)
                ? 'active'
                : ''
            "
          >
            <a>
              <i class="fas fa-list"></i>
              <span>{{ $t("menu_category") }}</span>
            </a>
            <div
              class="sidebar-submenu menu-children"
              :style="{
                display: [
                  'CATOGORY',
                  'ORGANISATIONS',
                  'PROVIDER',
                  'SATELLITE',
                  'PRIORITY_LEVEL',
                  'ORDER_STATUS',
                  'IMAGE_TYPES',
                  'DATA_LIST',
                  'PROCESSING_LEVEL',
                  'DIRECTION',
                  'BEAM_MODE',
                  'POL_LAYER',
                  'PROJECT_STATUS',
                  'ORGANISATION_STATUS',
                  'ACTIONS_LOG',
                  'CONTACT_STATUS',
                  'ORDER_TYPES',
                  'DATA_SPECIFICATION',
                ].includes(menuActive)
                  ? 'block'
                  : '',
              }"
            >
              <ul
                class="sidebar-dropdown__sub"
                :style="{ maxHeight: menuSubHeight + 'px' }"
              >
                <li
                  v-if="accessPaths.includes('/category/organisation')"
                  :class="menuActive === 'ORGANISATIONS' ? 'active' : ''"
                  @click="goOrganisationPage"
                >
                  <a>{{ $t("menu_organisation") }}</a>
                </li>
                <li
                  v-if="accessPaths.includes('/category/provider')"
                  :class="menuActive === 'PROVIDER' ? 'active' : ''"
                  @click="goProviderPage"
                >
                  <a>{{ $t("menu_provider") }}</a>
                </li>
                <li
                  v-if="accessPaths.includes('/category/satellite')"
                  :class="menuActive === 'SATELLITE' ? 'active' : ''"
                  @click="goModelPage"
                >
                  <a>{{ $t("menu_satellite") }}</a>
                </li>
                <li
                  v-if="accessPaths.includes('/category/image-types')"
                  :class="menuActive === 'IMAGE_TYPES' ? 'active' : ''"
                  @click="goImageTypesPage"
                >
                  <a>{{ $t("menu_image_types") }}</a>
                </li>
                <li
                  v-if="accessPaths.includes('/category/data-catalog')"
                  :class="menuActive === 'DATA_LIST' ? 'active' : ''"
                  @click="go2CategoryDataList"
                >
                  <a>{{ $t("menu_category_data_catalog") }}</a>
                </li>
                <li
                  v-if="accessPaths.includes('/category/processing-level')"
                  :class="menuActive === 'PROCESSING_LEVEL' ? 'active' : ''"
                  @click="goProcessingLevelPage"
                >
                  <a>{{ $t("menu_processing_level") }}</a>
                </li>

                <li
                  v-if="accessPaths.includes('/category/imaging-mode')"
                  :class="menuActive === 'BEAM_MODE' ? 'active' : ''"
                  @click="goModePage"
                >
                  <a>{{ $t("menu_mode") }}</a>
                </li>

                <li
                  v-if="accessPaths.includes('/category/direction')"
                  :class="menuActive === 'DIRECTION' ? 'active' : ''"
                  @click="goDirectionPage"
                >
                  <a>{{ $t("menu_direction") }}</a>
                </li>

                <li
                  v-if="accessPaths.includes('/category/pol-layer')"
                  :class="menuActive === 'POL_LAYER' ? 'active' : ''"
                  @click="goPolLayerPage"
                >
                  <a>{{ $t("menu_pol_layer") }}</a>
                </li>

                <li
                  v-if="accessPaths.includes('/category/priority-level')"
                  :class="menuActive === 'PRIORITY_LEVEL' ? 'active' : ''"
                  @click="goPriorityLevelPage"
                >
                  <a>{{ $t("menu_priority_level") }}</a>
                </li>
                <li
                  v-if="accessPaths.includes('/category/order-status')"
                  :class="menuActive === 'ORDER_STATUS' ? 'active' : ''"
                  @click="goOrderStatusPage"
                >
                  <a>{{ $t("menu_order_status") }}</a>
                </li>
                <li
                  v-if="accessPaths.includes('/category/organisation-status')"
                  :class="menuActive === 'ORGANISATION_STATUS' ? 'active' : ''"
                  @click="goOrganisationStatusPage"
                >
                  <a>{{ $t("menu_organisation_status") }}</a>
                </li>
                <li
                  v-if="accessPaths.includes('/category/actions-log')"
                  :class="menuActive === 'ACTIONS_LOG' ? 'active' : ''"
                  @click="goActionsLogPage"
                >
                  <a>{{ $t("menu_actions_log") }}</a>
                </li>

                <li
                  v-if="accessPaths.includes('/category/data-specification')"
                  :class="menuActive === 'DATA_SPECIFICATION' ? 'active' : ''"
                  @click="go2DataSpecification"
                >
                  <a>{{ $t("menu_data_specification") }}</a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="sidebar-footer text-center" id="sidebarFooter">
      <button class="btn btn-sm" @click="onLogout">
        <i class="fa fa-power-off"></i>
      </button>
    </div>
  </nav>
</template>
<script>
import oauthFunc from "@/utils/functions/oauth";
export default {
  data() {
    return {
      menuActive: "",
      menuSubHeight: 150,
    };
  },
  async created() {
    this.getMenuActive(this.$route.path);
  },
  watch: {
    route: {
      handler(val) {
        this.getMenuActive(val.path);
      },
      deep: true,
    },
    loggedIn: {
      handler(val) {
        this.calMenuSubHeight();
        if (val) {
          if (!this.userMe || Object.keys(this.userMe).length === 0)
            this.getMyInfo();
        } else {
          this.cartCount = 0;
        }
      },
      immediate: true,
    },
  },
  methods: {
    checkShowSidebarDropdown(paths) {
      let giao = this.accessPaths.filter((x) => paths.includes(x));
      if (giao && giao.length > 0) return true;
      else return false;
    },
    closeSidebar() {
      this.$emit("closeSidebar");
    },
    async getMyInfo() {
      try {
        let response = await oauthFunc.userMe(
          this.$store.getters.getAccessToken
        );
        if (response.status === 200) {
          this.$store.dispatch("setUser", response.data);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getMyInfo);
        }
      }
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
    getMenuActive(val) {
      switch (val) {
        case "/":
          this.menuActive = "DASHBOARD";
          break;
        case "/order":
        case "/order/":
          this.menuActive = "ORDER_LIST";
          break;
        case "/order-tasking":
        case "/order-tasking/":
          this.menuActive = "ORDER_TASKING";
          break;
        case "/order/satellite":
          this.menuActive = "ORDER_SATELLITE";
          break;
        case "/order/ortho-image":
          this.menuActive = "ORDER_ORTHO_IMAGE";
          break;
        case "/satellite-image":
        case "/satellite-image/":
          this.menuActive = "SATELLITE_IMAGE";
          break;
        case "/shared-by-me":
          this.menuActive = "SHARED_BY_ME";
          break;
        case "/sharing":
          this.menuActive = "SHARING";
          break;
        case "/upload":
        case "/upload/":
          this.menuActive = "UPLOAD_SATELLITE";
          break;
        case "/upload-folder":
        case "/upload-folder/":
          this.menuActive = "UPLOAD_FOLDER";
          break;
        case "/upload-history":
        case "/upload-history/":
          this.menuActive = "UPLOAD_HISTORY";
          break;
        case "/project":
          this.menuActive = "PROJECTS";
          break;
        case "/customer":
          this.menuActive = "CUSTOMER";
          break;
        case "/contact":
          this.menuActive = "CONTACT";
          break;
        case "/role":
          this.menuActive = "AUTHORITIES";
          break;
        case "/permission":
          this.menuActive = "PERMISSIONS";
          break;
        case "/user":
          this.menuActive = "USERS";
          break;
        case "/logs":
          this.menuActive = "LOGS";
          break;
        case "/common-config":
          this.menuActive = "COMMON_CONFIG";
          break;
        case "/category/organisation":
          this.menuActive = "ORGANISATIONS";
          break;
        case "/category/provider":
          this.menuActive = "PROVIDER";
          break;
        case "/category/satellite":
          this.menuActive = "SATELLITE";
          break;
        case "/data-specification":
          this.menuActive = "DATA_SPECIFICATION";
          break;
        case "/category/priority-level":
          this.menuActive = "PRIORITY_LEVEL";
          break;
        case "/category/order-status":
          this.menuActive = "ORDER_STATUS";
          break;
        case "/category/image-types":
          this.menuActive = "IMAGE_TYPES";
          break;
        case "/category/data-catalog":
          this.menuActive = "DATA_LIST";
          break;
        case "/category/processing-level":
          this.menuActive = "PROCESSING_LEVEL";
          break;
        case "/category/direction":
          this.menuActive = "DIRECTION";
          break;
        case "/category/imaging-mode":
          this.menuActive = "BEAM_MODE";
          break;
        case "/category/pol-layer":
          this.menuActive = "POL_LAYER";
          break;
        case "/category/project-status":
          this.menuActive = "PROJECT_STATUS";
          break;
        case "/category/organisation-status":
          this.menuActive = "ORGANISATION_STATUS";
          break;
        case "/category/contact-status":
          this.menuActive = "CONTACT_STATUS";
          break;
        case "/category/actions-log":
          this.menuActive = "ACTIONS_LOG";
          break;
        case "/category/order-types":
          this.menuActive = "ORDER_TYPES";
          break;
        case "/statistical-report/general":
          this.menuActive = "GENERAL_REPORT";
          break;
        case "/statistical-report/upload":
          this.menuActive = "STATISTIC_UPLOAD";
          break;
        case "/statistical-report/download":
          this.menuActive = "STATISTIC_DOWNLOAD";
          break;
        default:
          this.menuActive = "";
          break;
      }
      if (!this.menuActive) {
        if (val.startsWith("/category/data-specification")) {
          this.menuActive = "DATA_SPECIFICATION";
        } else if (val.startsWith("/order/")) {
          this.menuActive = "ORDER";
        } else this.menuActive = "DASHBOARD";
      }
    },
    goPriorityLevelPage() {
      if (this.$route.path != "/category/priority-level")
        this.$router.push({
          path: "/category/priority-level",
        });
    },
    goImageTypesPage() {
      if (this.$route.path != "/category/image-types")
        this.$router.push({
          path: "/category/image-types",
        });
    },
    go2CategoryDataList() {
      if (this.$route.path != "/category/data-catalog")
        this.$router.push({
          path: "/category/data-catalog",
        });
    },
    goDirectionPage() {
      if (this.$route.path != "/category/direction")
        this.$router.push({
          path: "/category/direction",
        });
    },
    goPolLayerPage() {
      if (this.$route.path != "/category/pol-layer")
        this.$router.push({
          path: "/category/pol-layer",
        });
    },
    goModePage() {
      if (this.$route.path != "/category/imaging-mode")
        this.$router.push({
          path: "/category/imaging-mode",
        });
    },
    goProcessingLevelPage() {
      if (this.$route.path != "/category/processing-level")
        this.$router.push({
          path: "/category/processing-level",
        });
    },
    goOrderStatusPage() {
      if (this.$route.path != "/category/order-status")
        this.$router.push({
          path: "/category/order-status",
        });
    },
    goOrderTypesPage() {
      if (this.$route.path != "/category/order-types")
        this.$router.push({
          path: "/category/order-types",
        });
    },

    goGranularityPage() {
      if (this.$route.path != "/category/granularity")
        this.$router.push({
          path: "/category/granularity",
        });
    },
    goProjectStatusPage() {
      if (this.$route.path != "/category/project-status")
        this.$router.push({
          path: "/category/project-status",
        });
    },
    goContactStatusPage() {
      if (this.$route.path != "/category/contact-status")
        this.$router.push({
          path: "/category/contact-status",
        });
    },
    goActionsLogPage() {
      if (this.$route.path != "/category/actions-log")
        this.$router.push({
          path: "/category/actions-log",
        });
    },
    go2DataSpecification() {
      if (this.$route.path != "/category/data-specification")
        this.$router.push({
          path: "/category/data-specification",
        });
    },
    goOrganisationStatusPage() {
      if (this.$route.path != "/category/organisation-status")
        this.$router.push({
          path: "/category/organisation-status",
        });
    },
    goOrderTaskingPage() {
      if (this.$route.path != "/order-tasking")
        this.$router.push({
          path: "/order-tasking",
        });
    },
    goOrderListPage() {
      if (this.$route.path != "/order/" || this.$route.path != "/order")
        this.$router.push({
          path: "/order",
        });
    },
    goOrderSatellitePage() {
      if (this.$route.path != "/order/satellite")
        this.$router.push({
          path: "/order/satellite",
        });
    },
    goOrderOrthoImagePage() {
      if (this.$route.path != "/order/ortho-image")
        this.$router.push({
          path: "/order/ortho-image",
        });
    },
    goGeoImageSatellitePage() {
      if (this.$route.path != "/satellite-image")
        this.$router.push({
          path: "/satellite-image",
        });
    },
    goSharingPage() {
      if (this.$route.path != "/sharing")
        this.$router.push({
          path: "/sharing",
        });
    },
    goShareByMePage() {
      if (this.$route.path != "/shared-by-me")
        this.$router.push({
          path: "/shared-by-me",
        });
    },
    goUploadSatellitePage() {
      if (this.$route.path != "/upload")
        this.$router.push({
          path: "/upload",
        });
    },
    goUploadFolderPage() {
      if (this.$route.path != "/upload-folder")
        this.$router.push({
          path: "/upload-folder",
        });
    },
    goUploadHistoryPage() {
      if (this.$route.path != "/upload-history")
        this.$router.push({
          path: "/upload-history",
        });
    },
    goGeneralReportPage() {
      if (this.$route.path != "/statistical-report/general")
        this.$router.push({
          path: "/statistical-report/general",
        });
    },
    goStatisticUploadPage() {
      if (this.$route.path != "/statistical-report/upload")
        this.$router.push({
          path: "/statistical-report/upload",
        });
    },
    goStatisticDownloadPage() {
      if (this.$route.path != "/statistical-report/download")
        this.$router.push({
          path: "/statistical-report/download",
        });
    },
    goProjectPage() {
      if (this.$route.path != "/project")
        this.$router.push({
          path: "/project",
        });
    },
    goCustomerPage() {
      if (this.$route.path != "/customer")
        this.$router.push({
          path: "/customer",
        });
    },
    goContactPage() {
      if (this.$route.path != "/contact")
        this.$router.push({
          path: "/contact",
        });
    },
    goProviderPage() {
      if (this.$route.path != "/category/provider")
        this.$router.push({
          path: "/category/provider",
        });
    },
    goModelPage() {
      if (this.$route.path != "/category/satellite")
        this.$router.push({
          path: "/category/satellite",
        });
    },
    goDataSpecificationPage() {
      if (this.$route.path != "/data-specification")
        this.$router.push({
          path: "/data-specification",
        });
    },

    goOrganisationPage() {
      if (this.$route.path != "/category/organisation")
        this.$router.push({
          path: "/category/organisation",
        });
    },
    goAuthorityPage() {
      if (this.$route.path != "/role")
        this.$router.push({
          path: "/role",
        });
    },
    goPermissionPage() {
      if (this.$route.path != "/permission")
        this.$router.push({
          path: "/permission",
        });
    },
    goCommonConfigPage() {
      if (this.$route.path != "/common-config")
        this.$router.push({
          path: "/common-config",
        });
    },
    goLogsPage() {
      if (this.$route.path != "/logs")
        this.$router.push({
          path: "/logs",
        });
    },
    goUserPage() {
      if (this.$route.path != "/user")
        this.$router.push({
          path: "/user",
        });
    },
    goLeaderSchedulePage() {
      if (this.$route.path != "/leader-calendar")
        this.$router.push({
          path: "/leader-calendar",
        });
    },
    goManagerSchedulePage() {
      if (this.$route.path != "/manager-calendar")
        this.$router.push({
          path: "/manager-calendar",
        });
    },
    goDocumentsPage() {
      if (this.$route.path != "/documents")
        this.$router.push({
          path: "/documents",
        });
    },
    goNotificationPage() {
      if (this.$route.path != "/notifications")
        this.$router.push({
          path: "/notifications",
        });
    },
    goDocumentTypePage() {
      if (this.$route.path != "/document-types")
        this.$router.push({
          path: "/document-types",
        });
    },
    goMeetingDocumentsPage() {
      if (this.$route.path != "/meeting-documents")
        this.$router.push({
          path: "/meeting-documents",
        });
    },
    go2Home() {
      if (this.accessPaths.includes("/")) {
        if (this.$route.path != "/")
          this.$router.push({
            path: "/",
          });
      }
    },
    onLogout: function() {
      this.$store.dispatch("clearToken").then((r) => {
        this.$router.push({ name: "Login" });
      });
    },
    calMenuSubHeight() {
      let elementSidebarBrand = document.getElementById("sidebarBrand");
      let elementSidebarHeader = document.getElementById("sidebarHeader");
      let elementDropdownDataMng = document.getElementById("dropdownDataMng");
      let elementDropdownRequestMng = document.getElementById(
        "dropdownRequestMng"
      );
      let elementDropdownShareMng = document.getElementById("dropdownShareMng");
      let elementDropdownStatisticalMng = document.getElementById(
        "dropdownStatisticalMng"
      );
      let elementDropdownProjectMng = document.getElementById(
        "dropdownProjectMng"
      );

      let elementDropdownAdminMng = document.getElementById("dropdownAdminMng");
      let elementSidebarFooter = document.getElementById("sidebarFooter");

      this.menuSubHeight =
        this.documentHeight -
        (elementSidebarBrand ? elementSidebarBrand.offsetHeight : 54) -
        (elementSidebarHeader ? elementSidebarHeader.offsetHeight : 0) -
        46 -
        46 -
        46 -
        46 -
        46 -
        46 -
        46 -
        (elementSidebarFooter ? elementSidebarFooter.offsetHeight : 64);
    },
  },
  computed: {
    route() {
      return this.$route;
    },
    loggedIn() {
      return this.$store.getters.loggedIn;
    },

    accessPaths() {
      return this.$store.getters.getAccessPaths;
    },
    userMe() {
      return this.$store.getters.getUser;
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    sidebarMenuHeight() {
      if (this.documentHeight <= 768) {
        if (this.loggedIn) {
          return this.documentHeight - 80 - 54 - 50;
        } else {
          return this.documentHeight - 54;
        }
      } else return this.documentHeight - 54;
    },
  },
  mounted() {
    jQuery(function($) {
      $(".sidebar-dropdown > a").click(function() {
        $(".sidebar-submenu").slideUp(200);
        if (
          $(this)
            .parent()
            .hasClass("active")
        ) {
          $(".sidebar-dropdown").removeClass("active");
          $(this)
            .parent()
            .removeClass("active");
        } else {
          $(".sidebar-dropdown").removeClass("active");
          $(this)
            .next(".sidebar-submenu")
            .slideDown(200);
          $(this)
            .parent()
            .addClass("active");
        }
      });

      $("#close-sidebar").click(function() {
        $(".page-wrapper").removeClass("toggled");
      });
      $("#show-sidebar").click(function() {
        $(".page-wrapper").addClass("toggled");
      });
    });
    setTimeout(() => {
      this.calMenuSubHeight();
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(5deg);
  }
  70% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes sonar {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand > a,
.sidebar-wrapper .sidebar-dropdown > a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.sidebar-wrapper .sidebar-menu ul li a,
#close-sidebar {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.sidebar-submenu li {
  padding-left: 1rem;
  a {
    color: #9499ac;
  }
}
.sidebar-submenu li.active {
  background: #0083ca;
}

.page-wrapper.toggled .sidebar-wrapper {
  left: 0px;
}
.sidebar-wrapper {
  width: 260px;
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  left: -300px;
  z-index: 999;
  font-size: 14px;
}
.sidebar-wrapper ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.sidebar-wrapper a {
  text-decoration: none;
}
.sidebar-content {
  overflow-y: auto;
  position: relative;
}
.sidebar-content.desktop {
  overflow-y: hidden;
}
.sidebar-wrapper .sidebar-brand {
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  background: #1a1a27;
  border-bottom: 1px solid #1a1a27;
  &:hover {
    cursor: pointer;
  }
  a {
    display: flex;
    img {
      width: 41px;
      height: 30px;
    }
    span {
      height: 100%;
      margin: auto 0;
      margin-left: 0.5rem;
      text-transform: uppercase;
      font-size: 12px;
    }
  }
}
.sidebar-wrapper .sidebar-brand > a {
  font-size: 16px;
  flex-grow: 1;
}
.sidebar-wrapper .sidebar-brand #close-sidebar {
  cursor: pointer;
  font-size: 20px;
  padding: 0.25rem;
}
.sidebar-wrapper .sidebar-header {
  padding: 1rem 0.75rem;
  overflow: hidden;
}
.sidebar-wrapper .sidebar-header .user-pic {
  float: left;
  width: 60px;
  padding: 2px;
  border-radius: 12px;
  margin-right: 15px;
  overflow: hidden;
}
.sidebar-wrapper .sidebar-header .user-pic img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.sidebar-wrapper .sidebar-header .user-info {
  float: left;
  background: #1e1e2d;
  .user-name {
    color: white;
  }
  .select-language {
    display: block;
    margin: auto 0;
    select {
      background: transparent;
      color: white;
      border: 0px;
      outline: 0px;
      max-width: 6.5rem;
      font-size: 12px;
      option {
        color: #495057;
      }
      &:active {
        border-color: transparent !important;
      }
      &:hover {
        border-color: transparent !important;
      }
      &:focus-visible {
        border-color: transparent !important;
      }
    }
  }
}
.sidebar-wrapper .sidebar-header .user-info > span {
  display: block;
}
.sidebar-wrapper .sidebar-header .user-info .user-role {
  font-size: 12px;
}
.sidebar-wrapper .sidebar-header .user-info .user-status {
  font-size: 11px;
  margin-top: 4px;
}
.sidebar-wrapper .sidebar-header .user-info .user-status i {
  font-size: 8px;
  margin-right: 4px;
  color: #5cb85c;
}
.sidebar-wrapper .sidebar-menu {
  padding-bottom: 10px;
}
.sidebar-wrapper .sidebar-menu .header-menu span {
  font-weight: bold;
  font-size: 14px;
  padding: 15px 20px 5px 20px;
  display: inline-block;
}
.sidebar-wrapper .sidebar-menu ul li a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  position: relative;
  padding: 0.5rem 1.5rem 0.5rem 0.75rem;
  cursor: pointer;
}
.sidebar-menu .menu-children ul li a {
  padding: 0.5rem 1.5rem 0.5rem 1.25rem;
}
.sidebar-wrapper .sidebar-menu ul li a i {
  margin-right: 10px;
  font-size: 12px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
}
.sidebar-wrapper .sidebar-menu ul li a:hover > i::before {
  display: inline-block;
  animation: swing ease-in-out 0.5s 1 alternate;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown__sub {
  overflow-y: auto;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown > a:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f105";
  font-style: normal;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: 0 0;
  position: absolute;
  right: 15px;
  top: 14px;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
  font-size: 0.825rem;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a {
  &:before {
    content: "\f111";
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    font-style: normal;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 10px;
    font-size: 8px;
  }
  &:hover {
    cursor: pointer;
  }
}
.sidebar-wrapper .sidebar-menu ul li a span.label,
.sidebar-wrapper .sidebar-menu ul li a span.badge {
  float: right;
  margin-top: 8px;
  margin-left: 5px;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
  float: right;
  margin-top: 0px;
}
.sidebar-wrapper .sidebar-menu .sidebar-submenu {
  display: none;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a:after {
  transform: rotate(90deg);
  right: 17px;
}
.btn-logout {
  margin-top: 0.75rem;
  background: #000;
  color: white;
}
// /*--------------------------side-footer------------------------------*/
.sidebar-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
}
.sidebar-footer > a {
  flex-grow: 1;
  text-align: center;
  height: 30px;
  line-height: 30px;
  position: relative;
  cursor: pointer;
}
.sidebar-footer > a .notification {
  position: absolute;
  top: 0;
}
.sidebar-wrapper {
  background: #1e1e2d;
}

.sidebar-wrapper .sidebar-header .user-info .user-role,
.sidebar-wrapper .sidebar-header .user-info .user-status,
.sidebar-wrapper .sidebar-brand > a,
.sidebar-wrapper .sidebar-menu ul li a,
.sidebar-footer > a {
  color: #9499ac;
}
#close-sidebar {
  color: #bdbdbd;
}
#close-sidebar:hover {
  color: #ffffff;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown div {
  background: #1b1b28; // #2b2b42;
}

.sidebar-wrapper .sidebar-menu ul li a i {
  background: #2b2b42;
}
.sidebar-wrapper .sidebar-menu .header-menu span {
  color: #6c7b88;
}
.sidebar-footer {
  background: #1e1e2d;
  button {
    margin: 0.5rem auto;
    background: #2a2b3a;
    color: white;
    padding: 0.3125rem 5rem;
    border-radius: 0.25rem;
    height: 48px;
    width: 238px;
    i {
      font-size: 1.25rem;
    }
  }
}
.sidebar-footer > a:first-child {
  border-left: none;
}
.sidebar-footer > a:last-child {
  border-right: none;
}
.sidebar-menu li[data-type="no-sub"].active {
  background: #1b1b28;
  a {
    color: #ffffff;
    i {
      background: #009ef7;
    }
  }
}

.sidebar-menu li.active {
  background: #1b1b28;
  a {
    color: white !important;
  }
  .sidebar-submenu {
    li {
      a {
        color: #9499ac !important;
      }
      &.active {
        a {
          color: white !important;
          &::before {
            color: #009ef7;
          }
        }
      }
    }
  }
}
</style>
