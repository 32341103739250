<template>
  <CommonAccordion :propTitle="$t('title_provider_structure')">
    <div slot="content">
      <div>
        <div class="p-grid m-0">
          <div class="p-field p-col-12 p-md-4">
            <label class="p-col-fixed" :style="styleLabel">
              {{ $t("label_name") }}&nbsp;
              <span class="font-weight-bold required">*</span>
            </label>
            <ValidationProvider :name="$t('label_name')" rules="required">
              <InputText
                type="text"
                :style="styleControl"
                maxlength="255"
                v-model="propEntity.name"
                :disabled="!propStatus.isAdd && !propStatus.isEdit"
                class="new-style"
              />
            </ValidationProvider>
          </div>
          <div class="p-field p-col-12 p-md-4">
            <label class="p-col-fixed" :style="styleLabel">
              {{ $t("label_value") }}&nbsp;
              <span class="font-weight-bold required">*</span>
            </label>
            <ValidationProvider :name="$t('label_value')" rules="required">
              <InputText
                type="text"
                :style="styleControl"
                maxlength="255"
                v-model="propEntity.value"
                :disabled="!propStatus.isAdd && !propStatus.isEdit"
                class="new-style"
              />
            </ValidationProvider>
          </div>
          <div class="p-field p-grid p-col-12 m-0">
            <label class="p-col-fixed" :style="styleLabel">{{
              $t("label_description")
            }}</label>
            <Textarea
              :autoResize="true"
              rows="3"
              :style="styleControl"
              maxlength="255"
              v-model="propEntity.description"
              :disabled="!propStatus.isAdd && !propStatus.isEdit"
              class="new-style"
            />
          </div>
        </div>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import InputText from "@/components/commons/inputtext/InputText";
import Textarea from "@/components/commons/textarea/Textarea";
export default {
  components: { InputText, Textarea, CommonAccordion },
  props: {
    propEntity: {
      type: Object,
      default: () => {},
    },
    propStatus: {
      type: Object,
      default: () => {
        return {
          isAdd: false,
          isEdit: false,
        };
      },
    },
  },
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    styleLabel() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:90px";
    },
    styleLabelCheckbox() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:80px";
    },
    styleControl() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:calc(100% - 90px)";
    },
  },
};
</script>
<style lang="scss">
.provider__structure {
  .p-md-4 {
    padding: 0.5rem !important;
  }
}
</style>
