<template>
  <CommonAccordion :propTitle="$t('title_customer_list')">
    <div slot="content">
      <div class="datatable">
        <!-- <ContextMenu :model="menuModel" ref="cmCustomerDatatable" /> -->
        <DataTable
          :value="propItems"
          ref="dt"
          :resizableColumns="false"
          columnResizeMode="expand"
          selectionMode="single"
          dataKey="id"
          :selection.sync="propTable.selected"
          @row-contextmenu="onRowContextMenu"
          :scrollHeight="propTable.height - 40 - 38 + 'px'"
          :rowClass="rowClass"
          :scrollable="true"
          :virtualScroll="true"
          @virtual-scroll="onVirtualScroll"
        >
          <template #header>
            <div class="text-left">
              <div>
                <div v-if="propItems.length" class="font-weight-normal">
                  {{
                    $t("label_show_from_to", null, {
                      from: 1,
                      to: propItems.length,
                      total: propTotal,
                    })
                  }}
                </div>
              </div>
            </div>
          </template>
          <template #empty v-if="propItems && propItems.length === 0">
            <span class="text-center">{{ $t("no_data") }}</span>
          </template>
          <Column
            headerStyle="width: 3rem; text-align: center!important"
            bodyStyle="text-align: center;"
          >
            <template #body="slotProps">
              {{ slotProps.index + 1 }}
            </template></Column
          >
          <Column
            :headerClass="'p-frozen-column left'"
            :bodyClass="'p-frozen-column left'"
            headerStyle="width: 15rem;"
            bodyStyle="width: 15rem;"
            field="full_name"
            :header="$t('label_customer_name')"
          >
          </Column>
          <Column
            headerStyle="width: 8rem;"
            bodyStyle="width: 8rem;"
            :header="$t('label_prefix_for_name')"
            ><template #body="slotProps">
              {{ getPrefix4Name(slotProps.data) }}
            </template>
          </Column>
          <Column
            :header="$t('label_registration_date')"
            headerStyle="width: 12rem"
            bodyStyle="width: 12rem"
          >
            <template #body="slotProps">
              {{ formatUnixDateTime2String(slotProps.data.created_at) }}
            </template>
          </Column>
          <Column
            headerStyle="width: 10rem;"
            bodyStyle="width: 10rem;"
            field="phone_number"
            :header="$t('label_phone_number')"
          >
          </Column>
          <Column
            headerStyle="width: 18rem;"
            bodyStyle="width: 18rem;"
            field="email"
            :header="$t('label_email')"
          >
          </Column>
          <Column
            headerStyle="width: 20rem;"
            bodyStyle="width: 20rem;"
            field="address"
            :header="$t('label_address')"
          >
          </Column>
          <Column
            headerStyle="width: 20rem;"
            bodyStyle="width: 20rem;"
            field="organization"
            :header="$t('label_organisation_name')"
          >
          </Column>
        </DataTable>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import ContextMenu from "@/components/commons/contextmenu/ContextMenu";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import moment from "moment";
export default {
  props: {
    propItems: {
      type: Array,
      default: () => [],
    },
    propTable: {
      type: Object,
      default: () => {
        return {
          selected: null,
        };
      },
    },
    propTotal: {
      type: Number,
      default: 0,
    },
  },
  components: { CommonAccordion, ContextMenu, DataTable, Column },
  data() {
    return {
      menuModel: [
        {
          label: this.$t("label_customer_info"),
          icon: "fas fa-id-card",
          command: () => this.showDetail(this.propTable.selected),
          visible: true,
        },
      ],
    };
  },
  created() {},
  methods: {
    onVirtualScroll(e) {
      this.$emit("nextPage");
    },
    rowClass(data) {
      return !data.is_active ? "bg-secondary text-white" : "";
    },
    onRowContextMenu(event) {
      // if (
      //   this.propTable.selected &&
      //   Object.keys(this.propTable.selected).length > 0
      // ) {
      //   this.$refs.cmCustomerDatatable.show(event.originalEvent);
      // }
    },
    getPrefix4Name(data) {
      if (data && Object.keys(data).length > 0) {
        if (data.gender) {
          switch (data.gender + "") {
            case "1":
              return this.$t("label_mr");
              break;
            case "2":
              return this.$t("label_ms");
            case "3":
              return this.$t("label_other");
              break;
          }
        } else return "";
      } else return "";
    },

    formatUnixDateTime2String(value) {
      let result = "";
      if (value) {
        try {
          let date = new Date(value);
          result = moment
            .unix(date.getTime() / 1000)
            .format("HH:mm DD/MM/YYYY");
          result = result
            .replace(":", this.lang === "vi" ? "h" : ":")
            .replace(" ", this.lang === "vi" ? " ngày " : " ");
        } catch (error) {}
      }
      return result;
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss">
.customer-content__datatable {
  @import "@/assets/scss/_datatable.scss";
}
</style>