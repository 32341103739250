<template>
  <div
    class="datatable"
    :style="{ maxHeight: propTableHeight + 'px' }"
    @scroll="onScroll"
  >
    <ContextMenu :model="menuModel" ref="cmOrderDetailDatatable" />
    <DataTable
      :scrollable="true"
      :value="propItems"
      ref="dt"
      :resizableColumns="true"
      columnResizeMode="expand"
      selectionMode="single"
      dataKey="id"
      contextMenu
      :selection.sync="selected"
      @row-contextmenu="onRowContextMenu"
    >
      <template #header>
        <div class="caption uhe34db23F">
          <div>
            <div v-if="propItems.length" class="font-weight-normal">
              {{
                $t("label_show_from_to", null, {
                  from: 1,
                  to: propItems.length,
                  total: propTotal,
                })
              }}
            </div>
          </div>
          <div class="text-right d-flex">
            <div v-for="(item, index) in caption" :key="'caption' + index">
              <div class="d-flex priority-item">
                <div class="square" :style="{ background: item.color }"></div>
                <label class="pl-2 pr-3">{{ item.label }}</label>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #empty v-if="propItems && propItems.length === 0">
        <span class="text-center">{{ $t("label_no_data") }}</span>
      </template>
      <Column
        header=""
        headerStyle="width: 3rem; text-align: center!important"
        bodyStyle="text-align: center;padding:0"
      >
        <template #body="slotProps">
          <div :style="columnStyle(slotProps.data)">
            {{ slotProps.index + 1 }}
          </div>
        </template></Column
      >
      <Column :header="$t('label_order_time')" headerStyle="width: 12.5rem;">
        <template #body="slotProps">
          {{ formatUnixDateTime2String(slotProps.data.created_at) }}
        </template>
      </Column>
      <Column :header="$t('label_user_order')" headerStyle="width: 9rem;">
        <template #body="slotProps">
          {{
            slotProps.data.information_buyer
              ? slotProps.data.information_buyer.full_name
              : ""
          }}
        </template>
      </Column>
      <Column field="note" :header="$t('label_order_description')"></Column>

      <Column :header="$t('label_order_reason')">
        <template #body="slotProps">
          {{ getRemark(slotProps.data) }}
        </template>
      </Column>
      <Column
        headerStyle="width: 4rem; text-align: center!important"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <Button
            icon="fas fa-clipboard-list"
            type="button"
            class="p-button-sm p-button-info"
            :title="$t('title_image_list')"
            @click="showOrderDetail(slotProps.data)"
          >
          </Button>
        </template>
      </Column>
    </DataTable>
  </div>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import Button from "@/components/commons/button/Button";
import ContextMenu from "@/components/commons/contextmenu/ContextMenu";
import moment from "moment";
export default {
  components: {
    CommonAccordion,
    DataTable,
    Column,
    Button,
    ContextMenu,
  },
  props: {
    propItems: {
      type: Array,
      default: () => [],
    },
    propPriorityLevel: {
      type: Array,
      default: () => [],
    },
    propOrderStatus: {
      type: Object,
      default: () => {},
    },
    propTotal: {
      type: Number,
      default: 0,
    },
    propTableHeight: {
      type: Number,
      default: 400,
    },
  },
  data() {
    return {
      selected: null,
      menuModel: [
        {
          label: this.$t("title_image_list"),
          icon: "fas fa-clipboard-list",
          command: () => this.showDialogOrderDetail(this.selected),
          visible: true,
        },
      ],
    };
  },
  methods: {
    async onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.$emit("nextPage");
      }
    },
    getRemark(data) {
      let curentStatus = data.current_status;
      if (curentStatus && Object.keys(curentStatus).length > 0) {
        let item = data.order_status.find(
          (x) => x.order_status.id === curentStatus.id
        );
        if (item && Object.keys(item).length > 0) return item.remark;
      }
      return null;
    },
    formatUnixDateTime2String(value) {
      let result = "";
      if (value) {
        try {
          let date = new Date(value);
          result = moment
            .unix(date.getTime() / 1000)
            .format("HH:mm DD/MM/YYYY");
          result = result
            .replace(":", this.lang === "vi" ? "h" : ":")
            .replace(" ", this.lang === "vi" ? " ngày " : " ");
        } catch (error) {}
      }
      return result;
    },
    rowClass() {},
    columnStyle(val) {
      let result = "";
      let priority = val.priority ? val.priority : null;
      if (priority && Object.keys(priority).length > 0) {
        try {
          let objVal = JSON.parse(priority.value);
          if (objVal && Object.keys(objVal).length > 0) {
            if (objVal.color || objVal.COLOR) {
              result =
                "background:" + (objVal.color ? objVal.color : objVal.COLOR);
            }
          }
        } catch (error) {}
      }
      return result;
    },
    onRowContextMenu(event) {
      if (this.selected && Object.keys(this.selected).length > 0) {
        this.$refs.cmOrderDetailDatatable.show(event.originalEvent);
      }
    },
    shoDialogUpdateStatus(item) {
      this.$emit("shoDialogUpdateStatus", item);
    },
    showDialogOrderDetail(item) {
      this.$emit("showOrderDetail", item);
    },
    showOrderDetail(item) {
      this.$emit("showOrderDetail", item);
    },
    goDetailPage(val) {
      if (this.$route.name != "OrderDetail") {
        this.$router.push({
          name: "OrderDetail",
          params: {
            id: val,
          },
        });
      }
    },
    pageSizechanged(val) {
      this.$emit("pageSizechanged", {
        val: val,
      });
    },
    pagechanged(val) {
      this.$emit("pagechanged", {
        val: val,
      });
    },
  },
  computed: {
    caption() {
      let results = [];
      for (let i = 0; i < this.propPriorityLevel.length; i++) {
        let objVal = JSON.parse(this.propPriorityLevel[i].value);
        if (objVal && Object.keys(objVal).length > 0) {
          if (objVal.color || objVal.COLOR) {
            results.push({
              label: objVal.value ? objVal.value : objVal.VALUE,
              color: objVal.color ? objVal.color : objVal.COLOR,
            });
          }
        }
      }
      return results;
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    scrollHeight() {
      return this.documentHeight - 56 - 48 - 2 * 37 - 50 - 36 - 2 * 16;
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>

<style lang="scss" scoped>
.datatable {
  padding: 0.25rem;
  overflow-y: auto;
}
</style>
<style lang="scss" >
.order__tabs {
  .datatable {
    button {
      width: 1.825rem !important;
      height: 1.825rem !important;
      padding: 0.25rem !important;
      &:first-child {
        margin-right: 0.25rem;
      }
    }
  }
  .caption {
    padding: 0.5rem 0.25rem 0.25rem;
    width: 100%;
    &.uhe34db23F {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
    label {
      margin: 0;
    }
    .priority-item {
      margin-left: 0.5rem;
      &:first-child {
        margin: 0;
      }
    }
    .square {
      height: 20px;
      width: 20px;
    }
  }
}
</style>