var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-layout',{attrs:{"isToolbar":true,"isBreadcrumb":true,"breadcrumbitems":[
    { label: _vm.$t('menu_administration') },
    { label: _vm.$t('menu_management_permission') } ],"propButtonsState":{
    save: _vm.buttons.save,
    add: _vm.buttons.add,
    edit: _vm.buttons.edit,
    reload: _vm.buttons.reload,
    print: {
      visible: false,
    },
    delete: _vm.buttons.delete,
  },"propTitle":_vm.$t('menu_management_permission')},on:{"emitFunction":function($event){return _vm.menuBarFunction($event)}}},[_c('div',{staticClass:"permission-content"},[_c('div',{staticClass:"permission-content__dialog"},[(_vm.confirm.show)?_c('DialogConfirmation',{attrs:{"prop-show":_vm.confirm.show,"prop-title":_vm.confirm.title,"prop-icon":_vm.confirm.icon,"prop-message":_vm.confirm.message,"textNo":_vm.$t('button_text_close'),"textYes":_vm.$t('button_text_save')},on:{"cancel":_vm.onCancelConfirm,"confirmed":_vm.onConfirmed}}):_vm._e(),(_vm.confirmDelete.show)?_c('DialogConfirmation',{attrs:{"prop-show":_vm.confirmDelete.show,"prop-title":_vm.$t('title_message_confirm'),"prop-icon":_vm.confirmDelete.icon,"prop-message":_vm.confirmDelete.message,"textNo":_vm.$t('button_text_close'),"textYes":_vm.$t('button_text_delete')},on:{"cancel":_vm.onCancelConfirmDelete,"confirmed":_vm.onConfirmedDelete}}):_vm._e(),(_vm.message.length > 0)?_c('DialogMessage',{attrs:{"propTitle":_vm.$t('title_message_info'),"prop-show":_vm.message.length > 0,"prop-message":_vm.message},on:{"close":function($event){_vm.message = []}}}):_vm._e()],1),_c('div',{staticClass:"permission-content__datatable"},[_c('DataTable',{ref:"permissionDatatable",attrs:{"propData":{
          items: _vm.permission.items,
          page_number: _vm.permission.page_number,
          page_size: _vm.permission.page_size,
          total_page: _vm.permission.total_page,
          total_records: _vm.permission.total_records,
          permissions: _vm.permission.permissions,
        },"prop-table":_vm.permission.table,"prop-first":_vm.permission.first,"prop-loading":_vm.permission.loading},on:{"rowClick":_vm.rowClick}})],1),_c('div',{staticClass:"permission-content__structure"},[_c('ValidationObserver',{ref:"permisisonObserver"},[_c('Structure',{attrs:{"prop-entity":_vm.permission.entity,"prop-status":{
            isAdd: _vm.permission.isAdd,
            isEdit: _vm.permission.isEdit,
          }}})],1),_c('div',{staticClass:"actions"},[_c('Button',{staticClass:"p-button-rounded p-button-secondary",attrs:{"icon":_vm.buttons.prev.processing
              ? 'fa fa-spinner fa-spin'
              : 'pi pi-chevron-left',"disabled":_vm.buttons.prev.disabled},on:{"click":_vm.prevItem}}),_c('Button',{staticClass:"p-button-rounded p-button-secondary",attrs:{"icon":_vm.buttons.next.processing
              ? 'fa fa-spinner fa-spin'
              : 'pi pi-chevron-right',"disabled":_vm.buttons.next.disabled},on:{"click":_vm.nextItem}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }