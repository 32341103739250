<template>
  <div slot="slot-content">
    <spinner :propLoading="loading.show" :propShape="loading.shape" v-if="loading.show"  :prop-text="loading.text"></spinner>
    <UploadHistoryPage/>
  </div>
</template>
<script>
import Spinner from "@/components/commons/spinners/Index";
import UploadHistoryPage from "@/components/pages/uploadhistory/UploadHistory"
export default {
  name: 'upload',
  components: {
    UploadHistoryPage,Spinner
  },
  created(){},
  data(){
    return{}
  },
  computed:{
    loading(){
      return this.$store.getters.getSpinnerApp
    }
  }
}
</script>
