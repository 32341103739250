
<template>
  <div class="huGHgeKd82d">    
      <PieChart :chartdata="pie.chartData" :options="pie.options" />
  </div>
</template>
<script>
import PieChart from "@/components/commons/chart/Pie2";
export default {
  components: {
    PieChart,
  },
  props: {
    propData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      id: new Date().getTime(),
      loading:true,
      
      pie: {
        chartData: {
          labels: [],
          datasets: [
            {
              label: "Số lượng",
              backgroundColor: [],
              data: [],
            },
          ],
        },
        options: {
          id: "hod",
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true,
            labels: {
              generateLabels: function (chart) {
                var data = chart.data;
                if (data.labels.length && data.datasets.length) {
                  return data.labels.map(function (label, i) {
                    var meta = chart.getDatasetMeta(0);
                    var ds = data.datasets[0];
                    var fill = data.datasets[0].backgroundColor[i];
                    var stroke = "transparent";
                    return {
                      text:
                        parseFloat(
                          (ds.data[i] * 100) /
                            ds.data.reduce((a, b) => a + b, 0)
                        ).toFixed(2) +
                        "% " +
                        label,
                      fillStyle: fill,
                      strokeStyle: stroke,
                      hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                      index: i,
                    };
                  });
                }
                return [];
              },
            },
          },
        },
      },
    };
  },
  created() {
    this.id =
      this.id -
      Math.floor(Math.random() * 5000) +
      Math.floor(Math.random() * 9999);

    
  },
  watch: {
    propData: {
      handler(val) {
        this.pie.chartData.labels = [];
        this.pie.chartData.datasets[0].data=[];
        this.pie.chartData.datasets[0].backgroundColor=[];
        if (val && val.length > 0) {
          for (let i = 0; i < val.length; i++) {
            if(val[i].satellite){
              let color =  this.getRandomColor();
              this.pie.chartData.labels.push(val[i].satellite.value)
              this.pie.chartData.datasets[0].backgroundColor.push(color);
              this.pie.chartData.datasets[0].data.push(val[i].total)
            }
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    getRandomColor() {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    hexToRgbA(hex, opacity) {
      if (!opacity) opacity = "0.4";
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.huGHgeKd82d {
  position: relative;
  height: 200px;
  width: 100%;
}
</style>
