<template>
  <Dialog
    :header="$t('title_image_info')"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :maximizable="false"
    :modal="true"
    :contentStyle="propStyle"
    @hide="onClose"
  >
    <div class="infomation">
      <table class="table table-bordered">
        <tbody>
          <tr>
            <td>{{ $t("label_image_name") }}</td>
            <td>{{ propInfo.image_name }}</td>
          </tr>
          <tr>
            <td>{{ $t("label_source_id") }}</td>
            <td>{{ propInfo.source_id }}</td>
          </tr>
          <tr>
            <td>{{ $t("label_username") }}</td>
            <td>{{ propInfo.created_by }}</td>
          </tr>
          <tr>
            <td>{{ $t("label_upload_time") }}</td>
            <td>{{ formatUnixDateTime2String(propInfo.created_at) }}</td>
          </tr>
        </tbody>
      </table>
      <table
        class="table table-bordered"
        style="width: 100%; table-layout: fixed; white-space: nowrap"
      >
        <tbody>
          <tr>
            <td colspan="2">
              <strong>{{ $t("label_statistic_download") }} </strong>
            </td>
          </tr>
          <tr>
            <td>
              <div class="form-row">
                <div class="col">
                  <label class="pt-2">{{ $t("label_date_from") }}</label>
                </div>
                <div class="col">
                  <date-picker
                    v-model="search.from"
                    :format="'DD/MM/YYYY'"
                    valueType="timestamp"
                    :clearable="false"
                    :disabled-date="notAfterToday"
                  >
                  </date-picker>
                </div>
              </div>
            </td>
            <td>
              <div class="form-row">
                <div class="col">
                  <label class="pt-2">{{ $t("label_date_to") }}</label>
                </div>
                <div class="col">
                  <date-picker
                    v-model="search.to"
                    :format="'DD/MM/YYYY'"
                    valueType="timestamp"
                    :clearable="false"
                    :disabled-date="notAfterToday"
                  >
                  </date-picker>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <div class="datatable">
                <DataTable
                  :loading="propLoading"
                  :value="propDownHistory"
                  rowGroupMode="subheader"
                  groupRowsBy="created_by"
                  sortMode="single"
                  sortField="created_by"
                  :sortOrder="1"
                >
                  <template
                    #empty
                    v-if="propDownHistory && propDownHistory.length === 0"
                  >
                    <span class="text-center">{{ $t("label_no_data") }}</span>
                  </template>
                  <Column
                    field="created_by"
                    header=""
                    v-if="propDownHistory.length"
                  ></Column>
                  <Column
                    headerStyle="width: 3rem; text-align: center!important"
                    bodyStyle="text-align: center;"
                  >
                    <template #body="slotProps">
                      {{ slotProps.index + 1 }}
                    </template></Column
                  >
                  <Column
                    field="date_str"
                    :header="$t('label_download_time')"
                  ></Column>
                  <template #groupheader="slotProps">
                    <span class="image-text"
                      ><strong>{{ slotProps.data.created_by }}</strong
                      >&nbsp;({{ $t("lable_download_total") }}:&nbsp;{{
                        calculateGroupTotal(slotProps.data.created_by)
                      }})
                    </span>
                  </template>
                  <template #header>
                    <span style="text-align: left; width: 100%"
                      >{{ $t("lable_download_total") }}:&nbsp;<strong>{{
                        propDownHistory.length
                      }}</strong></span
                    >
                  </template>
                </DataTable>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <template #footer>
      <Button
        :label="$t('button_text_close')"
        :icon="'fas fa-times'"
        :class="'p-button-danger  p-button-sm mr-0'"
        @click="onClose"
      />
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
import Button from "@/components/commons/button/Button";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import DatePicker from "vue2-datepicker";
import { formatUnixDateTime2String } from "@/utils/commons/common";
export default {
  components: {
    Dialog,
    Button,
    DatePicker,
    DataTable,
    Column,
  },
  props: {
    propInfo: {
      type: Object,
      default: () => {},
    },
    propTitle: {
      type: String,
      default: "",
    },
    propStyle: {
      type: Object,
      default: () => {
        return {
          width: "480px",
        };
      },
    },
    propShow: {
      type: Boolean,
      default: false,
    },
    propSearch: {
      type: Object,
      default: () => {
        return {
          from: null,
          to: null,
        };
      },
    },
    propDownHistory: {
      type: Array,
      default: () => [],
    },
    propLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      show: false,
      search: {
        from: null,
        to: null,
      },
    };
  },
  created() {
    this.show = this.propShow;
    this.search.from = this.propSearch.from
      ? new Date(this.propSearch.from).getTime()
      : null;
    this.search.to = this.propSearch.to
      ? new Date(this.propSearch.to).getTime()
      : null;
  },
  watch: {
    propShow(val) {
      this.show = val;
    },
    "search.from"(newVal, oldVal) {
      if (oldVal && oldVal != newVal) {
        if (!newVal) {
          this.search.from = oldVal;
          this.$emit("message", [
            this.$t("message_search_from_time_is_required"),
          ]);
          return;
        }
        let f = new Date(newVal).setHours(0, 0, 0, 0);
        let t = new Date(this.search.to).setHours(23, 59, 59, 0);
        if (f - t > 0) {
          this.$emit("message", [
            this.$t(
              "message_search_to_time_must_greater_than_or_equal_from_time"
            ),
          ]);
          this.search.from = oldVal;
        } else {
          this.$emit("searchFromChange", f);
        }
      } else {
        if (!newVal) {
          this.search.from = oldVal;
          this.$emit("message", [
            this.$t("message_search_from_time_is_required"),
          ]);
        }
      }
    },
    "search.to"(newVal, oldVal) {
      if (oldVal && oldVal != newVal) {
        if (!newVal) {
          this.search.to = oldVal;
          this.$emit("message", [
            this.$t("message_search_to_time_is_required"),
          ]);
          return;
        }
        let f = new Date(this.search.from).setHours(0, 0, 0, 0);
        let t = new Date(newVal).setHours(23, 59, 59, 0);
        if (f - t > 0) {
          this.$emit("message", [
            this.$t(
              "message_search_to_time_must_greater_than_or_equal_from_time"
            ),
          ]);
          this.search.to = oldVal;
        } else {
          this.$emit("searchToChange", t);
        }
      } else {
        if (!newVal) {
          this.search.to = oldVal;
          this.$emit("message", [
            this.$t("message_search_to_time_is_required"),
          ]);
        }
      }
    },
  },
  methods: {
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    calculateGroupTotal(name) {
      let total = 0;
      if (this.propDownHistory) {
        for (let item of this.propDownHistory) {
          if (item.created_by === name) {
            total = total + item.total;
          }
        }
      }
      return total;
    },
    onClose() {
      this.show = false;
      this.$emit("close");
    },
    formatUnixDateTime2String(value) {
      return formatUnixDateTime2String(value);
    },
  },
};
</script>