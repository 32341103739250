<template>
  <div class="buyer-info">
    <CommonAccordion :isChevronEnd="true" :propTitle="$t('label_buyer_info')"
      ><div slot="content" class="accordion w-100">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="row no-gutters">
              <label class="col-12 col-md-4 col-form-label">{{
                $t("label_buyer_name")
              }}</label>
              <div class="col-12 col-md-8">
                <InputText
                  id="inutBuyerName"
                  class="form-control new-style w-100"
                  maxlength="50"
                  :value="propItem.information_buyer.full_name"
                  readonly
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="row no-gutters">
              <label class="col-12 col-md-4 col-form-label">{{
                $t("label_organisation_name")
              }}</label>
              <div class="col-12 col-md-8">
                <InputText
                  class="form-control new-style w-100"
                  maxlength="255"
                  :value="propItem.information_buyer.organization"
                  readonly
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <div class="row no-gutters">
              <label class="col-12 col-md-4 col-form-label">{{
                $t("label_phone_number")
              }}</label>
              <div class="col-12 col-md-8">
                <InputText
                  class="form-control new-style w-100"
                  maxlength="20"
                  v-model="propItem.information_buyer.phone_number"
                  readonly
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="row no-gutters">
              <label class="col-12 col-md-4 col-form-label">{{
                $t("label_email")
              }}</label>
              <div class="col-12 col-md-8">
                <InputText
                  class="form-control new-style w-100"
                  maxlength="150"
                  v-model="propItem.information_buyer.email"
                  readonly
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="row no-gutters">
              <label
                class="col-form-label"
                style="width: calc((100% / 6) - 4px)"
                >{{ $t("label_address") }}</label
              >
              <div class="" style="width: calc((100% / 6) * 5)">
                <InputText
                  class="form-control new-style w-100"
                  maxlength="255"
                  :value="propItem.information_buyer.address"
                  readonly
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonAccordion>
  </div>
</template>
<script>
import InputText from "@/components/commons/inputtext/InputText";
import CommonAccordion from "@/components/commons/accordion/Accordion2";
export default {
  components: {
    InputText,
    CommonAccordion,
  },
  props: {
    propItem: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="scss" scoped>
.buyer-info {
  .row {
    margin-bottom: 0.25rem;
  }
}
</style>