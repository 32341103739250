<template>
  <admin-layout>
    <slot name="slot-content"></slot>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/components/pages/layouts/Admin";
export default {
  name: "Layout",

  components: {
    AdminLayout,
  },
  props: {
    breadcrumbitems: {
      type: Array,
      default: () => [],
    },
    propButtonsState: {
      type: Object,
      default: () => {
        return {
          save: {
            disabled: false,
            processing: false,
          },
          cancel: {
            disabled: false,
            processing: false,
            visible: false,
          },
          add: {
            disabled: false,
            processing: false,
          },
          edit: {
            disabled: false,
            processing: false,
          },
          reload: {
            disabled: false,
            processing: false,
          },
          print: {
            show: false,
            disabled: false,
            processing: false,
          },
          search: {
            disabled: false,
            processing: false,
          },
        };
      },
    },
    propTitle: {
      type: String,
      default: "",
    },
    propTextSearch: {
      type: String,
      default: "",
    },
    propDescription: {
      type: Object,
      default: () => {
        return {
          text: "",
        };
      },
    },
    propMenusVisible: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
