<template>
  <main-layout
    :isToolbar="false"
    :isBreadcrumb="true"
    :breadcrumbitems="[
      { label: $t('menu_order_management') },
      { label: $t('menu_order_list') },
    ]"
    :propButtonsState="{
      save: {
        visible: false,
      },
      add: {
        visible: false,
      },
      edit: {
        visible: false,
      },
      reload: {
        visible: true,
      },
      print: {
        visible: false,
      },
    }"
    :propTitle="$t('menu_order_list')"
    @emitFunction="menuHeaderClick"
  >
    <div class="order-content">
      <div class="order__dialog">
        <div class="order__dialog--images">
          <DialogOrderDetail
            v-if="dlgOrderDetail.show"
            :prop-style="{ width: dlgOrderDetail.width }"
            :prop-show="dlgOrderDetail.show"
            :prop-title="dlgOrderDetail.title"
            :prop-items="dlgOrderDetail.value.images"
            :prop-order="dlgOrderDetail.value"
            :propImageTypes="imageTypes.items"
            :prop-shared="
              dlgOrderDetail.value.shared
                ? dlgOrderDetail.value.shared.map((x) => x.image_id)
                : []
            "
            :propOrderStatusConfirmed="
              dlgOrderDetail.value.current_status.name === 'ORDER_CONFIRMED'
            "
            :propOrderStatusCompleted="
              dlgOrderDetail.value.current_status.name === 'ORDER_COMPLETED'
            "
            :propOrderStatusReject="
              dlgOrderDetail.value.current_status.name === 'ORDER_REJECT'
            "
            :prop-buttons="buttons"
            @close="
              dlgOrderDetail.show = false;
              dlgOrderDetail.value = null;
            "
            @showDialogShare="showDialogShare"
            @showDialogMetadata="showDialogMetadata"
            @onApprove="onApprove"
            @message="message = $event"
          />
        </div>

        <DialogShare
          v-if="dlgShare.show"
          :prop-style="{ width: dlgShare.width }"
          :prop-show="dlgShare.show"
          :prop-title="dlgShare.title"
          :prop-items="dlgShare.value"
          :propOrderBy="dlgOrderDetail.value.order_by"
          :propButtonShare="buttons.share"
          @close="
            dlgShare.show = false;
            dlgShare.value = null;
          "
          @onShare="onShare"
        />
        <DialogUpdateStatus
          v-if="dlgUpdateStatus.show"
          :prop-style="{
            width: dlgUpdateStatus.width,
            overflowY: 'inherit!important',
          }"
          :prop-show="dlgUpdateStatus.show"
          :prop-title="dlgUpdateStatus.title"
          :prop-tab-id="
            dlgUpdateStatus.value.current_status
              ? dlgUpdateStatus.value.current_status.id
              : null
          "
          :prop-item="dlgUpdateStatus.value"
          :prop-button="order.table.buttons.update_status"
          :propOrderStatus="orderStatus.items"
          @close="
            dlgUpdateStatus.show = false;
            dlgUpdateStatus.value = null;
          "
          @onUpdateStatus="onUpdateStatus"
        />

        <DialogRemark
          v-if="dlgDialogRemark.show"
          :prop-style="{
            width: dlgDialogRemark.width,
          }"
          :prop-show="dlgDialogRemark.show"
          :prop-title="dlgDialogRemark.title"
          :prop-value="dlgDialogRemark.value"
          @close="
            dlgDialogRemark.show = false;
            dlgDialogRemark.value = null;
            dlgDialogRemark.title = null;
          "
        />

        <DialogMetadata
          v-if="dialogMetadata.show"
          :prop-style="{ width: dialogMetadata.width }"
          :prop-show="dialogMetadata.show"
          :prop-title="dialogMetadata.title"
          :prop-item="dialogMetadata.value"
          @close="
            dialogMetadata.show = false;
            dialogMetadata.value = null;
          "
        />
        <DialogUploadImages
          v-if="dialogUploadFile.show"
          ref="dlgUploadImages"
          :prop-style="{ width: dialogUploadFile.width }"
          :prop-show="dialogUploadFile.show"
          :prop-title="dialogUploadFile.title"
          :prop-value="dialogUploadFile.value"
          @close="
            dialogUploadFile.show = false;
            dialogUploadFile.value = null;
            files = [];
            filesUploaded.success = [];
            filesUploaded.error = [];
          "
          @uploadFiles="onUploadFiles"
          @message="message = $event"
        />
        <DialogAddImages
          v-if="dlgAddImages.show"
          :prop-show="dlgAddImages.show"
          :prop-images="dlgAddImages.value.images"
          :prop-order="dlgAddImages.value"
          :prop-style="{
            width: dlgAddImages.width,
          }"
          :prop-categories="
            categoryDataList && categoryDataList.items
              ? categoryDataList.items.filter((x) => code4Add.includes(x.name))
              : []
          "
          @close="
            dlgAddImages.show = false;
            dlgAddImages.value = null;
          "
          @selected="onUpdateImagesAdded"
        />
        <DialogMessage
          v-if="message.length > 0"
          :propTitle="$t('title_message_info')"
          :prop-show="message.length > 0"
          :prop-message="message"
          @close="message = []"
        />
      </div>
      <div class="order__search" id="elementSearch">
        <OrderSearch
          :prop-user="user.items"
          :prop-search="order.search"
          :prop-order-status="orderStatus.items"
          :propButtons="buttons"
          @onSearch="onSearch($event, true)"
          @message="message = $event"
          @accordionHeaderClick="accordionHeaderClick"
        ></OrderSearch>
      </div>
      <div class="order__datatable">
        <OrderDataTable
          :prop-items="order.items"
          :prop-total="order.total_records"
          :prop-loading="order.loading"
          :prop-table="order.table"
          :propButtonDetail="order.table.buttons.detail"
          @nextPage="nextPage"
          @showOrderDetail="showOrderDetail"
          @shoDialogUpdateStatus="shoDialogUpdateStatus"
          @shoDialogRemark="shoDialogRemark"
          @shoDialogUpload="shoDialogUpload"
          @shoDialogAddImages="shoDialogAddImages"
        />
      </div>
    </div>
  </main-layout>
</template>
<script>
import MainLayout from "@/components/pages/layouts/admin/Main";
import CommonAccordion from "@/components/commons/accordion/Accordion";
import { VueTabs, VTab } from "vue-nav-tabs";
import OrderSearch from "./Search";
import OrderDataTable from "./DataTable";
import orderFunc from "@/utils/functions/order";
import userFunc from "@/utils/functions/user";
import oauthFunc from "@/utils/functions/oauth";
import imageSharingFunc from "@/utils/functions/imagesharing";
import cryptoFunc from "@/utils/functions/crypto";
import DialogMetadata from "@/components/commons/metadata/Metadata";
import DialogOrderDetail from "./list/DialogOrderDetail.vue";
import DialogShare from "./list/DialogShare.vue";
import DialogUpdateStatus from "./list/DialogUpdateStatus.vue";
import DialogMessage from "@/components/commons/dialog/MessageBox";
import DialogRemark from "./list/DialogRemark.vue";
import DialogAddImages from "./list/AddImages.vue";
import DialogUploadImages from "./list/DialogUpload.vue";
import Canceled from "./tabs/Canceled.vue";
import Rejected from "./tabs/Rejected.vue";
import Processing from "./tabs/Processing.vue";
import Created from "./tabs/Created.vue";
import Completed from "./tabs/Completed.vue";
import metaKeyFunc from "@/utils/functions/metakey";
import fileFunc from "@/utils/functions/file";
import imageFunc from "@/utils/functions/image";
import { getMimeType } from "@/utils/commons/mimetypes";
import { rawDataCode, rawDataCode4Add } from "@/utils/commons/environment";
export default {
  components: {
    OrderSearch,
    OrderDataTable,
    MainLayout,
    DialogOrderDetail,
    DialogShare,
    DialogUpdateStatus,
    DialogMessage,
    VueTabs,
    VTab,
    CommonAccordion,
    Completed,
    Created,
    Processing,
    Rejected,
    Canceled,
    DialogMetadata,
    DialogRemark,
    DialogUploadImages,
    DialogAddImages,
  },
  data() {
    return {
      message: [],
      code4Add: [],
      files: [],
      filesUploaded: {
        success: [],
        error: [],
      },
      tabSelected: 0,
      tabIndex: null,
      order: {
        created: {
          items: [],
          page_number: 0,
          page_size: 10,
          total_page: 0,
          total_records: 0,
          is_bottom: false,
          loadding: false,
          loaded: false,
          page: 0,
        },
        canceled: {
          items: [],
          page_number: 0,
          page_size: 10,
          total_page: 0,
          total_records: 0,
          is_bottom: false,
          loadding: false,
          loaded: false,
          page: 0,
        },
        rejected: {
          items: [],
          page_number: 0,
          page_size: 10,
          total_page: 0,
          total_records: 0,
          is_bottom: false,
          loadding: false,
          loaded: false,
          page: 0,
        },
        processing: {
          items: [],
          page_number: 0,
          page_size: 10,
          total_page: 0,
          total_records: 0,
          is_bottom: false,
          loadding: false,
          loaded: false,
          page: 0,
        },
        completed: {
          items: [],
          page_number: 0,
          page_size: 10,
          total_page: 0,
          total_records: 0,
          is_bottom: false,
          loadding: false,
          loaded: false,
          page: 0,
        },

        items: [],
        table: {
          selected: null,
          height: 400,
          buttons: {
            detail: {
              disable: false,
              processing: false,
              id: null,
            },
            update_status: {
              disable: false,
              processing: false,
              id: null,
            },
          },
        },
        search: {
          page: 0,
          size: 2147483647,
          create_from: null,
          create_to: null,
          user_id: null,
          status_id: null,
        },
        is_bottom: false,
        loading: false,
      },
      dlgOrderDetail: {
        show: false,
        title: this.$t("title_image_list"),
        value: null,
        width: "480px",
      },
      dlgShare: {
        show: false,
        title: this.$t("title_share"),
        value: null,
        width: "480px",
      },
      dlgUpdateStatus: {
        show: false,
        title: this.$t("title_update_status"),
        value: null,
        width: "540px",
      },
      dialogMetadata: {
        show: false,
        title: "Metadata",
        value: null,
        width: "540px",
      },

      dlgDialogRemark: {
        show: false,
        title: null,
        value: null,
        width: "540px",
      },
      dialogUploadFile: {
        show: false,
        title: this.$t("title_upload_images"),
        value: null,
        width: "480px",
      },
      dlgAddImages: {
        show: false,
        title: this.$t("title_add_images"),
        value: null,
        width: "540px",
      },
      buttons: {
        filter: {
          disabled: false,
          processing: false,
          visible: true,
        },
        reload: {
          disabled: false,
          processing: false,
          visible: true,
        },
        approve: {
          disabled: false,
          processing: false,
          visible: true,
        },
        share: {
          disabled: false,
          processing: false,
          visible: true,
        },
      },
      user: {
        items: [],
      },
      orderStatus: {
        items: [],
        data: [],
      },
      priorityLevel: {
        items: [],
      },
      imageTypes: {
        items: [],
      },
      categoryDataList: {
        items: [],
      },
    };
  },
  computed: {
    accessToken() {
      return this.$store.getters.getAccessToken;
    },
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    tableHeight() {
      return this.documentHeight - 56 - 122 - 2 * 42;
    },
    lang() {
      return this.$store.getters.getLang;
    },
    basemap() {
      return this.$store.getters.getBasemap;
    },
  },
  async created() {
    var vm = this;
    if (vm.documentWidth < 576) {
      this.dlgOrderDetail.width = this.documentWidth + "px";
      this.dlgShare.width = this.documentWidth + "px";
      this.dialogMetadata.width = this.documentWidth + "px";
      this.dlgUpdateStatus.width = this.documentWidth + "px";
      this.dlgDialogRemark.width = this.documentWidth + "px";
      this.dialogUploadFile.width = this.documentWidth + "px";
      this.dlgAddImages.width = this.documentWidth + "px";
    } else if (vm.documentWidth >= 576 && vm.documentWidth < 768) {
      this.dlgOrderDetail.width = "540px";
      this.dlgShare.width = "540px";
      this.dialogMetadata.width = "540px";
      this.dlgUpdateStatus.width = "480px";
      this.dlgDialogRemark.width = "480px";
      this.dialogUploadFile.width = "540px";
      this.dlgAddImages.width = "540px";
    } else if (vm.documentWidth >= 768 && vm.documentWidth < 992) {
      this.dlgOrderDetail.width = "720px";
      this.dlgShare.width = "720px";
      this.dialogMetadata.width = "720px";
      this.dlgUpdateStatus.width = "480px";
      this.dlgDialogRemark.width = "480px";
      this.dialogUploadFile.width = "720px";
      this.dlgAddImages.width = "720px";
    } else {
      this.dlgOrderDetail.width = "960px";
      this.dlgShare.width = "720px";
      this.dialogMetadata.width = "960px";
      this.dlgUpdateStatus.width = "480px";
      this.dlgDialogRemark.width = "480px";
      this.dialogUploadFile.width = "960px";
      this.dlgAddImages.width = "960px";
    }
    this.order.search.size = Math.ceil(this.tableHeight / 40) + 5;
    this.getAllOrder(true);
    this.getAllCustomer();
    this.getAllOrderStatus();
    this.getAllBasemaps();
    this.getAllImageTypes();
    this.getAllCategoryDataList();
    this.code4Add = rawDataCode4Add ? rawDataCode4Add.split(",") : [];
  },
  mounted() {
    setTimeout(() => {
      this.order.table.height = this.calcTableHeight();
    }, 50);
  },
  watch: {
    lang() {
      this.getOrderStatusByLang();
    },
  },
  methods: {
    menuHeaderClick(val) {
      switch (val.code) {
        case "RELOAD":
          let size = this.order.search.size;
          this.order.search = {};
          this.order.search.create_from = null;
          this.order.search.create_to = null;
          this.order.search.user_id = null;
          this.order.search.status_id = null;
          this.order.items = [];
          this.order.search.page = 0;
          this.order.search.size = size;
          this.order.is_bottom = false;
          this.getAllOrder(true);
          break;
      }
    },
    accordionHeaderClick() {
      setTimeout(() => {
        this.order.table.height = this.calcTableHeight();
      }, 100);
    },
    calcTableHeight() {
      let element = document.getElementById("elementSearch");
      return (
        this.documentHeight -
        3 * 0.5 * 16 -
        56 -
        (element ? element.offsetHeight : 0) -
        24 -
        2 * 0.5 * 16
      );
    },
    nextPage() {
      if (!this.order.is_bottom) {
        this.order.search.page = this.order.search.page + 1;
        this.getAllOrder();
      }
    },
    nextPage2() {
      let obj = this.orderStatus.items.find((x) => x.id === this.tabSelected);
      if (obj && Object.keys(obj).length > 0) {
        switch (obj.name) {
          case "ORDER_CANCELED":
            if (!this.order.canceled.is_bottom) {
              this.order.canceled.page = this.order.canceled.page + 1;
              this.getAllOrderPage();
            }
            break;
          case "ORDER_CREATED":
            if (!this.order.created.is_bottom) {
              this.order.created.page = this.order.created.page + 1;
              this.getAllOrderPage();
            }
            break;
          case "ORDER_PROCESSING":
            if (!this.order.processing.is_bottom) {
              this.order.processing.page = this.order.processing.page + 1;
              this.getAllOrderPage();
            }
            break;
          case "ORDER_REJECTED":
            if (!this.order.rejected.is_bottom) {
              this.order.rejected.page = this.order.rejected.page + 1;
              this.getAllOrderPage();
            }
            break;
          case "ORDER_COMPLETED":
            if (!this.order.completed.is_bottom) {
              this.order.completed.page = this.order.completed.page + 1;
              this.getAllOrderPage();
            }
            break;
          default:
            break;
        }
      }
    },
    tabChange(index, newTab, oldTab) {
      if (newTab) {
        this.tabSelected =
          newTab.$attrs && newTab.$attrs.value ? newTab.$attrs.value : null;
        this.order.search.status_id = this.tabSelected;
        let obj = this.orderStatus.items.find((x) => x.id === this.tabSelected);
        if (obj && Object.keys(obj).length > 0) {
          switch (obj.name) {
            case "ORDER_CANCELED":
              if (!this.order.canceled.loaded) this.getAllOrderPage();
              break;
            case "ORDER_CREATED":
              if (!this.order.created.loaded) this.getAllOrderPage();
              break;
            case "ORDER_PROCESSING":
              if (!this.order.processing.loaded) this.getAllOrderPage();
              break;
            case "ORDER_REJECTED":
              if (!this.order.rejected.loaded) this.getAllOrderPage();
              break;
            case "ORDER_COMPLETED":
              if (!this.order.completed.loaded) this.getAllOrderPage();
              break;
            default:
              break;
          }
        }
      }
    },
    shoDialogAddImages(item) {
      this.dlgAddImages.value = item;
      this.dlgAddImages.show = true;
    },
    shoDialogUpload(item) {
      this.dialogUploadFile.value = item;
      this.dialogUploadFile.show = true;
    },
    shoDialogRemark(data) {
      this.dlgDialogRemark.value = data.remark;
      this.dlgDialogRemark.title = data.title;
      this.dlgDialogRemark.show = true;
    },
    shoDialogUpdateStatus(item) {
      this.dlgUpdateStatus.value = item;
      this.dlgUpdateStatus.show = true;
    },
    showOrderDetail(item) {
      this.dlgOrderDetail.value = item;
      this.dlgOrderDetail.show = true;
      if (this.$store.getters.getPermissions.includes("ORDER_UPDATE_STATUS")) {
        if (item && Object.keys(item).length > 0) {
          let currentStatus = item.current_status;
          if (currentStatus && Object.keys(currentStatus).length > 0) {
            if (currentStatus.name === "ORDER_CREATED") {
              let index = this.orderStatus.items.findIndex(
                (x) => x.name === "ORDER_PROCESSING"
              );
              if (index >= 0) {
                this.onUpdateStatus(
                  {
                    status_id: this.orderStatus.items[index].id
                      ? this.orderStatus.items[index].id
                      : null,
                    remark: "..",
                  },
                  false,
                  item.id,
                  false
                );
                this.tabSelected = currentStatus.id;
                setTimeout(() => {
                  this.onSearch(this.order.search);
                  this.tabSelected = this.orderStatus.items[index].id;
                  this.tabIndex = index;
                }, 500);
              }
            }
          }
        }
      }
    },
    async onApprove() {
      let index = this.orderStatus.items.findIndex(
        (x) => x.name === "ORDER_CONFIRMED"
      );
      if (index >= 0) {
        this.buttons.approve.disabled = true;
        this.buttons.approve.processing = true;
        await this.onUpdateStatus(
          {
            status_id: this.orderStatus.items[index].id
              ? this.orderStatus.items[index].id
              : null,
            remark: "..",
          },
          false,
          this.dlgOrderDetail.value.id,
          true
        );
        //this.tabSelected = this.orderStatus.items[index].id;
        //this.tabIndex = index;

        this.buttons.approve.disabled = false;
        this.buttons.approve.processing = false;
      }
    },

    showDialogMetadata(item) {
      this.dialogMetadata.value = item;
      this.dialogMetadata.show = true;
    },
    showDialogShare(item) {
      this.dlgShare.value = item.map((x) => x.image);
      this.dlgShare.show = true;
    },

    async onUpdateStatus(
      entity,
      showMsg = true,
      orderId = null,
      isUpdateDialog = false,
      isLoadData = true
    ) {
      entity.remark = entity.remark
        ? entity.remark.trim().replace(/\s\s+/g, " ")
        : null;
      if (!entity.status_id) {
        if (showMsg) this.message.push(this.$t("message_status_is_required"));
      }
      if (!entity.remark) {
        if (showMsg) {
          try {
            let itemStatus = this.orderStatus.items.find(
              (x) => x.id === entity.status_id
            );
            if (itemStatus && Object.keys(itemStatus).length > 0) {
              switch (itemStatus.name) {
                case "ORDER_REJECTED":
                  this.message.push(this.$t("message_reason_is_required"));
                  break;
                case "ORDER_PROCESSING":
                  this.message.push(this.$t("message_note_is_required"));
                  break;

                case "ORDER_COMPLETED":
                  this.message.push(this.$t("message_note_is_required"));
                  break;
                case "ORDER_CANCELED":
                  this.message.push(this.$t("message_reason_is_required"));
                  break;
                default:
                  this.message.push(this.$t("message_note_is_required"));
                  break;
              }
            } else {
              this.message.push(this.$t("message_note_is_required"));
            }
          } catch (error) {
            this.message.push(this.$t("message_note_is_required"));
          }
        }
      }
      if (this.message.length > 0) return;

      this.order.table.buttons.update_status.disable = true;
      this.order.table.buttons.update_status.processing = true;
      try {
        let response = await orderFunc.addStatus(
          {
            order_id: orderId ? orderId : this.dlgUpdateStatus.value.id,
            remark: entity.remark,
            status_id: entity.status_id,
          },
          this.$store.getters.getAccessToken
        );
        if (response.status === 200 || response.status === 201) {
          if (showMsg) {
            this.$toast.success({
              title: this.$t("title_message_info"),
              message: this.$t("message_update_order_status_success"),
              position: "top right",
            });
          }
          this.dlgUpdateStatus.value = null;
          this.dlgUpdateStatus.show = false;
          if (isLoadData) {
            this.order.items = [];
            this.order.search.page = 0;
            this.order.is_bottom = false;
            this.order.search.status_id = null; //entity.status_id;
            setTimeout(() => {
              // this.tabSelected = entity.status_id;
              // this.tabIndex = this.orderStatus.items.findIndex(
              //   (x) => x.id === entity.status_id
              // );
              this.onSearch(this.order.search);
            }, 500);
          }
          if (isUpdateDialog) {
            this.dlgOrderDetail.value.current_status =
              this.orderStatus.items.find((x) => x.id === entity.status_id);
          }
        } else {
          if (showMsg)
            this.message.push(this.$t("message_update_order_status_error"));
        }

        this.order.table.buttons.update_status.disable = false;
        this.order.table.buttons.update_status.processing = false;
      } catch (error) {
        this.order.table.buttons.update_status.disable = false;
        this.order.table.buttons.update_status.processing = false;
        if (error.response && error.response.status === 401)
          await this.refreshToken(this.onUpdateStatus, entity);
        else {
          if (showMsg) {
            let msg =
              error.response &&
              error.response.data &&
              error.response.data.message
                ? error.response.data.message
                : null;
            this.message.push(
              msg ? msg : this.$t("message_update_order_status_error")
            );
          }
        }
      }
    },
    async onShare(entity) {
      let message = [];
      entity.description = entity.description ? entity.description.trim() : "";
      if (!entity.description)
        message.push(this.$t("message_content_required"));

      if (entity.isExpired) {
        if (entity.expiredTime) {
          entity.expiredTime = new Date(entity.expiredTime).setHours(
            23,
            59,
            59,
            0
          );
          if (entity.expiredTime - new Date().getTime() < 0)
            message.push(
              this.$t("message_expired_time_must_greater_than_or_equal_today")
            );
        } else {
          message.push(this.$t("message_expired_time_required"));
        }
      }
      if (entity.isPass) {
        if (!entity.password) {
          message.push(this.$t("message_password_required"));
        }
      }
      if (entity.isNum) {
        if (!entity.download_number) {
          message.push(
            this.$t("message_numberdownload_must_greater_than_zero")
          );
        }
      }
      if (message.length > 0) {
        this.message = message;
        return;
      } else {
        await this.handleSharing(entity);
      }
    },
    async handleSharing(entity) {
      if (this.dlgShare.value && this.dlgShare.value.length > 0) {
        let ids = [];
        for (let i = 0; i < this.dlgShare.value.length; i++) {
          if (!ids.includes(this.dlgShare.value[i].id)) {
            let dto = {
              description: entity.description ? entity.description.trim() : "",
              download_number: entity.download_number,
              expired_time: entity.isExpired
                ? new Date(entity.expiredTime)
                : null,
              image_id: this.dlgShare.value[i].id,
              is_public: false,
              member_ids: [this.dlgOrderDetail.value.order_by.id],
              password:
                entity.isPass && entity.password ? entity.password : null,
              order_id: this.dlgOrderDetail.value
                ? this.dlgOrderDetail.value.id
                : null,
            };
            this.buttons.share.disabled = true;
            this.buttons.share.processing = true;
            try {
              let response = await imageSharingFunc.create(
                dto,
                this.$store.getters.getAccessToken
              );
              if (response.status === 200 || response.status === 201) {
                ids.push(this.dlgShare.value[i].id);
                this.$toast.success({
                  title: this.$t("title_message_info"),
                  message: this.$t(
                    "message_create_image_sharing_susscess",
                    null,
                    {
                      name: this.dlgShare.value[i].name
                        ? this.dlgShare.value[i].name
                        : "",
                    }
                  ),
                  position: "top right",
                });
                if (i === this.dlgShare.value.length - 1) {
                  this.dlgOrderDetail.show = false;
                  this.dlgOrderDetail.value = null;

                  this.dlgShare.show = false;
                  this.dlgShare.value = null;
                  this.order.items = [];
                  this.order.search.page = 0;
                  this.order.is_bottom = false;
                  this.onSearch(this.order.search);

                  this.buttons.share.disabled = false;
                  this.buttons.share.processing = false;
                  return;
                }
              } else {
                this.message.push(
                  this.$t("message_create_image_sharing_error", null, {
                    name: this.dlgShare.value[i].name
                      ? this.dlgShare.value[i].name
                      : "",
                  })
                );
              }

              this.buttons.share.disabled = false;
              this.buttons.share.processing = false;
            } catch (error) {
              this.buttons.share.disabled = false;
              this.buttons.share.processing = false;
              if (error.response && error.response.status === 401)
                await this.refreshToken(this.onShare, entity);
              else {
                let msg =
                  error.response.data && error.response.data.message
                    ? error.response.data.message
                    : null;
                this.message.push(
                  msg
                    ? msg
                    : this.$t("message_create_image_sharing_error", null, {
                        name: this.dlgShare.value[i].name
                          ? this.dlgShare.value[i].name
                          : "",
                      })
                );
              }
            }
          } else {
            if (
              ids.length ===
              [...new Set(this.dlgShare.value.map((x) => x.id))].length
            ) {
              this.dlgOrderDetail.show = false;
              this.dlgOrderDetail.value = null;

              this.dlgShare.show = false;
              this.dlgShare.value = null;
              this.order.items = [];
              this.order.search.page = 0;
              this.order.is_bottom = false;
              this.onSearch(this.order.search);

              this.buttons.share.disabled = false;
              this.buttons.share.processing = false;
              return;
            }
          }
        }
      }
    },

    async onUploadFiles(data) {
      this.files = Object.assign([], data);
      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].status != "SUCCESS") {
          if (!["SUCCESS", "PROCESS", "ERROR"].includes(this.files[i].status)) {
            this.files[i].status = "PROCESS";
            if (this.$refs.dlgUploadImages) {
              this.$refs.dlgUploadImages.updateStatus(this.files[i]);
            }
          }
          for (let j = 0; j < this.files[i].files.length; j++) {
            if (this.files[i].files[j].status != "SUCCESS") {
              this.uploadFileTest({
                id: this.files[i].id,
                file: this.files[i].files[j],
              });
            }
          }
        }
      }
    },

    async uploadFileTest(data) {
      var vm = this;
      let index = this.files.findIndex((x) => x.id === data.id);
      try {
        setTimeout(() => {
          Event.$emit("imageProgressBar", vm.files[index]);
        }, 100);
        let mimeType = getMimeType(
          data.file.name.split(".").pop().toLowerCase()
        );
        let responseGetUrl = await imageFunc.uploadImage(
          mimeType && mimeType.type
            ? mimeType.type
            : data.file.type
            ? data.file.type
            : "application/zip",
          this.$store.getters.getUserName + "/" + data.id,
          this.$store.getters.getAccessToken,
          data.file.name
        );
        if (responseGetUrl && responseGetUrl.status === 200) {
          if (responseGetUrl.data && responseGetUrl.data.url) {
            let res = await fileFunc.uploadFilePresignedUrl(
              responseGetUrl.data.url,
              data.file,
              mimeType && mimeType.type
                ? mimeType.type
                : data.file.type
                ? data.file.type
                : "application/zip"
            );
            if (res && res.status === 200) {
              vm.$toast.success({
                title: vm.$t("title_message_info"),
                message: vm.$t("message_upload_file_success", null, {
                  name: data.file.name,
                }),
                position: "top right",
              });
              if (index >= 0) {
                this.files[index].status = "SUCCESS";
                if (this.$refs.dlgUploadImages) {
                  this.$refs.dlgUploadImages.updateStatus(this.files[index]);
                }
                this.getMetadata(vm.files[index]);
              }
            } else {
              this.filesUploaded.error.push(data.id);
              if (index >= 0) {
                this.files[index].status = "ERROR";
                if (this.$refs.dlgUploadImages) {
                  this.$refs.dlgUploadImages.updateStatus(this.files[index]);
                }
              }

              setTimeout(() => {
                if (
                  vm.files.filter(
                    (x) => x.status === "SUCCESS" || x.status === "ERROR"
                  ).length === vm.files.length
                ) {
                  if (
                    this.filesUploaded.success.filter((x) => x != null).length >
                    0
                  ) {
                    this.onUpdateImagesOrder({
                      image_ids: [
                        ...this.filesUploaded.success,
                        ...(this.dialogUploadFile.value.images
                          ? this.dialogUploadFile.value.images.map(
                              (x) => x.image.id
                            )
                          : []),
                      ],
                      order_id: this.dialogUploadFile.value
                        ? this.dialogUploadFile.value.id
                        : null,
                    });
                  }
                }
              }, 300);

              vm.$toast.error({
                title: vm.$t("title_message_info"),
                message: vm.$t("message_upload_file_error", null, {
                  name: data.file.name,
                }),
                position: "top right",
              });
            }
            let progressValue = Math.ceil(
              (this.files.filter(
                (x) => x.status === "SUCCESS" || x.status === "ERROR"
              ).length *
                100) /
                this.files.length
            );
            if (this.$refs.dlgUploadImages) {
              this.$refs.dlgUploadImages.updateRemainingTime({
                progressValue: progressValue,
              });
            }
          } else {
            this.filesUploaded.error.push(data.id);
            if (index >= 0) {
              this.files[index].status = "ERROR";
              if (this.$refs.dlgUploadImages) {
                this.$refs.dlgUploadImages.updateStatus(this.files[index]);
              }
            }

            setTimeout(() => {
              if (
                vm.files.filter(
                  (x) => x.status === "SUCCESS" || x.status === "ERROR"
                ).length === vm.files.length
              ) {
                if (
                  this.filesUploaded.success.filter((x) => x != null).length > 0
                ) {
                  this.onUpdateImagesOrder({
                    image_ids: [
                      ...this.filesUploaded.success,
                      ...(this.dialogUploadFile.value.images
                        ? this.dialogUploadFile.value.images.map(
                            (x) => x.image.id
                          )
                        : []),
                    ],
                    order_id: this.dialogUploadFile.value
                      ? this.dialogUploadFile.value.id
                      : null,
                  });
                }
              }
            }, 300);

            let progressValue = Math.ceil(
              (this.files.filter(
                (x) => x.status === "SUCCESS" || x.status === "ERROR"
              ).length *
                100) /
                this.files.length
            );
            if (this.$refs.dlgUploadImages) {
              this.$refs.dlgUploadImages.updateRemainingTime({
                progressValue: progressValue,
              });
            }
            vm.$toast.error({
              title: vm.$t("title_message_info"),
              message: vm.$t("message_upload_file_error", null, {
                name: data.file.name,
              }),
              position: "top right",
            });
          }
        } else {
          this.filesUploaded.error.push(data.id);
          if (index >= 0) {
            this.files[index].status = "ERROR";
            if (this.$refs.dlgUploadImages) {
              this.$refs.dlgUploadImages.updateStatus(this.files[index]);
            }
          }

          setTimeout(() => {
            if (
              vm.files.filter(
                (x) => x.status === "SUCCESS" || x.status === "ERROR"
              ).length === vm.files.length
            ) {
              if (
                this.filesUploaded.success.filter((x) => x != null).length > 0
              ) {
                this.onUpdateImagesOrder({
                  image_ids: [
                    ...this.filesUploaded.success,
                    ...(this.dialogUploadFile.value.images
                      ? this.dialogUploadFile.value.images.map(
                          (x) => x.image.id
                        )
                      : []),
                  ],
                  order_id: this.dialogUploadFile.value
                    ? this.dialogUploadFile.value.id
                    : null,
                });
              }
            }
          }, 300);

          let progressValue = Math.ceil(
            (this.files.filter(
              (x) => x.status === "SUCCESS" || x.status === "ERROR"
            ).length *
              100) /
              this.files.length
          );
          if (this.$refs.dlgUploadImages) {
            this.$refs.dlgUploadImages.updateRemainingTime({
              progressValue: progressValue,
            });
          }
          vm.$toast.error({
            title: vm.$t("title_message_info"),
            message: vm.$t("message_upload_file_error", null, {
              name: data.file.name,
            }),
            position: "top right",
          });
        }
        Event.$emit("imageProgressBar", vm.files[index]);
        setTimeout(() => {
          if (
            vm.files.filter(
              (x) => x.status === "SUCCESS" || x.status === "ERROR"
            ).length === vm.files.length
          ) {
            setTimeout(() => {
              vm.$store.dispatch("showWidgetUploadProgressBar", false);
            }, 1000);
          }
        }, 300);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(uploadFileTest, data);
        } else {
          this.filesUploaded.error.push(data.id);
          if (index >= 0) {
            this.files[index].status = "ERROR";
            if (this.$refs.dlgUploadImages) {
              this.$refs.dlgUploadImages.updateStatus(this.files[index]);
            }
          }

          let progressValue = Math.ceil(
            (this.files.filter(
              (x) => x.status === "SUCCESS" || x.status === "ERROR"
            ).length *
              100) /
              this.files.length
          );
          if (this.$refs.dlgUploadImages) {
            this.$refs.dlgUploadImages.updateRemainingTime({
              progressValue: progressValue,
            });
          }

          let msg =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          vm.$toast.error({
            title: vm.$t("title_message_info"),
            message: msg
              ? msg
              : vm.$t("message_upload_file_error", null, {
                  name: data.file.name,
                }),
            position: "top right",
          });
        }
        Event.$emit("imageProgressBar", vm.files[index]);
        setTimeout(() => {
          if (
            vm.files.filter(
              (x) => x.status === "SUCCESS" || x.status === "ERROR"
            ).length === vm.files.length
          ) {
            setTimeout(() => {
              vm.$store.dispatch("showWidgetUploadProgressBar", false);
            }, 1000);
            if (
              this.filesUploaded.success.filter((x) => x != null).length > 0
            ) {
              this.onUpdateImagesOrder({
                image_ids: [
                  ...this.filesUploaded.success,
                  ...(this.dialogUploadFile.value.images
                    ? this.dialogUploadFile.value.images.map((x) => x.image.id)
                    : []),
                ],
                order_id: this.dialogUploadFile.value
                  ? this.dialogUploadFile.value.id
                  : null,
              });
            }
          }
        }, 300);
      }
    },
    onUpdateImagesAdded(items) {
      if (items && items.length > 0) {
        this.onUpdateImagesOrder({
          image_ids: [...new Set(items.map((x) => x.id))],
          order_id: this.dlgAddImages.value ? this.dlgAddImages.value.id : null,
        });
      }
    },
    async onUpdateImagesOrder(data) {
      try {
        let response = await orderFunc.updateImagesOrder(
          {
            image_ids: data.image_ids,
            order_id: data.order_id,
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data && response.data.success) {
            this.$toast.success({
              title: this.$t("title_message_info"),
              message: this.$t(
                "message_upload_images_order_tasking_success",
                null,
                {
                  name: this.dialogUploadFile.value
                    ? this.dialogUploadFile.value.order_code
                    : null,
                }
              ),
              position: "top right",
            });
            this.order.items = [];
            this.order.search.page = 0;
            this.order.is_bottom = false;
            this.getAllOrder(true);
            // let indexCheck = this.order.items.findIndex(
            //   (x) =>
            //     x.id ===
            //     (this.dialogUploadFile.show && this.dialogUploadFile.value
            //       ? this.dialogUploadFile.value.id
            //       : this.dlgAddImages.show && this.dlgAddImages.value
            //       ? this.dlgAddImages.value.id
            //       : null)
            // );
            // if (indexCheck >= 0) {
            //   if (
            //     response.data &&
            //     response.data.data &&
            //     response.data.data.images
            //   ) {
            //     if (
            //       this.order.items[indexCheck].images === null ||
            //       this.order.items[indexCheck].images === undefined
            //     )
            //       this.order.items[indexCheck].images = [];
            //     this.order.items[indexCheck].images = response.data.data.images;
            //   }
            // }
            this.dialogUploadFile.value = null;
            this.files = [];
            this.filesUploaded.success = [];
            this.filesUploaded.error = [];
            this.dialogUploadFile.show = false;
            this.dlgAddImages.show = false;
            this.dlgAddImages.value = null;
          } else {
            this.message.push(
              this.$t("message_upload_images_order_tasking_error", null, {
                name: this.dialogUploadFile.value
                  ? this.dialogUploadFile.value.order_code
                  : null,
              })
            );
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.onUpdateImagesOrder, data);
        } else {
          let msg =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push(
            msg
              ? msg
              : this.$t("message_upload_images_order_tasking_error", null, {
                  name: this.dialogUploadFile.value
                    ? this.dialogUploadFile.value.order_code
                    : null,
                })
          );
        }
      }
    },
    async getMetadata(item) {
      var vm = this;
      try {
        item.read_metadata = "PROCESSING";
        if (this.$refs.dlgUploadImages) {
          this.$refs.dlgUploadImages.updateStatusMetadata(item);
        }
        let categoryItem =
          this.categoryDataList && this.categoryDataList.items
            ? this.categoryDataList.items.find((x) => x.name === rawDataCode)
            : null;
        let response = await fileFunc.getMetadata(
          this.$store.getters.getUserName + "/" + item.id,
          this.$store.getters.getAccessToken,
          categoryItem ? categoryItem.id : null
        );
        if (response && response.status === 200) {
          if (response.data && response.data.success) {
            item.data_file =
              response.data && response.data.data ? response.data.data : null;
            item.loading_data = false;
            item.read_metadata = "SUCCESS";
            this.filesUploaded.success.push(
              response.data.data ? response.data.data.id : null
            );
            if (this.$refs.dlgUploadImages) {
              this.$refs.dlgUploadImages.updateStatusMetadata(item);
            }
          } else {
            item.loading_data = false;
            item.read_metadata = "ERROR";
            if (this.$refs.dlgUploadImages) {
              this.$refs.dlgUploadImages.updateStatusMetadata(item);
            }
            vm.$toast.error({
              title: vm.$t("title_message_info"),
              message: vm.$t("message_get_metadata_file_error", null, {
                name: item.name,
              }),
              position: "top right",
            });
          }
        } else {
          item.loading_data = false;
          item.read_metadata = "ERROR";
          if (this.$refs.dlgUploadImages) {
            this.$refs.dlgUploadImages.updateStatusMetadata(item);
          }
          vm.$toast.error({
            title: vm.$t("title_message_info"),
            message: vm.$t("message_get_metadata_file_error", null, {
              name: item.name,
            }),
            position: "top right",
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getMetadata, item);
        } else {
          item.loading_data = false;
          item.read_metadata = "ERROR";
          if (this.$refs.dlgUploadImages) {
            this.$refs.dlgUploadImages.updateStatusMetadata(item);
          }
          let msg =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          vm.$toast.error({
            title: vm.$t("title_message_info"),
            message: msg
              ? msg
              : vm.$t("message_get_metadata_file_error", null, {
                  name: item.name,
                }),
            position: "top right",
          });
        }
      }
      setTimeout(() => {
        if (
          this.files.filter(
            (x) => x.status === "SUCCESS" || x.status === "ERROR"
          ).length === this.files.length
        ) {
          if (
            this.filesUploaded.success.filter(
              (x) => x != null && x != undefined
            ).length > 0
          ) {
            this.onUpdateImagesOrder({
              image_ids: [
                ...this.filesUploaded.success,
                ...(this.dialogUploadFile.value.images
                  ? this.dialogUploadFile.value.images.map((x) => x.image.id)
                  : []),
              ],
              order_id: this.dialogUploadFile.value
                ? this.dialogUploadFile.value.id
                : null,
            });
          }
        }
      }, 300);
    },
    /*------------- Emit ------------------*/

    onSearch(data, loading) {
      this.order.search.create_from = data.create_from
        ? data.create_from
        : null;
      this.order.search.create_to = data.create_to ? data.create_to : null;
      this.order.search.user_id = data.user_id ? data.user_id : null;
      this.order.search.status_id = data.status_id ? data.status_id : null;
      this.order.items = [];
      this.order.search.page = 0;
      this.order.is_bottom = false;
      this.getAllOrder(loading);
    },
    onSearch2(data) {
      this.order.search.create_from = data.create_from;
      this.order.search.create_to = data.create_to;
      this.order.search.status_id = data.status_id;
      this.order.search.user_id = data.user_id;
      this.order.search.page = 0;
      this.order.items = [];
      this.order.is_bottom = false;
      let obj = this.orderStatus.items.find((x) => x.id === this.tabSelected);
      if (obj && Object.keys(obj).length > 0) {
        switch (obj.name) {
          case "ORDER_CANCELED":
            this.order.canceled.page = 0;
            this.order.canceled.items = [];
            this.order.canceled.is_bottom = false;
            break;
          case "ORDER_CREATED":
            this.order.created.page = 0;
            this.order.created.items = [];
            this.order.created.is_bottom = false;
            break;
          case "ORDER_PROCESSING":
            this.order.processing.page = 0;
            this.order.processing.items = [];
            this.order.processing.is_bottom = false;
            break;
          case "ORDER_REJECTED":
            this.order.rejected.page = 0;
            this.order.rejected.items = [];
            this.order.rejected.is_bottom = false;
            break;
          case "ORDER_COMPLETED":
            this.order.completed.page = 0;
            this.order.completed.items = [];
            this.order.completed.is_bottom = false;
            break;
          default:
            break;
        }
      }

      this.getAllOrderPage();
    },
    initTab() {},
    /*--------------- End emit ------------------*/

    /*---------------- Meta key ----------------*/
    getOrderStatusByLang() {
      this.orderStatus.items = [];
      for (let i = 0; i < this.orderStatus.data.length; i++) {
        if (
          this.orderStatus.data[i].translation &&
          this.orderStatus.data[i].translation[this.lang] &&
          Object.keys(this.orderStatus.data[i].translation[this.lang]).length >
            0
        ) {
          this.orderStatus.items.push({
            id: this.orderStatus.data[i].id,
            key: this.orderStatus.data[i].key,
            name: this.orderStatus.data[i].name,
            value: this.orderStatus.data[i].translation[this.lang].value
              ? this.orderStatus.data[i].translation[this.lang].value
              : this.orderStatus.data[i].value,
            description: this.orderStatus.data[i].translation[this.lang]
              .description
              ? this.orderStatus.data[i].translation[this.lang].description
              : this.orderStatus.data[i].description,
          });
        } else {
          this.orderStatus.items.push({
            id: this.orderStatus.data[i].id,
            key: this.orderStatus.data[i].key,
            name: this.orderStatus.data[i].name,
            value: this.orderStatus.data[i].value,
            description: this.orderStatus.data[i].description,
          });
        }
      }
    },
    async getAllCategoryDataList() {
      try {
        let response = await metaKeyFunc.getByKey(
          "CATEGORY",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success) {
            this.categoryDataList.items =
              response && response.data && response.data.data
                ? response.data.data
                : [];
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.getAllCategoryDataList, null);
        }
      }
    },
    async getAllImageTypes() {
      try {
        let response = await metaKeyFunc.getByKey(
          "IMAGE_TYPES",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.imageTypes.items =
            response && response.data && response.data.data
              ? response.data.data
              : [];
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllImageTypes);
        }
      }
    },
    async getAllBasemaps() {
      try {
        let response = await metaKeyFunc.getByKey(
          "BASEMAPS",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data && response.data.success) {
            let items = [];
            let data = response.data.data ? response.data.data : [];
            for (let i = 0; i < data.length; i++) {
              try {
                let obj = JSON.parse(data[i].value);
                if (obj && Object.keys(obj).length > 0) {
                  items.push({
                    id: data[i].id,
                    type:
                      obj.type && obj.type === "shapefile"
                        ? "JSON_FILE"
                        : "TILE_LAYER",
                    name: data[i].name,
                    title: obj.name ? obj.name : obj.NAME,
                    value: data[i].value,
                    description: data[i].description,
                    id:
                      new Date().getTime() -
                      Math.floor(Math.random() * 99999) +
                      Math.floor(Math.random() * 10000),
                    show:
                      (obj.is_show && obj.is_show + "" === "1") ||
                      (obj.IS_SHOW && obj.IS_SHOW + "" === "1")
                        ? true
                        : false,
                    opacity: 100,
                    basemap: true,
                    source: {
                      type: "TILE_IMAGE",
                      url: obj.path ? obj.path : obj.PATH,
                    },
                  });
                }
              } catch (error) {}
            }
            if (items && items.length > 0) {
              if (!this.basemap || Object.keys(this.basemap).length === 0) {
                let arrayBasemaps = items.filter(
                  (x) => x.show === true && (!x.type || x.type === "TILE_LAYER")
                );
                if (arrayBasemaps && arrayBasemaps.length > 0)
                  this.$store.dispatch(
                    "setBasemapDefault",
                    arrayBasemaps[arrayBasemaps.length - 1]
                  );
              }
            }
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllBasemaps);
        }
      }
    },
    /*---------------- End metakey ------------------*/
    async getAllCustomer() {
      try {
        let response = await userFunc.search(
          {
            page: 0,
            size: 2147483647,
            order: "full_name",
            sort: "asc",
          },
          {
            is_active: null,
            role_id: "ROLE_CUSTOMER",
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.user.items = response.data.content_page;
        }
      } catch (error) {
        if (error.response && error.response.status === 401)
          await this.refreshToken(this.getAllCustomer);
      }
    },
    async getAllOrder(loading) {
      if (this.order.is_bottom) return;
      if (loading) {
        // this.$store.dispatch("setSpinnerApp", {
        //   show: true,
        // });
        this.order.loading = true;
      }
      this.buttons.filter.disabled = true;
      this.buttons.filter.processing = true;
      try {
        let response = await orderFunc.getAllPage(
          {
            create_from: this.order.search.create_from
              ? new Date(this.order.search.create_from)
              : null,
            create_to: this.order.search.create_to
              ? new Date(this.order.search.create_to)
              : null,
            page: this.order.search.page,
            size: this.order.search.size,
            status_id: this.order.search.status_id
              ? this.order.search.status_id
              : null,
            user_id: this.order.search.user_id
              ? this.order.search.user_id
              : null,
            is_order_request: false,
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.order.items = [
            ...this.order.items,
            ...response.data.content_page,
          ];
          this.order.page_number = response.data.page_number;
          this.order.page_size = response.data.page_size;
          this.order.total_page = response.data.total_page;
          this.order.total_records = response.data.total_records;
          if (
            (this.order.items.length === this.order.total_records &&
              this.order.total_page > 1) ||
            (response.data.total_page === 1 &&
              this.order.items.length <= response.data.page_size)
          )
            this.order.is_bottom = true;
        }
        this.buttons.filter.disabled = false;
        this.buttons.filter.processing = false;
        // this.$store.dispatch("setSpinnerApp", {
        //   show: false,
        // });
        this.order.loading = false;
      } catch (error) {
        // this.$store.dispatch("setSpinnerApp", {
        //   show: false,
        // });
        this.order.loading = false;
        this.buttons.filter.disabled = false;
        this.buttons.filter.processing = false;
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.getAllOrder);
        }
      }
    },
    async getAllOrderPage(status_id) {
      let obj = this.orderStatus.items.find(
        (x) => x.id === (status_id ? status_id : this.tabSelected)
      );
      let params = {
        create_from: this.order.search.create_from
          ? new Date(this.order.search.create_from)
          : null,
        create_to: this.order.search.create_to
          ? new Date(this.order.search.create_to)
          : null,
        size: this.order.search.size,
        status_id: status_id ? status_id : this.tabSelected,
        user_id: this.order.search.user_id,
        is_order_request: false,
      };
      if (obj && Object.keys(obj).length > 0) {
        obj.label = obj.value ? obj.value : null;
        switch (obj.name) {
          case "ORDER_CANCELED":
            if (this.order.canceled.is_bottom) return;
            this.order.canceled.loadding = true;
            this.order.canceled.loaded = true;
            params.page = this.order.canceled.page;
            break;
          case "ORDER_CREATED":
            if (this.order.created.is_bottom) return;
            this.order.created.loadding = true;
            this.order.created.loaded = true;
            params.page = this.order.created.page;
            break;
          case "ORDER_PROCESSING":
            if (this.order.processing.is_bottom) return;
            this.order.processing.loadding = true;
            this.order.processing.loaded = true;
            params.page = this.order.processing.page;
            break;
          case "ORDER_REJECTED":
            if (this.order.rejected.is_bottom) return;
            this.order.rejected.loadding = true;
            this.order.rejected.loaded = true;
            params.page = this.order.rejected.page;
            break;
          case "ORDER_COMPLETED":
            if (this.order.completed.is_bottom) return;
            this.order.completed.loadding = true;
            this.order.completed.loaded = true;
            params.page = this.order.completed.page;
            break;
          default:
            break;
        }
      }
      try {
        this.buttons.filter.processing = true;
        this.buttons.filter.disabled = true;
        this.buttons.reload.disabled = true;
        this.buttons.reload.processing = true;
        let response = await orderFunc.getAllPage(
          params,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          let indexStatus = -1;
          switch (obj.name) {
            case "ORDER_CANCELED":
              this.order.canceled.items = [
                ...this.order.canceled.items,
                ...response.data.content_page,
              ];
              this.order.canceled.page_number = response.data.page_number;
              this.order.canceled.page_size = response.data.page_size;
              this.order.canceled.total_page = response.data.total_page;
              this.order.canceled.total_records = response.data.total_records;
              if (
                this.order.canceled.items.length ===
                  this.order.canceled.total_records &&
                this.order.canceled.items.length > 0 &&
                this.order.canceled.total_page > 1
              )
                this.order.canceled.is_bottom = true;

              indexStatus = this.orderStatus.items.findIndex(
                (x) => x.id === (status_id ? status_id : this.tabSelected)
              );
              if (indexStatus >= 0 && this.order.canceled.total_records > 0) {
                this.orderStatus.items[indexStatus].label =
                  this.orderStatus.items[indexStatus].value +
                  " (" +
                  this.order.canceled.total_records +
                  ")";
              } else {
                this.orderStatus.items[indexStatus].label =
                  this.orderStatus.items[indexStatus].value;
              }
              break;
            case "ORDER_CREATED":
              this.order.created.items = [
                ...this.order.created.items,
                ...response.data.content_page,
              ];
              this.order.created.page_number = response.data.page_number;
              this.order.created.page_size = response.data.page_size;
              this.order.created.total_page = response.data.total_page;
              this.order.created.total_records = response.data.total_records;
              if (
                this.order.created.items.length ===
                  this.order.created.total_records &&
                this.order.created.items.length > 0 &&
                this.order.created.total_page > 1
              )
                this.order.created.is_bottom = true;
              indexStatus = this.orderStatus.items.findIndex(
                (x) => x.id === (status_id ? status_id : this.tabSelected)
              );
              if (indexStatus >= 0 && this.order.created.total_records > 0) {
                this.orderStatus.items[indexStatus].label =
                  this.orderStatus.items[indexStatus].value +
                  " (" +
                  this.order.created.total_records +
                  ")";
              } else {
                this.orderStatus.items[indexStatus].label =
                  this.orderStatus.items[indexStatus].value;
              }
              break;
            case "ORDER_PROCESSING":
              this.order.processing.items = [
                ...this.order.processing.items,
                ...response.data.content_page,
              ];
              this.order.processing.page_number = response.data.page_number;
              this.order.processing.page_size = response.data.page_size;
              this.order.processing.total_page = response.data.total_page;
              this.order.processing.total_records = response.data.total_records;
              if (
                this.order.processing.items.length ===
                  this.order.processing.total_records &&
                this.order.processing.items.length > 0 &&
                this.order.processing.total_page > 1
              )
                this.order.processing.is_bottom = true;

              indexStatus = this.orderStatus.items.findIndex(
                (x) => x.id === (status_id ? status_id : this.tabSelected)
              );
              if (indexStatus >= 0 && this.order.processing.total_records > 0) {
                this.orderStatus.items[indexStatus].label =
                  this.orderStatus.items[indexStatus].value +
                  " (" +
                  this.order.processing.total_records +
                  ")";
              } else {
                this.orderStatus.items[indexStatus].label =
                  this.orderStatus.items[indexStatus].value;
              }
              break;
            case "ORDER_REJECTED":
              this.order.rejected.items = [
                ...this.order.rejected.items,
                ...response.data.content_page,
              ];
              this.order.rejected.page_number = response.data.page_number;
              this.order.rejected.page_size = response.data.page_size;
              this.order.rejected.total_page = response.data.total_page;
              this.order.rejected.total_records = response.data.total_records;
              if (
                this.order.rejected.items.length ===
                  this.order.rejected.total_records &&
                this.order.rejected.items.length > 0 &&
                this.order.rejected.total_page > 1
              )
                this.order.rejected.is_bottom = true;

              indexStatus = this.orderStatus.items.findIndex(
                (x) => x.id === (status_id ? status_id : this.tabSelected)
              );
              if (indexStatus >= 0 && this.order.rejected.total_records > 0) {
                this.orderStatus.items[indexStatus].label =
                  this.orderStatus.items[indexStatus].value +
                  " (" +
                  this.order.rejected.total_records +
                  ")";
              } else {
                this.orderStatus.items[indexStatus].label =
                  this.orderStatus.items[indexStatus].value;
              }
              break;
            case "ORDER_COMPLETED":
              this.order.completed.items = [
                ...this.order.completed.items,
                ...response.data.content_page,
              ];
              this.order.completed.page_number = response.data.page_number;
              this.order.completed.page_size = response.data.page_size;
              this.order.completed.total_page = response.data.total_page;
              this.order.completed.total_records = response.data.total_records;
              if (
                this.order.completed.items.length ===
                  this.order.completed.total_records &&
                this.order.completed.items.length > 0 &&
                this.order.completed.total_page > 1
              )
                this.order.completed.is_bottom = true;

              indexStatus = this.orderStatus.items.findIndex(
                (x) => x.id === (status_id ? status_id : this.tabSelected)
              );
              if (indexStatus >= 0 && this.order.completed.total_records > 0) {
                this.orderStatus.items[indexStatus].label =
                  this.orderStatus.items[indexStatus].value +
                  " (" +
                  this.order.completed.total_records +
                  ")";
              } else {
                this.orderStatus.items[indexStatus].label =
                  this.orderStatus.items[indexStatus].value;
              }
              break;
            default:
              break;
          }
        }
        this.buttons.filter.processing = false;
        this.buttons.filter.disabled = false;
        this.buttons.reload.disabled = false;
        this.buttons.reload.processing = false;
      } catch (error) {
        this.order.completed.loadding = false;
        this.order.canceled.loadding = false;
        this.order.created.loadding = false;
        this.order.processing.loadding = false;
        this.order.rejected.loadding = false;

        this.buttons.filter.processing = false;
        this.buttons.filter.disabled = false;
        this.buttons.reload.disabled = false;
        this.buttons.reload.processing = false;
        if (error.response && error.response.status === 401)
          await this.refreshToken(this.getAllOrderPage);
      }
      this.order.completed.loadding = false;
      this.order.canceled.loadding = false;
      this.order.created.loadding = false;
      this.order.processing.loadding = false;
      this.order.rejected.loadding = false;
    },

    async getAllOrderStatus() {
      try {
        let response = await metaKeyFunc.getByKey(
          "ORDER_STATUS",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.orderStatus.data =
            response.data && response.data.data ? response.data.data : [];
          this.getOrderStatusByLang();
          // if (this.orderStatus.items.length > 0) {
          //   for (let i = 0; i < this.orderStatus.items.length; i++) {
          //     this.orderStatus.items[i].label = this.orderStatus.items[i].value;
          //   }
          //   let index = this.orderStatus.items.findIndex(
          //     (x) => x.name === "ORDER_CREATED"
          //   );
          //   if (index >= 0) {
          //     this.order.search.status_id = this.orderStatus.items[index].id;
          //     this.tabSelected = this.order.search.status_id;
          //     this.tabIndex = index;
          //   }
          //   for (let i = 0; i < this.orderStatus.items.length; i++) {
          //     await this.getAllOrderPage(this.orderStatus.items[i].id);
          //   }
          // }
        }
      } catch (error) {
        if (error.response && error.response.status === 401)
          this.refreshToken(this.getAllOrderStatus);
      }
    },
    async getAllPriorityLevel() {
      try {
        let response = await metaKeyFunc.getByKey(
          "PRIORITY_LEVEL",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.priorityLevel.items =
            response.data && response.data.data ? response.data.data : [];
        }
      } catch (error) {
        if (error.response && error.response.status === 401)
          this.refreshToken(this.getAllPriorityLevel);
      }
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          this.$router.push({
            name: "Login",
            query: {
              next: btoa(this.$route.path),
            },
          });
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
};
</script>

<style lang="scss" scoped>
.order-content {
  padding: 0.5rem;
  .order__search {
    padding: 0;
  }
  .order__datatable {
    padding: 0;
    padding-top: 0.5rem;
  }
}
</style>


<style lang="scss" scoped>
.order-content {
  .order__search {
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .order__datatable {
    .accordion {
      border-radius: 0.5rem;
    }
  }
}
</style>
