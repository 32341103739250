<template>
  <main-layout
    :isToolbar="true"
    :isBreadcrumb="true"
    :breadcrumbitems="[
      { label: $t('menu_category') },
      { label: $t('menu_organisation') },
    ]"
    :propButtonsState="{
      delete: buttons.delete,
      save: buttons.save,
      add: buttons.add,
      edit: buttons.edit,
      reload: buttons.reload,
      print: {
        visible: false,
      },
    }"
    :propTitle="$t('menu_organisation')"
    @emitFunction="menuBarFunction($event)"
  >
    <div class="organisation-content">
      <div class="organisation__dialog">
        <DialogConfirmation
          v-if="confirmDelete.show"
          :prop-show="confirmDelete.show"
          :prop-title="$t('title_message_confirm')"
          :prop-icon="confirmDelete.icon"
          :prop-message="confirmDelete.message"
          :textNo="$t('button_text_close')"
          :textYes="$t('button_text_delete')"
          @cancel="onCancelConfirmDelete"
          @confirmed="onConfirmedDelete"
        />

        <DialogAddMembers
          v-if="dialog.addMembers.show"
          :prop-show="dialog.addMembers.show"
          :prop-items="
            member.items.filter(
              (x) =>
                x.id != userMeId &&
                !organisation.entity.member
                  .filter((y) => !y.is_delete)
                  .map((z) => z.user.id)
                  .includes(x.id)
            )
          "
          :prop-roles="roleGroup.items"
          @getAllUser="getAllUser"
          @getAllRoleGroup="getAllRoleGroup"
          @close="
            dialog.addMembers.show = false;
            dialog.addMembers.value = null;
          "
          @selected="selectedMembers"
          @addMember="onAddMember"
          @message="message = $event"
        />
        <DialogMessage
          v-if="message.length > 0"
          :propTitle="$t('title_message_info')"
          :prop-show="message.length > 0"
          :prop-message="message"
          @close="message = []"
        />
      </div>
      <div class="organisation__search">
        <OrgSearch
          :prp-search="organisation.search"
          @onSearch="onSearch"
        ></OrgSearch>
      </div>
      <div class="row no-gutters">
        <div class="col-12 col-md-8">
          <div class="organisation__datatable">
            <DataTable
              ref="organisationDatatable"
              :propItems="organisation.items"
              :prop-table="organisation.table"
              :propFirst="organisation.first"
              :propLoading="organisation.loading"
              @rowClick="rowClick"
            ></DataTable>
          </div>
          <div class="organisation__structure">
            <ValidationObserver ref="organisationObserver">
              <structure
                ref="organisationStructure"
                :prop-entity="organisation.entity"
                :propOrgStatus="orgStatus.items"
                :propTreeOrganisation="organisation.structure.tree"
                :prop-status="{
                  isAdd: organisation.isAdd,
                  isEdit: organisation.isEdit,
                }"
                @showDialogAddMembers="showDialogAddMembers"
                @removeMember="removeMember"
              >
              </structure>
            </ValidationObserver>
            <div class="actions">
              <Button
                :icon="
                  buttons.prev.processing
                    ? 'fa fa-spinner fa-spin'
                    : 'pi pi-chevron-left'
                "
                class="p-button-rounded p-button-secondary"
                :disabled="buttons.prev.disabled"
                @click="prevItem"
              />
              <Button
                :icon="
                  buttons.next.processing
                    ? 'fa fa-spinner fa-spin'
                    : 'pi pi-chevron-right'
                "
                class="p-button-rounded p-button-secondary"
                :disabled="buttons.next.disabled"
                @click="nextItem"
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="organisation__tree">
            <Tree
              :prop-data="organisation.items"
              :propLoading="organisation.loading"
              :propSelected="organisation.table.selected"
              @onNodeSelect="onNodeSelect"
              @onNodeUnselect="onNodeUnselect"
            />
          </div>
        </div>
      </div>
    </div>
  </main-layout>
</template>
<script>
import MainLayout from "@/components/pages/layouts/admin/Main";
import DataTable from "./DataTable";
import Tree from "./Tree";
import Structure from "./Structure";
import OrgSearch from "./Search";
import DialogConfirmation from "@/components/commons/dialog/Confirmation";
import DialogMessage from "@/components/commons/dialog/MessageBox";
import DialogAddMembers from "./structure/AddMembers2.vue";
import Button from "@/components/commons/button/Button";
import organisationFunc from "@/utils/functions/organisation";
import metaKeyFunc from "@/utils/functions/metakey";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import userFunc from "@/utils/functions/user";
import roleFunc from "@/utils/functions/role";
export default {
  components: {
    DataTable,
    Tree,
    Structure,
    MainLayout,
    Button,
    DialogConfirmation,
    DialogMessage,
    DialogAddMembers,
    OrgSearch,
  },
  data() {
    return {
      message: [],
      member: {
        items: [],
      },
      roleGroup: {
        items: [],
      },
      confirmDelete: {
        message: null,
        show: false,
        title: null,
        icon: "fas fa-question",
        value: false,
      },
      organisation: {
        items: [],
        tree: {
          items: [],
          selected: null,
        },
        first: 0,
        loading: false,
        search: {
          page: 0,
          size: 0,
          create_time_from: null,
          create_time_to: null,
          org_name: null,
        },
        entity: {
          description: null,
          email: null,
          fax_number: null,
          id: null,
          name: null,
          phone_number: null,
          status: null,
          parent_id: null,
          member: [],
        },
        table: {
          selected: null,
          page: 0,
          size: 10,
        },
        structure: {
          tree: [],
        },
        isAdd: false,
        isEdit: false,
      },
      orgStatus: {
        items: [],
        data: [],
      },
      buttons: {
        prev: {
          disabled: true,
          processing: false,
          visible: true,
        },
        next: {
          disabled: true,
          processing: false,
          visible: true,
        },
        delete: {
          disabled: true,
          processing: false,
          visible: true,
        },
        save: {
          disabled: true,
          processing: false,
          visible: true,
        },
        edit: {
          disabled: true,
          processing: false,
          visible: true,
        },
        add: {
          disabled: false,
          processing: false,
          visible: true,
        },
        filter: {
          disabled: false,
          processing: false,
          visible: true,
        },
        reload: {
          disabled: false,
          processing: false,
          visible: true,
        },
      },
      dialog: {
        addMembers: {
          show: false,
          title: "Chọn thành viên",
          value: null,
        },
      },
    };
  },
  created() {
    this.organisation.entity = this.generateEntity();
    this.getAllOrgStatus();
    this.getAllOrganisation();
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    accessToken() {
      return this.$store.getters.getAccessToken;
    },
    userMeId() {
      return this.$store.getters.getUserId;
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
  watch: {
    lang(val) {
      this.getOrgStatusByLang();
    },
  },
  methods: {
    generateEntity() {
      return {
        description: null,
        email: null,
        fax_number: null,
        id: null,
        name: null,
        phone_number: null,
        status: null,
        parent_id: null,
        member: [],
      };
    },
    entityFromSelected(data) {
      if (data && Object.keys(data).length > 0) {
        this.organisation.entity.id = data.id ? data.id : null;
        this.organisation.entity.email = data.email ? data.email : null;
        this.organisation.entity.fax_number = data.fax_number
          ? data.fax_number
          : null;
        this.organisation.entity.description = data.description
          ? data.description
          : null;
        this.organisation.entity.name = data.name ? data.name : null;
        this.organisation.entity.phone_number = data.phone_number
          ? data.phone_number
          : null;
        this.organisation.entity.status = data.status ? data.status.id : null;
        this.organisation.entity.parent_id = data.parent_id
          ? data.parent_id
          : null;
        this.organisation.entity.member = data.member ? data.member : [];
      }
    },

    onNodeSelect(val) {
      if (val && Object.keys(val).length) {
        this.organisation.tree.selected = val;
        let index = this.organisation.items.findIndex((x) => x.id === val.id);
        if (index >= 0) {
          this.organisation.table.selected = this.organisation.items[index];
          this.rowClick({
            data: this.organisation.table.selected,
            index: index,
          });
        }
      }
    },
    onNodeUnselect() {
      this.organisation.tree.selected = null;
      this.organisation.table.selected = null;
    },
    async prevItem() {
      if (this == undefined) return;
      if (this.$refs.organisationDatatable) {
        let data = this.$refs.organisationDatatable.$refs.dt.processedData;
        let selection = this.$refs.organisationDatatable.$refs.dt.selection;
        if (!selection) return;
        let index = data.findIndex((x) => x.id === selection.id);
        this.buttons.prev.processing = true;
        this.buttons.prev.disabled = true;
        this.$store.dispatch("setSpinnerApp", {
          show: true,
        });
        await this.entityFromSelected(data[index - 1]);
        this.organisation.table.selected = data[index - 1];
        this.buttons.prev.processing = false;
        this.buttons.prev.disabled = false;

        if (index === 1) {
          this.buttons.prev.disabled = true;
          this.buttons.next.disabled = false;
        } else {
          this.buttons.next.disabled = false;
          this.buttons.prev.disabled = false;
        }

        this.organisation.table.page = Math.floor((index - 1) / 10);
        this.organisation.first = Math.floor((index - 1) / 10) * 10;
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
      }
    },
    async nextItem() {
      if (this == undefined) return;
      if (this.$refs.organisationDatatable) {
        let data = this.$refs.organisationDatatable.$refs.dt.processedData;
        let selection = this.$refs.organisationDatatable.$refs.dt.selection;
        if (!selection) return;
        let index = data.findIndex((x) => x.id === selection.id);
        this.buttons.next.processing = true;
        this.buttons.next.disabled = true;
        this.$store.dispatch("setSpinnerApp", {
          show: true,
        });
        await this.entityFromSelected(data[index + 1]);
        this.organisation.table.selected = data[index + 1];
        this.buttons.next.processing = false;
        this.buttons.next.disabled = false;

        if (index === data.length - 2) {
          this.buttons.prev.disabled = false;
          this.buttons.next.disabled = true;
        } else {
          this.buttons.prev.disabled = false;
          this.buttons.next.disabled = false;
        }
        this.organisation.table.page = Math.floor((index + 1) / 10);
        this.organisation.first = Math.floor((index + 1) / 10) * 10;
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
      }
    },
    onSearch(data) {
      this.organisation.search.org_name = data.org_name ? data.org_name : null;
      this.getAllOrganisation();
    },
    /* ---------- Emit ----------*/

    onAddMember(data) {
      let role = this.roleGroup.items.find((x) => x.id === data.role_id);
      let user = this.member.items.find((x) => x.id === data.user_id);
      this.organisation.entity.member.push({
        role: role,
        user: user,
        is_add: true,
        is_delete: false,
      });
      this.dialog.addMembers.show = false;
    },
    selectedMembers(val) {
      if (val && val.length > 0) {
        for (let i = 0; i < val.length; i++) {
          let index = this.organisation.entity.member.findIndex(
            (x) => x.user.id === val[i].id
          );
          if (index < 0) {
            this.organisation.entity.member.push({
              role:
                val[i].roles && val[i].roles.length > 0 ? val[i].roles[0] : {},
              role_id:
                val[i].roles && val[i].roles.length > 0
                  ? val[i].roles[0].id
                  : null,
              user: val[i],
              user_id: val[i].id,
              is_delete: false,
              is_add: true,
              selected: true,
            });
          } else {
            if ((this.organisation.entity.member[index].is_delete = true)) {
              this.organisation.entity.member[index].is_delete = false;
              this.organisation.entity.member[index].is_add = true;
              this.organisation.entity.member[index].selected = true;
            }
          }
        }
      }
      var difference = this.organisation.entity.member.filter(
        (x) => !val.map((y) => y.id).includes(x.user.id)
      );
      if (difference && difference.length > 0) {
        for (let i = 0; i < difference.length; i++) {
          let index = this.organisation.entity.member.findIndex(
            (x) => x.user.id === difference[i].id
          );
          if (index >= 0) {
            this.organisation.entity.member[index].is_delete = true;
            this.organisation.entity.member[index].is_add = false;
            this.organisation.entity.member[index].selected = false;
          }
        }
      }
      if (this.$refs.organisationStructure) {
        if (this.$refs.organisationStructure.$refs.membersDatatable)
          this.$refs.organisationStructure.$refs.membersDatatable.initData();
      }
      this.dialog.addMembers.show = false;
    },
    showDialogAddMembers() {
      this.dialog.addMembers.show = true;
    },
    removeMember(item) {
      let index = this.organisation.entity.member.findIndex(
        (x) => x.user.id === item.user.id
      );
      if (index >= 0) {
        this.organisation.entity.member[index].is_delete = true;
        this.organisation.entity.member[index].is_add = false;
        this.organisation.entity.member[index].selected = false;
      }
    },
    async rowClick(val) {
      this.organisation.isAdd = false;
      this.organisation.isEdit = false;
      this.buttons.add.disabled = false;
      this.buttons.add.processing = false;
      this.buttons.edit.disabled = false;
      this.buttons.edit.processing = false;
      this.buttons.delete.disabled = false;
      this.buttons.delete.processing = false;
      this.buttons.save.disabled = true;
      this.buttons.save.processing = false;
      this.buttons.prev.disabled = true;
      this.buttons.next.disabled = true;
      if (val.index === 0) {
        this.buttons.prev.disabled = true;
        if (this.organisation.items.length > 1)
          this.buttons.next.disabled = false;
        else this.buttons.next.disabled = true;
      } else if (val.index === this.organisation.items.length - 1) {
        if (this.organisation.items.length > 1)
          this.buttons.prev.disabled = false;
        else this.buttons.prev.disabled = true;
        this.buttons.next.disabled = true;
      } else {
        this.buttons.prev.disabled = false;
        this.buttons.next.disabled = false;
      }
      if (val.data) {
        this.$store.dispatch("setSpinnerApp", {
          show: true,
        });
        this.organisation.table.selected = val.data;
        this.organisation.entity = this.generateEntity();
        await this.entityFromSelected(val.data);
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
      }
    },

    async menuBarFunction(val) {
      switch (val.code) {
        case "SAVE":
          this.onSave();
          break;
        case "ADD":
          this.organisation.isEdit = false;
          this.organisation.isAdd = true;
          this.buttons.edit.disabled = true;
          this.buttons.edit.processing = false;
          this.buttons.save.disabled = false;
          this.buttons.save.processing = false;
          this.organisation.table.selected = null;
          this.buttons.delete.disabled = true;
          this.buttons.delete.processing = false;
          this.organisation.structure.tree = this.listToTree(
            this.organisation.items
          );
          this.organisation.entity = this.generateEntity();
          break;
        case "EDIT":
          this.organisation.structure.tree = this.listToTree(
            this.organisation.items.filter(
              (x) => x.id != this.organisation.entity.id
            )
          );
          this.organisation.isEdit = true;
          this.organisation.isAdd = false;
          this.buttons.add.disabled = false;
          this.buttons.add.processing = false;
          this.buttons.save.disabled = false;
          this.buttons.save.processing = false;
          break;
        case "FILTER":
          break;
        case "RELOAD":
          this.buttons.reload.processing = true;
          if (!this.cancel) await this.onReload();
          this.buttons.reload.processing = false;
          break;
        case "DELETE":
          this.confirmDelete.value = this.organisation.entity.id;
          this.confirmDelete.message = this.$t(
            "message_confirm_delete_organisation",
            null,
            {
              name: this.organisation.entity.name
                ? this.organisation.entity.name.trim()
                : "",
            }
          );
          this.confirmDelete.show = true;
          break;
        default:
          break;
      }
    },

    onCancelConfirmDelete() {
      this.confirmDelete.show = false;
      this.confirmDelete.message = null;
      this.confirmDelete.value = null;
    },
    async onConfirmedDelete() {
      this.buttons.delete.disabled = true;
      this.buttons.delete.processing = true;
      this.$store.dispatch("setSpinnerApp", {
        show: true,
      });
      try {
        let response = await organisationFunc.delete(
          this.confirmDelete.value,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.$toast.success({
            title: this.$t("title_message_info"),
            message: this.$t("message_delete_organisation_success", null, {
              name: this.organisation.entity.name
                ? this.organisation.entity.name.trim()
                : "",
            }),
            position: "top right",
          });
          this.onCancelConfirmDelete();
          this.getAllOrganisation();
        } else {
          this.message.push([
            this.$t("message_delete_organisation_error", null, {
              name: this.organisation.entity.name
                ? this.organisation.entity.name.trim()
                : "",
            }),
          ]);
        }
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
      } catch (error) {
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.onConfirmedDelete);
        } else {
          let msg =
            error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push([
            msg
              ? msg
              : this.$t("message_delete_organisation_error", null, {
                  name: this.organisation.entity.name
                    ? this.organisation.entity.name.trim()
                    : "",
                }),
          ]);
        }
      }
      this.$store.dispatch("setSpinnerApp", {
        show: false,
      });
      this.buttons.delete.processing = false;
    },

    async onReload() {
      await this.getAllOrganisation();
    },

    listToTree(list) {
      var map = {},
        node,
        roots = [],
        i;
      let items = [];
      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
        let obj = {
          parent_id:
            list[i].parent_id && list[i].parent_id != list[i].id
              ? list[i].parent_id
              : -1,
          label: list[i].name,
          text: list[i].name,
          name: list[i].name,
          id: list[i].id,
          key: list[i].id,
          children: [],
        };
        items.push(obj);
      }
      for (i = 0; i < items.length; i += 1) {
        try {
          node = items[i];
          if (node.parent_id != null && node.parent_id + "" !== "-1") {
            if (items[map[node.parent_id]]) {
              if (!items[map[node.parent_id]].children) {
                items[map[node.parent_id]].children = [];
              }
              items[map[node.parent_id]].children.push(node);
            } else roots.push(node);
          } else {
            roots.push(node);
          }
        } catch (error) {}
      }
      return roots;
    },
    /* ---------- Put, Post -----------*/
    async onSave() {
      this.message = [];
      var vm = this;
      this.$refs.organisationObserver.validate().then(async (success) => {
        if (!success) {
          setTimeout(() => {
            Object.keys(vm.$refs.organisationObserver.errors).forEach(function (
              key
            ) {
              if (
                vm.$refs.organisationObserver.errors[key] &&
                vm.$refs.organisationObserver.errors[key].length
              )
                vm.message.push(
                  vm.$refs.organisationObserver.errors[key].join(",\n")
                );
            });
          }, 100);
          return;
        } else {
          this.buttons.save.processing = true;
          this.buttons.save.disabled = true;
          this.buttons.add.disabled = true;
          this.buttons.edit.disabled = true;
          this.buttons.reload.disabled = true;
          //this.buttons.delete.disabled = true;
          this.$store.dispatch("setSpinnerApp", {
            show: true,
          });
          let dto = Object.assign(
            {},
            JSON.parse(JSON.stringify(this.organisation.entity))
          );
          dto.name = dto.name ? dto.name.trim().replace(/\s\s+/g, " ") : null;
          dto.description = dto.description
            ? dto.description.trim().replace(/\s\s+/g, " ")
            : null;
          dto.email = dto.email
            ? dto.email.trim().replace(/\s\s+/g, " ")
            : null;
          dto.fax_number = dto.fax_number
            ? dto.fax_number.trim().replace(/\s\s+/g, " ")
            : null;
          dto.phone_number = dto.phone_number
            ? dto.phone_number.trim().replace(/\s\s+/g, " ")
            : null;
          dto.member = [];
          if (
            this.organisation.entity.member &&
            this.organisation.entity.member.length > 0
          ) {
            for (let i = 0; i < this.organisation.entity.member.length; i++) {
              if (
                this.organisation.entity.member[i].user &&
                this.organisation.entity.member[i].role
              ) {
                dto.member.push({
                  member_id: this.organisation.entity.member[i].user
                    ? this.organisation.entity.member[i].user.id
                    : null,
                  role_id: this.organisation.entity.member[i].role
                    ? this.organisation.entity.member[i].role.id
                    : null,
                });
              }
            }
          }
          let response;
          try {
            if (!dto.id) {
              // Add new
              response = await organisationFunc.create(
                dto,
                this.$store.getters.getAccessToken
              );
            } else {
              // Edit
              response = await organisationFunc.update(
                dto,
                this.$store.getters.getAccessToken
              );
            }
            if (
              response &&
              (response.status === 201 ||
                response.status === 200 ||
                response.status === 204)
            ) {
              if (response.data.success) {
                this.$toast.success({
                  title: this.$t("title_message_info"),
                  message: this.$t("message_save_organisation_success", null, {
                    txt:
                      this.organisation.isEdit && this.organisation.entity.id
                        ? this.$t("text_save")
                        : this.$t("text_add_new"),
                    name: dto.name,
                  }),
                  position: "top right",
                });

                this.getAllOrganisation();
                this.buttons.save.disabled = true;
                this.buttons.save.processing = false;
                this.buttons.add.disabled = false;
                this.buttons.add.processing = false;
                this.buttons.edit.disabled = true;
                this.buttons.edit.processing = false;
                this.organisation.isAdd = false;
                this.organisation.isEdit = false;
                this.organisation.table.selected = null;
                this.organisation.entity = this.generateEntity();
              } else {
                this.message.push(
                  this.$t("message_save_organisation_error", null, {
                    txt:
                      this.organisation.isEdit && this.organisation.entity.id
                        ? this.$t("text_save")
                        : this.$t("text_add_new"),
                    name: dto.name,
                  })
                );
              }
            } else {
              this.message.push(
                this.$t("message_save_organisation_error", null, {
                  txt:
                    this.organisation.isEdit && this.organisation.entity.id
                      ? this.$t("text_save")
                      : this.$t("text_add_new"),
                  name: dto.name,
                })
              );
            }
          } catch (error) {
            if (error.response && error.response.status === 401) {
              this.$store.dispatch("setSpinnerApp", {
                show: false,
              });
              await this.refreshToken(this.onSave);
            } else {
              this.buttons.save.processing = false;
              this.buttons.save.disabled = false;
              this.buttons.edit.disabled = !this.organisation.isEdit;
              this.buttons.add.disabled = false;
              this.buttons.reload.disabled = false;
              let msg =
                error.response.data && error.response.data.message
                  ? error.response.data.message
                  : null;
              this.message.push(
                msg
                  ? msg
                  : this.$t("message_save_organisation_error", null, {
                      txt:
                        this.organisation.isEdit && this.organisation.entity.id
                          ? this.$t("text_save")
                          : this.$t("text_add_new"),
                      name: dto.name,
                    })
              );
            }
          }
          this.$store.dispatch("setSpinnerApp", {
            show: false,
          });
        }
      });
    },
    /*------------------ Meta key -------------------*/
    getOrgStatusByLang() {
      this.orgStatus.items = [];
      for (let i = 0; i < this.orgStatus.data.length; i++) {
        if (
          this.orgStatus.data[i].translation &&
          this.orgStatus.data[i].translation[this.lang] &&
          Object.keys(this.orgStatus.data[i].translation[this.lang]).length > 0
        ) {
          this.orgStatus.items.push({
            id: this.orgStatus.data[i].id,
            key: this.orgStatus.data[i].key,
            name: this.orgStatus.data[i].name,
            value: this.orgStatus.data[i].translation[this.lang].value
              ? this.orgStatus.data[i].translation[this.lang].value
              : this.orgStatus.data[i].value,
            description: this.orgStatus.data[i].translation[this.lang]
              .description
              ? this.orgStatus.data[i].translation[this.lang].description
              : this.orgStatus.data[i].description,
            sub_meta_key: this.orgStatus.data[i].sub_meta_key,
          });
        } else {
          this.orgStatus.items.push({
            id: this.orgStatus.data[i].id,
            key: this.orgStatus.data[i].key,
            name: this.orgStatus.data[i].name,
            value: this.orgStatus.data[i].value,
            description: this.orgStatus.data[i].description,
            sub_meta_key: this.orgStatus.data[i].sub_meta_key,
          });
        }
      }
    },
    /*------------------ End meta key ------------------*/
    /*------------------ Get data -------------------*/
    async getAllRoleGroup() {
      try {
        let response = await roleFunc.getByGroup(
          "organization",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success)
            this.roleGroup.items =
              response.data && response.data.data ? response.data.data : [];
        }
      } catch (error) {
        if (error.response && error.response.status === 401)
          this.refreshToken(this.getAllRoleGroup);
      }
    },
    async getAllUser() {
      try {
        let response = await userFunc.search(
          {
            page: 0,
            size: 2147483647,
            order: "full_name",
            sort: "asc",
          },
          {
            is_active: null,
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.member.items =
            response.data && response.data.content_page
              ? response.data.content_page.filter((x) => x.is_active)
              : [];
          this.member.items.sort(function (a, b) {
            if (a.full_name < b.full_name) return -1;
            else if (a.full_name > b.full_name) return 1;
            return 0;
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 401)
          this.refreshToken(this.getAllUser);
      }
    },
    async getAllOrgStatus() {
      try {
        let response = await metaKeyFunc.getByKey(
          "ORG_STATUS",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.orgStatus.data =
            response.data && response.data.data ? response.data.data : [];
          this.orgStatus.data.sort(function (a, b) {
            if (a.value < b.value) return -1;
            else if (a.value > b.value) return 1;
            return 0;
          });
          this.getOrgStatusByLang();
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.getAllOrgStatus);
        }
      }
    },
    async getAllOrganisation() {
      this.organisation.items = [];
      this.organisation.entity = this.generateEntity();
      this.organisation.table.selected = null;
      this.organisation.isAdd = false;
      this.organisation.isEdit = false;
      this.organisation.loading = true;
      this.buttons.filter.processing = true;
      this.buttons.filter.disabled = true;
      this.buttons.reload.disabled = true;
      this.buttons.reload.processing = true;
      this.buttons.delete.disabled = true;
      this.buttons.delete.processing = false;
      this.buttons.save.processing = false;
      this.buttons.save.disabled = true;
      this.buttons.add.disabled = false;
      this.buttons.add.processing = false;
      this.buttons.edit.disabled = true;
      this.buttons.edit.processing = false;
      try {
        let response = await organisationFunc.getAll(
          {
            create_time_from: this.organisation.search.create_time_from
              ? this.organisation.search.create_time_from
              : null,
            create_time_to: this.organisation.search.create_time_from
              ? this.organisation.search.create_time_from
              : null,
            org_name: this.organisation.search.org_name
              ? this.organisation.search.org_name
              : null,
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.organisation.items =
            response.data && response.data.content_page
              ? response.data.content_page
              : [];
          this.organisation.items.sort(function (a, b) {
            if (a.name < b.name) return -1;
            else if (a.name > b.name) return 1;
            return 0;
          });
          this.organisation.tree.items = this.listToTree(
            this.organisation.items
          );

          this.organisation.structure.tree = this.organisation.tree.items;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.getAllOrganisation);
        }
      }

      this.buttons.reload.disabled = false;
      this.buttons.reload.processing = false;
      this.buttons.filter.processing = false;
      this.buttons.filter.disabled = false;
      this.organisation.loading = false;
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
};
</script>
<style lang="scss" scoped>
.organisation-content {
  padding: 0.5rem;
  padding-top: 0;
  .organisation__search {
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .organisation__datatable {
    padding: 0;
    margin-top: 0.5rem;
    margin-right: 0.25rem;
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .organisation__tree {
    padding: 0;
    margin-top: 0.5rem;
    margin-left: 0.25rem;
    .accordion {
      border-radius: 0.5rem;
    }
    .p-tree {
      border-color: transparent;
    }
  }
  .organisation__structure {
    padding: 0;
    margin-top: 0.5rem;
    position: relative;

    .actions {
      position: absolute;
      right: 2.5rem;
      top: 0.375rem;
      button {
        border-radius: 50% !important;
        background: #009ef7;
        color: white;
        margin-right: 0.25rem;
        width: 1.6125rem;
        height: 1.6125rem;
        padding: 0.25rem;
        &:last-child {
          margin-right: 0;
        }
        &:disabled {
          background: #f4f4f4;
          color: #009ef7;
        }
        .pi {
          font-size: 0.75rem !important;
        }
      }
    }
  }
}
</style>
