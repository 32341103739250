<template>
  <main-layout
    :isToolbar="false"
    :isBreadcrumb="true"
    :breadcrumbitems="[
      { label: $t('menu_administration') },
      { label: $t('menu_management_log') },
    ]"
    :propButtonsState="{
      save: {
        visible: false,
      },
      add: {
        visible: false,
      },
      edit: {
        visible: false,
      },
      reload: buttons.reload,
      print: {
        visible: false,
      },
    }"
    :propTitle="$t('menu_management_log')"
    @emitFunction="menuBarFunction($event)"
  >
    <div class="logs-content">
      <div class="logs__dialog">
        <DialogMessage
          v-if="message.length > 0"
          :propTitle="$t('title_message_info')"
          :prop-show="message.length > 0"
          :prop-message="message"
          @close="message = []"
        />
      </div>
      <div class="logs__search" id="elementSearch">
        <LogsFilter
          ref="logsearch"
          :propSearch="logs.search"
          :prop-buttons="{
            filter: buttons.filter,
          }"
          :prop-user="user.items"
          @onSearch="onSearch"
          @message="message = $event"
          @accordionHeaderClick="accordionHeaderClick"
        />
      </div>
      <div class="logs__datatable">
        <LogsDataTable
          :prop-items="logs.items"
          :propTableHeight="logs.table.height"
          :prop-total="logs.total_records"
          :prop-loading="logs.loading"
          @nextPage="nextPage"
        />
      </div>
    </div>
  </main-layout>
</template>
<script>
import MainLayout from "@/components/pages/layouts/admin/Main";
import DialogMessage from "@/components/commons/dialog/MessageBox";
import LogsFilter from "./Filter.vue";
import LogsDataTable from "./DataTable.vue";
import logFunc from "@/utils/functions/log";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import userFunc from "@/utils/functions/user";
import metaKeyFunc from "@/utils/functions/metakey";
export default {
  components: {
    MainLayout,
    LogsFilter,
    LogsDataTable,
    DialogMessage,
  },
  data() {
    return {
      message: [],
      buttons: {
        filter: {
          disabled: false,
          processing: false,
          visible: true,
        },
        reload: {
          disabled: false,
          processing: false,
          visible: true,
        },
      },
      actionsLog: {
        items: [],
      },
      user: {
        items: [],
      },
      logs: {
        items: [],
        is_bottom: false,
        search: {
          action: null,
          create_from: null,
          create_to: null,
          page: 0,
          size: 0,
        },
        loading: false,
        table: {
          selected: null,
          height: 400,
        },
        page_size: 0,
        page_number: 0,
        total_page: 0,
        total_records: 0,
      },
    };
  },
  async created() {
    this.logs.search.size = Math.ceil(this.tableHeight / 45) + 5;
    let today = new Date(),
      dateMinus7 = new Date();
    today.setHours(23);
    today.setMinutes(59);
    today.setSeconds(59);
    dateMinus7.setHours(0);
    dateMinus7.setMinutes(0);
    dateMinus7.setSeconds(0);
    this.logs.search.create_to = today;
    this.logs.search.create_from = new Date(
      dateMinus7.setDate(dateMinus7.getDate() - 7)
    );
    this.getAllUser();
    this.getLogs();
    await this.getAllActionsLog();
  },
  mounted() {
    setTimeout(() => {
      this.logs.table.height = this.calcTableHeight();
    }, 100);
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    tableHeight() {
      return this.documentHeight - 56 - 114 - 38 - 7;
    },
    username() {
      return this.$store.getters.getUserName;
    },
  },
  methods: {
    accordionHeaderClick() {
      setTimeout(() => {
        this.logs.table.height = this.calcTableHeight();
      }, 100);
    },
    calcTableHeight() {
      let element = document.getElementById("elementSearch");
      return (
        this.documentHeight -
        3 * 0.5 * 16 -
        56 -
        (element ? element.offsetHeight : 0) -
        24 -
        2 * 0.5 * 16
      );
    },
    menuBarFunction(val) {
      switch (val.code) {
        case "RELOAD":
          this.logs.search.action = null;
          this.logs.search.user_id = null;
          let today = new Date(),
            dateMinus7 = new Date();
          today.setHours(23);
          today.setMinutes(59);
          today.setSeconds(59);
          dateMinus7.setHours(0);
          dateMinus7.setMinutes(0);
          dateMinus7.setSeconds(0);
          this.logs.search.create_to = today;
          this.logs.search.create_from = new Date(
            dateMinus7.setDate(dateMinus7.getDate() - 7)
          );

          this.logs.search.page = 0;
          this.logs.is_bottom = false;
          this.logs.items = [];
          if (this.$refs.logsearch) this.$refs.logsearch.refreshSearchObject();
          this.getLogs();
          break;

        default:
          break;
      }
    },
    nextPage() {
      if (this.logs.is_bottom) return;
      this.logs.search.page = this.logs.search.page + 1;
      this.getLogs();
    },
    onSearch(data) {
      this.logs.is_bottom = false;
      this.logs.search.page = 0;
      this.logs.search.create_from = data.from;
      this.logs.search.create_to = data.to;
      this.logs.search.user_id = data.user_id;
      this.logs.search.action = data.action;
      this.logs.items = [];
      this.getLogs();
    },
    async getLogs() {
      if (this.logs.is_bottom) return;
      this.logs.loading = true;
      this.buttons.filter.processing = true;
      this.buttons.filter.disabled = true;
      this.buttons.reload.processing = true;
      this.buttons.reload.disabled = true;
      try {
        let response = await logFunc.getLogs(
          {
            create_from: this.logs.search.create_from
              ? new Date(this.logs.search.create_from)
              : null,
            create_to: this.logs.search.create_to
              ? new Date(this.logs.search.create_to)
              : null,
            user_id: this.logs.search.user_id,
            action: this.logs.search.action,
            page: this.logs.search.page,
            size: this.logs.search.size,
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data && response.data.content_page) {
            let dataResponse = response.data.content_page;
            dataResponse.sort(function (a, b) {
              if (a.created_at < b.created_at) return 1;
              else if (a.created_at > b.created_at) return -1;
              return 0;
            });

            for (let i = 0; i < dataResponse.length; i++) {
              let dateStr = new Date(dataResponse[i].created_at);
              let obj = Object.assign({}, dataResponse[i]);
              obj.date_str =
                (dateStr.getDate() < 10
                  ? "0" + dateStr.getDate()
                  : dateStr.getDate()) +
                "/" +
                (dateStr.getMonth() + 1 < 10
                  ? "0" + (dateStr.getMonth() + 1)
                  : dateStr.getMonth() + 1) +
                "/" +
                dateStr.getFullYear();
              if (!obj.data.is_metadata) {
                obj.processing = false;
                obj.message = [];
              }
              obj.data_image = null;
              obj.obj_action = this.actionsLog.items.find(
                (x) => x.name === dataResponse[i].action
              );
              this.logs.items.push(obj);
            }
          }
          this.logs.page_number = response.data.page_number;
          this.logs.page_size = response.data.page_size;
          this.logs.total_page = response.data.total_page;
          this.logs.total_records = response.data.total_records;
          if (
            this.logs.items.length === this.logs.total_records &&
            this.logs.page_number > 0 &&
            this.logs.total_records > 0
          )
            this.logs.is_bottom = true;
        }
        this.logs.loading = false;
        this.buttons.filter.processing = false;
        this.buttons.filter.disabled = false;

        this.buttons.reload.processing = false;
        this.buttons.reload.disabled = false;
      } catch (error) {
        this.logs.loading = false;
        this.buttons.filter.processing = false;
        this.buttons.filter.disabled = false;

        this.buttons.reload.processing = false;
        this.buttons.reload.disabled = false;
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.getLogs);
        }
      }
    },

    async getAllActionsLog() {
      try {
        let response = await metaKeyFunc.getByKey(
          "ACTIONS_LOG",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data && response.data.success) {
            this.actionsLog.items =
              response.data && response.data.data ? response.data.data : [];
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.getAllActionsLog);
        }
      }
    },
    async getAllUser() {
      try {
        let response = await userFunc.search(
          {
            page: 0,
            size: 2147483647,
            order: "full_name",
            sort: "asc",
          },
          {
            is_active: null,
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.user.items = response.data.content_page
            ? response.data.content_page.filter((x) => x.is_active)
            : [];
          // this.user.items.sort(function (a, b) {
          //   if (a.full_name < b.full_name) return -1;
          //   else if (a.full_name > b.full_name) return 1;
          //   return 0;
          // });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.getAllUser);
        }
      }
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
};
</script>
<style lang="scss" scoped>
.logs-content {
  padding: 0.5rem;
  .logs__search {
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .logs__datatable {
    padding: 0;
    margin-top: 0.5rem;
    .accordion {
      border-radius: 0.5rem;
    }
  }
}
</style>