<template>
  <div class="background">
    <div class="outer ">
      <div class="middle fadeInDown">
        <div class="card card-login mx-auto">
          <div class="card-header mx-auto text-center">
            <span>
              <img src="/img/logo.png" alt="Logo" />
            </span>
          </div>
          <div class="card-body">
            <ValidationObserver ref="observer" v-slot="{ valid }">
              <div class="notification text-danger" v-if="message">
                {{ message }}
              </div>
              <form v-on:keyup.enter="handleLogin">
                <div class="form-group">
                  <div class="form-group__username">
                    <ValidationProvider
                      :name="$t('label_username')"
                      :rules="'required'"
                      persist
                    >
                      <label class="w-100">{{ $t("label_username") }}</label>
                      <input
                        type="text"
                        v-model="username"
                        name="username"
                        class="form-control"
                        :placeholder="$t('placeholder_username')"
                        autocomplete="off"
                        autofocus
                      />
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group">
                  <div class="form-group__password">
                    <ValidationProvider
                      :name="$t('label_password')"
                      :rules="'required'"
                      persist
                    >
                      <label class="w-100"> {{ $t("label_password") }} </label>
                      <input
                        type="password"
                        v-model="password"
                        name="password"
                        class="form-control"
                        :placeholder="$t('placeholder_password')"
                        autocomplete="off"
                      />
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="chkrememberMe"
                        v-model="rememberMe"
                      />
                      <label class="form-check-label" for="chkrememberMe">{{
                        $t("label_remember_me")
                      }}</label>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <button
                    :disabled="!valid || loading"
                    type="button"
                    name="btn"
                    class="btn btn-geostore2 float-right w-100 btn-login"
                    @click="handleLogin"
                  >
                    <i class="fa fa-spinner fa-spin" v-if="loading"></i>&nbsp;
                    {{ $t("button_text_login") }}
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from "@/components/commons/checkbox/Checkbox";
import qs from "qs";
import cryptoFunc from "@/utils/functions/crypto";
import { tokenServiceApiURL } from "@/utils/commons/environment";
export default {
  components: { Checkbox },
  data() {
    return {
      username: null,
      password: null,
      message: null,
      rememberMe: false,
      loading: false,
    };
  },
  async created() {
    let data = localStorage.getItem("data2");
    let plaintext = await cryptoFunc.decrypt(atob(data));
    try {
      plaintext = JSON.parse(plaintext);
      if (plaintext && Object.keys(plaintext).length > 0) {
        this.username = plaintext["username"] ? plaintext["username"] : null;
        this.password = plaintext["password"] ? plaintext["password"] : null;
        this.rememberMe = plaintext["rememberMe"] ? true : false;
      }
    } catch (error) {}
  },
  methods: {
    handleLogin: async function() {
      var vm = this;
      let message = "";
      this.message = null;
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          setTimeout(() => {
            Object.keys(vm.$refs.observer.errors).forEach(function(key) {
              message =
                message + vm.$refs.observer.errors[key].join(",\n") + ". ";
            });
            if (message) alert(message.substring(0, message.length - 2));
          }, 100);
          return;
        }
        if (this.rememberMe) {
          localStorage.setItem(
            "data2",
            btoa(
              cryptoFunc
                .encrypt(
                  JSON.stringify({
                    username: this.username,
                    password: this.password,
                    rememberMe: this.rememberMe,
                  })
                )
                .toString()
            )
          );
        } else {
          localStorage.removeItem("data2");
        }
        this.loading = true;
        return vm
          .axios({
            method: "POST",
            url: tokenServiceApiURL + "/oauth/token",
            data: qs.stringify({
              grant_type: "password",
              username: vm.username,
              password: vm.password,
            }),
            headers: {
              Authorization: "Basic bW9iaWxlOm1vYmlsZQ==",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          })
          .then(async (res) => {
            if (res.status === 200) {
              this.$emit("loginSuccess", res.data);
            } else {
              this.message = this.$t("message_user_pass_wrong");
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            let msg =
              err.response &&
              err.response.data &&
              err.response.data.error_description;
            this.message =
              msg && msg.trim().toLowerCase() != "Bad credentials".toLowerCase()
                ? msg
                : this.$t("message_user_pass_wrong");
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
$dark: #343a40;
.outer {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1080;
  background-color: rgba(0, 0, 0, 0.75);
  input,
  button,
  span,
  p {
    font-size: 14px;
  }
}
.background {
  height: 100%;
  width: 100%;
  position: absolute;
  background-image: url("/img/background.jpg");
  background-size: 100%;
}
.middle {
  display: table-cell;
  vertical-align: middle;
}
.card {
  box-shadow: 0 12px 18px 2px rgba(34, 0, 51, 0.04),
    0 6px 22px 4px rgba(7, 48, 114, 0.12),
    0 6px 10px -4px rgba(14, 13, 26, 0.12);
  border-radius: 0.5rem;
}
.card-login {
  padding: 1rem;
  max-width: 26rem;
  .close {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    font-size: 14px;
    width: 24px;
    height: 24px;
    margin: 0 auto;
    padding: 0.3rem 0.45rem;
    border-radius: 50%;
    background: #dde1ec;
    &:hover {
      cursor: pointer;
    }
  }
}

.card-header {
  color: #fff;
  font-size: 20px;
  border-bottom: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  padding: 0;
  .title {
    font-size: 16px;
    color: $background-color;
    margin-top: 0.75rem;
  }
  img {
    width: 144px;
    height: 144px;
    &:hover {
      cursor: pointer;
    }
  }
}
.card-footer {
  background-color: transparent;
}
.card-body {
  padding: 0;
  padding-top: 1rem;
  .notification {
    border: 1px solid rgba(255, 0, 0, 0.15);
    background-color: rgba(255, 0, 0, 0.1);
    padding: 1rem 0.5rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  .form-group__username {
    position: relative;
    label {
      margin-bottom: 0.375rem;
      color: #3f4254;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
    input {
      background: #f5f8fa;
      border-radius: 0.25rem;
      border-color: #f5f8fa;
    }
    .form-group__username-icon {
      position: absolute;
      top: 0.75rem;
      left: 0.5rem;
    }
    .error {
      margin-top: 0.25rem;
    }
  }
  .form-group__password {
    position: relative;
    label {
      margin-bottom: 0.375rem;
      color: #3c4858;
    }
    input {
      background: #f1f4fa;
      border-radius: 4px;
      border-color: #f1f4fa;
    }
    .form-group__password-icon {
      position: absolute;
      top: 0.75rem;
      left: 0.5rem;
      .error {
        margin-top: 0.25rem;
      }
    }
  }
  .form-row {
    margin-bottom: 1rem;
    .form-check-input {
      margin-top: 0.25rem;
    }
    .label-forget-pass {
      color: $background-color;
      text-decoration: underline;
      &:hover {
        cursor: pointer;
        color: #007bff;
      }
    }
  }
  .btn-login {
    border-radius: 0.5rem;
    font-size: 14px;
  }
}
input:focus {
  outline: 0 0 0 0 !important;
  box-shadow: 0 0 0 0 !important;
}
form {
  color: $dark;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.6;
  background-color: transparent;
  background-clip: padding-box;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
