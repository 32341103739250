<template>
  <CommonAccordion
    :propTitle="$t('title_search')"
    @accordionHeaderClick="accordionHeaderClick"
  >
    <div slot="content" class="accordion">
      <div class="form-search">
        <div class="form-row no-gutters">
          <div class="col col-input-search">
            <div class="form-group">
              <label for="inputSearchName">{{ $t("label_action") }}</label>
              <Dropdown
                style="width: 100%"
                v-model="search.action"
                :showClear="false"
                :options="actions"
                optionValue="code"
                :filter="true"
                optionLabel="label"
                class="new-style"
              />
            </div>
          </div>
          <div class="col">
            <label class="control-label">{{ $t("label_username") }}</label>
            <Dropdown
              style="width: 100%"
              v-model="search.user_id"
              :showClear="false"
              :options="propUser"
              optionValue="id"
              :filter="true"
              optionLabel="full_name"
              class="new-style"
            />
          </div>
          <div class="col">
            <label class="control-label">{{ $t("label_date_from") }}</label>
            <date-picker
              v-model="search.from"
              class="w-100"
              :format="'DD/MM/YYYY'"
              valuetype="timestamp"
              :disabled-date="notAfterToday"
              :lang="lang"
              input-class="form-control new-style"
            >
            </date-picker>
          </div>
          <div class="col">
            <label class="control-label">{{ $t("label_date_to") }}</label>
            <date-picker
              v-model="search.to"
              class="w-100"
              :format="'DD/MM/YYYY'"
              valuetype="timestamp"
              :disabled-date="notAfterToday"
              :lang="lang"
              input-class="form-control new-style"
            >
            </date-picker>
          </div>
          <div class="col">
            <button
              :disabled="propButtons.filter.disabled"
              type="button"
              :label="$t('button_text_search')"
              :icon="
                propButtons.filter.processing
                  ? 'fas fa-spinner fa-spin'
                  : 'fas fa-search'
              "
              class="btn btn-geostore2 btn-sm btn-w120-h36"
              @click="onSearch"
            >
              <i
                :class="
                  propButtons.filter.processing
                    ? 'fas fa-spinner fa-spin'
                    : 'fas fa-search'
                "
              ></i
              >&nbsp;
              {{ $t("button_text_search") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import InputText from "@/components/commons/inputtext/InputText";
import Button from "@/components/commons/button/Button";
import DatePicker from "vue2-datepicker";
export default {
  props: {
    propButtons: {
      type: Object,
      default: () => {},
    },
    propUser: {
      type: Array,
      default: () => [],
    },

    propSearch: {
      type: Object,
      default: () => {
        return {
          action: null,
          user_id: null,
          create_from: null,
          create_to: null,
        };
      },
    },
  },
  components: {
    CommonAccordion,
    Dropdown,
    InputText,
    Button,
    DatePicker,
  },

  data() {
    return {
      search: {
        from: null,
        to: null,
        user_id: null,
        action: null,
      },
      actions: [
        {
          code: "UPLOAD_IMAGE",
          label: "Upload",
        },
        {
          code: "DOWNLOAD_IMAGE",
          label: "Download",
        },
      ],
    };
  },
  watch: {
    propSearch: {
      handler(val) {
        if (val && Object.keys(val).length > 0) {
          this.search.from = val.create_from ? val.create_from : null;
          this.search.to = val.create_to ? val.create_to : null;
          this.search.user_id = val.user_id ? val.user_id : null;
          this.search.action = val.action ? val.action : null;
        }
      },
      immediate: true,
    },
  },
  methods: {
    refreshSearchObject() {
      if (this.propSearch && Object.keys(this.propSearch).length > 0) {
        this.search.from = this.propSearch.create_from
          ? this.propSearch.create_from
          : null;
        this.search.to = this.propSearch.create_to
          ? this.propSearch.create_to
          : null;
        this.search.user_id = this.propSearch.user_id
          ? this.propSearch.user_id
          : null;
        this.search.action = this.propSearch.action
          ? this.propSearch.action
          : null;
      }
    },
    accordionHeaderClick() {
      this.$emit("accordionHeaderClick");
    },
    onSearch() {
      if (!this.search.from && this.search.to) {
        this.$emit("message", [
          this.$t("message_search_from_time_is_required"),
        ]);
        return;
      } else if (this.search.from && !this.search.to) {
        this.$emit("message", [this.$t("message_search_to_time_is_required")]);
        return;
      } else if (this.search.from && this.search.to) {
        let from = new Date(this.search.from).setHours(0, 0, 0, 0);
        let to = new Date(this.search.to).setHours(23, 59, 59, 0);
        if (from - to > 0) {
          this.$emit("message", [
            this.$t(
              "message_search_to_time_must_greater_than_or_equal_from_time"
            ),
          ]);
          return;
        }
        this.$emit("onSearch", {
          from: from,
          to: to,
          user_id: this.search.user_id ? this.search.user_id : null,
          action: this.search.action ? this.search.action : null,
        });
      } else {
        this.$emit("onSearch", {
          from: null,
          to: null,
          user_id: this.search.user_id ? this.search.user_id : null,
          action: this.search.action ? this.search.action : null,
        });
      }
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
.form-search {
  button {
    margin-top: 1.25rem;
    font-size: 14px;
  }
  label {
    padding: 0;
    margin: 0;
    padding-left: 0.25rem;
  }
}
.form-group {
  margin-bottom: 0.5rem;
  margin: 0;
  label {
    padding-left: 0.25rem;
  }
}
</style>
<style lang="scss">
.logs__search {
  .mx-datepicker {
    width: 100%;
    .mx-input {
      border: 1px solid #a6a6a6 !important;
      height: 33px;
      &:hover {
        border-color: #212121 !important;
      }
      &:disabled,
      .disabled {
        background-color: transparent;
      }
    }
  }
}
</style>
