<template>
  <CommonAccordion :propTitle="$t('title_user_list')">
    <div slot="content">
      <div class="datatable">
        <DataTable
          :value="propData.items"
          ref="dt"
          :paginator="propData.items && propData.items.length > 10"
          :loading="propLoading"
          :rows="10"
          :resizableColumns="true"
          columnResizeMode="expand"
          selectionMode="single"
          dataKey="id"
          :selection.sync="propTable.selected"
          @row-click="rowClick"
          :first="propFirst"
          @page="pageChange"
          :rowClass="rowClass"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll"
          :currentPageReportTemplate="$t('label_showing') + ' {first} '+$t('label_to').toLowerCase()+' {last} ' +$t('label_of').toLowerCase()+ ' {totalRecords}'"
        >
          <template #header v-if="propData.items && propData.items.length <= 10">
            <div class="text-left">
              <div>
                <div v-if="propData.items.length" class="font-weight-normal">
                  {{
                    $t("label_show_from_to", null, {
                      from: 1,
                      to: propData.items.length,
                      total: propData.total_records,
                    })
                  }}
                </div>
              </div>
            </div>
          </template>
          <template #empty v-if="propData.items && propData.items.length === 0">
            <span class="text-center">{{ $t("label_no_data") }}</span>
          </template>
          <Column
            header="No"
            headerStyle="width: 3rem; text-align: center!important"
            bodyStyle="text-align: center;"
          >
            <template #body="slotProps">
              {{ propTable.page * propTable.size + slotProps.index + 1 }}
            </template></Column
          >
          <Column field="full_name" :header="$t('label_full_name')"></Column
          ><Column field="username" :header="$t('label_username')"></Column>
          <Column
            field="phone_number"
            :header="$t('label_phone_number')"
          ></Column>
          <Column field="email" :header="$t('label_email')"></Column>
        </DataTable>
      </div></div
  ></CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import Button from "@/components/commons/button/Button";
import Menu from "@/components/commons/menu/Menu";
export default {
  props: {
    propData: {
      type: Object,
      default: () => {},
    },
    propTable: {
      type: Object,
      default: () => {},
    },
    propLoading: {
      type: Boolean,
      default: false,
    },
    propFirst: {
      type: Number,
      default: -1,
    },
  },
  components: { CommonAccordion, DataTable, Column, Button, Menu },
  data() {
    return {};
  },
  created() {},
  methods: {
    rowClick(val) {
      this.$emit("rowClick", val);
    },
    pageChange(e) {
      this.propTable.page = e.page;
      this.propTable.size = e.rows;
    },
    rowClass(data) {
      return !data.is_active ? "bg-secondary text-white" : "";
    },
  },
};
</script>
<style lang="scss">
.user-content__datatable {
  @import "@/assets/scss/_datatable.scss";
  .p-paginator {
    border: transparent;
    background: transparent;
  }
}
</style>