<template>
  <CommonAccordion :propTitle="$t('title_search')">
    <div slot="content">
      <div class="form-search">
        <div class="form-row no-gutters">
          <div class="col col-input-search">
            <div class="form-group">
              <label for="inputSearchName">{{
                $t("label_organisation_name")
              }}</label>
              <InputText
                id="inputSearchName"
                style="width: 100%"
                type="search"
                v-model="search.org_name"
                class="new-style"
              />
            </div>
          </div>
          <div class="col">
            <!-- <Button
              type="button"
              :label="$t('button_text_search')"
              icon="fas fa-search"
              class="p-button-success p-button-sm"
              @click="onSearch"
            /> -->
            <button
              :disabled="
                propButtons && propButtons.filter && propButtons.filter.disabled
              "
              type="button"
              :label="$t('button_text_search')"
              :icon="
                propButtons &&
                propButtons.filter &&
                propButtons.filter.processing
                  ? 'fas fa-spinner fa-spin'
                  : 'fas fa-search'
              "
              class="btn btn-geostore2 btn-sm btn-w120-h36"
              @click="onSearch"
            >
              <i
                :class="
                  propButtons &&
                  propButtons.filter &&
                  propButtons.filter.processing
                    ? 'fas fa-spinner fa-spin'
                    : 'fas fa-search'
                "
              ></i
              >&nbsp;
              {{ $t("button_text_search") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import InputText from "@/components/commons/inputtext/InputText";
import Button from "@/components/commons/button/Button";
export default {
  props: {
    propSearch: {
      type: Object,
      default: () => {
        return {
          org_name: null,
        };
      },
    },
    propButtons: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    CommonAccordion,
    Button,
    InputText,
  },
  data() {
    return {
      normalizer(node) {
        return {
          id: node.code,
        };
      },
      search: {
        org_name: null,
      },
    };
  },
  created() {
    if (this.propSearch && Object.keys(this.propSearch).length > 0) {
      this.search.org_name = this.propSearch.org_name
        ? this.propSearch.org_name
        : null;
    }
  },
  watch: {
    "propSearch.org_name": {
      handler(val) {
        this.search.org_name = val ? val : null;
      },
      immediate: true,
    },
  },
  methods: {
    clearText() {
      this.search.org_name = "";
    },
    onSearch() {
      this.$emit("onSearch", this.search);
    },
  },
};
</script>
<style lang="scss" scoped>
.form-search {
  button {
    margin-top: 1.25rem;
    font-size: 14px;
  }
  label {
    margin: 0;
    padding: 0;
    padding-left: 0.25rem;
  }
}
.form-group {
  margin-bottom: 0.5rem;
  margin: 0;
  label {
    padding-left: 0.25rem;
  }
}
</style>
