
<template>
  <div class="huGHgeKd82d">
    <PieChart :chartdata="pie.chartData" :options="pie.options" />
  </div>
</template>
<script>
import PieChart from "@/components/commons/chart/Pie2";
export default {
  components: {
    PieChart,
  },
  props: {
    propData: {
      type: Array,
      default: () => [],
    },
    propSatelliteType: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      id:
        new Date().getTime() +
        Math.floor(Math.random() * 9999999) -
        Math.floor(Math.random() * 1000000),
      loading: true,

      pie: {
        chartData: {
          labels: [],
          color: "#fff",
          datasets: [
            {
              backgroundColor: [],
              data: [],
            },
          ],
        },
        options: {
          id: "hod",
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true,
          },
        },
      },
    };
  },
  created() {
    this.id =
      this.id -
      Math.floor(Math.random() * 5000) +
      Math.floor(Math.random() * 9999);
  },
  watch: {
    propData: {
      handler(val) {
        this.pie.chartData.labels = [];
        this.pie.chartData.datasets[0].data = [];
        this.pie.chartData.datasets[0].backgroundColor = [];
        if (val && val.length > 0) {
          for (let i = 0; i < this.propSatelliteType.length; i++) {
            let ids = [
              ...new Set(
                this.propSatelliteType[i].sub_meta_key.map((x) => x.name)
              ),
            ];
            let satellites = val.filter((x) => ids.includes(x.satellite.name));
            if (satellites && satellites.length > 0) {
              let color = this.getRandomColor();
              if (
                this.propSatelliteType[i].name.trim().toLowerCase() ===
                "optical"
              ) {
                color = "#F1416C";
              } else if (
                this.propSatelliteType[i].name.trim().toLowerCase() === "radar"
              ) {
                color = "#009EF7";
              }
              this.pie.chartData.labels.push(this.propSatelliteType[i].value);
              this.pie.chartData.datasets[0].backgroundColor.push(color);
              this.pie.chartData.datasets[0].data.push(
                satellites && satellites.length > 0
                  ? satellites.reduce(
                      (sum, record) => sum + parseFloat(record["total"]),
                      0
                    )
                  : 0
              );
            }
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    hexToRgbA(hex, opacity) {
      if (!opacity) opacity = "0.4";
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.huGHgeKd82d {
  position: relative;
  height: 18rem;
  width: 100%;
}
</style>
