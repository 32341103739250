<template>
  <Dialog
    :header="$t('title_add_images')"
    :visible.sync="show"
    :style="{ zIndex: 1060 }"
    :contentStyle="propStyle"
    :modal="true"
    :showHeader="true"
    @hide="onClose"
  >
    <div class="add-images">
      <div class="add-images__search">
        <div class="row no-gutters">
          <div class="col-8 col-input-search">
            <div class="form-group">
              <InputText
                id="inputSearchByText"
                style="width: 100%"
                type="search"
                v-model="strSearch"
                class="new-stype"
              />
            </div>
          </div>
          <div class="col-4 pl-2">
            <Button
              type="button"
              :label="$t('button_text_search')"
              :icon="
                button.processing ? 'fas fa-spinner fa-spin' : 'pi pi-search'
              "
              class="p-button-success p-button-sm"
              @click="onSearch"
              :disabled="button.disabled"
            />
          </div>
        </div>
      </div>
      <div class="add-images__search--results">
        <CommonAccordion :propTitle="$t('title_search_results')">
          <div slot="content" class="datatable">
            <DataTable
              :value="images.items"
              :loading="button.processing"
              ref="dtresult"
              columnResizeMode="expand"
              dataKey="id"
              :virtualScroll="true"
              @virtual-scroll="onVirtualScroll"
              :scrollHeight="tableHeight + 'px'"
              :scrollable="true"
              :resizableColumns="true"
            >
              <template #empty v-if="images.items && images.items.length === 0">
                <span class="text-center">{{ $t("no_data") }}</span>
              </template>
              <Column
                header="No"
                headerStyle="width: 3rem; text-align: center!important"
                bodyStyle="text-align: center;"
              >
                <template #body="slotProps">
                  {{ slotProps.index + 1 }}
                </template></Column
              >
              <Column headerStyle="width: 3em">
                <template #header>
                  <Checkbox
                    v-model="checkAll"
                    :binary="true"
                    @change="checkAllChange"
                  />
                </template>
                <template #body="slotProps">
                  <Checkbox
                    :id="slotProps.data.id"
                    v-model="selectedClone"
                    :value="slotProps.data.id"
                  />
                </template>
              </Column>
              <Column
                :header="$t('label_image_name')"
                headerStyle="width: 15rem"
              >
                <template #body="slotProps">
                  {{ slotProps.data.name }}
                </template>
              </Column>
              <Column
                :header="$t('label_source_id')"
                headerStyle="width: 14rem"
              >
                <template #body="slotProps">
                  <div
                    :title="
                      slotProps.data.scene_id &&
                      slotProps.data.scene_id.length > 22
                        ? slotProps.data.scene_id
                        : ''
                    "
                  >
                    {{
                      slotProps.data.scene_id &&
                      slotProps.data.scene_id.length > 22
                        ? slotProps.data.scene_id.substring(0, 22) + "..."
                        : ""
                    }}
                  </div></template
                >
              </Column>
              <Column :header="$t('label_image_time')">
                <template #body="slotProps">
                  {{
                    slotProps.data.info && slotProps.data.info.imaging_date
                      ? slotProps.data.info.imaging_date
                      : null
                  }}</template
                >
              </Column>
            </DataTable>
          </div>
        </CommonAccordion>
      </div>
      <div class="add-images__actions mt-2 mb-2">
        <div class="row no-gutters text-center">
          <button
            type="button"
            :label="$t('button_text_add')"
            icon="pi pi-plus"
            class="btn btn-geostore2 btn-add"
            :disabled="!selectedClone || selectedClone.length === 0"
            @click="add2Table"
          >
            <i class="fas fa-plus"></i>
            {{ $t("button_text_add") }}
          </button>
        </div>
      </div>
      <div class="add-images__search--added">
        <CommonAccordion :propTitle="$t('label_selected_results')">
          <div class="datatable" slot="content">
            <DataTable
              :value="imagesAdded"
              :paginator="imagesAdded && imagesAdded.length > 10"
              :rows="5"
              ref="dtadded"
              :resizableColumns="true"
              columnResizeMode="expand"
              selectionMode="single"
              dataKey="id"
            >
              <template #empty v-if="imagesAdded && imagesAdded.length === 0">
                <span class="text-center">{{ $t("no_data") }}</span>
              </template>
              <Column
                header="No"
                headerStyle="width: 3rem; text-align: center!important"
                bodyStyle="text-align: center;"
              >
                <template #body="slotProps">
                  {{ slotProps.index + 1 }}
                </template></Column
              >
              <Column :header="$t('label_image_name')">
                <template #body="slotProps">
                  <div :title="slotProps.data.name">
                    {{ slotProps.data.name }}
                  </div>
                </template>
              </Column>
              <Column :header="$t('label_source_id')">
                <template #body="slotProps">
                  <div
                    :title="
                      slotProps.data.scene_id ? slotProps.data.scene_id : ''
                    "
                  >
                    {{ slotProps.data.scene_id ? slotProps.data.scene_id : "" }}
                  </div>
                </template>
              </Column>
              <Column :header="$t('label_image_time')">
                <template #body="slotProps">
                  {{
                    slotProps.data.info && slotProps.data.info.imaging_date
                      ? slotProps.data.info.imaging_date
                      : null
                  }}</template
                >
              </Column>
            </DataTable>
          </div>
        </CommonAccordion>
      </div>
    </div>
    <template #footer>
      <button
        @click="onApply"
        :label="$t('button_text_select')"
        icon="pi pi-check"
        class="btn btn-geostore2 btn-select m-0"
      >
        <i class="fas fa-check"></i>&nbsp;{{ $t("button_text_select") }}
      </button>
    </template>
  </Dialog>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import ContextMenu from "@/components/commons/contextmenu/ContextMenu";
import Button from "@/components/commons/button/Button";
import Dialog from "@/components/commons/dialog/Dialog";
import InputText from "@/components/commons/inputtext/InputText";
import Checkbox from "@/components/commons/checkbox/Checkbox";
import imageFunc from "@/utils/functions/image";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import moment from "moment";
export default {
  components: {
    DataTable,
    Column,
    ContextMenu,
    Button,
    Dialog,
    InputText,
    CommonAccordion,
    Checkbox,
  },
  props: {
    propShow: {
      type: Boolean,
      default: false,
    },
    propImages: {
      type: Array,
      default: () => [],
    },
    propStyle: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      checkAll: false,
      show: false,
      selected: null,
      selectedClone: [],
      imagesResult: [],
      imagesAdded: [],
      strSearch: null,
      button: {
        disabled: false,
        processing: false,
      },
      images: {
        is_bottom: false,
        loading: false,
        items: [],

        search: {
          text: null,
          size: 15,
          page: 0,
        },
      },
    };
  },
  created() {
    this.show = this.propShow;
    this.imagesAdded = Object.assign(
      [],
      this.propImages ? this.propImages : []
    );
    this.searchImages();
  },
  watch: {
    propShow: {
      handler(val) {
        this.show = val;
      },
      immediate: true,
    },
    selectedClone: {
      handler(val) {
        if (val && val.length === this.images.items.length && val.length > 0)
          this.checkAll = true;
        else this.checkAll = false;
      },
      immediate: true,
    },
  },
  methods: {
    onVirtualScroll(e) {
      if (!this.images.is_bottom) {
        this.images.search.page = this.images.search.page + 1;
        this.searchImages();
      }
    },
    formatUnixDateTime2String(value) {
      let result = "";
      if (value) {
        try {
          let date = new Date(value);
          result = moment
            .unix(date.getTime() / 1000)
            .format("HH:mm DD/MM/YYYY");
          result = result
            .replace(":", this.lang === "vi" ? "h" : ":")
            .replace(" ", this.lang === "vi" ? " ngày " : " ");
        } catch (error) {}
      }
      return result;
    },
    onClose() {
      this.show = false;
      this.$emit("close");
    },
    onApply() {
      if (this.imagesAdded && this.imagesAdded.length > 0) {
        this.$emit("selected", this.imagesAdded);
      }
    },
    add2Table() {
      if (this.selectedClone && this.selectedClone.length > 0) {
        for (let i = 0; i < this.selectedClone.length; i++) {
          if (
            this.imagesAdded.findIndex((x) => x.id === this.selectedClone[i]) <
            0
          ) {
            this.imagesAdded.push(
              this.images.items.find((x) => x.id === this.selectedClone[i])
            );
          } else {
            this.$emit(
              "message",
              this.$toast.info({
                title: this.$t("title_message_info"),
                message: this.$t("message_image_already_exists", null, {
                  name: this.selectedClone[i].name,
                }),
                position: "top right",
              })
            );
          }
        }
      }
    },

    checkAllChange() {
      if (this.checkAll) {
        this.selectedClone = this.images.items.map((x) => x.id);
      } else {
        this.selectedClone = [];
      }
    },
    async onSearch() {
      this.images.search.page = 0;
      this.images.is_bottom = false;
      this.images.items = [];
      this.searchImages();
    },
    async searchImages() {
      if (this.images.is_bottom) return;

      this.button.disabled = true;
      this.button.processing = true;
      this.images.loading = true;
      this.images.search.text = this.strSearch;
      try {
        let response = await imageFunc.search(
          {
            page: this.images.search.page,
            size: this.images.search.size,
          },
          {
            boundary: [],
            text: this.strSearch ? this.strSearch.trim() : null,
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (this.checkAll) {
            this.selectedClone = [
              ...this.selectedClone,
              ...(response.data && response.data.content_page
                ? response.data.content_page.map((x) => x.id)
                : []),
            ];
          }
          this.images.items = [
            ...this.images.items,
            ...(response.data && response.data.content_page
              ? response.data.content_page
              : []),
          ];
          this.images.page_number = response.data.page_number;
          this.images.page_size = response.data.page_size;
          this.images.total_page = response.data.total_page;
          this.images.total_records = response.data.total_records;
          if (
            (this.images.items.length === this.images.total_records &&
              this.images.total_page > 1) ||
            (this.images.total_page === 1 &&
              this.images.items.length <= response.data.page_size)
          )
            this.images.is_bottom = true;
          else this.images.is_bottom = false;
        }
        this.images.loading = false;
        this.button.disabled = false;
        this.button.processing = false;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.searchImages);
        }
      }
      this.images.loading = false;
      this.button.disabled = false;
      this.button.processing = false;
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    tableHeight() {
      return (this.documentHeight - 450) / 2;
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
.add-images {
  .add-images__search {
    .col-input-search {
      padding-top: 0.125rem;
      .form-group {
        margin-bottom: 0.5rem;
      }
    }
  }
  .add-images__search--results {
    border: 1px solid #ccc;
    .datatable {
      padding: 0.5rem;
    }
  }
  .add-images__actions {
    button {
      margin: 0 auto;
    }
  }
  .add-images__search--added {
    border: 1px solid #ccc;
    .accordion {
      .accordion-header {
        background: #f1faff;
      }
    }
    .datatable {
      padding: 0.5rem;
    }
  }
}
button {
  font-size: 14px;
  &.btn-add {
    width: 96px !important;
  }
  &.btn-select {
    width: 112px !important;
  }
}
</style>
