var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-layout',{attrs:{"isToolbar":false,"isBreadcrumb":true,"breadcrumbitems":[
    { label: _vm.$t('menu_order_management') },
    { label: _vm.$t('menu_order_detail') } ],"propButtonsState":{
    save: {
      visible: false,
    },
    add: {
      visible: false,
    },
    edit: {
      visible: false,
    },
    reload: {
      visible: false,
    },
    print: {
      visible: false,
    },
  },"propTitle":_vm.$t('menu_order_detail')}},[_c('div',{staticClass:"order-detail-content"},[(!_vm.loading)?_c('div',[(!_vm.id || !_vm.data || Object.keys(_vm.data).length === 0)?_c('div',{staticClass:"empty-data"},[_c('div',{attrs:{"id":"notfound"}},[_c('div',{staticClass:"notfound"},[_c('div',{staticClass:"notfound-404"},[_c('h3',[_vm._v("No data found")]),_c('h1',[_c('span',[_vm._v("4")]),_c('span',[_vm._v("0")]),_c('span',[_vm._v("4")])])]),_c('h6',[_vm._v(_vm._s(_vm.$t("message_cound_not_looking_for")))])])])]):_c('div',[_c('Detail',{attrs:{"prop-item":_vm.data,"propGranularities":_vm.granularity.items,"propLookDirection":_vm.lookDirection.items,"propOrbitDirection":_vm.orbitDirection.items,"propProgramingServices":_vm.programingService.items,"propSatelliteType":_vm.satelliteType.items},on:{"getAllDirection":_vm.getAllDirection,"getAllTaskingProgrammingService":_vm.getAllTaskingProgrammingService,"getAllTaskingGranularity":_vm.getAllTaskingGranularity,"getAllSatelliteType":_vm.getAllSatelliteType}})],1)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }