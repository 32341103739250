<template>
  <div class="upload-page">
    <UploadSatellite />
  </div>
</template>
<script>
import UploadSatellite from "@/components/pages/upload/Satellite";
export default {
  components: {
    UploadSatellite,
  },
};
</script>