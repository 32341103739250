<template>
  <Dialog
    :header="propTitle"
    :visible.sync="show"
    :style="{ zIndex: 1060 }"
    :contentStyle="propStyle ? propStyle : { width: '480px' }"
    :modal="true"
    @hide="onClose"
  >
    <div class="confirmation-content">
      <i :class="propIcon" style="font-size: 2rem" />
      <span class="pl-2">&nbsp;{{ propMessage }}</span>
    </div>
    <template #footer>
      <Button
        :label="textNo"
        icon="pi pi-times"
        @click="onClose"
        class="p-button-danger p-button-sm"
        autofocus
      />
      <Button
        :label="textYes"
        icon="pi pi-check"
        @click="onConfirm"
        class="p-button-success p-button-sm"
      />
    </template>
  </Dialog>
</template>
<script>
import Button from "@/components/commons/button/Button";
import Dialog from "./Dialog";
export default {
  components: {
    Button,
    Dialog,
  },
  props: {
    propTitle: {
      type: String,
      default: "Confirmation",
    },
    propShow: {
      type: Boolean,
      default: false,
    },
    propMessage: {
      type: String,
      default: "",
    },
    propIcon: {
      type: String,
      default: "pi pi-exclamation-triangle p-mr-3",
    },
    propStyle: {
      type: Object,
      default: () => {
        return {
          width: "540px",
        };
      },
    },
    propType: {
      type: String,
      default: "warning",
    },
    textNo: {
      type: String,
      default: "No",
    },
    textYes: {
      type: String,
      default: "Yes",
    },
  },
  data() {
    return {
      show: false,
    };
  },
  created() {
    this.show = this.propShow;
  },
  watch: {
    propShow: {
      handler(val) {
        this.show = val;
      },
      immediate: true,
    },
  },
  methods: {
    onClose() {
      this.show = false;
      this.$emit("cancel");
    },
    onConfirm() {
      this.$emit("confirmed");
    },

    formatText() {
      return this.propMessage.join("<br>");
    },
  },
};
</script>
<style lang="scss" scoped>
.p-dialog .p-dialog-footer button {
  margin: 0;
  margin-left: 0.5rem;
  &:first-child {
    margin: 0;
  }
}
.confirmation-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: left;
}
button {
  margin-left: 0.5rem;
}
</style>
