<template>
  <main-layout
    :isToolbar="true"
    :isBreadcrumb="true"
    :breadcrumbitems="[
      { label: $t('menu_administration') },
      { label: $t('menu_management_permission') },
    ]"
    :propButtonsState="{
      save: buttons.save,
      add: buttons.add,
      edit: buttons.edit,
      reload: buttons.reload,
      print: {
        visible: false,
      },
      delete: buttons.delete,
    }"
    :propTitle="$t('menu_management_permission')"
    @emitFunction="menuBarFunction($event)"
  >
    <div class="permission-content">
      <div class="permission-content__dialog">
        <DialogConfirmation
          v-if="confirm.show"
          :prop-show="confirm.show"
          :prop-title="confirm.title"
          :prop-icon="confirm.icon"
          :prop-message="confirm.message"
          :textNo="$t('button_text_close')"
          :textYes="$t('button_text_save')"
          @cancel="onCancelConfirm"
          @confirmed="onConfirmed"
        />
        <DialogConfirmation
          v-if="confirmDelete.show"
          :prop-show="confirmDelete.show"
          :prop-title="$t('title_message_confirm')"
          :prop-icon="confirmDelete.icon"
          :prop-message="confirmDelete.message"
          :textNo="$t('button_text_close')"
          :textYes="$t('button_text_delete')"
          @cancel="onCancelConfirmDelete"
          @confirmed="onConfirmedDelete"
        />
        <DialogMessage
          v-if="message.length > 0"
          :propTitle="$t('title_message_info')"
          :prop-show="message.length > 0"
          :prop-message="message"
          @close="message = []"
        />
      </div>
      <div class="permission-content__datatable">
        <DataTable
          ref="permissionDatatable"
          :propData="{
            items: permission.items,
            page_number: permission.page_number,
            page_size: permission.page_size,
            total_page: permission.total_page,
            total_records: permission.total_records,
            permissions: permission.permissions,
          }"
          :prop-table="permission.table"
          :prop-first="permission.first"
          :prop-loading="permission.loading"
          @rowClick="rowClick"
        />
      </div>
      <div class="permission-content__structure">
        <ValidationObserver ref="permisisonObserver">
          <Structure
            :prop-entity="permission.entity"
            :prop-status="{
              isAdd: permission.isAdd,
              isEdit: permission.isEdit,
            }"
          />
        </ValidationObserver>
        <div class="actions">
          <Button
            @click="prevItem"
            :icon="
              buttons.prev.processing
                ? 'fa fa-spinner fa-spin'
                : 'pi pi-chevron-left'
            "
            class="p-button-rounded p-button-secondary"
            :disabled="buttons.prev.disabled"
          />
          <Button
            @click="nextItem"
            :icon="
              buttons.next.processing
                ? 'fa fa-spinner fa-spin'
                : 'pi pi-chevron-right'
            "
            class="p-button-rounded p-button-secondary"
            :disabled="buttons.next.disabled"
          />
        </div>
      </div>
    </div>
  </main-layout>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion";
import MainLayout from "@/components/pages/layouts/admin/Main";
import DataTable from "./DataTable";
import Structure from "./Structure";
import Button from "@/components/commons/button/Button";
import DialogMessage from "@/components/commons/dialog/MessageBox";
import DialogConfirmation from "@/components/commons/dialog/Confirmation";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import permissionFunc from "@/utils/functions/permission";
export default {
  components: {
    DataTable,
    Structure,
    CommonAccordion,
    MainLayout,
    Button,
    DialogConfirmation,
    DialogMessage,
  },
  data() {
    return {
      message: [],
      confirm: {
        message: null,
        show: false,
        title: this.$t("title_message_confirm"),
        icon: "fas fa-question",
        callback: {
          no: {
            method: null,
            params: null,
          },
          yes: {
            method: null,
            params: null,
          },
        },
        initValue: [],
        value: false,
      },
      confirmDelete: {
        message: null,
        show: false,
        title: null,
        icon: "fas fa-question",
        value: false,
      },
      permission: {
        items: [],
        first: -1,
        isAdd: false,
        isEdit: false,
        page_number: 0,
        page_size: 2147483647,
        total_page: 0,
        total_records: 0,
        loading: false,
        entity: {
          id: null,
          name: null,
          description: null,
          is_active: null,
          is_delete: false,
          translation: {
            vi: {
              description: null,
            },
            en: {
              description: null,
            },
          },
        },
        table: {
          selected: null,
          page: 0,
          size: 10,
        },
      },

      buttons: {
        prev: {
          disabled: true,
          processing: false,
          visible: true,
        },
        next: {
          disabled: true,
          processing: false,
          visible: true,
        },
        save: {
          disabled: true,
          processing: false,
          visible: true,
        },
        edit: {
          disabled: true,
          processing: false,
          visible: true,
        },
        add: {
          disabled: false,
          processing: false,
          visible: true,
        },
        filter: {
          disabled: false,
          processing: false,
          visible: true,
        },
        reload: {
          disabled: false,
          processing: false,
          visible: true,
        },
        delete: {
          disabled: true,
          processing: false,
          visible: true,
        },
      },
    };
  },
  created() {
    this.getAllPermission();
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    accessToken() {
      return this.$store.getters.getAccessToken;
    },
  },
  methods: {
    generateEntity() {
      return {
        id: null,
        name: null,
        description: null,
        is_active: null,
        is_delete: false,
        translation: {
          vi: {
            description: null,
          },
          en: {
            description: null,
          },
        },
      };
    },

    entityFromSelected(data) {
      if (data && Object.keys(data).length > 0) {
        this.permission.entity.id = data.id ? data.id : null;
        this.permission.entity.name = data.name ? data.name : null;
        this.permission.entity.description = data.description
          ? data.description
          : null;
        this.permission.entity.is_active =
          data.is_active != undefined || data.is_active != null
            ? data.is_active
            : true;
        this.permission.entity.is_delete =
          data.is_delete != undefined || data.is_delete != null
            ? data.is_delete
            : false;

        this.permission.entity.translation = data.translation
          ? data.translation
          : null;
        if (
          !this.permission.entity.translation ||
          Object.keys(this.permission.entity.translation).length === 0
        ) {
          this.permission.entity.translation = {
            vi: {
              description: this.permission.entity.description,
            },
            en: {
              description: null,
            },
          };
        } else {
          if (
            !this.permission.entity.translation.vi ||
            Object.keys(this.permission.entity.translation.vi).length === 0
          ) {
            this.permission.entity.translation.vi = {
              description: this.permission.entity.description,
            };
          }
          if (
            !this.permission.entity.translation.en ||
            Object.keys(this.permission.entity.translation.en).length === 0
          ) {
            this.permission.entity.translation.en = {
              description: null,
            };
          }
        }
      }
    },
    // return false. Entity chua bi thay doi
    // return true. Entity da bi thay doi
    checkEntity() {
      var vm = this,
        result = false;
      for (const [key, value] of Object.entries(this.permission.entity)) {
        if (value != null) {
          switch (typeof value) {
            case "number":
              if (value > 0) result = true;

              break;
            case "string":
              if (value.trim()) result = true;
              break;
            case "undefined":
              break;
          }
        }
        if (result) break;
      }
      return result;
    },

    async prevItem() {
      if (this == undefined) return;
      if (this.$refs.permissionDatatable) {
        let data = this.$refs.permissionDatatable.$refs.dt.processedData;
        let selection = this.$refs.permissionDatatable.$refs.dt.selection;
        if (!selection) return;
        let index = data.findIndex((x) => x.id === selection.id);
        this.buttons.prev.processing = true;
        this.buttons.prev.disabled = true;
        //data[index + 1]
        this.$store.dispatch("setSpinnerApp", {
          show: true,
        });
        await this.entityFromSelected(data[index - 1]);
        this.permission.table.selected = data[index - 1];
        this.buttons.prev.processing = false;
        this.buttons.prev.disabled = false;

        if (index === 1) {
          this.buttons.prev.disabled = true;
          this.buttons.next.disabled = false;
        } else {
          this.buttons.next.disabled = false;
          this.buttons.prev.disabled = false;
        }

        this.permission.table.page = Math.floor((index - 1) / 10);
        this.permission.first = Math.floor((index - 1) / 10) * 10;
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
      }
    },
    async nextItem() {
      if (this == undefined) return;
      if (this.$refs.permissionDatatable) {
        let data = this.$refs.permissionDatatable.$refs.dt.processedData;
        let selection = this.$refs.permissionDatatable.$refs.dt.selection;
        if (!selection) return;
        let index = data.findIndex((x) => x.id === selection.id);
        this.buttons.next.processing = true;
        this.buttons.next.disabled = true;
        this.$store.dispatch("setSpinnerApp", {
          show: true,
        });
        await this.entityFromSelected(data[index + 1]);
        this.permission.table.selected = data[index + 1];
        this.buttons.next.processing = false;
        this.buttons.next.disabled = false;

        if (index === data.length - 2) {
          this.buttons.prev.disabled = false;
          this.buttons.next.disabled = true;
        } else {
          this.buttons.prev.disabled = false;
          this.buttons.next.disabled = false;
        }
        this.permission.table.page = Math.floor((index + 1) / 10);
        this.permission.first = Math.floor((index + 1) / 10) * 10;
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
      }
    },
    /* ---------- Emit ----------*/

    async rowClick(val) {
      this.permission.isAdd = false;
      this.permission.isEdit = false;
      this.buttons.add.disabled = false;
      this.buttons.add.processing = false;
      this.buttons.edit.disabled = false;
      this.buttons.edit.processing = false;
      this.buttons.save.disabled = true;
      this.buttons.save.processing = false;
      this.buttons.delete.disabled = false;
      this.buttons.delete.processing = false;
      if (val.index === 0) {
        this.buttons.prev.disabled = true;
        if (this.permission.items.length > 1)
          this.buttons.next.disabled = false;
        else this.buttons.next.disabled = true;
      } else if (val.index === this.permission.items.length - 1) {
        if (this.permission.items.length > 1)
          this.buttons.prev.disabled = false;
        else this.buttons.prev.disabled = true;
        this.buttons.next.disabled = true;
      } else {
        this.buttons.prev.disabled = false;
        this.buttons.next.disabled = false;
      }
      if (val.data) {
        this.$store.dispatch("setSpinnerApp", {
          show: true,
        });
        this.permission.table.selected = val.data;
        this.permission.entity = this.generateEntity();
        await this.entityFromSelected(val.data);
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
      }
    },

    async menuBarFunction(val) {
      switch (val.code) {
        case "SAVE":
          if (this.permission.entity.id && this.permission.isAdd) {
            let check = this.checkEntity();
            if (check) {
              try {
                if (this.permission.isAdd)
                  this.confirm.message =
                    this.$t("label_add_permission") +
                    " " +
                    (this.permission.entity.description
                      ? this.permission.entity.description.trim()
                      : "") +
                    "?";
                else
                  this.confirm.message =
                    this.$t("label_save_permission") +
                    " " +
                    (this.permission.entity.description.trim()
                      ? this.permission.entity.description
                      : "") +
                    "?";
                this.confirm.callback.yes.method = "onSave";
                this.confirm.callback.yes.params = null;
                this.confirm.callback.no.method = null;
                this.confirm.callback.no.params = null;
                this.confirm.initValue.cancel = [];
                this.confirm.initValue.confirmed = [
                  {
                    variable: "permission",
                    key: "isAdd",
                    value: false,
                  },
                ];
                this.confirm.show = true;
                return;
              } catch (error) {
                return;
              }
            }
          }
          this.onSave();
          break;
        case "ADD":
          this.permission.isEdit = false;
          this.permission.isAdd = true;
          this.buttons.edit.disabled = true;
          this.buttons.edit.processing = false;
          this.buttons.save.disabled = false;
          this.buttons.save.processing = false;
          this.buttons.delete.disabled = true;
          this.buttons.delete.processing = false;
          this.permission.table.selected = null;
          this.permission.entity = this.generateEntity();
          break;
        case "EDIT":
          this.permission.isEdit = true;
          this.permission.isAdd = false;
          this.buttons.add.disabled = false;
          this.buttons.add.processing = false;
          this.buttons.save.disabled = false;
          this.buttons.save.processing = false;
          break;
        case "FILTER":
          break;
        case "RELOAD":
          this.buttons.filter.disabled = true;
          this.buttons.filter.processing = true;
          this.buttons.reload.processing = true;
          this.buttons.reload.disabled = true;
          if (!this.cancel) await this.onFilter();
          this.buttons.reload.processing = false;
          this.buttons.reload.disabled = false;
          this.buttons.filter.disabled = false;
          this.buttons.filter.processing = false;
          break;
        case "DELETE":
          this.confirmDelete.value = this.permission.entity.id;
          this.confirmDelete.message = this.$t(
            "message_confirm_delete_permission",
            null,
            {
              name: this.permission.entity.description
                ? this.permission.entity.description.trim()
                : "",
            }
          );
          this.confirmDelete.show = true;
          break;
        default:
          break;
      }
    },
    onCancelConfirm() {
      this.confirm.show = false;
      this.confirm.message = "";
      this.confirm.callback.method = null;
      this.confirm.callback.params = null;
      if (this.confirm.initValue && this.confirm.initValue.cancel) {
        for (let i = 0; i < this.confirm.initValue.cancel.length; i++) {
          if (this.confirm.initValue.cancel[i].variable) {
            let obj = this[this.confirm.initValue.cancel[i].variable];
            obj[this.confirm.initValue.cancel[i].key] =
              this.confirm.initValue.cancel[i].value;
          } else
            this[this.confirm.initValue.cancel[i].key] =
              this.confirm.initValue.cancel[i].value;
        }
      }
      if (this.confirm.callback.no.method)
        this[this.confirm.callback.no.method](this.confirm.callback.no.params);
    },

    onConfirmed() {
      this.confirm.show = false;
      this.confirm.message = "";
      if (this.confirm.initValue && this.confirm.initValue.confirmed) {
        for (let i = 0; i < this.confirm.initValue.confirmed.length; i++) {
          if (this.confirm.initValue.confirmed[i].variable) {
            let obj = this[this.confirm.initValue.confirmed[i].variable];
            obj[this.confirm.initValue.confirmed[i].key] =
              this.confirm.initValue.confirmed[i].value;
          } else
            this[this.confirm.initValue.confirmed[i].key] =
              this.confirm.initValue.confirmed[i].value;
        }
      }
      if (this.confirm.callback.yes.method)
        this[this.confirm.callback.yes.method](
          this.confirm.callback.yes.params
        );
    },
    /* ---------- End Emit ----------*/
    /* ---------- Put, Post -----------*/

    onCancelConfirmDelete() {
      this.confirmDelete.show = false;
      this.confirmDelete.message = null;
      this.confirmDelete.value = null;
    },
    async onConfirmedDelete() {
      this.buttons.delete.disabled = true;
      this.buttons.delete.processing = true;
      this.$store.dispatch("setSpinnerApp", {
        show: true,
      });
      try {
        let response = await permissionFunc.delete(
          this.confirmDelete.value,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.$toast.success({
            title: this.$t("title_message_info"),
            message: this.$t("message_delete_permission_success", null, {
              name: this.permission.entity.description
                ? this.permission.entity.description.trim()
                : "",
            }),
            position: "top right",
          });
          this.onCancelConfirmDelete();
          this.getAllPermission();
        } else {
          this.message.push([
            this.$t("message_delete_permission_error", null, {
              name: this.permission.description.name
                ? this.permission.description.name.trim()
                : "",
            }),
          ]);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$store.dispatch("setSpinnerApp", {
            show: false,
          });
          await this.refreshToken(this.onConfirmedDelete);
        } else {
          let msg =
            error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push([
            msg
              ? msg
              : this.$t("message_delete_permission_error", null, {
                  name: this.permission.entity.description
                    ? this.permission.entity.description.trim()
                    : "",
                }),
          ]);
        }
      }
      this.$store.dispatch("setSpinnerApp", {
        show: false,
      });
      this.buttons.delete.processing = false;
    },
    async onSave() {
      this.message = [];
      var vm = this;
      this.$refs.permisisonObserver.validate().then(async (success) => {
        if (!success) {
          setTimeout(() => {
            Object.keys(vm.$refs.permisisonObserver.errors).forEach(function (
              key
            ) {
              if (
                vm.$refs.permisisonObserver.errors[key] &&
                vm.$refs.permisisonObserver.errors[key].length
              )
                vm.message.push(
                  vm.$refs.permisisonObserver.errors[key].join(",\n")
                );
            });
          }, 100);
          return;
        } else {
          this.buttons.save.processing = true;
          this.buttons.save.disabled = true;
          this.buttons.add.disabled = true;
          this.buttons.edit.disabled = true;
          this.buttons.reload.disabled = true;
          this.$store.dispatch("setSpinnerApp", {
            show: true,
          });
          let dto = Object.assign(
            {},
            JSON.parse(JSON.stringify(this.permission.entity))
          );
          dto.name = dto.name ? dto.name.trim().replace(/\s\s+/g, " ") : null;
          dto.description = dto.description
            ? dto.description.trim().replace(/\s\s+/g, " ")
            : null;

          if (dto.translation && Object.keys(dto.translation).length > 0) {
            if (dto.translation.vi) {
              try {
                dto.translation.vi.description = dto.translation.vi.description
                  ? dto.translation.vi.description.trim().replace(/\s\s+/g, " ")
                  : null;
                if (!dto.description) {
                  dto.description = dto.translation.vi.description
                    ? dto.translation.vi.description
                    : "";
                }
              } catch (error) {}
            }
            if (dto.translation.en) {
              try {
                dto.translation.en.description = dto.translation.en.description
                  ? dto.translation.en.description.trim().replace(/\s\s+/g, " ")
                  : null;
              } catch (error) {}
            }
          }
          let response;
          try {
            if (!dto.id) {
              // Add new
              response = await permissionFunc.create(
                dto,
                this.$store.getters.getAccessToken
              );
            } else {
              // Edit
              response = await permissionFunc.update(
                dto,
                this.$store.getters.getAccessToken
              );
            }
            if (
              response &&
              (response.status === 201 ||
                response.status === 200 ||
                response.status === 204)
            ) {
              this.$toast.success({
                title: this.$t("title_message_info"),
                message:
                  (this.permission.isEdit && this.permission.entity.id
                    ? this.$t("label_save_permission")
                    : this.$t("label_add_permission")) +
                  " " +
                  this.permission.entity.description +
                  " " +
                  this.$t("label_success"),
                position: "top right",
              });
              this.getAllPermission();
              // if (this.permission.isEdit && this.permission.entity.id) {
              //   let index = this.permission.items.findIndex(
              //     (x) => x.id === this.permission.entity.id
              //   );
              //   if (index >= 0) {
              //     this.permission.items[index].name = dto.name;
              //     this.permission.items[index].description = dto.description;
              //   }
              // } else {
              //   this.permission.items.push(response.data);
              // }
            } else {
              this.message.push(
                (this.permission.isEdit && this.permission.entity.id
                  ? this.$t("label_save_permission")
                  : this.$t("label_add_permission")) +
                  " " +
                  this.permission.entity.description +
                  " " +
                  this.$t("label_error")
              );
            }
          } catch (error) {
            if (error.response) {
              if (error.response.status === 401) {
                this.$store.dispatch("setSpinnerApp", {
                  show: false,
                });
                await this.refreshToken(this.onSave);
              } else {
                this.buttons.save.processing = false;
                this.buttons.save.disabled = false;
                this.buttons.edit.disabled = !this.permission.isEdit;
                this.message.push(
                  error.response.data && error.response.data.message
                    ? error.response.data.message
                    : (this.permission.isEdit && this.permission.entity.id
                        ? this.$t("label_save_permission")
                        : this.$t("label_add_permission")) +
                        " " +
                        this.permission.entity.description +
                        " " +
                        this.$t("label_error")
                );
              }
            }
          }
          this.buttons.save.processing = false;
          this.buttons.save.disabled = true;
          this.buttons.edit.disabled = true;
          this.buttons.reload.disabled = false;
          this.buttons.add.disabled = false;
          this.$store.dispatch("setSpinnerApp", {
            show: false,
          });
        }
      });
    },
    /* ---------- End Put, Post -----------*/
    /* ---------- Api get data ---------- */

    async onFilter() {
      await this.getAllPermission();
    },
    async getAllPermission() {
      // this.$store.dispatch("setSpinnerApp", {
      //   show: true,
      // });
      this.permission.loading = true;
      this.permission.entity = this.generateEntity();
      this.permission.table.selected = null;
      this.permission.items = [];
      this.permission.first = -1;
      this.permission.page_number = 0;
      this.permission.page_size = 100000;
      this.permission.total_page = 0;
      this.permission.total_records = 0;
      this.permission.isAdd = false;
      this.permission.isEdit = false;
      this.buttons.filter.processing = true;
      this.buttons.filter.disabled = true;
      this.buttons.reload.disabled = true;
      this.buttons.delete.disabled = true;
      this.buttons.delete.processing = false;
      try {
        let response = await permissionFunc.getAll(
          { page: 0, size: 2147483647 },
          this.$store.getters.getAccessToken
        );
        if (response) {
          if (response.status === 200) {
            this.permission.items =
              response.data && response.data.content_page
                ? response.data.content_page
                : [];
            this.permission.page_number =
              response.data && response.data.page_number
                ? response.data.page_number
                : 0;
            this.permission.page_size =
              response.data && response.data.page_size
                ? response.data.page_size
                : 2147483647;
            this.permission.total_page =
              response.data && response.data.total_page
                ? response.data.total_page
                : 0;
            this.permission.total_records =
              response.data && response.data.total_records
                ? response.data.total_records
                : 0;
            if (this.permission.items.length > 0) this.permission.first = 0;
          }
        }
        this.permission.loading = false;
      } catch (error) {
        this.permission.loading = false;
        // this.$store.dispatch("setSpinnerApp", {
        //   show: false,
        // });
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.getAllPermission);
        }
      }
      this.buttons.reload.disabled = false;
      this.buttons.filter.processing = false;
      this.buttons.filter.disabled = false;
      this.$store.dispatch("setSpinnerApp", {
        show: false,
      });
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
};
</script>

<style lang="scss" scoped>
.permission-content {
  padding: 0.5rem;
  padding-top: 0;
  .permission-content__datatable {
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .permission-content__structure {
    position: relative;
    margin-top: 0.5rem;
    .accordion {
      border-radius: 0.5rem;
    }
    .actions {
      position: absolute;
      right: 2.5rem;
      top: 0.375rem;
      button {
        border-radius: 50% !important;
        background: #009ef7;
        color: white;
        margin-right: 0.25rem;
        width: 1.6125rem;
        height: 1.6125rem;
        padding: 0.25rem;
        &:last-child {
          margin-right: 0;
        }
        &:disabled {
          background: #f4f4f4;
          color: #009ef7;
        }
        .pi {
          font-size: 0.75rem !important;
        }
      }
    }
  }
}
</style>