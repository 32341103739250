<template>
  <Dialog
    :header="$t('header_detail')"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :modal="true"
    :contentStyle="propStyle"
    @hide="onClose"
  >
    <div class="tasking-confirmation">
      <div class="row no-gutters">
        <div class="col-12 col-md-6">
          <div class="row no-gutters">
            <label class="col-12 col-md-4 col-form-label">{{
              $t("label_order_code")
            }}</label>
            <div class="col-12 col-md-8">
              <InputText
                class="form-control new-style w-100"
                maxlength="50"
                :value="propItem.order_code"
                readonly
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="row no-gutters">
            <label class="col-12 col-md-4 col-form-label">{{
              $t("label_order_time")
            }}</label>
            <div class="col-12 col-md-8">
              <InputText
                class="form-control new-style w-100"
                maxlength="50"
                :value="
                  formatUnixDateTime2String(
                    new Date(propItem.created_at).getTime()
                  )
                "
                readonly
              />
            </div>
          </div>
        </div>
      </div>
      <div class="confirmation__buyer-info">
        <CommonAccordion
          :isChevronEnd="true"
          :propTitle="$t('label_buyer_info')"
          ><div slot="content" class="accordion w-100">
            <div class="row no-gutters">
              <div class="col-12 col-md-6">
                <div class="row no-gutters">
                  <label class="col-12 col-md-4 col-form-label">{{
                    $t("label_buyer_name")
                  }}</label>
                  <div class="col-12 col-md-8">
                    <InputText
                      id="inutBuyerName"
                      autofocus
                      class="form-control new-style w-100"
                      maxlength="50"
                      :value="propItem.information_buyer.full_name"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="row no-gutters">
                  <label class="col-12 col-md-4 col-form-label">{{
                    $t("label_organisation_name")
                  }}</label>
                  <div class="col-12 col-md-8">
                    <InputText
                      class="form-control new-style w-100"
                      maxlength="255"
                      :value="propItem.information_buyer.organization"
                      readonly
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row no-gutters">
              <div class="col-12 col-md-6">
                <div class="row no-gutters">
                  <label class="col-12 col-md-4 col-form-label">{{
                    $t("label_country")
                  }}</label>
                  <div class="col-12 col-md-8">
                    <InputText
                      class="form-control new-style w-100"
                      maxlength="50"
                      :value="
                        propItem.information_buyer.country
                          ? propItem.information_buyer.country
                          : ''
                      "
                      readonly
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="row no-gutters">
                  <label class="col-12 col-md-4 col-form-label">{{
                    $t("label_province")
                  }}</label>
                  <div class="col-12 col-md-8">
                    <InputText
                      class="form-control new-style w-100"
                      maxlength="50"
                      :value="
                        propItem.information_buyer.state
                          ? propItem.information_buyer.state
                          : ''
                      "
                      readonly
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-12 col-md-6">
                <div class="row no-gutters">
                  <label class="col-12 col-md-4 col-form-label">{{
                    $t("label_address")
                  }}</label>
                  <div class="col-12 col-md-8">
                    <InputText
                      class="form-control new-style w-100"
                      maxlength="255"
                      :value="propItem.information_buyer.address"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="row no-gutters">
                  <label class="col-12 col-md-4 col-form-label">{{
                    $t("label_zip_code")
                  }}</label>
                  <div class="col-12 col-md-8">
                    <InputText
                      class="form-control new-style w-100"
                      maxlength="20"
                      :value="propItem.information_buyer.zipcode"
                      readonly
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-12 col-md-6">
                <div class="row no-gutters">
                  <label class="col-12 col-md-4 col-form-label">{{
                    $t("label_phone_number")
                  }}</label>
                  <div class="col-12 col-md-8">
                    <InputText
                      class="form-control new-style w-100"
                      maxlength="20"
                      v-model="propItem.information_buyer.phone_number"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="row no-gutters">
                  <label class="col-12 col-md-4 col-form-label">{{
                    $t("label_email")
                  }}</label>
                  <div class="col-12 col-md-8">
                    <InputText
                      class="form-control new-style w-100"
                      maxlength="150"
                      v-model="propItem.information_buyer.email"
                      readonly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CommonAccordion>
      </div>
      <div class="confirmation__area-of-interest">
        <CommonAccordion
          :isChevronEnd="true"
          :propTitle="$t('label_step_area_of_interest')"
          ><div slot="content" class="accordion w-100">
            <div class="row no-gutters">
              <div class="col-12 col-md-6">
                <div class="row no-gutters">
                  <label class="col-12 col-md-4 col-form-label">{{
                    $t("label_area_name")
                  }}</label>
                  <div class="col-12 col-md-8">
                    <InputText
                      class="form-control new-style w-100"
                      maxlength="255"
                      :value="propItem.location_name"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="row no-gutters">
                  <label class="col-12 col-md-4 col-form-label">{{
                    $t("label_number_of_scenes")
                  }}</label>
                  <div class="col-12 col-md-8">
                    <InputText
                      class="form-control new-style w-100"
                      maxlength="10"
                      :value="propItem.order_request_info.scene_number"
                      readonly
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-12 col-md-6">
                <div class="row no-gutters">
                  <label class="col-12 col-md-4 col-form-label">{{
                    $t("label_coordinate_system")
                  }}</label>
                  <div class="col-12 col-md-8">
                    <InputText
                      class="form-control new-style w-100"
                      maxlength="50"
                      :value="
                        propItem.order_request_info.coordinate_system
                          ? propItem.order_request_info.coordinate_system.value
                          : 'WGS84'
                      "
                      readonly
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="row no-gutters">
                  <label class="col-12 col-md-4 col-form-label">{{
                    $t("label_satellite")
                  }}</label>
                  <div class="col-12 col-md-8">
                    <InputText
                      class="form-control new-style w-100"
                      maxlength="50"
                      :value="
                        propItem.order_request_info.satellite
                          ? propItem.order_request_info.satellite.value
                          : ''
                      "
                      readonly
                    />
                  </div>
                </div>
              </div>
            </div>
            <fieldset
              class="p-0"
              v-if="
                this.propItem.order_request_info.centre_point &&
                  this.propItem.order_request_info.centre_point.length > 0
              "
            >
              <legend class="w-auto">
                {{ $t("label_center_coordinates") }}
              </legend>
              <div class="row no-gutters">
                <div class="col-12 col-md-4">
                  <div class="row no-gutters">
                    <label class="col-12 col-md-4 col-form-label">{{
                      $t("label_latitude")
                    }}</label>
                    <div class="col-12 col-md-8">
                      <InputText
                        class="form-control new-style w-100"
                        :value="
                          this.propItem.order_request_info.centre_point[1]
                        "
                        readonly
                        maxlength="9"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row no-gutters">
                    <label class="col-12 col-md-4 col-form-label">{{
                      $t("label_longitude")
                    }}</label>
                    <div class="col-12 col-md-8">
                      <InputText
                        class="form-control new-style w-100"
                        :value="
                          this.propItem.order_request_info.centre_point[0]
                        "
                        readonly
                        maxlength="10"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row no-gutters">
                    <label class="col-12 col-md-4 col-form-label">{{
                      $t("label_radius")
                    }}</label>
                    <div class="col-12 col-md-8">
                      <InputText
                        class="form-control new-style w-100"
                        :value="this.propItem.order_request_info.radius"
                        readonly
                        maxlength="4"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset
              class="p-0"
              v-else-if="
                this.propItem.order_request_info.bounding_box &&
                  Object.keys(this.propItem.order_request_info.bounding_box)
                    .length > 0
              "
            >
              <legend class="w-auto">
                {{ $t("label_corner_coordinates") }}
              </legend>
              <div class="row no-gutters w-100">
                <div class="col-12 col-md-6 corner-group">
                  <div class="row corner-group">
                    <label class="col-2 text-position pl-0 pr-0">{{
                      $t("label_upper_left")
                    }}</label>
                    <div class="col-5">
                      <label for="inputRadiuslat" class="col-form-label">{{
                        $t("label_latitude")
                      }}</label>
                      <InputText
                        type="text"
                        class="form-control new-style"
                        readonly
                        maxlength="9"
                        :value="upperLeft.lat"
                      />
                    </div>
                    <div class="col-5">
                      <label for="inputRadiusLng" class="col-form-label">{{
                        $t("label_longitude")
                      }}</label>
                      <InputText
                        type="text"
                        class="form-control new-style"
                        readonly
                        maxlength="10"
                        :value="upperLeft.lng"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 corner-group">
                  <div class="row corner-group">
                    <label class="col-2 text-position pl-0 pr-0">{{
                      $t("label_upper_right")
                    }}</label>
                    <div class="col-5">
                      <label for="inputRadiuslat" class="col-form-label">{{
                        $t("label_latitude")
                      }}</label>
                      <InputText
                        type="text"
                        class="form-control new-style"
                        readonly
                        maxlength="9"
                        :value="upperRight.lat"
                      />
                    </div>
                    <div class="col-5">
                      <label for="inputRadiusLng" class="col-form-label">{{
                        $t("label_longitude")
                      }}</label>
                      <InputText
                        type="text"
                        class="form-control new-style"
                        readonly
                        maxlength="10"
                        :value="upperRight.lng"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row no-gutters w-100">
                <div class="col-12 col-md-6 corner-group">
                  <div class="row corner-group">
                    <label class="col-2 text-position pl-0 pr-0">{{
                      $t("label_lower_left")
                    }}</label>
                    <div class="col-5">
                      <label for="inputRadiuslat" class="col-form-label">{{
                        $t("label_latitude")
                      }}</label>
                      <InputText
                        type="text"
                        class="form-control new-style"
                        readonly
                        maxlength="9"
                        :value="lowerLeft.lat"
                      />
                    </div>
                    <div class="col-5">
                      <label for="inputRadiusLng" class="col-form-label">{{
                        $t("label_longitude")
                      }}</label>
                      <InputText
                        type="text"
                        class="form-control new-style"
                        readonly
                        maxlength="10"
                        :value="lowerLeft.lng"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 corner-group">
                  <div class="row corner-group">
                    <label class="col-2 text-position pl-0 pr-0">{{
                      $t("label_lower_right")
                    }}</label>
                    <div class="col-5">
                      <label for="inputRadiuslat" class="col-form-label">{{
                        $t("label_latitude")
                      }}</label>
                      <InputText
                        type="text"
                        class="form-control new-style"
                        readonly
                        maxlength="9"
                        :value="lowerRight.lat"
                      />
                    </div>
                    <div class="col-5">
                      <label for="inputRadiusLng" class="col-form-label">{{
                        $t("label_longitude")
                      }}</label>
                      <InputText
                        type="text"
                        class="form-control new-style"
                        readonly
                        maxlength="10"
                        :value="lowerRight.lng"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </CommonAccordion>
      </div>
      <div class="confirmation__tasking">
        <CommonAccordion
          :isChevronEnd="true"
          :propTitle="$t('label_step_tasking')"
          ><div slot="content" class="accordion w-100">
            <ConfirmTaskingTimeOptical
              :propDto="propItem"
              v-if="
                satelliteType &&
                  satelliteType.trim().toLowerCase() === 'optical'
              "
            />
            <ConfirmTaskingTimeRadar
              :propDto="propItem"
              :propLookDirection="propLookDirection"
              :propOrbitDirection="propOrbitDirection"
              v-else-if="
                satelliteType && satelliteType.trim().toLowerCase() === 'radar'
              "
            />
          </div>
        </CommonAccordion>
      </div>

      <div
        class="confirmation__sensor-mode"
        v-if="satelliteType && satelliteType.trim().toLowerCase() === 'radar'"
      >
        <CommonAccordion
          :isChevronEnd="true"
          :propTitle="$t('label_step_sensor_mode_and_polatization')"
          ><div slot="content" class="accordion w-100">
            <div class="row no-gutters">
              <div class="col-12 col-md-6">
                <div class="row no-gutters">
                  <label class="col-12 col-md-4 col-form-label">{{
                    $t("label_image_mode")
                  }}</label>
                  <div class="col-12 col-md-8">
                    <InputText
                      class="form-control new-style w-100"
                      maxlength="255"
                      :value="
                        propItem.order_request_info.sensor_mode
                          ? propItem.order_request_info.sensor_mode
                              .translation &&
                            propItem.order_request_info.sensor_mode.translation[
                              lang
                            ] &&
                            propItem.order_request_info.sensor_mode.translation[
                              lang
                            ].value
                            ? propItem.order_request_info.sensor_mode
                                .translation[lang].value
                            : propItem.order_request_info.sensor_mode.value
                          : ''
                      "
                      readonly
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="row no-gutters">
                  <label class="col-12 col-md-4 col-form-label">{{
                    $t("label_pol_layer")
                  }}</label>
                  <div class="col-12 col-md-8">
                    <InputText
                      class="form-control new-style w-100"
                      maxlength="255"
                      :value="
                        propItem.order_request_info.polarization
                          ? propItem.order_request_info.polarization
                              .translation &&
                            propItem.order_request_info.polarization
                              .translation[lang] &&
                            propItem.order_request_info.polarization
                              .translation[lang].value
                            ? propItem.order_request_info.polarization
                                .translation[lang].value
                            : propItem.order_request_info.polarization.value
                          : ''
                      "
                      readonly
                    />
                  </div>
                </div>
              </div>
            </div>
            <fieldset class="p-0">
              <legend class="w-auto">{{ $t("label_interferometry") }}</legend>
              <div class="row no-gutters">
                <div class="col-12 col-md-6">
                  <div class="row no-gutters">
                    <label class="col-12 col-md-4 col-form-label">{{
                      $t("label_number_of_iterations")
                    }}</label>
                    <div class="col-12 col-md-8">
                      <InputText
                        class="form-control new-style w-100"
                        maxlength="255"
                        :value="propItem.order_request_info.iterations"
                        readonly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col-2">
                  <label>{{ $t("label_granularity") }}</label>
                </div>
                <div class="col-10">
                  <div class="row no-gutters">
                    <div
                      class="form-group m-0 pr-3"
                      v-for="(item, index) in propGranularities"
                      :key="'granularity' + index"
                    >
                      <Checkbox
                        v-model="granularity"
                        :value="item.id"
                        :id="item.id"
                        :readonly="true"
                      />
                      <label class="p-checkbox-label">{{ item.value }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </CommonAccordion>
      </div>

      <div class="confirmation__programing-service">
        <CommonAccordion
          :isChevronEnd="true"
          :propTitle="$t('label_step_programming_service')"
          ><div slot="content" class="accordion w-100">
            <div class="row no-gutters">
              <div
                class="form-group m-0 pr-5"
                v-for="(item, index) in propProgramingServices"
                :key="'programing-service' + index"
              >
                <Checkbox
                  v-model="programmingService"
                  :value="item.id"
                  :id="item.id"
                  :readonly="true"
                />
                <label class="p-checkbox-label">{{ item.value }}</label>
              </div>
            </div>
          </div>
        </CommonAccordion>
      </div>
      <div class="confirmation__confirmation__production-parameters">
        <CommonAccordion
          :isChevronEnd="true"
          :propTitle="$t('label_step_production_parameters')"
          ><div slot="content" class="accordion w-100">
            <ConfirmProductionParametersOptical
              :propDto="propItem"
              v-if="
                satelliteType &&
                  satelliteType.trim().toLowerCase() === 'optical'
              "
            />
            <ConfirmProductionParametersRadar
              :propDto="propItem"
              v-else-if="
                satelliteType && satelliteType.trim().toLowerCase() === 'radar'
              "
            />
          </div>
        </CommonAccordion>
      </div>
    </div>
    <template #footer>
      <Button
        :label="$t('button_text_close')"
        :icon="'fas fa-times'"
        :class="'p-button-danger mr-0'"
        @click="onClose"
      />
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
import Button from "@/components/commons/button/Button";
import InputText from "@/components/commons/inputtext/InputText";
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import Checkbox from "@/components/commons/checkbox/Checkbox";
import ConfirmProductionParametersOptical from "./dialogorderdetailegeo/ProductionParametersOptical";
import ConfirmProductionParametersRadar from "./dialogorderdetailegeo/ProductionParametersRadar";
import ConfirmTaskingTimeRadar from "./dialogorderdetailegeo/TaskingTimeRadar.vue";
import ConfirmTaskingTimeOptical from "./dialogorderdetailegeo/TaskingTimeOptical.vue";
import moment from "moment";
export default {
  components: {
    InputText,
    CommonAccordion,
    Checkbox,
    ConfirmProductionParametersOptical,
    ConfirmProductionParametersRadar,
    ConfirmTaskingTimeRadar,
    ConfirmTaskingTimeOptical,
    Dialog,
    Button,
  },
  props: {
    propItem: {
      type: Object,
      default: () => {},
    },
    propTitle: {
      type: String,
      default: "",
    },
    propStyle: {
      type: Object,
      default: () => {
        return {
          width: "480px",
        };
      },
    },
    propShow: {
      type: Boolean,
      default: false,
    },
    propGranularities: { type: Array, default: () => [] },
    propProgramingServices: {
      type: Array,
      default: () => [],
    },
    propLookDirection: {
      type: Array,
      default: () => [],
    },
    propOrbitDirection: {
      type: Array,
      default: () => [],
    },
    propSatelliteType: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      granularity: [],
      programmingService: [],
      upperLeft: {
        lat: null,
        lng: null,
      },
      upperRight: {
        lat: null,
        lng: null,
      },
      lowerLeft: {
        lat: null,
        lng: null,
      },
      lowerRight: {
        lat: null,
        lng: null,
      },
    };
  },
  created() {
    this.show = this.propShow;

    if (!this.propSatelliteType || this.propSatelliteType.length === 0) {
      this.$emit("getAllSatelliteType");
    }
    if (!this.propGranularities || this.propGranularities.length === 0) {
      this.$emit("getAllTaskingGranularity");
    }
    if (
      !this.propProgramingServices ||
      this.propProgramingServices.length === 0
    ) {
      this.$emit("getAllTaskingProgrammingService");
    }
    if (!this.propLookDirection || this.propLookDirection.length === 0) {
      this.$emit("getAllDirection");
    }
    if (
      this.propItem.order_request_info &&
      this.propItem.order_request_info.bounding_box
    ) {
      if (
        this.propItem.order_request_info.bounding_box.points &&
        this.propItem.order_request_info.bounding_box.points.length > 0
      ) {
        if (
          this.propItem.order_request_info.bounding_box.points[0] &&
          Object.keys(this.propItem.order_request_info.bounding_box.points[0])
            .length > 0
        ) {
          this.upperLeft.lat = this.propItem.order_request_info.bounding_box
            .points[0].y
            ? this.propItem.order_request_info.bounding_box.points[0].y
            : null;
          this.upperLeft.lng = this.propItem.order_request_info.bounding_box
            .points[0].x
            ? this.propItem.order_request_info.bounding_box.points[0].x
            : null;
        }
        if (
          this.propItem.order_request_info.bounding_box.points[1] &&
          Object.keys(this.propItem.order_request_info.bounding_box.points[1])
            .length > 0
        ) {
          this.upperRight.lat = this.propItem.order_request_info.bounding_box
            .points[1].y
            ? this.propItem.order_request_info.bounding_box.points[1].y
            : null;
          this.upperRight.lng = this.propItem.order_request_info.bounding_box
            .points[1].x
            ? this.propItem.order_request_info.bounding_box.points[1].x
            : null;
        }

        if (
          this.propItem.order_request_info.bounding_box.points[3] &&
          Object.keys(this.propItem.order_request_info.bounding_box.points[3])
            .length > 0
        ) {
          this.lowerLeft.lat = this.propItem.order_request_info.bounding_box
            .points[3].y
            ? this.propItem.order_request_info.bounding_box.points[3].y
            : null;
          this.lowerLeft.lng = this.propItem.order_request_info.bounding_box
            .points[3].x
            ? this.propItem.order_request_info.bounding_box.points[3].x
            : null;
        }
        if (
          this.propItem.order_request_info.bounding_box.points[2] &&
          Object.keys(this.propItem.order_request_info.bounding_box.points[2])
            .length > 0
        ) {
          this.lowerRight.lat = this.propItem.order_request_info.bounding_box
            .points[2].y
            ? this.propItem.order_request_info.bounding_box.points[2].y
            : null;
          this.lowerRight.lng = this.propItem.order_request_info.bounding_box
            .points[2].x
            ? this.propItem.order_request_info.bounding_box.points[2].x
            : null;
        }
      }
    }
    this.granularity = this.propItem.order_request_info.granularity
      ? [this.propItem.order_request_info.granularity.id]
      : [];
    this.programmingService = this.propItem.priority
      ? [this.propItem.priority.id]
      : [];
  },
  mounted() {},

  watch: {
    propShow: {
      handler(val) {
        this.show = val;
      },
      immediate: true,
    },
  },
  methods: {
    onClose() {
      this.show = false;
      this.$emit("close");
    },
    formatUnixDateTime2String(value) {
      let result = "";
      if (value) {
        try {
          let date = new Date(value);
          result = moment
            .unix(date.getTime() / 1000)
            .format("HH:mm DD/MM/YYYY");
          result = result
            .replace(":", this.lang === "vi" ? "h" : ":")
            .replace(" ", this.lang === "vi" ? " ngày " : " ");
        } catch (error) {}
      }
      return result;
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    satelliteType() {
      let result = "";
      if (
        this.propSatelliteType &&
        this.propSatelliteType.length > 0 &&
        this.propItem.order_request_info &&
        this.propItem.order_request_info.satellite
      ) {
        for (let i = 0; i < this.propSatelliteType.length; i++) {
          if (this.propSatelliteType[i].sub_meta_key) {
            if (
              this.propSatelliteType[i].sub_meta_key
                .map((x) => x.id)
                .includes(this.propItem.order_request_info.satellite.id)
            ) {
              result = this.propSatelliteType[i].name;
            }
          }
        }
      }
      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.tasking-confirmation {
  padding: 0 0.5rem;
  width: 100%;
  color: $text-color;
  .row {
    margin-bottom: 1rem;
    .col-md-6 {
      padding-right: 0.25rem;
      &:last-child {
        padding-right: 0;
        padding-left: 0.25rem;
      }
    }
    .corner-group {
      label {
        &.text-position {
          display: flex;
          justify-content: center;
          align-items: flex-end;
        }
      }
      .col-5 {
        padding-right: 0.25rem;
        &:last-child {
          padding-left: 0.25rem;
          padding-right: 0;
        }
      }
    }
    .row {
      margin: 0;
    }
  }
  legend {
    font-size: 14px;
  }
  .row {
    .col-md-4 {
      padding-right: 0.25rem;
      padding-left: 0.25rem;
      &:last-child {
        padding-right: 0;
      }
      &:first-child {
        padding-left: 0;
      }
    }
  }
}
</style>
<style lang="scss">
.tasking-confirmation {
  .mx-input-wrapper {
    input {
      font-size: 14px;
      background: #f5f8fa;
      border-radius: 4px;
      border-color: #f5f8fa;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      &:hover {
        border-color: #212121 !important;
      }
      &:disabled,
      .disabled {
        background-color: transparent;
      }
    }
  }
  .accordion {
    width: 100%;
    .accordion-header a {
      padding-left: 0 !important;
    }
  }
  fieldset {
    border-color: transparent;
  }
}
</style>
