<template>
  <Dialog
    :header="propTitle"
    :visible.sync="show"
    :style="{ zIndex: 1060 }"
    :maximizable="propMaximizable"
    :modal="true"
    :contentStyle="propStyle"
    @hide="handleClose"
  >
    <p v-html="formatText()"></p>
    <template #footer>
      <Button
        label="Ok"
        class="p-button p-button-sm"
        :class="'p-button-' + propType"
        @click="handleClose"
      />
    </template>
  </Dialog>
</template>
<script>
import Button from "../button/Button";
import Dialog from "./Dialog";
export default {
  components: {
    Button,
    Dialog,
  },
  props: {
    propTitle: {
      type: String,
      default: "Message box",
    },
    propShow: {
      type: Boolean,
      default: false,
    },
    propMessage: {
      type: Array,
      default: () => [],
    },
    propStyle: {
      type: Object,
      default: () => {
        return {
          width: "540px",
        };
      },
    },
    propType: {
      type: String,
      default: "warning",
    },
    propMaximizable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  created() {
    this.show = this.propShow;
  },
  watch: {
    propShow: {
      handler(val) {
        this.show = val;
      },
      immediate: true,
    },
  },
  methods: {
    handleClose() {
      this.show = false;
      this.$emit("close");
    },

    formatText() {
      return this.propMessage.join("<br>");
    },
  },
};
</script>
<style lang="scss" scoped>
.p-dialog .p-dialog-footer button {
  margin: 0 !important;
}
p {
  margin: 0;
}
</style>
