var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-layout',{attrs:{"isToolbar":false,"isBreadcrumb":true,"breadcrumbitems":[
    { label: _vm.$t('menu_share_management') },
    { label: _vm.$t('menu_my_shared') } ],"propButtonsState":{
    save: {
      visible: false,
    },
    add: {
      visible: false,
    },
    edit: {
      visible: false,
    },
    reload: _vm.buttons.reload,
    print: {
      visible: false,
    },
  },"propTitle":_vm.$t('menu_my_shared')},on:{"emitFunction":_vm.menuHeaderClick}},[_c('div',{staticClass:"shared-by-me"},[_c('div',{staticClass:"shared-by-me__dialog"},[(_vm.dialog.metadata.show)?_c('DialogMetadata',{attrs:{"prop-style":{ width: _vm.dialog.metadata.width },"prop-show":_vm.dialog.metadata.show,"prop-title":_vm.dialog.metadata.title,"prop-item":_vm.dialog.metadata.value},on:{"close":function($event){_vm.dialog.metadata.show = false;
          _vm.dialog.metadata.value = null;},"initSuccessPreviewImange":_vm.initSuccessPreviewImange}}):_vm._e(),(_vm.dialog.share.show)?_c('DialogShare',{attrs:{"prop-style":{ width: _vm.dialog.share.width },"prop-show":_vm.dialog.share.show,"prop-title":_vm.dialog.share.title,"prop-item":_vm.dialog.share.value.image,"propUsers":_vm.user.items.filter(
            function (x) { return x.username != _vm.username &&
              !_vm.dialog.share.value.member.map(function (x) { return x.id; }).includes(x.id); }
          ),"propButtonShare":_vm.buttons.share},on:{"close":function($event){_vm.dialog.share.show = false;
          _vm.dialog.share.value = null;},"onShare":_vm.onShare}}):_vm._e(),(_vm.dialog.cancelSharing.show)?_c('DialogCancelShare',{attrs:{"prop-style":{ width: _vm.dialog.cancelSharing.width },"prop-show":_vm.dialog.cancelSharing.show,"propItem":_vm.dialog.cancelSharing.value.image,"prop-member":_vm.dialog.cancelSharing.value.member},on:{"close":function($event){_vm.dialog.cancelSharing.show = false;
          _vm.dialog.cancelSharing.value = null;},"onRemoveShare":_vm.onRemoveShare}}):_vm._e(),(_vm.message.length > 0)?_c('DialogMessage',{attrs:{"propTitle":_vm.$t('title_message_info'),"prop-show":_vm.message.length > 0,"prop-message":_vm.message},on:{"close":function($event){_vm.message = []}}}):_vm._e()],1),_c('div',{staticClass:"shared-by-me__search",attrs:{"id":"elementSearch"}},[_c('SharedFilter',{attrs:{"propSearch":_vm.sharedByMe.search},on:{"onSearch":_vm.onSearch,"message":function($event){_vm.message = $event},"accordionHeaderClick":_vm.accordionHeaderClick}})],1),_c('div',{staticClass:"shared-by-me__datatable"},[_c('SharedDatatable',{attrs:{"prop-items":_vm.sharedByMe.items,"prop-table":_vm.sharedByMe.table,"prop-total":_vm.sharedByMe.total_records,"prop-loading":_vm.sharedByMe.loading},on:{"showDialogMetadata":_vm.showDialogMetadata,"showDialogShare":_vm.showDialogShare,"showDialogCancelShare":_vm.showDialogCancelShare,"downloadImage":_vm.downloadImage,"nextPage":_vm.nextPage}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }