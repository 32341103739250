<template>
  <div class="order-detail" v-if="propItem && propItem.images">
    <OrderInfo :prop-item="propItem" />

    <BuyerInfo :prop-item="propItem" />
    <AoiInfo :prop-item="propItem" :prop-images="propItem.images" />
    <div class="order-images">
      <CommonAccordion
        :isChevronEnd="true"
        :propTitle="$t('label_order_images')"
        ><div slot="content" class="accordion w-100">
          <div class="datatable" v-for="item in imagesOne" :key="item.id">
            <DetailMetadata :propItem="item.image" />
          </div>
        </div>
      </CommonAccordion>
    </div>
    <div class="delivery-images">
      <CommonAccordion
        :isChevronEnd="true"
        :propTitle="$t('label_delivery_images')"
        ><div slot="content" class="accordion w-100">
          <div class="datatable" v-for="item in imagesTwo" :key="item.id">
            <DetailMetadata :propItem="item.image" />
          </div>
        </div>
      </CommonAccordion>
    </div>
  </div>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import DetailMetadata from "./detail4images/Metadata.vue";
import InputText from "@/components/commons/inputtext/InputText";
import AoiInfo from "./detail4images/AoiInfo";
import BuyerInfo from "./detail4images/BuyerInfo";
import OrderInfo from "./detail4images/OrderInfo";
import moment from "moment";
export default {
  components: {
    CommonAccordion,
    DetailMetadata,
    InputText,
    OrderInfo,
    BuyerInfo,
    AoiInfo,
  },
  props: {
    propItem: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    formatUnixDateTime2String(value) {
      let result = "";
      if (value) {
        try {
          let date = new Date(value);
          result = moment
            .unix(date.getTime() / 1000)
            .format("HH:mm DD/MM/YYYY");
          result = result
            .replace(":", this.lang === "vi" ? "h" : ":")
            .replace(" ", this.lang === "vi" ? " ngày " : " ");
        } catch (error) {}
      }
      return result;
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },

    imagesOne() {
      // images of buyer
      let results = [];
      if (
        this.propItem &&
        this.propItem.images &&
        this.propItem.images.length > 0
      ) {
        for (let i = 0; i < this.propItem.images.length; i++) {
          if (
            this.propItem.images[i].image &&
            this.propItem.images[i].user_add === this.propItem.created_by
          ) {
            if (
              results.findIndex(
                (x) => x.image.id === this.propItem.images[i].image.id
              ) < 0
            )
              results.push(this.propItem.images[i]);
          }
        }
      }
      return results;
    },
    imagesTwo() {
      // images of manager
      let results = [];
      if (
        this.propItem &&
        this.propItem.images &&
        this.propItem.images.length > 0
      ) {
        for (let i = 0; i < this.propItem.images.length; i++) {
          if (
            this.propItem.images[i].image &&
            this.propItem.images[i].user_add != this.propItem.created_by
          ) {
            if (
              results.findIndex(
                (x) => x.image.id === this.propItem.images[i].image.id
              ) < 0
            )
              results.push(this.propItem.images[i]);
          }
        }
      }
      return results;
    },
  },
};
</script>
<style lang="scss" scoped>
.accordion {
  width: 100%;
  .accordion-header a {
    padding-left: 0 !important;
  }
}
.order-detail {
  .detail-metadata {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
