<template>
  <div class="preview-icon mr-2 border">
    <div
      class="spinner-border spinner-border-sm align-items-center mt-4"
      role="status"
      v-if="!iconSrc"
    >
      <span class="sr-only">{{ $t("label_loading") }}</span>
    </div>
    <img :src="iconSrc" class="preview-image" v-else />
  </div>
</template>
<script>
import { getFileExtension3 } from "@/utils/commons/common";
export default {
  props: {
    propFileName: {
      type: String,
      default: "",
    },
  },
  data() {
    return { iconSrc: null };
  },
  created() {
    if (this.propFileName) {
      switch (getFileExtension3(this.propFileName).toLowerCase()) {
        case "zip":
          this.iconSrc = "/img/icons/zip32x32.png";
          break;
        default:
          this.iconSrc = "/img/icons/document32x32.png";
          break;
      }
    } else {
      this.iconSrc = "/img/icons/document32x32.png";
    }
  },
  mounted() {},
  computed: {},

  watch: {
    propFileName(val) {
      if (val) {
        switch (getFileExtension3(val).toLowerCase()) {
          case "zip":
            this.iconSrc = "/img/icons/zip32x32.png";
            break;
          default:
            this.iconSrc = "/img/icons/document32x32.png";
            break;
        }
      } else {
        this.iconSrc = "/img/icons/document32x32.png";
      }
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.preview-icon {
  text-align: center;
  width: 33px;
  height: 33px;
  background-color: white;
  border-radius: 0.25rem;
  img {
    object-fit: cover;
    // padding-top: 1px;
    width: 2rem;
    height: 2rem;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>