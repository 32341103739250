var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-layout',{attrs:{"isToolbar":false,"propButtonsState":{
    delete: _vm.buttons.delete,
    save: _vm.buttons.save,
    add: _vm.buttons.add,
    edit: _vm.buttons.edit,
    reload: _vm.buttons.reload,
    print: {
      visible: false,
    },
  },"breadcrumbitems":[
    { label: _vm.$t('menu_project_and_customer') },
    { label: _vm.$t('menu_customer') } ],"propTitle":_vm.$t('menu_customer')},on:{"emitFunction":function($event){return _vm.menuBarFunction($event)}}},[_c('div',{staticClass:"customer-content"},[_c('div',{staticClass:"customer-content__dialog"},[(_vm.message.length > 0)?_c('DialogMessage',{attrs:{"propTitle":_vm.$t('title_message_info'),"prop-show":_vm.message.length > 0,"prop-message":_vm.message},on:{"close":function($event){_vm.message = []}}}):_vm._e()],1),_c('div',{staticClass:"customer-content__search",attrs:{"id":"elementSearch"}},[_c('CustomerFilter',{attrs:{"prop-search":_vm.customer.search,"propButtons":_vm.buttons},on:{"accordionHeaderClick":_vm.accordionHeaderClick,"onSearch":_vm.onSearch}})],1),_c('div',{staticClass:"customer-content__datatable"},[_c('DataTable',{ref:"customerDatatable",attrs:{"prop-items":_vm.customer.items,"propTotal":_vm.customer.total_records,"prop-table":_vm.customer.table},on:{"nextPage":_vm.nextPage}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }