import axios from 'axios';
import basicApi from "@/utils/apis/basic";
import app from './../../main'
import { geoServiceApiURL } from "@/utils/commons/environment"
export default {
  async search(params, dtoSearch, access_token) {
    let headers = basicApi.image.search.headers;
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    if(params && Object.keys(params).length > 0){
      if(params.size === null || params.size === undefined)
      params.size = 2147483647
    } else {
      params= {}
    }
    params.lang = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.image.search.method,
      url: geoServiceApiURL + basicApi.image.search.url,
      headers:headers,
      params:params,
      data:dtoSearch
    });
    let response = await object;
    return response;
  },
  async create(entity,access_token){
    let headers = basicApi.image.create.headers;
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.image.create.method,
      url:  geoServiceApiURL + basicApi.image.create.url,
      data: entity,
      headers:headers,
      params: {
        lang:app.$store.getters.getLang
      }
    });
    let response = await object;
    return response;
  },
  async update(entity, access_token) {
    let headers = basicApi.image.update.headers;
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.image.update.method,
      url: geoServiceApiURL + basicApi.image.update.url.replace("{id}",entity.id),
      data: entity,
      headers:headers,
      params: {
        lang:app.$store.getters.getLang
      }
    });
    let response = await object;
    return response;
  },
  
  async delete(id, access_token) {
    let headers = basicApi.image.delete.headers;
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.image.delete.method,
      url: geoServiceApiURL + basicApi.image.delete.url.replace("{id}",id),
      headers:headers,
      params: {
        lang:app.$store.getters.getLang
      }
    });
    let response = await object;
    return response;
  },
  
    async downloadImage(id, access_token) {
      let headers = basicApi.image.downoad.headers;
      headers["Authorization"] = "bearer " + access_token;
      headers['Accept-Language'] = app.$store.getters.getLang;
      const object = axios({
        method: basicApi.image.downoad.method,
        url: geoServiceApiURL + basicApi.image.downoad.url.replace("{id}",id),
        headers:headers,
        responseType:'blob',
        params: {
          lang:app.$store.getters.getLang
        }
      });
      let response = await object;
      return response;
    },
    async getById(id, access_token) {
      let headers = basicApi.image.get_by_id.headers;
      headers["Authorization"] = "bearer " + access_token;
      headers['Accept-Language'] = app.$store.getters.getLang;
      const object = axios({
        method: basicApi.image.get_by_id.method,
        url: geoServiceApiURL + basicApi.image.get_by_id.url.replace("{id}",id),
        headers:headers,
        params: {
          lang:app.$store.getters.getLang
        }
      });
      let response = await object;
      return response;
  },
  async downloadFolderAsZip(url, data) {
      const object = axios({
      method:'post',
      url:url,
      data:data,
      responseType: "blob",
      params: {
        lang:app.$store.getters.getLang
      }
    });
    let response = await object;
    return response;
  },
  async searchImagesHub(params, dtoSearch, username, password, apiUrl) {
    let headers = {};
    headers["Authorization"] = "basic " + btoa(username+":"+password);
    headers["Content-Type"]=  "application/json";
    headers["Accept"] = "application/json";
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: "get",
      url: apiUrl ? apiUrl : process.env.VUE_APP_API_SEARCH_SENTINEL_HUB,
      headers:headers,
      params: params,
      data:dtoSearch
    });
    let response = await object;
    return response;
  },
  
  async uploadImage(contentType, path, access_token, file_name) {
    let headers = basicApi.image.upload_image.headers;
    headers["Authorization"] = "bearer " + access_token;      
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.image.upload_image.method,
      url: geoServiceApiURL + basicApi.image.upload_image.url,
      headers: headers,
      params: {
        lang:app.$store.getters.getLang,
        "content-type":contentType,
        path: path,
        file_name:file_name
      }
    });
    let response = await object;
    return response;
  },
  async createLinkPreview(contentType, path, access_token){
    let headers = basicApi.image.create_link_upload_preview.headers;
    if(!headers)
      headers= {}
    headers["Authorization"] = "bearer " + access_token;
    headers["Content-Type"] = "application/json";
    headers['Accept-Language'] = app.$store.getters.getLang;
    let params = {
      "content-type":contentType,
      path:path,
    };
    params.lang = app.$store.getters.getLang;
    const object =  axios({
      method: basicApi.image.create_link_upload_preview.method,
      url:geoServiceApiURL + basicApi.image.create_link_upload_preview.url,
      params:params,
      headers: headers,
    });

    let response = await object;
    return response;
  },
  async uploadFilePresignedUrl(url,file,contentType){
    const object =  axios({
      method: "PUT",
      url: url,
      data:file,
      headers:{
        "Content-Type":contentType ? contentType : "application/zip",
        "Content-Disposition":"form-data; name='fieldName'; filename="+ file.name
      }
    });

    let response = await object;
    return response;
  }
}