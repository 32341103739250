<template>
  <Dialog
    :header="propTitle"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :modal="true"
    :contentStyle="propStyle"
    @hide="onClose"
  >
    <div class="Iybw1Bhj3Jhd8s upload-metadata">
      <div>
        <table class="table">
          <tbody>
            <tr>
              <td
                align="left"
                width="30%"
                style="vertical-align: top"
                v-if="!maximized"
              >
                <p class="border-radius-4 bg-A1A5B7">
                  {{ $t("label_quicklook") }}
                </p>
                <div>
                  <table
                    class="table-images w-100"
                    cellspacing="0"
                    cellpadding="0"
                  >
                    <tbody>
                      <tr>
                        <td class="p-0">
                          <p class="border-radius-4 bg-009EF7">
                            {{ $t("label_icon_image") }}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0">
                          <attach-file
                            accept="image/x-png,image/gif,image/jpeg,image/jpg"
                            @fileChange="fileIconChanged"
                          ></attach-file>
                        </td>
                      </tr>
                      <tr>
                        <td v-if="propEntity.icon_src">
                          <div class="preview">
                            <img
                              :src="propEntity.icon_src"
                              class="preview-image"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="p-0">
                          <p class="border-radius-4 bg-009EF7">
                            {{ $t("label_preview_image") }}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0">
                          <attach-file
                            accept="image/x-png,image/gif,image/jpeg,image/jpg"
                            @fileChange="filePreviewChanged"
                          ></attach-file>
                        </td>
                      </tr>
                      <tr>
                        <td v-if="propEntity.preview_src">
                          <div class="preview">
                            <img
                              :src="propEntity.preview_src"
                              class="preview-image"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
              <td
                align="left"
                style="vertical-align: top"
                :style="[maximized ? { width: '30%' } : { width: '70%' }]"
              >
                <p class="border-radius-4 bg-A1A5B7">
                  {{ $t("label_values") }}
                </p>
                <div>
                  <table
                    class="table-metadata w-100"
                    id="tableMetadata"
                    cellspacing="0"
                    cellpadding="0"
                  >
                    <tbody>
                      <tr>
                        <td class="pl-0 pr-0 pt-0" style="width: 10rem">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_metadata_file") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <attach-file
                            accept="text/xml,.gml"
                            @fileChange="fileMetadataChanged"
                          ></attach-file>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_server_path") }}
                            <span class="required">&nbsp;*</span></span
                          >
                        </td>
                        <td class="pt-0">
                          <InputText
                            id="inputFilePath"
                            v-model="propEntity.file_path"
                            class="w-100 new-style border-danger"
                            :class="propEntity.file_path ? '' : 'border-danger'"
                            maxLength="255"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_project") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <treeselect
                            class="new-style"
                            v-model="propEntity.project_ids"
                            name="treeProject"
                            :multiple="true"
                            :options="propProjects"
                            placeholder=""
                          >
                            <label slot="option-label" slot-scope="{ node }">{{
                              node.raw.title
                            }}</label>
                            <div slot="value-label" slot-scope="{ node }">
                              {{ node.raw.title }}
                            </div>
                          </treeselect>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_source_id") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <div
                            :title="
                              propEntity.scence_id
                                ? propEntity.scence_id &&
                                  propEntity.scence_id.length > 50
                                  ? propEntity.scence_id
                                  : null
                                : propEntity.source_id &&
                                  propEntity.source_id.length > 50
                                ? propEntity.source_id
                                : null
                            "
                          >
                            {{
                              propEntity.scence_id
                                ? propEntity.scence_id.length > 50
                                  ? propEntity.scence_id.substring(0, 50) +
                                    "..."
                                  : propEntity.scence_id
                                : propEntity.source_id &&
                                  propEntity.source_id.length > 50
                                ? propEntity.source_id.substring(0, 50) + "..."
                                : propEntity.source_id
                            }}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_image_name") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <InputText
                            v-model="propEntity.name"
                            class="w-100 new-style"
                            maxLength="255"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_satellite") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <treeselect
                            class="w-50 new-style"
                            v-model="propEntity.satellite_id"
                            name="treeSatellite"
                            :multiple="false"
                            :options="propProvider"
                            :noChildrenText="$t('label_no_sub_options')"
                            :noOptionsText="$t('label_no_options_text')"
                            :noResultsText="$t('label_no_results_text')"
                            :disableBranchNodes="true"
                            placeholder=""
                            :default-expand-level="1"
                          >
                            <label slot="option-label" slot-scope="{ node }">{{
                              node.raw.label
                            }}</label>
                            <div slot="value-label" slot-scope="{ node }">
                              {{ node.raw.label }}
                            </div>
                          </treeselect>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_image_time") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <date-picker
                            input-class="form-control new-style"
                            class="w-50"
                            v-model="propEntity.imaging_date_timestamp"
                            :format="'DD/MM/YYYY HH:mm'"
                            valueType="timestamp"
                            :clearable="false"
                            :disabled-date="notAfterToday"
                            :lang="lang"
                            type="datetime"
                            :show-time-panel="showTimePanel"
                            @close="handleOpenChange"
                          >
                            <template v-slot:footer>
                              <button
                                class="mx-btn mx-btn-text"
                                @click="toggleTimePanel"
                              >
                                {{
                                  showTimePanel
                                    ? $t("button_text_select_date")
                                    : $t("button_text_select_time")
                                }}
                              </button>
                            </template>
                          </date-picker>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_image_type") }}</span
                          >
                        </td>
                        <td class="pt-2 pb-2">
                          <span v-text="propEntity.image_type"></span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_incidence_angle") }}</span
                          >
                        </td>
                        <td>
                          <div class="row no-gutters">
                            <div class="col-8">
                              <InputText
                                type="number"
                                v-model="propEntity.incidence_angle"
                                class="w-100 new-style"
                              />
                            </div>

                            <div class="col-4 pl-2">
                              <InputText
                                value="°"
                                readonly
                                class="w-100 new-style"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_resolution") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <div class="row no-gutters">
                            <div class="col-8">
                              <InputText
                                v-model="propEntity.resolution"
                                class="w-100 new-style"
                                maxLength="50"
                              />
                            </div>

                            <div class="col-4 pl-2">
                              <InputText
                                value="m"
                                readonly
                                class="w-100 new-style"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_processing_level") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <Dropdown
                            class="w-50 new-style"
                            v-model="propEntity.processing_level_name"
                            :showClear="false"
                            :options="propProcessingLevel"
                            optionValue="name"
                            optionLabel="value"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_cloud_cove") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <div class="row no-gutters">
                            <div class="col-8">
                              <InputText
                                v-model="propEntity.cloud_coverage"
                                class="w-100 new-style"
                                maxLength="50"
                              />
                            </div>
                            <div class="col-4 pl-2">
                              <InputText
                                v-model="propEntity.cloud_coverage_unit"
                                class="w-100 new-style"
                                maxLength="50"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_snow_cove") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <div class="row no-gutters">
                            <div class="col-8">
                              <InputText
                                v-model="propEntity.snow_coverage"
                                class="w-100 new-style"
                                maxLength="50"
                              />
                            </div>
                            <div class="col-4 pl-2">
                              <InputText
                                v-model="propEntity.snow_coverage_unit"
                                class="w-100 new-style"
                                maxLength="50"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_area") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <div class="row no-gutters">
                            <div class="col-8">
                              <InputText
                                v-model="propEntity.area"
                                class="w-100 new-style"
                                maxLength="50"
                              />
                            </div>
                            <div class="col-4 pl-2">
                              <InputText
                                v-model="propEntity.area_unit"
                                class="w-100 new-style"
                                maxLength="50"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_image_mode") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <AutoComplete
                            v-model="propEntity.mode"
                            :suggestions="arrayBeamMode"
                            @complete="searchBeamMode($event)"
                            :dropdown="false"
                            class="w-100 new-style"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_direction") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <AutoComplete
                            v-model="propEntity.direction"
                            :suggestions="arrayDirection"
                            @complete="searchDirection($event)"
                            :dropdown="false"
                            class="w-100 new-style"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_pol_layer") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <AutoComplete
                            v-model="propEntity.pol_layer"
                            :suggestions="arrayPolLayer"
                            @complete="searchPolLayer($event)"
                            :dropdown="false"
                            class="w-100 new-style"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              d-block
                              line-height-56
                              pl-2
                            "
                            >{{ $t("label_description") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <Textarea
                            v-model="propEntity.description"
                            :autoResize="true"
                            rows="2"
                            cols="30"
                            class="w-100 new-style"
                            maxLength="255"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <template #footer>
      <!-- <Button
        :label="$t('button_text_close')"
        icon="pi pi-times"
        @click="onClose"
        class="p-button-danger p-button-sm"
        autofocus
      /> -->
      <Button
        :disabled="!propEntity.is_success"
        label="Upload"
        icon="pi pi-check"
        class="p-button-success p-button-sm"
        @click="onUpload"
      />
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
import AttachFile from "@/components/commons/upload/AttachFile";
import Button from "@/components/commons/button/Button";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import InputText from "@/components/commons/inputtext/InputText";
import InputNumber from "@/components/commons/inputnumber/InputNumber";
import Textarea from "@/components/commons/textarea/Textarea";
import DatePicker from "vue2-datepicker";
import AutoComplete from "@/components/commons/autocomplete/AutoComplete";
import Treeselect from "@riophae/vue-treeselect";
import moment from "moment";
import {
  change_alias,
  getFileExtension1,
  getFileExtension2,
  getFileExtension3,
} from "@/utils/commons/common";
export default {
  props: {
    propEntity: {
      type: Object,
    },
    propTitle: {
      type: String,
      default: "",
    },
    propStyle: {
      type: Object,
      default: () => {
        return {
          width: "400px",
        };
      },
    },
    propShow: {
      type: Boolean,
      default: false,
    },
    propProvider: {
      type: Array,
      default: () => [],
    },
    propProcessingLevel: {
      type: Array,
      default: () => [],
    },
    propBeamMode: {
      type: Array,
      default: () => [],
    },
    propDirection: {
      type: Array,
      default: () => [],
    },
    propPolLayer: {
      type: Array,
      default: () => [],
    },
    propProjects: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Dialog,
    Button,
    Dropdown,
    InputText,
    InputNumber,
    Textarea,
    AttachFile,
    Treeselect,
    AutoComplete,
    DatePicker,
  },
  data() {
    return {
      showTimePanel: false,
      arrayBeamMode: [],
      arrayDirection: [],
      arrayPolLayer: [],
      maximized: false,
      isEdit: true,
      show: false,
    };
  },
  created() {
    this.show = this.propShow;
    if (!this.propProcessingLevel || this.propProcessingLevel.length === 0)
      this.$emit("getAllProcessingLevel");
    if (!this.propBeamMode || this.propBeamMode.length === 0)
      this.$emit("getAllBeamMode");
    if (!this.propDirection || this.propDirection.length === 0)
      this.$emit("getAllDirection");
    if (!this.propPolLayer || this.propPolLayer.length === 0)
      this.$emit("getAllPolLayer");
    if (!this.propProjects || this.propProjects.length === 0)
      this.$emit("getAllProject");
  },
  watch: {
    propShow: {
      handler(val) {
        this.show = this.propShow;
      },
      immediate: true,
    },
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    accessToken() {
      return this.$store.getters.getAccessToken;
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
  methods: {
    searchPolLayer(e) {
      this.arrayPolLayer = this.propPolLayer
        .filter((x) =>
          change_alias(x.value.trim().split(" ").join("")).startsWith(
            change_alias(e.query.toLowerCase().split(" ").join(""))
          )
        )
        .map((x) => x.value);
    },
    searchDirection(e) {
      this.arrayDirection = this.propDirection
        .filter((x) =>
          change_alias(x.value.trim().split(" ").join("")).startsWith(
            change_alias(e.query.toLowerCase().split(" ").join(""))
          )
        )
        .map((x) => x.value);
    },
    searchBeamMode(e) {
      this.arrayBeamMode = this.propBeamMode
        .filter((x) =>
          change_alias(x.value.trim().split(" ").join("")).startsWith(
            change_alias(e.query.toLowerCase().split(" ").join(""))
          )
        )
        .map((x) => x.value);
    },

    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel;
    },
    handleOpenChange() {
      this.showTimePanel = false;
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    formatUnixDateTime(val) {
      if (!val) return "";
      try {
        return moment.unix(val / 1000).format("DD/MM/YYYY HH:mm");
      } catch (error) {
        return "";
      }
    },
    async fileMetadataChanged(e) {
      this.getDataFromXml(e);
    },
    async getDataFromXml(file) {
      this.$emit("metaFileChange", file);
      document.getElementById("inputFilePath").focus();
    },
    async filePreviewChanged(e) {
      this.propEntity.preview_src = URL.createObjectURL(e);
      this.propEntity.preview_file = e;
    },
    async fileIconChanged(e) {
      this.propEntity.icon_src = URL.createObjectURL(e);
      this.propEntity.icon_file = e;
    },
    onClose() {
      this.show = false;
      this.$emit("close");
    },
    onUpload() {
      if (!this.propEntity.file_path || !this.propEntity.file_path.trim()) {
        this.$emit("message", [this.$t("message_file_path_is_required")]);
        return;
      }
      if (!this.propEntity.is_success) {
        this.$emit("message", [
          this.$t("message_selected_metadata_file_is_required"),
        ]);
        return;
      }
      this.$emit("onUpload");
    },
  },
};
</script>
<style lang="scss" scoped>
.Iybw1Bhj3Jhd8s {
  position: relative;
  width: 100%;
  height: 100%;
  .ehAbnmg4Jwb9BS {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 3;
  }
}
table {
  margin-bottom: 0;
  td {
    border-top: none;
    p {
      background: #f0f0f0;
      font-size: 14px;
      padding: 0.5rem;
    }
    .map-preview {
      position: relative;
    }
  }
}
table.table-images {
  p {
    margin: 0 !important;
  }
}
.preview {
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: white;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    &:hover {
      cursor: pointer;
    }
  }
}
.bg-A1A5B7 {
  background: #a1a5b7;
  color: white;
}
.bg-009EF7 {
  background: #009ef7;
  color: white;
}
.bg-red {
  background: red;
  height: 36px;
  line-height: 35px;
}
.border-radius-4 {
  border-radius: 4px;
}
.line-height-36 {
  line-height: 36px;
}
.line-height-56 {
  line-height: 56px;
}
table.table-metadata {
  border-collapse: separate;
  border-spacing: 0 0.125rem;
  tr {
    margin-bottom: 0.25rem;
    td {
      padding: 0.5rem 0.75rem;
      border: 1px solid white;
      &:first-child {
        // background: #009ef7;
        color: white;
        border-radius: 0.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}
</style>
<style lang="scss" >
.upload-metadata {
  .custom-file-label::after {
    background: #009ef7 !important;
    color: white;
  }
}
</style>