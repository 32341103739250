<template>
  <CommonAccordion :propTitle="$t('title_user_tree')">
    <div slot="content">
      <TreeCommon
        class="w-100 p-1"
        :value="dataTree"
        :expandedKeys="expandedKeys"
        v-if="dataTree && dataTree.length"
      ></TreeCommon></div
  ></CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import TreeCommon from "@/components/commons/tree/Tree";
export default {
  components: {
    CommonAccordion,
    TreeCommon,
  },
  props: {
    propData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dataTree: [],
      expandedKeys: {},
    };
  },
  watch: {
    propData: {
      handler(val) {
        this.dataTree = this.listToTree(val);
        this.expandAll();
      },
      immediate: true,
    },
  },
  methods: {
    expandAll() {
      for (let node of this.dataTree) {
        this.expandNode(node);
      }

      this.expandedKeys = { ...this.expandedKeys };
    },
    collapseAll() {
      this.expandedKeys = {};
    },
    expandNode(node) {
      this.expandedKeys[node.key] = true;
      if (node.children && node.children.length) {
        for (let child of node.children) {
          this.expandNode(child);
        }
      }
    },
    listToTree(list) {
      var map = {},
        node,
        roots = [],
        i;
      let items = [];
      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
        let obj = {
          parent_id:
            list[i].parent_id && list[i].parent_id != list[i].id
              ? list[i].parent_id
              : -1,
          label: list[i].full_name,
          text: list[i].full_name,
          name: list[i].full_name,
          id: list[i].id,
          key: list[i].id,
          children: [],
        };
        items.push(obj);
      }
      for (i = 0; i < items.length; i += 1) {
        try {
          node = items[i];
          if (node.parent_id != null && node.parent_id + "" !== "-1") {
            if (items[map[node.parent_id]]) {
              if (!items[map[node.parent_id]].children) {
                items[map[node.parent_id]].children = [];
              }
              items[map[node.parent_id]].children.push(node);
            } else roots.push(node);
          } else {
            roots.push(node);
          }
        } catch (error) {}
      }
      return roots;
    },
  },
};
</script>
<style lang="scss">
.user__list-tree {
  .p-tree {
    border-color: transparent;
  }
}
</style>