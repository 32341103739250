import axios from 'axios';
import basicApi from "@/utils/apis/basic";
import app from './../../main'
import { geoServiceApiURL } from "@/utils/commons/environment"

export default {
  async getMetadata(path, access_token, category_id, dataModel) {
    let headers = basicApi.file.get_metadata.headers;
    if (!headers)
      headers = {}
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.file.get_metadata.method,
      url: geoServiceApiURL + basicApi.file.get_metadata.url,
      params: {
        path: path,
        lang: app.$store.getters.getLang,
        category_id: category_id,
        model:dataModel
      },
      headers: headers,
    });

    let response = await object;
    return response;
  },
  async getMetadata2(params, access_token) {
    let headers = basicApi.file.get_metadata.headers;
    if (!headers)
      headers = {}
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    if (!params) params = {}
    params.lang = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.file.get_metadata.method,
      url: geoServiceApiURL + basicApi.file.get_metadata.url,
      params: params,
      headers: headers,
    });

    let response = await object;
    return response;
  },
  async downloadPreview(key, access_token) {
    let headers = basicApi.file.download_preview.headers;
    if (!headers)
      headers = {}
    headers["Authorization"] = "bearer " + access_token;
    headers["Access-Control-Expose-Headers"] = "Content-Disposition";
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.file.download_preview.method,
      url: geoServiceApiURL + basicApi.file.download_preview.url.replace("{key}", key),
      responseType: 'blob',
      headers: headers,
      params: {
        lang: app.$store.getters.getLang
      }
    });

    let response = await object;
    return response;
  },
  async dataFromXml(formData, access_token) {
    let headers = basicApi.file.data_from_xml.headers;
    if (!headers)
      headers = {}
    headers["Authorization"] = "bearer " + access_token;
    headers["Content-Type"] = "application/json";
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.file.data_from_xml.method,
      url: geoServiceApiURL + basicApi.file.data_from_xml.url,
      data: formData,
      headers: headers,
      params: {
        lang: app.$store.getters.getLang
      }
    });

    let response = await object;
    return response;
  },
  async putObject(formData, access_token) {
    let headers = basicApi.file.put_object.headers;
    if (!headers)
      headers = {}
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.file.put_object.method,
      url: geoServiceApiURL + basicApi.file.put_object.url,
      data: formData,
      headers: headers
    });

    let response = await object;
    return response;
  },
  async generateUrlUpload(path, access_token) {
    let headers = basicApi.file.generate_url_upload.headers;
    if (!headers)
      headers = {}
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.file.generate_url_upload.method,
      url: geoServiceApiURL + basicApi.file.generate_url_upload.url,
      params: {
        path: path
      },
      headers: headers
    });

    let response = await object;
    return response;
  },
  async uploadFilePresignedUrl(url, file, contentType) {
    const object = axios({
      method: "PUT",
      url: url,
      data: file,
      headers: {
        "Content-Type": contentType ? contentType : "application/zip"
      }
    });

    let response = await object;
    return response;
  }
}