import axios from 'axios';
import basicApi from "@/utils/apis/basic";
import app from './../../main'
import { userServiceApiURL } from "@/utils/commons/environment"
export default {
    async getAll(params, access_token) {
        let headers = basicApi.permission.get_list.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        if (!params) params = {};
        params.lang = app.$store.getters.getLang;
        const object = axios({
          method: basicApi.permission.get_list.method,
          url: userServiceApiURL + basicApi.permission.get_list.url,
          headers:headers,
          params:params
        });
        let response = await object;
        return response;
    },
    async getById( id,access_token) {
        let headers = basicApi.permission.get_by_id.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
          method: basicApi.permission.get_by_id.method,
          url: userServiceApiURL + basicApi.permission.get_by_id.url.replace("{id}",id),
          headers:headers,
          params:{
            page:0,
            size: 2147483647,
            lang : app.$store.getters.getLang
          }
        });
        let response = await object;
        return response;
    },
    
    async create(entity,access_token){
      let headers = basicApi.permission.create.headers;
      headers["Authorization"] = "bearer " + access_token;
      headers['Accept-Language'] = app.$store.getters.getLang;
      const object = axios({
        method: basicApi.permission.create.method,
        url:  userServiceApiURL + basicApi.permission.create.url,
        data: entity,
        headers:headers,
        params: {
            lang:app.$store.getters.getLang
        }
      });
      let response = await object;
      return response;
  },
    
    async update(entity,access_token){
      let headers = basicApi.permission.update.headers;
      headers["Authorization"] = "bearer " + access_token;
      headers['Accept-Language'] = app.$store.getters.getLang;
      const object = axios({
        method: basicApi.permission.update.method,
        url:  userServiceApiURL + basicApi.permission.update.url.replace("{permission_id}",entity.id),
        data: entity,
        params: {
          permission_id: entity.id,          
          lang:app.$store.getters.getLang
        },
        headers:headers,
      });
      let response = await object;
      return response;
  },
    
    async delete(id,access_token){
      let headers = basicApi.permission.delete.headers;
      headers["Authorization"] = "bearer " + access_token;
      headers['Accept-Language'] = app.$store.getters.getLang;
      const object = axios({
        method: basicApi.permission.delete.method,
        url:  userServiceApiURL + basicApi.permission.delete.url.replace("{permission_id}",id),
        params: {
          permission_id: id,
          lang:app.$store.getters.getLang
        },
        headers:headers,
      });
      let response = await object;
      return response;
  },
}