var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{style:({ zIndex: 1050 }),attrs:{"id":"dialogOrderDetail","header":_vm.$t('title_image_list'),"visible":_vm.show,"modal":true,"contentStyle":_vm.propStyle},on:{"update:visible":function($event){_vm.show=$event},"hide":_vm.onClose},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('Button',{class:'p-button-danger p-button-sm mr-0',attrs:{"label":_vm.$t('button_text_close'),"icon":'fas fa-times'},on:{"click":_vm.onClose}})]},proxy:true}])},[_c('div',{staticClass:"datatable"},[_c('ContextMenu',{ref:"cmDlgOrderDetailDatatable",attrs:{"model":_vm.menuModel,"appendTo":'dialogOrderDetail'}}),_c('DataTable',{ref:"dt",attrs:{"value":_vm.items,"resizableColumns":true,"columnResizeMode":"expand","selectionMode":"single","dataKey":"id","contextMenu":"","selection":_vm.selected},on:{"update:selection":function($event){_vm.selected=$event},"row-contextmenu":_vm.onRowContextMenu,"row-click":_vm.rowClick}},[_c('Column',{scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('div',{staticClass:"media"},[_c('icon-preview',{attrs:{"propId":slotProps.data.preview && slotProps.data.preview.icon_path
                  ? slotProps.data.preview.icon_path
                  : slotProps.data.preview &&
                    slotProps.data.preview.preview_path
                  ? slotProps.data.preview.preview_path
                  : null,"prop-src":slotProps.data.preview && slotProps.data.preview.icon_path
                  ? slotProps.data.preview.icon_path
                  : slotProps.data.preview &&
                    slotProps.data.preview.preview_path
                  ? slotProps.data.preview.preview_path
                  : null,"propPreviewImages":slotProps.data.preview &&
                slotProps.data.preview.preview_images
                  ? slotProps.data.preview.preview_images
                  : []}}),_c('div',{staticClass:"media-body"},[_c('ul',[_c('li',{staticClass:"lbl-geostore"},[_c('strong',[_vm._v(_vm._s(slotProps.data.name))])]),_c('li',{staticClass:"mt-1"},[_c('div',{},[_c('span',[_vm._v(_vm._s(_vm.$t("label_image_time"))+": "),_c('strong',[_vm._v(_vm._s(slotProps.data && slotProps.data.info ? slotProps.data.info.imaging_date : ""))])]),_c('span',{staticClass:"pl-2 pr-2"},[_vm._v("|")]),_c('span',[_vm._v(_vm._s(_vm.$t("label_satellite"))+": "),_c('strong',[_vm._v(_vm._s(slotProps.data.info && slotProps.data.info.satellite ? slotProps.data.info.satellite.value : slotProps.data.metadata && slotProps.data.metadata.satellite ? slotProps.data.metadata.satellite.value : slotProps.data.metadata_extra ? (slotProps.data.metadata_extra.mission ? slotProps.data.metadata_extra.mission : "") + " " + (slotProps.data.metadata_extra.mission_index ? slotProps.data.metadata_extra.mission_index : "") : ""))])])])]),_c('ItemMetadata',{attrs:{"prop-item":slotProps.data,"propImageTypes":_vm.propImageTypes}})],1)])],1)]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }