<template>
  <Dialog
    :header="$t('label_satellite')"
    :visible.sync="show"
    :modal="true"
    :style="{ zIndex: 1060 }"
    :contentStyle="{ width: dialogWidth + 'px' }"
    @hide="onClose"
  >
    <div class="datatable">
      <DataTable
        :value="propItems"
        ref="dtAddCustomers"
        :resizableColumns="true"
        columnResizeMode="expand"
        dataKey="id"
        :selection.sync="selected"
      >
        <template #empty v-if="propItems && propItems.length === 0">
          <span class="text-center">{{ $t("label_no_data") }}</span>
        </template>
        <Column
          header="No"
          headerStyle="width: 3rem; text-align: center!important"
          bodyStyle="text-align: center;"
        >
          <template #body="slotProps">
            {{ slotProps.index + 1 }}
          </template></Column
        >

        <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
        <Column field="name" :header="$t('label_satellite_name')"></Column>
      </DataTable>
    </div>
    <template #footer>
      <!-- <Button
        @click="onSelected"
        :label="$t('button_text_select')"
        icon="pi pi-check"
        class="p-button-success p-button-sm mr-0"
      /> -->
      <button
        @click="onSelected"
        :label="$t('button_text_select')"
        icon="pi pi-check"
        class="btn btn-geostore2 btn-select btn-w96-h36 mr-0"
      >
        <i class="fas fa-check"></i>&nbsp; {{ $t("button_text_select") }}
      </button>
    </template>
  </Dialog>
</template>
<script>
import Button from "@/components/commons/button/Button";
import Dialog from "@/components/commons/dialog/Dialog";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
export default {
  components: {
    Button,
    Dialog,
    DataTable,
    Column,
  },
  props: {
    propItems: {
      type: Array,
      default: () => [],
    },
    propShow: {
      type: Boolean,
      default: false,
    },
    propSelected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: null,
      show: false,
    };
  },
  created() {
    this.show = this.propShow;
    if (this.propItems && this.propItems.length === 0) {
      this.$emit("getAllSatellite");
    }
  },
  mounted() {
    if (this.propSelected && this.propItems && this.propItems.length > 0)
      this.selected = Object.assign(
        [],
        this.propSelected.filter((x) => x.is_delete != true)
      );
  },
  watch: {
    propShow(val) {
      this.show = val;
    },
    propItems(val) {
      if (val && val.length > 0) {
        setTimeout(() => {
          if (this.propSelected) {
            this.selected = [];
            this.selected = Object.assign(
              [],
              this.propSelected.filter((x) => x.is_delete != true)
            );
          }
        }, 100);
      }
    },
  },
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    dialogWidth() {
      if (this.documentWidth >= 992) return 720;
      else if (this.documentWidth >= 768 && this.documentWidth < 992)
        return 720;
      else return this.documentWidth - 32;
    },
  },
  methods: {
    initData() {
      if (this.propItems && this.propItems.length > 0)
        this.items = Object.assign(
          [],
          this.propItems.filter((x) => x.is_delete != true)
        );
      else this.items = [];
    },
    onClose() {
      this.$emit("close");
    },
    onSelected() {
      this.$emit("selected", this.selected);
    },
  },
};
</script>
<style lang="scss" scoped>
button {
  &.btn-select {
    font-size: 14px;
  }
}
</style>
<style lang="scss">
.image-types__dialog {
  .datatable {
    @import "@/assets/scss/_datatable.scss";
    .p-paginator {
      border: transparent;
      background: transparent;
    }
  }
}
</style>