<template>
  <CommonAccordion :propTitle="$t('title_satellite_list')">
    <div slot="content">
      <div class="datatable">
        <DataTable
          :value="items"
          ref="dt"
          :resizableColumns="true"
          columnResizeMode="expand"
          selectionMode="single"
          dataKey="id"
        >
          <template #header>
            <div class="text-left">
              <div>
                <div v-if="items.length" class="font-weight-normal">
                  {{
                    $t("label_show_from_to", null, {
                      from: 1,
                      to: items.length,
                      total: items.length,
                    })
                  }}
                </div>
              </div>
            </div>
          </template>
          <template #empty v-if="items && items.length === 0">
            <span class="text-center">{{ $t("label_no_data") }}</span>
          </template>
          <Column
            header="No"
            headerStyle="width: 3rem; text-align: center!important"
            bodyStyle="text-align: center;"
          >
            <template #body="slotProps">
              {{ slotProps.index + 1 }}
            </template></Column
          >
          <Column field="name" :header="$t('label_satellite_name')"></Column>
        </DataTable>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import Button from "@/components/commons/button/Button";
export default {
  components: {
    DataTable,
    Column,
    Button,
    CommonAccordion,
  },
  props: {
    propItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      items: [],
    };
  },
  watch: {
    propItems: {
      handler(val) {
        this.initData();
        this.selected = null;
      },
      immediate: true,
    },
  },
  methods: {
    initData() {
      if (this.propItems && this.propItems.length > 0)
        this.items = Object.assign(
          [],
          this.propItems.filter((x) => x.is_delete != true)
        );
      else this.items = [];
    },
    rowClick(val) {
      this.$emit("rowClick", val);
    },
    pageChange(e) {
      this.propTable.page = e.page;
    },
  },
};
</script>
<style lang="scss">
.provider__satellites {
  .datatable {
    @import "@/assets/scss/_datatable.scss";
    .p-paginator {
      border: transparent;
      background: transparent;
    }
  }
}
</style>