var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",class:_vm.containerClass,on:{"click":_vm.onClick}},[_c('div',{staticClass:"p-hidden-accessible"},[_c('input',{ref:"focusInput",attrs:{"type":"text","role":"listbox","id":_vm.inputId,"readonly":"","disabled":_vm.disabled,"tabindex":_vm.tabindex,"aria-haspopup":"listbox","aria-expanded":_vm.overlayVisible,"aria-labelledby":_vm.ariaLabelledBy},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"keydown":_vm.onKeyDown}})]),_c('div',{staticClass:"p-multiselect-label-container"},[_c('div',{class:_vm.labelClass},[_vm._t("value",function(){return [_vm._v(" "+_vm._s(_vm.label)+" ")]},{"value":_vm.value,"placeholder":_vm.placeholder})],2)]),_vm._m(0),_c('transition',{attrs:{"name":"p-connected-overlay"},on:{"enter":_vm.onOverlayEnter,"leave":_vm.onOverlayLeave}},[(_vm.overlayVisible)?_c('div',{ref:"overlay",staticClass:"p-multiselect-panel p-component"},[_c('div',{staticClass:"p-multiselect-header"},[_c('div',{staticClass:"p-checkbox",attrs:{"role":"checkbox","aria-checked":_vm.allSelected},on:{"click":_vm.onToggleAll}},[_c('div',{staticClass:"p-hidden-accessible"},[_c('input',{attrs:{"type":"checkbox","readonly":""},on:{"focus":_vm.onHeaderCheckboxFocus,"blur":_vm.onHeaderCheckboxBlur}})]),_c('div',{class:[
              'p-checkbox-box p-component',
              {
                'p-highlight': _vm.allSelected,
                'p-focus': _vm.headerCheckboxFocused,
              } ],attrs:{"role":"checkbox","aria-checked":_vm.allSelected}},[_c('span',{class:['p-checkbox-icon', { 'pi pi-check': _vm.allSelected }]})])]),(_vm.filter)?_c('div',{staticClass:"p-multiselect-filter-container"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filterValue),expression:"filterValue"}],staticClass:"p-multiselect-filter p-inputtext p-component",attrs:{"type":"text","placeholder":_vm.filterPlaceholder},domProps:{"value":(_vm.filterValue)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.filterValue=$event.target.value},_vm.onFilterChange]}}),_c('span',{staticClass:"p-multiselect-filter-icon pi pi-search"})]):_vm._e(),_c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"p-multiselect-close p-link",attrs:{"type":"button"},on:{"click":_vm.onCloseClick}},[_c('span',{staticClass:"p-multiselect-close-icon pi pi-times"})])]),_c('div',{ref:"itemsWrapper",staticClass:"p-multiselect-items-wrapper",style:({ 'max-height': _vm.scrollHeight })},[_c('ul',{staticClass:"p-multiselect-items p-component",attrs:{"role":"listbox","aria-multiselectable":"true"}},_vm._l((_vm.visibleOptions),function(option,i){return _c('li',{directives:[{name:"ripple",rawName:"v-ripple"}],key:_vm.getOptionRenderKey(option),class:[
              'p-multiselect-item',
              {
                'p-highlight': _vm.isSelected(option),
                'p-disabled': _vm.isOptionDisabled(option),
              } ],attrs:{"role":"option","aria-selected":_vm.isSelected(option),"aria-label":_vm.getOptionLabel(option),"tabindex":_vm.tabindex || '0'},on:{"click":function($event){return _vm.onOptionSelect($event, option)},"keydown":function($event){return _vm.onOptionKeyDown($event, option)}}},[_c('div',{staticClass:"p-checkbox p-component"},[_c('div',{class:[
                  'p-checkbox-box p-component',
                  { 'p-highlight': _vm.isSelected(option) } ]},[_c('span',{class:[
                    'p-checkbox-icon',
                    { 'pi pi-check': _vm.isSelected(option) } ]})])]),_vm._t("option",function(){return [_c('span',[_vm._v(_vm._s(_vm.getOptionLabel(option)))])]},{"option":option,"index":i})],2)}),0)])]):_vm._e()])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-multiselect-trigger"},[_c('span',{staticClass:"p-multiselect-trigger-icon pi pi-chevron-down"})])}]

export { render, staticRenderFns }