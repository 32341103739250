<template>
  <Dialog
    :header="$t('label_incompleted')"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :modal="true"
    :contentStyle="propStyle"
    @hide="onClose"
  >
    <div class="Iybw1Bhj3Jhd8s upload-metadata">
      <div>
        <table class="table">
          <tbody>
            <tr>
              <td
                align="left"
                width="30%"
                style="vertical-align: top"
                v-if="!maximized"
              >
                <p class="border-radius-4 bg-A1A5B7">
                  {{ $t("label_quicklook") }}
                </p>
                <div>
                  <table
                    class="table-images w-100"
                    cellspacing="0"
                    cellpadding="0"
                  >
                    <tbody>
                      <tr>
                        <td class="p-0">
                          <p class="border-radius-4 bg-009EF7">
                            {{ $t("label_icon_image") }}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0">
                          <attach-file
                            accept="image/x-png,image/gif,image/jpeg,image/jpg,image/tiff"
                            @fileChange="fileIconChanged"
                          ></attach-file>
                        </td>
                      </tr>
                      <tr>
                        <td v-if="propEntity.icon_src">
                          <div class="preview">
                            <img
                              :src="propEntity.icon_src"
                              class="preview-image"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="p-0">
                          <p class="border-radius-4 bg-009EF7">
                            {{ $t("label_preview_image") }}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0">
                          <attach-file
                            accept="image/x-png,image/gif,image/jpeg,image/jpg,image/tiff"
                            @fileChange="filePreviewChanged"
                          ></attach-file>
                        </td>
                      </tr>
                      <tr>
                        <td v-if="propEntity.preview_src">
                          <div class="preview">
                            <img
                              :src="propEntity.preview_src"
                              class="preview-image"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="upload-map">
                  <div class="map-bounding-box" id="mapBoundingBox"></div>
                  <button
                    class="btn button-clear-features"
                    @click="clearBoundary"
                  >
                    {{ $t("button_text_clear_boundary") }}
                  </button>
                </div>
              </td>
              <td
                align="left"
                style="vertical-align: top"
                :style="[maximized ? { width: '30%' } : { width: '70%' }]"
              >
                <p class="border-radius-4 bg-A1A5B7">
                  {{ $t("label_values") }}
                </p>
                <div>
                  <table
                    class="table-metadata w-100"
                    id="tableMetadata"
                    cellspacing="0"
                    cellpadding="0"
                  >
                    <tbody>
                      <tr>
                        <td class="pl-0 pr-0 pt-0" style="width: 10rem">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_metadata_file") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <attach-file
                            accept="text/xml,.gml"
                            @fileChange="fileMetadataChanged"
                          ></attach-file>
                        </td>
                      </tr>

                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_data_specification") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <Dropdown
                            class="w-100 new-style"
                            v-model="propEntity.data_specification"
                            :showClear="false"
                            :options="propDataSpecifications"
                            optionValue="name"
                            optionLabel="name"
                            @change="dataSpecificationChange"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("menu_category_data_catalog") }}
                            <span class="required">&nbsp;*</span></span
                          >
                        </td>
                        <td class="pt-0">
                          <select
                            class="new-style form-control w-100"
                            v-model="propEntity.category_id"
                          >
                            <option
                              v-for="c in propCategoryDataList"
                              :key="c.id"
                              :value="c.id"
                            >
                              {{
                                c &&
                                c.translation &&
                                c.translation[lang] &&
                                c.translation[lang].description
                                  ? c.translation[lang].description
                                  : c && c.description
                                  ? c.description
                                  : ""
                              }}
                            </option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_server_path") }}
                            <span class="required">&nbsp;*</span></span
                          >
                        </td>
                        <td class="pt-0">
                          <InputText
                            id="inputFilePath"
                            v-model="propEntity.file_path"
                            class="w-100 new-style border-danger"
                            :class="propEntity.file_path ? '' : 'border-danger'"
                            maxLength="255"
                            readonly
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_source_id")
                            }}<span class="required">&nbsp;*</span></span
                          >
                        </td>
                        <td class="pt-0">
                          <InputText
                            class="w-100 new-style"
                            maxLength="255"
                            v-model="propEntity.scene_id"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_image_name")
                            }}<span class="required">&nbsp;*</span></span
                          >
                        </td>
                        <td class="pt-0">
                          <InputText
                            v-model="propEntity.name"
                            class="w-100 new-style"
                            maxLength="255"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_satellite")
                            }}<span class="required">&nbsp;*</span></span
                          >
                        </td>
                        <td class="pt-0">
                          <treeselect
                            class="w-100 new-style"
                            v-model="propEntity.satellite_id"
                            name="treeSatellite"
                            :multiple="false"
                            :options="propProvider"
                            :noChildrenText="$t('label_no_sub_options')"
                            :noOptionsText="$t('label_no_options_text')"
                            :noResultsText="$t('label_no_results_text')"
                            :disableBranchNodes="true"
                            placeholder=""
                            :default-expand-level="1"
                          >
                            <label slot="option-label" slot-scope="{ node }">{{
                              node.raw.label
                            }}</label>
                            <div slot="value-label" slot-scope="{ node }">
                              {{ node.raw.label }}
                            </div>
                          </treeselect>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_image_time")
                            }}<span class="required">&nbsp;*</span></span
                          >
                        </td>
                        <td class="pt-0">
                          <date-picker
                            input-class="form-control new-style"
                            class="w-100"
                            v-model="propEntity.imaging_date_timestamp"
                            :format="'DD/MM/YYYY HH:mm'"
                            valueType="timestamp"
                            :clearable="false"
                            :disabled-date="notAfterToday"
                            :lang="lang"
                            type="datetime"
                            :show-time-panel="showTimePanel"
                            @close="handleOpenChange"
                          >
                            <template v-slot:footer>
                              <button
                                class="mx-btn mx-btn-text"
                                @click="toggleTimePanel"
                              >
                                {{
                                  showTimePanel
                                    ? $t("button_text_select_date")
                                    : $t("button_text_select_time")
                                }}
                              </button>
                            </template>
                          </date-picker>
                        </td>
                      </tr>

                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_incidence_angle") }}</span
                          >
                        </td>
                        <td>
                          <div class="row no-gutters">
                            <div class="col-8">
                              <InputText
                                type="number"
                                v-model="propEntity.incidence_angle"
                                class="w-100 new-style"
                              />
                            </div>

                            <div class="col-4 pl-2">
                              <InputText
                                class="w-100 new-style"
                                v-model="propEntity.incidence_angle_unit"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_resolution") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <div class="row no-gutters">
                            <div class="col-8">
                              <InputText
                                type="number"
                                v-model="propEntity.resolution"
                                class="w-100 new-style"
                                maxLength="50"
                              />
                            </div>

                            <div class="col-4 pl-2">
                              <InputText
                                class="w-100 new-style"
                                v-model="propEntity.resolution_unit"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_processing_level") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <Dropdown
                            class="w-100 new-style"
                            v-model="propEntity.processing_level_name"
                            :showClear="false"
                            :options="propProcessingLevel"
                            optionValue="name"
                            optionLabel="value"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              d-block
                              line-height-56
                              pl-2
                            "
                            >{{ $t("label_boundary")
                            }}<span class="required">&nbsp;*</span></span
                          >
                        </td>
                        <td class="pt-0">
                          <PolygonPoints
                            :propCoordinates="coordinates"
                            ref="polygonPoints"
                            @inputUpperLeftChange="inputUpperLeftChange"
                            @inputUpperRightChange="inputUpperRightChange"
                            @inputLowerLeftChange="inputLowerLeftChange"
                            @inputLowerRightChange="inputLowerRightChange"
                          />
                        </td>
                      </tr>

                      <tr
                        v-for="item in (propEntity.metadata
                          ? propEntity.metadata
                          : []
                        ).filter(
                          (x) =>
                            x &&
                            x.active &&
                            x.key != 'boundary' &&
                            !fieldsIgnore.includes(x.key)
                        )"
                        :key="item.id"
                      >
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ item.name }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <div class="row no-gutters">
                            <div class="col-8">
                              <InputText
                                v-model="item.value"
                                class="w-100"
                                :placeholder="$t('label_value')"
                              />
                            </div>
                            <div class="col-4 pl-2">
                              <InputText
                                v-model="item.unit"
                                class="w-100"
                                :placeholder="$t('label_unit')"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr v-for="item in fieldsAddNew" :key="item.gen_id">
                        <td class="pl-0 pr-0 pt-0">
                          <span class="border-radius-4 w-100 d-block"
                            ><InputText
                              v-model="item.name"
                              class="w-100"
                              maxlength="255"
                              :placeholder="$t('label_field_name')"
                          /></span>
                        </td>
                        <td class="pt-0">
                          <div class="row no-gutters">
                            <div class="col-4">
                              <InputText
                                v-model="item.key"
                                class="w-100"
                                :placeholder="$t('label_key')"
                              />
                            </div>
                            <div class="col-4">
                              <InputText
                                v-model="item.value"
                                class="w-100"
                                :placeholder="$t('label_value')"
                              />
                            </div>
                            <div class="col-4 pl-2">
                              <InputText
                                v-model="item.unit"
                                class="w-100"
                                :placeholder="$t('label_unit')"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" class="text-right">
                          <button
                            class="btn btn-primary btn-sm"
                            @click="addNewField"
                          >
                            <i class="fas fa-plus"></i>&nbsp;
                            {{ $t("button_add_new_field") }}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <template #footer>
      <Button
        :disabled="
          propButton && propButton.disabled ? propButton.disabled : false
        "
        :label="$t('button_text_update')"
        :icon="
          propButton && propButton.processing
            ? 'fa fa-spinner fa-spin'
            : 'pi pi-check'
        "
        class="p-button-success p-button-sm"
        @click="onUpload"
      />
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
import AttachFile from "@/components/commons/upload/AttachFile";
import Button from "@/components/commons/button/Button";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import InputText from "@/components/commons/inputtext/InputText";
import InputNumber from "@/components/commons/inputnumber/InputNumber";
import Textarea from "@/components/commons/textarea/Textarea";
import DatePicker from "vue2-datepicker";
import AutoComplete from "@/components/commons/autocomplete/AutoComplete";
import PolygonPoints from "./uploadmetadata/Polygon";
import Treeselect from "@riophae/vue-treeselect";
import Map from "ol/Map";
import View from "ol/View";
import { Tile as TileLayer } from "ol/layer";
import XYZ from "ol/source/XYZ";
import { TileImage } from "ol/source";
import { defaults as defaultControls, FullScreen } from "ol/control";
import { defaults } from "ol/interaction";
import { fromLonLat, transform } from "ol/proj";
import Feature from "ol/Feature";
import { Vector as VectorLayer, Image as ImageLayer } from "ol/layer";
import { Vector as VectorSource, ImageCanvas } from "ol/source";
import { Modify, Select } from "ol/interaction.js";
import Draw from "ol/interaction/Draw";
import { unByKey } from "ol/Observable.js";
import Collection from "ol/Collection";
import { getArea, getLength } from "ol/sphere.js";
import { LineString, Polygon, MultiPoint, Point } from "ol/geom.js";
import { getCenter, getTopLeft } from "ol/extent";
import {
  Style,
  Fill,
  Stroke,
  Circle,
  Icon,
  Text,
  RegularShape,
} from "ol/style.js";
import moment from "moment";
import {
  change_alias,
  getFileExtension1,
  getFileExtension2,
  getFileExtension3,
  convertProjection2WGS84,
} from "@/utils/commons/common";
export default {
  props: {
    propEntity: {
      type: Object,
    },
    propTitle: {
      type: String,
      default: "",
    },
    propStyle: {
      type: Object,
      default: () => {
        return {
          width: "400px",
        };
      },
    },
    propShow: {
      type: Boolean,
      default: false,
    },
    propProvider: {
      type: Array,
      default: () => [],
    },
    propProcessingLevel: {
      type: Array,
      default: () => [],
    },
    propBeamMode: {
      type: Array,
      default: () => [],
    },
    propDirection: {
      type: Array,
      default: () => [],
    },
    propPolLayer: {
      type: Array,
      default: () => [],
    },
    propProjects: {
      type: Array,
      default: () => [],
    },
    propDataSpecifications: {
      type: Array,
      default: () => [],
    },
    propCategoryDataList: {
      type: Array,
      default: () => [],
    },
    propButton: {
      type: Object,
      default: () => {
        return {
          disabled: false,
          processing: false,
        };
      },
    },
  },
  components: {
    Dialog,
    Button,
    Dropdown,
    InputText,
    InputNumber,
    Textarea,
    AttachFile,
    Treeselect,
    AutoComplete,
    DatePicker,
    PolygonPoints,
  },
  data() {
    return {
      fieldsAddNew: [],
      fieldsIgnore: [
        "image_name",
        "imaging_date",
        "scene_id",
        "source_id",
        "incidence_angle",
        "processing_level",
        "resolution",
      ],
      showTimePanel: false,
      arrayBeamMode: [],
      arrayDirection: [],
      arrayPolLayer: [],
      maximized: false,
      isEdit: true,
      show: false,
      map: Object,
      layer: Object,
      layerLabel: Object,
      feature: null,
      featureDraw: null,
      draw: Object,
      modify: Object,
      select: Object,
      isCoordinates: false,
      coordinates: [
        {
          lat: null,
          lng: null,
        },
        {
          lat: null,
          lng: null,
        },
        {
          lat: null,
          lng: null,
        },
        {
          lat: null,
          lng: null,
        },
      ],
    };
  },
  created() {
    this.show = this.propShow;
    if (
      !this.propDataSpecifications ||
      this.propDataSpecifications.length === 0
    )
      this.$emit("getAllModel");
    if (!this.propProcessingLevel || this.propProcessingLevel.length === 0)
      this.$emit("getAllProcessingLevel");
    // if (!this.propBeamMode || this.propBeamMode.length === 0)
    //   this.$emit("getAllBeamMode");
    // if (!this.propDirection || this.propDirection.length === 0)
    //   this.$emit("getAllDirection");
    // if (!this.propPolLayer || this.propPolLayer.length === 0)
    //   this.$emit("getAllPolLayer");
    // if (!this.propProjects || this.propProjects.length === 0)
    //   this.$emit("getAllProject");
  },
  mounted() {
    var vm = this;
    var source = new VectorSource({ wrapX: false, id: "sourcedraw" });

    this.layer = new VectorLayer({
      source: source,
      style: new Style({
        fill: new Fill({
          color: this.hexToRgbA("#fab710", 0.5), //"transparent",
        }),
        stroke: new Stroke({
          color: "#FFBF23",
          width: 2,
        }),
      }),
    });

    this.layer.set("id", "layerdraw");

    const source2 = new VectorSource({
      wrapX: false,
    });
    this.layerLabel = new VectorLayer({
      source: source2,
      id: "labelLayer",
      style: new Style({
        fill: new Fill({
          color: this.hexToRgbA("#fff", 1),
        }),
        stroke: new Stroke({
          color: this.hexToRgbA("#000", 0.5),
          width: 2,
        }),
      }),
    });
    this.map = new Map({
      controls: defaultControls({ attribution: false, zoom: false }),
      interactions: defaults({ doubleClickZoom: false }),
      layers: [
        new TileLayer({
          title: "Basemap",
          id: "vgbasemap",
          source: new XYZ({
            url:
              vm.basemap &&
              Object.keys(vm.basemap).length > 0 &&
              vm.basemap.source &&
              vm.basemap.source.url
                ? vm.basemap.source.url
                : process.env.VUE_APP_BASEMAP_URL,
          }),
          show: true,
          opacity: 1,
        }),
        this.layer,
        this.layerLabel,
      ],
      target: "mapBoundingBox",
      view: new View({
        center: fromLonLat([108.222848, 16.062833]),
        zoom: 8,
        minZoom: 0,
        maxZoom: 20,
      }),
    });
    this.map.on("pointermove", pointerMove);
    function pointerMove(evt) {
      if (!vm.featureDraw && Object.keys(vm.draw).length === 0) {
        vm.addInteraction();
      } else if (vm.featureDraw && vm.draw && Object.keys(vm.draw).length > 0) {
        if (vm.featureDraw.getGeometry().getCoordinates().length > 0) {
          if (vm.featureDraw.getGeometry().getCoordinates()[0].length >= 6) {
            vm.map.removeInteraction(vm.draw);
            vm.draw = Object;
            setTimeout(() => {
              if (vm.featureDraw && vm.layer && vm.featureDraw.id_) {
                let f = vm.layer.getSource().getFeatureById(vm.featureDraw.id_);
                if (f) vm.layer.getSource().removeFeature(f);
              }
            }, 60);
            vm.onDrawEnd();
          }
        }
      }
    }
  },
  watch: {
    propShow: {
      handler(val) {
        this.show = this.propShow;
      },
      immediate: true,
    },
    "propEntity.bounding_box": {
      handler(val) {
        if (val && val.length > 0) {
          if (this.layer && Object.keys(this.layer).length > 0) {
            this.layer.getSource().clear();
            this.layerLabel.getSource().clear();
            if (this.draw && Object.keys(this.draw).length > 0) {
              this.map.removeInteraction(this.draw);
              this.draw = Object;
            }
            this.clearModify();
            let points = [];
            let id =
              new Date().getTime() -
              Math.floor(Math.random() * 999999999) +
              Math.floor(Math.random() * 888888888);
            for (let i = 0; i < val.length; i++) {
              points.push(transform(val[i], "EPSG:4326", "EPSG:3857"));
            }
            this.featureDraw = new Feature({
              geometry: new Polygon([points]),
              id: id,
            });
            this.featureDraw.set("id", id);
            this.featureDraw.id_ = id;
            this.layer.getSource().addFeature(this.featureDraw);
            var ext = this.featureDraw.getGeometry().getExtent();
            this.map.getView().fit(ext, {
              size: this.map.getSize(),
            });
            var center = getCenter(ext);
            this.map.getView().animate({
              center: [center[0], center[1]],
              duration: 100,
              zoom: this.map.getView().getZoom(),
            });
            //this.getCoordinatesFromFeature(this.featureDraw);
            setTimeout(() => {
              if (this.featureDraw && this.layer && this.featureDraw.id_) {
                let f = this.layer
                  .getSource()
                  .getFeatureById(this.featureDraw.id_);
                if (f) this.layer.getSource().removeFeature(f);
              }
            }, 60);
            setTimeout(() => {
              this.onDrawEnd();
            }, 500);
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    accessToken() {
      return this.$store.getters.getAccessToken;
    },
    lang() {
      return this.$store.getters.getLang;
    },
    basemap() {
      return this.$store.getters.getBasemap;
    },
  },
  methods: {
    addNewField() {
      this.fieldsAddNew.push({
        active: true,
        id: null,
        key: null,
        name: null,
        unit: null,
        value: null,
        gen_id: new Date().getTime() + Math.floor(Math.random() * 999),
      });
    },
    dataSpecificationChange(e) {
      if (e) {
        let item = this.propDataSpecifications.find((x) => x.name === e.value);
        this.propEntity.metadata = item && item.data ? item.data : [];
      } else {
        this.propEntity.metadata = [];
      }
    },
    clearBoundary() {
      if (this.layer && Object.keys(this.layer).length > 0) {
        this.layer.getSource().clear();
      }
      if (this.layerLabel && Object.keys(this.layerLabel).length > 0)
        this.layerLabel.getSource().clear();
      this.featureDraw = null;
      this.feature = null;
      for (let i = 0; i < this.coordinates.length; i++) {
        this.coordinates[i].lat = null;
        this.coordinates[i].lng = null;
      }
      if (this.$refs.polygonPoints) {
        this.$refs.polygonPoints.initVertices();
      }
      setTimeout(() => {
        this.addInteraction();
      }, 100);
    },
    inputUpperLeftChange(data) {
      if (
        !this.coordinates[0] ||
        Object.keys(this.coordinates[0]).length === 0
      ) {
        this.coordinates[0] = { lat: null, lng: null };
      }
      this.coordinates[0].lat = data.lat ? data.lat : null;
      this.coordinates[0].lng = data.lng ? data.lng : null;
      this.polygonChange();
    },
    inputUpperRightChange(data) {
      if (
        !this.coordinates[1] ||
        Object.keys(this.coordinates[1]).length === 0
      ) {
        this.coordinates[1] = { lat: null, lng: null };
      }
      this.coordinates[1].lat = data.lat ? data.lat : null;
      this.coordinates[1].lng = data.lng ? data.lng : null;
      this.polygonChange();
    },
    inputLowerLeftChange(data) {
      if (
        !this.coordinates[3] ||
        Object.keys(this.coordinates[3]).length === 0
      ) {
        this.coordinates[3] = { lat: null, lng: null };
      }
      this.coordinates[3].lat = data.lat ? data.lat : null;
      this.coordinates[3].lng = data.lng ? data.lng : null;
      this.polygonChange();
    },
    inputLowerRightChange(data) {
      if (
        !this.coordinates[2] ||
        Object.keys(this.coordinates[2]).length === 0
      ) {
        this.coordinates[2] = { lat: null, lng: null };
      }
      this.coordinates[2].lat = data.lat ? data.lat : null;
      this.coordinates[2].lng = data.lng ? data.lng : null;
      this.polygonChange();
    },
    polygonChange() {
      if (
        this.coordinates[0].lat != undefined &&
        this.coordinates[0].lat != null &&
        this.coordinates[0].lng != undefined &&
        this.coordinates[0].lng != null &&
        this.coordinates[1].lat != undefined &&
        this.coordinates[1].lat != null &&
        this.coordinates[1].lng != undefined &&
        this.coordinates[1].lng != null &&
        this.coordinates[2].lat != undefined &&
        this.coordinates[2].lat != null &&
        this.coordinates[2].lng != undefined &&
        this.coordinates[2].lng != null &&
        this.coordinates[3].lat != undefined &&
        this.coordinates[3].lat != null &&
        this.coordinates[3].lng != undefined &&
        this.coordinates[3].lng != null
      ) {
        let points = [];
        if (this.draw && Object.keys(this.draw).length > 0) {
          this.map.removeInteraction(this.draw);
          this.draw = Object;
        }
        this.layerLabel.getSource().clear();
        for (let i = 0; i < this.coordinates.length; i++) {
          points.push(
            transform(
              [this.coordinates[i].lng, this.coordinates[i].lat],
              "EPSG:4326",
              "EPSG:3857"
            )
          );
          let featureLabel = new Feature({
            type: "icon",
            geometry: new Point(
              transform(
                [this.coordinates[i].lng, this.coordinates[i].lat],
                "EPSG:4326",
                "EPSG:3857"
              )
            ),
          });
          featureLabel.setStyle(
            new Style({
              text: new Text({
                textAlign: "center",
                textBaseline: "middle",
                font: "16px Roboto, Helvetica Neue, Avenir, Helvetica, Arial, sans-serif",
                fill: new Fill({ color: this.hexToRgbA("#fff", 1) }),
                stroke: new Stroke({
                  color: this.hexToRgbA("#000", 0.5),
                  width: 3,
                }),
                text: i + 1 + "",
                offsetX: 2,
                offsetY: 0,
              }),
            })
          );
          this.layerLabel.getSource().addFeature(featureLabel);
        }
        let feature = this.layer.getSource().getFeatureById("featureupload");
        if (feature && Object.keys(feature).length > 0) {
          feature.getGeometry().setCoordinates([points]);
          var ext = feature.getGeometry().getExtent();
          this.map.getView().fit(ext, {
            size: this.map.getSize(),
          });
          var center = getCenter(ext);
          this.map.getView().animate({
            center: [center[0], center[1]],
            duration: 100,
            zoom: this.map.getView().getZoom(),
          });
        } else {
          if (Object.keys(this.draw).length > 0) {
            this.map.removeInteraction(this.draw);
            this.draw = Object;
          }
          let id =
            new Date().getTime() -
            Math.floor(Math.random() * 999999999) +
            Math.floor(Math.random() * 888888888);
          this.featureDraw = new Feature({
            geometry: new Polygon([points]),
            id: id,
          });
          this.featureDraw.set("id", id);
          this.featureDraw.id_ = id;
          this.layer.getSource().addFeature(this.featureDraw);
          var ext = this.featureDraw.getGeometry().getExtent();
          this.map.getView().fit(ext, {
            size: this.map.getSize(),
          });
          var center = getCenter(ext);
          this.map.getView().animate({
            center: [center[0], center[1]],
            duration: 100,
            zoom: this.map.getView().getZoom(),
          });
          setTimeout(() => {
            if (this.featureDraw && this.layer && this.featureDraw.id_) {
              let f = this.layer
                .getSource()
                .getFeatureById(this.featureDraw.id_);
              if (f) this.layer.getSource().removeFeature(f);
            }
          }, 60);
          this.feature = new Feature({
            geometry: new Polygon([points]),
            id: "featureupload",
          });
          this.feature.set("id", "featureupload");
          this.feature.id_ = "featureupload";
          this.layer.getSource().addFeature(this.feature);

          setTimeout(() => {
            this.modifyFeature();
          }, 1000);
          this.isCoordinates = true;
        }
      } else {
        this.layer.getSource().clear();
      }
    },

    addInteraction() {
      if (Object.keys(this.draw).length > 0) {
        this.map.removeInteraction(this.draw);
        vm.draw = Object;
      }
      var vm = this;
      this.clearModify();
      let id =
        new Date().getTime() -
        Math.floor(Math.random() * 999999999) +
        Math.floor(Math.random() * 888888888);
      var vm = this;
      this.draw = new Draw({
        source: vm.layer.getSource(),
        type: "Polygon",
      });
      this.map.addInteraction(this.draw);

      let listener;
      this.draw.on("drawstart", function (evt) {
        vm.featureDraw = evt.feature;
      });

      this.draw.on("drawend", function (event) {
        if (event.feature.getGeometry().getCoordinates()[0].length >= 5) {
          event.feature.set("id", id);
          event.feature.id = id;
          event.feature.id_ = id;
          unByKey(listener); // unset sketch
          vm.map.removeInteraction(vm.draw);
          vm.draw = Object;
          setTimeout(() => {
            if (this.featureDraw && this.layer) {
              let f = this.layer
                .getSource()
                .getFeatureById(this.featureDraw.id_);
              if (f) this.layer.getSource().removeFeature(f);
            }
          }, 50);
          vm.onDrawEnd();
        } else {
          vm.$emit("message", [vm.$t("message_draw_4_points")]);
          setTimeout(() => {
            vm.layer.getSource().clear();
            vm.addInteraction();
          }, 50);
        }
      });
    },
    onDrawEnd() {
      if (this.featureDraw && Object.keys(this.featureDraw).length > 0) {
        let coors = this.featureDraw.getGeometry().getCoordinates();
        let coordinatesDraw = [];
        for (let i = 0; i < coors[0].length; i++) {
          coordinatesDraw.push(
            transform(coors[0][i], "EPSG:3857", "EPSG:4326")
          );
          if (i >= 3) break;
        }
        var centerTest = getCenter(this.featureDraw.getGeometry().getExtent());

        centerTest = transform(centerTest, "EPSG:3857", "EPSG:4326");
        let upperPoints = coordinatesDraw.filter((x) => x[1] > centerTest[1]);
        if (upperPoints && upperPoints.length > 0) {
          // Find top left
          let indexOfTopLeft = -1;
          let topleft = {
            lat: null,
            lng: null,
          };
          if (upperPoints.length === 1) {
            topleft.lat = upperPoints[0][1];
            topleft.lng = upperPoints[0][0];
          } else if (upperPoints.length == 2) {
            if (upperPoints[0][0] < upperPoints[1][0]) {
              topleft.lat = upperPoints[0][1];
              topleft.lng = upperPoints[0][0];
            } else {
              topleft.lat = upperPoints[1][1];
              topleft.lng = upperPoints[1][0];
            }
          } else if (upperPoints.length == 3) {
            let max = Math.max.apply(
              null,
              upperPoints.map((x) => x[0])
            );
            let idx = upperPoints.findIndex((x) => x[0] === max);
            if (idx >= 0) {
              upperPoints.splice(idx, 1);
              if (upperPoints[0][0] < upperPoints[1][0]) {
                topleft.lat = upperPoints[0][1];
                topleft.lng = upperPoints[0][0];
              } else {
                topleft.lat = upperPoints[1][1];
                topleft.lng = upperPoints[1][0];
              }
            }
          }
          for (let i = 0; i < coordinatesDraw.length; i++) {
            if (
              coordinatesDraw[i][0] === topleft.lng &&
              coordinatesDraw[i][1] === topleft.lat
            ) {
              indexOfTopLeft = i;
              break;
            }
          }
          let increment = 0;
          for (let i = indexOfTopLeft; i < coordinatesDraw.length; i++) {
            this.coordinates[increment].lat = coordinatesDraw[i][1];
            this.coordinates[increment].lng = coordinatesDraw[i][0];
            increment++;
          }
          for (let i = 0; i < coordinatesDraw.length - increment; i++) {
            this.coordinates[increment].lat = coordinatesDraw[i][1];
            this.coordinates[increment].lng = coordinatesDraw[i][0];
            increment++;
          }
        } else {
          for (let i = 0; i < coordinatesDraw.length; i++) {
            if (i > 3) break;
            this.coordinates[i].lat = coordinatesDraw[i][1];
            this.coordinates[i].lng = coordinatesDraw[i][0];
          }
        }
        if (this.$refs.polygonPoints) {
          this.$refs.polygonPoints.initVertices();
        }
        let points = [];
        for (let i = 0; i < this.coordinates.length; i++) {
          points.push(
            transform(
              [this.coordinates[i].lng, this.coordinates[i].lat],
              "EPSG:4326",
              "EPSG:3857"
            )
          );
          let featureLabel = new Feature({
            type: "icon",
            geometry: new Point(
              transform(
                [this.coordinates[i].lng, this.coordinates[i].lat],
                "EPSG:4326",
                "EPSG:3857"
              )
            ),
          });
          featureLabel.setStyle(
            new Style({
              text: new Text({
                textAlign: "center",
                textBaseline: "middle",
                font: "16px Roboto, Helvetica Neue, Avenir, Helvetica, Arial, sans-serif",
                fill: new Fill({ color: this.hexToRgbA("#fff", 1) }),
                stroke: new Stroke({
                  color: this.hexToRgbA("#000", 0.5),
                  width: 3,
                }),
                text: i + 1 + "",
                offsetX: 2,
                offsetY: 0,
              }),
            })
          );
          this.layerLabel.getSource().addFeature(featureLabel);
        }
        this.feature = new Feature({
          geometry: new Polygon([points]),
          id: "featureupload",
        });
        this.feature.set("id", "featureupload");
        this.feature.id_ = "featureupload";
        this.layer.getSource().addFeature(this.feature);

        setTimeout(() => {
          this.modifyFeature();
        }, 500);
        this.isCoordinates = true;
      }
    },

    modifyFeature() {
      var vm = this;
      if (!this.select || Object.keys(this.select).length == 0) {
        this.select = new Select({
          features: vm.feature
            ? new Collection([vm.feature])
            : this.layer.getSource().getFeatures(),

          style: [
            new Style({
              image: new Circle({
                radius: 4,
                fill: new Fill({
                  color: vm.hexToRgbA("#fff", 1),
                }),
                stroke: new Stroke({
                  color: vm.hexToRgbA("#FFBF23", 1),
                  width: 4,
                }),
              }),
              geometry: function (feature) {
                var coordinates = feature.getGeometry().getCoordinates()[0];
                return new MultiPoint(coordinates);
              },
            }),
            new Style({
              fill: new Fill({
                color: vm.hexToRgbA("#fab710", 0.5),
              }),
              stroke: new Stroke({
                color: "#FFBF23",
                width: 2,
                lineDash: [5, 10],
              }),
            }),
          ],
        });
        this.map.addInteraction(this.select);
      }
      if (!this.modify || Object.keys(this.modify).length == 0) {
        vm.modify = new Modify({
          features: vm.select.getFeatures(),
          insertVertexCondition: function (event) {
            return false;
          },
        });
        vm.map.addInteraction(vm.modify);
        this.modify.on("modifystart", function (evt) {});

        this.modify.on("modifyend", function (event) {
          if (
            event.features &&
            event.features.array_ &&
            event.features.array_.length > 0
          ) {
            vm.getCoordinatesFromFeature(event.features.array_[0]);
          }
        });
      }
    },
    getCoordinatesFromFeature(feature) {
      if (feature && Object.keys(feature).length > 0) {
        let coors = feature.getGeometry().getCoordinates();
        let coordinatesDraw = [];
        for (let i = 0; i < coors[0].length; i++) {
          coordinatesDraw.push(
            transform(coors[0][i], "EPSG:3857", "EPSG:4326")
          );
          if (i >= 3) break;
        }
        var centerTest = getCenter(feature.getGeometry().getExtent());

        centerTest = transform(centerTest, "EPSG:3857", "EPSG:4326");
        let upperPoints = coordinatesDraw.filter((x) => x[1] > centerTest[1]);
        if (upperPoints && upperPoints.length > 0) {
          // Find top left
          let indexOfTopLeft = -1;
          let topleft = {
            lat: null,
            lng: null,
          };
          if (upperPoints.length === 1) {
            topleft.lat = upperPoints[0][1];
            topleft.lng = upperPoints[0][0];
          } else if (upperPoints.length == 2) {
            if (upperPoints[0][0] < upperPoints[1][0]) {
              topleft.lat = upperPoints[0][1];
              topleft.lng = upperPoints[0][0];
            } else {
              topleft.lat = upperPoints[1][1];
              topleft.lng = upperPoints[1][0];
            }
          } else if (upperPoints.length == 3) {
            let max = Math.max.apply(
              null,
              upperPoints.map((x) => x[0])
            );
            let idx = upperPoints.findIndex((x) => x[0] === max);
            if (idx >= 0) {
              upperPoints.splice(idx, 1);
              if (upperPoints[0][0] < upperPoints[1][0]) {
                topleft.lat = upperPoints[0][1];
                topleft.lng = upperPoints[0][0];
              } else {
                topleft.lat = upperPoints[1][1];
                topleft.lng = upperPoints[1][0];
              }
            }
          }
          for (let i = 0; i < coordinatesDraw.length; i++) {
            if (
              coordinatesDraw[i][0] === topleft.lng &&
              coordinatesDraw[i][1] === topleft.lat
            ) {
              indexOfTopLeft = i;
              break;
            }
          }
          let increment = 0;
          for (let i = indexOfTopLeft; i < coordinatesDraw.length; i++) {
            this.coordinates[increment].lat = coordinatesDraw[i][1];
            this.coordinates[increment].lng = coordinatesDraw[i][0];
            increment++;
          }
          for (let i = 0; i < coordinatesDraw.length - increment; i++) {
            this.coordinates[increment].lat = coordinatesDraw[i][1];
            this.coordinates[increment].lng = coordinatesDraw[i][0];
            increment++;
          }
        } else {
          for (let i = 0; i < coordinatesDraw.length; i++) {
            if (i > 3) break;
            this.coordinates[i].lat = coordinatesDraw[i][1];
            this.coordinates[i].lng = coordinatesDraw[i][0];
          }
        }
        this.layerLabel.getSource().clear();
        for (let i = 0; i < this.coordinates.length; i++) {
          let featureLabel = new Feature({
            type: "icon",
            geometry: new Point(
              transform(
                [this.coordinates[i].lng, this.coordinates[i].lat],
                "EPSG:4326",
                "EPSG:3857"
              )
            ),
          });
          featureLabel.setStyle(
            new Style({
              text: new Text({
                textAlign: "center",
                textBaseline: "middle",
                font: "16px Roboto, Helvetica Neue, Avenir, Helvetica, Arial, sans-serif",
                fill: new Fill({ color: this.hexToRgbA("#fff", 1) }),
                stroke: new Stroke({
                  color: this.hexToRgbA("#000", 0.5),
                  width: 3,
                }),
                text: i + 1 + "",
                offsetX: 2,
                offsetY: 0,
              }),
            })
          );
          this.layerLabel.getSource().addFeature(featureLabel);
        }
        if (this.$refs.polygonPoints) {
          this.$refs.polygonPoints.initVertices();
        }
      }
    },
    clearModify() {
      if (Object.keys(this.modify).length > 0) {
        this.map.removeInteraction(this.modify);
        this.modify = Object;
      }
      if (Object.keys(this.select).length > 0) {
        this.map.removeInteraction(this.select);
        this.select = Object;
      }
    },
    hexToRgbA(hex, opacity) {
      if (!opacity) opacity = "0.4";
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
    },
    searchPolLayer(e) {
      this.arrayPolLayer = this.propPolLayer
        .filter((x) =>
          change_alias(x.value.trim().split(" ").join("")).startsWith(
            change_alias(e.query.toLowerCase().split(" ").join(""))
          )
        )
        .map((x) => x.value);
    },
    searchDirection(e) {
      this.arrayDirection = this.propDirection
        .filter((x) =>
          change_alias(x.value.trim().split(" ").join("")).startsWith(
            change_alias(e.query.toLowerCase().split(" ").join(""))
          )
        )
        .map((x) => x.value);
    },
    searchBeamMode(e) {
      this.arrayBeamMode = this.propBeamMode
        .filter((x) =>
          change_alias(x.value.trim().split(" ").join("")).startsWith(
            change_alias(e.query.toLowerCase().split(" ").join(""))
          )
        )
        .map((x) => x.value);
    },

    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel;
    },
    handleOpenChange() {
      this.showTimePanel = false;
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    formatUnixDateTime(val) {
      if (!val) return "";
      try {
        return moment.unix(val / 1000).format("DD/MM/YYYY HH:mm");
      } catch (error) {
        return "";
      }
    },
    async fileMetadataChanged(e) {
      this.getDataFromXml(e);
    },
    async getDataFromXml(file) {
      this.$emit("metaFileChange", file);
      this.propEntity.data_specification = null;
    },
    async filePreviewChanged(e) {
      this.propEntity.preview_file = e;
      let extension = getFileExtension3(e.name);
      if (
        ["tif", "tiff"].includes(extension ? extension.toLowerCase() : null)
      ) {
        const tiff = await GeoTIFF.fromBlob(e);
        const image = await tiff.getImage();
        let geoKeys = image.getGeoKeys();
        const data = await image.readRasters();
        const canvas = document.createElement("canvas");
        const plot = new plotty.plot({
          canvas,
          data: data[0],
          width: image.getWidth(),
          height: image.getHeight(),
          domain: [0, 256],
          colorScale: "greys",
        });
        plot.render();
        this.propEntity.preview_src = canvas.toDataURL("image/tiff");
        const bbox = image.getBoundingBox();
        if (bbox && bbox.length > 3) {
          if (
            geoKeys &&
            geoKeys.ProjectedCSTypeGeoKey &&
            geoKeys.ProjectedCSTypeGeoKey != 4326
          ) {
            let minCoordinate = convertProjection2WGS84(
              geoKeys.ProjectedCSTypeGeoKey,
              {
                x: bbox[0],
                y: bbox[1],
              }
            );
            let maxCoordinate = convertProjection2WGS84(
              geoKeys.ProjectedCSTypeGeoKey,
              {
                x: bbox[2],
                y: bbox[3],
              }
            );
            if (
              minCoordinate &&
              Object.keys(minCoordinate).length > 0 &&
              maxCoordinate &&
              Object.keys(maxCoordinate).length > 0
            ) {
              this.coordinates[0].lng = minCoordinate.x;
              this.coordinates[0].lat = maxCoordinate.y;

              this.coordinates[1].lng = maxCoordinate.x;
              this.coordinates[1].lat = maxCoordinate.y;

              this.coordinates[2].lng = maxCoordinate.x;
              this.coordinates[2].lat = minCoordinate.y;

              this.coordinates[3].lng = minCoordinate.x;
              this.coordinates[3].lat = minCoordinate.y;
              this.polygonChange();
            }
          } else {
            this.coordinates[0].lng = bbox[0];
            this.coordinates[0].lat = bbox[3];

            this.coordinates[1].lng = bbox[2];
            this.coordinates[1].lat = bbox[3];

            this.coordinates[2].lng = bbox[2];
            this.coordinates[2].lat = bbox[1];

            this.coordinates[3].lng = bbox[0];
            this.coordinates[3].lat = bbox[1];
            this.polygonChange();
          }
          if (this.$refs.polygonPoints) {
            this.$refs.polygonPoints.initVertices();
          }
        }
      } else {
        this.propEntity.preview_src = URL.createObjectURL(e);
      }
    },
    async fileIconChanged(e) {
      this.propEntity.icon_file = e;
      let extension = getFileExtension3(e.name);
      if (
        ["tif", "tiff"].includes(extension ? extension.toLowerCase() : null)
      ) {
        const tiff = await GeoTIFF.fromBlob(e);
        const image = await tiff.getImage();
        const data = await image.readRasters();
        const canvas = document.createElement("canvas");
        const plot = new plotty.plot({
          canvas,
          data: data[0],
          width: image.getWidth(),
          height: image.getHeight(),
          domain: [0, 256],
          colorScale: "greys",
        });
        plot.render();
        this.propEntity.icon_src = canvas.toDataURL("image/tiff");
      } else {
        this.propEntity.icon_src = URL.createObjectURL(e);
      }
    },
    onClose() {
      this.show = false;
      this.$emit("close");
    },
    onUpload() {
      let msg = [];
      if (!this.propEntity.category_id) {
        msg.push(this.$t("message_data_catalog_required"));
      }
      if (!this.propEntity.file_path || !this.propEntity.file_path.trim()) {
        msg.push(this.$t("message_file_path_is_required"));
      }
      if (!this.propEntity.scene_id || !this.propEntity.scene_id.trim()) {
        msg.push(this.$t("message_scene_id_required"));
      }
      if (!this.propEntity.name || !this.propEntity.name.trim()) {
        msg.push(this.$t("message_image_name_required"));
      }
      if (
        !this.propEntity.satellite_id ||
        !this.propEntity.satellite_id.trim()
      ) {
        msg.push(this.$t("message_satellite_required"));
      }
      if (!this.propEntity.imaging_date_timestamp) {
        msg.push(this.$t("message_image_time_is_required"));
      }

      if (
        this.coordinates[0].lat == undefined ||
        this.coordinates[0].lat == null ||
        this.coordinates[0].lng == undefined ||
        this.coordinates[0].lng == null ||
        this.coordinates[1].lat == undefined ||
        this.coordinates[1].lat == null ||
        this.coordinates[1].lng == undefined ||
        this.coordinates[1].lng == null ||
        this.coordinates[2].lat == undefined ||
        this.coordinates[2].lat == null ||
        this.coordinates[2].lng == undefined ||
        this.coordinates[2].lng == null ||
        this.coordinates[3].lat == undefined ||
        this.coordinates[3].lat == null ||
        this.coordinates[3].lng == undefined ||
        this.coordinates[3].lng == null
      ) {
        msg.push(this.$t("message_boundary_is_required"));
      }
      if (msg.length > 0) {
        this.$emit("message", msg);
        return;
      }
      this.propEntity.bounding_box = [];
      for (let i = 0; i < this.coordinates.length; i++) {
        this.propEntity.bounding_box.push([
          this.coordinates[i].lng,
          this.coordinates[i].lat,
        ]);
      }
      this.propEntity.bounding_box.push([
        this.coordinates[0].lng,
        this.coordinates[0].lat,
      ]);
      if (!this.propEntity.metadata) {
        this.propEntity.metadata = [];
      }
      if (this.fieldsIgnore && this.propEntity.metadata) {
        for (let i = 0; i < this.fieldsIgnore.length; i++) {
          let index = this.propEntity.metadata.findIndex(
            (x) => x.key === this.fieldsIgnore[i]
          );
          if (index >= 0) {
            switch (this.fieldsIgnore[i]) {
              case "satellite":
                this.propEntity.metadata[index].value =
                  this.propEntity.satellite_name;
                break;
              case "imaging_date":
                try {
                  this.propEntity.metadata[index].value = new Date(
                    this.propEntity.imaging_date_timestamp
                  );
                } catch (error) {}
                break;
              case "incidence_angle":
                this.propEntity.metadata[index].value =
                  this.propEntity.incidence_angle;
                this.propEntity.metadata[index].unit =
                  this.propEntity.incidence_angle_unit;
                break;
              case "resolution":
                this.propEntity.metadata[index].value =
                  this.propEntity.resolution;
                this.propEntity.metadata[index].unit =
                  this.propEntity.resolution_unit;
                break;
              case "processing_level":
                this.propEntity.metadata[index].value =
                  this.propEntity.processing_level_name;
                break;
              case "image_name":
                this.propEntity.metadata[index].value = this.propEntity.name
                  .trim()
                  .replace(/\s\s+/g, " ");
                break;
              default:
                break;
            }
          } else {
            switch (this.fieldsIgnore[i]) {
              case "incidence_angle":
                this.propEntity.metadata.push({
                  key: "incidence_angle",
                  name: "Incidence angle",
                  value: this.propEntity.incidence_angle,
                  unit: this.propEntity.incidence_angle_unit,
                });
                break;
              case "resolution":
                this.propEntity.metadata.push({
                  key: "resolution",
                  name: "Resolution",
                  value: this.propEntity.resolution,
                  unit: this.propEntity.resolution_unit,
                });
                break;
            }
          }
        }
      }
      if (this.fieldsAddNew) {
        for (let i = 0; i < this.fieldsAddNew.length; i++) {
          if (
            this.fieldsAddNew[i].name &&
            this.fieldsAddNew[i].name.trim().replace(/\s\s+/g, " ") &&
            this.fieldsAddNew[i].value &&
            this.fieldsAddNew[i].value.trim().replace(/\s\s+/g, " ")
          ) {
            this.propEntity.metadata.push(this.fieldsAddNew[i]);
          }
        }
      }
      for (let i = 0; i < this.propEntity.metadata.length; i++) {
        if (
          this.propEntity.metadata[i].value &&
          typeof this.propEntity.metadata[i].value === "string"
        )
          this.propEntity.metadata[i].value = this.propEntity.metadata[i].value
            .trim()
            .replace(/\s\s+/g, " ");
        if (this.propEntity.metadata[i].unit)
          this.propEntity.metadata[i].unit = this.propEntity.metadata[i].unit
            .trim()
            .replace(/\s\s+/g, " ");
      }
      this.$emit("onUpload");
    },
  },
};
</script>
<style lang="scss" scoped>
.Iybw1Bhj3Jhd8s {
  position: relative;
  width: 100%;
  height: 100%;
  .ehAbnmg4Jwb9BS {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 3;
  }
}
table {
  margin-bottom: 0;
  td {
    border-top: none;
    p {
      background: #f0f0f0;
      font-size: 14px;
      padding: 0.5rem;
    }
    .map-preview {
      position: relative;
    }
  }
}
table.table-images {
  p {
    margin: 0 !important;
  }
}
.preview {
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: white;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    &:hover {
      cursor: pointer;
    }
  }
}
.bg-A1A5B7 {
  background: #a1a5b7;
  color: white;
}
.bg-009EF7 {
  background: #009ef7;
  color: white;
}
.bg-red {
  background: red;
  height: 36px;
  line-height: 35px;
}
.border-radius-4 {
  border-radius: 4px;
}
.line-height-36 {
  line-height: 36px;
}
.line-height-56 {
  line-height: 56px;
}
table.table-metadata {
  border-collapse: separate;
  border-spacing: 0 0.125rem;
  tr {
    margin-bottom: 0.25rem;
    td {
      padding: 0.5rem 0.75rem;
      border: 1px solid white;
      &:first-child {
        color: white;
        border-radius: 0.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}
.upload-map {
  width: 100%;
  height: 608px;
  border: 1px solid #eee;
  position: relative;
  #mapBoundingBox {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .button-clear-features {
    position: absolute;
    bottom: 0.25rem;
    right: 0.25rem;
    font-size: 12px;
    color: white;
    background: #d93737;
    &:hover {
      background: rgba($color: #d93737, $alpha: 0.8);
    }
  }
}
</style>
<style lang="scss" >
.upload-metadata {
  .custom-file-label::after {
    background: #009ef7 !important;
    color: white;
  }
  select {
    background: #eef3f7 !important;
    border-radius: 4px;
    border-color: #eef3f7 !important;
    option {
      background: white !important;
    }
  }
}
</style>