import axios from 'axios';
import basicApi from "@/utils/apis/basic";
import app from './../../main'
import { userServiceApiURL } from "@/utils/commons/environment"

// var qs = require('qs');
export default {
    async search(params,searchDto,access_token) {
      let headers = basicApi.user.search.headers;
      headers["Authorization"] = "bearer " + access_token;
      headers['Accept-Language'] = app.$store.getters.getLang;
      if (!params) params = {}
      params.lang = app.$store.getters.getLang;
      const object = axios({
        method: basicApi.user.search.method,
        url: userServiceApiURL + basicApi.user.search.url,
        headers:headers,
        params: params,
        data:searchDto
      });
      let response = await object;
      return response;
    },
    async getAll(access_token){
        const object = axios({
            method:basicApi.user.get_all.method,
            url: userServiceApiURL + basicApi.user.get_all.url,
            params: {
                page:0,
                size: 2147483647,
                active: true,
                lang : app.$store.getters.getLang
            },
            headers: {
                "Authorization": "bearer " + access_token,
                "Content-Type": "application/json",
                'Accept-Language':app.$store.getters.getLang
            },
        });

        let response = await object;
        return response;
    },
    async getAllWithParams(params, access_token) {
        if (!params) params = {};
        params.lang = app.$store.getters.getLang;
        const object = axios({
            method:basicApi.user.get_all.method,
            url: userServiceApiURL + basicApi.user.get_all.url,
            params: params,
            headers: {
                "Authorization": "bearer " + access_token,
                "Content-Type": "application/json",
                'Accept-Language':app.$store.getters.getLang
            },
        });
        let response = await object;
        return response;
    },


    async create(entity, access_token) {
        let headers = basicApi.user.create.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.user.create.method,
            url:  userServiceApiURL + basicApi.user.create.url,
            data: entity,
            headers: headers,
            params: {
                lang : app.$store.getters.getLang
            }
        });
        let response = await object;
        return response;
      },
    
    async update(entity, access_token) {
        let headers = basicApi.user.update.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.user.update.method,
            url: userServiceApiURL + basicApi.user.update.url,
            data: entity,
            headers:headers,
            params: {
                lang : app.$store.getters.getLang
            }
        });
        let response = await object;
        return response;
    },

    async delete(id, access_token) {
        let headers = basicApi.user.delete.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.user.delete.method,
            url: userServiceApiURL + basicApi.user.delete.url.replace("{id}",id),
            headers:headers,
            params: {
                lang : app.$store.getters.getLang
            }
        });
        let response = await object;
        return response;
    },
    async getById(userId, access_token) {
        const object = axios({
            method: basicApi.user.get_by_id.method,
            url: userServiceApiURL + basicApi.user.get_by_id.url.replace("{id}",userId),
            headers: {
                "Authorization": "bearer " + access_token,
                "Content-Type": "application/json",
                'Accept-Language':app.$store.getters.getLang
            },
            params: {
                lang : app.$store.getters.getLang
            }
        });

        let response = await object;
        return response;
    },

    async getSelfInfo(access_token) {
        const object = axios({
            method: 'get',
            url: system.endPoint + system.config.user.self,
            headers: {
                "Authorization": "bearer " + access_token,
                "Access-Control-Allow-Origin": "*",
                'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization",
                    // "Content-Type": "application/json",
                'Accept-Language':app.$store.getters.getLang
            },
            mode: 'no-cors',
            withCredentials: true,
            crossdomain: true,
            params: {
                lang : app.$store.getters.getLang
            }
        });

        let response = await object;
        return response;
    },
    async getUserByUserKey(userKey,access_token) {
        const object = axios({
            method: basicApi.user.get_user_by_user_key.method,
            url: userServiceApiURL + basicApi.user.get_user_by_user_key.url,     
            headers: {
                Authorization: "bearer " + access_token,
                'Accept-Language':app.$store.getters.getLang
            },
            params:{
                userKey: userKey,
                lang : app.$store.getters.getLang
            }
        });
        let response = await object;
        return response;
    },
    async getUserByUserName(username,access_token) {
        const object = axios({
            method: basicApi.user.get_user_by_user_name.method,
            url: userServiceApiURL + basicApi.user.get_user_by_user_name.url,     
            headers: {
                Authorization: "bearer " + access_token,
                'Accept-Language':app.$store.getters.getLang
            },
            params:{
                username: username,
                lang : app.$store.getters.getLang
            }
        });
        let response = await object;
        return response;
    },
    
    async deactive(id,access_token) {
        const object = axios({
            method: basicApi.user.deactive.method,
            url: userServiceApiURL + basicApi.user.deactive.url.replace("{id}",id),     
            headers: {
                Authorization: "bearer " + access_token,
                "Content-Type": "application/json",
                'Accept-Language':app.$store.getters.getLang
            },
            params: {
                lang : app.$store.getters.getLang
            }
        });
        let response = await object;
        return response;
    },
    async active(id,access_token) {
        const object = axios({
            method: basicApi.user.active.method,
            url: userServiceApiURL + basicApi.user.active.url.replace("{id}",id),     
            headers: {
                Authorization: "bearer " + access_token,
                "Content-Type": "application/json",
                'Accept-Language':app.$store.getters.getLang
            },
            params: {
                lang : app.$store.getters.getLang
            }
        });
        let response = await object;
        return response;
    },
    
    async addRole(user_id, role_id,access_token) {
        const object = axios({
            method: basicApi.user.add_role.method,
            url: userServiceApiURL + basicApi.user.add_role.url,     
            headers: {
                Authorization: "bearer " + access_token,
                "Content-Type": "application/json",
                'Accept-Language':app.$store.getters.getLang
            },
            params: {
                user_id: user_id,
                role_id: role_id,
                lang : app.$store.getters.getLang
            }
        });
        let response = await object;
        return response;
    }
}