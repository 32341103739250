<template>
  <CommonAccordion :propTitle="$t('title_statistic_images')">
    <div slot="content">
      <div class="datatable">
        <DataTable
          :value="items"
          rowGroupMode="subheader"
          groupRowsBy="provider"
          sortMode="single"
          sortField="provider"
          :sortOrder="1"
        >
          <template #empty v-if="items && items.length === 0">
            <span class="text-center">{{ $t("label_no_data") }}</span>
          </template>
          <Column field="provider" header="" v-if="items.length"></Column>
          <Column
            header="No"
            headerStyle="width: 3rem; text-align: center!important"
            bodyStyle="text-align: center;"
          >
            <template #body="slotProps">
              {{ slotProps.index + 1 }}
            </template></Column
          >
          <Column field="satellite" :header="$t('label_satellite')"></Column>
          <Column
            field="total"
            :header="$t('label_amount')"
            headerStyle="width: 8rem"
          ></Column>

          <template #groupheader="slotProps">
            <span class="image-text"
              ><strong>{{ slotProps.data.provider }}</strong
              >&nbsp;({{ $t("label_total_images_2") }}:&nbsp;{{
                calculateGroupTotal(slotProps.data.provider)
              }})
            </span>
          </template>
        </DataTable>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import Button from "@/components/commons/button/Button";
import ContextMenu from "@/components/commons/contextmenu/ContextMenu";
import moment from "moment";
export default {
  components: { CommonAccordion, DataTable, Column, Button, ContextMenu },
  props: {
    propItems: {
      type: Array,
      default: () => [],
    },
    propProvider: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      items: [],
    };
  },
  watch: {
    propItems: {
      handler(val) {
        this.items = [];
        if (val && val.length > 0) {
          this.initData(this.propItems);
        }
      },
      immediate: true,
    },
  },
  methods: {
    calculateGroupTotal(name) {
      let total = 0;
      if (this.items) {
        for (let item of this.items) {
          if (item.provider === name) {
            total = total + item.total;
          }
        }
      }
      return total;
    },
    initData(data) {
      if (data && data.length > 0) {
        if (this.propProvider && this.propProvider.length > 0) {
          for (let i = 0; i < this.propProvider.length; i++) {
            if (
              this.propProvider[i].sub_meta_key &&
              this.propProvider[i].sub_meta_key.length > 0
            ) {
              for (
                let j = 0;
                j < this.propProvider[i].sub_meta_key.length;
                j++
              ) {
                let index = data.findIndex(
                  (x) =>
                    x.satellite &&
                    x.satellite.name ===
                      this.propProvider[i].sub_meta_key[j].name
                );
                if (index >= 0) {
                  this.items.push({
                    provider: this.propProvider[i].value,
                    satellite: this.propProvider[i].sub_meta_key[j].value,
                    total: data[index].total,
                    satellite_id: this.propProvider[i].sub_meta_key[j].id,
                    satellite_name: this.propProvider[i].sub_meta_key[j].name,
                    provider_id: this.propProvider[i].id,
                    provider__name: this.propProvider[i].name,
                    items: [],
                  });
                }
              }
            }
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
<style lang="scss">
.general-report__datatable {
  .datatable {
    @import "@/assets/scss/_datatable.scss";
  }
}
</style>