import { render, staticRenderFns } from "./GeneralReport.vue?vue&type=template&id=95e1d9e2&scoped=true&"
import script from "./GeneralReport.vue?vue&type=script&lang=js&"
export * from "./GeneralReport.vue?vue&type=script&lang=js&"
import style0 from "./GeneralReport.vue?vue&type=style&index=0&id=95e1d9e2&lang=scss&scoped=true&"
import style1 from "./GeneralReport.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95e1d9e2",
  null
  
)

export default component.exports