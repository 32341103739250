var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-layout',{attrs:{"isToolbar":false,"isBreadcrumb":true,"breadcrumbitems":[
    { label: _vm.$t('menu_administration') },
    { label: _vm.$t('menu_management_log') } ],"propButtonsState":{
    save: {
      visible: false,
    },
    add: {
      visible: false,
    },
    edit: {
      visible: false,
    },
    reload: _vm.buttons.reload,
    print: {
      visible: false,
    },
  },"propTitle":_vm.$t('menu_management_log')},on:{"emitFunction":function($event){return _vm.menuBarFunction($event)}}},[_c('div',{staticClass:"logs-content"},[_c('div',{staticClass:"logs__dialog"},[(_vm.message.length > 0)?_c('DialogMessage',{attrs:{"propTitle":_vm.$t('title_message_info'),"prop-show":_vm.message.length > 0,"prop-message":_vm.message},on:{"close":function($event){_vm.message = []}}}):_vm._e()],1),_c('div',{staticClass:"logs__search",attrs:{"id":"elementSearch"}},[_c('LogsFilter',{ref:"logsearch",attrs:{"propSearch":_vm.logs.search,"prop-buttons":{
          filter: _vm.buttons.filter,
        },"prop-user":_vm.user.items},on:{"onSearch":_vm.onSearch,"message":function($event){_vm.message = $event},"accordionHeaderClick":_vm.accordionHeaderClick}})],1),_c('div',{staticClass:"logs__datatable"},[_c('LogsDataTable',{attrs:{"prop-items":_vm.logs.items,"propTableHeight":_vm.logs.table.height,"prop-total":_vm.logs.total_records,"prop-loading":_vm.logs.loading},on:{"nextPage":_vm.nextPage}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }