<template>
  <div class="d-flex">
    <div class="custom-file">
      <input
        type="file"
        class="custom-file-input"
        :accept="accept"
        :multiple="multiple"
        @change="fileChange"
        :disabled="disabled"
        :title="fileName"
      />
      <label class="custom-file-label" for="customFile">{{ fileName }}</label>
    </div>
    <button
      type="button"
      class="btn btn-sm btn-show"
      :disabled="disabled"
      v-if="isButtonShow"
      @click="showFile"
    >
      <i class="fas fa-link m-1"></i>
    </button>
  </div>
</template>
<script>
import Button from "@/components/commons/button/Button";
export default {
  components: { Button },
  props: {
    accept: {
      type: String,
      default: ".pdf",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    isButtonShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileName: null,
    };
  },
  created() {
    this.fileName = this.name;
  },
  watch: {
    name: function (val) {
      this.fileName = val;
    },
  },
  methods: {
    fileChange(e) {
      var vm = this;
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      else {
        this.fileName = files[0].fileName;
        if (!this.multiple) {
          this.fileName = files[0].name;
          this.$emit("fileChange", files[0]);
        } else {
          this.fileName = files;
          this.$emit("fileChange", files);
        }
      }
    },
    showFile() {
      this.$emit("showFile");
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-show {
  background: rgba(83, 83, 83, 0.2);
  color: black;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 0;
  border-left: 1px solid;
  border-color: rgba(83, 83, 83, 0.2);
  &:disabled {
    background: rgba(83, 83, 83, 0.45);
    &:hover {
      cursor: default;
    }
  }
}
</style>
<style lang="scss">
.custom-file {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.custom-file-input {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.custom-file-label {
  width: calc(100%);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.custom-file-label::after {
  content: url("/img/icons/attach16x16.png") !important; //"\f0c6" !important;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  background-size: auto;
  background-position: center;
}
</style>
