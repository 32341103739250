<template>
  <CommonAccordion :propTitle="$t('title_search')">
    <div slot="content">
      <div class="form-search">
        <div class="form-row no-gutters">
          <div class="col col-input-search">
            <div class="form-group">
              <label for="inputSearchName">{{ $t("label_full_name") }}</label>
              <InputText
                id="inputSearchName"
                style="width: 100%"
                type="search"
                maxLength="150"
                v-model="search.name"
                @click="inputClick"
                class="new-style"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <label>{{ $t("label_user_role") }}</label
            >&nbsp;
            <Dropdown
              class="w-100 new-style"
              v-model="search.role_id"
              :showClear="true"
              :options="propRoles"
              optionValue="id"
              optionLabel="description"
            />
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <label>{{ $t("label_status") }}</label
            >&nbsp;
            <Dropdown
              class="w-100 new-style"
              v-model="search.is_active"
              :showClear="false"
              :options="status"
              optionValue="id"
              optionLabel="label"
            />
          </div>
          <div class="col">
            <button
              @click="onSearch"
              :disabled="propButtons.filter.disabled"
              type="button"
              :label="$t('button_text_search')"
              :icon="
                propButtons.filter.processing
                  ? 'fas fa-spinner fa-spin'
                  : 'fas fa-search'
              "
              class="btn btn-geostore2 btn-sm btn-w120-h36"
            >
              <i
                :class="
                  propButtons.filter.processing
                    ? 'fas fa-spinner fa-spin'
                    : 'fas fa-search'
                "
              ></i
              >&nbsp;
              {{ $t("button_text_search") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import MultiSelect from "@/components/commons/multiselect/MultiSelect";
import InputText from "@/components/commons/inputtext/InputText";
import Button from "@/components/commons/button/Button";
import Checkbox from "@/components/commons/checkbox/Checkbox";
import Treeselect from "@riophae/vue-treeselect";
export default {
  props: {
    propButtons: {
      type: Object,
      default: () => {},
    },
    propRoles: {
      type: Array,
      default: () => [],
    },
    propSearch: {
      type: Object,
      default: () => {
        return {
          name: null,
          role_id: null,
          is_active: null,
        };
      },
    },
  },
  components: {
    CommonAccordion,
    Dropdown,
    MultiSelect,
    InputText,
    Button,
    Checkbox,
    Treeselect,
  },
  data() {
    return {
      status: [
        {
          id: "1",
          code: "label_active",
          label: "",
        },
        {
          id: "0",
          code: "label_inactive",
          label: "",
        },
        {
          id: "2",
          code: "label_all",
          label: "",
        },
      ],
      search: {
        text: null,
        name: null,
        is_active: null,
        role_id: null,
      },
    };
  },
  created() {
    this.initStatus();
    this.search.name =
      this.propSearch && this.propSearch.name ? this.propSearch.name : null;
    this.search.role_id =
      this.propSearch && this.propSearch.role_id
        ? this.propSearch.role_id
        : null;
    this.search.is_active =
      (this.propSearch && this.propSearch.is_active != null) ||
      this.propSearch.is_active != undefined
        ? this.propSearch.name
        : null;
  },
  watch: {
    "propSearch.text": {
      handler(val) {
        this.search.text = val;
      },
      immediate: true,
    },
    lang(val) {
      this.initStatus();
    },
  },
  methods: {
    inputClick(e) {
      try {
        //e.target.select();
      } catch (error) {}
    },
    refreshSearchObject() {
      this.search.name = null;
      this.search.is_active = null;
      this.search.role_id = null;
    },
    onSearch() {
      this.$emit("onSearch", this.search);
    },
    initStatus() {
      for (let i = 0; i < this.status.length; i++) {
        this.status[i].label = this.$t(this.status[i].code);
      }
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
.form-search {
  button {
    margin-top: 1.25rem;
    font-size: 14px;
  }
  label {
    margin: 0;
    padding: 0;
    padding-left: 0.25rem;
  }
}
.form-group {
  margin-bottom: 0.5rem;
  margin: 0;
  label {
    padding-left: 0.25rem;
  }
}
</style>

