import Vue from 'vue'
import Vuex from 'vuex'
import common from "./modules/common"
import token from "./modules/token"
Vue.use(Vuex)

export const store =  new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    common,
    token
  }
})
