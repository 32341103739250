<template>
  <div class="p-checkbox p-component" @click="onClick">
    <div class="p-hidden-accessible">
      <input
        :ref="'input' + id"
        type="checkbox"
        :checked="checked"
        @focus="onFocus($event)"
        @blur="onBlur($event)"
        :disabled="disabled"
      />
    </div>
    <div
      ref="box"
      :class="[
        'p-checkbox-box p-component',
        {
          'p-highlight': checked,
          'p-disabled': $attrs.disabled,
          'p-focus': focused,
        },
      ]"
      role="checkbox"
      :aria-checked="checked"
    >
      <span :class="['p-checkbox-icon', { 'pi pi-check': checked }]"></span>
    </div>
  </div>
</template>

<script>
import UniqueComponentId from "@/utils/commons/primevue/UniqueComponentId";
export default {
  inheritAttrs: false,
  props: {
    disabled: null,
    checked: null,
  },
  data() {
    return {
      focused: false,
      id: "hchk" + UniqueComponentId(),
    };
  },
  methods: {
    onClick(event) {
      if (!this.disabled) {
        this.$emit("change", event);
        if (this.$refs["input" + this.id])
          this.$refs["input" + this.id].focus();
      }
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
  },
};
</script>