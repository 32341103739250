var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CommonAccordion',{attrs:{"propTitle":_vm.$t('header_file_list')}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"datatable"},[_c('DataTable',{attrs:{"value":_vm.propItems,"rowGroupMode":"subheader","groupRowsBy":"date_str","sortMode":"single","scrollHeight":_vm.propTable.height - 40 - 38 + 'px',"sortOrder":1,"resizableColumns":false,"dataKey":"id","virtualScroll":true,"scrollable":true,"loading":_vm.propLoading},on:{"virtual-scroll":_vm.onVirtualScroll},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"text-left"},[_c('div',[(_vm.propItems.length)?_c('div',{staticClass:"font-weight-normal"},[_vm._v(" "+_vm._s(_vm.$t("label_show_from_to", null, { from: 1, to: _vm.propItems.length, total: _vm.propTotal, }))+" ")]):_vm._e()])])]},proxy:true},(_vm.propItems && _vm.propItems.length === 0)?{key:"empty",fn:function(){return [_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("label_no_data")))])]},proxy:true}:null,{key:"groupheader",fn:function(slotProps){return [_c('span',{staticClass:"image-text"},[_c('strong',[_vm._v(_vm._s(slotProps.data.date_str))]),_vm._v(" ("+_vm._s(_vm.$t("lable_files_total"))+": "+_vm._s(_vm.calculateGroupTotal(slotProps.data.date_str))+") ")])]}}],null,true)},[_c('Column',{attrs:{"header":"No","headerStyle":"width: 3rem; text-align: center!important","bodyStyle":"width: 3rem;text-align: center;"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.index + 1)+" ")]}}])}),_c('Column',{attrs:{"header":"Metadata","headerStyle":"width: 8rem","bodyStyle":"width: 8rem"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('div',{staticClass:"oval",class:_vm.columnClass(slotProps.data),attrs:{"title":!slotProps.data.data.is_metadata &&
                slotProps.data.message &&
                slotProps.data.message.length > 0
                  ? slotProps.data.message.join(',')
                  : ''}},[_c('span',[_vm._v(_vm._s(slotProps.data.data.is_metadata ? _vm.$t("label_completed") : _vm.$t("label_incompleted")))])])]}}])}),_c('Column',{attrs:{"header":_vm.$t('label_username'),"headerStyle":"width: 8rem","bodyStyle":"width: 8rem"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.data.created_by)+" ")]}}])}),_c('Column',{attrs:{"header":_vm.$t('lable_date_time'),"headerStyle":"width: 10rem","bodyStyle":"width: 10rem","sortable":true},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_vm._v(" "+_vm._s(_vm.formatUnixDateTime2String(slotProps.data.created_at))+" ")]}}])}),_c('Column',{attrs:{"header":_vm.$t('label_file_name'),"headerStyle":"width: 25rem","bodyStyle":"width: 25rem"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('span',{attrs:{"title":slotProps.data.data &&
                slotProps.data.data.file_name &&
                slotProps.data.data.file_name.length > 48
                  ? slotProps.data.data.file_name
                  : ''}},[_vm._v(_vm._s(slotProps.data.data && slotProps.data.data.file_name && slotProps.data.data.file_name.length > 48 ? slotProps.data.data.file_name.substring(0, 44) + "..." : slotProps.data.data.file_name))])]}}])}),_c('Column',{attrs:{"header":_vm.$t('label_path'),"headerStyle":"width: 25rem","bodyStyle":"width: 25rem"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('span',{attrs:{"title":slotProps.data.data &&
                slotProps.data.data.path &&
                slotProps.data.data.path.length > 48
                  ? slotProps.data.data.path
                  : ''}},[_vm._v(_vm._s(slotProps.data.data && slotProps.data.data.path && slotProps.data.data.path.length > 48 ? slotProps.data.data.path.substring(0, 44) + "..." : slotProps.data.data.path))])]}}])}),_c('Column',{attrs:{"field":"file_size","header":_vm.$t('label_file_size'),"headerStyle":"width: 7rem","bodyStyle":"width: 7rem"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.data.data.size ? _vm.formatSizeFile(slotProps.data.data.size) : "")+" ")]}}])}),_c('Column',{attrs:{"headerStyle":"width: 6.25rem; text-align: center!important","bodyStyle":"width: 6.25rem;","headerClass":'p-frozen-column right',"bodyClass":'p-frozen-column right'},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(slotProps.data.data.is_metadata)?_c('Button',{staticClass:"p-button-info p-button-sm",attrs:{"icon":"fas fa-info-circle","type":"button","title":_vm.$t('label_metadata')},on:{"click":function($event){return _vm.shoDialogMetadata(slotProps.data)}}}):_vm._e(),(
                !slotProps.data.data.is_metadata &&
                _vm.$store.getters.getPermissions.includes('DETECT_METADATA')
              )?_c('Button',{staticClass:"p-button-sm p-button-primary",attrs:{"icon":slotProps.data.processing
                  ? 'fas fa-spinner fa-spin'
                  : 'fas fa-clipboard-list',"type":"button","title":_vm.$t('button_text_read_metadata_again'),"disabled":slotProps.data.processing},on:{"click":function($event){return _vm.getMetadata(slotProps.data)}}}):_vm._e(),(
                !slotProps.data.data.is_metadata &&
                _vm.$store.getters.getPermissions.includes('IMAGE_ADD_IMAGE')
              )?_c('Button',{staticClass:"p-button-sm button-additional-information ml-1",attrs:{"icon":'fas fa-list-alt',"type":"button","title":_vm.$t('label_incompleted'),"disabled":slotProps.data.processing},on:{"click":function($event){return _vm.showDialogAdditionalInformation(slotProps.data)}}}):_vm._e()]}}])})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }