import axios from 'axios';
import basicApi from "@/utils/apis/basic";
import app from './../../main'
import { geoServiceApiURL } from "@/utils/commons/environment"
export default {
    async getList(params,access_token) {
      let headers = basicApi.meta_key.get_list.headers;
      headers["Authorization"] = "bearer " + access_token;
      headers['Accept-Language'] = app.$store.getters.getLang;
        headers["Access-Control-Allow-Credentials"] = true;
        headers["Access-Control-Allow-Headers"] = "*";
      if (!params) params = {};
      params.lang = app.$store.getters.getLang;
      const object = axios({
        method: basicApi.meta_key.get_list.method,
        url: geoServiceApiURL + basicApi.meta_key.get_list.url,
        headers:headers,
        params:params
      });
      let response = await object;
      return response;
    },
    async getAll(access_token) {
        let headers = basicApi.meta_key.get_list.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        headers["Access-Control-Allow-Credentials"] = true;
        headers["Access-Control-Allow-Headers"] = "*";
        const object = axios({
          method: basicApi.meta_key.get_list.method,
          url: geoServiceApiURL + basicApi.meta_key.get_list.url,
          headers:headers,
          params:{
            page:0,
            size: 2147483647,
            lang : app.$store.getters.getLang
          }
        });
        let response = await object;
        return response;
    },
    async getByKey(key,access_token) {
        let headers = basicApi.meta_key.get_by_key.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers["Access-Control-Allow-Credentials"] = true;
        headers["Access-Control-Allow-Headers"] = "*";
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
          method: basicApi.meta_key.get_by_key.method,
          url: geoServiceApiURL + basicApi.meta_key.get_by_key.url.replace("{key}",key),
          headers:headers,
          params:{
            key:key,
            //lang : app.$store.getters.getLang
          }
        });
        let response = await object;
        return response;
    },
    async create(entity,access_token){
        let headers = basicApi.meta_key.create.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
          method: basicApi.meta_key.create.method,
          url:  geoServiceApiURL + basicApi.meta_key.create.url,
          data: entity,
          headers:headers,
          params: {
            lang : app.$store.getters.getLang
          }
        });
        let response = await object;
        return response;
    },
    async update(entity, access_token) {
      let headers = basicApi.meta_key.update.headers;
      headers["Authorization"] = "bearer " + access_token;
      headers['Accept-Language'] = app.$store.getters.getLang;
      const object = axios({
        method: basicApi.meta_key.update.method,
        url: geoServiceApiURL + basicApi.meta_key.update.url.replace("{id}",entity.id),
        data: entity,
        headers:headers,
        params: {
          lang : app.$store.getters.getLang
        }
      });
      let response = await object;
      return response;
    },
  
    async delete(id, access_token) {
      let headers = basicApi.meta_key.delete.headers;
      headers["Authorization"] = "bearer " + access_token;
      headers['Accept-Language'] = app.$store.getters.getLang;
      const object = axios({
        method: basicApi.meta_key.delete.method,
        url: geoServiceApiURL + basicApi.meta_key.delete.url.replace("{id}",id),
        headers:headers,
        params: {
          lang : app.$store.getters.getLang
        }
      });
      let response = await object;
      return response;
    },
}