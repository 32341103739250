<template>
  <CommonAccordion
    :propTitle="$t('title_search')"
    @accordionHeaderClick="accordionHeaderClick"
  >
    <div slot="content">
      <div class="form-search">
        <div class="form-row no-gutters">
          <div class="col-4 col-image-name">
            <div class="form-group">
              <label for="inputSearchName">{{ $t("label_image_name") }}</label>
              <InputText
                id="inputSearchName"
                style="width: 100%"
                type="search"
                v-model="search.text"
                maxlength="255"
                class="new-style form-control w-100"
              />
            </div>
          </div>
          <div class="col-4 col-satellites-name">
            <div class="form-group">
              <label class="control-label">{{ $t("label_satellite") }}</label>
              <treeselect
                class="new-style"
                v-model="search.satellite_ids"
                name="treeSatellite"
                :multiple="true"
                :options="propProvider"
                :noChildrenText="$t('label_no_sub_options')"
                :noOptionsText="$t('label_no_options_text')"
                :noResultsText="$t('label_no_results_text')"
                :disableBranchNodes="true"
                placeholder=""
              >
                <label slot="option-label" slot-scope="{ node }">{{
                  node.raw.label
                }}</label>
                <div slot="value-label" slot-scope="{ node }">
                  {{ node.raw.label }}
                </div>
              </treeselect>
            </div>
          </div>
          <div class="col-4 col-buttons">
            <button
              type="button"
              :label="$t('button_text_search')"
              icon="pi pi-search"
              class="btn btn-geostore2 btn-sm btn-w120-h36"
              @click="onSearch"
              :disabled="propButton && propButton.disabled"
            >
              <i
                :class="
                  propButton && propButton.processing
                    ? 'fas fa-spinner fa-spin'
                    : 'pi pi-search'
                "
              ></i
              >&nbsp;
              {{ $t("button_text_search") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import MultiSelect from "@/components/commons/multiselect/MultiSelect";
import InputText from "@/components/commons/inputtext/InputText";
import Button from "@/components/commons/button/Button";
import Checkbox from "@/components/commons/checkbox/Checkbox";
import Treeselect from "@/components/commons/vue-treeselect/src/components/Treeselect";
export default {
  props: {
    propProvider: {
      type: Array,
      default: () => [],
    },
    propSearch: {
      type: Object,
      default: () => {
        return {
          text: null,
          satellite_ids: null,
        };
      },
    },
    propButton: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    CommonAccordion,
    Dropdown,
    Treeselect,
    Button,
    InputText,
    Checkbox,
    MultiSelect,
  },
  data() {
    return {
      normalizer(node) {
        return {
          id: node.code,
        };
      },
      search: {
        satellite_ids: null,
        text: null,
      },
    };
  },
  created() {
    this.objSearch = Object.assign({}, this.propSearch);
  },
  methods: {
    accordionHeaderClick() {
      this.$emit("accordionHeaderClick");
    },
    clearText() {
      this.propSearch.name = "";
    },
    onSearch() {
      this.$emit("onSearch", this.search);
    },
  },
};
</script>
<style lang="scss" scoped>
.form-search {
  padding: 0.25rem;
  button {
    margin-top: 1rem;
    font-size: 14px;
  }
  .col-image-name {
    padding: 0;
    padding-right: 0.25rem;
  }
  .col-satellites-name {
    padding: 0;
    padding-left: 0.25rem;
  }
  .col-buttons {
    padding: 0;
    padding-left: 0.5rem;
    padding-top: 0.275rem;
  }
}
.form-group {
  margin-bottom: 0.5rem;
  margin: 0;
  label {
    margin: 0;
    padding: 0;
    padding-left: 0.25rem;
  }
}
</style>
<style lang="scss">
.satellite-image__search {
  .form-search {
    .vue-treeselect__control {
      input {
        height: auto !important;
      }
    }
  }
}
</style>