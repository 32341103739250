import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";

import VueAxios from "vue-axios";
import axios from "axios";
Vue.use(VueAxios, axios);

import "./utils/commons/vee-validate.js";
import CxltToastr from "../public/js/toastr/cxlt-vue2-toastr";
import toastrcss from "../public/css/toastr/cxlt-vue2-toastr.css";
Vue.use(CxltToastr, {
  position: "bottom right",
  timeOut: "5000",
  progressBar: false,
  closeButton: true,
});

import i18n from "./lang/lang";

import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/vi";
import "vue2-datepicker/locale/en";

import "../public/css/customize/primevue.min.css";
import "../public/css/customize/saga-blue/theme.css";
import "../public/css/customize/nova-light/theme.css";
import "../public/css/primeicons/primeicons.css";

import "vue-nav-tabs/dist/vue-tabs.min.css";

import { ValidationObserver, ValidationProvider } from "vee-validate";

// Install components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.filter("formatSizeFile", function(bytes, si = false) {
  if (!bytes) return null;
  var thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }
  var units = si
    ? ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  var u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + "" + units[u];
});

import moment from "moment";
Vue.filter("formatUnix", function(value) {
  if (value) {
    return moment.unix(value).format("DD/MM/YYYY");
  } else return "";
});

Vue.filter("formatUnixDateTime", function(value) {
  if (value) {
    return moment.unix(value).format("HH:mm DD/MM/YYYY");
  }
});
Vue.filter("formatUnixDateTime2String", function(value) {
  let result = "";
  if (value) {
    result = moment.unix(value).format("HH:mm DD/MM/YYYY");
    result = result.replace(":", "h").replace(" ", " ngày ");
  }
  return result;
});

Vue.config.productionTip = false;

const app = new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
window.Event = app;
export default app;
