<template>
  <div>
    <attach-file
      accept=".zip"
      @fileChange="fileChanged"
      :name="propName"
    ></attach-file>
  </div>
</template>
<script>
import AttachFile from "@/components/commons/upload/AttachFile";
export default {
  components: {
    AttachFile,
  },
  props: {
    propName: {
      type: String,
      default: "",
    },
  },
  methods: {
    fileChanged(e) {
      this.$emit("fileChanged", e);
    },
  },
};
</script>