const interfaces = [
  {
    id: 100,
    code: "100",
    label: "label_dashboard",
    group_label: "label_dashboard",
    path: "/",
  },
  {
    id: 1,
    code: "01",
    label: "menu_data_list",
    group_label: "menu_data_management",
    path: "/satellite-image",
  },
  {
    id: 2,
    code: "02",
    label: "menu_data_upload",
    group_label: "menu_data_management",
    path: "/upload",
  },
  {
    id: 53,
    code: "53",
    label: "menu_data_upload_folder",
    group_label: "menu_data_management",
    path: "/upload-folder",
  },
  {
    id: 3,
    code: "03",
    label: "menu_upload_history",
    group_label: "menu_data_management",
    path: "/upload-history",
  },
  {
    id: 50,
    code: "50",
    label: "menu_order_tasking",
    group_label: "menu_order_management",
    path: "/order-tasking",
  },

  {
    id: 4,
    code: "04",
    label: "menu_order_list",
    group_label: "menu_order_management",
    path: "/order",
  },
  {
    id: 49,
    code: "49",
    label: "menu_order_detail",
    group_label: "menu_order_management",
    path: "/order/:id",
  },
  {
    id: 5,
    code: "05",
    label: "menu_sharing_list",
    group_label: "menu_share_management",
    path: "/sharing",
  },
  {
    id: 6,
    code: "06",
    label: "menu_my_shared",
    group_label: "menu_share_management",
    path: "/shared-by-me",
  },

  {
    id: 8,
    code: "08",
    label: "menu_statistic_general",
    group_label: "menu_statistic_report",
    path: "/statistical-report/general",
  },
  {
    id: 9,
    code: "09",
    label: "menu_statistic_upload",
    group_label: "menu_statistic_report",
    path: "/statistical-report/upload",
  },
  {
    id: 10,
    code: "10",
    label: "menu_statistic_download",
    group_label: "menu_statistic_report",
    path: "/statistical-report/download",
  },
  {
    id: 12,
    code: "12",
    label: "menu_customer",
    group_label: "menu_project_and_customer",
    path: "/customer",
  },
  {
    id: 13,
    code: "13",
    label: "menu_management_user",
    group_label: "menu_administration",
    path: "/user",
  },
  {
    id: 14,
    code: "14",
    label: "menu_management_role_user",
    group_label: "menu_administration",
    path: "/role",
  },

  {
    id: 15,
    code: "15",
    label: "menu_management_permission",
    group_label: "menu_administration",
    path: "/permission",
  },
  {
    id: 16,
    code: "16",
    label: "menu_management_common_config",
    group_label: "menu_administration",
    path: "/common-config",
  },
  {
    id: 17,
    code: "17",
    label: "menu_management_log",
    group_label: "menu_administration",
    path: "/logs",
  },

  {
    id: 18,
    code: "18",
    label: "menu_organisation",
    group_label: "menu_category",
    path: "/category/organisation",
  },
  {
    id: 19,
    code: "19",
    label: "menu_provider",
    group_label: "menu_category",
    path: "/category/provider",
  },
  {
    id: 20,
    code: "20",
    label: "menu_satellite",
    group_label: "menu_category",
    path: "/category/satellite",
  },

  {
    id: 21,
    code: "21",
    label: "menu_image_types",
    group_label: "menu_category",
    path: "/category/image-types",
  },
  {
    id: 55,
    code: "21",
    label: "menu_category_data_catalog",
    group_label: "menu_category",
    path: "/category/data-catalog",
  },
  {
    id: 22,
    code: "22",
    label: "menu_processing_level",
    group_label: "menu_category",
    path: "/category/processing-level",
  },
  {
    id: 23,
    code: "23",
    label: "menu_mode",
    group_label: "menu_category",
    path: "/category/imaging-mode",
  },

  {
    id: 24,
    code: "24",
    label: "menu_direction",
    group_label: "menu_category",
    path: "/category/direction",
  },
  {
    id: 25,
    code: "25",
    label: "menu_pol_layer",
    group_label: "menu_category",
    path: "/category/pol-layer",
  },
  {
    id: 26,
    code: "26",
    label: "menu_priority_level",
    group_label: "menu_category",
    path: "/category/priority-level",
  },

  {
    id: 27,
    code: "27",
    label: "menu_order_status",
    group_label: "menu_category",
    path: "/category/order-status",
  },
  {
    id: 29,
    code: "29",
    label: "menu_organisation_status",
    group_label: "menu_category",
    path: "/category/organisation-status",
  },
  {
    id: 30,
    code: "30",
    label: "menu_actions_log",
    group_label: "menu_category",
    path: "/category/actions-log",
  },
  {
    id: 54,
    code: "54",
    label: "menu_data_specification",
    group_label: "menu_category",
    path: "/category/data-specification",
  },
  {
    id: 31,
    code: "31",
    label: "label_account_info",
    group_label: "label_private",
    path: "/me",
  },
];

export default { interfaces };
