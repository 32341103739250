<template>
  <error404></error404>
</template>
<script>
import LayoutPage from "./Layout";
import Error404 from "@/components/pages/error/404";
export default {
  name: "NotFound",
  components: {
    LayoutPage,
    Error404,
  },
  created() {},
};
</script>
