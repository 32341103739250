var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-layout',{attrs:{"isToolbar":true,"isBreadcrumb":true,"breadcrumbitems":[
    { label: _vm.$t('menu_category') },
    { label: _vm.$t('menu_image_types') } ],"propButtonsState":{
    delete: _vm.buttons.delete,
    save: _vm.buttons.save,
    add: _vm.buttons.add,
    edit: _vm.buttons.edit,
    reload: _vm.buttons.reload,
    print: {
      visible: false,
    },
  },"propTitle":_vm.$t('menu_image_types')},on:{"emitFunction":function($event){return _vm.menuBarFunction($event)}}},[_c('div',{staticClass:"image-types-content"},[_c('div',{staticClass:"image-types__dialog"},[(_vm.message.length > 0)?_c('DialogMessage',{attrs:{"propTitle":_vm.$t('title_message_info'),"prop-show":_vm.message.length > 0,"prop-message":_vm.message},on:{"close":function($event){_vm.message = []}}}):_vm._e(),(_vm.confirmDelete.show)?_c('DialogConfirmation',{attrs:{"prop-show":_vm.confirmDelete.show,"prop-title":_vm.$t('title_message_confirm'),"prop-icon":_vm.confirmDelete.icon,"prop-message":_vm.confirmDelete.message,"textNo":_vm.$t('button_text_close'),"textYes":_vm.$t('button_text_delete')},on:{"cancel":_vm.onCancelConfirmDelete,"confirmed":_vm.onConfirmedDelete}}):_vm._e(),(_vm.dialog.satellite.show)?_c('DialogAddSatellites',{attrs:{"prop-show":_vm.dialog.satellite.show,"prop-items":_vm.satellite.items,"prop-selected":_vm.imageTypes.entity.satellites},on:{"close":function($event){_vm.dialog.satellite.show = false;
          _vm.dialog.satellite.value = null;},"getAllSatellite":_vm.getAllSatellite,"selected":_vm.selectedSatellite}}):_vm._e()],1),_c('div',{staticClass:"image-types__datatable"},[_c('DataTable',{ref:"imageTypesDatatable",attrs:{"propItems":_vm.imageTypes.items,"propTable":_vm.imageTypes.table,"propFirst":_vm.imageTypes.first},on:{"rowClick":_vm.rowClick}})],1),_c('div',{staticClass:"image-types__structure"},[_c('ValidationObserver',{ref:"imageTypesObserver"},[_c('Structure',{attrs:{"prop-entity":_vm.imageTypes.entity,"prop-status":{
            isAdd: _vm.imageTypes.isAdd,
            isEdit: _vm.imageTypes.isEdit,
          }}})],1),_c('div',{staticClass:"actions"},[_c('Button',{staticClass:"p-button-rounded p-button-secondary",attrs:{"icon":_vm.buttons.prev.processing
              ? 'fa fa-spinner fa-spin'
              : 'pi pi-chevron-left',"disabled":_vm.buttons.prev.disabled},on:{"click":_vm.prevItem}}),_c('Button',{staticClass:"p-button-rounded p-button-secondary",attrs:{"icon":_vm.buttons.next.processing
              ? 'fa fa-spinner fa-spin'
              : 'pi pi-chevron-right',"disabled":_vm.buttons.next.disabled},on:{"click":_vm.nextItem}})],1)],1),(_vm.imageTypes.table.selected || _vm.imageTypes.isAdd)?_c('div',{staticClass:"image-types__satellites"},[_c('Satellites',{ref:"imageTypesSatellites",attrs:{"prop-items":_vm.imageTypes.entity.satellites}}),_c('div',{staticClass:"actions"},[(_vm.imageTypes.isAdd || _vm.imageTypes.isEdit)?_c('Button',{staticClass:"p-button-rounded btn-geostore2",attrs:{"icon":'pi pi-plus'},on:{"click":_vm.addSatellite}}):_vm._e()],1)],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }