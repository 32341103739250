import axios from 'axios';
import basicApi from "@/utils/apis/basic";
import app from './../../main'
import { userServiceApiURL } from "@/utils/commons/environment"
export default {
    async getList(params,access_token) {
        let headers = basicApi.role.get_list.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        if (!params) params = {};
        params.lang = app.$store.getters.getLang;
        const object = axios({
          method: basicApi.role.get_list.method,
          url: userServiceApiURL + basicApi.role.get_list.url,
          headers:headers,
          params:params
        });
        let response = await object;
        return response;
    },
    async getAll(access_token) {
        let headers = basicApi.role.get_list.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
          method: basicApi.role.get_list.method,
          url: userServiceApiURL + basicApi.role.get_list.url,
          headers:headers,
          params:{
            page:0,
            size:2147483647,
            lang : app.$store.getters.getLang
          }
        });
        let response = await object;
        return response;
    },
    async create(entity,access_token){
        let headers = basicApi.role.create.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
          method: basicApi.role.create.method,
          url:  userServiceApiURL + basicApi.role.create.url,
          data: entity,
          headers: headers,
          params: {
            lang : app.$store.getters.getLang
          }
        });
        let response = await object;
        return response;
    },
    async update(entity, access_token) {
      let headers = basicApi.role.update.headers;
      headers["Authorization"] = "bearer " + access_token;
      headers['Accept-Language'] = app.$store.getters.getLang;
      const object = axios({
        method: basicApi.role.update.method,
        url: userServiceApiURL + basicApi.role.update.url.replace("{id}",entity.id),
        data: entity,
        headers: headers,
        params: {
          lang : app.$store.getters.getLang
        }
      });
      let response = await object;
      return response;
    },
  
    async delete(id, access_token) {
      let headers = basicApi.role.delete.headers;
      headers["Authorization"] = "bearer " + access_token;
      headers['Accept-Language'] = app.$store.getters.getLang;
      const object = axios({
        method: basicApi.role.delete.method,
        url: userServiceApiURL + basicApi.role.delete.url.replace("{role_id}",id),
        headers:headers,
        params:{
          role_id: id,          
          lang : app.$store.getters.getLang
        }
      });
      let response = await object;
      return response;
    },
    
    async addPermissions(permission_ids ,role_id,access_token){
      let headers = basicApi.role.add_permissions.headers;
      headers["Authorization"] = "bearer " + access_token;
      headers['Accept-Language'] = app.$store.getters.getLang;
      const object = axios({
        method: basicApi.role.add_permissions.method,
        url:  userServiceApiURL + basicApi.role.add_permissions.url,
        headers:headers,
        data:{
          permission_ids :permission_ids,
          role_id:role_id
        },
        params:{
          lang : app.$store.getters.getLang
        }
      });
      let response = await object;
      return response;
  },
    async getByGroup(group,access_token){
      let headers = basicApi.role.group.headers;
      headers["Authorization"] = "bearer " + access_token;
      headers['Accept-Language'] = app.$store.getters.getLang;
      let params = {
        name:group.trim().toLowerCase().replace(/\s\s+/g, ' ')
      }
      const object = axios({
        method: basicApi.role.group.method,
        url:  userServiceApiURL + basicApi.role.group.url,
        headers:headers,        
        params:params
      });
      let response = await object;
      return response;
  },
}