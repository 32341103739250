<template>
  <div class="geo-image">
    <SatelliteImage />
  </div>
</template>
<script>
import SatelliteImage from "./Satellite";
export default {
  components: {
    SatelliteImage,
  },
};
</script>