<template>
  <div>
    <CommonAccordion :propTitle="$t('title_organisation_info')">
      <div slot="content" class="org-info">
        <div class="p-grid m-0">
          <div class="p-field p-col-12">
            <label class="p-col-fixed" :style="styleLabel">
              {{ $t("label_name") }} &nbsp;
              <span class="font-weight-bold required">*</span>
            </label>
            <ValidationProvider :name="$t('label_name')" rules="required">
              <InputText
                type="text"
                :style="styleControl"
                maxlength="255"
                v-model="propEntity.name"
                aria-autocomplete="off"
                :disabled="!propStatus.isAdd && !propStatus.isEdit"
                class="new-style"
              />
            </ValidationProvider>
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label class="p-col-fixed" :style="styleLabel">
              {{ $t("label_phone_number") }}
            </label>
            <InputText
              type="tel"
              :style="styleControl"
              maxlength="10"
              v-model="propEntity.phone_number"
              :disabled="!propStatus.isAdd && !propStatus.isEdit"
              class="new-style"
            />
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label class="p-col-fixed" :style="styleLabel">
              {{ $t("label_email") }}
            </label>
            <ValidationProvider :name="$t('label_email')" rules="email">
              <InputText
                type="text"
                :style="styleControl"
                maxlength="150"
                v-model="propEntity.email"
                :disabled="!propStatus.isAdd && !propStatus.isEdit"
                class="new-style"
              />
            </ValidationProvider>
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label class="p-col-fixed" :style="styleLabel">Fax</label>
            <InputText
              type="text"
              :style="styleControl"
              maxlength="25"
              v-model="propEntity.fax_number"
              :disabled="!propStatus.isAdd && !propStatus.isEdit"
              class="new-style"
            />
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label class="p-col-fixed" :style="styleLabel">{{
              $t("label_status")
            }}</label>
            <Dropdown
              class="new-style"
              :style="styleControl"
              v-model="propEntity.status"
              :showClear="!(!propStatus.isAdd && !propStatus.isEdit)"
              :options="propOrgStatus"
              optionValue="id"
              optionLabel="value"
              optionDisabled="is_disable"
              dataKey="id"
              :disabled="!propStatus.isAdd && !propStatus.isEdit"
              ><template #option="slotProps">
                <span
                  >{{
                    slotProps.option.translation &&
                    slotProps.option.translation[lang] &&
                    slotProps.option.translation[lang].value
                      ? slotProps.option.translation[lang].value
                      : slotProps.option.value
                  }}
                </span>
              </template></Dropdown
            >
          </div>
          <div class="p-field p-col-12 p-md-12 d-flex">
            <label class="p-col-fixed" :style="styleLabel">{{
              $t("label_organisation_parent")
            }}</label>
            <treeselect
              class="new-style"
              :style="styleControl"
              v-model="propEntity.parent_id"
              name="orgTree"
              :multiple="false"
              :options="propTreeOrganisation"
              :normalizer="normalizer"
              :noChildrenText="$t('label_no_sub_options')"
              :noOptionsText="$t('label_no_options_text')"
              :noResultsText="$t('label_no_results_text')"
              placeholder=""
              :closeOnSelect="!(!propStatus.isAdd && !propStatus.isEdit)"
              :disabled="!propStatus.isAdd && !propStatus.isEdit"
            >
              <label slot="option-label" slot-scope="{ node }">{{
                node.raw.name
              }}</label>
              <div slot="value-label" slot-scope="{ node }">
                {{ node.raw.name }}
              </div>
            </treeselect>
          </div>
          <div class="p-field p-grid p-col-12 m-0">
            <label class="p-col-fixed" :style="styleLabel">{{
              $t("label_description")
            }}</label>
            <Textarea
              class="new-style"
              :autoResize="true"
              rows="3"
              :style="styleControl"
              maxlength="255"
              v-model="propEntity.description"
              :disabled="!propStatus.isAdd && !propStatus.isEdit"
            />
          </div>
        </div>
      </div>
    </CommonAccordion>
    <div class="organisation-members">
      <div
        class="organisation-members__actions"
        v-if="propStatus.isAdd || propStatus.isEdit"
      >
        <Button
          icon="pi pi-plus"
          class="p-button-rounded btn-geostore2"
          @click="showDialogAddMembers"
        />
      </div>
      <CommonAccordion :propTitle="$t('title_organisation_member')">
        <div slot="content" class="">
          <MembersDatatable
            ref="membersDatatable"
            :prop-items="propEntity.member"
            :propStatus="propStatus"
            @removeMember="removeMember"
          />
        </div>
      </CommonAccordion>
    </div>
  </div>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import InputText from "@/components/commons/inputtext/InputText";
import Button from "@/components/commons/button/Button";
import Treeselect from "@riophae/vue-treeselect";
import Textarea from "@/components/commons/textarea/Textarea";
import MembersDatatable from "./structure/MembersDatatable.vue";
export default {
  props: {},
  components: {
    CommonAccordion,
    Dropdown,
    Treeselect,
    Button,
    InputText,
    Textarea,
    MembersDatatable,
  },
  props: {
    propEntity: {
      type: Object,
      default: () => {},
    },
    propOrgStatus: {
      type: Array,
      default: () => [],
    },
    propStatus: {
      type: Object,
      default: () => {
        return {
          isAdd: false,
          isEdit: false,
        };
      },
    },
    propTreeOrganisation: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      normalizer(node) {
        return {
          children: node.children && node.children.length ? node.children : 0,
        };
      },
    };
  },
  methods: {
    showDialogAddMembers() {
      this.$emit("showDialogAddMembers");
    },
    removeMember(item) {
      this.$emit("removeMember", item);
    },
  },

  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    styleLabel() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:110px";
    },
    styleLabelCheckbox() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:128px";
    },
    styleControl() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:calc(100% - 110px)";
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss">
.organisation__structure {
  @import "@/assets/scss/_datatable.scss";
  .accordion {
    border-radius: 0.5rem;
  }
  .vue-treeselect--disabled {
    .vue-treeselect__control {
      opacity: 0.5;
      background: #fff;
      &:hover {
        border-color: #a6a6a6 !important;
      }
    }
  }
  .vue-treeselect__control {
    border-color: #a6a6a6;
    &:hover {
      border-color: #212121 !important;
    }
    &:disabled {
      opacity: 0.5;
    }
  }

  .organisation-members {
    position: relative;
    margin-top: 0.5rem;
    .accordion {
      border-radius: 0.5rem;
    }
    .organisation-members__actions {
      position: absolute;
      right: 0;
      top: 0.25rem;
      z-index: 1;
      button {
        height: auto;
        border-radius: 50% !important;
        margin-right: 0.25rem;
        width: 1.6125rem;
        height: 1.6125rem;
        padding: 0.25rem;
      }
    }
  }
}
</style>