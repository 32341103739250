<template>
  <div class="container-fluid">
    <login-form @loginSuccess="loginSuccess($event)"></login-form>
  </div>
</template>
<script>
import LoginForm from "@/components/pages/login/Index";
import cryptoFunc from "@/utils/functions/crypto";
export default {
  components: {
    LoginForm,
  },
  created() {},
  methods: {
    async loginSuccess(val) {
      let matchedPath = null;
      this.$store.dispatch("setToken", val);
      await localStorage.setItem(
        "data",
        btoa(cryptoFunc.encrypt(JSON.stringify(val)).toString())
      );
      let accessPaths = val.urls ? val.urls : [];
      this.$store.dispatch("setAccessPaths", accessPaths);
      if (!accessPaths || accessPaths.length === 0) {
        if (this.$route.name != "NotAuthorized") {
          this.$router.push({
            name: "NotAuthorized",
          });
        }
      } else {
        let path = "/";
        try {
          const urlParams = new URLSearchParams(window.location.search);
          path = urlParams.get("next");
          if (path) {
            try {
              path = await atob(path);
            } catch (errorDecrypt) {
              path = null;
            }
          } else {
            path = this.$route.path;
          }
          if (path) {
            let resolved = this.$router.resolve(path);
            if (resolved && resolved.route && resolved.route.matched)
              matchedPath =
                resolved.route.matched.length > 0
                  ? resolved.route.matched[resolved.route.matched.length - 1]
                      .path
                  : null;
            if (resolved.route.name || resolved.route.name != "404") {
              if (path === "/login") path = null;
            }
          }
        } catch (error) {
          path = null;
        }
        if (path && accessPaths.includes(path)) {
          if (path === "/login") path = "/";
          this.$router.push({
            path: path,
          });
        } else if (path && !accessPaths.includes(path) && path != "/") {
          if (matchedPath) {
            if (accessPaths.findIndex((x) => x === matchedPath.trim()) < 0)
              this.$router.push({
                name: "NotFound",
              });
            else
              this.$router.push({
                path: path,
              });
          } else
            this.$router.push({
              name: "NotFound",
            });
        } else {
          this.$router.push({
            path: accessPaths.includes("/") ? "/" : accessPaths[0],
          });
        }
      }
    },
  },
  mounted() {
    if (this.loggedIn)
      this.$router.push({
        name: Home,
      });
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
  },
};
</script>