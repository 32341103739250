<template>
  <Dialog
    :header="propTitle"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :maximizable="imgPreview.src ? true : false"
    :modal="true"
    :contentStyle="propStyle"
    @hide="onClose"
    @maximizedChage="maximizedChage"
  >
    <div class="table-responsive">
      <table class="table">
        <tbody>
          <tr>
            <td
              align="left"
              width="50%"
              style="vertical-align: top"
              v-if="maximized"
            >
              <p>{{ $t("label_map") }}</p>
              <div class="map-preview">
                <MapOpenlayer
                  v-if="imgPreview.src"
                  :prop-src="imgPreview.src"
                  :propPoints="
                    propItem.geom &&
                    propItem.geom.coordinates &&
                    propItem.geom.coordinates.length > 0
                      ? propItem.geom.coordinates[0]
                      : []
                  "
                />
              </div>
            </td>
            <td
              align="left"
              width="35%"
              style="vertical-align: top"
              v-if="!maximized"
            >
              <p>{{ $t("label_quicklook") }}</p>
              <div class="GP0VF02CF0">
                <div class="Iybw1Bhj3Jhd8s">
                  <tile
                    v-if="imgPreview.loading"
                    :loading="imgPreview.loading"
                    :height="'40'"
                    :width="'40'"
                    :color="'fd853e'"
                    class="ehAbnmg4Jwb9BS"
                  ></tile>
                  <div v-else>
                    <quick-look
                      v-if="imgPreview.src"
                      :prop-data="{
                        img: imgPreview.src,
                      }"
                    ></quick-look>
                    <img
                      width="100%"
                      v-else
                      src="/img/icons/no-image.png"
                      title="No Image"
                    />
                  </div>
                </div>
              </div>
            </td>
            <td
              align="left"
              style="vertical-align: top"
              :style="[maximized ? { width: '50%' } : { width: '65%' }]"
            >
              <p>{{ $t("label_values") }}</p>
              <div class="table-responsive">
                <table
                  class="table-metadata w-100"
                  id="tableMetadata"
                  cellspacing="0"
                  cellpadding="0"
                >
                  <colgroup>
                    <col />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <td>
                        {{ $t("label_source_id") }}
                      </td>
                      <td>
                        <div
                          :title="
                            entity &&
                            entity.scene_id &&
                            entity.scene_id.length > 50
                              ? entity.scene_id
                              : null
                          "
                        >
                          {{
                            entity &&
                            entity.scene_id &&
                            entity.scene_id.length > 50
                              ? entity.scene_id.substring(0, 50) + "..."
                              : entity.scene_id
                          }}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 150px">{{ $t("label_image_name") }}</td>
                      <td>
                        <div
                          :title="entity.name.length > 50 ? entity.name : null"
                        >
                          {{
                            entity.name.length > 50
                              ? entity.name.substring(0, 50) + "..."
                              : entity.name
                          }}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>{{ $t("label_satellite") }}</td>
                      <td>
                        <div>
                          <div>
                            {{
                              entity.satellite_name
                                ? entity.satellite_name
                                : null
                            }}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("label_image_time") }}</td>
                      <td>
                        <div>
                          {{
                            entity && entity.imaging_date
                              ? entity.imaging_date
                              : ""
                          }}
                        </div>
                      </td>
                    </tr>
                    <tr v-if="imageType && Object.keys(imageType).length > 0">
                      <td>{{ $t("label_image_type") }}</td>
                      <td>
                        <div>
                          {{
                            imageType.translation && imageType.translation[lang]
                              ? imageType.translation[lang].description
                              : imageType.value
                              ? imageType.value
                              : null
                          }}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("label_incidence_angle") }}</td>
                      <td>
                        <div>
                          {{
                            entity && entity.incidence_angle
                              ? entity.incidence_angle
                              : ""
                          }}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("label_resolution") }}</td>
                      <td>
                        <div>
                          {{
                            entity && entity.resolution ? entity.resolution : ""
                          }}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("label_processing_level") }}</td>
                      <td>
                        <div>
                          {{
                            entity && entity.processing_level_name
                              ? entity.processing_level_name
                              : ""
                          }}
                        </div>
                      </td>
                    </tr>
                    <tr
                      v-for="item in entity.metadata.filter(
                        (x) =>
                          x.active &&
                          x.key != 'boundary' &&
                          !fieldsIgnore.includes(x.key)
                      )"
                      :key="item.id"
                    >
                      <td>{{ item.name }}</td>
                      <td>
                        {{ item.value }}{{ item.unit ? item.unit : null }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="w-100 p-0 no-background"
                        colspan="2"
                        v-for="item in arrayFieldtypeObject"
                        :key="item.id"
                      >
                        <FieldTypeObject :propField="item" />
                      </td>
                    </tr>
                    <tr v-show="maximized">
                      <td>
                        {{ $t("label_quicklook") }}
                      </td>
                      <td>
                        <img
                          class="gwt-Image GP0VF02CE0"
                          style="width: 100%"
                          :src="imgPreview.src"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <template #footer>
      <Button
        :label="$t('button_text_close')"
        :icon="'fas fa-times'"
        :class="'p-button-danger'"
        @click="onClose"
      />
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
import Button from "@/components/commons/button/Button";
import MapOpenlayer from "./metadata/Map";
import QuickLook from "./metadata/QuickLook";
import FieldTypeObject from "./metadata/FieldTypeObject";
import { TileSpinner } from "vue-spinners";
import moment from "moment";
import fileFunc from "@/utils/functions/file";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import { enpointPreviewUrl, minioPrefix } from "@/utils/commons/environment";
import axios from "axios";

import metaKeyFunc from "@/utils/functions/metakey";
export default {
  components: {
    Dialog,
    Button,
    MapOpenlayer,
    QuickLook,
    Tile: TileSpinner,
    FieldTypeObject,
  },
  props: {
    propTitle: {
      type: String,
      default: "",
    },
    propStyle: {
      type: Object,
      default: () => {
        return {
          width: "480px",
        };
      },
    },
    propShow: {
      type: Boolean,
      default: false,
    },
    propItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
      fieldsIgnore: [],
      arrayFieldtypeObject: [],
      maximized: false,
      imgPreview: {
        loading: true,
        src: null,
      },
      enpoint: enpointPreviewUrl + minioPrefix,
      imageTypes: {
        items: [],
      },
    };
  },
  created() {
    this.show = this.propShow;
    this.getAllImageTypes();
    let path =
      this.propItem &&
      this.propItem.preview &&
      this.propItem.preview.preview_path
        ? this.propItem.preview.preview_path
        : this.propItem &&
          this.propItem.preview &&
          this.propItem.preview.icon_path
        ? this.propItem.preview.icon_path
        : null;
    if (path) {
      if (
        this.propItem.preview &&
        this.propItem.preview.preview_images &&
        this.propItem.preview.preview_images.length > 0
      ) {
        let previews = this.propItem.preview.preview_images.filter(
          (x) => (x.type = "PREVIEW")
        );
        if (previews && previews.length === 0)
          previews = this.propItem.preview.preview_images.filter(
            (x) => (x.type = "ICON")
          );
        if (path && path.trim()) {
          let item = previews.find((x) => x.path === path.trim());
          let ext = null;
          if (item && Object.keys(item).length > 0) {
            ext = item.ext ? item.ext : null;
          }
          if (ext && ["tif", "tiff"].includes(ext.trim().toLowerCase())) {
            this.getImageTif(this.enpoint + path);
          } else {
            this.imgPreview.loading = false;
            this.imgPreview.src = this.enpoint + path;
          }
        }
      } else {
        this.imgPreview.loading = false;
        this.imgPreview.src = this.enpoint + path;
      }
    } else {
      this.imgPreview.loading = false;
      this.imgPreview.src = null;
    }
  },
  watch: {
    propShow: {
      handler(val) {
        this.show = val;
      },
      immediate: true,
    },
  },
  methods: {
    async getAllImageTypes() {
      try {
        let response = await metaKeyFunc.getByKey(
          "IMAGE_TYPES",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.imageTypes.items =
            response && response.data && response.data.data
              ? response.data.data
              : [];
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllImageTypes);
        }
      }
    },
    async getImageTif(url) {
      try {
        return axios({
          url: url,
          method: "get",
          responseType: "blob",
        })
          .then(async (response) => {
            if (response && response.status === 200) {
              let blob = new Blob([response.data], {
                type: "image/tiff",
              });
              if (blob.size != 0) {
                if (this.propModel === "TSX-1") {
                  const tiff = await GeoTIFF.fromBlob(blob);
                  const image = await tiff.getImage();
                  const data = await image.readRasters();
                  const canvas = document.createElement("canvas");
                  const plot = new plotty.plot({
                    canvas,
                    data: data[0],
                    width: image.getWidth(),
                    height: image.getHeight(),
                    domain: [0, 256],
                    colorScale: "greys",
                  });
                  plot.render();
                  this.imgPreview.loading = false;
                  this.imgPreview.src = canvas.toDataURL("image/tiff");
                } else {
                  let buffer = await blob.arrayBuffer();
                  var tiff = new Tiff({ buffer: buffer });
                  var canvas = tiff.toCanvas();
                  this.imgPreview.loading = false;
                  this.imgPreview.src = canvas.toDataURL("image/tiff");
                }
              }
            }
          })
          .catch((err) => {});
      } catch (error) {
        if (error.response && error.response.status === 401) {
        } else {
        }
      }
    },
    onClose() {
      this.show = false;
      this.$emit("close");
    },
    maximizedChage(val) {
      this.maximized = val;
    },
    formatUnixDateTime2String(value) {
      let result = "";
      if (value) {
        try {
          let date = new Date(value);
          result = moment
            .unix(date.getTime() / 1000)
            .format("HH:mm DD/MM/YYYY");
          result = result
            .replace(":", this.lang === "vi" ? "h" : ":")
            .replace(" ", this.lang === "vi" ? " ngày " : " ");
        } catch (error) {}
      }
      return result;
    },
    async getImagePreview(key) {
      this.imgPreview.loading = true;
      this.imgPreview.src = null;
      try {
        let response = await fileFunc.downloadPreview(
          key,
          this.$store.getters.getAccessToken
        );
        if (response.status === 200) {
          let contentType =
            response.headers && response.headers["content-type"]
              ? response.headers["content-type"]
              : "image/jpg";
          let blob = new Blob([response.data], {
            type: contentType,
          });
          let arrSplit = contentType.split("/");
          if (blob.size != 0) {
            if (!window.navigator.msSaveOrOpenBlob) {
              // BLOB NAVIGATOR
              this.imgPreview.src = window.URL.createObjectURL(blob);
            } else {
              // BLOB FOR EXPLORER 11
              this.imgPreview.src = window.navigator.msSaveOrOpenBlob(
                blob,
                new Date().getTime() + "." + arrSplit[arrSplit.length - 1]
                  ? arrSplit[arrSplit.length - 1]
                  : "jpg"
              );
            }
            this.$emit("initSuccessPreviewImange", {
              id: this.propItem.id,
              val: this.imgPreview.src,
            });
          }
        }
        this.imgPreview.loading = false;
      } catch (error) {
        this.imgPreview.loading = false;
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getImagePreview, key);
        }
      }
      this.imgPreview.loading = false;
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
  computed: {
    entity() {
      let result = {
        area: null,
        area_unit: null,
        band: null,
        cloud_coverage: null,
        cloud_coverage_unit: null,
        description: null,
        google_earth_path: null,
        icon_path: null,
        image_type: null,
        imaging_date: null,
        imaging_date_timestamp: null,
        incidence_angle: null,
        incidence_angle_unit: null,
        metadata: [],
        metadata_extra: {},
        extend: {},
        name: null,
        preview_path: null,
        resolution: null,
        snow_coverage: null,
        snow_coverage_unit: null,
        processing_level: null,
        processing_level_id: null,
        processing_level_name: null,
        mode: null,
        beam_mode: null,
        direction: null,
        pol_layer: null,
        resolution: null,
        resolution_unit: null,
        satellite: null,
        satellite_type: null,
        satellite_id: null,
        satellite_name: null,
        bounding_box: [],
        provider: null,
      };
      if (this.propItem && Object.keys(this.propItem).length > 0) {
        result.scene_id = this.propItem.scene_id;
        result.id = this.propItem.id;
        result.file_path = this.propItem.file_path;
        result.icon_path = this.propItem.icon_path;
        result.preview_images = this.propItem.preview_images;
        result.name = this.propItem.name
          ? this.propItem.name.trim().replace(/\s\s+/g, " ")
          : null;
        result.metadata = this.propItem.metadata ? this.propItem.metadata : [];

        if (!result.name) {
          let index = result.metadata.findIndex((x) => x.key === "image_name");
          if (index >= 0) result.name = result.metadata[index].value;
        }
        if (result.name) {
          this.fieldsIgnore.push("image_name");
        }
        result.description = this.propItem.description
          ? this.propItem.description.trim().replace(/\s\s+/g, " ")
          : null;

        result.imaging_date =
          this.propItem.info && this.propItem.info.imaging_date
            ? this.propItem.info.imaging_date
            : null;
        if (!result.imaging_date) {
          let index = result.metadata.findIndex(
            (x) => x.key === "imaging_date"
          );
          if (index >= 0) result.imaging_date = result.metadata[index].value;
        }
        if (result.imaging_date) {
          this.fieldsIgnore.push("imaging_date");
          try {
            result.imaging_date_timestamp = new Date(
              result.imaging_date
            ).getTime();
          } catch (error) {}
        }
        result.incidence_angle =
          this.propItem.info &&
          this.propItem.info.incidence_angle != null &&
          this.propItem.info.incidence_angle != undefined
            ? this.propItem.info.incidence_angle
            : null;
        if (!result.incidence_angle) {
          let index = result.metadata.findIndex(
            (x) => x.key === "incidence_angle"
          );
          if (index >= 0) {
            result.incidence_angle = result.metadata[index].value
              ? (result.metadata[index].value + "")
                  .trim()
                  .replace(/\s\s+/g, " ") +
                (result.metadata[index].unit
                  ? result.metadata[index].unit.trim().replace(/\s\s+/g, " ")
                  : "")
              : null;
            result.incidence_angle_unit = result.metadata[index].unit
              ? result.metadata[index].unit.trim().replace(/\s\s+/g, " ")
              : "";
          }
        }
        if (!result.incidence_angle_unit) {
          let index = result.metadata.findIndex(
            (x) => x.key === "incidence_angle"
          );
          if (index >= 0) {
            result.incidence_angle = result.incidence_angle
              ? (result.incidence_angle + "").trim().replace(/\s\s+/g, " ") +
                (result.metadata[index].unit
                  ? result.metadata[index].unit.trim().replace(/\s\s+/g, " ")
                  : "")
              : null;
          }
        }
        if (result.incidence_angle) this.fieldsIgnore.push("incidence_angle");

        result.resolution =
          this.propItem.info &&
          this.propItem.info.resolution != null &&
          this.propItem.info.resolution != undefined
            ? this.propItem.info.resolution
            : null;
        if (!result.resolution) {
          let index = result.metadata.findIndex((x) => x.key === "resolution");
          if (index >= 0) {
            result.resolution = result.metadata[index].value
              ? (result.metadata[index].value + "")
                  .trim()
                  .replace(/\s\s+/g, " ") +
                (result.metadata[index].unit
                  ? result.metadata[index].unit.trim().replace(/\s\s+/g, " ")
                  : "")
              : null;
            result.resolution_unit = result.metadata[index].unit
              ? result.metadata[index].unit.trim().replace(/\s\s+/g, " ")
              : "";
          }
        }
        if (!result.resolution_unit) {
          let index = result.metadata.findIndex((x) => x.key === "resolution");
          if (index >= 0) {
            result.resolution = result.resolution
              ? (result.resolution + "").trim().replace(/\s\s+/g, " ") +
                (result.metadata[index].unit
                  ? result.metadata[index].unit.trim().replace(/\s\s+/g, " ")
                  : "")
              : null;
          }
        }
        if (result.resolution) this.fieldsIgnore.push("resolution");
        result.satellite =
          this.propItem.info &&
          this.propItem.info.satellite &&
          this.propItem.info.satellite
            ? this.propItem.info.satellite
            : null;
        if (result.satellite) this.fieldsIgnore.push("satellite");
        if (result.satellite && Object.keys(result.satellite).length > 0) {
          result.satellite_id = result.satellite.id;
          result.satellite_name = result.satellite.name;
        }

        result.processing_level =
          this.propItem.info &&
          this.propItem.info.processing_level &&
          this.propItem.info.processing_level
            ? this.propItem.info.processing_level
            : null;
        if (
          result.processing_level &&
          Object.keys(result.processing_level).length > 0
        ) {
          result.processing_level_id = result.processing_level.id;
          result.processing_level_name = result.processing_level.value;
        } else {
          let index = result.metadata.findIndex(
            (x) => x.key === "processing_level"
          );
          if (index >= 0) {
            result.processing_level_name = result.metadata[index].value
              ? (result.metadata[index].value + "")
                  .trim()
                  .replace(/\s\s+/g, " ") +
                (result.metadata[index].unit
                  ? result.metadata[index].unit.trim().replace(/\s\s+/g, " ")
                  : "")
              : null;
          }
        }
        if (result.processing_level_name)
          this.fieldsIgnore.push("processing_level");
        if (this.propItem.image_type)
          result.image_type =
            this.propItem.image_type && this.propItem.image_type.value
              ? this.propItem.image_type.value
              : null;
        if (
          this.propItem.preview_images &&
          this.propItem.preview_images.length > 0
        ) {
          this.previews = this.propItem.preview_images.filter(
            (x) => x.type === "PREVIEW"
          );
          if (this.previews.length > 0)
            result.preview_path = this.previews[0].path_image;
          this.icons = this.propItem.preview_images.filter(
            (x) => x.type === "ICON"
          );
          if (this.icons.length > 0)
            result.icon_path = this.icons[0].path_image;
          let googleEarths = this.propItem.preview_images.filter(
            (x) => x.type === "GOOGLE_EARTH"
          );
          if (googleEarths.length > 0)
            result.google_earth_path = googleEarths[0].path_image;
        }
        if (!result.imaging_date && this.propItem.imaging_date) {
          result.imaging_date = this.propItem.imaging_date;
          try {
            result.imaging_date_timestamp = result.imaging_date
              ? new Date(result.imaging_date).getTime()
              : null;
          } catch (error) {}
        }
      }
      if (result.metadata && result.metadata.length > 0) {
        this.arrayFieldtypeObject = result.metadata.filter(
          (x) => typeof x.value === "object" && x.key != "boundary"
        );
        for (let i = 0; i < this.arrayFieldtypeObject.length; i++) {
          this.fieldsIgnore.push(this.arrayFieldtypeObject[i].key);
        }
      }
      return result;
    },
    imageType() {
      let result = {};
      if (this.imageTypes.items && this.imageTypes.items.length > 0) {
        for (let i = 0; i < this.imageTypes.items.length; i++) {
          if (
            this.imageTypes.items[i].sub_meta_key &&
            this.imageTypes.items[i].sub_meta_key.length > 0
          ) {
            let satelliteName =
              this.entity.metadata && this.entity.metadata.satellite
                ? this.entity.metadata.satellite.name.trim()
                : this.entity.metadata_extra &&
                  this.entity.metadata_extra.satellite
                ? this.entity.metadata_extra.satellite.name.trim()
                : this.entity.satellite_name;
            if (
              this.imageTypes.items[i].sub_meta_key.findIndex(
                (x) => x.name === satelliteName
              ) >= 0
            ) {
              result = this.imageTypes.items[i];
            }
            if (result && Object.keys(result).length > 0) break;
          }
        }
      }
      return result;
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.Iybw1Bhj3Jhd8s {
  position: relative;
  width: 100%;
  height: 100%;
  .ehAbnmg4Jwb9BS {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 3;
  }
}

table {
  margin-bottom: 0;
  td {
    border-top: none;
    p {
      background: #a1a5b7;
      font-size: 14px;
      padding: 0.5rem;
      margin-bottom: 0.5rem;
      color: white;
      border-radius: 0.25rem;
    }
    .map-preview {
      position: relative;
    }
  }
}
table.table-metadata {
  border-collapse: separate;
  border-spacing: 0 0.125rem;
  tr {
    margin-bottom: 0.25rem;
    td {
      padding: 0.5rem 0.75rem;
      border: 1px solid white;
      &:first-child {
        background: $background-color-2;
        color: white;
        border-radius: 0.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        &.no-background {
          background-color: transparent;
          color: #212529;
        }
      }
    }
  }
}
</style>
