<template>
  <div>
    <spinner
      :propLoading="spinner.show || loading"
      :propShape="spinner.shape"
      v-if="spinner.show || loading"
      :prop-text="spinner.text"
    ></spinner>
    <layout-page>
      <router-view slot="slot-content" v-if="!loading" />
    </layout-page>
  </div>
</template>
<script>
import Spinner from "@/components/commons/spinners/Index";
import LayoutPage from "./Layout";
export default {
  name: "Home",
  components: {
    LayoutPage,
    Spinner,
  },
  data() {
    return {
      loading: false,
    };
  },
  created() {},
  computed: {
    spinner() {
      return this.$store.getters.getSpinnerApp;
    },
    accessPaths() {
      return this.$store.getters.getAccessPaths;
    },
  },
  watch: {
    $route(val) {
      this.loading = true;
      if (!this.accessPaths.includes(val.path)) {
        this.loading = false;
        this.$router.push({
          path: "/",
        });
      } else this.loading = false;
    },
  },
};
</script>
