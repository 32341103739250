<template>
  <div slot="slot-content">
    <spinner
      :propLoading="loading.show"
      :propShape="loading.shape"
      v-if="loading.show"
      :prop-text="loading.text"
    ></spinner>
    <SharingPage />
  </div>
</template>
<script>
import SharingPage from "@/components/pages/sharing/Sharing";
import Spinner from "@/components/commons/spinners/Index";
export default {
  name: "projects",
  components: {
    SharingPage,
    Spinner,
  },
  created() {},
  computed: {
    loading() {
      return this.$store.getters.getSpinnerApp;
    },
  },
};
</script>