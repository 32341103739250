<template>
  <main-layout
    :isToolbar="false"
    :propButtonsState="{
      delete: buttons.delete,
      save: buttons.save,
      add: buttons.add,
      edit: buttons.edit,
      reload: buttons.reload,
      print: {
        visible: false,
      },
    }"
    :breadcrumbitems="[
      { label: $t('menu_project_and_customer') },
      { label: $t('menu_customer') },
    ]"
    :propTitle="$t('menu_customer')"
    @emitFunction="menuBarFunction($event)"
  >
    <div class="customer-content">
      <div class="customer-content__dialog">
        <DialogMessage
          v-if="message.length > 0"
          :propTitle="$t('title_message_info')"
          :prop-show="message.length > 0"
          :prop-message="message"
          @close="message = []"
        />
      </div>
      <div class="customer-content__search" id="elementSearch">
        <CustomerFilter
          :prop-search="customer.search"
          :propButtons="buttons"
          @accordionHeaderClick="accordionHeaderClick"
          @onSearch="onSearch"
        />
      </div>
      <div class="customer-content__datatable">
        <DataTable
          ref="customerDatatable"
          :prop-items="customer.items"
          :propTotal="customer.total_records"
          :prop-table="customer.table"
          @nextPage="nextPage"
        />
      </div>
    </div>
  </main-layout>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion";
import MainLayout from "@/components/pages/layouts/admin/Main";
import DataTable from "./DataTable";
import CustomerFilter from "./Filter";
import Button from "@/components/commons/button/Button";
import DialogMessage from "@/components/commons/dialog/MessageBox";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import customerFunc from "@/utils/functions/customer";
export default {
  components: {
    DataTable,
    CommonAccordion,
    MainLayout,
    Button,
    DialogMessage,
    CustomerFilter,
  },
  data() {
    return {
      message: [],
      customer: {
        items: [],
        page_number: 0,
        page_size: 0,
        total_page: 0,
        total_records: 0,
        is_bottom: false,
        search: {
          page: 0,
          size: 15,
          role_id: "ROLE_CUSTOMER",
          name: null,
          order: "full_name",
          sort: "asc",
          is_active: null,
        },
        table: {
          height: 400,
          selected: null,
          page: 0,
        },
      },

      buttons: {
        prev: {
          disabled: true,
          processing: false,
          visible: true,
        },
        next: {
          disabled: true,
          processing: false,
          visible: true,
        },
        delete: {
          disabled: true,
          processing: false,
          visible: false,
        },
        save: {
          disabled: true,
          processing: false,
          visible: false,
        },
        edit: {
          disabled: true,
          processing: false,
          visible: false,
        },
        add: {
          disabled: false,
          processing: false,
          visible: false,
        },
        filter: {
          disabled: false,
          processing: false,
          visible: true,
        },
        reload: {
          disabled: false,
          processing: false,
          visible: true,
        },
      },
    };
  },
  created() {
    this.customer.search.size = Math.ceil(this.tableHeight / 45) + 5;
    this.getAllCustomer();
  },
  mounted() {
    setTimeout(() => {
      this.customer.table.height = this.calcTableHeight();
    }, 100);
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    tableHeight() {
      return this.documentHeight - 56 - 104 - 38;
    },
  },
  methods: {
    accordionHeaderClick() {
      setTimeout(() => {
        this.customer.table.height = this.calcTableHeight();
      }, 100);
    },
    calcTableHeight() {
      let element = document.getElementById("elementSearch");
      return (
        this.documentHeight -
        3 * 0.5 * 16 -
        56 -
        (element ? element.offsetHeight : 0) -
        24 -
        2 * 0.5 * 16
      );
    },
    async menuBarFunction(val) {
      switch (val.code) {
        case "FILTER":
          break;
        case "RELOAD":
          this.buttons.filter.disabled = true;
          this.buttons.filter.processing = true;
          this.buttons.reload.processing = true;
          this.buttons.reload.disabled = true;
          await this.onReload();
          this.buttons.reload.processing = false;
          this.buttons.reload.disabled = false;
          this.buttons.filter.disabled = false;
          this.buttons.filter.processing = false;
          break;

        default:
          break;
      }
    },

    nextPage() {
      if (!this.customer.is_bottom) {
        this.customer.search.page = this.customer.search.page + 1;
        this.getAllCustomer();
      }
    },
    onSearch(data) {
      this.customer.search.name = data.name
        ? data.name.trim().replace(/\s\s+/g, " ")
        : null;
      if (data.is_active) {
        switch (data.is_active) {
          case "1":
            this.customer.search.is_active = true;
            break;
          case "0":
            this.customer.search.is_active = false;
            break;
          case "2":
            this.customer.search.is_active = null;
            break;
        }
      } else this.customer.search.is_active = null;
      this.customer.items = [];
      this.customer.page = 0;
      this.customer.is_bottom = false;
      this.getAllCustomer();
    },
    /* ---------- End Emit ----------*/
    /* ---------- Api get data ---------- */

    async onReload() {
      this.customer.items = [];
      this.customer.page = 0;
      this.customer.is_bottom = false;
      await this.getAllCustomer();
    },
    async getAllCustomer() {
      if (this.customer.is_bottom) return;
      this.$store.dispatch("setSpinnerApp", {
        show: true,
      });
      this.buttons.filter.processing = true;
      this.buttons.filter.disabled = true;
      this.buttons.reload.disabled = true;
      this.buttons.reload.processing = true;
      try {
        let response = await customerFunc.getList(
          {
            page: this.customer.search.page,
            size: this.customer.search.size,
            order: this.customer.search.order,
            sort: this.customer.search.sort,
          },
          {
            is_active: this.customer.search.is_active,
            name: this.customer.search.name ? this.customer.search.name : null,
            role_id: this.customer.search.role_id
              ? this.customer.search.role_id
              : null,
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.customer.items = [
            ...this.customer.items,
            ...response.data.content_page,
          ];
          this.customer.page_number = response.data.page_number;
          this.customer.page_size = response.data.page_size;
          this.customer.total_page = response.data.total_page;
          this.customer.total_records = response.data.total_records;
          if (
            (this.customer.items.length === this.customer.total_records &&
              this.customer.total_page > 1) ||
            (response.data.total_page === 1 &&
              this.customer.items.length <= response.data.page_size)
          )
            this.customer.is_bottom = true;
        }
      } catch (error) {
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.getAllCustomer);
        }
      }

      this.buttons.reload.disabled = false;
      this.buttons.reload.processing = false;
      this.buttons.filter.processing = false;
      this.buttons.filter.disabled = false;
      this.$store.dispatch("setSpinnerApp", {
        show: false,
      });
    },

    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
};
</script>

<style lang="scss" scoped>
.customer-content {
  padding: 0.5rem;
  .customer-content__search {
    padding: 0;
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .customer-content__datatable {
    padding: 0;
    margin-top: 0.5rem;
    .accordion {
      border-radius: 0.5rem;
    }
  }
}
</style>