var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{style:({ zIndex: 1050 }),attrs:{"header":_vm.$t('title_upload_images'),"visible":_vm.show,"modal":true,"contentStyle":_vm.propStyle},on:{"update:visible":function($event){_vm.show=$event},"hide":_vm.onClose}},[_c('div',{staticClass:"myDropzone2",attrs:{"id":"myDropzone2"}},[_c('div',{staticClass:"row no-gutters upload-actions d-flex flex-row"},[_c('div',{staticClass:"add-files"},[_c('input',{ref:"addFilesInput",attrs:{"multiple":"","disabled":_vm.is_processing,"type":"file","id":"add-files__input","hidden":"","accept":"zip,application/zip,application/x-zip,application/x-zip-compressed"},on:{"change":_vm.inputFilesChange}}),_c('label',{attrs:{"for":"add-files__input"},on:{"click":_vm.labelFileClick}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("label_select_files")))])]),_c('button',{staticClass:"btn btn-start-upload",class:_vm.buttonUpload.class,staticStyle:{"width":"120px","height":"35px"},attrs:{"disabled":_vm.files.length === 0 || _vm.is_processing},on:{"click":_vm.startUpload}},[_c('i',{class:_vm.buttonUpload.icon}),_vm._v(" "+_vm._s(_vm.buttonUpload.label)+" ")]),_c('ProgressBar',{staticClass:"ml-2",staticStyle:{"width":"calc(100% - 260px)"},attrs:{"value":_vm.remainingTime.progressValue}})],1),_c('div',{staticClass:"row no-gutters upload-files"},[_c('div',{staticClass:"col",attrs:{"id":"HJud83Hwbr"}},[_c('div',{staticClass:"datatable"},[_c('DataTable',{ref:"dt",attrs:{"value":_vm.files,"resizableColumns":false,"columnResizeMode":"expand","dataKey":"id","scrollHeight":_vm.tableHeight + 'px',"virtualScroll":true,"scrollable":true},on:{"row-click":_vm.rowClick},scopedSlots:_vm._u([(_vm.files && _vm.files.length === 0)?{key:"empty",fn:function(){return [_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("label_no_files")))])]},proxy:true}:null],null,true)},[_c('Column',{attrs:{"headerStyle":{
                width: '3.75rem',
                textAlign: 'center!important',
              },"bodyStyle":"text-align: center;"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('div',[_vm._v(" "+_vm._s(slotProps.index + 1)+" ")])]}}])}),_c('Column',{attrs:{"headerStyle":"width: 3.5rem"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(slotProps.data)?_c('div',{class:'file-' + slotProps.data.type.toLowerCase()}):_vm._e()]}}])}),_c('Column',{attrs:{"header":_vm.$t('label_file_name')},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('div',{staticClass:"file-attachment"},[_c('span',{staticClass:"innernametext"},[(!slotProps.data.is_folder)?_c('div',{staticClass:"pl-2"},[_vm._v(" "+_vm._s(slotProps.data.name)+" "),(slotProps.data.other_name)?_c('p',{staticClass:"mt-2 text-primary"},[_vm._v(" ("+_vm._s(slotProps.data.other_name)+") ")]):_vm._e()]):_c('div',{staticStyle:{"width":"100%","height":"auto"}},[(slotProps.data.data_tree.length)?_c('TreeCommon',{attrs:{"value":slotProps.data.data_tree}}):_vm._e()],1)])])]}}])}),_c('Column',{attrs:{"header":_vm.$t('label_file_size'),"headerStyle":"width: 7rem"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_vm._v(" "+_vm._s(_vm._f("formatSizeFile")(slotProps.data.size))+" ")]}}])}),_c('Column',{attrs:{"header":_vm.$t('label_status'),"headerStyle":"width: 12rem"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(
                    slotProps.data.status === 'SUCCESS' &&
                    slotProps.data.read_metadata === 'SUCCESS'
                  )?_c('div',[_c('div',{staticClass:"status-item complete-status"},[_c('span',[_vm._v(_vm._s(_vm.$t("label_completed")))])])]):(
                    slotProps.data.status === 'SUCCESS' &&
                    slotProps.data.read_metadata === 'ERROR'
                  )?_c('div',[_c('div',{staticClass:"status-item incomplete-status"},[_c('span',[_vm._v(_vm._s(_vm.$t("label_incompleted")))])])]):(
                    slotProps.data.status === 'SUCCESS' &&
                    slotProps.data.read_metadata === 'PROCESSING'
                  )?_c('div',[_c('div',{staticClass:"status-item processing-status"},[_c('span',[_c('i',{staticClass:"fas fa-spinner fa-spin"}),_vm._v(" "+_vm._s(_vm.$t("label_processing")))])])]):(slotProps.data.status === 'ERROR')?_c('div',[_c('div',{staticClass:"status-item error-status"},[_c('span',[_vm._v(_vm._s(_vm.$t("label_error")))])])]):(slotProps.data.status === 'PROCESS')?_c('div',[_c('div',{staticClass:"status-item processing-status"},[_c('span',[_c('i',{staticClass:"fas fa-spinner fa-spin"}),_vm._v(" "+_vm._s(_vm.$t("label_uploading")))])])]):_vm._e()]}}])}),_c('Column',{attrs:{"headerStyle":"width: 4rem; text-align: center!important","bodyStyle":"text-align: center;"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [(slotProps.data.status === 'NEW')?_c('Button',{staticClass:"p-button-danger p-button-sm",attrs:{"disabled":_vm.is_processing,"title":_vm.$t('button_text_remove'),"icon":"fas fa-trash"},on:{"click":function($event){return _vm.removeItem(slotProps.data)}}}):_vm._e()]}}])})],1)],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }