<template>
  <Dialog
    :header="$t('title_change_pass')"
    :visible.sync="show"
    :modal="true"
    @hide="onClose"
    :style="{ zIndex: 1050 }"
    :contentStyle="{ width: '480px' }"
    :show-header="false"
  >
    <div class="change-pass">
      <div class="header-icons">
        <button
          @click="onClose"
          aria-label="close"
          type="button"
          tabindex="-1"
          class="p-dialog-header-icon p-dialog-header-close p-link"
        >
          <span class="p-dialog-header-close-icon"
            ><i class="fas fa-times"></i
          ></span>
        </button>
      </div>

      <ValidationObserver ref="changepassobserver">
        <form v-on:keyup.enter="onSubmit">
          <div class="p-field p-grid">
            <label class="p-col-fixed pb-0 m-0 w-100">
              {{ $t("label_old_password") }}&nbsp;
              <span class="font-weight-bold required">*</span>
            </label>
            <div class="p-col w-100">
              <ValidationProvider
                :name="$t('label_old_password')"
                rules="required"
              >
                <InputText
                  class="w-100 new-style"
                  type="password"
                  maxlength="50"
                  v-model="entity.pass_old"
                  autocomplete="off"
                />
              </ValidationProvider>
            </div>
          </div>
          <div class="p-field p-grid">
            <label class="p-col-fixed pb-0 m-0 w-100">
              {{ $t("label_new_password") }}&nbsp;
              <span class="font-weight-bold required">*</span>
            </label>
            <div class="p-col w-100">
              <ValidationProvider
                :name="$t('label_new_password')"
                rules="required"
                vid="confirmation"
              >
                <InputText
                  class="w-100 new-style"
                  v-model="entity.pass_new"
                  type="password"
                  maxlength="50"
                  autocomplete="off"
                />
              </ValidationProvider>
            </div>
          </div>
          <div class="p-field p-grid">
            <label class="p-col-fixed pb-0 m-0 w-100">
              {{ $t("label_new_password_confirm") }}&nbsp;
              <span class="font-weight-bold required">*</span>
            </label>
            <div class="p-col w-100">
              <ValidationProvider
                :name="$t('label_new_password_confirm')"
                rules="required|confirmed:confirmation"
              >
                <InputText
                  class="w-100 new-style"
                  type="password"
                  maxlength="50"
                  v-model="entity.pass_confirm"
                  autocomplete="off"
                />
              </ValidationProvider>
            </div>
          </div>
          <div class="p-field p-grid pt-2">
            <div class="p-col w-100">
              <button
                type="button"
                :label="$t('button_text_changepass')"
                class="w-100 btn btn-geostore2 btn-changepass"
                :icon="
                  button.processing ? 'fa fa-spinner fa-spin' : 'pi pi-check'
                "
                @click="onSubmit"
                :disabled="button.disabled"
              >
                <i
                  :class="button.processing ? 'fas fa-spinner fa-spin' : ''"
                ></i
                >&nbsp;
                {{ $t("button_text_changepass") }}
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
import Button from "@/components/commons/button/Button";
import InputText from "@/components/commons/inputtext/InputText";
import meControllerFunc from "@/utils/functions/usermecontroller";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
export default {
  components: { Dialog, Button, InputText },
  props: {
    propShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      count: 0,
      show: false,
      button: {
        disabled: false,
        processing: false,
      },
      entity: {
        pass_old: null,
        pass_new: null,
        pass_confirm: null,
      },
      employee: null,
    };
  },
  created() {
    this.show = this.propShow;
  },
  watch: {
    propShow(val) {
      this.show = val;
    },
  },
  mounted() {},
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    styleLabel() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:152px";
    },
    styleControl() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:calc(100% - 152px)";
    },
  },
  methods: {
    onClose() {
      this.show = false;
      this.$emit("close");
    },

    onSubmit() {
      if (this.count > 1) {
        this.$emit("message", [this.$t("message_changepass_error")]);
        this.count = 0;
        return;
      }
      var vm = this;
      let message = [];
      this.$refs.changepassobserver.validate().then(async (success) => {
        if (!success) {
          setTimeout(() => {
            Object.keys(vm.$refs.changepassobserver.errors).forEach(function (
              key
            ) {
              if (vm.$refs.changepassobserver.errors[key].length)
                message.push(
                  vm.$refs.changepassobserver.errors[key].join(",\n")
                );
            });
          }, 100);
          this.$emit("message", message);
          return;
        } else {
          if (this.entity.pass_new.length < 8) {
            message.push(this.$t("message_password_characters_error"));
            this.$emit("message", message);
            return;
          }
          let number = 0,
            alpha = 0;
          for (let i = 0; i < this.entity.pass_new.length; i++) {
            let characterNum = this.entity.pass_new.charCodeAt(i);
            if (characterNum === 32) {
              message.push(this.$t("message_password_characters_error"));
              break;
            }
            if (characterNum >= 48 && characterNum <= 57) {
              number = number + 1;
            }
            if (characterNum >= 65 && characterNum <= 90) {
              alpha = alpha + 1;
            }
            if (characterNum >= 97 && characterNum <= 122) {
              alpha = alpha + 1;
            }
          }
          if (number == 0 || alpha == 0) {
            message.push(this.$t("message_password_characters_error"));
          }
          if (message.length > 0) {
            this.$emit("message", message);
            return;
          }

          this.button.disabled = true;
          this.button.processing = true;
          this.entity.pass_old = this.entity.pass_old
            ? this.entity.pass_old.trim().replace(/\s\s+/g, " ")
            : "";
          this.entity.pass_new = this.entity.pass_new
            ? this.entity.pass_new.trim().replace(/\s\s+/g, " ")
            : "";
          this.entity.pass_confirm = this.entity.pass_confirm
            ? this.entity.pass_confirm.trim().replace(/\s\s+/g, " ")
            : "";
          try {
            let response = await meControllerFunc.resetPassword(
              {
                pass_confirm: this.entity.pass_confirm,
                pass_new: this.entity.pass_new,
                pass_old: this.entity.pass_old,
              },
              this.$store.getters.getAccessToken
            );
            if (response.status === 200 || response.status === 204) {
              try {
                await oauthFunc.logout(this.$store.getters.getAccessToken);
              } catch (errorLogout) {}
              this.$store.dispatch("clearToken");
              if (this.$route.path != "/login")
                this.$router.push({
                  path: "/login",
                  query: {
                    next: btoa(this.$route.path),
                  },
                });
              this.$toast.success({
                title: this.$t("title_message_info"),
                message: this.$t("message_changepass_success"),
                position: "top right",
              });
              this.onClose();
            } else this.$emit("message", [this.$t("message_changepass_error")]);
          } catch (error) {
            if (error.response) {
              if (error.response.status === 401) {
                this.count = this.count + 1;
                await this.refreshToken(this.onSubmit);
              } else {
                if (error.response.data) {
                  this.$emit(
                    "message",
                    error.response.data.message
                      ? error.response.data.message
                      : vm.$t("message_changepass_error")
                  );
                } else {
                  this.$emit("message", [this.$t("message_changepass_error")]);
                }
              }
            } else {
              this.count = this.count + 1;
              await this.refreshToken(this.onSubmit);
            }
          }
          this.button.disabled = false;
          this.button.processing = false;
        }
      });
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
};
</script>
<style lang="scss" scoped>
.change-pass {
  padding: 0.5rem 0.125rem;
  padding-bottom: 0;
  .header-icons {
    position: absolute;
    right: 0.625rem;
    top: 0.625rem;

    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    button {
      width: 2rem;
      height: 2rem;
      color: #6c757d;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      -webkit-transition: background-color 0.2s, color 0.2s,
        -webkit-box-shadow 0.2s;
      transition: background-color 0.2s, color 0.2s, -webkit-box-shadow 0.2s;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s,
        -webkit-box-shadow 0.2s;
      &:hover {
        color: #495057;
        border-color: transparent;
        background: #e9ecef;
      }
    }
  }
  .btn-changepass {
    font-size: 14px;
  }
}
</style>