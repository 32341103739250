import axios from 'axios';
import basicApi from "@/utils/apis/basic";
import app from './../../main'
import { geoServiceApiURL } from "@/utils/commons/environment"
export default {
  async getByPageSize(page, size, access_token) {
    let headers = basicApi.project.get_all.headers;
    if(!headers)
      headers= {}
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object =  axios({
      method: basicApi.project.get_all.method,
      url: geoServiceApiURL + basicApi.project.get_all.url,
      params: {
        page: page,
        size: size,
        lang: app.$store.getters.getLang
      },
      headers: headers,
    });

    let response = await object;
    return response;
  },
  async search(params,data, access_token) {
    let headers = basicApi.project.search.headers;
    if(!headers)
      headers= {}
    headers["Authorization"] = "bearer " + access_token;
    headers["Content-Type"] = "application/json";
    headers['Accept-Language'] = app.$store.getters.getLang;
    if (!params) params = {};
    params.lang = app.$store.getters.getLang;
    const object =  axios({
      method: basicApi.project.search.method,
      url:geoServiceApiURL + basicApi.project.search.url,
      params:params,
      data:data,
      headers: headers,
    });

    let response = await object;
    return response;
  },

  async searchProject(text,page, size,is_delete, access_token) {
    let headers = basicApi.project.search_project.headers;
    if(!headers)
      headers= {}
    headers["Authorization"] = "bearer " + access_token;
    headers["Content-Type"] = "application/json";
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object =  axios({
      method: basicApi.project.search_project.method,
      url:geoServiceApiURL + basicApi.project.search_project.url,
      params: {
        page: page,
        size: size,
        text:text,
        is_delete: is_delete,        
        lang: app.$store.getters.getLang
      },
      data: {
        name:text
      },
      headers: headers,
    });

    let response = await object;
    return response;
  },


  async getAll(access_token) {
    let headers = basicApi.project.get_all.headers;
    if(!headers)
      headers= {}
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object =  axios({
      method: basicApi.project.get_all.method,
      url: geoServiceApiURL + basicApi.project.get_all.url,
      headers:headers,
      params: {
        page: 0,
        size: 2147483647,
        lang: app.$store.getters.getLang
      },
    });
    let response = await object;
    return response;
  },

  async create(entity, access_token) {
    let headers = basicApi.project.create.headers;
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.project.create.method,
      url:  geoServiceApiURL + basicApi.project.create.url,
      data: entity,
      headers: headers,
      params: {
        lang:app.$store.getters.getLang
      }
    });
    let response = await object;
    return response;
  },

  async update(entity, access_token) {
    let headers = basicApi.project.update.headers;
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.project.update.method,
      url: geoServiceApiURL + basicApi.project.update.url.replace("{id}",entity.id),
      data: entity,
      headers: headers,
      params: {
        lang:app.$store.getters.getLang
      }
    });
    let response = await object;
    return response;
  },

  async delete(id, access_token) {
    let headers = basicApi.project.delete.headers;
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.project.delete.method,
      url: geoServiceApiURL + basicApi.project.delete.url.replace("{id}",id),
      headers: headers,
      params: {
        lang:app.$store.getters.getLang
      }
    });
    let response = await object;
    return response;
  },

  async getById(id, access_token) {
    let headers = basicApi.project.get_by_id.headers;
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.project.get_by_id.method,
      url: geoServiceApiURL + basicApi.project.get_by_id.url.replace("{id}",id),
      headers: headers,
      params: {
        lang:app.$store.getters.getLang
      }
    });

    let response = await object;
    return response;
  },
  
  async addImage(projectId,imageId, access_token) {
    let headers = basicApi.project.add_image.headers;
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.project.add_image.method,
      url: geoServiceApiURL + basicApi.project.add_image.url.replace("{project_id}",projectId),
      headers: headers,
      params:{
        image_id: imageId,        
        lang:app.$store.getters.getLang
      }
    });

    let response = await object;
    return response;
  },
  async changeStatus(projectId,statusId, access_token) {
    let headers = basicApi.project.change_status.headers;
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.project.change_status.method,
      url: geoServiceApiURL + basicApi.project.change_status.url.replace("{project_id}",projectId),
      headers: headers,
      params:{
        image_id: statusId,        
        lang:app.$store.getters.getLang
      }
    });

    let response = await object;
    return response;
  },
   async uploadAttachment(projectId,path, fileName, access_token) {
     let headers = basicApi.project.upload_attachment.headers;
     if(!headers) headers= {}
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.project.upload_attachment.method,
      url: geoServiceApiURL + basicApi.project.upload_attachment.url.replace("{project_id}",projectId),
      headers: headers,
      params:{    
        lang:app.$store.getters.getLang,
        path:path,
        file_name :fileName
      },
    });

    let response = await object;
    return response;
  },
  async deleteAttachment(projectId,fileId, access_token) {
    let headers = basicApi.project.delete_attachment.headers;
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.project.delete_attachment.method,
      url: geoServiceApiURL + basicApi.project.delete_attachment.url.replace("{project_id}",projectId),
      headers: headers,
      params: {
        lang: app.$store.getters.getLang,
        file_id:fileId
      }
    });
    let response = await object;
    return response;
  },
  async downloadAttachment(projectId,fileId, access_token) {
    let headers = basicApi.project.download_attachment.headers;
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.project.download_attachment.method,
      url: geoServiceApiURL + basicApi.project.download_attachment.url.replace("{project_id}",projectId),
      headers: headers,
      params: {
        lang: app.$store.getters.getLang,
        file_id:fileId
      },
      //responseType: "blob" // important
    });
    let response = await object;
    return response;
  },
  async createLinkUpload(projectId, contentType, path, access_token){
    let headers = basicApi.project.create_link_upload.headers;
    if(!headers)
      headers= {}
    headers["Authorization"] = "bearer " + access_token;
    headers["Content-Type"] = "application/json";
    headers['Accept-Language'] = app.$store.getters.getLang;
    let params = {
      "content-type":contentType,
      path:path,
      id:projectId,
      projectId:projectId
    };
    params.lang = app.$store.getters.getLang;
    const object =  axios({
      method: basicApi.project.create_link_upload.method,
      url:geoServiceApiURL + basicApi.project.create_link_upload.url.replace("{projectId}",projectId),
      params:params,
      headers: headers,
    });

    let response = await object;
    return response;
  },
  async uploadFilePresignedUrl(url,file,contentType){
    const object =  axios({
      method: "PUT",
      url: url,
      data:file,
      headers:{
        "Content-Type":contentType ? contentType : "application/zip"
      }
    });

    let response = await object;
    return response;
  }
}