<template>
  <Dialog
    :header="$t('title_update_metadata')"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :maximizable="false"
    :modal="true"
    :contentStyle="propStyle"
    @hide="onClose"
    @maximizedChage="maximizedChage"
  >
    <div class="Iybw1Bhj3Jhd8s update-metadata">
      <div>
        <table class="table">
          <tbody>
            <tr>
              <td
                align="left"
                width="30%"
                style="vertical-align: top"
                v-if="!maximized"
              >
                <div>
                  <table
                    class="table-images w-100"
                    cellspacing="0"
                    cellpadding="0"
                  >
                    <tbody>
                      <tr>
                        <td class="p-0">
                          <p class="border-radius-4 bg-A1A5B7">
                            {{ $t("label_icon_image") }}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="p-0 pt-2">
                          <div
                            class="
                              d-flex
                              justify-content-between
                              align-items-center
                            "
                          >
                            <div class="w-100">
                              <Dropdown
                                v-if="!iconFile"
                                class="w-100"
                                v-model="entity.icon_path"
                                :showClear="false"
                                :options="icons"
                                optionValue="path"
                                optionLabel="path"
                              />
                              <input
                                v-else
                                readonly
                                :value="iconFile.name"
                                class="form-control w-100"
                                :title="iconFile.name"
                              />
                            </div>
                            <div class="upload-btn-wrapper" v-if="!iconFile">
                              <button class="btn btn-primary btn-sm ml-1 mr-2">
                                <i class="fas fa-plus"></i>
                              </button>
                              <input
                                type="file"
                                name="iconfile"
                                accept="image/x-png,image/gif,image/jpeg,image/jpg,image/tiff"
                                @change="fileIconChanged"
                              />
                            </div>

                            <button
                              v-if="iconFile"
                              class="btn btn-danger btn-sm ml-1"
                              @click="
                                iconFile = null;
                                iconSrc = null;
                              "
                            >
                              <i class="fas fa-times"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="p-0 pt-2 pb-2">
                          <div class="preview-icon" v-if="iconSrc">
                            <img :src="iconSrc" class="preview-image" />
                          </div>
                          <ImagePreview
                            v-else
                            :propId="entity.icon_path ? entity.icon_path : null"
                            :propSrc="
                              entity.icon_path ? entity.icon_path : null
                            "
                            :propPreviewImages="icons"
                            @mapUpdateSize="mapUpdateSize"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="p-0">
                          <p class="border-radius-4 bg-A1A5B7">
                            {{ $t("label_preview_image") }}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="p-0 pt-2">
                          <div
                            class="
                              d-flex
                              justify-content-between
                              align-items-center
                            "
                          >
                            <div class="w-100">
                              <Dropdown
                                v-if="!previewFile"
                                class="w-100"
                                v-model="entity.preview_path"
                                :showClear="false"
                                :options="previews"
                                optionValue="path"
                                optionLabel="path"
                              />
                              <input
                                v-else
                                readonly
                                :value="previewFile.name"
                                class="form-control w-100"
                                :title="previewFile.name"
                              />
                            </div>
                            <div class="upload-btn-wrapper" v-if="!previewFile">
                              <button class="btn btn-primary btn-sm ml-1 mr-2">
                                <i class="fas fa-plus"></i>
                              </button>
                              <input
                                type="file"
                                name="previewfile"
                                accept="image/x-png,image/gif,image/jpeg,image/jpg,image/tiff"
                                @change="filePreviewChanged"
                              />
                            </div>
                            <button
                              v-if="previewFile"
                              class="btn btn-danger btn-sm ml-1"
                              @click="
                                previewFile = null;
                                previewSrc = null;
                              "
                            >
                              <i class="fas fa-times"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="p-0 pt-2">
                          <div class="preview-icon" v-if="previewSrc">
                            <img :src="previewSrc" class="preview-image" />
                          </div>
                          <ImagePreview
                            v-else
                            :propId="
                              entity.preview_path ? entity.preview_path : null
                            "
                            :propSrc="
                              entity.preview_path ? entity.preview_path : null
                            "
                            :propPreviewImages="previews"
                            @mapUpdateSize="mapUpdateSize"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="update-map">
                  <div class="map-bounding-box" id="mapBoundingBox"></div>
                </div>
              </td>
              <td
                align="left"
                style="vertical-align: top"
                :style="[maximized ? { width: '30%' } : { width: '70%' }]"
              >
                <p class="border-radius-4 bg-A1A5B7">
                  {{ $t("label_values") }}
                </p>
                <div>
                  <table
                    class="table-metadata w-100"
                    id="tableMetadata"
                    cellspacing="0"
                    cellpadding="0"
                  >
                    <tbody>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("menu_category_data_catalog") }}
                            <span class="required">&nbsp;*</span></span
                          >
                        </td>
                        <td>
                          <span>{{
                            propDataCatalog &&
                            propDataCatalog.translation &&
                            propDataCatalog.translation[lang] &&
                            propDataCatalog.translation[lang].description
                              ? propDataCatalog.translation[lang].description
                              : propDataCatalog && propDataCatalog.description
                              ? propDataCatalog.description
                              : ""
                          }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0" style="width: 10rem">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_source_id") }}</span
                          >
                        </td>
                        <td class="d-flex align-items-center">
                          <span
                            :title="
                              entity.scene_id && entity.scene_id.length > 48
                                ? entity.scene_id
                                : null
                            "
                            >{{
                              entity.scene_id && entity.scene_id.length > 48
                                ? entity.scene_id.substring(0, 45) + "..."
                                : entity.scene_id
                            }}</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_image_name") }}</span
                          >
                        </td>
                        <td>
                          <InputText v-model="entity.name" class="w-100" />
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_satellite") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <treeselect
                            class="w-100"
                            v-model="entity.satellite_id"
                            name="treeSatellite"
                            :multiple="false"
                            :options="propProvider"
                            :noChildrenText="$t('label_no_sub_options')"
                            :noOptionsText="$t('label_no_options_text')"
                            :noResultsText="$t('label_no_results_text')"
                            :disableBranchNodes="true"
                            placeholder=""
                            :default-expand-level="1"
                            @select="selectSatelliteChange"
                            @deselect="deselectSatellite"
                          >
                            <label slot="option-label" slot-scope="{ node }">{{
                              node.raw.label
                            }}</label>
                            <div slot="value-label" slot-scope="{ node }">
                              {{ node.raw.label }}
                            </div>
                          </treeselect>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_image_time") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <date-picker
                            class="w-100"
                            v-model="entity.imaging_date_timestamp"
                            :format="'DD/MM/YYYY HH:mm'"
                            valueType="timestamp"
                            :clearable="false"
                            :disabled-date="notAfterToday"
                            type="datetime"
                            :show-time-panel="showTimePanel"
                            @close="handleOpenChange"
                          >
                            <template v-slot:footer>
                              <button
                                class="mx-btn mx-btn-text"
                                @click="toggleTimePanel"
                              >
                                {{
                                  showTimePanel
                                    ? $t("button_text_select_date")
                                    : $t("button_text_select_time")
                                }}
                              </button>
                            </template>
                          </date-picker>
                        </td>
                      </tr>

                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_incidence_angle") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <div class="row no-gutters">
                            <div class="col-8">
                              <InputText
                                v-model="entity.incidence_angle"
                                class="w-100"
                              />
                            </div>
                            <div class="col-4 pl-2">
                              <InputText
                                v-model="entity.incidence_angle_unit"
                                class="w-100"
                                :placeholder="$t('label_unit')"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_resolution") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <div class="row no-gutters">
                            <div class="col-8">
                              <InputText
                                v-model="entity.resolution"
                                class="w-100"
                                :placeholder="$t('label_value')"
                              />
                            </div>
                            <div class="col-4 pl-2">
                              <InputText
                                v-model="entity.resolution_unit"
                                class="w-100"
                                :placeholder="$t('label_unit')"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_processing_level") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <Dropdown
                            class="w-100"
                            v-model="entity.processing_level_name"
                            :showClear="false"
                            :options="propProcessingLevel"
                            optionValue="name"
                            optionLabel="value"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              d-block
                              line-height-56
                              pl-2
                            "
                            >{{ $t("label_boundary")
                            }}<span class="required">&nbsp;*</span></span
                          >
                        </td>
                        <td class="pt-0">
                          <PolygonPoints
                            :propCoordinates="coordinates"
                            ref="polygonPoints"
                            @inputUpperLeftChange="inputUpperLeftChange"
                            @inputUpperRightChange="inputUpperRightChange"
                            @inputLowerLeftChange="inputLowerLeftChange"
                            @inputLowerRightChange="inputLowerRightChange"
                          />
                        </td>
                      </tr>
                      <tr
                        v-for="item in (entity.metadata
                          ? entity.metadata
                          : []
                        ).filter(
                          (x) =>
                            x &&
                            x.active &&
                            x.key != 'boundary' &&
                            !fieldsIgnore.includes(x.key)
                        )"
                        :key="item.id"
                      >
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ item.name }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <div class="row no-gutters">
                            <div class="col-8">
                              <InputText
                                v-model="item.value"
                                class="w-100"
                                :placeholder="$t('label_value')"
                              />
                            </div>
                            <div class="col-4 pl-2">
                              <InputText
                                v-model="item.unit"
                                class="w-100"
                                :placeholder="$t('label_unit')"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr v-for="item in fieldsAddNew" :key="item.gen_id">
                        <td class="pl-0 pr-0 pt-0">
                          <span class="border-radius-4 w-100 d-block"
                            ><InputText
                              v-model="item.name"
                              class="w-100"
                              maxlength="255"
                              :placeholder="$t('label_field_name')"
                          /></span>
                        </td>
                        <td class="pt-0">
                          <div class="row no-gutters">
                            <div class="col-4">
                              <InputText
                                v-model="item.key"
                                class="w-100"
                                :placeholder="$t('label_key')"
                              />
                            </div>
                            <div class="col-4">
                              <InputText
                                v-model="item.value"
                                class="w-100"
                                :placeholder="$t('label_value')"
                              />
                            </div>
                            <div class="col-4 pl-2">
                              <InputText
                                v-model="item.unit"
                                class="w-100"
                                :placeholder="$t('label_unit')"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" class="text-right">
                          <button
                            class="btn btn-primary btn-sm"
                            @click="addNewField"
                          >
                            <i class="fas fa-plus"></i>&nbsp;
                            {{ $t("button_add_new_field") }}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <tile
        v-if="propItem.loading_data"
        :loading="propItem.loading_data"
        :height="'40'"
        :width="'40'"
        :color="'fd853e'"
        class="ehAbnmg4Jwb9BS"
      ></tile>
    </div>
    <template #footer>
      <Button
        v-if="$store.getters.getPermissions.includes('IMAGE_UPDATE_IMAGE')"
        :label="$t('button_text_save')"
        :icon="
          propButton && propButton.processing
            ? 'fa fa-spinner fa-spin'
            : 'fas fa-save'
        "
        :class="'p-button-success mr-0'"
        @click="handleUpdateMetadata"
        :disabled="propItem.loading_data"
      />
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
import Button from "@/components/commons/button/Button";
import MapOpenlayer from "./updatemetadata/Map";
import QuickLook from "./updatemetadata/QuickLook";
import InputText from "@/components/commons/inputtext/InputText";
import Textarea from "@/components/commons/textarea/Textarea";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import ImagePreview from "./updatemetadata/ImagePreview.vue";
import DatePicker from "vue2-datepicker";
import AutoComplete from "@/components/commons/autocomplete/AutoComplete";
import Treeselect from "@riophae/vue-treeselect";
import { TileSpinner } from "vue-spinners";
import PolygonPoints from "./updatemetadata/Polygon";
import moment from "moment";
import {
  change_alias,
  getFileExtension1,
  getFileExtension2,
  getFileExtension3,
  convertProjection2WGS84,
} from "@/utils/commons/common";
import Map from "ol/Map";
import View from "ol/View";
import { Tile as TileLayer } from "ol/layer";
import XYZ from "ol/source/XYZ";
import { TileImage } from "ol/source";
import { defaults as defaultControls, FullScreen } from "ol/control";
import { defaults } from "ol/interaction";
import { fromLonLat, transform } from "ol/proj";
import Feature from "ol/Feature";
import { Vector as VectorLayer, Image as ImageLayer } from "ol/layer";
import { Vector as VectorSource, ImageCanvas } from "ol/source";
import { Modify, Select } from "ol/interaction.js";
import Draw from "ol/interaction/Draw";
import { unByKey } from "ol/Observable.js";
import Collection from "ol/Collection";
import { getArea, getLength } from "ol/sphere.js";
import { LineString, Polygon, MultiPoint, Point } from "ol/geom.js";
import { getCenter, getTopLeft } from "ol/extent";
import {
  Style,
  Fill,
  Stroke,
  Circle,
  Icon,
  Text,
  RegularShape,
} from "ol/style.js";
export default {
  components: {
    Dialog,
    Button,
    MapOpenlayer,
    QuickLook,
    Tile: TileSpinner,
    InputText,
    Textarea,
    Dropdown,
    ImagePreview,
    DatePicker,
    Treeselect,
    AutoComplete,
    PolygonPoints,
  },
  props: {
    propTitle: {
      type: String,
      default: "",
    },
    propStyle: {
      type: Object,
      default: () => {
        return {
          width: "400px",
        };
      },
    },
    propShow: {
      type: Boolean,
      default: false,
    },
    propItem: {
      type: Object,
      default: () => {},
    },

    propProvider: {
      type: Array,
      default: () => [],
    },
    propProcessingLevel: {
      type: Array,
      default: () => [],
    },
    propBeamMode: {
      type: Array,
      default: () => [],
    },
    propDirection: {
      type: Array,
      default: () => [],
    },
    propPolLayer: {
      type: Array,
      default: () => [],
    },
    propSatellites: { type: Array, default: () => [] },
    propButton: {
      type: Object,
      default: () => {
        return {
          disabled: false,
          processing: false,
        };
      },
    },
    propDataCatalog: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
      showTimePanel: false,
      maximized: false,
      isEdit: true,
      previews: [],
      icons: [],
      arrayBeamMode: [],
      arrayDirection: [],
      arrayPolLayer: [],
      fieldsIgnore: [],
      fieldsAddNew: [],
      entity: {
        area: null,
        area_unit: null,
        band: null,
        category_id: null,
        cloud_coverage: null,
        cloud_coverage_unit: null,
        description: null,
        google_earth_path: null,
        image_type: null,
        imaging_date: null,
        imaging_date_timestamp: null,
        incidence_angle: null,
        incidence_angle_unit: null,
        metadata: [],
        extend: {},
        name: null,
        resolution: null,
        resolution_unit: null,
        snow_coverage: null,
        snow_coverage_unit: null,
        processing_level: null,
        processing_level_id: null,
        mode: null,
        beam_mode: null,
        direction: null,
        pol_layer: null,
        satellite: null,
        satellite_id: null,
        bounding_box: [],
        provider: null,
        provider_id: null,
        file_path: null,
        icon_path: null,
        icon_path_id: null,
        icon_file: null,
        icon_src: null,
        preview_images: [],
        preview_path: null,
        preview_file: null,
        preview_src: null,
        preview_path_id: null,
        price: 0,
        price_unit: null,
        processing_level_name: null,
        provider_name: null,
        satellite_name: null,
        size: 0,
        scene_id: null,
        preview: {
          preview_images: [],
        },
      },
      previewFile: null,
      previewSrc: null,
      iconFile: null,
      iconSrc: null,
      map: Object,
      layer: Object,
      layerLabel: Object,
      feature: null,
      featureDraw: null,
      draw: Object,
      modify: Object,
      select: Object,
      coordinates: [
        {
          lat: null,
          lng: null,
        },
        {
          lat: null,
          lng: null,
        },
        {
          lat: null,
          lng: null,
        },
        {
          lat: null,
          lng: null,
        },
      ],
    };
  },
  async created() {
    this.show = this.propShow;
    if (!this.propProvider || this.propProvider.length === 0)
      await this.$emit("getAllProvider");
    if (!this.propProcessingLevel || this.propProcessingLevel.length === 0)
      await this.$emit("getAllProcessingLevel");
    if (!this.propSatellites || this.propSatellites.length === 0)
      await this.$emit("getAllSatellite");
    // if (!this.propBeamMode || this.propBeamMode.length === 0)
    //   await this.$emit("getAllBeamMode");
    // if (!this.propDirection || this.propDirection.length === 0)
    //   await this.$emit("getAllDirection");
    // if (!this.propPolLayer || this.propPolLayer.length === 0)
    //   await this.$emit("getAllPolLayer");
  },
  mounted() {
    var vm = this;
    var source = new VectorSource({ wrapX: false, id: "sourcedraw" });

    this.layer = new VectorLayer({
      source: source,
      style: new Style({
        fill: new Fill({
          color: this.hexToRgbA("#fab710", 0.5), //"transparent",
        }),
        stroke: new Stroke({
          color: "#FFBF23",
          width: 2,
        }),
      }),
    });

    this.layer.set("id", "layerdraw");

    const source2 = new VectorSource({
      wrapX: false,
    });
    this.layerLabel = new VectorLayer({
      source: source2,
      id: "labelLayer",
      style: new Style({
        fill: new Fill({
          color: this.hexToRgbA("#fff", 1),
        }),
        stroke: new Stroke({
          color: this.hexToRgbA("#000", 0.5),
          width: 2,
        }),
      }),
    });
    this.map = new Map({
      controls: defaultControls({ attribution: false, zoom: false }),
      interactions: defaults({ doubleClickZoom: false }),
      layers: [
        new TileLayer({
          title: "Basemap",
          id: "vgbasemap",
          source: new XYZ({
            url:
              vm.basemap &&
              Object.keys(vm.basemap).length > 0 &&
              vm.basemap.source &&
              vm.basemap.source.url
                ? vm.basemap.source.url
                : process.env.VUE_APP_BASEMAP_URL,
          }),
          show: true,
          opacity: 1,
        }),
        this.layer,
        this.layerLabel,
      ],
      target: "mapBoundingBox",
      view: new View({
        center: fromLonLat([108.222848, 16.062833]),
        zoom: 8,
        minZoom: 0,
        maxZoom: 20,
      }),
    });
    if (this.propItem && Object.keys(this.propItem).length > 0) {
      if (
        this.propItem.entity &&
        Object.keys(this.propItem.entity).length > 0
      ) {
        this.entity = Object.assign({}, this.propItem.entity);
      } else {
        this.assignData(this.propItem.data_file);
      }
    }
  },
  watch: {},
  methods: {
    selectSatelliteChange(e) {
      this.entity.satellite_name = e.name;
    },
    deselectSatellite() {
      this.entity.satellite = null;
      this.entity.satellite_name = null;
    },
    addNewField() {
      this.fieldsAddNew.push({
        active: true,
        id: null,
        key: null,
        name: null,
        unit: null,
        value: null,
        gen_id: new Date().getTime() + Math.floor(Math.random() * 999),
      });
    },
    async filePreviewChanged(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.previewFile = files[0];
      let extension = getFileExtension3(this.previewFile.name);
      if (
        ["tif", "tiff"].includes(extension ? extension.toLowerCase() : null)
      ) {
        const tiff = await GeoTIFF.fromBlob(this.previewFile);
        const image = await tiff.getImage();
        let geoKeys = image.getGeoKeys();
        const data = await image.readRasters();
        const canvas = document.createElement("canvas");
        const plot = new plotty.plot({
          canvas,
          data: data[0],
          width: image.getWidth(),
          height: image.getHeight(),
          domain: [0, 256],
          colorScale: "greys",
        });
        plot.render();
        this.previewSrc = canvas.toDataURL("image/tiff");
        const bbox = image.getBoundingBox();
        if (bbox && bbox.length > 3) {
          if (
            geoKeys &&
            geoKeys.ProjectedCSTypeGeoKey &&
            geoKeys.ProjectedCSTypeGeoKey != 4326
          ) {
            let minCoordinate = convertProjection2WGS84(
              geoKeys.ProjectedCSTypeGeoKey,
              {
                x: bbox[0],
                y: bbox[1],
              }
            );
            let maxCoordinate = convertProjection2WGS84(
              geoKeys.ProjectedCSTypeGeoKey,
              {
                x: bbox[2],
                y: bbox[3],
              }
            );
            if (
              minCoordinate &&
              Object.keys(minCoordinate).length > 0 &&
              maxCoordinate &&
              Object.keys(maxCoordinate).length > 0
            ) {
              this.coordinates[0].lng = minCoordinate.x;
              this.coordinates[0].lat = maxCoordinate.y;

              this.coordinates[1].lng = maxCoordinate.x;
              this.coordinates[1].lat = maxCoordinate.y;

              this.coordinates[2].lng = maxCoordinate.x;
              this.coordinates[2].lat = minCoordinate.y;

              this.coordinates[3].lng = minCoordinate.x;
              this.coordinates[3].lat = minCoordinate.y;
              this.polygonChange();
            }
          } else {
            this.coordinates[0].lng = bbox[0];
            this.coordinates[0].lat = bbox[3];

            this.coordinates[1].lng = bbox[2];
            this.coordinates[1].lat = bbox[3];

            this.coordinates[2].lng = bbox[2];
            this.coordinates[2].lat = bbox[1];

            this.coordinates[3].lng = bbox[0];
            this.coordinates[3].lat = bbox[1];
            this.polygonChange();
          }
          if (this.$refs.polygonPoints) {
            this.$refs.polygonPoints.initVertices();
          }
        }
      } else {
        this.previewSrc = URL.createObjectURL(this.previewFile);
      }
    },
    async fileIconChanged(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.iconFile = files[0];
      let extension = getFileExtension3(this.iconFile.name);
      if (
        ["tif", "tiff"].includes(extension ? extension.toLowerCase() : null)
      ) {
        const tiff = await GeoTIFF.fromBlob(this.iconFile);
        const image = await tiff.getImage();
        const data = await image.readRasters();
        const canvas = document.createElement("canvas");
        const plot = new plotty.plot({
          canvas,
          data: data[0],
          width: image.getWidth(),
          height: image.getHeight(),
          domain: [0, 256],
          colorScale: "greys",
        });
        plot.render();
        this.iconSrc = canvas.toDataURL("image/tiff");
      } else {
        this.iconSrc = URL.createObjectURL(this.iconFile);
      }
    },
    searchPolLayer(e) {
      this.arrayPolLayer = this.propPolLayer
        .filter((x) =>
          change_alias(x.value.trim().split(" ").join("")).startsWith(
            change_alias(e.query.toLowerCase().split(" ").join(""))
          )
        )
        .map((x) => x.value);
    },
    searchDirection(e) {
      this.arrayDirection = this.propDirection
        .filter((x) =>
          change_alias(x.value.trim().split(" ").join("")).startsWith(
            change_alias(e.query.toLowerCase().split(" ").join(""))
          )
        )
        .map((x) => x.value);
    },
    searchBeamMode(e) {
      this.arrayBeamMode = this.propBeamMode
        .filter((x) =>
          change_alias(x.value.trim().split(" ").join("")).startsWith(
            change_alias(e.query.toLowerCase().split(" ").join(""))
          )
        )
        .map((x) => x.value);
    },
    itemSelectBeamMode(item) {
      if (item && item.value) {
      }
    },
    itemUnselectBeamMode(event) {},
    clearItemBeamMode(e) {},

    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel;
    },
    handleOpenChange() {
      this.showTimePanel = false;
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    assignData(data) {
      this.entity = this.generateEntity();
      this.previews = [];
      this.icons = [];
      if (data && Object.keys(data).length > 0) {
        this.entity.scene_id = data.scene_id;
        this.entity.preview = data.preview
          ? data.preview
          : { preview_images: [] };
        this.entity.icon_path =
          data.preview && data.preview.icon_path
            ? data.preview.icon_path
            : null;
        this.entity.preview_path =
          data.preview && data.preview.preview_path
            ? data.preview.preview_path
            : null;
        this.entity.id = data.id;
        this.entity.preview_images =
          data.preview && data.preview.preview_images
            ? data.preview.preview_images
            : [];
        if (
          this.entity.preview_images &&
          this.entity.preview_images.length > 0
        ) {
          this.previews = this.entity.preview_images.filter(
            (x) => x.type === "PREVIEW"
          );
          this.icons = this.entity.preview_images.filter(
            (x) => x.type === "ICON"
          );
          let googleEarths = this.entity.preview_images.filter(
            (x) => x.type === "GOOGLE_EARTH"
          );
          if (googleEarths.length > 0)
            this.entity.google_earth_path = googleEarths[0].path_image;
        }
        this.entity.metadata = data.metadata ? data.metadata : [];
        this.entity.name = data.name ? data.name.trim() : null;
        if (!this.entity.name) {
          let index = this.entity.metadata.findIndex(
            (x) => x.key === "image_name"
          );
          if (index >= 0) this.entity.name = this.entity.metadata[index].value;
        }
        if (this.entity.name) {
          this.fieldsIgnore.push("image_name");
        }
        this.entity.description = data.description
          ? data.description.trim().replace(/\s\s+/g, " ")
          : null;
        this.entity.file_path = data.file_path ? data.file_path.trim() : null;

        this.entity.imaging_date =
          data.info && data.info.imaging_date ? data.info.imaging_date : null;
        if (!this.entity.imaging_date) {
          let index = this.entity.metadata.findIndex(
            (x) => x.key === "imaging_date"
          );
          if (index >= 0)
            this.entity.imaging_date = this.entity.metadata[index].value;
        }
        if (this.entity.imaging_date) {
          this.fieldsIgnore.push("imaging_date");
          try {
            this.entity.imaging_date_timestamp = new Date(
              this.entity.imaging_date
            ).getTime();
          } catch (error) {}
        }
        this.entity.incidence_angle =
          data.info &&
          data.info.incidence_angle != null &&
          data.info.incidence_angle != undefined
            ? data.info.incidence_angle
            : null;
        if (!this.entity.incidence_angle) {
          let index = this.entity.metadata.findIndex(
            (x) => x.key === "incidence_angle"
          );
          if (index >= 0) {
            this.entity.incidence_angle = this.entity.metadata[index].value
              ? (this.entity.metadata[index].value + "")
                  .trim()
                  .replace(/\s\s+/g, " ")
              : null;
            this.entity.incidence_angle_unit = this.entity.metadata[index].unit
              ? this.entity.metadata[index].unit.trim().replace(/\s\s+/g, " ")
              : null;
          }
        }
        if (!this.entity.incidence_angle_unit) {
          let index = this.entity.metadata.findIndex(
            (x) => x.key === "incidence_angle"
          );
          if (index >= 0) {
            this.entity.incidence_angle_unit = this.entity.metadata[index].unit
              ? this.entity.metadata[index].unit.trim().replace(/\s\s+/g, " ")
              : null;
          }
        }

        this.fieldsIgnore.push("incidence_angle");

        this.entity.resolution =
          data.info &&
          data.info.resolution != null &&
          data.info.resolution != undefined
            ? data.info.resolution
            : null;
        if (!this.entity.resolution) {
          let index = this.entity.metadata.findIndex(
            (x) => x.key === "resolution"
          );
          if (index >= 0) {
            this.entity.resolution = this.entity.metadata[index].value
              ? (this.entity.metadata[index].value + "")
                  .trim()
                  .replace(/\s\s+/g, " ")
              : null;
            this.entity.resolution_unit = this.entity.metadata[index].unit
              ? this.entity.metadata[index].unit.trim().replace(/\s\s+/g, " ")
              : null;
          }
        }

        if (!this.entity.resolution_unit) {
          let index = this.entity.metadata.findIndex(
            (x) => x.key === "resolution"
          );
          if (index >= 0) {
            this.entity.resolution_unit = this.entity.metadata[index].unit
              ? this.entity.metadata[index].unit.trim().replace(/\s\s+/g, " ")
              : null;
          }
        }
        this.fieldsIgnore.push("resolution");

        if (data.info && data.info.satellite_id && data.info.provider_id) {
          let provider = this.propProvider.find(
            (x) => x.id === data.info.provider_id
          );
          if (provider && provider.children) {
            let s = provider.children.find(
              (x) => x.id === data.info.satellite_id
            );
            if (s && Object.keys(s).length > 0) {
              this.entity.satellite_id = s.id;
              this.entity.satellite_name = s.name;
              this.entity.satellite = s;
            }
          }
        }
        if (!this.entity.satellite) {
          this.entity.satellite =
            data.info && data.info.satellite && data.info.satellite
              ? data.info.satellite
              : null;
        }
        if (!this.entity.satellite) {
          if (this.propSatellites && data.info.satellite_id) {
            this.entity.satellite = this.propSatellites.find(
              (x) => x.id === data.info.satellite_id
            );
          }
        }
        if (this.entity.satellite) {
        }
        this.fieldsIgnore.push("satellite");
        if (
          this.entity.satellite &&
          Object.keys(this.entity.satellite).length > 0
        ) {
          this.entity.satellite_id = this.entity.satellite.id;
          this.entity.satellite_name = this.entity.satellite.name;
        }
        if (data.info && data.info.processing_level_id) {
          this.entity.processing_level = this.propProcessingLevel.find(
            (x) => x.id === data.info.processing_level_id
          );
        }
        if (!this.entity.processing_level) {
          this.entity.processing_level =
            data.info &&
            data.info.processing_level &&
            data.info.processing_level
              ? data.info.processing_level
              : null;
        }
        if (this.entity.processing_level)
          this.fieldsIgnore.push("processing_level");
        if (
          this.entity.processing_level &&
          Object.keys(this.entity.processing_level).length > 0
        ) {
          this.entity.processing_level_id = this.entity.processing_level.id;
          this.entity.processing_level_name =
            this.entity.processing_level.value;
        }

        if (
          data.geom &&
          data.geom.coordinates &&
          data.geom.coordinates.length > 0
        ) {
          if (data.geom.coordinates[0].length > 4) {
            this.coordinates[0].lat = data.geom.coordinates[0][0][1];
            this.coordinates[0].lng = data.geom.coordinates[0][0][0];

            this.coordinates[1].lat = data.geom.coordinates[0][1][1];
            this.coordinates[1].lng = data.geom.coordinates[0][1][0];

            this.coordinates[2].lat = data.geom.coordinates[0][2][1];
            this.coordinates[2].lng = data.geom.coordinates[0][2][0];

            this.coordinates[3].lat = data.geom.coordinates[0][3][1];
            this.coordinates[3].lng = data.geom.coordinates[0][3][0];
            this.polygonChange();
            if (this.$refs.polygonPoints) {
              this.$refs.polygonPoints.initVertices();
            }
          }
        }
      }
    },
    generateEntity() {
      return {
        area: null,
        area_unit: null,
        band: null,
        category_id: this.propCategoryId,
        cloud_coverage: null,
        cloud_coverage_unit: null,
        description: null,
        google_earth_path: null,
        image_type: null,
        imaging_date: null,
        imaging_date_timestamp: null,
        incidence_angle: null,
        incidence_angle_unit: null,
        metadata: [],
        extend: {},
        name: null,
        resolution: null,
        resolution_unit: null,
        snow_coverage: null,
        snow_coverage_unit: null,
        processing_level: null,
        processing_level_id: null,
        mode: null,
        beam_mode: null,
        direction: null,
        pol_layer: null,
        satellite: null,
        satellite_id: null,
        bounding_box: [],
        provider: null,
        provider_id: null,
        file_path: null,
        icon_path: null,
        icon_path_id: null,
        icon_file: null,
        preview_images: [],
        preview_path: null,
        preview_path_id: null,
        preview_file: null,
        preview: {
          preview_images: [],
        },
        price: 0,
        price_unit: null,
        processing_level_name: null,
        provider_name: null,
        satellite_name: null,
        size: 0,
        scene_id: null,
      };
    },
    onClose() {
      this.show = false;
      this.$emit("close");
    },
    handleUpdateMetadata() {
      let msg = [];
      if (!this.entity.name || !this.entity.name.trim()) {
        msg.push(this.$t("message_image_name_required"));
      }
      if (!this.entity.satellite_id || !this.entity.satellite_id.trim()) {
        msg.push(this.$t("message_satellite_required"));
      }
      if (!this.entity.imaging_date_timestamp) {
        msg.push(this.$t("message_image_time_is_required"));
      }
      if (
        this.coordinates[0].lat == undefined ||
        this.coordinates[0].lat == null ||
        this.coordinates[0].lng == undefined ||
        this.coordinates[0].lng == null ||
        this.coordinates[1].lat == undefined ||
        this.coordinates[1].lat == null ||
        this.coordinates[1].lng == undefined ||
        this.coordinates[1].lng == null ||
        this.coordinates[2].lat == undefined ||
        this.coordinates[2].lat == null ||
        this.coordinates[2].lng == undefined ||
        this.coordinates[2].lng == null ||
        this.coordinates[3].lat == undefined ||
        this.coordinates[3].lat == null ||
        this.coordinates[3].lng == undefined ||
        this.coordinates[3].lng == null
      ) {
        msg.push(this.$t("message_boundary_is_required"));
      }
      if (msg.length > 0) {
        this.$emit("message", msg);
        return;
      }
      this.entity.bounding_box = [];
      for (let i = 0; i < this.coordinates.length; i++) {
        this.entity.bounding_box.push([
          this.coordinates[i].lng,
          this.coordinates[i].lat,
        ]);
      }
      this.entity.bounding_box.push([
        this.coordinates[0].lng,
        this.coordinates[0].lat,
      ]);
      if (this.previewFile) this.entity.preview_file = this.previewFile;
      if (this.iconFile) {
        this.entity.icon_file = this.iconFile;
      }
      if (!this.entity.metadata) {
        this.entity.metadata = [];
      }
      if (this.fieldsIgnore && this.entity.metadata) {
        for (let i = 0; i < this.fieldsIgnore.length; i++) {
          let index = this.entity.metadata.findIndex(
            (x) => x.key === this.fieldsIgnore[i]
          );
          if (index >= 0) {
            switch (this.fieldsIgnore[i]) {
              case "satellite":
                this.entity.metadata[index].value = this.entity.satellite_name;
                break;
              case "imaging_date":
                try {
                  this.entity.metadata[index].value = new Date(
                    this.entity.imaging_date_timestamp
                  );
                } catch (error) {}
                break;
              case "incidence_angle":
                this.entity.metadata[index].value = this.entity.incidence_angle;
                this.entity.metadata[index].unit =
                  this.entity.incidence_angle_unit;
                break;
              case "resolution":
                this.entity.metadata[index].value = this.entity.resolution;
                this.entity.metadata[index].unit = this.entity.resolution_unit;
                break;
              case "processing_level":
                this.entity.metadata[index].value =
                  this.entity.processing_level_name;
                break;
              case "image_name":
                this.entity.metadata[index].value = this.entity.name
                  .trim()
                  .replace(/\s\s+/g, " ");
                break;
              default:
                break;
            }
          } else {
            switch (this.fieldsIgnore[i]) {
              case "incidence_angle":
                this.entity.metadata.push({
                  key: "incidence_angle",
                  name: "Incidence angle",
                  value: this.entity.incidence_angle,
                  unit: this.entity.incidence_angle_unit,
                });
                break;
              case "resolution":
                this.entity.metadata.push({
                  key: "resolution",
                  name: "Resolution",
                  value: this.entity.resolution,
                  unit: this.entity.resolution_unit,
                });
                break;
            }
          }
        }
      }
      if (this.fieldsAddNew) {
        for (let i = 0; i < this.fieldsAddNew.length; i++) {
          if (
            this.fieldsAddNew[i].name &&
            this.fieldsAddNew[i].name.trim().replace(/\s\s+/g, " ") &&
            this.fieldsAddNew[i].value &&
            this.fieldsAddNew[i].value.trim().replace(/\s\s+/g, " ")
          ) {
            this.entity.metadata.push(this.fieldsAddNew[i]);
          }
        }
      }
      for (let i = 0; i < this.entity.metadata.length; i++) {
        if (
          this.entity.metadata[i].value &&
          typeof this.entity.metadata[i].value === "string"
        )
          this.entity.metadata[i].value = this.entity.metadata[i].value
            .trim()
            .replace(/\s\s+/g, " ");
        if (this.entity.metadata[i].unit)
          this.entity.metadata[i].unit = this.entity.metadata[i].unit
            .trim()
            .replace(/\s\s+/g, " ");
      }
      this.$emit("confirmMetadata", this.entity);
    },
    handleEdit() {
      this.isEdit = !this.isEdit;
    },
    maximizedChage(val) {
      this.maximized = val;
    },
    formatUnixDateTime2String(value) {
      let result = "";
      if (value) {
        try {
          let date = new Date(value);
          result = moment
            .unix(date.getTime() / 1000)
            .format("HH:mm DD/MM/YYYY");
          result = result
            .replace(":", this.lang === "vi" ? "h" : ":")
            .replace(" ", this.lang === "vi" ? " ngày " : " ");
        } catch (error) {}
      }
      return result;
    },
    inputUpperLeftChange(data) {
      if (
        !this.coordinates[0] ||
        Object.keys(this.coordinates[0]).length === 0
      ) {
        this.coordinates[0] = { lat: null, lng: null };
      }
      this.coordinates[0].lat = data.lat ? data.lat : null;
      this.coordinates[0].lng = data.lng ? data.lng : null;
      this.polygonChange();
    },
    inputUpperRightChange(data) {
      if (
        !this.coordinates[1] ||
        Object.keys(this.coordinates[1]).length === 0
      ) {
        this.coordinates[1] = { lat: null, lng: null };
      }
      this.coordinates[1].lat = data.lat ? data.lat : null;
      this.coordinates[1].lng = data.lng ? data.lng : null;
      this.polygonChange();
    },
    inputLowerLeftChange(data) {
      if (
        !this.coordinates[3] ||
        Object.keys(this.coordinates[3]).length === 0
      ) {
        this.coordinates[3] = { lat: null, lng: null };
      }
      this.coordinates[3].lat = data.lat ? data.lat : null;
      this.coordinates[3].lng = data.lng ? data.lng : null;
      this.polygonChange();
    },
    inputLowerRightChange(data) {
      if (
        !this.coordinates[2] ||
        Object.keys(this.coordinates[2]).length === 0
      ) {
        this.coordinates[2] = { lat: null, lng: null };
      }
      this.coordinates[2].lat = data.lat ? data.lat : null;
      this.coordinates[2].lng = data.lng ? data.lng : null;
      this.polygonChange();
    },
    polygonChange() {
      if (
        this.coordinates[0].lat != undefined &&
        this.coordinates[0].lat != null &&
        this.coordinates[0].lng != undefined &&
        this.coordinates[0].lng != null &&
        this.coordinates[1].lat != undefined &&
        this.coordinates[1].lat != null &&
        this.coordinates[1].lng != undefined &&
        this.coordinates[1].lng != null &&
        this.coordinates[2].lat != undefined &&
        this.coordinates[2].lat != null &&
        this.coordinates[2].lng != undefined &&
        this.coordinates[2].lng != null &&
        this.coordinates[3].lat != undefined &&
        this.coordinates[3].lat != null &&
        this.coordinates[3].lng != undefined &&
        this.coordinates[3].lng != null
      ) {
        let points = [];

        this.layerLabel.getSource().clear();
        for (let i = 0; i < this.coordinates.length; i++) {
          points.push(
            transform(
              [this.coordinates[i].lng, this.coordinates[i].lat],
              "EPSG:4326",
              "EPSG:3857"
            )
          );
          let featureLabel = new Feature({
            type: "icon",
            geometry: new Point(
              transform(
                [this.coordinates[i].lng, this.coordinates[i].lat],
                "EPSG:4326",
                "EPSG:3857"
              )
            ),
          });
          featureLabel.setStyle(
            new Style({
              text: new Text({
                textAlign: "center",
                textBaseline: "middle",
                font: "16px Roboto, Helvetica Neue, Avenir, Helvetica, Arial, sans-serif",
                fill: new Fill({ color: this.hexToRgbA("#fff", 1) }),
                stroke: new Stroke({
                  color: this.hexToRgbA("#000", 0.5),
                  width: 3,
                }),
                text: i + 1 + "",
                offsetX: 2,
                offsetY: 0,
              }),
            })
          );
          this.layerLabel.getSource().addFeature(featureLabel);
        }
        let feature = this.layer.getSource().getFeatureById("featureupdate");
        if (feature && Object.keys(feature).length > 0) {
          feature.getGeometry().setCoordinates([points]);
          var ext = feature.getGeometry().getExtent();
          this.map.getView().fit(ext, {
            size: this.map.getSize(),
          });
          var center = getCenter(ext);
          this.map.getView().animate({
            center: [center[0], center[1]],
            duration: 100,
            zoom: this.map.getView().getZoom(),
          });
        } else {
          if (Object.keys(this.draw).length > 0) {
            this.map.removeInteraction(this.draw);
            this.draw = Object;
          }

          this.feature = new Feature({
            geometry: new Polygon([points]),
            id: "featureupdate",
          });
          this.feature.set("id", "featureupdate");
          this.feature.id_ = "featureupdate";

          this.layer.getSource().addFeature(this.feature);
          var ext = this.feature.getGeometry().getExtent();
          this.map.getView().fit(ext, {
            size: this.map.getSize(),
          });
          var center = getCenter(ext);
          this.map.getView().animate({
            center: [center[0], center[1]],
            duration: 100,
            zoom: this.map.getView().getZoom(),
          });
        }
      }
    },
    mapUpdateSize() {
      if (this.map && Object.keys(this.map).length > 0) this.map.updateSize();
    },
    hexToRgbA(hex, opacity) {
      if (!opacity) opacity = "0.4";
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    basemap() {
      return this.$store.getters.getBasemap;
    },
  },
};
</script>
<style lang="scss" scoped>
.Iybw1Bhj3Jhd8s {
  position: relative;
  width: 100%;
  height: 100%;
  .ehAbnmg4Jwb9BS {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 3;
  }
}
table {
  margin-bottom: 0;
  td {
    border-top: none;
    p {
      background: #f0f0f0;
      font-size: 14px;
      padding: 0.5rem;
    }
    .map-preview {
      position: relative;
    }
  }
}
table.table-images {
  p {
    margin: 0 !important;
  }
}
.preview {
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: white;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    &:hover {
      cursor: pointer;
    }
  }
}
.bg-A1A5B7 {
  background: #a1a5b7;
  color: white;
}
.bg-009EF7 {
  background: #009ef7;
  color: white;
}
.bg-red {
  background: red;
  height: 36px;
  line-height: 35px;
}
.border-radius-4 {
  border-radius: 4px;
}
.line-height-36 {
  line-height: 36px;
}
.line-height-56 {
  line-height: 56px;
}
table.table-metadata {
  border-collapse: separate;
  border-spacing: 0 0.125rem;
  tr {
    margin-bottom: 0.25rem;
    td {
      padding: 0.5rem 0.75rem;
      border: 1px solid white;
      &:first-child {
        color: white;
        border-radius: 0.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.preview-icon {
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: white;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    &:hover {
      cursor: pointer;
    }
  }
}

.update-map {
  width: 100%;
  height: 608px;
  border: 1px solid #eee;
  position: relative;
  margin-top: 0.5rem;
  #mapBoundingBox {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
</style>
<style lang="scss">
.Iybw1Bhj3Jhd8s {
  .vue-treeselect__control {
    border-color: #a6a6a6;
    border-radius: 3px !important;
    &:hover {
      border-color: #212121 !important;
    }
  }
  .mx-input {
    border-color: #a6a6a6;
    border-radius: 3px !important;
    &:hover {
      border-color: #212121 !important;
    }
    &:disabled,
    .disabled {
      background-color: transparent;
    }
  }
  .p-autocomplete-input {
    width: 100%;
  }
}
</style>

<style lang="scss" >
.update-metadata {
  .custom-file-label::after {
    background: #009ef7 !important;
    color: white;
  }
}
</style>