<template>
  <div class="" slot="slot-content">
    <spinner
      :propLoading="loading.show"
      :propShape="loading.shape"
      v-if="loading.show"
      :prop-text="loading.text"
    ></spinner>
    <data-specification-page></data-specification-page>
  </div>
</template>
<script>
import Spinner from "@/components/commons/spinners/Index";
import DataSpecificationPage from "@/components/pages/category/dataspecification/Index";
export default {
  name: "projects",
  components: {
    Spinner,
    DataSpecificationPage,
  },
  created() {},
  computed: {
    loading() {
      return this.$store.getters.getSpinnerApp;
    },
  },
};
</script>