<template>
  <RPPDropdown class="row-per-page"
    :value="rows"
    :options="rowsOptions"
    optionLabel="label"
    optionValue="value"
    @change="onChange($event)"
  ></RPPDropdown>
</template>

<script>
import Dropdown from "@/components/commons/dropdown/Dropdown";

export default {
  inheritAttrs: false,
  emits: ["rows-change"],
  props: {
    options: Array,
    rows: Number,
  },
  methods: {
    onChange(value) {
      this.$emit("rows-change", value.value);
    },
  },
  computed: {
    rowsOptions() {
      let opts = [];
      if (this.options) {
        for (let i = 0; i < this.options.length; i++) {
          opts.push({ label: String(this.options[i]), value: this.options[i] });
        }
      }
      return opts;
    },
  },
  components: {
    RPPDropdown: Dropdown,
  },
};
</script>
<style scoped lang="scss">
.row-per-page{
  min-width: 5.5rem !important;
}
</style>