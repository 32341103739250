<template>
  <main-layout
    :isToolbar="true"
    :isBreadcrumb="true"
    :breadcrumbitems="[
      { label: 'Đặt hàng' },
      { label: 'Đơn hàng đã xác nhận' },
    ]"
    :propButtonsState="{
      save: {
        visible: false,
      },
      add: {
        visible: false,
      },
      edit: {
        visible: false,
      },
      reload: {
        visible: false,
      },
      print: {
        visible: false,
      },
    }"
    :propTitle="'Đơn hàng đã xác nhận'"
  >
    <div class="order-list">
      <div class="order-list__dialog">
        <div class="order-list__dialog--images">
          <DialogOrderDetail
            v-if="dlgOrderDetail.show"
            :prop-style="{ width: dlgOrderDetail.width }"
            :prop-show="dlgOrderDetail.show"
            :prop-title="dlgOrderDetail.title"
            :prop-items="dlgOrderDetail.value.imageList"
            @close="
              dlgOrderDetail.show = false;
              dlgOrderDetail.value = null;
            "
            @showDialogShare="showDialogShare"
          />
        </div>

        <DialogShare
          v-if="dlgShare.show"
          :prop-style="{ width: dlgShare.width }"
          :prop-show="dlgShare.show"
          :prop-title="dlgShare.title"
          :prop-item="dlgShare.value"
          :propOrderBy="dlgOrderDetail.value.orderBy"
          @close="
            dlgShare.show = false;
            dlgShare.value = null;
          "
          @onShare="onShare"
        />
      </div>
      <div class="order-list__search">
        <list-search
          :prop-data="{
            users: users,
          }"
          :prop-search="search"
        ></list-search>
      </div>
      <div class="order-list__table">
        <list-table
          :prop-data="order"
          :prop-users="users"
          @pagechanged="pagechanged"
          @pageSizechanged="pageSizechanged"
          @showOrderDetail="showOrderDetail"
        >
        </list-table>
      </div>
    </div>
  </main-layout>
</template>
<script>
import MainLayout from "@/components/pages/layouts/admin/Main";
import ListSearch from "./Search";
import ListTable from "./DataTable";
import orderFunc from "@/utils/functions/order";
import userFunc from "@/utils/functions/user";
import oauthFunc from "@/utils/functions/oauth";
import imageSharingFunc from "@/utils/functions/imagesharing";
import cryptoFunc from "@/utils/functions/crypto";
import DialogOrderDetail from "./list/DialogOrderDetail.vue";
import DialogShare from "./list/DialogShare.vue";
export default {
  components: {
    ListSearch,
    ListTable,
    MainLayout,
    DialogOrderDetail,
    DialogShare,
  },
  data() {
    return {
      search: {
        page: 0,
        size: 2147483647,
        order_from: null,
        order_to: null,
        ordered_by: "",
        status: "",
      },
      order: {},
      users: [],
      dlgOrderDetail: {
        show: false,
        title: "Chi tiết đơn hàng",
        value: null,
        width: "480px",
      },
      dlgShare: {
        show: false,
        title: "Chia sẻ",
        value: null,
        width: "480px",
      },
    };
  },
  computed: {
    accessToken() {
      return this.$store.getters.getAccessToken;
    },
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
  },
  async created() {
    var vm = this;
    if (vm.documentWidth < 576) {
      this.dlgOrderDetail.width = this.documentWidth + "px";
      this.dlgShare.width = this.documentWidth + "px";
    } else if (vm.documentWidth >= 576 && vm.documentWidth < 768) {
      this.dlgOrderDetail.width = "540px";
      this.dlgShare.width = "540px";
    } else if (vm.documentWidth >= 768 && vm.documentWidth < 992) {
      this.dlgOrderDetail.width = "720px";
      this.dlgShare.width = "720px";
    } else {
      this.dlgOrderDetail.width = "720px";
      this.dlgShare.width = "720px";
    }
    await this.getAllUser();
    this.getAllOrderPage();
  },
  methods: {
    showOrderDetail(item) {
      this.dlgOrderDetail.value = item;
      this.dlgOrderDetail.show = true;
    },
    showDialogShare(item) {
      this.dlgShare.value = item;
      this.dlgShare.show = true;
    },

    async onShare(entity) {
      let message = [];
      entity.description = entity.description ? entity.description.trim() : "";
      if (!entity.description)
        message.push(this.$t("message_content_required"));

      if (entity.isExpired) {
        if (entity.expiredTime) {
          entity.expiredTime = new Date(entity.expiredTime).setHours(
            23,
            59,
            59,
            0
          );
          if (entity.expiredTime - new Date().getTime() < 0)
            message.push(
              this.$t("message_expired_time_must_greater_than_or_equal_today")
            );
        } else {
          message.push(this.$t("message_expired_time_required"));
        }
      }
      if (entity.isPass) {
        if (!entity.password) {
          message.push(this.$t("message_password_required"));
        }
      }
      if (entity.isNum) {
        if (!entity.numDownload) {
          message.push(
            this.$t("message_numberdownload_must_greater_than_zero")
          );
        }
      }
      if (message.length > 0) {
        alert(message.join(". "));
        return;
      } else {
        await this.handleSharing(entity);
      }
    },
    async handleSharing(entity) {
      let dto = {
        description: entity.description ? entity.description.trim() : "",
        download_number: entity.download_number,
        expired_time: new Date(entity.expiredTime),
        image_id: this.dlgShare.value.id,
        is_public: false,
        member_ids: [this.dlgOrderDetail.value.orderBy.id],
        password: entity.isPass && entity.password ? entity.password : null,
      };
      try {
        let response = await imageSharingFunc.create(
          dto,
          this.$store.getters.getAccessToken
        );
        if (response.status === 200 || response.status === 201) {
          this.$toast.success({
            title: this.$t("title_message_info"),
            message: this.$t("message_create_image_sharing_susscess", null, {
              name:
                this.dlgOrderDetail.value && this.dlgOrderDetail.value.name
                  ? this.dlgOrderDetail.value.name
                  : "",
            }),
            position: "top right",
          });
          this.dlgShare.value = null;
          this.dlgShare.show = false;
        } else {
          this.message.push(
            this.$t("message_create_image_sharing_error", null, {
              name:
                this.dlgOrderDetail.value && this.dlgOrderDetail.value.name
                  ? this.dlgOrderDetail.value.name
                  : "",
            })
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 401)
          await this.refreshToken(this.onShare, entity);
        else {
          let msg =
            error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push(
            msg
              ? msg
              : this.$t("message_create_image_sharing_error", null, {
                  name:
                    this.dlgOrderDetail.value && this.dlgOrderDetail.value.name
                      ? this.dlgOrderDetail.value.name
                      : "",
                })
          );
        }
      }
    },
    pagechanged(val) {
      this.search.page = val.val - 1;
      this.getAllOrderPage();
    },
    pageSizechanged(val) {
      this.search.page = 0;
      this.search.size = val.val;
      this.getAllOrderPage();
    },
    async getAllUser() {
      try {
        let response = await userFunc.search(
          {
            page: 0,
            size: 2147483647,
            order: "full_name",
            sort: "asc",
          },
          {
            is_active: null,
          },
          this.$store.getters.getAccessToken
        );
        if (response.status === 200) {
          if (response.data) {
            this.users = response.data.records;
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401)
          await this.refreshToken(this.getAllUser);
      }
    },
    async getAllOrderPage() {
      let params = {
        page: this.search.page,
        size: this.search.size,
      };
      try {
        let response = await orderFunc.getAllPage(params, this.accessToken);
        if (response.status === 200) this.order = response.data;
      } catch (error) {
        if (error.response && error.response.status === 401)
          await this.refreshToken(this.getAllOrderPage);
      }
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            this.$router.push({
              name: "Login",
            });
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          this.$router.push({
            name: "Login",
          });
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
};
</script>


<style lang="scss" scoped>
.order-list {
  padding: 0.25rem;
  padding-top: 0;
  .order-list__search {
    margin-bottom: 0.25rem;
  }
  .order-list__tree {
    margin-left: 0.25rem;
  }
  .order-list__structure {
    position: relative;
    margin-top: 0.25rem;
    .actions {
      position: absolute;
      right: 0.25rem;
      top: 0.125rem;
      button {
        border-radius: 50% !important;
        margin-right: 0.25rem;
        width: 1.75rem;
        height: 1.75rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>