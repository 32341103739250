<template>
  <CommonAccordion :propTitle="$t('title_organisation_tree')">
    <div slot="content">
      <TreeCommon
        class="w-100 p-1"
        :loading="propLoading"
        :value="dataTree"
        selectionMode="single"
        :expandedKeys="expandedKeys"
        v-if="dataTree && dataTree.length"
        :selectionKeys.sync="selectedKey"
        @node-select="onNodeSelect"
        @node-unselect="onNodeUnselect"
      ></TreeCommon></div
  ></CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import TreeCommon from "@/components/commons/tree/Tree";
export default {
  components: {
    CommonAccordion,
    TreeCommon,
  },
  props: {
    propData: {
      type: Array,
      default: () => [],
    },
    propLoading: {
      type: Boolean,
      default: false,
    },
    propSelected: {
      type: null,
      default: null,
    },
  },
  data() {
    return {
      dataTree: [],
      expandedKeys: {},
      selectedKey: null,
    };
  },
  watch: {
    propData: {
      handler(val) {
        this.dataTree = this.listToTree(val);
        this.expandAll();
      },
      immediate: true,
    },
    propSelected: {
      handler(val) {
        if (val) {
          let key = val.id ? val.id : "";
          this.selectedKey = {};
          this.selectedKey[key] = true;
        } else this.selectedKey = null;
      },
      immediate: true,
    },
  },
  methods: {
    onNodeSelect(node) {
      this.$emit("onNodeSelect", node);
    },
    onNodeUnselect(node) {
      this.$emit("onNodeUnselect", node);
    },
    expandAll() {
      for (let node of this.dataTree) {
        this.expandNode(node);
      }

      this.expandedKeys = { ...this.expandedKeys };
    },
    collapseAll() {
      this.expandedKeys = {};
    },
    expandNode(node) {
      this.expandedKeys[node.key] = true;
      if (node.children && node.children.length) {
        for (let child of node.children) {
          this.expandNode(child);
        }
      }
    },
    listToTree(list) {
      var map = {},
        node,
        roots = [],
        i;
      let items = [];
      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
        let obj = {
          parent_id:
            list[i].parent_id && list[i].parent_id != list[i].id
              ? list[i].parent_id
              : -1,
          label: list[i].name,
          text: list[i].name,
          name: list[i].name,
          id: list[i].id,
          key: list[i].id,
          children: [],
        };
        items.push(obj);
      }
      for (i = 0; i < items.length; i += 1) {
        try {
          node = items[i];
          if (node.parent_id != null && node.parent_id + "" !== "-1") {
            if (items[map[node.parent_id]]) {
              if (!items[map[node.parent_id]].children) {
                items[map[node.parent_id]].children = [];
              }
              items[map[node.parent_id]].children.push(node);
            } else roots.push(node);
          } else {
            roots.push(node);
          }
        } catch (error) {}
      }
      return roots;
    },
  },
};
</script>
<style lang="scss">
.organisation__tree {
  .p-tree {
    border-color: transparent;
  }
}
</style>