<template>
  <CommonAccordion :propTitle="$t('header_file_list')">
    <div slot="content">
      <div class="datatable">
        <DataTable
          :value="propItems"
          rowGroupMode="subheader"
          groupRowsBy="date_str"
          sortMode="single"
          :scrollHeight="propTable.height - 40 - 38 + 'px'"
          :sortOrder="1"
          :resizableColumns="false"
          dataKey="id"
          :virtualScroll="true"
          @virtual-scroll="onVirtualScroll"
          :scrollable="true"
          :loading="propLoading"
        >
          <template #header>
            <div class="text-left">
              <div>
                <div v-if="propItems.length" class="font-weight-normal">
                  {{
                    $t("label_show_from_to", null, {
                      from: 1,
                      to: propItems.length,
                      total: propTotal,
                    })
                  }}
                </div>
              </div>
            </div>
          </template>
          <template #empty v-if="propItems && propItems.length === 0">
            <span class="text-center">{{ $t("label_no_data") }}</span>
          </template>
          <Column
            header="No"
            headerStyle="width: 3rem; text-align: center!important"
            bodyStyle="width: 3rem;text-align: center;"
          >
            <template #body="slotProps">
              {{ slotProps.index + 1 }}
            </template></Column
          >

          <Column
            header="Metadata"
            headerStyle="width: 8rem"
            bodyStyle="width: 8rem"
          >
            <template #body="slotProps">
              <div
                :class="columnClass(slotProps.data)"
                class="oval"
                :title="
                  !slotProps.data.data.is_metadata &&
                  slotProps.data.message &&
                  slotProps.data.message.length > 0
                    ? slotProps.data.message.join(',')
                    : ''
                "
              >
                <span>{{
                  slotProps.data.data.is_metadata
                    ? $t("label_completed")
                    : $t("label_incompleted")
                }}</span>
              </div>
            </template>
          </Column>
          <Column
            :header="$t('label_username')"
            headerStyle="width: 8rem"
            bodyStyle="width: 8rem"
          >
            <template #body="slotProps">
              {{ slotProps.data.created_by }}
            </template></Column
          >
          <Column
            :header="$t('lable_date_time')"
            headerStyle="width: 10rem"
            bodyStyle="width: 10rem"
            :sortable="true"
          >
            <template #body="slotProps">
              {{ formatUnixDateTime2String(slotProps.data.created_at) }}
            </template>
          </Column>

          <Column
            :header="$t('label_file_name')"
            headerStyle="width: 25rem"
            bodyStyle="width: 25rem"
          >
            <template #body="slotProps">
              <span
                :title="
                  slotProps.data.data &&
                  slotProps.data.data.file_name &&
                  slotProps.data.data.file_name.length > 48
                    ? slotProps.data.data.file_name
                    : ''
                "
                >{{
                  slotProps.data.data &&
                  slotProps.data.data.file_name &&
                  slotProps.data.data.file_name.length > 48
                    ? slotProps.data.data.file_name.substring(0, 44) + "..."
                    : slotProps.data.data.file_name
                }}</span
              >
            </template>
          </Column>
          <Column
            :header="$t('label_path')"
            headerStyle="width: 25rem"
            bodyStyle="width: 25rem"
          >
            <template #body="slotProps">
              <span
                :title="
                  slotProps.data.data &&
                  slotProps.data.data.path &&
                  slotProps.data.data.path.length > 48
                    ? slotProps.data.data.path
                    : ''
                "
                >{{
                  slotProps.data.data &&
                  slotProps.data.data.path &&
                  slotProps.data.data.path.length > 48
                    ? slotProps.data.data.path.substring(0, 44) + "..."
                    : slotProps.data.data.path
                }}</span
              >
            </template>
          </Column>
          <Column
            field="file_size"
            :header="$t('label_file_size')"
            headerStyle="width: 7rem"
            bodyStyle="width: 7rem"
          >
            <template #body="slotProps">
              {{
                slotProps.data.data.size
                  ? formatSizeFile(slotProps.data.data.size)
                  : ""
              }}
            </template>
          </Column>

          <Column
            headerStyle="width: 6.25rem; text-align: center!important"
            bodyStyle="width: 6.25rem;"
            :headerClass="'p-frozen-column right'"
            :bodyClass="'p-frozen-column right'"
          >
            <template #body="slotProps">
              <Button
                v-if="slotProps.data.data.is_metadata"
                icon="fas fa-info-circle"
                type="button"
                class="p-button-info p-button-sm"
                :title="$t('label_metadata')"
                @click="shoDialogMetadata(slotProps.data)"
              >
              </Button>
              <Button
                v-if="
                  !slotProps.data.data.is_metadata &&
                  $store.getters.getPermissions.includes('DETECT_METADATA')
                "
                :icon="
                  slotProps.data.processing
                    ? 'fas fa-spinner fa-spin'
                    : 'fas fa-clipboard-list'
                "
                type="button"
                class="p-button-sm p-button-primary"
                :title="$t('button_text_read_metadata_again')"
                @click="getMetadata(slotProps.data)"
                :disabled="slotProps.data.processing"
              >
              </Button>
              <Button
                v-if="
                  !slotProps.data.data.is_metadata &&
                  $store.getters.getPermissions.includes('IMAGE_ADD_IMAGE')
                "
                :icon="'fas fa-list-alt'"
                type="button"
                class="p-button-sm button-additional-information ml-1"
                :title="$t('label_incompleted')"
                @click="showDialogAdditionalInformation(slotProps.data)"
                :disabled="slotProps.data.processing"
              >
              </Button>
            </template>
          </Column>
          <template #groupheader="slotProps">
            <span class="image-text"
              ><strong>{{ slotProps.data.date_str }}</strong
              >&nbsp;({{ $t("lable_files_total") }}:&nbsp;{{
                calculateGroupTotal(slotProps.data.date_str)
              }})
            </span>
          </template>
        </DataTable>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import Button from "@/components/commons/button/Button";
import ContextMenu from "@/components/commons/contextmenu/ContextMenu";
import moment from "moment";
import { formatSizeFile } from "@/utils/commons/common";
export default {
  components: { CommonAccordion, DataTable, Column, Button, ContextMenu },
  props: {
    propItems: {
      type: Array,
      default: () => [],
    },
    propTable: {
      type: Object,
      default: () => {},
    },
    propTotal: {
      type: Number,
      default: 0,
    },
    propLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    onVirtualScroll(e) {
      this.$emit("nextPage");
    },
    getAllMetadata() {
      for (let i = 0; i < this.propItems.length; i++) {
        if (!this.propItems[i].data.is_metadata) {
          this.getMetadata(this.propItems[i]);
        }
      }
    },
    getMetadata(item) {
      this.$emit("getMetadata", item);
    },
    showDialogAdditionalInformation(item) {
      this.$emit("showDialogAdditionalInformation", item);
    },
    calculateGroupTotal(name) {
      let total = 0;
      if (this.propItems) {
        for (let item of this.propItems) {
          if (item.date_str === name) {
            total++;
          }
        }
      }
      return total;
    },
    rowClass(data) {
      return data.data.is_metadata === "bg-success " ? "bg-danger" : "";
    },
    columnClass(val) {
      let result = "";
      if (val && Object.keys(val).length > 0) {
        if (val.data.is_metadata) return "bg-completed txt-completed";
        else return "bg-additional-information txt-additional-information";
      }
      return;
    },
    formatUnixDateTime2String(value) {
      let result = "";
      if (value) {
        try {
          let date = new Date(value);
          result = moment
            .unix(date.getTime() / 1000)
            .format("HH:mm DD/MM/YYYY");
          result = result
            .replace(":", this.lang === "vi" ? "h" : ":")
            .replace(" ", this.lang === "vi" ? " ngày " : " ");
        } catch (error) {}
      }
      return result;
    },
    formatSizeFile(bytes, si = false) {
      return formatSizeFile(bytes, si);
    },
    shoDialogMetadata(item) {
      this.$emit("shoDialogMetadata", item);
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
.datatable {
  overflow-y: auto;
  .oval {
    height: 36px;
    width: 112px;
    margin: 0 auto;
    border-radius: 20px;
    text-align: center;
    opacity: 0.7;
    span {
      text-align: center;
      vertical-align: middle;
      line-height: 36px;
      font-size: 10px;
      font-weight: 500;
      word-wrap: break-word;
    }
  }
}

.button-additional-information {
  background: #f68e1e;
  color: white;
  border: 1px solid #f68e1e;
  &:hover {
    color: white !important;
    background: #f89d3c !important;
    border: 1px solid #f89d3c !important;
  }
}
.bg-additional-information {
  background: #f68e1e;
  border: 1px solid #f68e1e;
  color: #f68e1e;
}
.txt-additional-information {
  color: #fff;
}

.bg-completed {
  background: #009ef7;
  border: 1px solid #009ef7;
}
.txt-completed {
  color: white;
}
</style>
<style lang="scss">
.upload-history__datatable {
  @import "@/assets/scss/_datatable.scss";
}
</style>