import axios from "axios"
import qs from "qs"
import app from './../../main'
import { userServiceApiURL,tokenServiceApiURL } from "@/utils/commons/environment"
const refresh =async function(refreshToken){
  const object =  axios({
    method: "post",
    url: tokenServiceApiURL+"/oauth/token",
    data: qs.stringify({
      grant_type:"refresh_token",
      refresh_token: refreshToken
    }),
    //d2ViLWFkbWluOnZlZ2ExMjM0
    headers: {
      Authorization: "Basic bW9iaWxlOm1vYmlsZQ==",
      "Content-Type": "application/x-www-form-urlencoded",
      'Accept-Language':app.$store.getters.getLang
    },
    params: {
      lang : app.$store.getters.getLang
    }
  })
  return await object
}
const login = async function(data){
  const object =  axios({
    method: "post",
    url: tokenServiceApiURL+"/oauth/token",
    data: qs.stringify({
      grant_type:"password",
      username: data.username,
      password: data.password,
    }),
    headers: {
      Authorization: "Basic bW9iaWxlOm1vYmlsZQ==",
      "Content-Type": "application/x-www-form-urlencoded",
      'Accept-Language':app.$store.getters.getLang
    },
    params: {
      lang : app.$store.getters.getLang
    }
  })
  return await object
}

const userMe = async function(accessToken){
  const object =  axios({
    method: "get",
    url: userServiceApiURL+"/me",
    headers: {
      Authorization: "Bearer "+accessToken,
      'Accept-Language':app.$store.getters.getLang
    },
    params: {
      lang : app.$store.getters.getLang
    }
  })
  return await object
}

export default { refresh,login,userMe }