
<template>
<div class="row no-gutters">
  <div class="col-12 col-md-6">
     Hiển thị
    <select v-model="pageSize" size="sm">
      <option :value="size" v-for="size in sizes" :key="size">{{ size }}</option>
    </select>
    bản ghi trên trang
  </div>
  <div class="col-12 col-md-6">
    <ul class="_4Ysqk8xzqKd8Ksnhv0d pagination float-md-right">
      <li class="pagination-item">
        <button
          type="button"
          @click="onClickFirstPage"
          :disabled="isInFirstPage"
          size="sm"
        >
          <i class="fa fa-angle-double-left"></i>
        </button>
      </li>
      <li class="pagination-item">
        <button
          type="button"
          @click="onClickPreviousPage"
          :disabled="isInFirstPage"
        >
          <i class="fas fa-angle-left"></i>
        </button>
      </li>

      <li
        v-for="(page,index ) in pages"
        class="pagination-item" :key="index"
      >
        <button
          type="button"
          @click="onClickPage(page.name)"
          :disabled="page.isDisabled"
          :class="{ active: isPageActive(page.name) }"
        >
          <span>{{ page.name }}</span>
        </button>
      </li>

      <li class="pagination-item">
        <button
          type="button"
          @click="onClickNextPage"
          :disabled="isInLastPage"
        >
          <i class="fas fa-angle-right"></i>
        </button>
      </li>

      <li class="pagination-item">
        <button
          type="button"
          @click="onClickLastPage"
          :disabled="isInLastPage"
        >
          <i class="fa fa-angle-double-right"></i>
        </button>
      </li>
    </ul>
  </div>
</div>
</template>
<script>
export default {
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3
    },
    totalPages: {
      type: Number,
      required: false
    },
    total: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    propPageSize: {
      type: Number,
      default:5
    },
  },
  data() {
    return {
      sizes: [5,10,25,50],
      pageSize: 0
    };
  },
  created() {
    if (this.sizes.length > 0) {
      this.pageSize = this.sizes.find(x=>x === this.propPageSize);
      if(!this.pageSize)
        this.pageSize = this.sizes[0];
      this.$emit("pageSizechanged", this.pageSize);
    }
  },
  watch: {
    pageSize: function(newVal, oldVal) {
      if (oldVal != 0) this.$emit("pageSizechanged", newVal);
    }
  },
  methods: {
    onClickFirstPage() {
      this.$emit("pagechanged", 1);
    },
    onClickPreviousPage() {
      this.$emit("pagechanged", this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit("pagechanged", page);
    },
    onClickNextPage() {
      this.$emit("pagechanged", this.currentPage + 1);
    },
    onClickLastPage() {
      this.$emit("pagechanged", this.totalPages);
    },
    isPageActive(page) {
      return this.currentPage === page;
    }
  },
  computed: {
    startPage() {
      if (this.currentPage === 1) {
        return 1;
      }
      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons + 1;
      }

      return this.currentPage - 1;
    },
    endPage() {
      return Math.min(
        this.startPage + this.maxVisibleButtons - 1,
        this.totalPages
      );
    },
    pages() {
      const range = [];
      let start = this.startPage;
      if (start <= 0) start = 1;
      for (let i = start; i <= this.endPage; i += 1) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage
        });
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    }
  }
};
</script>
<style scoped>
.pagination {
  position: relative;
}
ul li {
  margin-right: 5px;
  float: right;
}
ul li:last-child {
  margin-right: 0;
}
ul li button {
  color: black;
  border: 1px solid #02c8f3;
  background-color: white;
  font-size: 14px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding: 2px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
ul li button.active {
  cursor: default;
}
ul li button.active:hover {
  background-color: #02c8f3;
  border-color: #02c8f3;
  color: #ffffff;
}
ul li button:hover {
  background-color: #68e2fd;
  border-color: #68e2fd;
  color: #ffffff;
}
.pagination {
  list-style-type: none;
  font-size: 14px;
}

.pagination-item {
  display: inline-block;
}

.active {
  background-color: #02c8f3;
  border-color: #02c8f3;
  color: #ffffff;
  opacity: 1;
}
.form-control{
  height: 100%;
  padding: .15rem .75rem;
}
button:disabled{
  cursor: not-allowed;
}
button:disabled:hover{
  background-color: white;
  color: black;
  border: 1px solid #02c8f3;
}
</style>