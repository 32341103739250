<template>
  <Dialog
    :header="$t('title_upload_images')"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :modal="true"
    :contentStyle="propStyle"
    @hide="onClose"
  >
    <div class="myDropzone2" id="myDropzone2">
      <div class="row no-gutters upload-actions d-flex flex-row">
        <div class="add-files">
          <input
            multiple
            :disabled="is_processing"
            type="file"
            id="add-files__input"
            hidden
            @change="inputFilesChange"
            ref="addFilesInput"
            accept="zip,application/zip,application/x-zip,application/x-zip-compressed"
          />
          <label for="add-files__input" @click="labelFileClick"
            ><i class="fas fa-plus"></i>&nbsp;{{
              $t("label_select_files")
            }}</label
          >
        </div>
        <button
          :class="buttonUpload.class"
          style="width: 120px; height: 35px"
          :disabled="files.length === 0 || is_processing"
          @click="startUpload"
          class="btn btn-start-upload"
        >
          <i :class="buttonUpload.icon"></i>&nbsp;{{ buttonUpload.label }}
        </button>
        <ProgressBar
          class="ml-2"
          :value="remainingTime.progressValue"
          style="width: calc(100% - 260px)"
        ></ProgressBar>
      </div>
      <div class="row no-gutters upload-files">
        <div class="col" id="HJud83Hwbr">
          <div class="datatable">
            <DataTable
              :value="files"
              ref="dt"
              :resizableColumns="false"
              columnResizeMode="expand"
              dataKey="id"
              @row-click="rowClick"
              :scrollHeight="tableHeight + 'px'"
              :virtualScroll="true"
              :scrollable="true"
            >
              <template #empty v-if="files && files.length === 0">
                <span class="text-center">{{ $t("label_no_files") }}</span>
              </template>
              <Column
                :headerStyle="{
                  width: '3.75rem',
                  textAlign: 'center!important',
                }"
                bodyStyle="text-align: center;"
              >
                <template #body="slotProps">
                  <div>
                    {{ slotProps.index + 1 }}
                  </div>
                </template></Column
              >

              <Column headerStyle="width: 3.5rem"
                ><template #body="slotProps">
                  <div
                    v-if="slotProps.data"
                    :class="'file-' + slotProps.data.type.toLowerCase()"
                  ></div> </template
              ></Column>
              <Column :header="$t('label_file_name')">
                <template #body="slotProps">
                  <div class="file-attachment">
                    <span class="innernametext">
                      <div v-if="!slotProps.data.is_folder" class="pl-2">
                        {{ slotProps.data.name }}
                        <p
                          v-if="slotProps.data.other_name"
                          class="mt-2 text-primary"
                        >
                          ({{ slotProps.data.other_name }})
                        </p>
                      </div>
                      <div v-else style="width: 100%; height: auto">
                        <TreeCommon
                          :value="slotProps.data.data_tree"
                          v-if="slotProps.data.data_tree.length"
                        ></TreeCommon>
                      </div>
                    </span>
                  </div>
                </template>
              </Column>
              <Column :header="$t('label_file_size')" headerStyle="width: 7rem">
                <template #body="slotProps">
                  {{ slotProps.data.size | formatSizeFile }}
                </template>
              </Column>
              <Column :header="$t('label_status')" headerStyle="width: 12rem">
                <template #body="slotProps">
                  <div
                    v-if="
                      slotProps.data.status === 'SUCCESS' &&
                      slotProps.data.read_metadata === 'SUCCESS'
                    "
                  >
                    <div class="status-item complete-status">
                      <span>{{ $t("label_completed") }}</span>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      slotProps.data.status === 'SUCCESS' &&
                      slotProps.data.read_metadata === 'ERROR'
                    "
                  >
                    <div class="status-item incomplete-status">
                      <span>{{ $t("label_incompleted") }}</span>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      slotProps.data.status === 'SUCCESS' &&
                      slotProps.data.read_metadata === 'PROCESSING'
                    "
                  >
                    <div class="status-item processing-status">
                      <span
                        ><i class="fas fa-spinner fa-spin"></i>&nbsp;{{
                          $t("label_processing")
                        }}</span
                      >
                    </div>
                  </div>
                  <div v-else-if="slotProps.data.status === 'ERROR'">
                    <div class="status-item error-status">
                      <span>{{ $t("label_error") }}</span>
                    </div>
                  </div>
                  <div v-else-if="slotProps.data.status === 'PROCESS'">
                    <div class="status-item processing-status">
                      <span
                        ><i class="fas fa-spinner fa-spin"></i>&nbsp;{{
                          $t("label_uploading")
                        }}</span
                      >
                    </div>
                  </div>
                </template>
              </Column>
              <Column
                headerStyle="width: 4rem; text-align: center!important"
                bodyStyle="text-align: center;"
              >
                <template #body="slotProps">
                  <Button
                    :disabled="is_processing"
                    :title="$t('button_text_remove')"
                    icon="fas fa-trash"
                    class="p-button-danger p-button-sm"
                    v-if="slotProps.data.status === 'NEW'"
                    @click="removeItem(slotProps.data)"
                  />
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
import Button from "@/components/commons/button/Button";
import ProgressBar from "@/components/commons/progressbar/ProgressBar.vue";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import ContextMenu from "@/components/commons/contextmenu/ContextMenu";
import CryptoJS from "crypto-js";
export default {
  components: { Dialog, Button, ProgressBar, DataTable, Column, ContextMenu },
  props: {
    propTitle: {
      type: String,
      default: "",
    },
    propStyle: {
      type: Object,
      default: () => {
        return {
          width: "480px",
        };
      },
    },
    propShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      files: [],
      selected: null,
      uploading: false,
      temp: {
        files: [],
        length: 0,
      },
      remainingTime: {
        progressValue: 0,
        timeValue: null,
        oldUploadedSize: 0,
        count: 0,
        countHold: false,
      },
      buttonUpload: {
        label: "Upload",
        status: "NORMAL",
        icon: "fas fa-upload",
        class: "btn btn-primary btn-sm",
      },
      menuModel: [
        {
          label: this.$t("button_text_remove"),
          icon: "text-danger fas fa-trash",
          command: () => this.removeItem(this.selected),
          visible: true,
        },
      ],
      message: [],
      is_processing: false,
    };
  },
  created() {
    this.show = this.propShow;
  },
  watch: {
    propShow: {
      handler(val) {
        this.show = this.propShow;
      },
      immediate: true,
    },
  },
  methods: {
    onClose() {
      this.show = false;
      this.$emit("close");
    },
    updateStatus(item) {
      let index = this.files.findIndex((x) => x.id === item.id);
      if (index >= 0) {
        this.files[index].status === item.status;
      }
    },
    updateStatusMetadata(item) {
      let index = this.files.findIndex((x) => x.id === item.id);
      if (index >= 0) {
        this.files[index].read_metadata === item.read_metadata;
      }
    },
    updateRemainingTime(data) {
      this.remainingTime.progressValue = data.progressValue;
    },
    resetUpload() {
      this.is_processing = false;
    },
    inputFilesChange(e) {
      var vm = this;
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      else {
        for (let i = 0; i < files.length; i++) {
          if (this.files.findIndex((x) => x.name === files[i].name) >= 0) {
            this.$emit(
              "message",
              this.$t("message_file_already_exists", null, {
                name: files[i].name,
              })
            );
            continue;
          }
          let tempId = CryptoJS.MD5(
            files[i].name +
              Math.floor(Math.random() * 9999) +
              new Date().getTime()
          ).toString();
          files[i].temp_id = tempId;
          files[i].uploaded_size = 0;
          this.files.push({
            name: files[i].name,
            files: [files[i]],
            is_folder: false,
            size: files[i].size,
            type: this.typeFromExtension(
              files[i].name.split(".").pop().toLowerCase()
            ),
            status: "NEW",
            value: 0,
            id: tempId,
            uploaded_size: 0,
            other_name: null,
            data_file: null,
            loading_data: true,
            entity: null,
            read_metadata: "PENDING",
          });
        }
      }
    },
    labelFileClick() {
      this.$refs.addFilesInput.value = null;
    },
    startUpload() {
      if (this.files && this.files.length > 0) {
        this.is_processing = true;
        this.$emit("uploadFiles", this.files);
      } else {
        this.$emit("message", [this.$t("label_no_files")]);
      }
    },
    onRowContextMenu() {},
    rowClick() {},

    removeItem(item) {
      if (item && Object.keys(item).length > 0) {
        let index = this.files.findIndex((x) => x.id === item.id);
        if (index >= 0) this.files.splice(index, 1);
      }
    },
    typeFromExtension(extension) {
      let result = "FILE";
      switch (extension) {
        case "txt":
          result = "TXT";
          break;
        case "html":
          result = "HTML";
          break;
        case "zip":
          result = "ZIP";
          break;
        case "rar":
          result = "RAR";
          break;
        case "png":
          result = "PNG";
          break;
        case "jpeg":
          result = "JPEG";
          break;
        case "jpg":
          result = "JPG";
          break;
        case "tif":
          result = "TIFF";
          break;
        case "svg":
        case "gif":
          result = "PICTURE";
          break;
        case "kmz":
          result = "KMZ";
          break;
        case "doc":
        case "docx":
          result = "DOCX";
          break;
        case "xls":
        case "xlsx":
          result = "EXCEL";
          break;
        case "ppt":
          result = "PPT";
          break;
        case "exe":
          result = "EXE";
          break;
        case "iso":
          result = "ISO";
          break;
        default:
          result = "FILE";
          break;
      }
      return result;
    },
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    tableHeight() {
      return this.documentHeight - 80 - 100 - 2 - 24 - 2 * 50;
    },
  },
  beforeDestroy() {
    if (this.intervalTime) clearInterval(this.intervalTime);
    if (this.intervalFiles) clearInterval(this.intervalFiles);
    if (this.files.filter((x) => x.status === "PROCESS").length > 0)
      this.$store.dispatch("showWidgetUploadProgressBar", true);
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_icon.scss";
.file-attachment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  .innernametext {
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    display: table-cell;
    width: auto;
    p {
      margin-bottom: 0;
    }
  }
  .fileactions {
    position: inherit;
    float: right;
    button {
      background-color: transparent;
      border: 0;
      &:active {
        color: #000000;
        outline: 0;
        border: 0;
      }
      &:hover {
        color: #000000;
        outline: 0;
        border: 0;
      }
    }
  }
}
.myDropzone2 {
  width: 100%;
  .p-tree {
    border: none;
    padding: 0;
    width: 100%;
    .p-tree-toggler {
      position: relative;
      top: 4px;
    }
  }
}
.p-progressbar {
  height: 2.25rem;
}
.myDropzone2 {
  padding: 0.5rem;

  .upload-actions {
    padding: 0;
    .add-files {
      label {
        color: #fff;
        background-color: #007bff;
        border-color: #007bff;
        padding: 0.3rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1.85;
        border-radius: 0.2rem;
        margin-bottom: 0;
        margin-right: 0.5rem;
        width: 120px;
        cursor: pointer;
        &:hover {
          color: #fff;
          background-color: #0069d9;
          border-color: #0062cc;
        }
        &:disabled {
          opacity: 0.65;
        }
      }
    }
    .btn-start-upload {
      background: #107d11;
      border-color: #107d11;
    }
  }
  .upload-files {
    padding: 0;
    margin-top: 0.5rem;
  }
}

.upload-status {
  margin-top: 0.5rem;
  .status-item {
    height: 36px;
    min-width: 144px;
    margin-right: 0.375rem;
    padding: 0.375rem;
    &:last-child {
      margin: 0;
    }
    &.complete-status {
      border: 1px solid #009ef7;
      box-sizing: border-box;
      border-radius: 4px;
      background: #f1faff;

      color: #009ef7;
    }
    &.incomplete-status {
      border: 1px solid #f68e1e;
      box-sizing: border-box;
      border-radius: 4px;
      background: #fef5ed;

      color: #f68e1e;
    }
    &.error-status {
      border: 1px solid #f1416c;
      box-sizing: border-box;
      border-radius: 4px;
      background: #fdefef;

      color: #f1416c;
    }
    &.processing-status {
      border: 1px solid #0d6efd;
      box-sizing: border-box;
      border-radius: 4px;
      background: #f1faff;

      color: #0d6efd;
    }
  }
}

.upload-files {
  .status-item {
    height: 36px;
    min-width: 112px;
    text-align: center;
    padding: 0.375rem;
    &.complete-status {
      border: 1px solid #eaf7f0;
      box-sizing: border-box;
      border-radius: 4px;
      background: #eaf7f0;

      color: #50cd89;
    }
    &.incomplete-status {
      border: 1px solid #fef5ed;
      box-sizing: border-box;
      border-radius: 4px;
      background: #fef5ed;

      color: #f68e1e;
    }
    &.error-status {
      border: 1px solid #fdefef;
      box-sizing: border-box;
      border-radius: 4px;
      background: #fdefef;

      color: #f1416c;
    }
    &.processing-status {
      border: 1px solid #f1faff;
      box-sizing: border-box;
      border-radius: 4px;
      background: #f1faff;

      color: #0d6efd;
    }
    span {
      text-align: center;
      font-size: 12px;
    }
  }
}
.button-additional-information {
  background: #f68e1e;
  color: white;
  border: 1px solid #f68e1e;
  &:hover {
    color: #f68e1e !important;
    background: #fef5ed !important;
    border: 1px solid #f68e1e !important;
  }
}
.label-support-satellite {
  line-height: 36px;
  vertical-align: middle;
  padding: 0.4rem;
}
</style>
<style lang="scss">
.myDropzone2 {
  .p-tree {
    .p-tree-toggler {
      position: relative;
      top: 4px;
    }
  }
}
#HJud83Hwbr {
  @import "@/assets/scss/_datatable.scss";
  .datatable {
    button {
      width: 1.825rem !important;
      height: 1.825rem !important;
      padding: 0.25rem !important;
      &:first-child {
        margin-right: 0.25rem;
      }
    }
  }
}
</style>