<template>
  <CommonAccordion :propTitle="$t('title_role_info')">
    <div slot="content" class="authority-structure">
      <div class="p-grid m-0">
        <div class="p-field p-col-12 p-md-6">
          <label class="p-col-fixed" :style="styleLabel">
            {{ $t("label_role_code") }}&nbsp;
            <span class="font-weight-bold required">*</span>
          </label>
          <ValidationProvider :name="$t('label_role_code')" rules="required">
            <InputText
              type="text"
              :style="styleControl"
              maxlength="100"
              v-model="propEntity.name"
              :disabled="!propStatus.isAdd && !propStatus.isEdit"
              class="new-style"
            />
          </ValidationProvider>
        </div>
        <div class="row no-gutters w-100">
          <div class="col-12 col-md-6 p-1">
            <fieldset>
              <legend class="w-auto">
                {{ $t("label_language_vietnamese") }}
              </legend>
              <div class="form-group row no-gutters">
                <label class="col-form-label" :style="styleLabel"
                  >{{ $t("label_name_vietnamese") }}&nbsp;<span
                    class="font-weight-bold required"
                    >*</span
                  ></label
                >
                <div :style="styleControl">
                  <ValidationProvider
                    :name="$t('label_name_vietnamese')"
                    rules="required"
                  >
                    <InputText
                      class="w-100 new-style"
                      type="text"
                      maxlength="255"
                      v-model="propEntity.translation.vi.description"
                      :disabled="!propStatus.isAdd && !propStatus.isEdit"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </fieldset>
          </div>
          <div class="col-12 col-md-6 p-1">
            <fieldset>
              <legend class="w-auto">{{ $t("label_language_english") }}</legend>
              <div class="form-group row no-gutters">
                <label class="col-form-label" :style="styleLabel"
                  >{{ $t("label_name_english") }}&nbsp;<span
                    class="font-weight-bold required"
                    >*</span
                  ></label
                >
                <div :style="styleControl">
                  <ValidationProvider
                    :name="$t('label_name_english')"
                    rules="required"
                  >
                    <InputText
                      class="w-100 new-style"
                      type="text"
                      maxlength="255"
                      v-model="propEntity.translation.en.description"
                      :disabled="!propStatus.isAdd && !propStatus.isEdit"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </fieldset>
          </div>
        </div>

        <div class="p-field p-col-12 p-md-12">
          <CommonAccordion2
            :isChevronEnd="true"
            :propTitle="$t('title_permission_list')"
            :isCollapse="false"
          >
            <div class="role-permissions__datatable" slot="content">
              <DataTable
                :value="
                  propEntity.permissions.filter((x) => x.is_delete != true)
                "
                ref="dtrolepermission"
                :paginator="
                  propEntity.permissions && propEntity.permissions.length > 10
                "
                :loading="!propEntity.permissions"
                :rows="10"
                :resizableColumns="true"
                columnResizeMode="expand"
                selectionMode="single"
                dataKey="id"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                :rowsPerPageOptions="[10, 20, 50]"
                responsiveLayout="scroll"
                :currentPageReportTemplate="
                  $t('label_showing') +
                  ' {first} ' +
                  $t('label_to').toLowerCase() +
                  ' {last} ' +
                  $t('label_of').toLowerCase() +
                  ' {totalRecords}'
                "
              >
                <template
                  #empty
                  v-if="
                    propEntity.permissions &&
                    propEntity.permissions.length === 0
                  "
                >
                  <span class="text-center">{{ $t("label_no_data") }}</span>
                </template>
                <Column
                  header="No"
                  headerStyle="width: 3rem; text-align: center!important"
                  bodyStyle="text-align: center;"
                >
                  <template #body="slotProps">
                    {{ slotProps.index + 1 }}
                  </template></Column
                >
                <Column
                  field="name"
                  :header="$t('label_permission_code')"
                ></Column>
                <Column :header="$t('label_permission_name')">
                  <template #body="slotProps">
                    {{
                      slotProps.data.translation &&
                      slotProps.data.translation[lang]
                        ? slotProps.data.translation[lang].description
                        : ""
                    }}
                  </template>
                </Column>
              </DataTable>

              <Button
                icon="pi pi-plus"
                class="p-button-rounded btn-geostore2 btn-add"
                @click="addPermission"
                :disabled="!propStatus.isAdd && !propStatus.isEdit"
              />
            </div>
          </CommonAccordion2>
        </div>
        <div class="p-field p-col-12 p-md-12">
          <CommonAccordion2
            :isChevronEnd="true"
            :propTitle="$t('title_interface_list')"
            :isCollapse="false"
          >
            <div class="role-interface-list__datatable" slot="content">
              <div class="datatable">
                <DataTable
                  :value="propInterfaces"
                  rowGroupMode="subheader"
                  groupRowsBy="group_label"
                  sortMode="single"
                  dataKey="path"
                  :selection.sync="selectedPath"
                >
                  <template
                    #empty
                    v-if="propInterfaces && propInterfaces.length === 0"
                  >
                    <span class="text-center">{{ $t("label_no_data") }}</span>
                  </template>
                  <Column field="group_label" header=""></Column>
                  <Column
                    header="No"
                    headerStyle="width: 3rem; text-align: center!important"
                    bodyStyle="text-align: center;"
                  >
                    <template #body="slotProps">
                      {{ slotProps.index + 1 }}
                    </template></Column
                  >
                  <Column headerStyle="width: 3em">
                    <template #header>
                      <Checkbox
                        v-model="checkAll"
                        :binary="true"
                        :disabled="!propStatus.isAdd && !propStatus.isEdit"
                        @change="checkAllChange"
                      />
                    </template>
                    <template #body="slotProps">
                      <Checkbox
                        :id="
                          new Date().getTime() -
                          Math.floor(Math.random() * 999999) +
                          Math.floor(Math.random() * 100000) +
                          '' +
                          slotProps.data.id
                        "
                        v-model="selectedInterfaces"
                        :value="slotProps.data.path"
                        :disabled="!propStatus.isAdd && !propStatus.isEdit"
                      />
                    </template>
                  </Column>

                  <Column
                    field="label"
                    :header="$t('label_screen')"
                    :disabled="true"
                  >
                    <template #body="slotProps">
                      {{ $t(slotProps.data.label) }}
                    </template>
                  </Column>
                  <template #groupheader="slotProps">
                    <strong> {{ $t(slotProps.data.group_label) }}</strong>
                  </template>
                </DataTable>
              </div>
            </div>
          </CommonAccordion2>
        </div>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import CommonAccordion2 from "@/components/commons/accordion/Accordion2";
import InputText from "@/components/commons/inputtext/InputText";
import Checkbox from "@/components/commons/checkbox/Checkbox";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import Button from "@/components/commons/button/Button";
export default {
  props: {
    propEntity: {
      type: Object,
      default: () => {},
    },

    propStatus: {
      type: Object,
      default: () => {
        return {
          isAdd: false,
          isEdit: false,
        };
      },
    },
    propInterfaces: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    CommonAccordion,
    InputText,
    Checkbox,
    DataTable,
    Column,
    Button,
    CommonAccordion2,
  },
  data() {
    return {
      selectedInterfaces: [],
      selectedPath: null,
      checkAll: false,
    };
  },
  created() {},
  watch: {
    "propEntity.id"(val) {
      this.checkAll = false;
    },
    selectedInterfaces(val) {
      if (val && val.length > 0) {
        this.$emit("selectedInterfaces", val);
        if (val.length === this.propInterfaces.length) this.checkAll = true;
      } else {
        this.$emit("selectedInterfaces", []);
      }
    },
    "propEntity.urls"(val) {
      if (val && val.length > 0) {
        this.selectedInterfaces = Object.assign([], val);
        if (this.selectedInterfaces === this.propInterfaces.length)
          this.checkAll = true;
      } else this.selectedInterfaces = [];
    },
  },
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    styleLabel() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:120px";
    },
    styleLabelCheckbox() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:128px";
    },
    styleControl() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:calc(100% - 120px)";
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
  methods: {
    addPermission() {
      this.$emit("showDialogPermission");
    },
    checkAllChange() {
      if (this.checkAll) {
        this.selectedInterfaces = this.propInterfaces.map((x) => x.path);
      } else {
        this.selectedInterfaces = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
fieldset {
  border-color: transparent;
  margin: 0;
  padding: 0;
  padding: 0.5rem;
  legend {
    font-size: 14px;
    font-weight: 700;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem;
  }
}
fieldset {
  .form-group {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
<style lang="scss">
.role-permissions__datatable {
  position: relative;
  margin-top: 0.5rem;
  @import "@/assets/scss/_datatable.scss";
  .btn-add {
    border-radius: 50% !important;
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    width: 2rem !important;
    height: 2rem !important;
  }
  .p-datatable {
    .p-datatable-thead > tr > th {
      text-align: left !important;
    }
  }
}
.role-interface-list__datatable {
  margin-top: 0.5rem;
  @import "@/assets/scss/_datatable.scss";
}
</style>