<template>
  <Dialog
    :header="$t('header_select_permission')"
    :visible.sync="show"
    :modal="true"
    :contentStyle="{ width: dialogWidth + 'px' }"
    @hide="onClose"
  >
    <div class="datatable">
      <DataTable
        :value="propItems"
        ref="dtpermissions"
        :resizableColumns="true"
        columnResizeMode="expand"
        dataKey="id"
        :selection.sync="selected"
      >
        <template #empty v-if="propItems && propItems.length === 0">
          <span class="text-center">{{ $t("label_no_data") }}</span>
        </template>
        <Column
          header=""
          headerStyle="width: 3rem; text-align: center!important"
          bodyStyle="text-align: center;"
        >
          <template #body="slotProps">
            {{ slotProps.index + 1 }}
          </template></Column
        >

        <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
        <Column field="name" :header="$t('label_permission_code')"></Column>
        <!-- <Column
          field="description"
          :header="$t('label_permission_name')"
        ></Column>
        <Column :header="$t('label_name_vietnamese')">
          <template #body="slotProps">
            {{
              slotProps.data.translation && slotProps.data.translation.vi
                ? slotProps.data.translation.vi.description
                : ""
            }}
          </template>
        </Column> -->
        <Column :header="$t('label_permission_name')">
          <template #body="slotProps">
            {{
              slotProps.data.translation && slotProps.data.translation[lang]
                ? slotProps.data.translation[lang].description
                : ""
            }}
          </template>
        </Column>
      </DataTable>
    </div>
    <template #footer>
      <Button
        :label="$t('button_text_close')"
        icon="pi pi-times"
        class="p-button-danger p-button-sm"
        autofocus
        @click="onClose"
      />
      <Button
        @click="onSelected"
        :label="$t('button_text_select')"
        icon="pi pi-check"
        class="p-button-success p-button-sm"
      />
    </template>
  </Dialog>
</template>
<script>
import Button from "@/components/commons/button/Button";
import Dialog from "@/components/commons/dialog/Dialog";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
export default {
  components: {
    Button,
    Dialog,
    DataTable,
    Column,
  },
  props: {
    propItems: {
      type: Array,
      default: () => [],
    },
    propShow: {
      type: Boolean,
      default: false,
    },
    propName: {
      type: String,
      default: "",
    },
    propSelected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: null,
      show: false,
    };
  },
  created() {
    this.show = this.propShow;
    if (this.propItems && this.propItems.length === 0) {
      this.$emit("getAllPermission");
    }
  },
  mounted() {
    if (this.propSelected && this.propItems && this.propItems.length > 0)
      this.selected = Object.assign(
        [],
        this.propSelected.filter((x) => x.is_delete != true)
      );
  },
  watch: {
    propShow(val) {
      this.show = val;
    },
    propItems(val) {
      if (val && val.length > 0) {
        setTimeout(() => {
          if (this.propSelected) {
            this.selected = [];
            this.selected = Object.assign(
              [],
              this.propSelected.filter((x) => x.is_delete != true)
            );
          }
        }, 100);
      }
    },
  },
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    dialogWidth() {
      if (this.documentWidth >= 992) return 720;
      else if (this.documentWidth >= 768 && this.documentWidth < 992)
        return 720;
      else return this.documentWidth - 32;
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    onSelected() {
      this.$emit("selected", this.selected);
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-permissions {
  .p-dialog-footer {
    button {
      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>