

const geo_image= {

    get_metadata:{
        url:"/geo-image/get-metadata",
        method:"post",
        headers:{}
    },

    deactive:{
        url:"/geo-image/deactive/{id}",
        method:"put",
        headers:{}
    },
    onactive:{
        url:"/geo-image/onactive/{id}",
        method:"put",
        headers:{}
    },
    
    create:{
        url: "/geo-image/",
        method: "post",
        headers: {}
    },
    save_metadata:{
        url:"/geo-image/save",
        method:"post",
        headers:{}
    },
    upload_from_local:{
        url:"/geo-image/upload-from-local",
        method:"post",
        headers:{}
    },
    upload_preview:{
        url:"/geo-image/preview/u/{id}",
        method:"post",
        headers:{}
    },
    download_preview:{
        url:"/geo-image/preview/d/{id}",
        method:"get",
        headers:{}
    },
    upload_icon:{
        url:"/geo-image/icon/u/{id}",
        method:"post",
        headers:{}
    },
    download_icon:{
        url:"/geo-image/icon/d/{id}",
        method:"get",
        headers:{}
    },
    upload_earth:{
        url:"/geo-image/earth/u/{id}",
        method:"post",
        headers:{}
    },
    download_earth:{
        url:"/geo-image/earth/d/{id}",
        method:"get",
        headers:{}
    },
    upload_from_server:{
        url:"/geo-image/upload-from-server",
        method:"post",
        headers:{}
    },
    search:{
        url:"/geo-image/search",
        method:"post",
        headers:{}
    },
    download_preview_from_zip:{
        url:"/geo-image/get-preview-image",
        method:"get",
        headers:{}
    },
    download_preview_google_from_zip:{
        url:"/geo-image/get-preview-google-earth",
        method:"get",
        headers:{}
    },
    download_my_zip_file:{
        url:"/geo-image/download/my-zip-file/{id}",
        method:"get",
        headers:{}
    }
    
}

const sharing = {
    save:{
        url:"/sharing/save",
        method:"post",
        headers:{}
    },
    update:{
        url:"/meeting/",
        method:"put",
        headers:{}
    },
    delete:{
        url:"/sharing/{id}",
        method:"delete",
        headers:{}
    },
    find_by_image_id:{
        url:"/sharing/find-by-image/{id}",
        method:"get",
        headers:{}
    },
    my_shared_satellite:{
        url:"/sharing/my-shared-satellite",
        method:"get",
        headers:{}
    },
    my_shared_orthoimage:{
        url:"/sharing/my-shared-orthoimage",
        method:"get",
        headers:{}
    },
    my_shared_radar:{
        url:"/sharing/my-shared-radar",
        method:"get",
        headers:{}
    }
}

const user = {
    get_user_by_user_key:{
        url: "/api/v1/user/key",
        method: "get",
        headers: {} 
    },
    get_user_by_user_name:{
        url: "/user/findByUsername",
        method: "get",
        headers: {} 
    },
    create:{
        url: "/user",
        method: "post",
        headers: {
            "Content-Type": "application/json"
        }
    },
    update:{
        url: "/user",
        method: "put",
        headers: {
            "Content-Type": "application/json"
        }
    },
    delete:{
        url: "/user/{id}",
        method: "delete",
        headers: {}
    },
    get_by_id:{
        url: "/user/{id}",
        method: "get",
        headers: {
            "Content-Type": "application/json"
        }
    },
    get_all:{
        url: "/user",
        method: "get",
        headers: {
            "Content-Type": "application/json"
        }
    },
    deactive: {
        url: "/user/deactive/{id}",
        method: "put",
        headers: {
            "Content-Type": "application/json"
        }
    },
    active: {
        url: "/user/active/{id}",
        method: "put",
        headers: {
            "Content-Type": "application/json"
        }
    },
    add_role: {
        url: "/user/add_role",
        method: "post",
        headers: {
            "Content-Type": "application/json"
        }
    },
    search: {
       url: "/user/search",
        method: "post",
        headers: {
            "Content-Type": "application/json"
        } 
    }
}

const provider = {
    get_all:{
        url: "/provider/all",
        method: "get",
        headers: {}
    },
    get_by_id:{
        url: "/provider/{id}",
        method: "get",
        headers: {}
    },
    create:{
        url: "/provider/",
        method: "post",
        headers: {}
    },
    update:{
        url: "/provider/{id}",
        method: "put",
        headers: {}
    },
    delete:{
        url: "/provider/{id}",
        method: "delete",
        headers: {}
    }
}
const model = {
    get_all:{
        url: "/model/all",
        method: "get",
        headers: {}
    },
    get_by_id:{
        url: "/model/{id}",
        method: "get",
        headers: {}
    },
    
    get_by_type:{
        url: "/model/get-by-type",
        method: "get",
        headers: {}
    },
    get_by_provider:{
        url: "/model/list/{provider}",
        method: "get",
        headers: {}
    },
    create:{
        url: "/model/",
        method: "post",
        headers: {}
    },
    update:{
        url: "/model/{id}",
        method: "put",
        headers: {}
    },
    delete:{
        url: "/model/{id}",
        method: "delete",
        headers: {}
    }
}
const data_specification={
    get_by_id:{
        url: "/dataSpecification/{id}",
        method: "get",
        headers: {}
    },
    update:{
        url: "/dataSpecification/{id}",
        method: "put",
        headers: {}
    }
}
const permission ={
    get_list:{
        url: "/permission",
        method: "get",
        headers: {}
    },
    get_by_id:{
        url: "/permission/{id}",
        method: "get",
        headers: {}
    },
    create:{
        url: "/permission",
        method: "post",
        headers: {}
    },
    update:{
        url: "/permission",
        method: "put",
        headers: {}
    },
    delete:{
        url: "/permission",
        method: "delete",
        headers: {}
    }
}

const authority ={
    get_list:{
        url: "/api/v1/authorities",
        method: "get",
        headers: {}
    }
}
const role ={
    get_list:{
        url: "/role",
        method: "get",
        headers: {}
    },
    create:{
        url: "/role",
        method: "post",
        headers: {}
    },
    update:{
        url: "/role",
        method: "put",
        headers: {}
    },
    delete:{
        url: "/role/{role_id}",
        method: "delete",
        headers: {}
    },
    add_permission:{
        url: "/role/permission",
        method: "post",
        headers: {}
    },
    add_permissions:{
        url: "/role/permissions",
        method: "post",
        headers: {}
    },
    group: {
        url: "/role/group",
        method: "get",
        headers: {} 
    },
    roleByName: {
        url: "/role/getRoleByName",
        method: "get",
        headers: {} 
    }
}

const organisation = {
    get_list:{
        url: "/org/search",
        method: "post",
        headers: {}
    },
    get_all:{
        url: "/org/search",
        method: "post",
        headers: {}
    },
    get_by_id:{
        url: "/org/{id}",
        method: "get",
        headers: {}
    },
    create:{
        url: "/org",
        method: "post",
        headers: {}
    },
    update:{
        url: "/org",
        method: "put",
        headers: {}
    },
    delete:{
        url: "/org/{id}",
        method: "delete",
        headers: {}
    }
}

const organisation_type = {
    get_all:{
        url: "/api/v1/operation/operation-type/",
        method: "get",
        headers: {}
    },
    get_by_id:{
        url: "/api/v1/operation/operation-type/{id}",
        method: "get",
        headers: {}
    }
}

const order = {
    get_list_ordered:{
        url: "/cart/all-ordered",
        method: "get",
        headers: {}
    },
    approve:{
        url: "/cart/approve/{id}",
        method: "put",
        headers: {}
    },
    get_all:{
        url: "/order/",
        method: "get",
        headers: {}
    },
    
    get_by_user:{
        url: "/order/getOrderByUser",
        method: "get",
        headers: {}
    },
    get_by_id:{
        url: "/order/{id}",
        method: "get",
        headers: {}
    },
    add_status:{
        url: "/order/status",
        method: "put",
        headers: {}
    },
    update_images_order:{
        url: "/order/image",
        method: "put",
        headers: {}
    }
}
const cloud_user = {
    get_by_userkey:{
        url: "/cloud-user/user-key/{user_key}",
        method: "get",
        headers: {}
    },
    create:{
        url: "/cloud-user/",
        method: "post",
        headers: {}
    },
    update:{
        url: "/cloud-user/{id}",
        method: "put",
        headers: {}
    }
}
const administrative = {
    create:{
        url: "/administrative/",
        method: "post",
        headers: {}
    }
}
const customer = {
    create:{
        url: "/customer",
        method: "post",
        headers: {}
    },
    update:{
        url: "/customer/{id}",
        method: "put",
        headers: {}
    },
    delete:{
        url: "/customer/{id}",
        method: "delete",
        headers: {}
    },
    get_by_id:{
        url: "/customer/{id}",
        method: "get",
        headers: {}
    },
    get_list:{
        url:"/customer",
        method: "get",
        headers: {}
    },
}

const image = {
    search:{
        url: "/image/search",
        method: "post",
        headers: {}
    },
    create:{
        url: "/image",
        method: "post",
        headers: {}
    },
    update:{
        url: "/image",
        method: "put",
        headers: {}
    },
    delete:{
        url: "/image/{id}",
        method: "delete",
        headers: {}
    },
    downoad:{
        url: "/image/downoad/{id}",
        method: "get",
        headers: {}
    },
    get_by_id:{
        url: "/image/{id}",
        method: "get",
        headers: {}
    },
    upload_image:{
        url: "/image/upload",
        method: "put",
        headers: {}
    },
    create_link_upload_preview:{
        url: "/image/previews/upload",
        method: "put",
        headers: {}
    }
    
}


const file = {   
    get_metadata:{
        //url: "/files/get_metadata",
        url: "/metadata/get_metadata",
        method: "get",
        headers: {}
    },
    download_preview:{
        url: "/files/preview/{key}",
        method: "get",
        headers: {}
    },
    data_from_xml: {
        //url: "/files/metadata/xml",
        url: "/metadata/metadata/xml",
        method: "post",
        headers: {}
    },
    put_object: {
        url: "/files",
        method: "post",
        headers: {}
    },
    generate_url_upload:{
        url: "/files/url-upload",
        method: "get",
        headers: {}
    }
}


const project = {
    get_all:{
        url: "/project/search",
        method: "post",
        headers: {}
    },
    get_by_id:{
        url: "/project/{id}",
        method: "get",
        headers: {}
    },
    create:{
        url: "/project/",
        method: "post",
        headers: {}
    },
    update:{
        url: "/project/{id}",
        method: "put",
        headers: {}
    },
    delete:{
        url: "/project/{id}",
        method: "delete",
        headers: {}
    },
    search_project:{
        url: "/project/search",
        method: "post",
        headers: {}
    },
    add_member:{
        url:"/project/member/{project_id}",
        method: "post",
        headers: {}
    },
    change_role_member:{
        url:"/project/member/{project_id}",
        method: "put",
        headers: {}
    },
    delete_member:{
        url:"/project/member/{project_id}",
        method: "delete",
        headers: {}
    },
    change_status:{
        url:"/project/status/{project_id}",
        method: "put",
        headers: {}
    },
    add_image:{
        url:"/project/image/{project_id}",
        method: "post",
        headers: {}
    },
    search: {
         url:"/project/search",
        method: "post",
        headers: {}
    },
    upload_attachment: {
        url:"/project/file/{project_id}",
        method: "post",
        headers: {}
    },
    delete_attachment: {
        url:"/project/file/{project_id}",
        method: "delete",
        headers: {}
    },
    download_attachment: {
        url:"/project/file/{project_id}",
        method: "get",
        headers: {}
    },
    create_link_upload:{
        url:"/project/file/{projectId}/upload",
        method: "put",
        headers: {}
    }
}

const meta_key ={
    get_list:{
        url:"/meta-key",
        method: "get",
        headers: {}
    },
    get_by_key:{
        url:"/meta-key/getByKey",
        method: "get",
        headers: {}
    },
    create:{
        url:"/meta-key",
        method: "post",
        headers: {}
    },
    update:{
        url:"/meta-key",
        method: "put",
        headers: {}
    },
    delete:{
        url:"/meta-key/{id}",
        method: "delete",
        headers: {}
    }
}

const image_sharing={
    create:{ 
        url:"/share/",
        method: "post",
        headers: {}
    },
    download:{
        url:"/share/d",
        method: "get",
        headers: {}
    },
    get_all: {
        url:"/share/search",
        method: "post",
        headers: {}
    },
    get_by_page: {
        url:"/share/search",
        method: "post",
        headers: {}
    },
    get_by_id: {
         url:"/share/{id}",
        method: "get",
        headers: {}
    },
    delete: {
        url:"/share/{id}",
        method: "delete",
        headers: {}
    },
    remove_user: {
        url:"/share/remove/user",
        method: "delete",
        headers: {}
    }

}
const statistic  ={
    get_statistic:{
        url:"/statistic",
        method: "get",
        headers: {}
    },
    get_summary:{
        url:"/statistic/satellite",
        method: "get",
        headers: {}
    }
}

const log = {
    get_log:{
        url:"/log/",
        method: "get",
        headers: {}
    }
}


const me_controller ={
    my_share_image:{
        url: "/me/sharing",
        method: "get",
        headers: {}
    },
    my_order:{
        url: "/me/order",
        method: "get",
        headers: {}
    },
    cancel_order:{
        url: "/me/order/cancel",
        method: "put",
        headers: {}
    },
    my_cart:{
        url: "/me/cart",
        method: "get",
        headers: {}
    },
    my_project:{
        url: "/me/project",
        method: "get",
        headers: {}
    },
    shared_by_me:{
        url: "/me/sharing/me",
        method: "post",
        headers: {}
    },
    create_share:{
        url: "/me/sharing",
        method: "post",
        headers: {}
    },
    my_logs:{
        url: "/me/logs",
        method: "get",
        headers: {}
    },
    change_pass:{
        url: "/me/password",
        method: "put",
        headers: {}
    }
    
}


const user_me = {
    info:{
        url:"/me",
        method: "get",
        headers: {}
    },
    update:{
        url:"/me",
        method: "put",
        headers: {}
    },
    change_pass:{
        url:"/me/password",
        method: "put",
        headers: {}
    }
}
const contact = {
    create:{
        url:"/contact",
        method: "post",
        headers: {}
    },
    update:{
        url:"/contact",
        method: "put",
        headers: {}
    },
    delete:{
        url:"/contact/{id}",
        method: "delete",
        headers: {}
    },
    get_all: {
         url:"/contact",
        method: "get",
        headers: {}
    },
    get_detail: {
         url:"/contact/{id}",
        method: "get",
        headers: {}
    },
    add_comment: {
         url:"/contact/comment",
        method: "post",
        headers: {}
    },
    update_comment: {
         url:"/contact/comment",
        method: "put",
        headers: {}
    },
    delete_comment: {
        url:"/contact/comment",
        method: "delete",
        headers: {}
    }
}
const processing_data = {
    create_model:{
        url:"/metadata/metadata/model",
        method: "post",
        headers: {}
    },
    update_model:{
        url:"/metadata/metadata/model",
        method: "put",
        headers: {}
    },
    delete_model:{
        url:"/metadata/metadata/model/{id}",
        method: "delete",
        headers: {}
    },
    get_all_model:{
        url:"/metadata/metadata/model/search",
        method: "post",
        headers: {}
    }
}
export default { sharing, geo_image ,user,
    project, provider,model,data_specification,permission,authority,role,organisation,order,organisation_type,cloud_user,administrative,
    customer, image, meta_key, file,image_sharing, statistic, log, me_controller, user_me, contact, processing_data
}