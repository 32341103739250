<template>
  <CommonAccordion :propTitle="$t('menu_sharing_list')">
    <div slot="content">
      <div class="datatable">
        <ContextMenu :model="menuModel" ref="cmSharingDatatable" />
        <DataTable
          :value="propItems"
          ref="dt"
          :loading="propLoading"
          :scrollable="true"
          :resizableColumns="false"
          :scrollHeight="propTable.height - 40 - 38 + 'px'"
          contextMenu
          :selection.sync="selected"
          @row-contextmenu="onRowContextMenu"
          selectionMode="single"
          dataKey="id"
          :virtualScroll="true"
          @virtual-scroll="onVirtualScroll"
          :rowClass="rowClass"
        >
          <template #header>
            <div class="text-left">
              <div>
                <div v-if="propItems.length" class="font-weight-normal">
                  {{
                    $t("label_show_from_to", null, {
                      from: 1,
                      to: propItems.length,
                      total: propTotal,
                    })
                  }}
                </div>
              </div>
            </div>
          </template>
          <template #empty v-if="propItems && propItems.length === 0">
            <span class="text-center">{{ $t("label_no_data") }}</span>
          </template>
          <Column
            header="No"
            headerStyle="width: 3rem; text-align: center!important"
            bodyStyle="text-align: center;"
          >
            <template #body="slotProps">
              {{ slotProps.index + 1 }}
            </template></Column
          >
          <Column
            :header="$t('label_image_name')"
            bodyStyle="width: 32rem"
            headerStyle="width: 32rem"
          >
            <template #body="slotProps">
              <span
                :title="slotProps.data.image ? slotProps.data.image.name : ''"
                >{{
                  slotProps.data.image ? slotProps.data.image.name : ""
                }}</span
              >
            </template>
          </Column>
          <Column
            :header="$t('label_receiver')"
            headerStyle="width: 14rem"
            bodyStyle="width: 14rem"
          >
            <template #body="slotProps">
              <div
                v-if="
                  slotProps.data.member &&
                  slotProps.data.member.filter((x) => x).length > 0
                "
              >
                <ol>
                  <li v-for="m in slotProps.data.member" :key="m.id">
                    {{ m.full_name }}
                  </li>
                </ol>
              </div>
              <div v-else></div>
            </template>
          </Column>
          <Column
            :header="$t('label_table_sharing_time')"
            headerStyle="width: 12rem"
            bodyStyle="width: 12rem"
          >
            <template #body="slotProps">
              {{
                slotProps.data.created_at
                  ? formatUnixDateTime2String(slotProps.data.created_at)
                  : ""
              }}
            </template>
          </Column>
          <Column
            field="download_number"
            :header="$t('label_table_remaining_downloads')"
            headerStyle="width: 12rem"
            bodyStyle="width: 12rem"
          ></Column>
          <Column
            :header="$t('label_table_expired_time')"
            headerStyle="width: 12rem"
            bodyStyle="width: 12rem"
          >
            <template #body="slotProps">
              {{
                slotProps.data && slotProps.data.expired_time
                  ? formatUnixDateTime2String(slotProps.data.expired_time)
                  : ""
              }}
            </template>
          </Column>

          <Column
            headerStyle="width: 11rem"
            bodyStyle="width: 11rem;"
            :headerClass="'p-frozen-column right'"
            :bodyClass="'p-frozen-column right'"
          >
            <template #body="slotProps">
              <Button
                v-if="slotProps.data.image"
                icon="fas fa-list-alt"
                type="button"
                class="p-button-success p-button-sm mr-1"
                :title="$t('button_text_metadata')"
                @click="showDialogMetadata(slotProps.data)"
              >
              </Button>

              <Button
                :icon="'fas fa-download'"
                type="button"
                class="p-button-sm p-button-primary mr-1"
                :title="$t('button_text_download')"
                @click="downloadImage(slotProps.data)"
                v-if="
                  slotProps.data.image &&
                  $store.getters.getPermissions.includes('P_DOWNLOAD_ALL_IMAGE')
                "
              >
              </Button>
              <Button
                :icon="'fas fa-share-alt'"
                type="button"
                class="p-button-sm p-button-primary mr-1"
                :title="$t('button_text_share')"
                @click="showDialogShare(slotProps.data)"
                v-if="
                  slotProps.data.image &&
                  $store.getters.getPermissions.includes('SHARE_IMAGE_CREATE')
                "
              >
              </Button>

              <Button
                v-if="
                  slotProps.data.image &&
                  !disableButton(slotProps.data) &&
                  $store.getters.getPermissions.includes('SHARE_IMAGE_CREATE')
                "
                :icon="'fas fa-eraser'"
                type="button"
                class="p-button-sm p-button-danger"
                :title="$t('button_text_cancel_share')"
                @click="showDialogCancelShare(slotProps.data)"
              >
              </Button>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import Button from "@/components/commons/button/Button";
import ContextMenu from "@/components/commons/contextmenu/ContextMenu";
import { formatUnixDateTime2String } from "@/utils/commons/common";
import moment from "moment";
export default {
  components: {
    CommonAccordion,
    DataTable,
    Column,
    Button,
    ContextMenu,
  },
  props: {
    propItems: {
      type: Array,
      default: () => [],
    },
    propTable: {
      type: Object,
      default: () => {},
    },
    propLoading: {
      type: Boolean,
      default: false,
    },
    propTableHeight: {
      type: Number,
      default: 400,
    },
    propTotal: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      selected: null,
      menuModel: [
        {
          label: this.$t("button_text_metadata"),
          icon: "fas fa-list-alt",
          command: () => this.showDialogMetadata(this.selected),
          visible: true,
        },
        {
          label: this.$t("button_text_download"),
          icon: "fas fa-download",
          command: () => this.downloadImage(this.selected),
          visible: true,
        },
        {
          label: this.$t("button_text_share"),
          icon: "fas fa-share-alt",
          command: () => this.showDialogShare(this.selected),
          visible: true,
        },
        {
          label: this.$t("button_text_cancel_share"),
          icon: "fas fa-eraser",
          command: () => this.showDialogCancelShare(this.selected),
          visible: true,
        },
      ],
    };
  },
  methods: {
    onVirtualScroll(e) {
      this.$emit("nextPage");
    },
    async onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.$emit("nextPage");
      }
    },
    formatUnixDateTime2String(value) {
      let result = "";
      if (value) {
        try {
          let date = new Date(value);
          result = moment
            .unix(date.getTime() / 1000)
            .format("HH:mm DD/MM/YYYY");
          result = result
            .replace(":", this.lang === "vi" ? "h" : ":")
            .replace(" ", this.lang === "vi" ? " ngày " : " ");
        } catch (error) {}
      }
      return result;
    },
    onRowContextMenu(event) {
      if (this.selected && Object.keys(this.selected).length > 0) {
        if (
          this.selected.image &&
          Object.keys(this.selected.image).length > 0
        ) {
          if (
            this.disableButton(this.selected) &&
            !this.$store.getters.getPermissions.includes("SHARE_IMAGE_CREATE")
          ) {
            this.menuModel[3].visible = false;
          } else this.menuModel[3].visible = true;
          if (
            this.$store.getters.getPermissions.includes("SHARE_IMAGE_CREATE")
          ) {
            this.menuModel[2].visible = true;
          } else this.menuModel[2].visible = false;

          if (
            this.$store.getters.getPermissions.includes("P_DOWNLOAD_ALL_IMAGE")
          ) {
            this.menuModel[1].visible = true;
          } else this.menuModel[1].visible = false;
          if (this.menuModel.filter((x) => x.visible).length > 0)
            this.$refs.cmSharingDatatable.show(event.originalEvent);
        }
      }
    },
    showDialogMetadata(item) {
      this.$emit("showDialogMetadata", item.image);
    },
    downloadImage(item) {
      this.$emit("downloadImage", item.image);
    },
    showDialogShare(item) {
      this.$emit("showDialogShare", item);
    },
    showDialogCancelShare(item) {
      this.$emit("showDialogCancelShare", item);
    },
    rowClass(data) {
      let result = null;
      if (data && Object.keys(data).length > 0) {
        if (data.expired_time) {
          if (new Date(data.expired_time) - new Date() < 0) {
            result = "bg-secondary text-white";
          } else if (
            new Date(data.expired_time) - new Date() <
            3 * 24 * 60 * 60 * 1000
          ) {
            result = "bg-gray-400";
          }
        }
      }
      return result;
    },
    disableButton(data) {
      let result = false;
      if (data && Object.keys(data).length > 0) {
        if (data.expired_time) {
          if (new Date(data.expired_time) - new Date() < 0) {
            result = true;
          }
        }
        try {
          if (
            data.download_number != null &&
            data.download_number != undefined &&
            parseInt(data.download_number) <= 0
          ) {
            result = true;
          }
        } catch (error) {}
      }
      return result;
    },
  },
  computed: {
    username() {
      return this.$store.getters.getUserName;
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
.datatable {
  ol {
    margin: 0;
    padding-left: 1rem;
  }
}
</style>
<style lang="scss">
.sharing__datatable {
  @import "@/assets/scss/_datatable.scss";
}
</style>