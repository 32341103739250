<template>
  <CommonAccordion
    :propTitle="$t('title_search')"
    @accordionHeaderClick="accordionHeaderClick"
  >
    <div slot="content">
      <div class="form-search">
        <div class="form-row no-gutters">
          <div class="col">
            <label class="control-label">{{
              $t("label_username_upload")
            }}</label>
            <Dropdown
              style="width: 100%"
              v-model="search.user_id"
              :showClear="true"
              :options="propUser"
              optionValue="id"
              :filter="true"
              optionLabel="full_name"
              dataKey="id"
              class="new-style"
            >
              <template #option="slotProps">
                <span
                  >{{ slotProps.option.full_name }} ({{
                    slotProps.option.username
                  }})</span
                >
              </template></Dropdown
            >
          </div>
          <div class="col">
            <label class="control-label">{{ $t("label_date_from") }}</label>
            <date-picker
              v-model="search.create_from"
              class="w-100"
              :format="'DD/MM/YYYY'"
              valuetype="timestamp"
              :disabled-date="notAfterToday"
              :lang="lang"
              input-class="form-control new-style"
            >
            </date-picker>
          </div>
          <div class="col">
            <label class="control-label">{{ $t("label_date_to") }}</label>
            <date-picker
              v-model="search.create_to"
              class="w-100"
              :format="'DD/MM/YYYY'"
              valuetype="timestamp"
              :disabled-date="notAfterToday"
              :lang="lang"
              input-class="form-control new-style"
            >
            </date-picker>
          </div>
          <div class="col">
            <button
              :label="$t('button_text_search')"
              icon="fas fa-search"
              type="button"
              class="btn btn-geostore2"
              :title="$t('button_text_search')"
              @click="onSearch"
            >
              <i class="fas fa-search"></i>
              &nbsp;
              {{ $t("button_text_search") }}
            </button>
            <button
              :label="$t('button_text_read_all')"
              icon="fas fa-clipboard-list"
              type="button"
              class="btn btn-geostore2-transparent float-right"
              :title="$t('title_read_all_metadata_error')"
              @click="getAllMetadata()"
            >
              <i class="fas fa-redo-alt"></i>&nbsp;
              {{ $t("button_text_read_all") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import Button from "@/components/commons/button/Button";
export default {
  components: {
    DatePicker,
    Dropdown,
    CommonAccordion,
    Button,
  },
  props: {
    propOrderStatus: {
      type: Array,
      default: () => [],
    },
    propUser: {
      type: Array,
      default: () => [],
    },
    propSearch: {
      type: Object,
      default: () => {
        return {
          create_from: null,
          create_to: null,
          user_id: null,
        };
      },
    },
  },
  data() {
    return {
      search: {
        create_from: null,
        create_to: null,
        user_id: null,
      },
    };
  },
  created() {
    this.search.create_from = this.propSearch.create_from;
    this.search.create_to = this.propSearch.create_to;
    this.search.user_id = this.propSearch.user_id;
  },
  watch: {
    propSearch: {
      handler(val) {
        if (val && Object.keys(val).length > 0) {
          this.search.create_from = val.create_from;
          this.search.create_to = val.create_to;
          this.search.user_id = val.user_id;
        }
      },
      immediate: true,
    },
  },
  methods: {
    accordionHeaderClick() {
      this.$emit("accordionHeaderClick");
    },
    onSearch() {
      if (!this.search.create_from && this.search.create_to) {
        this.$emit("message", [
          this.$t("message_search_from_time_is_required"),
        ]);
        return;
      } else if (this.search.create_from && !this.search.create_to) {
        this.$emit("message", [this.$t("message_search_to_time_is_required")]);
        return;
      } else if (this.search.create_from && this.search.create_to) {
        let from = new Date(this.search.create_from).setHours(0, 0, 0, 0);
        let to = new Date(this.search.create_to).setHours(23, 59, 59, 0);
        if (from - to > 0) {
          this.$emit("message", [
            this.$t(
              "message_search_to_time_must_greater_than_or_equal_from_time"
            ),
          ]);
          return;
        }

        this.$emit("onSearch", {
          create_from: from,
          create_to: to,
          user_id: this.search.user_id,
        });
      } else
        this.$emit("onSearch", {
          create_from: null,
          create_to: null,
          user_id: this.search.user_id,
        });
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    getMetadata() {},
    getAllMetadata() {
      this.$emit("getAllMetadata");
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
.form-search {
  padding: 0.25rem;
  button {
    margin-top: 1.25rem;
    font-size: 14px;
  }
  label {
    margin: 0;
    padding: 0;
    padding-left: 0.25rem;
  }
}
.form-group {
  margin-bottom: 0.5rem;
  margin: 0;
  label {
    padding-left: 0.25rem;
  }
}
</style>

<style lang="scss">
.upload-history__search {
  .mx-datepicker {
    width: 100%;
    .mx-input {
      border-color: #a6a6a6 !important;
      height: 33px;
      border-radius: 3px;
      &:hover {
        border-color: #212121 !important;
      }
      &:disabled,
      .disabled {
        background-color: transparent;
      }
    }
  }
}
</style>