<template>
  <div class="preview-icon border">
    <div
      class="spinner-border spinner-border-sm align-items-center mt-4"
      role="status"
      v-if="!iconSrc"
    >
      <span class="sr-only">Loading...</span>
    </div>
    <img
      :src="enpoint + propSrc"
      class="preview-image"
      @click.stop="showPreviewImage"
      v-else
    />
  </div>
</template>
<script>
import fileFunc from "@/utils/functions/file";
import { enpointPreviewUrl, minioPrefix } from "@/utils/commons/environment";

export default {
  props: {
    propId: {
      type: String,
      default: "",
    },
    propSrc: {
      required: true,
    },
    propType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      iconSrc: null,
      type: "img",
      enpoint: enpointPreviewUrl + minioPrefix,
    };
  },
  mounted() {
    //if (this.propSrc) this.iconSrc = this.propSrc;
    //else this.getIconPreview();
  },
  watch: {
    propId: {
      handler(val) {
        if (!this.propSrc) {
          this.iconSrc = null;
          this.getIconPreview();
        }
      },
      immediate: true,
    },
    propSrc: {
      handler(val) {
        this.iconSrc = val;
      },
      immediate: true,
    },
  },
  methods: {
    async getIconPreview() {
      try {
        let response = await fileFunc.downloadPreview(
          this.propId,
          this.$store.getters.getAccessToken
        );
        if (response.status === 200) {
          let contentType =
            response.headers && response.headers["content-type"]
              ? response.headers["content-type"]
              : "image/jpg";
          if (contentType === "image/tif") this.type = "tif";
          let blob = new Blob([response.data], {
            type: contentType,
          });
          let arrSplit = contentType.split("/");
          if (blob.size != 0) {
            if (!window.navigator.msSaveOrOpenBlob) {
              // BLOB NAVIGATOR
              this.iconSrc = window.URL.createObjectURL(blob);
            } else {
              // BLOB FOR EXPLORER 11
              this.iconSrc = window.navigator.msSaveOrOpenBlob(
                blob,
                this.propId + "." + arrSplit[arrSplit.length - 1]
                  ? arrSplit[arrSplit.length - 1]
                  : "jpg"
              );
            }
            this.$emit("initSuccessImange", {
              id: this.propId,
              val: this.iconSrc,
            });
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
        } else {
          this.iconSrc = "/img/icons/no-image.png";
          this.$emit("initSuccessImange", {
            id: this.propId,
            val: this.iconSrc,
          });
        }
      }
    },
    showPreviewImage() {
      this.$emit("showPreviewImage");
    },
  },
};
</script>
<style lang="scss" scoped>
.preview-icon {
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: white;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
