<template>
  <main-layout
    :isToolbar="true"
    :isBreadcrumb="true"
    :breadcrumbitems="[
      { label: $t('menu_administration') },
      { label: $t('menu_management_user') },
    ]"
    :propButtonsState="{
      save: buttons.save,
      add: buttons.add,
      edit: buttons.edit,
      reload: buttons.reload,
      print: {
        visible: false,
      },
      delete: buttons.delete,
    }"
    :propTitle="$t('menu_management_user')"
    @emitFunction="menuBarFunction($event)"
  >
    <div class="user-content" id="userContent">
      <div class="user-content__dialog">
        <DialogConfirmation
          v-if="confirm.show"
          :prop-show="confirm.show"
          :prop-title="confirm.title"
          :prop-icon="confirm.icon"
          :prop-message="confirm.message"
          :textNo="$t('button_text_close')"
          :textYes="$t('button_text_save')"
          @cancel="onCancelConfirm"
          @confirmed="onConfirmed"
        />

        <DialogConfirmation
          v-if="confirmDelete.show"
          :prop-show="confirmDelete.show"
          :prop-title="$t('title_message_confirm')"
          :prop-icon="confirmDelete.icon"
          :prop-message="confirmDelete.message"
          :textNo="$t('button_text_close')"
          :textYes="$t('button_text_delete')"
          @cancel="onCancelConfirmDelete"
          @confirmed="onConfirmedDelete"
        />
        <DialogMessage
          v-if="message.length > 0"
          :propTitle="$t('title_message_info')"
          :prop-show="message.length > 0"
          :prop-message="message"
          @close="message = []"
        />
      </div>
      <div class="user-content__filter">
        <UserFilter
          :prop-buttons="{
            filter: buttons.filter,
          }"
          :prop-roles="role.items"
          @onSearch="onSearch"
        />
      </div>
      <div class="row no-gutters">
        <div class="user-content__datatable">
          <DataTable
            ref="userDatatable"
            :prop-first="employee.first"
            :propData="{
              items: employee.items,
              page_number: employee.page_number,
              page_size: employee.page_size,
              total_page: employee.total_page,
              total_records: employee.total_records,
            }"
            :propTable="employee.table"
            @rowClick="rowClick"
          />
        </div>

        <div class="user-content__structure">
          <ValidationObserver ref="userObserver">
            <UserStructure
              :prop-entity="employee.entity"
              :propRole="role.items"
              :propOrganisation="organisation.items"
              ref="userStructure"
              :prop-status="{
                isAdd: employee.isAdd,
                isEdit: employee.isEdit,
                isChangePass: employee.isChangePass,
              }"
              @isChangePassChange="employee.isChangePass = $event"
            />
          </ValidationObserver>
          <div class="actions">
            <Button
              :icon="
                buttons.prev.processing
                  ? 'fa fa-spinner fa-spin'
                  : 'pi pi-chevron-left'
              "
              class="p-button-rounded p-button-secondary"
              :disabled="buttons.prev.disabled"
              @click="prevItem"
            />
            <Button
              :icon="
                buttons.next.processing
                  ? 'fa fa-spinner fa-spin'
                  : 'pi pi-chevron-right'
              "
              class="p-button-rounded p-button-secondary"
              :disabled="buttons.next.disabled"
              @click="nextItem"
            />
          </div>
        </div>
      </div>
    </div>
  </main-layout>
</template>
<script>
import MainLayout from "@/components/pages/layouts/admin/Main";
import DataTable from "./DataTable";
import UserFilter from "./Filter";
import UserTree from "./Tree";
import UserStructure from "./Structure";
import Button from "@/components/commons/button/Button";
import ModalDefault from "@/components/commons/modal/Default";
import MessageBoxConfirm from "@/components/commons/messagebox/Confirm";
import DialogConfirmation from "@/components/commons/dialog/Confirmation";
import DialogMessage from "@/components/commons/dialog/MessageBox";
import { ValidationObserver } from "vee-validate";
import userFunc from "@/utils/functions/user";
import roleFunc from "@/utils/functions/role";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import organisationFunc from "@/utils/functions/organisation";
export default {
  components: {
    MainLayout,
    DataTable,
    UserFilter,
    UserTree,
    UserStructure,
    ModalDefault,
    MessageBoxConfirm,
    ValidationObserver,
    Button,
    DialogConfirmation,
    DialogMessage,
  },
  data() {
    return {
      message: [],
      role: {
        items: [],
      },
      organisation: {
        items: [],
      },
      employee: {
        items: [],
        isAdd: false,
        isEdit: false,
        isChangePass: false,
        page_number: 0,
        page_size: 2147483647,
        total_page: 0,
        total_records: 0,
        first: -1,
        entity: {
          id: null,
          address: null,
          email: null,
          full_name: null,
          password: null,
          phone_number: null,
          username: null,
          is_active: true,
          is_active_old: true,
          role_id: null,
          role_id_old: null,
          organization: null,
          gender: null,
        },
        table: {
          selected: null,
          page: 0,
          size: 10,
        },
        tree: {
          selected: null,
        },
        search: {
          is_active: null,
          name: null,
          role_id: null,
          size: 2147483647,
          page: 0,
          order: "full_name",
          sort: "asc",
        },
      },
      search: {
        page: 0,
        size: 100,
        active: true,
        search: "",
      },

      confirmDelete: {
        message: null,
        show: false,
        title: null,
        icon: "fas fa-question",
        value: false,
      },
      confirm: {
        message: null,
        show: false,
        title: this.$t("title_message_confirm"),
        icon: "fas fa-question",
        callback: {
          no: {
            method: null,
            params: null,
          },
          yes: {
            method: null,
            params: null,
          },
        },
        initValue: [],
        value: false,
      },
      selected: {
        items: [],
      },
      buttons: {
        prev: {
          disabled: true,
          processing: false,
          visible: true,
        },
        next: {
          disabled: true,
          processing: false,
          visible: true,
        },
        save: {
          disabled: true,
          processing: false,
          visible: true,
        },
        edit: {
          disabled: true,
          processing: false,
          visible: true,
        },
        add: {
          disabled: false,
          processing: false,
          visible: true,
        },
        filter: {
          disabled: false,
          processing: false,
          visible: true,
        },
        reload: {
          disabled: false,
          processing: false,
          visible: true,
        },
        delete: {
          disabled: true,
          processing: false,
          visible: true,
        },
      },
    };
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    accessToken() {
      return this.$store.getters.getAccessToken;
    },
    permissions() {
      return this.$store.getters.getPermissions;
    },
  },
  async created() {
    this.getAllRole();
    this.getAllUser();
    this.getAllOrganisation();
  },
  mounted() {
    if (!this.permissions.includes("U_ADD_NEW_USER"))
      this.buttons.add.visible = false;
    if (!this.permissions.includes("U_UPDATE_USER"))
      this.buttons.edit.visible = false;
    if (!this.permissions.includes("U_DELETE_USER"))
      this.buttons.delete.visible = false;
    if (
      !this.permissions.includes("U_ADD_NEW_USER") &&
      !this.permissions.includes("U_UPDATE_USER")
    )
      this.buttons.save.visible = false;
  },
  methods: {
    generateEntity() {
      return {
        id: null,
        address: null,
        email: null,
        full_name: null,
        password: null,
        phone_number: null,
        username: null,
        is_active: true,
        is_active_old: true,
        role_id: null,
        role_id_old: null,
        organization: null,
        organization_object: null,
        gender: null,
      };
    },
    entityFromSelected(data) {
      if (data && Object.keys(data).length > 0) {
        this.employee.entity.id = data.id ? data.id : null;
        this.employee.entity.address = data.address
          ? data.address.trim().replace(/\s\s+/g, " ")
          : null;
        this.employee.entity.email = data.email
          ? data.email.trim().replace(/\s\s+/g, " ")
          : null;
        this.employee.entity.full_name = data.full_name
          ? data.full_name.trim().replace(/\s\s+/g, " ")
          : null;
        this.employee.entity.username = data.username
          ? data.username.trim().replace(/\s\s+/g, " ")
          : null;
        this.employee.entity.phone_number = data.phone_number
          ? data.phone_number.trim().replace(/\s\s+/g, " ")
          : null;
        this.employee.entity.is_active =
          data.is_active != undefined || data.is_active != null
            ? data.is_active
            : false;
        this.employee.entity.is_active_old = this.employee.entity.is_active;
        if (data.roles && data.roles.length > 0) {
          if (data.roles[data.roles.length - 1]) {
            this.employee.entity.role_id = data.roles[data.roles.length - 1].id;
            this.employee.entity.role_id_old = this.employee.entity.role_id;
          }
        }
        this.employee.entity.gender =
          data.gender != null || data.gender != undefined
            ? data.gender + ""
            : null;
        this.employee.entity.organization = data.organization
          ? data.organization.trim().replace(/\s\s+/g, " ")
          : null;
      }
    },

    async prevItem() {
      if (this == undefined) return;
      if (this.$refs.userDatatable) {
        let data = this.$refs.userDatatable.$refs.dt.processedData;
        let selection = this.$refs.userDatatable.$refs.dt.selection;
        if (!selection) return;
        let index = data.findIndex((x) => x.id === selection.id);
        this.buttons.prev.processing = true;
        this.buttons.prev.disabled = true;
        this.$store.dispatch("setSpinnerApp", {
          show: true,
        });
        await this.entityFromSelected(data[index - 1]);
        this.employee.table.selected = data[index - 1];
        this.buttons.prev.processing = false;
        this.buttons.prev.disabled = false;

        if (index === 1) {
          this.buttons.prev.disabled = true;
          this.buttons.next.disabled = false;
        } else {
          this.buttons.next.disabled = false;
          this.buttons.prev.disabled = false;
        }

        this.employee.table.page = Math.floor((index - 1) / 10);
        this.employee.first = Math.floor((index - 1) / 10) * 10;
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
      }
    },
    async nextItem() {
      if (this == undefined) return;
      if (this.$refs.userDatatable) {
        let data = this.$refs.userDatatable.$refs.dt.processedData;
        let selection = this.$refs.userDatatable.$refs.dt.selection;
        if (!selection) return;
        let index = data.findIndex((x) => x.id === selection.id);
        this.buttons.next.processing = true;
        this.buttons.next.disabled = true;
        this.$store.dispatch("setSpinnerApp", {
          show: true,
        });
        await this.entityFromSelected(data[index + 1]);
        this.employee.table.selected = data[index + 1];
        this.buttons.next.processing = false;
        this.buttons.next.disabled = false;

        if (index === data.length - 2) {
          this.buttons.prev.disabled = false;
          this.buttons.next.disabled = true;
        } else {
          this.buttons.prev.disabled = false;
          this.buttons.next.disabled = false;
        }
        this.employee.table.page = Math.floor((index + 1) / 10);
        this.employee.first = Math.floor((index + 1) / 10) * 10;
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
      }
    },
    // return false. Entity chua bi thay doi
    // return true. Entity da bi thay doi
    checkEntity() {
      var vm = this,
        result = false;
      for (const [key, value] of Object.entries(this.employee.entity)) {
        if (value != null) {
          switch (typeof value) {
            case "number":
              if (value > 0) result = true;

              break;
            case "string":
              if (value.trim()) result = true;
              break;
            case "undefined":
              break;
          }
        }
        if (result) break;
      }
      return result;
    },
    /* ---------- Emit ----------*/
    async rowClick(val) {
      this.employee.isAdd = false;
      this.employee.isEdit = false;
      this.buttons.add.disabled = false;
      this.buttons.add.processing = false;
      this.buttons.edit.disabled = false;
      this.buttons.edit.processing = false;
      this.buttons.save.disabled = true;
      this.buttons.save.processing = false;
      this.buttons.delete.disabled = false;
      this.buttons.delete.processing = false;
      if (val.index === 0) {
        this.buttons.prev.disabled = true;
        if (this.employee.items.length > 1) this.buttons.next.disabled = false;
        else this.buttons.next.disabled = true;
      } else if (val.index === this.employee.items.length - 1) {
        if (this.employee.items.length > 1) this.buttons.prev.disabled = false;
        else this.buttons.prev.disabled = true;
        this.buttons.next.disabled = true;
      } else {
        this.buttons.prev.disabled = false;
        this.buttons.next.disabled = false;
      }
      if (val.data) {
        this.$store.dispatch("setSpinnerApp", {
          show: true,
        });
        this.employee.table.selected = val.data;
        this.employee.entity = this.generateEntity();
        await this.entityFromSelected(val.data);
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
      }
    },

    async menuBarFunction(val) {
      switch (val.code) {
        case "SAVE":
          this.onSave();
          break;
        case "ADD":
          this.employee.isChangePass = false;
          this.employee.isEdit = false;
          this.employee.isAdd = true;
          this.buttons.edit.disabled = true;
          this.buttons.edit.processing = false;
          this.buttons.save.disabled = false;
          this.buttons.save.processing = false;
          this.employee.table.selected = null;
          this.employee.tree.selected = null;
          this.buttons.delete.disabled = true;
          this.buttons.delete.processing = false;
          this.employee.entity = this.generateEntity();
          this.employee.entity.gender = "1";
          break;
        case "EDIT":
          this.employee.isChangePass = false;
          this.employee.isEdit = true;
          this.employee.isAdd = false;
          this.buttons.add.disabled = false;
          this.buttons.add.processing = false;
          this.buttons.save.disabled = false;
          this.buttons.save.processing = false;
          break;
        case "FILTER":
          break;
        case "RELOAD":
          this.buttons.reload.disabled = true;
          this.buttons.reload.processing = true;
          if (!this.cancel) await this.onFilter();
          this.buttons.reload.processing = false;
          this.buttons.reload.disabled = false;
          break;
        case "DELETE":
          this.confirmDelete.value = this.employee.entity.id;
          this.confirmDelete.message = this.$t(
            "message_confirm_delete_user",
            null,
            {
              name: this.employee.entity.full_name
                ? this.employee.entity.full_name.trim()
                : "",
            }
          );
          this.confirmDelete.show = true;
          break;
        default:
          break;
      }
    },

    onCancelConfirm() {
      this.confirm.show = false;
      this.confirm.message = "";
      this.confirm.callback.method = null;
      this.confirm.callback.params = null;
      if (this.confirm.initValue && this.confirm.initValue.cancel) {
        for (let i = 0; i < this.confirm.initValue.cancel.length; i++) {
          if (this.confirm.initValue.cancel[i].variable) {
            let obj = this[this.confirm.initValue.cancel[i].variable];
            obj[this.confirm.initValue.cancel[i].key] =
              this.confirm.initValue.cancel[i].value;
          } else
            this[this.confirm.initValue.cancel[i].key] =
              this.confirm.initValue.cancel[i].value;
        }
      }
      if (this.confirm.callback.no.method)
        this[this.confirm.callback.no.method](this.confirm.callback.no.params);
    },
    onConfirmed() {
      this.confirm.show = false;
      this.confirm.message = "";
      if (this.confirm.initValue && this.confirm.initValue.confirmed) {
        for (let i = 0; i < this.confirm.initValue.confirmed.length; i++) {
          if (this.confirm.initValue.confirmed[i].variable) {
            let obj = this[this.confirm.initValue.confirmed[i].variable];
            obj[this.confirm.initValue.confirmed[i].key] =
              this.confirm.initValue.confirmed[i].value;
          } else
            this[this.confirm.initValue.confirmed[i].key] =
              this.confirm.initValue.confirmed[i].value;
        }
      }
      if (this.confirm.callback.yes.method)
        this[this.confirm.callback.yes.method](
          this.confirm.callback.yes.params
        );
    },

    onCancelConfirmDelete() {
      this.confirmDelete.show = false;
      this.confirmDelete.message = null;
      this.confirmDelete.value = null;
    },
    async onConfirmedDelete() {
      this.buttons.delete.disabled = true;
      this.buttons.delete.processing = true;
      this.$store.dispatch("setSpinnerApp", {
        show: true,
      });
      try {
        let response = await userFunc.delete(
          this.confirmDelete.value,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.$toast.success({
            title: this.$t("title_message_info"),
            message: this.$t("message_delete_user_success", null, {
              name: this.employee.entity.full_name
                ? this.employee.entity.full_name.trim()
                : "",
            }),
            position: "top right",
          });
          this.onCancelConfirmDelete();
          this.getAllUser();
        } else {
          this.message.push([
            this.$t("message_delete_user_error", null, {
              name: this.employee.entity.full_name
                ? this.employee.entity.full_name.trim()
                : "",
            }),
          ]);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$store.dispatch("setSpinnerApp", {
            show: false,
          });
          await this.refreshToken(this.onConfirmedDelete);
        } else {
          let msg =
            error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push([
            msg
              ? msg
              : this.$t("message_delete_user_error", null, {
                  name: this.employee.entity.name
                    ? this.employee.entity.name.trim()
                    : "",
                }),
          ]);
        }
      }
      this.$store.dispatch("setSpinnerApp", {
        show: false,
      });
      this.buttons.delete.processing = false;
    },
    onSearch(data) {
      this.employee.search.role_id = data.role_id ? data.role_id : null;
      this.employee.search.name = data.name
        ? data.name.trim().replace(/\s\s+/g, " ")
        : null;
      if (data.is_active) {
        switch (data.is_active) {
          case "1":
            this.employee.search.is_active = true;
            break;
          case "0":
            this.employee.search.is_active = false;
            break;
          case "2":
            this.employee.search.is_active = null;
            break;
        }
      } else this.employee.search.is_active = null;

      this.getAllUser();
    },
    /* ---------- End Emit ----------*/
    /* ---------- Put, Post -----------*/
    async onSave() {
      this.message = [];
      var vm = this;
      this.$refs.userObserver.validate().then(async (success) => {
        if (!success) {
          setTimeout(() => {
            Object.keys(vm.$refs.userObserver.errors).forEach(function (key) {
              if (
                vm.$refs.userObserver.errors[key] &&
                vm.$refs.userObserver.errors[key].length
              )
                vm.message.push(vm.$refs.userObserver.errors[key].join(",\n"));
            });
          }, 100);
          return;
        } else {
          let dto = Object.assign(
            {},
            JSON.parse(JSON.stringify(this.employee.entity))
          );
          dto.address = dto.address
            ? dto.address.trim().replace(/\s\s+/g, " ")
            : null;
          dto.email = dto.email
            ? dto.email.trim().replace(/\s\s+/g, " ")
            : null;
          dto.full_name = dto.full_name
            ? dto.full_name.trim().replace(/\s\s+/g, " ")
            : null;

          dto.password = dto.password
            ? dto.password.trim().replace(/\s\s+/g, " ")
            : null;
          dto.phone_number = dto.phone_number
            ? dto.phone_number.trim().replace(/\s\s+/g, " ")
            : null;
          dto.username = dto.username
            ? dto.username.trim().replace(/\s\s+/g, " ")
            : null;
          dto.organization = dto.organization
            ? dto.organization.trim().replace(/\s\s+/g, " ")
            : null;
          if (dto.gender) {
            try {
              dto.gender = parseInt(dto.gender);
            } catch (error) {}
          }
          let response;
          try {
            if (!dto.id) {
              // Add new

              if (dto.password.length < 8) {
                this.message.push(this.$t("message_password_characters_error"));

                return;
              }
              let number = 0,
                alpha = 0;
              for (let i = 0; i < dto.password.length; i++) {
                let characterNum = dto.password.charCodeAt(i);
                if (characterNum === 32) {
                  this.message.push(
                    this.$t("message_password_characters_error")
                  );
                  break;
                }
                if (characterNum >= 48 && characterNum <= 57) {
                  number = number + 1;
                }
                if (characterNum >= 65 && characterNum <= 90) {
                  alpha = alpha + 1;
                }
                if (characterNum >= 97 && characterNum <= 122) {
                  alpha = alpha + 1;
                }
              }
              if (number == 0 || alpha == 0) {
                this.message.push(this.$t("message_password_characters_error"));
              }
              if (this.message.length > 0) {
                return;
              }

              delete dto.id;

              this.$store.dispatch("setSpinnerApp", {
                show: true,
              });

              this.buttons.save.processing = true;
              this.buttons.save.disabled = true;
              this.buttons.add.disabled = true;
              this.buttons.edit.disabled = true;
              this.buttons.reload.disabled = true;

              delete dto.is_active_old;
              delete dto.role_id_old;

              response = await userFunc.create(
                dto,
                this.$store.getters.getAccessToken
              );
            } else {
              // Edit
              if (this.employee.isChangePass) {
                if (!dto.password || dto.password.length < 8) {
                  this.message.push(
                    this.$t("message_password_characters_error")
                  );
                  return;
                }
                let number = 0,
                  alpha = 0;
                for (let i = 0; i < dto.password.length; i++) {
                  let characterNum = dto.password.charCodeAt(i);
                  if (characterNum === 32) {
                    this.message.push(
                      this.$t("message_password_characters_error")
                    );
                    break;
                  }
                  if (characterNum >= 48 && characterNum <= 57) {
                    number = number + 1;
                  }
                  if (characterNum >= 65 && characterNum <= 90) {
                    alpha = alpha + 1;
                  }
                  if (characterNum >= 97 && characterNum <= 122) {
                    alpha = alpha + 1;
                  }
                }
                if (number == 0 || alpha == 0) {
                  this.message.push(
                    this.$t("message_password_characters_error")
                  );
                }
                if (this.message.length > 0) {
                  return;
                }
              } else {
                delete dto.password;
              }
              this.$store.dispatch("setSpinnerApp", {
                show: true,
              });
              this.buttons.save.processing = true;
              this.buttons.save.disabled = true;
              this.buttons.add.disabled = true;
              this.buttons.edit.disabled = true;
              this.buttons.reload.disabled = true;
              let check = false;
              if (dto.is_active != dto.is_active_old) {
                if (dto.is_active) {
                  try {
                    let response = await userFunc.active(
                      dto.id,
                      vm.$store.getters.getAccessToken
                    );
                    if (response && response.status === 200) {
                      if (response.data.success) check = true;
                      else check = false;
                    } else check = false;
                  } catch (error) {
                    if (
                      error &&
                      error.response &&
                      error.response.status === 401
                    ) {
                      this.$store.dispatch("setSpinnerApp", {
                        show: false,
                      });
                      await this.refreshToken(this.onSave);
                    } else {
                      check = false;
                    }
                  }
                } else {
                  try {
                    let response = await userFunc.deactive(
                      dto.id,
                      vm.$store.getters.getAccessToken
                    );
                    if (response && response.status === 200) {
                      if (response.data.success) check = true;
                      else check = false;
                    } else check = false;
                  } catch (error) {
                    if (
                      error &&
                      error.response &&
                      error.response.status === 401
                    ) {
                      this.$store.dispatch("setSpinnerApp", {
                        show: false,
                      });
                      await this.refreshToken(this.onSave);
                    } else {
                      check = false;
                    }
                  }
                }
                if (!check) {
                  this.$store.dispatch("setSpinnerApp", {
                    show: false,
                  });
                  this.buttons.save.processing = false;
                  this.buttons.save.disabled = false;
                  this.buttons.edit.disabled = !this.employee.isEdit;
                  this.buttons.add.disabled = false;
                  this.buttons.reload.disabled = false;
                  this.message.push(
                    this.$t("message_save_user_error", null, {
                      name: dto.full_name ? dto.full_name : "",
                    })
                  );
                  return;
                }
              }

              if (dto.role_id != dto.role_id_old) {
                let checkRole = false;
                try {
                  let response = await userFunc.addRole(
                    dto.id,
                    dto.role_id,
                    vm.$store.getters.getAccessToken
                  );
                  if (response && response.status === 200) {
                    checkRole = true;
                  }
                } catch (error) {
                  if (
                    error &&
                    error.response &&
                    error.response.status === 401
                  ) {
                    this.$store.dispatch("setSpinnerApp", {
                      show: false,
                    });
                    await this.refreshToken(this.onSave);
                  }
                }
                if (!checkRole) {
                  this.$store.dispatch("setSpinnerApp", {
                    show: false,
                  });
                  this.buttons.save.processing = false;
                  this.buttons.save.disabled = false;
                  this.buttons.edit.disabled = !this.employee.isEdit;
                  this.buttons.add.disabled = false;
                  this.buttons.reload.disabled = false;
                  this.message.push(
                    this.$t("message_save_user_error", null, {
                      name: dto.full_name ? dto.full_name : "",
                    })
                  );
                  return;
                }
              }

              delete dto.username;
              delete dto.is_active_old;
              delete dto.role_id_old;
              response = await userFunc.update(
                dto,
                this.$store.getters.getAccessToken
              );
            }
            if (
              response &&
              (response.status === 201 ||
                response.status === 200 ||
                response.status === 204)
            ) {
              this.$toast.success({
                title: this.$t("title_message_info"),
                message:
                  this.employee.isEdit && this.employee.entity.id
                    ? this.$t("message_save_user_success", null, {
                        name: dto.full_name ? dto.full_name : "",
                      })
                    : this.$t("message_add_new_user_success", null, {
                        name: dto.full_name ? dto.full_name : "",
                      }),
                position: "top right",
              });
              this.getAllUser();
              this.employee.isChangePass = false;
              this.buttons.save.disabled = true;
              this.buttons.save.processing = false;
              this.buttons.add.disabled = false;
              this.buttons.add.processing = false;
              this.buttons.edit.disabled = true;
              this.buttons.edit.processing = false;
              this.buttons.delete.disabled = true;
              this.buttons.delete.processing = false;
              this.employee.entity = this.generateEntity();
              this.employee.isAdd = false;
              this.employee.isEdit = false;
            } else {
              this.message.push(
                this.employee.isEdit && this.employee.entity.id
                  ? this.$t("message_save_user_error", null, {
                      name: dto.full_name ? dto.full_name : "",
                    })
                  : this.$t("message_add_new_user_error", null, {
                      name: dto.full_name ? dto.full_name : "",
                    })
              );
            }
          } catch (error) {
            if (error.response) {
              if (error.response.status === 401) {
                this.$store.dispatch("setSpinnerApp", {
                  show: false,
                });
                await this.refreshToken(this.onSave);
              } else {
                this.buttons.save.processing = false;
                this.buttons.save.disabled = false;
                this.buttons.edit.disabled = !this.employee.isEdit;
                this.buttons.add.disabled = false;
                this.buttons.reload.disabled = false;
                let msg =
                  error.response.data && error.response.data.errors
                    ? error.response.data.errors
                    : [];
                if (msg.length === 0) {
                  this.message.push(
                    error.response.data && error.response.data.message
                      ? error.response.data.message
                      : this.employee.isEdit && this.employee.entity.id
                      ? this.$t("message_save_user_error", null, {
                          name: dto.full_name ? dto.full_name : "",
                        })
                      : this.$t("message_add_new_user_error", null, {
                          name: dto.full_name ? dto.full_name : "",
                        })
                  );
                } else this.message = msg;
              }
            }
          }
          this.$store.dispatch("setSpinnerApp", {
            show: false,
          });
        }
      });
    },
    async deactiveUser(id) {
      var vm = this;
      return new Promise(async (resolve, reject) => {
        try {
          let response = await userFunc.deactive(
            id,
            vm.$store.getters.getAccessToken
          );
          if (response && response.status === 200) {
            if (response.data.success) resolve(true);
            else resolve(false);
          } else resolve(false);
        } catch (error) {
          resolve(false);
        }
      });
    },

    async activeUser(id) {
      var vm = this;
      return new Promise(async (resolve, reject) => {
        try {
          let response = await userFunc.active(
            id,
            vm.$store.getters.getAccessToken
          );
          if (response && response.status === 200) {
            if (response && response.status === 200) {
              if (response.data.success) resolve(true);
              else resolve(false);
            }
          } else resolve(false);
        } catch (error) {
          resolve(false);
        }
      });
    },
    async addRole(data) {
      var vm = this;
      return new Promise(async (resolve, reject) => {
        try {
          let response = await userFunc.addRole(
            data.user_id,
            data.role_id,
            vm.$store.getters.getAccessToken
          );
          if (response && response.status === 200) {
            resolve(true);
          }
        } catch (error) {
          resolve(false);
        }
      });
    },
    /* ---------- Put, Post ---------- */
    /* ---------- Api get data ---------- */
    async onFilter() {
      await this.getAllUser();
    },
    async getAllRole() {
      try {
        let response = await roleFunc.getAll(
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.role.items =
            response.data && response.data.content_page
              ? response.data.content_page
              : [];
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.getAllRole, null);
        }
      }
    },
    async getAllOrganisation() {
      try {
        let response = await organisationFunc.getAll(
          {
            create_time_from: null,
            create_time_to: null,
            org_name: null,
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.organisation.items =
            response.data && response.data.content_page
              ? response.data.content_page
              : [];
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.getAllOrganisation, null);
        }
      }
    },
    async getAllUser() {
      this.employee.entity = this.generateEntity();
      this.employee.table.selected = null;
      this.employee.tree.selected = null;
      this.employee.items = [];
      this.employee.page_number = 0;
      this.employee.page_size = 2147483647;
      this.employee.total_page = 0;
      this.employee.total_records = 0;
      this.employee.isAdd = false;
      this.employee.isEdit = false;
      this.buttons.filter.processing = true;
      this.buttons.filter.disabled = true;
      this.buttons.reload.disabled = true;
      this.buttons.reload.processing = true;
      try {
        let response = await userFunc.search(
          {
            page: this.employee.search.page,
            size: this.employee.search.size,
            order: this.employee.search.order,
            sort: this.employee.search.sort,
          },
          {
            is_active: this.employee.search.is_active,
            name: this.employee.search.name
              ? this.employee.search.name.trim().replace(/\s\s+/g, " ")
              : null,
            role_id: this.employee.search.role_id
              ? this.employee.search.role_id
              : null,
          },
          this.$store.getters.getAccessToken
        );
        if (response) {
          if (response.status === 200) {
            this.employee.items =
              response.data && response.data.content_page
                ? response.data.content_page
                : [];
            this.employee.page_number =
              response.data && response.data.page_number
                ? response.data.page_number
                : 0;
            this.employee.page_size =
              response.data && response.data.page_size
                ? response.data.page_size
                : 2147483647;
            this.employee.total_page =
              response.data && response.data.total_page
                ? response.data.total_page
                : 0;
            this.employee.total_records =
              response.data && response.data.total_records
                ? response.data.total_records
                : 0;
            this.employee.items.sort(function (a, b) {
              if (a.full_name < b.full_name) return -1;
              else if (a.full_name > b.full_name) return 1;
              return 0;
            });
          }
          if (this.employee.items.length > 0) this.employee.first = 0;
        }

        this.buttons.reload.disabled = false;
        this.buttons.reload.processing = false;
        this.buttons.filter.disabled = false;
        this.buttons.filter.processing = false;
      } catch (error) {
        this.buttons.reload.disabled = false;
        this.buttons.reload.processing = false;
        this.buttons.filter.disabled = false;
        this.buttons.filter.processing = false;
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.getAllUser, null);
        }
      }
      this.buttons.reload.disabled = false;
      this.buttons.reload.processing = false;
      this.buttons.filter.disabled = false;
      this.buttons.filter.processing = false;
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
};
</script>

<style lang="scss" scoped>
.user-content {
  padding: 0.5rem;
  padding-top: 0;
  .user-content__filter {
    border-radius: 0.5rem;
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .user-content__datatable {
    margin-top: 0.5rem;
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .user-content__tree {
    margin-left: 0.25rem;
    .accordion {
      border-radius: 0.5rem;
    }
  }

  .user-content__structure {
    position: relative;
    margin-top: 0.5rem;

    .actions {
      position: absolute;
      right: 2.5rem;
      top: 0.4rem;
      button {
        border-radius: 50% !important;
        background: #009ef7;
        color: white;
        margin-right: 0.25rem;
        width: 1.6125rem;
        height: 1.6125rem;
        padding: 0.25rem;
        &:last-child {
          margin-right: 0;
        }
        &:disabled {
          background: #f4f4f4;
          color: #009ef7;
        }
        .pi {
          font-size: 0.75rem !important;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.user-content__structure {
  .actions {
    button {
      .pi {
        font-size: 0.75rem !important;
      }
    }
  }
}
</style>
