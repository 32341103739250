<template>
  <main-layout
    :isToolbar="false"
    :isBreadcrumb="true"
    :breadcrumbitems="[
      { label: $t('menu_data_management') },
      { label: $t('menu_data_list') },
    ]"
    :propButtonsState="{
      save: {
        visible: false,
      },
      add: {
        visible: false,
      },
      edit: {
        visible: false,
      },
      reload: buttons.reload,
      print: {
        visible: false,
      },
    }"
    :propTitle="$t('menu_data_list')"
    @emitFunction="menuHeaderClick"
  >
    <div class="satellite-image__list">
      <div class="satellite-image__dialog">
        <DialogUploadMetadata
          v-if="dialogUploadMetadata.show"
          :prop-title="dialogUploadMetadata.title"
          :prop-show="dialogUploadMetadata.show"
          :prop-style="{ width: dialogUploadMetadata.width }"
          :propEntity="entity"
          :prop-provider="provider.tree"
          :prop-processing-level="processingLevel.items"
          :prop-beam-mode="beamMode.items"
          :prop-direction="direction.items"
          :prop-pol-layer="polLayer.items"
          :prop-projects="project.items"
          @clear="clearEntity"
          @close="dialogUploadMetadata.show = false"
          @onUpload="onUpload"
          @metaFileChange="metaFileChange"
          @message="message = $event"
          @getAllProcessingLevel="getAllProcessingLevel"
          @getAllBeamMode="getAllBeamMode"
          @getAllDirection="getAllDirection"
          @getAllPolLayer="getAllPolLayer"
          @getAllProject="getAllProject"
        />
        <DialogMetadata
          v-if="dialog.metadata.show"
          :prop-style="{ width: dialog.metadata.width }"
          :prop-show="dialog.metadata.show"
          :prop-title="dialog.metadata.title"
          :prop-item="dialog.metadata.value"
          @close="
            dialog.metadata.show = false;
            dialog.metadata.value = null;
          "
          @initSuccessPreviewImange="initSuccessPreviewImange"
        />

        <DialogShare
          v-if="dialog.share.show"
          :prop-style="{ width: dialog.share.width }"
          :prop-show="dialog.share.show"
          :prop-title="dialog.share.title"
          :prop-items="dialog.share.value"
          :propUsers="user.items.filter((x) => x.username != username)"
          :propButtonShare="buttons.share"
          @close="
            dialog.share.show = false;
            dialog.share.value = null;
          "
          @onShare="onShare"
        />
        <DialogInfomation
          v-if="dialog.information.show"
          :prop-style="{ width: dialog.information.width }"
          :prop-show="dialog.information.show"
          :prop-info="{
            image_name: dialog.information.value.image.name,
            source_id: dialog.information.value.image.scene_id
              ? dialog.information.value.image.scene_id
              : dialog.information.value.image.metadata
              ? dialog.information.value.image.metadata.source_id
              : null,
            created_by: dialog.information.value.image.created_by,
            created_at: dialog.information.value.image.created_at,
          }"
          :prop-search="dialog.information.value.search"
          :prop-down-history="dialog.information.value.down_history"
          :prop-loading="dialog.information.value.loading"
          @searchToChange="searchToChange"
          @searchFromChange="searchFromChange"
          @close="
            dialog.information.show = false;
            dialog.information.value = null;
          "
          @message="message = $event"
        />
        <DialogUpdateMetadata
          v-if="dialog.updateMetadata.show"
          :prop-style="{ width: dialog.updateMetadata.width }"
          :prop-show="dialog.updateMetadata.show"
          :prop-title="dialog.updateMetadata.title"
          :prop-item="dialog.updateMetadata.value"
          :prop-provider="provider.tree"
          :prop-processing-level="processingLevel.items"
          :prop-beam-mode="beamMode.items"
          :prop-direction="direction.items"
          :prop-pol-layer="polLayer.items"
          @close="
            dialog.updateMetadata.show = false;
            dialog.updateMetadata.value = null;
          "
          @getAllProcessingLevel="getAllProcessingLevel"
          @getAllBeamMode="getAllBeamMode"
          @getAllDirection="getAllDirection"
          @getAllPolLayer="getAllPolLayer"
          @confirmMetadata="handleUpdateMetadata"
          @message="message = $event"
        />
        <DialogConfirmation
          v-if="dlgConfirmDelete.show"
          :prop-show="dlgConfirmDelete.show"
          :prop-title="dlgConfirmDelete.title"
          :prop-icon="dlgConfirmDelete.icon"
          :prop-message="dlgConfirmDelete.message"
          :textNo="$t('button_text_close')"
          :textYes="$t('button_text_delete')"
          @cancel="onCancelConfirmDelete"
          @confirmed="onConfirmedDelete"
        />
        <DialogMessage
          v-if="message.length > 0"
          :propTitle="$t('title_message_info')"
          :prop-show="message.length > 0"
          :prop-message="message"
          @close="message = []"
        />
      </div>
      <div class="satellite-image__button-upmetadata">
        <button class="btn btn-geostore2" @click="showDialogUpMetadata">
          <i class="fas fa-plus"></i>&nbsp;Upload metadata
        </button>
      </div>
      <div class="satellite-image__search" id="elementSearch">
        <list-search
          :propProvider="provider.tree"
          @onSearch="onSearch"
          @message="message = $event"
          @accordionHeaderClick="accordionHeaderClick"
        ></list-search>
      </div>
      <div class="satellite-image__datatable">
        <list-table
          :propItems="image.items"
          :propTotal="image.total_records"
          :prop-loading="image.loading"
          :prop-table="image.table"
          @showDialogInfo="showDialogInfo"
          @showDialogMetadata="showDialogMetadata"
          @showDialogShare="showDialogShare"
          @downloadImage="downloadImage"
          @showDialogUpdateMetadata="showDialogUpdateMetadata"
          @updateIsPublicImage="updateIsPublicImage"
          @nextPage="nextPage"
          @showDialogQuestionDelete="showDialogQuestionDelete"
        >
        </list-table>
      </div>
    </div>
  </main-layout>
</template>
<script>
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}
const _crypto = _interopRequireDefault(require("crypto"));

const _http = _interopRequireDefault(require("http"));

const _helpers = require("minio/dist/main/helpers");
const _signing = require("minio/dist/main/signing.js");
import MainLayout from "@/components/pages/layouts/admin/Main";
import ListTable from "./list/Table";
import ListSearch from "./list/Search";
import DialogUploadMetadata from "./UploadMetadata";
import DialogMetadata from "@/components/commons/metadata/Metadata";
import DialogMessage from "@/components/commons/dialog/MessageBox";
import DialogShare from "./dialog/DialogShare";
import DialogInfomation from "./dialog/DialogInfomation";
import DialogUpdateMetadata from "./dialog/DialogUpdateMetadata";
import DialogConfirmation from "@/components/commons/dialog/Confirmation";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import imageFunc from "@/utils/functions/image";
import imageSharingFunc from "@/utils/functions/imagesharing";
import userFunc from "@/utils/functions/user";
import metaKeyFunc from "@/utils/functions/metakey";
import meControllerFunc from "@/utils/functions/mecontroller";
import fileFunc from "@/utils/functions/file";
import projectFunc from "@/utils/functions/project";
import {
  change_alias,
  string_all_trim,
  getFileExtension1,
  getFileExtension2,
  getFileExtension3,
} from "@/utils/commons/common";
import { sha256, sha224 } from "js-sha256";
import logFunc from "@/utils/functions/log";
import moment from "moment";
import axios from "axios";
import { getMimeType } from "@/utils/commons/mimetypes";
import { enpointPreviewUrl, minioPrefix } from "@/utils/commons/environment";
import { formatUnixDateTime2String } from "@/utils/commons/common";
import CryptoJS from "crypto-js";
export default {
  components: {
    MainLayout,
    ListTable,
    ListSearch,
    DialogUploadMetadata,
    DialogMetadata,
    DialogShare,
    DialogMessage,
    DialogInfomation,
    DialogUpdateMetadata,
    DialogConfirmation,
  },
  data() {
    return {
      s3Client: null,
      information: [],
      entity: {
        area: null,
        area_unit: null,
        band: null,
        cloud_coverage: null,
        cloud_coverage_unit: null,
        description: null,
        google_earth_path: null,
        image_type: null,
        imaging_date: null,
        imaging_date_timestamp: null,
        incidence_angle: null,
        metadata: {},
        extend: {},
        name: null,
        resolution: null,
        snow_coverage: null,
        snow_coverage_unit: null,
        processing_level: null,
        processing_level_id: null,
        mode: null,
        beam_mode: null,
        direction: null,
        pol_layer: null,
        satellite_type: null,
        satellite_id: null,
        bounding_box: [],
        provider: null,
        file_path: null,
        icon_path: {},
        icon_path_id: null,
        icon_src: null,
        icon_file: null,
        preview: {},
        preview_images: [],
        preview_path: {},
        preview_path_id: null,
        preview_src: null,
        preview_file: null,
        price: 0,
        price_unit: null,
        processing_level_name: null,
        provider_name: null,
        satellite_name: null,
        size: 0,
        project_ids: [],
        source_id: null,
        is_success: false,
        scene_id: null,
      },
      minioServer: {
        data: null,
        metakey: null,
      },
      provider: {
        items: [],
        tree: [],
      },
      processingLevel: {
        items: [],
      },
      beamMode: {
        items: [],
      },
      direction: {
        items: [],
      },
      polLayer: {
        items: [],
      },
      user: {
        items: [],
      },
      project: {
        items: [],
      },
      image: {
        items: [],
        is_bottom: false,
        page_size: 0,
        page_number: 0,
        total_page: 0,
        total_records: 0,
        loading: false,
        search: {
          text: null,
          satellite_ids: [],
          page: 0,
          size: 10,
        },
        table: {
          select: null,
          height: 400,
        },
      },

      dlgConfirmDelete: {
        message: null,
        show: false,
        title: this.$t("title_message_confirm"),
        icon: "fas fa-question",
        value: false,
        width: "540px",
      },
      dialogUploadMetadata: {
        show: false,
        data: null,
        title: "Upload Metadata",
        width: "540px",
      },
      message: [],
      dialog: {
        metadata: {
          show: false,
          title: "Metadata",
          value: null,
          width: "540px",
        },
        share: {
          show: false,
          title: this.$t("label_share"),
          value: null,
          width: "540px",
        },
        information: {
          show: false,
          title: this.$t("title_image_info"),
          value: null,
          width: "540px",
        },
        updateMetadata: {
          show: false,
          title: "Update metadata",
          value: null,
          width: "540px",
        },
      },
      buttons: {
        reload: {
          disabled: false,
          processing: false,
          visible: true,
        },
        share: {
          disabled: false,
          processing: false,
        },
      },
    };
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    tableHeight() {
      return this.documentHeight - 56 - 48 - 104 - 38;
    },
    username() {
      return this.$store.getters.getUserName;
    },
  },
  created() {
    this.image.search.size = Math.ceil(this.tableHeight / 45) + 5;
    this.getAllUser();
    this.getAllProvider();
    this.getImages();
  },
  mounted() {
    var vm = this;
    if (vm.documentWidth < 576) {
      this.dialogUploadMetadata.width = this.documentWidth + "px";
      this.dialog.metadata.width = this.documentWidth + "px";
      this.dialog.share.width = this.documentWidth + "px";
      this.dialog.information.width = this.documentWidth + "px";
      this.dialog.updateMetadata.width = this.documentWidth + "px";
    } else if (vm.documentWidth >= 576 && vm.documentWidth < 768) {
      this.dialog.metadata.width = "540px";
      this.dialogUploadMetadata.width = "540px";
      this.dialog.share.width = "540px";
      this.dialog.information.width = "540px";
      this.dialog.updateMetadata.width = "540px";
    } else if (vm.documentWidth >= 768 && vm.documentWidth < 992) {
      this.dialog.metadata.width = "720px";
      this.dialogUploadMetadata.width = "720px";
      this.dialog.share.width = "720px";
      this.dialog.information.width = "720px";
      this.dialog.updateMetadata.width = "720px";
    } else {
      this.dialogUploadMetadata.width = "960px";
      this.dialog.metadata.width = "960px";
      this.dialog.share.width = "720px";
      this.dialog.information.width = "720px";
      this.dialog.updateMetadata.width = "960px";
    }
    setTimeout(() => {
      this.image.table.height = this.calcTableHeight();
    }, 100);
  },
  methods: {
    accordionHeaderClick() {
      setTimeout(() => {
        this.image.table.height = this.calcTableHeight();
      }, 100);
    },
    calcTableHeight() {
      let element = document.getElementById("elementSearch");
      return (
        this.documentHeight -
        4 * 0.5 * 16 -
        56 -
        36 -
        (element ? element.offsetHeight : 0) -
        24 -
        2 * 0.5 * 16
      );
    },
    generateEntity() {
      return {
        area: null,
        area_unit: null,
        band: null,
        cloud_coverage: null,
        cloud_coverage_unit: null,
        description: null,
        google_earth_path: null,
        image_type: null,
        imaging_date: null,
        imaging_date_timestamp: null,
        incidence_angle: null,
        metadata: {},
        extend: {},
        name: null,
        resolution: null,
        snow_coverage: null,
        snow_coverage_unit: null,
        processing_level: null,
        processing_level_id: null,
        mode: null,
        beam_mode: null,
        direction: null,
        pol_layer: null,
        satellite_type: null,
        satellite_id: null,
        bounding_box: [],
        provider: null,
        file_path: null,
        icon_path: {},
        icon_path_id: null,
        icon_src: null,
        icon_file: null,
        preview: {},
        preview_images: [],
        preview_path: {},
        preview_path_id: null,
        preview_src: null,
        preview_file: null,
        price: 0,
        price_unit: null,
        processing_level_name: null,
        provider_name: null,
        satellite_name: null,
        size: 0,
        project_ids: [],
        source_id: null,
        is_success: false,
        scene_id: null,
      };
    },

    generateMinioData() {
      return {
        end_point: {
          id: "end_point",
          label: "Endpoint",
          value: null,
        },
        port: {
          id: "port",
          label: "Port",
          value: null,
        },
        use_ssl: {
          id: "use_ssl",
          label: "Use ssl",
          value: null,
        },
        access_key: {
          id: "access_key",
          label: "Access key",
          value: null,
        },
        secret_key: {
          id: "secret_key",
          label: "secret key",
          value: null,
        },
        part_size: {
          id: "part_size",
          label: "Part size",
          value: null,
        },
      };
    },
    initSuccessPreviewImange(data) {
      let index = this.image.items.findIndex((x) => x.id === data.id);
      if (index >= 0) {
        this.image.items[index].preview_src = data.val;
      }
    },
    showDialogQuestionDelete(val) {
      this.dlgConfirmDelete.value = val;
      this.dlgConfirmDelete.message = this.$t(
        "message_confirm_delete_image",
        null,
        {
          name: val.name ? val.name.trim() : val.scene_id,
        }
      );
      this.dlgConfirmDelete.show = true;
    },
    onCancelConfirmDelete() {
      this.dlgConfirmDelete.show = false;
      this.dlgConfirmDelete.message = "";
      this.dlgConfirmDelete.value = null;
    },
    async onConfirmedDelete() {
      this.$store.dispatch("setSpinnerApp", {
        show: true,
      });
      try {
        let response = await imageFunc.delete(
          this.dlgConfirmDelete.value.id,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data && response.data.success) {
            this.image.search.page = 0;
            this.image.is_bottom = false;
            this.image.items = [];
            this.getImages();
            this.$toast.success({
              title: this.$t("title_message_info"),
              message: this.$t("message_delete_project_status_success", null, {
                name: this.dlgConfirmDelete.value.name
                  ? this.dlgConfirmDelete.value.name.trim()
                  : this.dlgConfirmDelete.value.scene_id,
              }),
              position: "top right",
            });
            this.onCancelConfirmDelete();
          } else {
            this.message.push([
              this.$t("message_delete_project_status_error", null, {
                name: this.dlgConfirmDelete.value.name
                  ? this.dlgConfirmDelete.value.name.trim()
                  : this.dlgConfirmDelete.value.scene_id,
              }),
            ]);
          }
        } else {
          this.message.push([
            this.$t("message_delete_project_status_error", null, {
              name: this.dlgConfirmDelete.value.name
                ? this.dlgConfirmDelete.value.name.trim()
                : this.dlgConfirmDelete.value.scene_id,
            }),
          ]);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$store.dispatch("setSpinnerApp", {
            show: false,
          });
          await this.refreshToken(this.onConfirmedDelete);
        } else {
          let msg =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push([
            msg
              ? msg
              : this.$t("message_delete_project_status_error", null, {
                  name: this.dlgConfirmDelete.value.name
                    ? this.dlgConfirmDelete.value.name.trim()
                    : this.dlgConfirmDelete.value.scene_id,
                }),
            ,
          ]);
        }
      }
      this.$store.dispatch("setSpinnerApp", {
        show: false,
      });
    },
    async handleUpdateMetadata(entity) {
      let dto = Object.assign({}, entity);
      if (dto.imaging_date_timestamp) {
        try {
          dto.imaging_date = new Date(dto.imaging_date_timestamp);
          dto.metadata.imaging_date = dto.imaging_date;
        } catch (error) {}
      }
      if (dto.processing_level_name) {
        dto.processing_level = this.processingLevel.items.find(
          (x) => x.name === dto.processing_level_name
        );
        if (
          dto.processing_level &&
          Object.keys(dto.processing_level).length > 0
        ) {
          dto.processing_level_id = dto.processing_level.id;
        }
      }
      if (dto.icon_path_id && dto.preview_images) {
        dto.icon_path = dto.preview_images.find(
          (x) => x.path_image === dto.icon_path_id && x.type === "ICON"
        );
        if (dto.icon_path && dto.icon_path.src) delete dto.icon_path.src;
      }
      if (!dto.icon_path || Object.keys(dto.icon_path).length === 0) {
        dto.icon_path = {
          ext: null,
          path_image: dto.icon_path_id ? dto.icon_path_id : null,
          size: null,
          type: null,
        };
      }

      if (dto.preview_path_id && dto.preview_images) {
        dto.preview_path = dto.preview_images.find(
          (x) => x.path_image === dto.preview_path_id && x.type === "PREVIEW"
        );
        if (dto.preview_path && dto.preview_path.src)
          delete dto.preview_path.src;
      }
      if (!dto.preview_path || Object.keys(dto.preview_path).length === 0) {
        dto.preview_path = {
          ext: null,
          path_image: dto.preview_path_id ? dto.preview_path_id : null,
          size: null,
          type: null,
        };
      }

      if (dto.satellite_id) {
        if (dto.satellite_type && Object.keys(dto.satellite_type).length > 0) {
          if (dto.satellite_type.id != dto.satellite_id) {
            for (let i = 0; i < this.provider.items.length; i++) {
              if (this.provider.items[i].sub_meta_key) {
                dto.satellite_type = this.provider.items[i].sub_meta_key.find(
                  (x) => x.id === dto.satellite_id
                );
                if (
                  dto.satellite_type &&
                  Object.keys(dto.satellite_type).length > 0
                ) {
                  dto.provider = this.provider.items[i].id;
                  dto.provider_name = this.provider.items[i].name;
                  dto.satellite_name = dto.satellite_type.name;
                  break;
                }
              }
            }
          }
        } else {
          for (let i = 0; i < this.provider.items.length; i++) {
            if (this.provider.items[i].sub_meta_key) {
              dto.satellite_type = this.provider.items[i].sub_meta_key.find(
                (x) => x.id === dto.satellite_id
              );
              if (
                dto.satellite_type &&
                Object.keys(dto.satellite_type).length > 0
              ) {
                dto.provider = this.provider.items[i].id;
                dto.provider_name = this.provider.items[i].name;
                dto.satellite_name = dto.satellite_type.name;
                break;
              }
            }
          }
        }
      }
      if (!dto.metadata) dto.metadata = {};
      dto.metadata.imaging_date = dto.imaging_date;
      dto.metadata.resolution = dto.resolution;
      dto.metadata.incidence_angle = dto.incidence_angle;
      dto.metadata.band = dto.band;
      dto.metadata.area = dto.area;
      dto.metadata.area_unit = dto.area_unit;
      dto.metadata.snow_coverage = dto.snow_coverage;
      dto.metadata.snow_coverage_unit = dto.snow_coverage_unit;
      dto.metadata.cloud_coverage = dto.cloud_coverage;
      dto.metadata.cloud_coverage_unit = dto.cloud_coverage_unit;
      dto.mode = dto.beam_mode;
      dto.metadata.beam_mode = dto.beam_mode;
      dto.metadata.mode = dto.beam_mode;
      dto.metadata.direction = dto.direction;
      dto.metadata.pol_layer = dto.pol_layer;
      dto.extend = dto.metadata;

      let previewPath, iconPath, previewUrl, iconUrl;
      let mimeTypePreview;
      let mimeTypeIcon;
      if (dto.preview_file) {
        mimeTypePreview = getMimeType(
          dto.preview_file.name
            .split(".")
            .pop()
            .toLowerCase()
        );
        previewPath =
          "previews/" +
          CryptoJS.MD5(
            dto.preview_file.name +
              Math.floor(Math.random() * 9999) +
              new Date().getTime()
          ).toString();
        try {
          let responsePreview = await imageFunc.createLinkPreview(
            mimeTypePreview ? mimeTypePreview.type : "image/jpg",
            previewPath,
            this.$store.getters.getAccessToken
          );
          if (responsePreview && responsePreview.status === 200) {
            previewUrl = responsePreview.data ? responsePreview.data.url : null;
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            this.refreshToken(this.handleUpdateMetadata, entity);
            return;
          }
        }
      }
      if (dto.icon_file) {
        mimeTypeIcon = getMimeType(
          dto.icon_file.name
            .split(".")
            .pop()
            .toLowerCase()
        );
        iconPath =
          "previews/" +
          CryptoJS.MD5(
            dto.icon_file.name +
              Math.floor(Math.random() * 9999) +
              new Date().getTime()
          ).toString();
        try {
          let responseIcon = await imageFunc.createLinkPreview(
            mimeTypeIcon ? mimeTypeIcon.type : "image/jpg",
            iconPath,
            this.$store.getters.getAccessToken
          );
          if (responseIcon && responseIcon.status === 200) {
            iconUrl = responseIcon.data ? responseIcon.data.url : null;
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            this.refreshToken(this.handleUpdateMetadata, entity);
            return;
          }
        }
      }
      if (previewUrl) {
        try {
          let response = await imageFunc.uploadFilePresignedUrl(
            previewUrl,
            dto.preview_file,
            mimeTypePreview ? mimeTypePreview.type : "image/jpg"
          );
          if (response && response.status === 200) {
            dto.preview_path = previewPath;
            dto.preview.preview_path = previewPath;
            dto.preview.preview_images.push({
              type: "PREVIEW",
              path: previewPath,
              size: dto.preview_file.size,
              ext: dto.preview_file.name
                .split(".")
                .pop()
                .toLowerCase(),
            });
          }
        } catch (error) {}
      }
      if (iconUrl) {
        try {
          let response = await imageFunc.uploadFilePresignedUrl(
            iconUrl,
            dto.icon_file,
            mimeTypeIcon ? mimeTypeIcon.type : "image/jpg"
          );
          if (response && response.status === 200) {
            dto.icon_path = iconPath;
            dto.preview.icon_path = iconPath;
            dto.preview.preview_images.push({
              type: "ICON",
              path: iconPath,
              size: dto.icon_file.size,
              ext: dto.icon_file.name
                .split(".")
                .pop()
                .toLowerCase(),
            });
          }
        } catch (error) {}
      }
      if (dto.preview_file) delete dto.preview_file;
      if (dto.preview_src) delete dto.preview_src;
      if (dto.icon_file) delete dto.icon_file;
      if (dto.icon_src) delete dto.icon_src;

      this.updateMetadata2DB(dto);
    },

    async updateMetadata2DB(entity) {
      this.message = [];
      if (!entity.name || !entity.name.trim()) {
        this.message.push(this.$t("message_image_name_required"));
        return;
      }
      if (!entity.satellite_name || !entity.satellite_name.trim()) {
        this.message.push(this.$t("message_satellite_required"));
      }
      if (this.message.length > 0) {
        return;
      }
      if (!entity.provider) delete entity.provider;
      if (entity.bounding_box != undefined) delete entity.bounding_box;
      try {
        let response = await imageFunc.update(
          entity,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.onSearch({
            text: this.image.search.text ? this.image.search.text.trim() : null,
            satellite_ids: this.image.search.satellite_ids,
          });
          this.dialog.updateMetadata.show = false;
          this.dialog.updateMetadata.value = null;
          this.$toast.success({
            title: this.$t("title_message_info"),
            message: this.$t("message_update_metadata_success", null, {
              name: entity.name,
            }),
            position: "top right",
          });
        } else {
          this.message.push(
            this.$t("message_update_metadata_error", null, {
              name: entity.name,
            })
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 401)
          this.refreshToken(this.updateMetadata2DB, entity);
        else {
          let msg =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push(
            msg
              ? msg
              : this.$t("message_update_metadata_error", null, {
                  name: entity.name,
                })
          );
        }
      }
    },
    /* ----------------- Emit ----------------*/
    showDialogUpMetadata() {
      this.emitFunction({
        code: "UPLOAD_METADATA",
      });
    },
    assignData(data) {
      this.previews = [];
      this.icons = [];
      if (data && Object.keys(data).length > 0) {
        this.entity.id = data.id;
        this.entity.scene_id = data.scene_id ? data.scene_id : null;
        this.entity.preview_images = data.preview_images;
        if (data.preview_images && data.preview_images.length > 0) {
          this.previews = data.preview_images.filter(
            (x) => x.type === "PREVIEW"
          );
          this.icons = data.preview_images.filter((x) => x.type === "ICON");
          let googleEarths = data.preview_images.filter(
            (x) => x.type === "GOOGLE_EARTH"
          );
          if (googleEarths.length > 0)
            this.entity.google_earth_path = googleEarths[0].path_image;
        }
        this.entity.area =
          data.area != null && data.area != undefined ? data.area : null;
        this.entity.area_unit = data.area_unit ? data.area_unit.trim() : null;
        this.entity.band =
          data.band != null && data.band != undefined ? data.band : null;
        this.entity.cloud_coverage =
          data.cloud_coverage != null && data.cloud_coverage != undefined
            ? data.cloud_coverage
            : null;
        this.entity.cloud_coverage_unit = data.cloud_coverage_unit
          ? data.cloud_coverage_unit.trim()
          : null;

        this.entity.description = data.description
          ? data.description.trim()
          : null;
        this.entity.file_path = data.file_path ? data.file_path.trim() : null;

        this.entity.imaging_date = data.imaging_date ? data.imaging_date : null;
        if (this.entity.imaging_date) {
          try {
            this.entity.imaging_date_timestamp = new Date(
              this.entity.imaging_date
            ).getTime();
          } catch (error) {}
        }
        if (!this.entity.imaging_date_timestamp) {
          try {
            this.entity.imaging_date_timestamp = new Date(
              this.entity.info.imaging_date
            ).getTime();
          } catch (error) {}
        }
        this.entity.incidence_angle =
          data.incidence_angle != null && data.incidence_angle != undefined
            ? data.incidence_angle
            : null;
        this.entity.metadata = data.metadata ? data.metadata : {};

        this.entity.mode = data.beam_mode ? data.beam_mode.trim() : null;
        this.entity.beam_mode = data.beam_mode ? data.beam_mode.trim() : null;
        this.entity.name = data.name ? data.name.trim() : null;
        this.entity.pol_layer = data.pol_layer ? data.pol_layer.trim() : null;
        this.entity.price =
          data.price != null && data.price != undefined ? data.price : null;
        this.entity.price_unit = data.price_unit
          ? data.price_unit.trim()
          : null;

        this.entity.processing_level_id = data.processing_level_id
          ? data.processing_level_id.trim()
          : null;
        if (this.entity.processing_level_id) {
          this.entity.processing_level = this.processingLevel.items.find(
            (x) => x.id === this.entity.processing_level_id
          );
          if (
            this.entity.processing_level &&
            Object.keys(this.entity.processing_level).length > 0
          ) {
            this.entity.processing_level_name = this.entity.processing_level.value;
          }
        }

        this.entity.provider_id = data.provider_id
          ? data.provider_id.trim()
          : null;
        this.entity.satellite_id = data.satellite_id
          ? data.satellite_id.trim()
          : null;

        this.entity.resolution =
          data.resolution != undefined && data.resolution != null
            ? data.resolution
            : null;
        this.entity.satellite_type = data.satellite_type
          ? data.satellite_type
          : null;

        if (
          this.entity.satellite_type &&
          Object.keys(this.entity.satellite_type).length > 0
        ) {
          this.entity.satellite_id = this.entity.satellite_type.id;
          this.entity.satellite_name = this.entity.satellite_type.name;
        }
        this.entity.size =
          data.size != undefined && data.size != null ? data.size : null;

        this.entity.snow_coverage =
          data.snow_coverage != undefined && data.snow_coverage != null
            ? data.snow_coverage
            : null;
        this.entity.snow_coverage_unit = data.snow_coverage_unit
          ? data.snow_coverage_unit.trim()
          : null;

        if (this.previews && this.previews.length > 0) {
          this.entity.preview_path = this.previews.find(
            (x) => x.path_image === data.preview_path
          );
        }
        if (
          !this.entity.preview_path ||
          Object.keys(this.entity.preview_path).length === 0
        ) {
          this.entity.preview_path = {
            ext: null,
            path_image: data.preview_path ? data.preview_path : null,
            size: null,
            type: null,
          };
        }

        if (this.icons && this.icons.length > 0) {
          this.entity.icon_path = this.icons.find(
            (x) => x.path_image === data.icon_path
          );
        }
        if (
          !this.entity.icon_path ||
          Object.keys(this.entity.icon_path).length === 0
        ) {
          this.entity.icon_path = {
            ext: null,
            path_image: data.icon_path ? data.icon_path : null,
            size: null,
            type: null,
          };
        }
        this.entity.icon_path_id = data.icon_path;
        this.entity.preview_path_id = data.preview_path;

        if (data.image_type)
          this.entity.image_type =
            data.image_type && data.image_type.value
              ? data.image_type.value
              : null;
        if (data.metadata && Object.keys(data.metadata).length > 0) {
          this.entity.source_id = data.metadata.source_id
            ? data.metadata.source_id.trim()
            : data.metadata.product_id;
          if (this.entity.area === null || this.entity.area === undefined) {
            this.entity.area = data.metadata.area;
          }
          if (
            this.entity.area_unit === null ||
            this.entity.area_unit === undefined
          ) {
            this.entity.area_unit = data.metadata.area_unit;
          }
          if (this.entity.band === null || this.entity.band === undefined) {
            this.entity.band = data.metadata.band;
          }

          if (
            this.entity.cloud_coverage === null ||
            this.entity.cloud_coverage === undefined
          ) {
            this.entity.cloud_coverage = data.metadata.cloud_coverage;
          }
          if (
            this.entity.cloud_coverage_unit === null ||
            this.entity.cloud_coverage_unit === undefined
          ) {
            this.entity.cloud_coverage_unit = data.metadata.cloud_coverage_unit;
          }

          if (
            this.entity.snow_coverage === null ||
            this.entity.snow_coverage === undefined
          ) {
            this.entity.snow_coverage = data.metadata.snow_coverage;
          }
          if (
            this.entity.snow_coverage_unit === null ||
            this.entity.snow_coverage_unit === undefined
          ) {
            this.entity.snow_coverage_unit = data.metadata.snow_coverage_unit;
          }

          if (
            this.entity.imaging_date === null ||
            this.entity.imaging_date === undefined
          ) {
            this.entity.imaging_date = data.metadata.imaging_date;
            if (this.entity.imaging_date) {
              try {
                this.entity.imaging_date_timestamp = new Date(
                  this.entity.imaging_date
                ).getTime();
              } catch (error) {}
            }
          }

          if (this.entity.mode === null || this.entity.mode === undefined) {
            this.entity.mode = data.metadata.beam_mode;
            this.entity.beam_mode = data.metadata.beam_mode;
          }

          if (
            this.entity.incidence_angle === null ||
            this.entity.incidence_angle === undefined
          ) {
            this.entity.incidence_angle = data.metadata.incidence_angle;
          }
          if (
            this.entity.pol_layer === null ||
            this.entity.pol_layer === undefined
          ) {
            this.entity.pol_layer = data.metadata.pol_layer;
          }
          if (
            this.entity.direction === null ||
            this.entity.direction === undefined
          ) {
            this.entity.direction = data.metadata.direction;
          }

          if (
            this.entity.resolution === null ||
            this.entity.resolution === undefined
          ) {
            this.entity.resolution = data.metadata.resolution;
          }

          if (data.metadata.bounding_box) {
            let coors = data.metadata.bounding_box;
            if (!this.entity.bounding_box) this.entity.bounding_box = [];
            for (let i = 0; i < coors.length; i++) {
              this.entity.bounding_box.push([
                coors[i].longitude,
                coors[i].latitude,
              ]);
            }
            if (coors.length === 4) {
              this.entity.bounding_box.push([
                coors[0].longitude,
                coors[0].latitude,
              ]);
            }
          }
        }
        this.entity.cloud_coverage =
          this.entity.cloud_coverage != null &&
          this.entity.cloud_coverage != undefined &&
          (this.entity.cloud_coverage + "").trim() != "-1"
            ? this.entity.cloud_coverage
            : null;
        this.entity.snow_coverage =
          this.entity.snow_coverage != null &&
          this.entity.snow_coverage != undefined &&
          (this.entity.snow_coverage + "").trim() != "-1"
            ? this.entity.snow_coverage
            : null;
      }
    },
    async getDataFromXml(file) {
      let icon_file = this.entity.icon_file;
      let icon_src = this.entity.icon_src;
      let preview_file = this.entity.preview_file;
      let preview_src = this.entity.preview_src;

      this.entity = this.generateEntity();
      this.entity.icon_file = icon_file;
      this.entity.icon_src = icon_src;
      this.entity.preview_file = preview_file;
      this.entity.preview_src = preview_src;
      try {
        let formData = new FormData();
        formData.append("file", file);
        let response = await fileFunc.dataFromXml(
          formData,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data && response.data.success) {
            this.entity.is_success = true;
            await this.assignData(response.data.data);
          } else {
            this.message.push(
              this.$t("message_read_metadata_file_error", null, {
                name: file.name,
              })
            );
          }
        } else {
          this.message.push(
            this.$t("message_read_metadata_file_error", null, {
              name: file.name,
            })
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 401)
          this.refreshToken(this.getDataFromXml, file);
        else {
          this.message.push(
            error.response.data && error.response.data.message
              ? error.response.data.message
              : this.$t("message_read_metadata_file_error", null, {
                  name: file.name,
                })
          );
        }
      }
    },
    async metaFileChange(file) {
      this.getDataFromXml(file);
    },
    async onUpload() {
      this.entity.preview = {
        preview_images: [],
      };
      let previewPath, iconPath, previewUrl, iconUrl;
      let mimeTypePreview;
      let mimeTypeIcon;
      if (this.entity.preview_file) {
        mimeTypePreview = getMimeType(
          this.entity.preview_file.name
            .split(".")
            .pop()
            .toLowerCase()
        );
        previewPath =
          "previews/" +
          CryptoJS.MD5(
            this.entity.preview_file.name +
              Math.floor(Math.random() * 9999) +
              new Date().getTime()
          ).toString();
        try {
          let responsePreview = await imageFunc.createLinkPreview(
            mimeTypePreview ? mimeTypePreview.type : "image/jpg",
            previewPath,
            this.$store.getters.getAccessToken
          );
          if (responsePreview && responsePreview.status === 200) {
            previewUrl = responsePreview.data ? responsePreview.data.url : null;
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            this.refreshToken(this.onUpload);
            return;
          }
        }
      }
      if (this.entity.icon_file) {
        mimeTypeIcon = getMimeType(
          this.entity.icon_file.name
            .split(".")
            .pop()
            .toLowerCase()
        );
        iconPath =
          "previews/" +
          CryptoJS.MD5(
            this.entity.icon_file.name +
              Math.floor(Math.random() * 9999) +
              new Date().getTime()
          ).toString();
        try {
          let responseIcon = await imageFunc.createLinkPreview(
            mimeTypeIcon ? mimeTypeIcon.type : "image/jpg",
            iconPath,
            this.$store.getters.getAccessToken
          );
          if (responseIcon && responseIcon.status === 200) {
            iconUrl = responseIcon.data ? responseIcon.data.url : null;
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            this.refreshToken(this.onUpload);
            return;
          }
        }
      }
      if (previewUrl) {
        try {
          let response = await imageFunc.uploadFilePresignedUrl(
            previewUrl,
            this.entity.preview_file,
            mimeTypePreview ? mimeTypePreview.type : "image/jpg"
          );
          if (response && response.status === 200) {
            this.entity.icon_path = previewPath;
            this.entity.preview.preview_path = previewPath;
            this.entity.preview.preview_images.push({
              type: "PREVIEW",
              path: previewPath,
              size: this.entity.preview_file.size,
              ext: this.entity.preview_file.name
                .split(".")
                .pop()
                .toLowerCase(),
            });
          }
        } catch (error) {}
      }
      if (iconUrl) {
        try {
          let response = await imageFunc.uploadFilePresignedUrl(
            iconUrl,
            this.entity.icon_file,
            mimeTypeIcon ? mimeTypeIcon.type : "image/jpg"
          );
          if (response && response.status === 200) {
            this.entity.icon_path = iconPath;
            this.entity.preview.icon_path = iconPath;
            this.entity.preview.preview_images.push({
              type: "ICON",
              path: iconPath,
              size: this.entity.icon_file.size,
              ext: this.entity.icon_file.name
                .split(".")
                .pop()
                .toLowerCase(),
            });
          }
        } catch (error) {}
      }
      this.addImage2Server(this.entity);
    },
    async addImage2Server(entity) {
      if (entity.metadata && Object.keys(entity.metadata).length > 0) {
        entity.metadata.incidence_angle = entity.incidence_angle;
        entity.metadata.resolution = entity.resolution;
        entity.metadata.cloud_coverage = entity.cloud_coverage;
        entity.metadata.cloud_coverage_unit = entity.cloud_coverage_unit;
        entity.metadata.snow_coverage = entity.snow_coverage;
        entity.metadata.snow_coverage_unit = entity.snow_coverage_unit;
        entity.metadata.area = entity.area;
        entity.metadata.area_unit = entity.area_unit;
        entity.metadata.mode = entity.mode;
        entity.metadata.beam_mode = entity.mode;
        entity.metadata.direction = entity.direction;
        entity.metadata.pol_layer = entity.pol_layer;
        entity.metadata.imaging_date = entity.imaging_date;
      }
      let dto = Object.assign({}, this.entity);
      if (dto.preview_file) delete dto.preview_file;
      dto.preview_path = dto.preview.preview_path;
      if (dto.preview_src) delete dto.preview_src;
      if (dto.icon_file) delete dto.icon_file;
      dto.icon_path = dto.preview.icon_path;
      if (dto.icon_src) delete dto.icon_src;
      dto.preview_images = dto.preview.preview_images;
      try {
        let response = await imageFunc.create(
          dto,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success) {
            this.$toast.success({
              title: this.$t("title_message_info"),
              message: this.$t("message_add_image_to_server_success", null, {
                name: entity.name,
              }),
              position: "top right",
            });
            if (entity.project_ids && entity.project_ids.length > 0) {
              for (let i = 0; i < entity.project_ids.length; i++) {
                this.addImage2Project({
                  project_id: entity.project_ids[i],
                  image_id: response.data.data ? response.data.data.id : null,
                });
              }
            }
            this.dialogUploadMetadata.show = false;
            this.onSearch({
              text: this.image.search.text
                ? this.image.search.text.trim()
                : null,
              satellite_ids: this.image.search.satellite_ids,
            });
            setTimeout(() => {
              this.entity = this.generateEntity();
            }, 200);
          } else {
            this.message.push(
              this.$t("message_add_image_to_server_error", null, {
                name: entity.name,
              })
            );
          }
        } else {
          this.message.push(
            this.$t("message_add_image_to_server_error", null, {
              name: entity.name,
            })
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.addImage2Server, entity);
        } else {
          let msg =
            error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push(
            msg
              ? msg
              : this.$t("message_add_image_to_server_error", null, {
                  name: entity.name,
                })
          );
        }
      }
    },
    async addImage2Project(data) {
      try {
        let response = await projectFunc.addImage(
          data.project_id,
          data.image_id,
          this.$store.getters.getAccessToken
        );
      } catch (error) {
        if (error.response && error.response.status === 401)
          this.refreshToken(this.addImage2Project, data);
      }
    },
    nextPage() {
      if (!this.image.is_bottom) {
        this.image.search.page = this.image.search.page + 1;
        this.getImages();
      }
    },
    clearEntity() {},
    async searchFromChange(val) {
      if (
        this.dialog.information.value &&
        Object.keys(this.dialog.information.value).length > 0
      ) {
        this.dialog.information.value.search.from = new Date(val);
        //this.dialog.information.value.loading = true;
        await this.getLogs(this.dialog.information.value);
        //this.dialog.information.value.loading = false;
      }
    },
    async searchToChange(val) {
      if (
        this.dialog.information.value &&
        Object.keys(this.dialog.information.value).length > 0
      ) {
        this.dialog.information.value.search.to = new Date(val);
        //this.dialog.information.value.loading = true;
        await this.getLogs(this.dialog.information.value);
        //this.dialog.information.value.loading = false;
      }
    },
    async showDialogInfo(item) {
      let index = this.information.findIndex((x) => x.image_id === item.id);
      if (index < 0) {
        let objSearch = {
          from: null,
          to: null,
        };
        let today = new Date(),
          dateMinus7 = new Date();
        today.setHours(23);
        today.setMinutes(59);
        today.setSeconds(59);
        dateMinus7.setHours(0);
        dateMinus7.setMinutes(0);
        dateMinus7.setSeconds(0);
        objSearch.to = today;
        objSearch.from = new Date(dateMinus7.setDate(dateMinus7.getDate() - 7));
        this.information.push({
          image: item,
          image_id: item.id,
          search: objSearch,
          down_history: [],
          loading: false,
        });
        await this.getLogs(this.information[this.information.length - 1]);
        this.dialog.information.value = this.information[
          this.information.length - 1
        ];
      } else {
        this.dialog.information.value = this.information[index];
      }
      this.dialog.information.show = true;
    },
    showDialogUpdateMetadata(item) {
      this.dialog.updateMetadata.value = {
        data_file: item,
      };
      this.dialog.updateMetadata.show = true;
    },
    showDialogMetadata(item) {
      this.dialog.metadata.value = item;
      this.dialog.metadata.show = true;
    },

    showDialogShare(item) {
      this.dialog.share.value = [item];
      this.dialog.share.show = true;
    },
    async downloadImage(item) {
      this.$store.dispatch("setImagesDownload", [
        {
          is_add: true,
          image: item,
        },
      ]);
      try {
        let response = await imageSharingFunc.download2(
          item.password ? item.password : null,
          item.token,
          item.id,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          //if (response.data.type.toLowerCase() === "file") {
          if (response.data.url) {
            window.open(response.data.url, "_blank");
          } else if (response.data.type.toLowerCase() === "folder") {
            this.downloadFolderAsZip({
              url: response.data.url,
              // .replace("http://", "http:/")
              // .replace("https://", "https:/")
              // .replace("//", "/")
              // .replace("http:/", "http://")
              // .replace("https:/", "https://"),
              method: "post",
              data: response.data.data,
              image: item,
            });
          }
        } else {
          this.message.push(
            this.$t("message_download_file_error", null, { name: item.name })
          );
        }
        this.$store.dispatch("setImagesDownload", [
          {
            is_add: false,
            image: item,
          },
        ]);
      } catch (error) {
        this.$store.dispatch("setImagesDownload", [
          {
            is_add: false,
            image: item,
          },
        ]);
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.downloadImage, item);
        } else {
          let msg =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push(
            msg
              ? msg
              : this.$t("message_download_file_error", null, {
                  name: item.name,
                })
          );
        }
      }
    },
    async downloadFolderAsZip(data) {
      this.$store.dispatch("setImagesDownload", [
        {
          is_add: true,
          image: data.image,
        },
      ]);
      try {
        let response = await imageFunc.downloadFolderAsZip(data.url, data.data);
        if (response.status === 200) {
          let contentType =
            response.headers && response.headers["content-type"]
              ? response.headers["content-type"]
              : "application/zip";
          let url,
            blob = new Blob([response.data], { type: contentType });
          let extension = "";
          try {
            extension = contentType.substring(
              contentType.indexOf("/") + 1,
              contentType.length
            );
          } catch (error2) {}
          if (blob.size === 0) {
            this.message.push(this.$t("message_file_dose_not_exist"));
          } else {
            try {
              if (!window.navigator.msSaveOrOpenBlob) {
                // BLOB NAVIGATOR
                url = window.URL.createObjectURL(blob);
              } else {
                // BLOB FOR EXPLORER 11
                url = window.navigator.msSaveOrOpenBlob(
                  blob,
                  change_alias(data.image.name) + ".zip" //(extension ? "."+extension :".zip")
                );
              }
              const linkdown = document.createElement("a");
              linkdown.href = url;
              linkdown.setAttribute(
                "download",
                change_alias(data.image.name) + ".zip"
              ), //(extension ? "."+extension :".zip"));
                document.body.appendChild(linkdown);
              linkdown.click();
              document.body.removeChild(linkdown);
              window.URL.revokeObjectURL(url);
            } catch (error3) {}
          }
        } else {
          this.message.push(
            this.$t("message_download_file_error", null, {
              name: data.image.name,
            })
          );
        }
        this.$store.dispatch("setImagesDownload", [
          {
            is_add: false,
            image: data.image,
          },
        ]);
      } catch (error) {
        this.$store.dispatch("setImagesDownload", [
          {
            is_add: false,
            image: data.image,
          },
        ]);
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.downloadFolderAsZip, data);
        } else {
          //let responseObj = await error.response.data.text();
          if (
            error.request &&
            error.request.responseType === "blob" &&
            error.response.data instanceof Blob &&
            error.response.data.type &&
            error.response.data.type.toLowerCase().indexOf("json") != -1
          ) {
            let reader = new FileReader();
            reader.onload = () => {
              let responseObj = JSON.parse(reader.result);
              let msg =
                responseObj && responseObj.message ? responseObj.message : null;
              this.message.push(
                msg
                  ? msg
                  : this.$t("message_download_file_error", null, {
                      name: data.image.name ? data.image.name : null,
                    })
              );
            };
            reader.readAsText(error.response.data);
          } else {
            let msg =
              error.response &&
              error.response.data &&
              error.response.data.message
                ? error.response.data.message
                : null;
            this.message.push(
              msg
                ? msg
                : this.$t("message_download_file_error", null, {
                    name: data.image.name,
                  })
            );
          }
        }
      }
    },
    async downloadMyZipFile(val) {
      this.$store.dispatch("setImagesDownload", [
        {
          is_add: true,
          image: val,
        },
      ]);
      try {
        let response = await imageSharingFunc.download2(
          val.password ? val.password : null,
          val.token,
          val.id,
          this.$store.getters.getAccessToken
        );
        if (response.status === 200) {
          let contentType =
            response.headers && response.headers["content-type"]
              ? response.headers["content-type"]
              : "application/zip";
          let url,
            blob = new Blob([response.data], { type: contentType });
          let extension = "";
          try {
            extension = contentType.substring(
              contentType.indexOf("/") + 1,
              contentType.length
            );
          } catch (error2) {}
          if (blob.size === 0) {
            this.message.push(this.$t("message_file_dose_not_exist"));
          } else {
            try {
              if (!window.navigator.msSaveOrOpenBlob) {
                // BLOB NAVIGATOR
                url = window.URL.createObjectURL(blob);
              } else {
                // BLOB FOR EXPLORER 11
                url = window.navigator.msSaveOrOpenBlob(
                  blob,
                  change_alias(val.name) + ".zip" //(extension ? "."+extension :".zip")
                );
              }
              const linkdown = document.createElement("a");
              linkdown.href = url;
              linkdown.setAttribute(
                "download",
                change_alias(val.name) + ".zip"
              ), //(extension ? "."+extension :".zip"));
                document.body.appendChild(linkdown);
              linkdown.click();
              document.body.removeChild(linkdown);
              window.URL.revokeObjectURL(url);
            } catch (error3) {}
          }
        } else {
          this.message.push(
            this.$t("message_download_file_error", null, { name: val.name })
          );
        }
        this.$store.dispatch("setImagesDownload", [
          {
            is_add: false,
            image: val,
          },
        ]);
      } catch (error) {
        this.$store.dispatch("setImagesDownload", [
          {
            is_add: false,
            image: val,
          },
        ]);
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.downloadMyZipFile, val);
        } else {
          //let responseObj = await error.response.data.text();
          if (
            error.request.responseType === "blob" &&
            error.response.data instanceof Blob &&
            error.response.data.type &&
            error.response.data.type.toLowerCase().indexOf("json") != -1
          ) {
            let reader = new FileReader();
            reader.onload = () => {
              let responseObj = JSON.parse(reader.result);
              let msg =
                responseObj && responseObj.message ? responseObj.message : null;
              this.message.push(
                msg
                  ? msg
                  : this.$t("message_download_file_error", null, {
                      name: val.name ? val.name : null,
                    })
              );
            };
            reader.readAsText(error.response.data);
          } else {
            let msg =
              error.response &&
              error.response.data &&
              error.response.data.message
                ? error.response.data.message
                : null;
            this.message.push(
              msg
                ? msg
                : this.$t("message_download_file_error", null, {
                    name: val.name,
                  })
            );
          }
        }
      }
    },

    async onShare(entity) {
      let message = [];
      entity.description = entity.description
        ? entity.description.trim().replace(/\s\s+/g, " ")
        : "";
      if (!entity.member_ids || entity.member_ids.length === 0) {
        message.push(this.$t("message_receiver_required"));
      }
      if (!entity.description)
        message.push(this.$t("message_content_required"));
      if (entity.isExpired) {
        if (entity.expiredTime) {
          entity.expiredTime = new Date(entity.expiredTime).setHours(
            23,
            59,
            59,
            0
          );
          if (entity.expiredTime - new Date().getTime() < 0)
            message.push(
              this.$t("message_expired_time_must_greater_than_or_equal_today")
            );
        } else {
          message.push(this.$t("message_expired_time_required"));
        }
      }
      if (entity.isPass) {
        if (!entity.password) {
          message.push(this.$t("message_password_required"));
        }
      }
      if (entity.isNum) {
        if (!entity.download_number) {
          message.push(
            this.$t("message_numberdownload_must_greater_than_zero")
          );
        }
      }
      if (message.length > 0) {
        this.message = message;
        return;
      } else {
        await this.handleSharing(entity);
      }
    },
    async handleSharing(entity) {
      if (this.dialog.share.value && this.dialog.share.value.length > 0) {
        for (let i = 0; i < this.dialog.share.value.length; i++) {
          let dto = {
            description: entity.description ? entity.description.trim() : "",
            download_number: entity.download_number,
            expired_time: entity.isExpired
              ? new Date(entity.expiredTime).setHours(23, 59, 59, 0)
              : null,
            image_id: this.dialog.share.value[i].id,
            is_public: false,
            member_ids: entity.member_ids,
            password: entity.isPass && entity.password ? entity.password : null,
            order_id: null,
          };
          this.buttons.share.disabled = true;
          this.buttons.share.processing = true;
          try {
            let response = await meControllerFunc.createShare(
              dto,
              this.$store.getters.getAccessToken
            );
            if (response.status === 200 || response.status === 201) {
              this.$toast.success({
                title: this.$t("title_message_info"),
                message: this.$t(
                  "message_create_image_sharing_susscess",
                  null,
                  {
                    name: this.dialog.share.value[i].name
                      ? this.dialog.share.value[i].name
                      : "",
                  }
                ),
                position: "top right",
              });
              setTimeout(() => {
                this.dialog.share.show = false;
                this.dialog.share.value = null;
              }, 100);
            } else {
              this.message.push(
                this.$t("message_create_image_sharing_error", null, {
                  name: this.dialog.share.value[i].name
                    ? this.dialog.share.value[i].name
                    : "",
                })
              );
            }
            this.buttons.share.disabled = false;
            this.buttons.share.processing = false;
          } catch (error) {
            this.buttons.share.disabled = false;
            this.buttons.share.processing = false;
            if (error.response && error.response.status === 401)
              await this.refreshToken(this.handleSharing, entity);
            else {
              let msg =
                error.response.data && error.response.data.message
                  ? error.response.data.message
                  : null;
              this.message.push(
                msg
                  ? msg
                  : this.$t("message_create_image_sharing_error", null, {
                      name: this.dialog.share.value[i].name
                        ? this.dialog.share.value[i].name
                        : "",
                    })
              );
            }
          }
        }
      }
    },
    async updateIsPublicImage(data) {
      try {
        let response = await imageFunc.update(
          {
            id: data.id,
            is_public: data.is_public,
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.$toast.success({
            title: this.$t("title_message_info"),
            message: this.$t("message_update_ispublic_image_success", null, {
              name: data.is_public ? "public" : "private",
              image_name: data.name ? data.name : "",
            }),
            position: "top right",
          });
        } else {
          this.message.push(
            this.$t("message_update_ispublic_image_error", null, {
              name: data.is_public ? "public" : "private",
              image_name: data.name ? data.name : "",
            })
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.updateIsPublicImage, data);
        } else {
          let msg =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push(
            msg
              ? msg
              : this.$t("message_update_ispublic_image_error", null, {
                  name: data.is_public ? "public" : "private",
                  image_name: data.name ? data.name : "",
                })
          );
        }
      }
    },
    /* ----------------- Upload 2 minio server ---------------------*/
    async checkObjectExists(bucketName, objectName) {
      var vm = this,
        result = null;
      return new Promise(async (resolve, reject) => {
        vm.s3Client.statObject(bucketName, objectName, async function(
          err,
          exists
        ) {
          if (err) {
            resolve(false);
            return false;
          }
          resolve(true);
        });
      });
    },
    async checkUpload(file, bucketName, uploadId, id = null) {
      var vm = this,
        result = null;
      // Kiểm tra các part đã upload thành công trước đó nhưng chưa upload toàn bộ các part của file
      this.s3Client.listParts(
        bucketName,
        "preview/" + file.name,
        uploadId,
        async function(err, etags) {
          if (err) {
          }
          let intPart = 0;
          let oldParts = etags.reduce(function(prev, item) {
            intPart++;
            if (!item.part) item.part = intPart;
            if (!prev[intPart]) {
              prev[intPart] = item;
            }
            return prev;
          }, {});

          result = await vm.putObject(
            file,
            bucketName,
            uploadId,
            {},
            etags.length,
            oldParts,
            id
          );
        }
      );
    },
    findUploadId(file, bucketName, id = null) {
      var vm = this,
        result = null;
      this.s3Client.findUploadId(
        bucketName,
        "preview/" + file.name,
        async function(err, exists) {
          if (err) {
          }
          if (!exists) {
            result = await vm.initiateNewMultipartUpload(file, bucketName, id);
          } else {
            result = await vm.checkUpload(file, bucketName, exists, id);
          }
        }
      );
    },
    initiateNewMultipartUpload(file, bucketName, id = null) {
      var vm = this,
        result = null;
      this.s3Client.initiateNewMultipartUpload(
        bucketName,
        "preview/" + file.name,
        {},
        async function(err, data) {
          if (err) {
            resolve(null);
          }
          result = await vm.putObject(file, bucketName, data, {}, 0, null, id);
        }
      );
    },

    async putObjectOnePart(file, bucketName, metaData, id = null) {
      var vm = this;
      try {
        let uint8Array = await vm.readAsUint8Array(file);
        var headers = Object.assign({}, metaData, {
          "Content-Length": uint8Array.length,
        });
        let query = "";
        let method = "PUT";
        var options = {
          method,
          query,
          headers,
          bucketName: bucketName,
          objectName: "preview/" + file.name,
          region: "us-east-1",
        };
        var sha256sum = "";
        // Có thể dùng hàm này nhưng bị đơ trình duyệt 1 vài giây
        //if (vm.s3Client.enableSHA256) sha256sum = await vm.createSha256(uint8Array); //_crypto.default.createHash('sha256').update(uint8Array).digest('hex');
        if (vm.s3Client.enableSHA256) {
          let hash = sha256.update(uint8Array);
          sha256sum = hash.hex();
        }
        var stream = (0, _helpers.readableStream)(uint8Array);
        var reqOptions = vm.s3Client.getRequestOptions(options);
        if (!vm.s3Client.anonymous) {
          // For non-anonymous https requests sha256sum is 'UNSIGNED-PAYLOAD' for signature calculation.
          if (!vm.s3Client.enableSHA256) sha256sum = "UNSIGNED-PAYLOAD";
          var date = new Date();
          reqOptions.headers["x-amz-date"] = (0, _helpers.makeDateLong)(date);
          reqOptions.headers["x-amz-content-sha256"] = sha256sum;

          if (vm.s3Client.sessionToken) {
            reqOptions.headers["x-amz-security-token"] =
              vm.s3Client.sessionToken;
          }
          var authorization = (0, _signing.signV4)(
            reqOptions,
            vm.s3Client.accessKey,
            vm.s3Client.secretKey,
            "",
            date
          );
          reqOptions.headers.authorization = authorization;
        }
        if (reqOptions.headers["host"]) delete reqOptions.headers["host"];
        if (reqOptions.headers["user-agent"])
          delete reqOptions.headers["user-agent"];
        if (reqOptions.headers["content-length"])
          delete reqOptions.headers["content-length"];

        let uploadResp = await axios.put(
          reqOptions.protocol +
            "//" +
            reqOptions.host +
            ":" +
            reqOptions.port +
            reqOptions.path,
          uint8Array,
          { headers: reqOptions.headers }
        );

        let resolvedArray = await Promise.all([uploadResp]);
        resolvedArray.forEach((resolvedPromise, k) => {
          if (resolvedPromise && resolvedPromise.status === 200) {
          } else {
          }
        });
      } catch (error) {}
    },
    async putObject(
      file,
      bucketName,
      uploadId,
      metaData,
      partNumber = 0,
      oldParts,
      id = null
    ) {
      var vm = this;

      const FILE_CHUNK_SIZE = vm.s3Client.partSize;
      const fileSize = file.size;
      const NUM_CHUNKS = Math.floor(fileSize / FILE_CHUNK_SIZE) + 1;
      let start,
        end,
        blob,
        etags = [];
      let sum = partNumber * FILE_CHUNK_SIZE;
      if (partNumber === NUM_CHUNKS) {
        for (const [key, value] of Object.entries(oldParts)) {
          if (key) etags.push(value);
        }
      }
      for (let index = partNumber + 1; index < NUM_CHUNKS + 1; index++) {
        try {
          start = (index - 1) * FILE_CHUNK_SIZE;
          end = index * FILE_CHUNK_SIZE;
          blob =
            index < NUM_CHUNKS ? file.slice(start, end) : file.slice(start);
          let uint8Array = await vm.readAsUint8Array(blob);
          var headers = Object.assign({}, metaData, {
            "Content-Length": uint8Array.length,
          });
          let query = "partNumber=" + index + "&uploadId=" + uploadId;
          let method = "PUT";
          var options = {
            method,
            query,
            headers,
            bucketName: bucketName,
            objectName: "preview/" + file.name,
            region: "us-east-1",
          };
          var sha256sum = "";
          // Có thể dùng hàm này nhưng bị đơ trình duyệt 1 vài giây
          //if (vm.s3Client.enableSHA256) sha256sum = await vm.createSha256(uint8Array); //_crypto.default.createHash('sha256').update(uint8Array).digest('hex');
          if (vm.s3Client.enableSHA256) {
            let hash = sha256.update(uint8Array);
            sha256sum = hash.hex();
          }
          var stream = (0, _helpers.readableStream)(uint8Array);
          var reqOptions = vm.s3Client.getRequestOptions(options);
          if (!vm.s3Client.anonymous) {
            // For non-anonymous https requests sha256sum is 'UNSIGNED-PAYLOAD' for signature calculation.
            if (!vm.s3Client.enableSHA256) sha256sum = "UNSIGNED-PAYLOAD";
            var date = new Date();
            reqOptions.headers["x-amz-date"] = (0, _helpers.makeDateLong)(date);
            reqOptions.headers["x-amz-content-sha256"] = sha256sum;

            if (vm.s3Client.sessionToken) {
              reqOptions.headers["x-amz-security-token"] =
                vm.s3Client.sessionToken;
            }
            var authorization = (0, _signing.signV4)(
              reqOptions,
              vm.s3Client.accessKey,
              vm.s3Client.secretKey,
              "",
              date
            );
            reqOptions.headers.authorization = authorization;
          }
          if (reqOptions.headers["host"]) delete reqOptions.headers["host"];
          if (reqOptions.headers["user-agent"])
            delete reqOptions.headers["user-agent"];
          if (reqOptions.headers["content-length"])
            delete reqOptions.headers["content-length"];
          let uploadResp = await axios.put(
            reqOptions.protocol +
              "//" +
              reqOptions.host +
              ":" +
              reqOptions.port +
              reqOptions.path,
            uint8Array,
            { headers: reqOptions.headers }
          );
          let resolvedArray = await Promise.all([uploadResp]);
          resolvedArray.forEach((resolvedPromise, k) => {
            if (index < NUM_CHUNKS) {
              sum = sum + (end - start);
            } else {
              sum = sum + (fileSize - start);
            }
            etags.push({
              etag: resolvedPromise.headers.etag,
              part: index,
            });
          });
        } catch (error) {}
      }
      if (
        etags.length === NUM_CHUNKS - partNumber ||
        partNumber === NUM_CHUNKS
      ) {
        let finishEtag = await vm.completeMultipartUpload({
          etags: etags,
          count: 0,
          bucketName: bucketName,
          uploadId: uploadId,
          objectName: "images/" + file.name,
        });
        if (finishEtag) {
        } else {
        }
      } else {
      }
    },
    async createSha256(uint8Array) {
      return new Promise((resolve, reject) => {
        resolve(
          _crypto.default
            .createHash("sha256")
            .update(uint8Array)
            .digest("hex")
        );
      });
    },
    async readAsUint8Array(blob) {
      return new Promise((resolve, reject) => {
        var fr = new FileReader();
        fr.onload = async function(event) {
          let uint8Array = new Uint8Array(event.target.result);
          resolve(uint8Array);
        };
        fr.onerror = function(e) {
          resolve(null);
        };
        fr.readAsArrayBuffer(blob);
      });
    },

    async makeRequest(reqOptions, uint8Array) {
      var vm = this;
      return new Promise(async (resolve, reject) => {
        return vm
          .axios({
            url:
              reqOptions.protocol +
              "//" +
              reqOptions.host +
              ":" +
              reqOptions.port +
              reqOptions.path,
            method: "PUT",
            data: uint8Array,
            headers: reqOptions.headers,
          })
          .then((res) => {
            if (res && res.status === 200) {
              resolve(
                res.headers && res.headers.etag ? res.headers.etag : null
              );
            } else resolve(null);
          })
          .catch((err) => {
            resolve(null);
          });
      });
    },
    completeMultipartUpload(data) {
      var vm = this;
      return new Promise((resolve, reject) => {
        // Calls the CompleteMultipartUpload endpoint in the backend server
        vm.s3Client.completeMultipartUpload(
          data.bucketName,
          data.objectName,
          data.uploadId,
          data.etags,
          function(err, etag) {
            // Call our callback on the next tick to allow the streams infrastructure
            if (err) {
              data.count = data.count + 1;
              if (data.count < 2) {
                return vm.completeMultipartUpload(data);
              } else {
                resolve(null);
              }
            }
            // to finish what its doing before we continue.
            resolve(etag);
          }
        );
      });
    },
    /* ----------------- End Upload 2 minio ------------------*/
    /*------------------ End emit ----------------*/
    menuHeaderClick(val) {
      switch (val.code) {
        case "ADD":
          this.emitFunction({
            code: "UPLOAD_METADATA",
          });
          break;
      }
    },
    async emitFunction(val) {
      switch (val.code) {
        case "UPLOAD_METADATA":
          this.entity = this.generateEntity();
          this.dialogUploadMetadata.show = true;
          break;
      }
    },
    async onSearch(data) {
      this.image.search.text = data.text ? data.text.trim() : null;
      this.image.search.satellite_ids = data.satellite_ids
        ? data.satellite_ids
        : null;
      this.image.search.page = 0;
      this.image.items = [];
      this.image.is_bottom = false;
      this.getImages();
    },
    async getLogs(data) {
      data.loading = true;
      data.down_history = [];
      try {
        let response = await logFunc.getLogs(
          {
            action: "DOWNLOAD_IMAGE",
            create_from: data.search.from,
            create_to: data.search.to,
            image_id: data.image.id,
            page: 0,
            size: 2147483647,
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data && response.data.content_page) {
            for (let j = 0; j < response.data.content_page.length; j++) {
              try {
                data.down_history.push({
                  image_id: data.image.id,
                  image_name: data.image.name,
                  created_by: response.data.content_page[j].created_by,
                  created_at: response.data.content_page[j].created_at,
                  date_str: formatUnixDateTime2String(
                    new Date(response.data.content_page[j].created_at)
                  ),
                  total: 1,
                });
              } catch (error) {}
            }
          }
        }
        data.loading = false;
      } catch (error) {
        data.loading = false;
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getLogs, data);
        }
      }
      data.loading = false;
    },
    async getImages() {
      if (this.image.is_bottom) return;
      this.image.loading = true;
      try {
        let params = {
          page: this.image.search.page,
          size: this.image.search.size,
        };
        let response = await imageFunc.search(
          params,
          {
            text: this.image.search.text,
            satellite_ids: this.image.search.satellite_ids,
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.image.items = [
            ...this.image.items,
            ...response.data.content_page,
          ];
          this.image.page_size = response.data.page_size;
          this.image.page_number = response.data.page_number;
          this.image.total_page = response.data.total_page;
          this.image.total_records = response.data.total_records;
          if (
            this.image.items.length === this.image.total_records &&
            this.image.items.length > 0
          )
            this.image.is_bottom = true;
        }
      } catch (error) {
        this.image.loading = false;
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.getImages);
        }
      }
      this.image.loading = false;
    },
    async getAllProject() {
      try {
        let response = await projectFunc.getAll(
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.project.items = response.data.content_page
            ? response.data.content_page.filter((x) => !x.is_delete)
            : [];
          this.project.items.sort(function(a, b) {
            if (a.title < b.title) return -1;
            else if (a.title > b.title) return 1;
            return 0;
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.getAllProject);
        }
      }
    },
    async getAllUser() {
      try {
        let response = await userFunc.search(
          {
            page: 0,
            size: 2147483647,
            order: "full_name",
            sort: "asc",
          },
          {
            is_active: true,
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.user.items = response.data.content_page
            ? response.data.content_page.filter((x) => x.is_active)
            : [];
          // this.user.items.sort(function (a, b) {
          //   if (a.full_name < b.full_name) return -1;
          //   else if (a.full_name > b.full_name) return 1;
          //   return 0;
          // });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.getAllUser);
        }
      }
    },
    async getMinioConfig() {
      try {
        let response = await metaKeyFunc.getByKey(
          "MINIO_SERVER",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success) {
            let data = response.data.data ? response.data.data : [];
            if (data.length > 0) {
              this.minioServer.metakey = data[data.length - 1];
              try {
                let obj = JSON.parse(
                  await cryptoFunc.decrypt(data[data.length - 1].value)
                );
                if (obj && Object.keys(obj).length > 0) {
                  this.minioServer.data = this.generateMinioData();
                  this.minioServer.data.end_point.value = obj.end_point
                    ? obj.end_point
                    : obj.END_POINT;
                  this.minioServer.data.port.value = obj.port
                    ? obj.port
                    : obj.PORT;
                  this.minioServer.data.use_ssl.value =
                    (obj.use_ssl && obj.use_ssl + "" === "1") ||
                    (obj.USE_SSL && obj.USE_SSL + "" === "1")
                      ? true
                      : false;
                  this.minioServer.data.access_key.value = obj.access_key
                    ? obj.access_key
                    : obj.ACCESS_KEY;
                  this.minioServer.data.secret_key.value = obj.secret_key
                    ? obj.secret_key
                    : obj.SECRET_KEY;
                  this.minioServer.data.part_size.value = obj.part_size
                    ? obj.part_size
                    : obj.PART_SIZE;
                }
              } catch (error) {
                this.minioServer.data = this.generateMinioData();
              }
            }
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401)
          this.refreshToken(this.getMinioConfig);
      }
    },
    async getAllBeamMode() {
      try {
        let response = await metaKeyFunc.getByKey(
          "BEAM_MODE",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success) {
            this.beamMode.items =
              response && response.data && response.data.data
                ? response.data.data
                : [];
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllBeamMode);
        }
      }
    },
    async getAllDirection() {
      try {
        let response = await metaKeyFunc.getByKey(
          "DIRECTION",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success) {
            this.direction.items =
              response && response.data && response.data.data
                ? response.data.data
                : [];
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllDirection);
        }
      }
    },
    async getAllPolLayer() {
      try {
        let response = await metaKeyFunc.getByKey(
          "POL_LAYER",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success) {
            this.polLayer.items =
              response && response.data && response.data.data
                ? response.data.data
                : [];
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllPolLayer);
        }
      }
    },
    async getAllProcessingLevel() {
      try {
        let response = await metaKeyFunc.getByKey(
          "PROCESSING_LEVEL",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success) {
            this.processingLevel.items =
              response && response.data && response.data.data
                ? response.data.data
                : [];
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllProcessingLevel);
        }
      }
    },
    async getAllProvider() {
      try {
        let response = await metaKeyFunc.getByKey(
          "PROVIDER",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.provider.items =
            response && response.data && response.data.data
              ? response.data.data
              : [];
          if (this.provider.items && this.provider.items.length > 0) {
            for (let i = 0; i < this.provider.items.length; i++) {
              if (
                this.provider.items[i].sub_meta_key &&
                this.provider.items[i].sub_meta_key.length > 0
              ) {
                let children = [];
                for (
                  let j = 0;
                  j < this.provider.items[i].sub_meta_key.length;
                  j++
                ) {
                  children.push({
                    id: this.provider.items[i].sub_meta_key[j].id,
                    key: this.provider.items[i].sub_meta_key[j].id,
                    name: this.provider.items[i].sub_meta_key[j].name,
                    code: this.provider.items[i].sub_meta_key[j].name,
                    label: this.provider.items[i].sub_meta_key[j].value,
                  });
                }
                this.provider.tree.push({
                  id: this.provider.items[i].id,
                  key: this.provider.items[i].id,
                  name: this.provider.items[i].name,
                  code: this.provider.items[i].name,
                  label: this.provider.items[i].value,
                  children: children,
                });
              }
            }
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllProvider);
        }
      }
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
            this.$toast.info({
              title: this.$t("title_message_info"),
              message: this.$t("message_login_again"),
              position: "top right",
            });
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
          this.$toast.info({
            title: this.$t("title_message_info"),
            message: this.$t("message_login_again"),
            position: "top right",
          });
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
};
</script>
<style lang="scss" scoped>
.satellite-image__list {
  padding: 0.5rem;
  .satellite-image__button-upmetadata {
    button {
      font-size: 14px;
      width: 160px;
      height: 36px;
    }
  }
  .satellite-image__search {
    margin-top: 0.5rem;
  }
  .satellite-image__datatable {
    margin-top: 0.5rem;
    overflow-y: auto;
  }
}
</style>
<style lang="scss" scoped>
.satellite-image__list {
  .satellite-image__search {
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .satellite-image__datatable {
    .accordion {
      border-radius: 0.5rem;
    }
  }
}
</style>
