<template>
  <Dialog
    :header="$t('title_update_status')"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :modal="true"
    :contentStyle="propStyle"
    @hide="onClose"
  >
    <div class="p-grid m-0">
      <div class="form-row p-field p-col-12 m-0">
        <label class="p-col-fixed m-0 w-100">{{
          $t("label_order_code")
        }}</label>
        <InputText
          class="w-100 new-style"
          :value="propItem ? propItem.order_code : ''"
          maxLength="255"
          :readonly="true"
        />
      </div>
      <div class="p-field p-col-12">
        <label class="p-col-fixed m-0 w-100">
          {{ $t("label_status") }} &nbsp;
          <span class="font-weight-bold required">*</span>
        </label>
        <Dropdown
          class="w-100 new-style"
          v-model="entity.status_id"
          :showClear="false"
          :options="
            propOrderStatus.filter(
              (x) => x.id != this.propTabId && x.name != 'ORDER_CANCELED'
            )
          "
          optionValue="id"
          optionLabel="value"
          optionDisabled="is_disable"
          dataKey="id"
          :disabled="
            propButton && propButton.disable ? propButton.disable : false
          "
          :placeholder="$t('placeholder_select_status')"
        >
          <template #option="slotProps">
            <div class="d-flex">
              <div
                class="square _18x18 pr-2"
                :style="orderColor(slotProps.option.name)"
              >
                &nbsp;
              </div>
              <span>{{ slotProps.option.value }}</span>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="form-row p-field p-col-12 m-0">
        <label class="p-col-fixed m-0 w-100"
          >{{ labelRemark }}&nbsp;
          <span class="font-weight-bold required">*</span></label
        >
        <Textarea
          class="w-100 new-style"
          v-model="entity.remark"
          :autoResize="true"
          rows="3"
          maxLength="255"
          :readonly="
            propButton && propButton.disable ? propButton.disable : false
          "
        />
      </div>
    </div>
    <template #footer>
      <button
        :disabled="
          propButton && propButton.disable ? propButton.disable : false
        "
        :label="$t('button_text_save')"
        :icon="
          propButton && propButton.processing
            ? 'fas fa-spinner fa-spin'
            : 'fas fa-save'
        "
        :class="'btn btn-geostore2  btn-w96-h36 btn-save mr-0'"
        @click="onUpdateStatus"
      >
        <i
          :class="
            propButton && propButton.processing
              ? 'fas fa-spinner fa-spin'
              : 'fas fa-save'
          "
        ></i>
        {{ $t("button_text_save") }}
      </button>
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
import Button from "@/components/commons/button/Button";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import Textarea from "@/components/commons/textarea/Textarea";
import InputText from "@/components/commons/inputtext/InputText";
export default {
  components: {
    Dialog,
    Button,
    Dropdown,
    Textarea,
    InputText,
  },
  props: {
    propOrder: {
      type: Object,
      default: () => {
        return {};
      },
    },
    propOrderStatus: {
      type: Array,
      default: () => [],
    },
    propTitle: {
      type: String,
      default: "",
    },
    propStyle: {
      type: Object,
      default: () => {
        return {
          width: "480px",
        };
      },
    },
    propTabId: { type: String, default: "" },
    propShow: {
      type: Boolean,
      default: false,
    },
    propButton: { type: Object, default: () => {} },
    propItem: { type: Object, default: () => {} },
  },
  data() {
    return {
      entity: {
        remark: null,
        order_id: null,
        status_id: null,
      },
      show: false,
    };
  },
  created() {
    this.show = this.propShow;
  },
  watch: {
    propShow: {
      handler(val) {
        this.show = this.propShow;
      },
      immediate: true,
    },
  },
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    styleLabel() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:135px";
    },
    styleLabelCheckbox() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:128px";
    },
    styleControl() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:calc(100% - 135px)";
    },
    labelRemark() {
      let item = this.propOrderStatus.find(
        (x) => x.id === this.entity.status_id
      );
      if (item && Object.keys(item).length > 0) {
        switch (item.name) {
          case "ORDER_REJECTED":
            return this.$t("label_order_reason");
            break;
          case "ORDER_PROCESSING":
            return this.$t("label_note");
            break;

          case "ORDER_COMPLETED":
            return this.$t("label_note");
            break;
          case "ORDER_CANCELED":
            return this.$t("label_order_reason");
            break;
        }
      }
      return this.$t("label_note");
    },
  },
  methods: {
    onClose() {
      this.show = false;
      this.$emit("close");
    },
    onUpdateStatus() {
      this.$emit("onUpdateStatus", this.entity);
    },

    orderColor(name) {
      let result = null;
      switch (name) {
        case "ORDER_PROCESSING":
          result = "background:#0d6efd";
          break;
        case "ORDER_CREATED":
          result = "background:#F68E1E";
          break;
        case "ORDER_COMPLETED":
          result = "background:#27AE60";
          break;
        case "ORDER_CANCELED":
          result = "background:#ffc107";
          break;
        case "ORDER_REJECTED":
          result = "background:#F1416C";
          break;
        case "ORDER_CONFIRMED":
          result = "background:#0dcaf0";
          break;
        default:
          result = "background:#9da5b1";
          break;
      }
      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
.square {
  margin-right: 0.5rem;
  opacity: 0.7;
}
button.btn-save {
  font-size: 14px;
  width: 100px !important;
}
</style>
