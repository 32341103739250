import { render, staticRenderFns } from "./UploadMetadata.vue?vue&type=template&id=eecc8c82&scoped=true&"
import script from "./UploadMetadata.vue?vue&type=script&lang=js&"
export * from "./UploadMetadata.vue?vue&type=script&lang=js&"
import style0 from "./UploadMetadata.vue?vue&type=style&index=0&id=eecc8c82&lang=scss&scoped=true&"
import style1 from "./UploadMetadata.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eecc8c82",
  null
  
)

export default component.exports