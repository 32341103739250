var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-layout',{attrs:{"isToolbar":false,"isBreadcrumb":true,"breadcrumbitems":[
    { label: _vm.$t('menu_statistic_report') },
    { label: _vm.$t('menu_statistic_general') } ],"propButtonsState":{
    save: {
      visible: false,
    },
    add: {
      visible: false,
    },
    edit: {
      visible: false,
    },
    reload: {
      visible: true,
    },
    print: {
      visible: false,
    },
  },"propTitle":_vm.$t('menu_statistic_general')},on:{"emitFunction":_vm.menuHeaderClick}},[_c('div',{staticClass:"general-report"},[_c('div',{staticClass:"general-report__data"},[_c('table',{staticClass:"table"},[_c('tbody',[_c('tr',{attrs:{"colspan":"2"}},[_c('td',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("menu_statistic_general")))])])]),_c('tr',[_c('td',{staticStyle:{"width":"14rem"}},[_vm._v(_vm._s(_vm.$t("label_total_images_2"))+":")]),_c('td',[_c('strong',[_vm._v(_vm._s(_vm.statistic.data.total_images))])])]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("lable_download_total"))+":")]),_c('td',[_c('strong',[_vm._v(_vm._s(_vm.statistic.data.total_download))])])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("label_most_download_top_num", null, { num: 10, }))+": ")]),_c('td',[_c('div',{staticClass:"HsJhe782hf"},[_c('ol',_vm._l((_vm.imagesName),function(name,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(name)+" ")])}),0),_c('button',{staticClass:"btn btn-geostore2 btn-export btn-w120-h36",attrs:{"label":"","icon":"fas fa-file-csv"},on:{"click":_vm.exportCsv}},[_c('i',{staticClass:"fas fa-file-csv"}),_vm._v("  Export CSV ")])])])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t("label_user_most_download_top_num", null, { num: 10, }))+": ")]),_c('td',[_c('ol',_vm._l((_vm.usersName),function(name,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(name)+" ")])}),0)])])])])]),_c('div',{staticClass:"general-report__datatable"},[_c('DataTable',{attrs:{"propItems":_vm.statistic.data.most_satellite_image,"prop-provider":_vm.provider.items}})],1),(_vm.statistic.data.most_satellite_image)?_c('div',{staticClass:"general-report__chart"},[_c('DataChart',{attrs:{"prop-data":_vm.statistic.data.most_satellite_image}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }