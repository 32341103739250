<template>
  <div class="accordion">
    <slot name="accordion-header">
      <div class="accordion-header">
        <span class="header-title">
          {{ propTitle }}
        </span>
        <div class="header-icons">
          <span @click="onHeaderClick" v-if="isCollapse"
            ><i
              :class="active ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
            ></i
          ></span>
        </div></div
    ></slot>
    <div class="accordion-content" :id="id" :class="active ? 'in' : ''">
      <div :id="id2">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
export default {
  components: {},
  props: {
    propTitle: {
      Type: String,
      default: "",
    },
    propActive: {
      type: Boolean,
      default: true,
    },
    propTimeOut: {
      type: Number,
      default: 100,
    },
    isChevronEnd: {
      type: Boolean,
      default: false,
    },
    isCollapse: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      id: uuidv4(),
      id2: uuidv4(),
      l: undefined,
      w: undefined,
      active: true,
    };
  },
  created() {
    this.active = true;
    if (!this.propActive) {
      setTimeout(() => {
        this.onHeaderClick();
      }, 200);
    }
  },
  mounted() {
    this.w = $("#" + this.id);
    this.l = $("#" + this.id2);
    this.w.height(this.l.outerHeight(true));
    setTimeout(() => {
      this.w.height(!this.active ? 0 : "auto");
      this.w.css("overflow", "visible");
    }, this.propTimeOut);
  },
  methods: {
    onHeaderClick() {
      var vm = this;
      if (this.w.hasClass("in")) {
        this.w.removeClass("in");
        this.w.height(0);
        this.active = false;
        vm.w.css("overflow", "hidden");
      } else {
        setTimeout(() => {
          vm.w.css("overflow", "visible");
          this.w.height("auto");
        }, 100);
        this.w.addClass("in");
        this.w.height(this.l.outerHeight(true));
        this.active = true;
      }
      this.$emit("accordionHeaderClick");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.accordion {
  background: white;
  padding: 0.5rem;
  .accordion-header {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    .header-title {
      font-weight: 550;
    }
    .header-icons {
      span {
        color: #009ef7;
        display: block;
        width: 24px;
        height: 24px;
        background: #f1faff;
        border-radius: 0.25rem;
        font-size: 0.75rem;
        display: grid;
        place-items: center;
        &:hover {
          cursor: pointer;
        }
      }
      &:hover {
        cursor: default;
      }
    }
  }
  .accordion-content {
    background-color: #ffffff;
    overflow: hidden;
    &.in {
      // padding-top: 0.75rem;
      transition: height 200ms;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
}
</style>
