<template>
  <Dialog
    :header="propTitle"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :maximizable="false"
    :modal="true"
    :contentStyle="propStyle"
    @hide="onClose"
  >
    <div>
      <div>
        <div class="form-group">
          <label for="inputName">{{ $t("label_image_name") }}</label>
          <p
            class="font-weight-bold"
            v-for="item in images4Sharing"
            :key="item.id"
          >
            {{ item.name }}
          </p>
        </div>
        <div class="form-group">
          <label
            >{{ $t("label_receiver") }}:&nbsp;<strong>{{
              propOrderBy.full_name
            }}</strong></label
          >
        </div>
        <div class="form-group">
          <label for="inputName"
            >{{ $t("label_content")
            }}<span class="required">&nbsp;*</span></label
          >
          <Textarea
            type="text"
            class="form-control"
            rows="2"
            v-model="entity.description"
            maxlength="255"
            autofocus
            :autoResize="true"
          ></Textarea>
        </div>
        <div class="form-row">
          <div class="col-6">
            <div class="form-group form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="checkPassword"
                v-model="entity.isPass"
              />
              <label class="form-check-label" for="checkPassword">{{
                $t("label_is_password")
              }}</label>
            </div>
            <div class="form-group" v-show="entity.isPass">
              <input
                type="password"
                class="form-control"
                id="inputPassword"
                autocomplete="off"
                v-model="entity.password"
              />
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <div class="form-group form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="checkExpiredTime"
                v-model="entity.isExpired"
              />
              <label class="form-check-label" for="checkExpiredTime">{{
                $t("label_is_expired_time")
              }}</label>
            </div>
            <div class="form-group" v-show="entity.isExpired">
              <label for="inputExpiredTime" class="w-100">{{
                $t("label_expired_date")
              }}</label>
              <date-picker
                v-model="entity.expiredTime"
                :format="'DD/MM/YYYY'"
                valueType="timestamp"
                :input-class="'form-control'"
                id="inputExpiredTime"
              >
              </date-picker>
            </div>
          </div>
          <div class="col">
            <div class="form-group form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="checkNumDownload"
                v-model="entity.isNum"
              />
              <label class="form-check-label" for="checkNumDownload">{{
                $t("label_is_number_download")
              }}</label>
            </div>
            <div class="form-group" v-show="entity.isNum">
              <label for="inputNumDownload">{{ $t("label_number") }}</label>
              <input
                type="number"
                class="form-control"
                id="inputNumDownload"
                v-model="entity.download_number"
                min="1"
                max="100"
                step="1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        :label="$t('button_text_close')"
        :icon="'fas fa-times'"
        :class="'p-button-danger  p-button-sm'"
        @click="onClose"
      />
      <Button
        :disabled="propButtonShare && propButtonShare.disabled"
        :label="$t('button_text_share')"
        :icon="
          propButtonShare && propButtonShare.processing
            ? 'fas fa-spinner fa-spin'
            : 'fas fa-check'
        "
        :class="'p-button-success p-button-sm mr-0'"
        @click="onShare"
      />
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
import Button from "@/components/commons/button/Button";
import DatePicker from "vue2-datepicker";
import Textarea from "@/components/commons/textarea/Textarea";
export default {
  components: { Dialog, Button, DatePicker, Textarea },
  props: {
    propItems: {
      type: Array,
      default: () => [],
    },
    propOrderBy: {
      type: Object,
      default: () => {},
    },
    propTitle: {
      type: String,
      default: "",
    },
    propStyle: {
      type: Object,
      default: () => {
        return {
          width: "480px",
        };
      },
    },
    propShow: {
      type: Boolean,
      default: false,
    },
    propButtonShare: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      entity: {
        description: null,
        isNum: false,
        isPass: false,
        isExpired: false,
        download_number: 10,
        expired_time: null,
        expiredTime: null,
        image_id: null,
        is_public: true,
        member_ids: [],
        password: null,
      },
    };
  },
  created() {
    this.show = this.propShow;
  },
  watch: {
    propShow: {
      handler(val) {
        this.show = val;
      },
      immediate: true,
    },
  },
  methods: {
    onClose() {
      this.show = false;
      this.$emit("close");
    },
    onShare() {
      this.$emit("onShare", this.entity);
    },
  },
  computed: {
    images4Sharing() {
      let results = [];
      if (this.propItems && this.propItems.length > 0) {
        for (let i = 0; i < this.propItems.length; i++) {
          if (results.findIndex((x) => x.id === this.propItems[i].id) < 0)
            results.push(this.propItems[i]);
        }
      }
      return results;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>