<template>
  <div id="notauthorized">
    <div class="notauthorized">
      <div class="notauthorized-401">
        <h3>Oops! Page not authorized</h3>
        <h1><span>4</span><span>0</span><span>1</span></h1>
      </div>
      <h6>
        {{
          $t("message_not_authorized_to_access_this_page", null, {
            full_name: userInfo.full_name,
            username: userInfo.username,
          })
        }}
      </h6>
      <a class="text-left login-again" @click="onLoginAgain"
        ><i class="fas fa-sign-in-alt"></i>&nbsp;{{
          $t("label_logout_and_login_with_anothoer_account")
        }}</a
      >
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    userInfo() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    onLoginAgain() {
      this.$store.dispatch("clearToken");
      this.$router.push({
        name: "Login",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#notauthorized {
  position: relative;
  height: calc(100vh - 4rem);
}

#notauthorized .notauthorized {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notauthorized {
  max-width: 520px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
}

.notauthorized .notauthorized-401 {
  position: relative;
  height: 240px;
}

.notauthorized .notauthorized-401 h1 {
  font-family: "Montserrat", sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 14rem;
  font-weight: 900;
  margin: 0px;
  color: #262626;
  text-transform: uppercase;
  letter-spacing: -40px;
  margin-left: -20px;
}

.notauthorized .notauthorized-401 h1 > span {
  text-shadow: -8px 0px 0px #fff;
}

.notauthorized .notauthorized-401 h3 {
  position: relative;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #262626;
  margin: 0px;
  letter-spacing: 3px;
  padding-left: 6px;
}

.notauthorized h6 {
  font-weight: 400;
  color: #000;
  margin-top: 0px;
  margin-bottom: 0.75rem;
}
a.login-again {
  font-size: 16px;
  color: #0d6efd;
  &:hover {
    cursor: pointer;
    color: #009ef7;
  }
}
@media only screen and (max-width: 767px) {
  .notauthorized .notauthorized-401 {
    height: 200px;
  }
  .notauthorized .notauthorized-401 h1 {
    font-size: 200px;
  }
}

@media only screen and (max-width: 480px) {
  .notauthorized .notauthorized-401 {
    height: 162px;
  }
  .notauthorized .notauthorized-401 h1 {
    font-size: 162px;
    height: 150px;
    line-height: 162px;
  }
  .notauthorized h2 {
    font-size: 16px;
  }
}
</style>