<template>
  <CommonAccordion
    :propTitle="$t('title_search')"
    @accordionHeaderClick="accordionHeaderClick"
  >
    <div slot="content">
      <div class="form-search">
        <div class="form-row no-gutters">
          <div class="col-12 col-md-4">
            <div class="form-group">
              <label for="inputSearchName">{{ $t("label_image_name") }}</label>
              <InputText
                id="inputSearchName"
                style="width: 100%"
                type="search"
                maxLength="255"
                v-model="search.image_name"
                class="new-style"
              />
            </div>
          </div>
          <div class="col-12 col-md-3">
            <label class="control-label">{{ $t("label_date_from") }}</label>
            <date-picker
              v-model="search.from"
              class="w-100"
              :format="'DD/MM/YYYY'"
              valuetype="timestamp"
              :disabled-date="notAfterToday"
              :lang="lang"
              input-class="form-control new-style"
            >
            </date-picker>
          </div>
          <div class="col-12 col-md-3">
            <label class="control-label">{{ $t("label_date_to") }}</label>
            <date-picker
              v-model="search.to"
              class="w-100"
              :format="'DD/MM/YYYY'"
              valuetype="timestamp"
              :disabled-date="notAfterToday"
              :lang="lang"
              input-class="form-control new-style"
            >
            </date-picker>
          </div>
          <div class="col-12 col-md-2">
            <button
              type="button"
              :label="$t('button_text_search')"
              :icon="'fas fa-search'"
              class="btn btn-geostore2 btn-sm btn-w120-h36"
              @click="onSearch"
            >
              <i
                :class="
                  propButtons &&
                  propButtons.filter &&
                  propButtons.filter.processing
                    ? 'fas fa-spinner fa-spin'
                    : 'fas fa-search'
                "
              ></i>
              &nbsp;{{ $t("button_text_search") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import InputText from "@/components/commons/inputtext/InputText";
import Button from "@/components/commons/button/Button";
import DatePicker from "vue2-datepicker";
export default {
  props: {
    propButtons: {
      type: Object,
      default: () => {},
    },
    propData: {
      type: Object,
      default: () => {},
    },
    propSearch: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    CommonAccordion,
    InputText,
    Button,
    DatePicker,
  },
  data() {
    return {
      search: {
        image_name: null,
        from: null,
        to: null,
      },
    };
  },
  created() {
    if (this.propSearch && Object.keys(this.propSearch).length > 0) {
      this.search.image_name = this.propSearch.image_name
        ? this.propSearch.image_name.trim()
        : null;
      this.search.from = this.propSearch.from ? this.propSearch.from : null;
      this.search.to = this.propSearch.to ? this.propSearch.to : null;
    }
  },
  watch: {
    propSearch(val) {
      if (this.propSearch && Object.keys(this.propSearch).length > 0) {
        this.search.image_name = this.propSearch.image_name
          ? this.propSearch.image_name.trim()
          : null;
        this.search.from = this.propSearch.from ? this.propSearch.from : null;
        this.search.to = this.propSearch.to ? this.propSearch.to : null;
      }
    },
  },
  methods: {
    accordionHeaderClick() {
      this.$emit("accordionHeaderClick");
    },
    onSearch() {
      if (!this.search.from && this.search.to) {
        this.$emit("message", [
          this.$t("message_search_from_time_is_required"),
        ]);
        return;
      } else if (this.search.from && !this.search.to) {
        this.$emit("message", [this.$t("message_search_to_time_is_required")]);
        return;
      } else if (this.search.from && this.search.to) {
        let from = new Date(this.search.from).setHours(0, 0, 0, 0);
        let to = new Date(this.search.to).setHours(23, 59, 59, 0);
        if (from - to > 0) {
          this.$emit("message", [
            this.$t(
              "message_search_to_time_must_greater_than_or_equal_from_time"
            ),
          ]);
          return;
        } else {
          this.$emit("onSearch", {
            from: from,
            to: to,
            image_name: this.search.image_name
              ? this.search.image_name.trim().replace(/\s\s+/g, " ")
              : null,
          });
        }
      } else {
        this.$emit("onSearch", {
          from: null,
          to: null,
          image_name: this.search.image_name
            ? this.search.image_name.trim().replace(/\s\s+/g, " ")
            : null,
        });
      }
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
.form-search {
  button {
    margin-top: 1.25rem;
    font-size: 14px;
  }
  label {
    margin: 0;
    padding: 0;
    padding-left: 0.25rem;
  }
}
.form-group {
  margin-bottom: 0.5rem;
  margin: 0;
  label {
    padding-left: 0.25rem;
  }
}
</style>
<style lang="scss">
.shared-by-me__search {
  .mx-input {
    border-color: #a6a6a6 !important;
    height: 33px;
    border-radius: 3px;
    &:hover {
      border-color: #212121 !important;
    }
    &:disabled,
    .disabled {
      background-color: transparent;
    }
  }
}
</style>