<template>
  <main-layout
    :isToolbar="true"
    :isBreadcrumb="true"
    :breadcrumbitems="[
      { label: $t('menu_category') },
      { label: $t('menu_satellite') },
    ]"
    :propButtonsState="{
      delete: buttons.delete,
      save: buttons.save,
      add: buttons.add,
      edit: buttons.edit,
      reload: buttons.reload,
      print: {
        visible: false,
      },
    }"
    :propTitle="$t('menu_satellite')"
    @emitFunction="menuBarFunction($event)"
  >
    <div class="satellite-content">
      <div class="satellite__dialog">
        <DialogMessage
          v-if="message.length > 0"
          :propTitle="$t('title_message_info')"
          :prop-show="message.length > 0"
          :prop-message="message"
          @close="message = []"
        />
        <DialogConfirmation
          v-if="confirmDelete.show"
          :prop-show="confirmDelete.show"
          :prop-title="$t('title_message_confirm')"
          :prop-icon="confirmDelete.icon"
          :prop-message="confirmDelete.message"
          :textNo="$t('button_text_close')"
          :textYes="$t('button_text_delete')"
          @cancel="onCancelConfirmDelete"
          @confirmed="onConfirmedDelete"
        />
      </div>
      <div class="satellite__datatable">
        <DataTable
          ref="satelliteDatatable"
          :propItems="satellite.items"
          :propTable="satellite.table"
          :propFirst="satellite.first"
          @rowClick="rowClick"
        >
        </DataTable>
      </div>
      <div class="satellite__structure">
        <ValidationObserver ref="satelliteObserver">
          <Structure
            :prop-entity="satellite.entity"
            :prop-status="{
              isAdd: satellite.isAdd,
              isEdit: satellite.isEdit,
            }"
          />
        </ValidationObserver>
        <div class="actions">
          <Button
            :icon="
              buttons.prev.processing
                ? 'fa fa-spinner fa-spin'
                : 'pi pi-chevron-left'
            "
            class="p-button-rounded p-button-secondary"
            :disabled="buttons.prev.disabled"
            @click="prevItem"
          />
          <Button
            :icon="
              buttons.next.processing
                ? 'fa fa-spinner fa-spin'
                : 'pi pi-chevron-right'
            "
            class="p-button-rounded p-button-secondary"
            :disabled="buttons.next.disabled"
            @click="nextItem"
          />
        </div>
      </div>
    </div>
  </main-layout>
</template>
<script>
import MainLayout from "@/components/pages/layouts/admin/Main";
import Structure from "./model/Structure.vue";
import DataTable from "./model/DataTable";
import metaKeyFunc from "@/utils/functions/metakey";
import Button from "@/components/commons/button/Button";
import DialogConfirmation from "@/components/commons/dialog/Confirmation";
import DialogMessage from "@/components/commons/dialog/MessageBox";
export default {
  components: {
    MainLayout,
    Structure,
    DataTable,
    Button,
    DialogMessage,
    DialogConfirmation,
  },
  data() {
    return {
      message: [],

      satellite: {
        items: [],
        loading: false,
        isAdd: false,
        isEdit: false,
        first: 0,
        table: {
          selected: null,
          page: 0,
          size:10
        },
        entity: {
          description: null,
          key: null,
          name: null,
          value: null,
          id: null,
        },
      },

      confirmDelete: {
        message: null,
        show: false,
        title: null,
        icon: "fas fa-question",
        value: false,
      },
      buttons: {
        prev: {
          disabled: true,
          processing: false,
          visible: true,
        },
        next: {
          disabled: true,
          processing: false,
          visible: true,
        },
        delete: {
          disabled: true,
          processing: false,
          visible: true,
        },
        save: {
          disabled: true,
          processing: false,
          visible: true,
        },
        edit: {
          disabled: true,
          processing: false,
          visible: true,
        },
        add: {
          disabled: false,
          processing: false,
          visible: true,
        },
        filter: {
          disabled: false,
          processing: false,
          visible: true,
        },
        reload: {
          disabled: false,
          processing: false,
          visible: true,
        },
      },
    };
  },
  created() {
    this.satellite.entity = this.generateEntity();
    this.getAllSatellite();
  },
  methods: {
    generateEntity() {
      return {
        description: null,
        key: null,
        name: null,
        value: null,
        id: null,
      };
    },

    entityFromSelected(data) {
      if (data && Object.keys(data).length > 0) {
        this.satellite.entity.id = data.id ? data.id : null;
        this.satellite.entity.key = data.key ? data.key : null;
        this.satellite.entity.name = data.name ? data.name : null;
        this.satellite.entity.description = data.description
          ? data.description
          : null;
        this.satellite.entity.value = data.value ? data.value : null;
      }
    },

    async prevItem() {
      if (this == undefined) return;
      if (this.$refs.satelliteDatatable) {
        let data = this.$refs.satelliteDatatable.$refs.dt.processedData;
        let selection = this.$refs.satelliteDatatable.$refs.dt.selection;
        if (!selection) return;
        let index = data.findIndex((x) => x.id === selection.id);
        this.buttons.prev.processing = true;
        this.buttons.prev.disabled = true;
        this.$store.dispatch("setSpinnerApp", {
          show: true,
        });
        await this.entityFromSelected(data[index - 1]);
        this.satellite.table.selected = data[index - 1];
        this.buttons.prev.processing = false;
        this.buttons.prev.disabled = false;

        if (index === 1) {
          this.buttons.prev.disabled = true;
          this.buttons.next.disabled = false;
        } else {
          this.buttons.next.disabled = false;
          this.buttons.prev.disabled = false;
        }

        this.satellite.table.page = Math.floor((index - 1) / 10);
        this.satellite.first = Math.floor((index - 1) / 10) * 10;
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
      }
    },
    async nextItem() {
      if (this == undefined) return;
      if (this.$refs.satelliteDatatable) {
        let data = this.$refs.satelliteDatatable.$refs.dt.processedData;
        let selection = this.$refs.satelliteDatatable.$refs.dt.selection;
        if (!selection) return;
        let index = data.findIndex((x) => x.id === selection.id);
        this.buttons.next.processing = true;
        this.buttons.next.disabled = true;
        this.$store.dispatch("setSpinnerApp", {
          show: true,
        });
        await this.entityFromSelected(data[index + 1]);
        this.satellite.table.selected = data[index + 1];
        this.buttons.next.processing = false;
        this.buttons.next.disabled = false;

        if (index === data.length - 2) {
          this.buttons.prev.disabled = false;
          this.buttons.next.disabled = true;
        } else {
          this.buttons.prev.disabled = false;
          this.buttons.next.disabled = false;
        }
        this.satellite.table.page = Math.floor((index + 1) / 10);
        this.satellite.first = Math.floor((index + 1) / 10) * 10;
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
      }
    },
    /* ---------- Emit ----------*/
    async rowClick(val) {
      this.satellite.isAdd = false;
      this.satellite.isEdit = false;
      this.buttons.add.disabled = false;
      this.buttons.add.processing = false;
      this.buttons.edit.disabled = false;
      this.buttons.edit.processing = false;
      this.buttons.delete.disabled = false;
      this.buttons.delete.processing = false;
      this.buttons.save.disabled = true;
      this.buttons.save.processing = false;
      if (val.index === 0) {
        this.buttons.prev.disabled = true;
        if (this.satellite.items.length > 1) this.buttons.next.disabled = false;
        else this.buttons.next.disabled = true;
      } else if (val.index === this.satellite.items.length - 1) {
        if (this.satellite.items.length > 1) this.buttons.prev.disabled = false;
        else this.buttons.prev.disabled = true;
        this.buttons.next.disabled = true;
      } else {
        this.buttons.prev.disabled = false;
        this.buttons.next.disabled = false;
      }
      if (val.data) {
        this.$store.dispatch("setSpinnerApp", {
          show: true,
        });
        this.satellite.table.selected = val.data;
        this.satellite.entity = this.generateEntity();
        await this.entityFromSelected(val.data);
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
      }
    },

    async menuBarFunction(val) {
      switch (val.code) {
        case "SAVE":
          this.onSave();
          break;
        case "ADD":
          this.satellite.isEdit = false;
          this.satellite.isAdd = true;
          this.buttons.edit.disabled = true;
          this.buttons.edit.processing = false;
          this.buttons.save.disabled = false;
          this.buttons.save.processing = false;
          this.satellite.table.selected = null;
          this.buttons.delete.disabled = true;
          this.buttons.delete.processing = false;
          this.satellite.entity = this.generateEntity();
          break;
        case "EDIT":
          this.satellite.isEdit = true;
          this.satellite.isAdd = false;
          this.buttons.add.disabled = false;
          this.buttons.add.processing = false;
          this.buttons.save.disabled = false;
          this.buttons.save.processing = false;
          break;
        case "FILTER":
          break;
        case "RELOAD":
          this.buttons.reload.processing = true;
          if (!this.cancel) await this.onReload();
          this.buttons.reload.processing = false;
          break;
        case "DELETE":
          this.confirmDelete.value = this.satellite.entity.id;
          this.confirmDelete.message = this.$t(
            "message_confirm_delete_satellite",
            null,
            {
              name: this.satellite.entity.name
                ? this.satellite.entity.name.trim()
                : "",
            }
          );
          this.confirmDelete.show = true;
          break;
        default:
          break;
      }
    },
    onCancelConfirmDelete() {
      this.confirmDelete.show = false;
      this.confirmDelete.message = null;
      this.confirmDelete.value = null;
    },
    async onConfirmedDelete() {
      this.buttons.delete.disabled = true;
      this.buttons.delete.processing = true;
      this.$store.dispatch("setSpinnerApp", {
        show: true,
      });
      try {
        let response = await metaKeyFunc.delete(
          this.confirmDelete.value,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.$toast.success({
            title: this.$t("title_message_info"),
            message: this.$t("message_delete_satellite_success", null, {
              name: this.satellite.entity.name
                ? this.satellite.entity.name.trim()
                : "",
            }),
            position: "top right",
          });
          this.onCancelConfirmDelete();
          this.getAllSatellite();
        } else {
          this.message.push(
            this.$t("message_delete_satellite_error", null, {
              name: this.satellite.entity.name
                ? this.satellite.entity.name.trim()
                : "",
            })
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$store.dispatch("setSpinnerApp", {
            show: false,
          });
          await this.refreshToken(this.onConfirmedDelete);
        } else {
          let msg =
            error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push(
            msg
              ? msg
              : this.$t("message_delete_satellite_error", null, {
                  name: this.satellite.entity.name
                    ? this.satellite.entity.name.trim()
                    : "",
                })
          );
        }
      }
      this.$store.dispatch("setSpinnerApp", {
        show: false,
      });
      this.buttons.delete.processing = false;
    },

    async onReload() {
      await this.getAllSatellite();
    },
    /* -------------- End emit -----------------*/
    /* ---------- Put, Post -----------*/
    async onSave() {
      this.message = [];
      var vm = this;
      this.$refs.satelliteObserver.validate().then(async (success) => {
        if (!success) {
          setTimeout(() => {
            Object.keys(vm.$refs.satelliteObserver.errors).forEach(function (
              key
            ) {
              if (
                vm.$refs.satelliteObserver.errors[key] &&
                vm.$refs.satelliteObserver.errors[key].length
              )
                vm.message.push(
                  vm.$refs.satelliteObserver.errors[key].join(",\n")
                );
            });
          }, 100);
          return;
        } else {
          this.buttons.save.processing = true;
          this.buttons.save.disabled = true;
          this.buttons.add.disabled = true;
          this.buttons.edit.disabled = true;
          this.buttons.reload.disabled = true;
          //this.buttons.delete.disabled = true;
          this.$store.dispatch("setSpinnerApp", {
            show: true,
          });
          let dto = {
            description: this.satellite.entity.description
              ? this.satellite.entity.description.trim().replace(/\s\s+/g, " ")
              : null,
            key: "SATELLITE",
            name: this.satellite.entity.name
              ? this.satellite.entity.name.trim().replace(/\s\s+/g, " ")
              : null,
            sub_metakey_ids: [],
            value: this.satellite.entity.value
              ? this.satellite.entity.value.trim().replace(/\s\s+/g, " ")
              : null,
            id: this.satellite.entity.id ? this.satellite.entity.id : null,
          };
          let response;
          try {
            if (!dto.id) {
              // Add new
              response = await metaKeyFunc.create(
                dto,
                this.$store.getters.getAccessToken
              );
            } else {
              // Edit
              response = await metaKeyFunc.update(
                dto,
                this.$store.getters.getAccessToken
              );
            }
            if (
              response &&
              (response.status === 201 ||
                response.status === 200 ||
                response.status === 204)
            ) {
              // if (this.satellite.isEdit && this.satellite.entity.id) {
              //   let index = this.satellite.items.findIndex(
              //     (x) => x.id === this.satellite.entity.id
              //   );
              //   if (index >= 0) {
              //     this.satellite.items[index].name = dto.name;
              //     this.satellite.items[index].key = dto.key;
              //     this.satellite.items[index].description = dto.description;
              //     this.satellite.items[index].value = dto.value;
              //   }
              // } else {
              //   this.satellite.items.push({
              //     description: dto.description,
              //     key: "SATELLITE",
              //     name: dto.name,
              //     value: dto.value,
              //     id:
              //       response.data && response.data.data && response.data.data.id
              //         ? response.data.data.id
              //         : null,
              //   });
              // }
              this.$toast.success({
                title: this.$t("title_message_info"),
                message: this.$t("message_save_satellite_success", null, {
                  txt:
                    this.satellite.isEdit && this.satellite.entity.id
                      ? this.$t("text_save")
                      : this.$t("text_add_new"),
                  name: dto.name,
                }),
                position: "top right",
              });
              this.getAllSatellite();
              this.buttons.save.disabled = true;
              this.buttons.save.processing = false;
              this.buttons.add.disabled = false;
              this.buttons.add.processing = false;
              this.buttons.edit.disabled = true;
              this.buttons.edit.processing = false;
              this.satellite.isAdd = false;
              this.satellite.isEdit = false;
              this.satellite.table.selected = null;
              this.satellite.entity = this.generateEntity();
            }
          } catch (error) {
            if (error.response && error.response.status === 401) {
              this.$store.dispatch("setSpinnerApp", {
                show: false,
              });
              await this.refreshToken(this.onSave);
            } else {
              this.buttons.save.processing = false;
              this.buttons.save.disabled = false;
              this.buttons.edit.disabled = !this.satellite.isEdit;
              this.buttons.add.disabled = false;
              this.buttons.reload.disabled = false;
              let msg =
                error.response.data && error.response.data.message
                  ? error.response.data.message
                  : null;
              this.message.push(
                msg
                  ? msg
                  : this.$t("message_save_satellite_error", null, {
                      txt:
                        this.satellite.isEdit && this.satellite.entity.id
                          ? this.$t("text_save")
                          : this.$t("text_add_new"),
                      name: dto.name,
                    })
              );
            }
          }
          this.$store.dispatch("setSpinnerApp", {
            show: false,
          });
        }
      });
    },
    /*--------------- Get data ------------------*/
    async getAllSatellite() {
      this.satellite.items = [];
      this.satellite.entity = this.generateEntity();
      this.satellite.table.selected = null;
      this.satellite.isAdd = false;
      this.satellite.isEdit = false;
      this.buttons.filter.processing = true;
      this.buttons.filter.disabled = true;
      this.buttons.reload.disabled = true;
      this.buttons.reload.processing = true;
      this.buttons.delete.disabled = true;
      this.buttons.delete.processing = false;
      this.buttons.save.processing = false;
      this.buttons.save.disabled = true;
      this.buttons.add.disabled = false;
      this.buttons.add.processing = false;
      this.buttons.edit.disabled = true;
      this.buttons.edit.processing = false;
      try {
        let response = await metaKeyFunc.getByKey(
          "SATELLITE",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.satellite.items =
            response.data && response.data.data ? response.data.data : [];

          this.satellite.items.sort(function (a, b) {
            if (a.name < b.name) return -1;
            else if (a.name > b.name) return 1;
            return 0;
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.getAllSatellite);
        }
      }
      this.buttons.reload.disabled = false;
      this.buttons.reload.processing = false;
      this.buttons.filter.processing = false;
      this.buttons.filter.disabled = false;
    },

    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
    /*--------------- End get data -----------------*/
  },
};
</script>
<style lang="scss" scoped>
.satellite-content {
  padding: 0.5rem;
  padding-top: 0;
  .satellite__datatable {
    padding: 0;
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .satellite__structure {
    padding: 0;
    margin-top: 0.5rem;
    position: relative;
    .accordion {
      border-radius: 0.5rem;
    }
    .actions {
      position: absolute;
      right: 2.5rem;
      top: 0.6125rem;
      button {
        border-radius: 50% !important;
        background: #009ef7;
        color: white;
        margin-right: 0.25rem;
        width: 1.6125rem;
        height: 1.6125rem;
        padding: 0.25rem;
        &:last-child {
          margin-right: 0;
        }
        &:disabled {
          background: #f4f4f4;
          color: #009ef7;
        }
        .pi {
          font-size: 0.75rem !important;
        }
      }
    }
  }
}
</style>