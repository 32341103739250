var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-layout',{attrs:{"isToolbar":false,"isBreadcrumb":true,"breadcrumbitems":[
    { label: _vm.$t('menu_order_management') },
    { label: _vm.$t('menu_order_tasking') } ],"propButtonsState":{
    save: {
      visible: false,
    },
    add: {
      visible: false,
    },
    edit: {
      visible: false,
    },
    reload: _vm.buttons.reload,
    print: {
      visible: false,
    },
  },"propTitle":_vm.$t('menu_order_tasking')}},[_c('div',{staticClass:"order-content"},[_c('div',{staticClass:"order__dialog"},[(_vm.dlgOrderDetailEgeo.show)?_c('DialogOrderDetailEgeo',{attrs:{"prop-style":{ width: _vm.dlgOrderDetailEgeo.width },"prop-show":_vm.dlgOrderDetailEgeo.show,"prop-title":_vm.dlgOrderDetailEgeo.title,"prop-item":_vm.dlgOrderDetailEgeo.value,"propGranularities":_vm.granularity.items,"propLookDirection":_vm.lookDirection.items,"propOrbitDirection":_vm.orbitDirection.items,"propProgramingServices":_vm.programingService.items,"propSatelliteType":_vm.satelliteType.items},on:{"close":function($event){_vm.dlgOrderDetailEgeo.show = false;
          _vm.dlgOrderDetailEgeo.value = null;},"getAllDirection":_vm.getAllDirection,"getAllTaskingProgrammingService":_vm.getAllTaskingProgrammingService,"getAllTaskingGranularity":_vm.getAllTaskingGranularity,"getAllSatelliteType":_vm.getAllSatelliteType}}):_vm._e(),(_vm.dlgImageList.show)?_c('DialogImageList',{attrs:{"prop-style":{ width: _vm.dlgImageList.width },"prop-show":_vm.dlgImageList.show,"prop-title":_vm.dlgImageList.title,"prop-items":_vm.dlgImageList.value.images,"propImageTypes":_vm.satelliteType.items},on:{"close":function($event){_vm.dlgImageList.show = false;
          _vm.dlgImageList.value = null;},"getAllSatelliteType":_vm.getAllSatelliteType,"showDialogMetadata":_vm.showDialogMetadata}}):_vm._e(),(_vm.dialogMetadata.show)?_c('DialogMetadata',{attrs:{"prop-style":{ width: _vm.dialogMetadata.width },"prop-show":_vm.dialogMetadata.show,"prop-title":_vm.dialogMetadata.title,"prop-item":_vm.dialogMetadata.value},on:{"close":function($event){_vm.dialogMetadata.show = false;
          _vm.dialogMetadata.value = null;}}}):_vm._e(),(_vm.dlgUpdateStatus.show)?_c('DialogUpdateStatus',{attrs:{"prop-style":{
          width: _vm.dlgUpdateStatus.width,
          overflowY: 'inherit!important',
        },"prop-show":_vm.dlgUpdateStatus.show,"prop-title":_vm.dlgUpdateStatus.title,"prop-tab-id":_vm.dlgUpdateStatus.value.current_status
            ? _vm.dlgUpdateStatus.value.current_status.id
            : null,"prop-button":_vm.buttons.update_status,"prop-item":_vm.dlgUpdateStatus.value,"propOrderStatus":_vm.orderStatus.items},on:{"close":function($event){_vm.dlgUpdateStatus.show = false;
          _vm.dlgUpdateStatus.value = null;},"onUpdateStatus":_vm.onUpdateStatus}}):_vm._e(),(_vm.dlgDialogRemark.show)?_c('DialogRemark',{attrs:{"prop-style":{
          width: _vm.dlgDialogRemark.width,
        },"prop-show":_vm.dlgDialogRemark.show,"prop-title":_vm.dlgDialogRemark.title,"prop-value":_vm.dlgDialogRemark.value},on:{"close":function($event){_vm.dlgDialogRemark.show = false;
          _vm.dlgDialogRemark.value = null;
          _vm.dlgDialogRemark.title = null;}}}):_vm._e(),(_vm.dialogUploadFile.show)?_c('DialogUploadImages',{ref:"dlgUploadImages",attrs:{"prop-style":{ width: _vm.dialogUploadFile.width },"prop-show":_vm.dialogUploadFile.show,"prop-title":_vm.dialogUploadFile.title,"prop-value":_vm.dialogUploadFile.value},on:{"close":function($event){_vm.dialogUploadFile.show = false;
          _vm.dialogUploadFile.value = null;
          _vm.files = [];
          _vm.filesUploaded.success = [];
          _vm.filesUploaded.error = [];},"uploadFiles":_vm.onUploadFiles,"message":function($event){_vm.message = $event}}}):_vm._e(),(_vm.dlgAddImages.show)?_c('DialogAddImages',{attrs:{"prop-show":_vm.dlgAddImages.show,"prop-images":_vm.dlgAddImages.value.images,"prop-style":{
          width: _vm.dlgAddImages.width,
        }},on:{"close":function($event){_vm.dlgAddImages.show = false;
          _vm.dlgAddImages.value = null;},"selected":_vm.onUpdateImagesAdded}}):_vm._e(),(_vm.message.length > 0)?_c('DialogMessage',{attrs:{"propTitle":_vm.$t('title_message_info'),"prop-show":_vm.message.length > 0,"prop-message":_vm.message},on:{"close":function($event){_vm.message = []}}}):_vm._e()],1),_c('div',{staticClass:"order__search",attrs:{"id":"elementSearch"}},[_c('OrderSearch',{attrs:{"prop-user":_vm.user.items,"prop-order-status":_vm.orderStatus.items,"prop-search":_vm.order.search,"propButtons":_vm.buttons},on:{"onSearch":_vm.onSearch,"message":function($event){_vm.message = $event},"accordionHeaderClick":_vm.accordionHeaderClick}})],1),_c('div',{staticClass:"order__datatable"},[_c('OrderDataTable',{attrs:{"prop-items":_vm.order.items,"prop-total":_vm.order.total_records,"prop-loading":_vm.order.loading,"prop-table":_vm.order.table,"propButtonDetail":_vm.order.table.buttons.detail},on:{"nextPage":_vm.nextPage,"showOrderDetail":_vm.showOrderDetail,"showImageList":_vm.showImageList,"shoDialogUpdateStatus":_vm.shoDialogUpdateStatus,"shoDialogRemark":_vm.shoDialogRemark,"shoDialogUpload":_vm.shoDialogUpload,"shoDialogAddImages":_vm.shoDialogAddImages}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }