<template>
  <CommonAccordion
    :propTitle="$t('title_search')"
    @accordionHeaderClick="accordionHeaderClick"
  >
    <div slot="content">
      <div class="form-search">
        <div class="form-row no-gutters">
          <div class="col-12 col-md-4">
            <label>{{ $t("label_customer_name") }}</label>
            <InputText
              class="w-100 new-style"
              :type="'search'"
              v-model="search.name"
              @click="inputClick"
              maxlength="150"
            />
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <label>{{ $t("label_status") }}</label
            >&nbsp;
            <Dropdown
              class="w-100 new-style"
              v-model="search.is_active"
              :showClear="false"
              :options="status"
              optionValue="id"
              optionLabel="label"
            />
          </div>
          <div class="col-12 col-sm-6 col-md-2">
            <button
              @click="onSearch"
              :disabled="propButtons.filter.disabled"
              type="button"
              :label="$t('button_text_search')"
              :icon="
                propButtons.filter.processing
                  ? 'fas fa-spinner fa-spin'
                  : 'fas fa-search'
              "
              class="btn btn-geostore2 btn-sm btn-w120-h36"
            >
              <i
                :class="
                  propButtons.filter.processing
                    ? 'fas fa-spinner fa-spin'
                    : 'fas fa-search'
                "
              ></i
              >&nbsp;
              {{ $t("button_text_search") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import InputText from "@/components/commons/inputtext/InputText";
import Button from "@/components/commons/button/Button";
export default {
  props: {
    propButtons: {
      type: Object,
      default: () => {},
    },
    propSearch: {
      type: Object,
      default: () => {
        return {
          name: null,
          is_active: true,
        };
      },
    },
  },
  components: {
    CommonAccordion,
    Dropdown,
    Button,
    InputText,
  },
  data() {
    return {
      normalizer(node) {
        return {
          id: node.code,
        };
      },
      status: [
        {
          id: "1",
          code: "label_active",
          label: "",
        },
        {
          id: "0",
          code: "label_inactive",
          label: "",
        },
        {
          id: "2",
          code: "label_all",
          label: "",
        },
      ],
      search: {
        name: null,
        is_active: null,
      },
    };
  },
  created() {
    this.initStatus();
    if (this.propSearch && Object.keys(this.propSearch).length > 0) {
      this.search.name = this.propSearch.name ? this.propSearch.name : null;
      this.search.is_active = this.propSearch.is_active
        ? this.propSearch.is_active
        : "2";
    }
  },
  watch: {
    lang(val) {
      this.initStatus();
    },
    propSearch(val) {
      if (this.propSearch && Object.keys(this.propSearch).length > 0) {
        this.search.name = this.propSearch.name ? this.propSearch.name : null;
        if (this.this.search.is_active.is_active === null)
          this.search.is_active = "2";
        else this.search.is_active = this.propSearch.is_active;
      }
    },
  },
  methods: {
    accordionHeaderClick() {
      this.$emit("accordionHeaderClick");
    },
    inputClick(e) {
      try {
        //e.target.select();
      } catch (error) {}
    },
    clearText() {
      this.search.name = null;
    },
    onSearch() {
      this.$emit("onSearch", this.search);
    },
    initStatus() {
      for (let i = 0; i < this.status.length; i++) {
        this.status[i].label = this.$t(this.status[i].code);
      }
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
.form-search {
  button {
    margin-top: 1.25rem;
    font-size: 14px;
  }
  label {
    margin: 0;
    padding: 0;
    padding-left: 0.25rem;
  }
}
</style>
