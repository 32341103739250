<template>
  <main-layout
    :isToolbar="true"
    :isBreadcrumb="true"
    :breadcrumbitems="[
      { label: $t('menu_administration') },
      { label: $t('menu_management_role_user') },
    ]"
    :propButtonsState="{
      save: buttons.save,
      add: buttons.add,
      edit: buttons.edit,
      reload: buttons.reload,
      print: {
        visible: false,
      },
      delete: buttons.delete,
    }"
    :propTitle="$t('menu_management_role_user')"
    @emitFunction="menuBarFunction($event)"
  >
    <div class="authority-content">
      <div class="authority-content__dialog">
        <div class="dialog-permissions" v-if="dialog.permission.show">
          <AddPermission
            :prop-show="dialog.permission.show"
            :prop-name="role.entity.description"
            :prop-items="permission.items"
            :prop-selected="role.entity.permissions"
            @close="dialog.permission.show = false"
            @getAllPermission="getAllPermission"
            @selected="selectedPermissions"
          />
        </div>
        <div class="dialog-confirm" v-if="confirm.show">
          <DialogConfirmation
            :prop-show="confirm.show"
            :prop-title="confirm.title"
            :prop-icon="confirm.icon"
            :prop-message="confirm.message"
            :textNo="$t('button_text_close')"
            :textYes="$t('button_text_save')"
            @cancel="onCancelConfirm"
            @confirmed="onConfirmed"
          />
        </div>
        <DialogConfirmation
          v-if="confirmDelete.show"
          :prop-show="confirmDelete.show"
          :prop-title="$t('title_message_confirm')"
          :prop-icon="confirmDelete.icon"
          :prop-message="confirmDelete.message"
          :textNo="$t('button_text_close')"
          :textYes="$t('button_text_delete')"
          @cancel="onCancelConfirmDelete"
          @confirmed="onConfirmedDelete"
        />

        <div class="dialog-message" v-if="message.length > 0">
          <DialogMessage
            v-if="message.length > 0"
            :propTitle="$t('title_message_info')"
            :prop-show="message.length > 0"
            :prop-message="message"
            @close="message = []"
          />
        </div>
      </div>
      <div class="authority-content__datatable">
        <DataTable
          ref="roleDatatable"
          :propData="{
            items: role.items,
            page_number: role.page_number,
            page_size: role.page_size,
            total_page: role.total_page,
            total_records: role.total_records,
            permissions: role.permissions,
          }"
          :prop-table="role.table"
          :prop-first="role.first"
          @rowClick="rowClick"
        />
      </div>
      <div class="authority-content__structure">
        <ValidationObserver ref="roleObserver">
          <Structure
            :prop-entity="role.entity"
            :prop-status="{
              isAdd: role.isAdd,
              isEdit: role.isEdit,
            }"
            :propInterfaces="interfaces"
            @selectedInterfaces="role.entity.selectedInterfaces = $event"
            @showDialogPermission="dialog.permission.show = true"
          />
        </ValidationObserver>
        <div class="actions">
          <Button
            @click="prevItem"
            :icon="
              buttons.prev.processing
                ? 'fa fa-spinner fa-spin'
                : 'pi pi-chevron-left'
            "
            class="p-button-rounded p-button-secondary"
            :disabled="buttons.prev.disabled"
          />
          <Button
            @click="nextItem"
            :icon="
              buttons.next.processing
                ? 'fa fa-spinner fa-spin'
                : 'pi pi-chevron-right'
            "
            class="p-button-rounded p-button-secondary"
            :disabled="buttons.next.disabled"
          />
        </div>
      </div>
    </div>
  </main-layout>
</template>
<script>
import MainLayout from "@/components/pages/layouts/admin/Main";
import DataTable from "./DataTable";
import Structure from "./Structure";
import Button from "@/components/commons/button/Button";
import AddPermission from "./AddPermission";
import DialogMessage from "@/components/commons/dialog/MessageBox";
import DialogConfirmation from "@/components/commons/dialog/Confirmation";
import authorityFunc from "@/utils/functions/authority";
import roleFunc from "@/utils/functions/role";
import permissionFunc from "@/utils/functions/permission";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import interfaces from "@/utils/commons/interfaces";
export default {
  components: {
    MainLayout,
    DataTable,
    Structure,
    Button,
    AddPermission,
    DialogMessage,
    DialogConfirmation,
  },
  data() {
    return {
      message: [],
      confirm: {
        message: null,
        show: false,
        title: this.$t("title_message_confirm"),
        icon: "fas fa-question",
        callback: {
          no: {
            method: null,
            params: null,
          },
          yes: {
            method: null,
            params: null,
          },
        },
        initValue: [],
        value: false,
      },
      confirmDelete: {
        message: null,
        show: false,
        title: null,
        icon: "fas fa-question",
        value: false,
      },
      permission: {
        items: [],
      },
      role: {
        items: [],
        isAdd: false,
        isEdit: false,
        page_number: 0,
        page_size: 10000,
        total_page: 0,
        total_records: 0,
        first: -1,
        entity: {
          id: null,
          name: null,
          description: null,
          is_active: null,
          permissions: [],
          urls: [],
          selectedInterfaces: null,
          translation: {
            vi: {
              description: null,
            },
            en: {
              description: null,
            },
          },
        },
        table: {
          selected: null,
          page: 0,
          size:10
        },
      },
      dialog: {
        permission: {
          show: false,
        },
      },
      buttons: {
        prev: {
          disabled: true,
          processing: false,
          visible: true,
        },
        next: {
          disabled: true,
          processing: false,
          visible: true,
        },
        save: {
          disabled: true,
          processing: false,
          visible: true,
        },
        edit: {
          disabled: true,
          processing: false,
          visible: true,
        },
        add: {
          disabled: false,
          processing: false,
          visible: true,
        },
        filter: {
          disabled: false,
          processing: false,
          visible: true,
        },
        reload: {
          disabled: false,
          processing: false,
          visible: true,
        },
        delete: {
          disabled: true,
          processing: false,
          visible: true,
        },
      },
      interfaces: interfaces.interfaces,
    };
  },
  created() {
    this.getAllRole();
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    accessToken() {
      return this.$store.getters.getAccessToken;
    },
  },
  methods: {
    generateEntity() {
      return {
        id: null,
        name: null,
        description: null,
        is_active: true,
        permissions: [],
        urls: [],
        selectedInterfaces: null,
        translation: {
          vi: {
            description: null,
          },
          en: {
            description: null,
          },
        },
      };
    },

    entityFromSelected(data) {
      if (data && Object.keys(data).length > 0) {
        this.role.entity.id = data.id ? data.id : null;
        this.role.entity.name = data.name ? data.name : null;
        this.role.entity.description = data.description
          ? data.description
          : null;
        this.role.entity.permissions = data.permissions ? data.permissions : [];
        this.role.entity.urls = data.urls ? data.urls : [];
        this.role.entity.selectedInterfaces =
          this.role.entity.urls && this.role.entity.urls.length > 0
            ? this.role.entity.urls
            : [];
        this.role.entity.is_active = data.is_active ? data.is_active : null;

        this.role.entity.translation = data.translation
          ? data.translation
          : null;
        if (
          !this.role.entity.translation ||
          Object.keys(this.role.entity.translation).length === 0
        ) {
          this.role.entity.translation = {
            vi: {
              description: this.role.entity.description,
            },
            en: {
              description: null,
            },
          };
        } else {
          if (
            !this.role.entity.translation.vi ||
            Object.keys(this.role.entity.translation.vi).length === 0
          ) {
            this.role.entity.translation.vi = {
              description: null,
            };
          }
          if (
            !this.role.entity.translation.en ||
            Object.keys(this.role.entity.translation.en).length === 0
          ) {
            this.role.entity.translation.en = {
              description: null,
            };
          }
        }
      }
    },
    // return false. Entity chua bi thay doi
    // return true. Entity da bi thay doi
    checkEntity() {
      var vm = this,
        result = false;
      for (const [key, value] of Object.entries(this.role.entity)) {
        if (value != null) {
          switch (typeof value) {
            case "number":
              if (value > 0) result = true;

              break;
            case "string":
              if (value.trim()) result = true;
              break;
            case "undefined":
              break;
          }
        }
        if (result) break;
      }
      return result;
    },

    async prevItem() {
      if (this == undefined) return;
      if (this.$refs.roleDatatable) {
        let data = this.$refs.roleDatatable.$refs.dt.processedData;
        let selection = this.$refs.roleDatatable.$refs.dt.selection;
        if (!selection) return;
        let index = data.findIndex((x) => x.id === selection.id);
        this.buttons.prev.processing = true;
        this.buttons.prev.disabled = true;
        //data[index + 1]
        this.$store.dispatch("setSpinnerApp", {
          show: true,
        });
        await this.entityFromSelected(data[index - 1]);
        this.role.table.selected = data[index - 1];
        this.buttons.prev.processing = false;
        this.buttons.prev.disabled = false;

        if (index === 1) {
          this.buttons.prev.disabled = true;
          this.buttons.next.disabled = false;
        } else {
          this.buttons.next.disabled = false;
          this.buttons.prev.disabled = false;
        }

        this.role.table.page = Math.floor((index - 1) / 10);
        this.role.first = Math.floor((index - 1) / 10) * 10;
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
      }
    },
    async nextItem() {
      if (this == undefined) return;
      if (this.$refs.roleDatatable) {
        let data = this.$refs.roleDatatable.$refs.dt.processedData;
        let selection = this.$refs.roleDatatable.$refs.dt.selection;
        if (!selection) return;
        let index = data.findIndex((x) => x.id === selection.id);
        this.buttons.next.processing = true;
        this.buttons.next.disabled = true;
        this.$store.dispatch("setSpinnerApp", {
          show: true,
        });
        await this.entityFromSelected(data[index + 1]);
        this.role.table.selected = data[index + 1];
        this.buttons.next.processing = false;
        this.buttons.next.disabled = false;

        if (index === data.length - 2) {
          this.buttons.prev.disabled = false;
          this.buttons.next.disabled = true;
        } else {
          this.buttons.prev.disabled = false;
          this.buttons.next.disabled = false;
        }
        this.role.table.page = Math.floor((index + 1) / 10);
        this.role.first = Math.floor((index + 1) / 10) * 10;
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
      }
    },
    /* ---------- Emit ----------*/
    async rowClick(val) {
      this.role.isAdd = false;
      this.role.isEdit = false;
      this.buttons.add.disabled = false;
      this.buttons.add.processing = false;
      this.buttons.edit.disabled = false;
      this.buttons.edit.processing = false;
      this.buttons.save.disabled = true;
      this.buttons.save.processing = false;
      this.buttons.delete.disabled = false;
      this.buttons.delete.processing = false;
      if (val.index === 0) {
        this.buttons.prev.disabled = true;
        if (this.role.items.length > 1) this.buttons.next.disabled = false;
        else this.buttons.next.disabled = true;
      } else if (val.index === this.role.items.length - 1) {
        if (this.role.items.length > 1) this.buttons.prev.disabled = false;
        else this.buttons.prev.disabled = true;
        this.buttons.next.disabled = true;
      } else {
        this.buttons.prev.disabled = false;
        this.buttons.next.disabled = false;
      }
      if (val.data) {
        this.$store.dispatch("setSpinnerApp", {
          show: true,
        });
        this.role.table.selected = val.data;
        this.role.entity = this.generateEntity();
        await this.entityFromSelected(val.data);
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
      }
    },

    async menuBarFunction(val) {
      switch (val.code) {
        case "SAVE":
          if (this.role.entity.id && this.role.isAdd) {
            let check = this.checkEntity();
            if (check) {
              try {
                if (this.role.isAdd)
                  this.confirm.message =
                    +this.$t("label_add_role") +
                    " " +
                    (this.role.entity.description
                      ? this.role.entity.description.trim()
                      : "") +
                    "?";
                else
                  this.confirm.message =
                    +this.$t("label_save_role") +
                    " " +
                    (this.role.entity.description.trim()
                      ? this.role.entity.description
                      : "") +
                    "?";
                this.confirm.callback.yes.method = "onSave";
                this.confirm.callback.yes.params = null;
                this.confirm.callback.no.method = null;
                this.confirm.callback.no.params = null;
                this.confirm.initValue.cancel = [];
                this.confirm.initValue.confirmed = [
                  {
                    variable: "role",
                    key: "isAdd",
                    value: false,
                  },
                ];
                this.confirm.show = true;
                return;
              } catch (error) {
                return;
              }
            }
          }
          this.onSave();
          break;
        case "ADD":
          this.role.isEdit = false;
          this.role.isAdd = true;
          this.buttons.edit.disabled = true;
          this.buttons.edit.processing = false;
          this.buttons.save.disabled = false;
          this.buttons.save.processing = false;
          this.buttons.delete.disabled = true;
          this.buttons.delete.processing = false;
          this.role.table.selected = null;
          this.role.entity = this.generateEntity();
          break;
        case "EDIT":
          this.role.isEdit = true;
          this.role.isAdd = false;
          this.buttons.add.disabled = false;
          this.buttons.add.processing = false;
          this.buttons.save.disabled = false;
          this.buttons.save.processing = false;
          break;
        case "FILTER":
          break;
        case "RELOAD":
          this.buttons.filter.disabled = true;
          this.buttons.filter.processing = true;
          this.buttons.reload.processing = true;
          this.buttons.reload.disabled = true;
          if (!this.cancel) await this.onFilter();
          this.buttons.reload.processing = false;
          this.buttons.reload.disabled = false;
          this.buttons.filter.disabled = false;
          this.buttons.filter.processing = false;
          break;
        case "DELETE":
          this.confirmDelete.value = this.role.entity.id;
          this.confirmDelete.message = this.$t(
            "message_confirm_delete_role",
            null,
            {
              name: this.role.entity.description
                ? this.role.entity.description.trim()
                : "",
            }
          );
          this.confirmDelete.show = true;
          break;
        default:
          break;
      }
    },
    selectedPermissions(val) {
      if (this.role.entity.permissions) {
        for (let i = 0; i < val.length; i++) {
          let index = this.role.entity.permissions.findIndex(
            (x) => x.id === val[i].id
          );
          if (index < 0) {
            val[i].is_delete = false;
            val[i].is_add = true;
            this.role.entity.permissions.push(val[i]);
          } else {
            if ((this.role.entity.permissions[index].is_delete = true)) {
              this.role.entity.permissions[index].is_delete = false;
              this.role.entity.permissions[index].is_add = true;
            }
          }
        }
      }
      var vm = this;
      var difference = vm.role.entity.permissions.filter(
        (x) => !val.map((y) => y.id).includes(x.id)
      );
      if (difference && difference.length > 0) {
        for (let i = 0; i < difference.length; i++) {
          let index = vm.role.entity.permissions.findIndex(
            (x) => x.id === difference[i].id
          );
          if (index >= 0) {
            vm.role.entity.permissions[index].is_delete = true;
            vm.role.entity.permissions[index].is_add = false;
          }
        }
      }
      this.dialog.permission.show = false;
    },
    /* ---------- End Emit ----------*/
    /* ---------- Put, Post -----------*/

    onCancelConfirmDelete() {
      this.confirmDelete.show = false;
      this.confirmDelete.message = null;
      this.confirmDelete.value = null;
    },
    async onConfirmedDelete() {
      this.buttons.delete.disabled = true;
      this.buttons.delete.processing = true;
      this.$store.dispatch("setSpinnerApp", {
        show: true,
      });
      try {
        let response = await roleFunc.delete(
          this.confirmDelete.value,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.$toast.success({
            title: this.$t("title_message_info"),
            message: this.$t("message_delete_role_success", null, {
              name: this.role.entity.description
                ? this.role.entity.description.trim()
                : "",
            }),
            position: "top right",
          });
          this.onCancelConfirmDelete();
          this.getAllRole();
        } else {
          this.message.push([
            this.$t("message_delete_role_error", null, {
              name: this.role.description.name
                ? this.role.description.name.trim()
                : "",
            }),
          ]);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$store.dispatch("setSpinnerApp", {
            show: false,
          });
          await this.refreshToken(this.onConfirmedDelete);
        } else {
          let msg =
            error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push([
            msg
              ? msg
              : this.$t("message_delete_role_error", null, {
                  name: this.role.entity.description
                    ? this.role.entity.description.trim()
                    : "",
                }),
          ]);
        }
      }
      this.$store.dispatch("setSpinnerApp", {
        show: false,
      });
      this.buttons.delete.processing = false;
    },
    async onSave() {
      this.message = [];
      var vm = this;
      this.$refs.roleObserver.validate().then(async (success) => {
        if (!success) {
          setTimeout(() => {
            Object.keys(vm.$refs.roleObserver.errors).forEach(function (key) {
              if (
                vm.$refs.roleObserver.errors[key] &&
                vm.$refs.roleObserver.errors[key].length
              )
                vm.message.push(vm.$refs.roleObserver.errors[key].join(",\n"));
            });
          }, 100);
          return;
        } else {
          this.buttons.save.processing = true;
          this.buttons.save.disabled = true;
          this.buttons.add.disabled = true;
          this.buttons.edit.disabled = true;
          this.buttons.reload.disabled = true;
          this.$store.dispatch("setSpinnerApp", {
            show: true,
          });
          let dto = Object.assign(
            {},
            JSON.parse(JSON.stringify(this.role.entity))
          );
          dto.name = dto.name ? dto.name.trim().replace(/\s\s+/g, " ") : null;
          dto.description = dto.description
            ? dto.description.trim().replace(/\s\s+/g, " ")
            : null;
          dto.urls = dto.selectedInterfaces ? dto.selectedInterfaces : [];

          if (dto.translation && Object.keys(dto.translation).length > 0) {
            if (dto.translation.vi) {
              try {
                dto.translation.vi.description = dto.translation.vi.description
                  ? dto.translation.vi.description.trim().replace(/\s\s+/g, " ")
                  : null;
                if (!dto.description) {
                  dto.description = dto.translation.vi.description
                    ? dto.translation.vi.description
                    : "";
                }
              } catch (error) {}
            }
            if (dto.translation.en) {
              try {
                dto.translation.en.description = dto.translation.en.description
                  ? dto.translation.en.description.trim().replace(/\s\s+/g, " ")
                  : null;
              } catch (error) {}
            }
          }

          let response;
          try {
            if (!dto.id) {
              // Add new
              response = await roleFunc.create(
                dto,
                this.$store.getters.getAccessToken
              );
            } else {
              // Edit
              if (
                dto.permissions &&
                dto.permissions.filter((x) => x.is_add).length > 0
              ) {
                let ids = this.addRolePermissions2DB(
                  dto.permissions.filter((x) => x.is_add),
                  dto.id
                );
              }
              response = await roleFunc.update(
                dto,
                this.$store.getters.getAccessToken
              );
            }
            if (
              response &&
              (response.status === 201 ||
                response.status === 200 ||
                response.status === 204)
            ) {
              let r_id =
                this.role.isEdit && this.role.entity.id
                  ? this.role.entity.id
                  : response.data && response.data.data && response.data.data.id
                  ? response.data.data.id
                  : null;
              if (r_id && this.role.isAdd && !this.role.entity.id) {
                if (
                  dto.permissions &&
                  dto.permissions.filter((x) => x.is_add).length > 0
                ) {
                  let ids = this.addRolePermissions2DB(
                    dto.permissions.filter((x) => x.is_add),
                    r_id
                  );
                }
              }
              this.$toast.success({
                title: this.$t("title_message_info"),
                message:
                  (this.role.isEdit && this.role.entity.id
                    ? this.$t("label_save_role") + " "
                    : this.$t("label_add_role") + " ") +
                  this.role.entity.description +
                  " " +
                  this.$t("label_success").toLowerCase(),
                position: "top right",
              });
              this.getAllRole();
            } else {
              this.message.push(
                (this.role.isEdit && this.role.entity.id
                  ? this.$t("label_save_role") + " "
                  : this.$t("label_add_role") + " ") +
                  this.role.entity.description +
                  " " +
                  this.$t("label_error").toLowerCase()
              );
            }
          } catch (error) {
            if (error.response) {
              if (error.response.status === 401) {
                this.$store.dispatch("setSpinnerApp", {
                  show: false,
                });
                await this.refreshToken(this.onSave);
              } else if (error.response.status === 403) {
                this.message.push("403 Forbidden. Access is denied");
              } else {
                this.buttons.save.processing = false;
                this.buttons.save.disabled = false;
                this.buttons.edit.disabled = !this.role.isEdit;
                this.message.push(
                  error.response.data && error.response.data.message
                    ? error.response.data.message
                    : (this.role.isEdit && this.role.entity.id
                        ? this.$t("label_save_role") + " "
                        : this.$t("label_add_role") + " ") +
                        this.role.entity.description +
                        " " +
                        this.$t("label_error").toLowerCase()
                );
              }
            }
          }
          this.buttons.save.processing = false;
          this.buttons.save.disabled = false;
          this.buttons.edit.disabled = false;
          this.buttons.reload.disabled = false;
          this.buttons.add.disabled = false;
          this.$store.dispatch("setSpinnerApp", {
            show: false,
          });
        }
      });
    },
    addRolePermissions2DB(val, id) {
      return new Promise(async (resolve, reject) => {
        try {
          //let response = await roleFunc.addPermissions(val.join("-"), id);
          let response = await roleFunc.addPermissions(
            val.map((x) => x.id),
            id,
            this.$store.getters.getAccessToken
          );
          if (
            response &&
            (response.status === 200 ||
              response.status === 201 ||
              response.status === 204)
          ) {
            resolve(val.map((x) => x.id));
          } else resolve([]);
        } catch (error) {
          resolve([]);
        }
      });
    },
    deleteRolePermissionsFromDB(val) {},
    /* ---------- End Put, Post -----------*/
    /* ---------- Api get data ---------- */
    async onFilter() {
      await this.getAllRole();
    },
    async getAllRole() {
      this.role.entity = this.generateEntity();
      this.role.table.selected = null;
      this.role.items = [];
      this.role.page_number = 0;
      this.role.page_size = 2147483647;
      this.role.total_page = 0;
      this.role.total_records = 0;
      this.role.isAdd = false;
      this.role.isEdit = false;
      this.buttons.filter.processing = true;
      this.buttons.filter.disabled = true;
      this.buttons.reload.disabled = true;
      this.buttons.delete.disabled = true;
      this.buttons.delete.processing = false;
      try {
        let response = await roleFunc.getAll(
          this.$store.getters.getAccessToken
        );
        if (response) {
          if (response.status === 200) {
            this.role.items =
              response.data && response.data.content_page
                ? response.data.content_page
                : [];
            this.role.page_number =
              response.data && response.data.page_number
                ? response.data.page_number
                : 0;
            this.role.page_size =
              response.data && response.data.page_size
                ? response.data.page_size
                : 2147483647;
            this.role.total_page =
              response.data && response.data.total_page
                ? response.data.total_page
                : 0;
            this.role.total_records =
              response.data && response.data.total_records
                ? response.data.total_records
                : 0;
            if (this.role.items.length > 0) this.role.first = 0;
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.getAllRole);
        }
      }
      this.buttons.reload.disabled = false;
      this.buttons.filter.processing = false;
      this.buttons.filter.disabled = false;
    },

    async getAllPermission() {
      this.permission.items = [];
      try {
        let response = await permissionFunc.getAll(
          { page: 0, size: 2147483647 },
          this.$store.getters.getAccessToken
        );
        if (response) {
          if (response.status === 200) {
            this.permission.items =
              response.data && response.data.content_page
                ? response.data.content_page
                : [];
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$store.dispatch("setSpinnerApp", {
            show: false,
          });
          await this.refreshToken(this.getAllPermission);
        }
      }
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },

    /* ---------- End Api get data ---------- */
  },
};
</script>

<style lang="scss" scoped>
.authority-content {
  padding: 0.5rem;
  padding-top: 0;
  .authority-content__datatable {
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .authority-content__structure {
    position: relative;
    margin-top: 0.25rem;
    .actions {
      position: absolute;
      right: 2.5rem;
      top: 0.375rem;
      button {
        border-radius: 50% !important;
        margin-right: 0.25rem;
        background: #009ef7;
        color: white;
        width: 1.6125rem;
        height: 1.6125rem;
        padding: 0.25rem;
        &:last-child {
          margin-right: 0;
        }
        &:disabled {
          background: #f4f4f4;
          color: #009ef7;
        }
        .pi {
          font-size: 0.75rem !important;
        }
      }
    }
    .authority-structure {
      background: white;
    }
  }
}
</style>
<style lang="scss">
.user-content__structure {
  .actions {
    button {
      .pi {
        font-size: 1rem !important;
      }
    }
  }
}
</style>