<template>
  <main-layout
    :isToolbar="false"
    :isBreadcrumb="true"
    :breadcrumbitems="[
      { label: $t('menu_administration') },
      { label: $t('menu_management_common_config') },
    ]"
    :propButtonsState="{
      save: {
        visible: false,
      },
      add: {
        visible: false,
      },
      edit: {
        visible: false,
      },
      reload: {
        visible: true,
      },
      print: {
        visible: false,
      },
    }"
    :propTitle="$t('menu_management_common_config')"
    @emitFunction="menuBarFunction($event)"
  >
    <div class="common-config-content">
      <div class="common-config__dialog">
        <ValidationObserver ref="basemapObserver">
          <DialogBasemapDetail
            v-if="dialog.basemaps.show"
            :prop-show="dialog.basemaps.show"
            :prop-entity="dialog.basemaps.value"
            :prop-style="{ width: dialog.basemaps.width }"
            :prop-title="dialog.basemaps.title"
            @close="
              dialog.basemaps.show = false;
              dialog.basemaps.value = null;
              dialog.basemaps.title = null;
            "
            @onSave="saveBasemap"
            @message="message = $event"
          />
        </ValidationObserver>

        <DialogConfirmation
          v-if="dialog.confirmDelete.show"
          :prop-show="dialog.confirmDelete.show"
          :prop-title="$t('title_message_confirm')"
          :prop-icon="dialog.confirmDelete.icon"
          :prop-message="dialog.confirmDelete.message"
          :textNo="$t('button_text_close')"
          :textYes="$t('button_text_delete')"
          @cancel="onCancelConfirmDelete"
          @confirmed="onConfirmedDelete"
        />
        <DialogMessage
          v-if="message.length > 0"
          :propTitle="$t('title_message_info')"
          :prop-show="message.length > 0"
          :prop-message="message"
          @close="message = []"
        />
      </div>
      <div class="common-config__accordion">
        <div class="common-config__accordion--basemaps">
          <Basemaps
            :propItems="basemaps.items"
            :prop-loading="basemaps.loading"
            @showDialogAddBasemaps="showDialogAddBasemaps"
            @showDialogConfirmDelete="showDialogConfirmDelete"
          />
        </div>
      </div>
    </div>
  </main-layout>
</template>
<script>
import MainLayout from "@/components/pages/layouts/admin/Main";
import Basemaps from "./accordion/Basemaps.vue";
import DialogBasemapDetail from "./accordion/basemaps/Detail.vue";
import DialogMessage from "@/components/commons/dialog/MessageBox";
import DialogConfirmation from "@/components/commons/dialog/Confirmation";
import logFunc from "@/utils/functions/log";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import userFunc from "@/utils/functions/user";
import metaKeyFunc from "@/utils/functions/metakey";
import { validURL, isValidURL } from "@/utils/commons/common";
export default {
  components: {
    MainLayout,
    Basemaps,
    DialogBasemapDetail,
    DialogMessage,
    DialogConfirmation,
  },
  data() {
    return {
      message: [],
      buttons: {
        filter: {
          disabled: false,
          processing: false,
          visible: false,
        },
      },
      basemaps: {
        items: [],
        loading: false,
      },
      dialog: {
        basemaps: {
          show: false,
          title: "",
          value: null,
          width: "540px",
        },
        confirmDelete: {
          message: null,
          show: false,
          title: null,
          icon: "fas fa-question",
          value: false,
        },
      },
    };
  },
  async created() {
    this.getAllBasemaps();
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    tableHeight() {
      return this.documentHeight - 56 - 48 - 114 - 38 - 7;
    },
    username() {
      return this.$store.getters.getUserName;
    },
  },
  methods: {
    /*--------------- Emit -------------------*/
    menuBarFunction(val) {
      switch (val.code) {
        case "RELOAD":
          break;

        default:
          break;
      }
    },

    showDialogEditMinioInfo(data) {
      this.dialog.minio.show = true;
      this.dialog.minio.value = data;
    },
    showDialogEditSentinelData(data) {
      this.dialog.sentinel.show = true;
      this.dialog.sentinel.value = data;
    },
    showDialogConfirmDelete(data) {
      this.dialog.confirmDelete.message = this.$t(
        "message_confirm_delete_basemap",
        null,
        {
          name: data.obj_layer.name,
        }
      );
      this.dialog.confirmDelete.value = data;
      this.dialog.confirmDelete.show = true;
    },
    onCancelConfirmDelete() {
      this.dialog.confirmDelete.message = null;
      this.dialog.confirmDelete.value = null;
      this.dialog.confirmDelete.show = false;
    },
    async onConfirmedDelete() {
      try {
        let response = await metaKeyFunc.delete(
          this.dialog.confirmDelete.value.id,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success) {
            this.$toast.success({
              title: this.$t("title_message_info"),
              message: this.$t("message_delete_basemap_success", null, {
                name: this.dialog.confirmDelete.value.obj_layer
                  ? this.dialog.confirmDelete.value.obj_layer.name
                  : "",
              }),
              position: "top right",
            });
            this.getAllBasemaps();
            this.onCancelConfirmDelete();
          } else {
            this.message.push(
              this.$t("message_delete_basemap_error", null, {
                name: this.dialog.confirmDelete.value.obj_layer
                  ? this.dialog.confirmDelete.value.obj_layer.name
                  : "",
              })
            );
          }
        } else {
          this.message.push(
            this.$t("message_delete_basemap_error", null, {
              name: this.dialog.confirmDelete.value.obj_layer
                ? this.dialog.confirmDelete.value.obj_layer.name
                : "",
            })
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.onConfirmedDelete);
        } else {
          let msg =
            error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push(
            msg
              ? msg
              : (msg = this.$t("message_delete_basemap_error", null, {
                  name: this.dialog.confirmDelete.value.obj_layer
                    ? this.dialog.confirmDelete.value.obj_layer.name
                    : "",
                }))
          );
        }
      }
    },
    showDialogAddBasemaps(data) {
      this.dialog.basemaps.show = true;
      this.dialog.basemaps.value = data;
      this.dialog.basemaps.title = this.$t("title_add_basemaps");
    },
    async saveBasemap(data) {
      var vm = this;
      this.$refs.basemapObserver.validate().then(async (success) => {
        if (!success) {
          setTimeout(() => {
            Object.keys(vm.$refs.basemapObserver.errors).forEach(function (
              key
            ) {
              if (
                vm.$refs.basemapObserver.errors[key] &&
                vm.$refs.basemapObserver.errors[key].length
              )
                vm.message.push(
                  vm.$refs.basemapObserver.errors[key].join(",\n")
                );
            });
          }, 100);
          return;
        } else {
          if (!data.path || !data.path.trim()) {
            this.message.push(this.$t("message_path_is_required"));
            return;
          }
          let dto = {
            id: data.id ? data.id : null,
            key: "BASEMAPS",
            name: new Date().getTime() + "",
            description: "",
            value: JSON.stringify({
              name: data.name ? data.name.trim().replace(/\s\s+/g, " ") : null,
              path: data.path ? data.path.trim().replace(/\s\s+/g, " ") : null,
              is_show: data.is_show ? 1 : 0,
              type: data.type,
              geojson: JSON.stringify(data.geojson),
              fill: data.fill,
              fill_opacity: data.fill_opacity,
              stroke: data.stroke,
              stroke_opacity: data.stroke_opacity,
            }),
          };
          if (dto.id) {
            try {
              let response = await metaKeyFunc.update(
                dto,
                this.$store.getters.getAccessToken
              );
              let msg = "";
              if (response && response.status === 200) {
                if (response.data.success) {
                  this.$toast.success({
                    title: this.$t("title_message_info"),
                    message: this.$t("message_edit_basemap_success", null, {
                      name: data.name ? data.name : "",
                    }),
                    position: "top right",
                  });
                  this.getAllBasemaps();
                  this.dialog.basemaps.value = null;
                  this.dialog.basemaps.show = false;
                } else {
                  msg = this.$t("message_edit_basemap_error", null, {
                    name: data.name ? data.name : "",
                  });
                }
              } else
                msg = this.$t("message_edit_basemap_error", null, {
                  name: data.name ? data.name : "",
                });
              if (msg) this.message.push(msg);
            } catch (error) {
              if (error.response && error.response.status === 401) {
                this.refreshToken(this.saveBasemap, data);
              } else {
                let msg =
                  error.response.data && error.response.data.message
                    ? error.response.data.message
                    : null;
                this.message.push(
                  msg
                    ? msg
                    : (msg = this.$t("message_edit_basemap_error", null, {
                        name: data.name ? data.name : "",
                      }))
                );
              }
            }
          } else {
            try {
              let response = await metaKeyFunc.create(
                dto,
                this.$store.getters.getAccessToken
              );
              let msg = "";
              if (response && response.status === 200) {
                if (response.data.success) {
                  this.$toast.success({
                    title: this.$t("title_message_info"),
                    message: this.$t("message_add_basemap_success", null, {
                      name: data.name ? data.name : "",
                    }),
                    position: "top right",
                  });
                  this.getAllBasemaps();
                  this.dialog.basemaps.value = null;
                  this.dialog.basemaps.show = false;
                } else {
                  msg = this.$t("message_add_basemap_error", null, {
                    name: data.name ? data.name : "",
                  });
                }
              } else
                msg = this.$t("message_add_basemap_error", null, {
                  name: data.name ? data.name : "",
                });
              if (msg) this.message.push(msg);
            } catch (error) {
              if (error.response && error.response.status === 401) {
                this.refreshToken(this.saveBasemap, data);
              } else {
                let msg =
                  error.response.data && error.response.data.message
                    ? error.response.data.message
                    : null;
                this.message.push(
                  msg
                    ? msg
                    : (msg = this.$t("message_add_basemap_error", null, {
                        name: data.name ? data.name : "",
                      }))
                );
              }
            }
          }
        }
      });
    },
    /*---------------- End emit ----------------*/
    async getAllBasemaps() {
      try {
        this.basemaps.items = [];
        this.basemaps.loading = true;
        let response = await metaKeyFunc.getByKey(
          "BASEMAPS",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data && response.data.success) {
            let data = response.data.data ? response.data.data : [];
            for (let i = 0; i < data.length; i++) {
              try {
                let obj = JSON.parse(data[i].value);
                if (obj && Object.keys(obj).length > 0) {
                  this.basemaps.items.push({
                    id: data[i].id,
                    type: obj.type ? obj.type : "tile",
                    name: data[i].name,
                    value: data[i].value,
                    description: data[i].description,
                    path: obj.path ? obj.path : null,
                    fill: obj.fill ? obj.fill : null,
                    fill_opacity: obj.fill_opacity ? obj.fill_opacity : null,
                    stroke: obj.stroke ? obj.stroke : null,
                    stroke_opacity: obj.stroke_opacity,
                    obj_layer: {
                      name: obj.name ? obj.name : obj.NAME,
                      path: obj.path ? obj.path : obj.PATH,
                      is_show:
                        (obj.is_show && obj.is_show + "" === "1") ||
                        (obj.IS_SHOW && obj.IS_SHOW + "" === "1")
                          ? true
                          : false,
                      type: obj.type ? obj.type : null,
                      fill: obj.fill ? obj.fill : null,
                      fill_opacity: obj.fill_opacity ? obj.fill_opacity : null,
                      stroke: obj.stroke ? obj.stroke : null,
                      stroke_opacity: obj.stroke_opacity,
                    },
                  });
                  try {
                    this.basemaps.items[i].obj_layer.geojson = JSON.parse(
                      obj.geojson
                    );
                  } catch (errorParse) {}
                }
              } catch (error) {}
            }
          }
        }
        this.basemaps.loading = false;
      } catch (error) {
        this.basemaps.loading = false;
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllBasemaps);
        }
      }
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
};
</script>
<style lang="scss" scoped>
.common-config-content {
  padding: 0.5rem;
  .common-config__accordion--basemaps {
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .common-config__accordion--sentinel {
    margin-top: 0.5rem;
    .accordion {
      border-radius: 0.5rem;
    }
  }
}
</style>