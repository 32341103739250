<template>
  <CommonAccordion :propTitle="$t('title_basemaps')">
    <div slot="content">
      <div class="datatable">
        <DataTable
          :value="propItems"
          :sortOrder="1"
          :loading="propLoading"
          ref="dt"
          :resizableColumns="true"
          columnResizeMode="expand"
          selectionMode="single"
          dataKey="id"
          :paginator="propItems && propItems.length > 10"
          :rows="10"
          @page="pageChange"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll"
          :currentPageReportTemplate="$t('label_showing') + ' {first} '+$t('label_to').toLowerCase()+' {last} ' +$t('label_of').toLowerCase()+ ' {totalRecords}'"
        >
        
          <template #header v-if="propItems && propItems.length <= 10"> 
            <div class="text-left">
              <div>
                <div v-if="propItems.length" class="font-weight-normal">
                  {{
                    $t("label_show_from_to", null, {
                      from: 1,
                      to: propItems.length,
                      total: propItems.length,
                    })
                  }}
                </div>
              </div>
            </div>
          </template>
          <template #empty v-if="propItems && propItems.length === 0">
            <span class="text-center">{{ $t("label_no_data") }}</span>
          </template>
          <Column
            header="No"
            headerStyle="width: 3rem; text-align: center!important"
            bodyStyle="text-align: center;"
          >
            <template #body="slotProps">
              {{  table.page *  table.size +  slotProps.index + 1 }}
            </template></Column
          >

          <Column :header="$t('label_layer_name')" headerStyle="width: 12rem">
            <template #body="slotProps">
              {{
                slotProps.data.obj_layer ? slotProps.data.obj_layer.name : ""
              }}
            </template>
          </Column>
          <Column :header="$t('label_path')">
            <template #body="slotProps">
              {{
                slotProps.data.obj_layer ? slotProps.data.obj_layer.path : ""
              }}
            </template></Column
          >
          <Column :header="$t('label_show')" headerStyle="width: 5.75rem">
            <template #body="slotProps">
              <i
                class="fas fa-check text-success"
                v-if="
                  slotProps.data.obj_layer && slotProps.data.obj_layer.is_show
                "
              ></i>
            </template>
          </Column>
          <Column
            headerStyle="width: 6rem; text-align: center!important"
            bodyStyle="text-align: center; overflow: visible"
          >
            <template #header>
              <button class="btn btn-primary btn-sm" @click="addItem">
                <i class="fas fa-plus"></i>
              </button>
            </template>
            <template #body="slotProps">
              <Button
                icon="fas fa-edit"
                type="button"
                class="p-button-sm mr-1"
                :title="$t('button_text_edit')"
                @click="editItem(slotProps.data)"
              >
              </Button>
              <Button
                icon="fas fa-trash"
                type="button"
                class="p-button-sm p-button-danger"
                :title="$t('button_text_remove')"
                @click="removeItem(slotProps.data)"
              >
              </Button>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import Button from "@/components/commons/button/Button";
import ContextMenu from "@/components/commons/contextmenu/ContextMenu";
export default {
  components: {
    CommonAccordion,
    DataTable,
    Column,
    Button,
    ContextMenu,
  },
  props: {
    propItems: {
      type: Array,
      default: () => [],
    },
    propLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      table:{
        page:0,
        size:10
      },
      entity: {
        id: null,
        key: "BASEMAPS",
        name: null,
        description: null,
        value: null,
        obj_layer: {
          name: null,
          path: null,
          is_show: null,
        },
      },
    };
  },
  methods: {
    generateEntity() {
      return {
        id: null,
        key: "BASEMAPS",
        name: null,
        description: null,
        value: null,
        obj_layer: {
          name: null,
          path: null,
          is_show: null,
        },
      };
    },
    editItem(item) {
      this.$emit("showDialogAddBasemaps", item);
    },
    removeItem(item) {
      this.$emit("showDialogConfirmDelete", item);
    },
    addItem() {
      this.entity = this.generateEntity();
      this.$emit("showDialogAddBasemaps", this.entity);
    },    
    pageChange(e) {
      this.table.page = e.page;
      this.table.size = e.rows;
    },
  },
};
</script>
<style lang="scss">
.common-config__accordion--basemaps {
  .datatable {
    @import "@/assets/scss/_datatable.scss";
  }
}
</style>