import axios from "axios";
import basicApi from "@/utils/apis/basic";
import app from './../../main'
import { userServiceApiURL,geoServiceApiURL } from "@/utils/commons/environment"
export default {
    async getMyShareImage(params, access_token) {
        let headers = basicApi.me_controller.my_share_image.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        if (!params) params = {};
        params.lang = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.me_controller.my_share_image.method,
            url:  geoServiceApiURL  +
            basicApi.me_controller.my_share_image.url,
            params: params,
            headers: headers
        });
        let response = await object;
        return response;
    },
    async getMyOrder(params, access_token) {
        let headers = basicApi.me_controller.my_order.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        if (!params) params = {};
        params.lang = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.me_controller.my_order.method,
            url:  geoServiceApiURL  +
            basicApi.me_controller.my_order.url,
            params: params,
            headers: headers
        });
        let response = await object;
        return response;
    },
    async getMyCart(params, access_token) {
        let headers = basicApi.me_controller.my_cart.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        if (!params) params = {};
        params.lang = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.me_controller.my_cart.method,
            url:  geoServiceApiURL  +
            basicApi.me_controller.my_cart.url,
            params: params,
            headers: headers
        });
        let response = await object;
        return response;
    },
    async getMyProject(params, access_token) {
        let headers = basicApi.me_controller.my_project.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        if (!params) params = {};
        params.lang = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.me_controller.my_project.method,
            url:  geoServiceApiURL  +
            basicApi.me_controller.my_project.url,
            params: params,
            headers: headers
        });
        let response = await object;
        return response;
    },
    async cancelOrder(entity, access_token) {
        let headers = basicApi.me_controller.cancel_order.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.me_controller.cancel_order.method,
            url:  geoServiceApiURL  +
            basicApi.me_controller.cancel_order.url,
            data: entity,
            headers: headers,
            params: {
                lang : app.$store.getters.getLang
            }
        });
        let response = await object;
        return response;
    },
    async getSharedByMe(params,access_token,data){
        let headers = basicApi.me_controller.shared_by_me.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        if (!params) params = {};
        params.lang = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.me_controller.shared_by_me.method,
            url:  geoServiceApiURL  +
            basicApi.me_controller.shared_by_me.url,
            params: params,
            data:data,
            headers: headers
        });
        let response = await object;
        return response;
    },
    
    async createShare(entity, access_token) {
        let headers = basicApi.me_controller.create_share.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.me_controller.create_share.method,
            url:  geoServiceApiURL  +
            basicApi.me_controller.create_share.url,
            data: entity,
            headers: headers,
            params: {
                lang : app.$store.getters.getLang
            }
        });
        let response = await object;
        return response;
    },
    
    async changePass(entity, access_token) {
        let headers = basicApi.me_controller.change_pass.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        let params = Object.assign({}, entity);
        params.lang = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.me_controller.change_pass.method,
            url:  userServiceApiURL  +
            basicApi.me_controller.change_pass.url,
            data: entity,
            params:params,
            headers: headers
        });
        let response = await object;
        return response;
    },
}