<template>
  <main-layout
    :isToolbar="true"
    :isBreadcrumb="true"
    :breadcrumbitems="[
      { label: $t('menu_category') },
      { label: $t('menu_data_specification') },
    ]"
    :propButtonsState="{
      delete: buttons.delete,
      save: buttons.save,
      add: buttons.add,
      edit: buttons.edit,
      reload: buttons.reload,
      print: {
        visible: false,
      },
    }"
    :propTitle="$t('menu_data_specification')"
    @emitFunction="menuBarFunction($event)"
  >
    <div class="data-specification__content">
      <div class="data-specification__dialog">
        <DialogSpecification
          v-if="dialogSpecification.show"
          :prop-entity="dialogSpecification.entity"
          :prop-show="dialogSpecification.show"
          :prop-style="{
            width: dialogSpecification.width,
          }"
          :prop-button="dialogSpecification.button"
          @message="message = $event"
          @close="
            dialogSpecification.show = false;
            dialogSpecification.entity = null;
          "
          @saveSpecification="saveSpecification"
        />
        <DialogConfirmation
          v-if="confirmDelete.show"
          :prop-show="confirmDelete.show"
          :prop-title="$t('title_message_confirm')"
          :prop-icon="confirmDelete.icon"
          :prop-message="confirmDelete.message"
          :textNo="$t('button_text_close')"
          :textYes="$t('button_text_delete')"
          @cancel="onCancelConfirmDelete"
          @confirmed="onConfirmedDelete"
        />
        <DialogConfirmation
          v-if="confirmDeleteSpecification.show"
          :prop-show="confirmDeleteSpecification.show"
          :prop-title="$t('title_message_confirm')"
          :prop-icon="confirmDeleteSpecification.icon"
          :prop-message="confirmDeleteSpecification.message"
          :textNo="$t('button_text_close')"
          :textYes="$t('button_text_delete')"
          @cancel="onCancelConfirmDeleteSpecification"
          @confirmed="onConfirmedDeleteSpecification"
        />
        <DialogMessage
          v-if="message.length > 0"
          :propTitle="$t('title_message_info')"
          :prop-show="message.length > 0"
          :prop-message="message"
          @close="message = []"
        />
      </div>
      <div class="data-specification__search"></div>
      <div class="data-specification__datatable">
        <DataTable
          ref="dataSpecificationDatatable"
          :prop-loading="dataSpecification.loading"
          :prop-items="dataSpecification.items"
          :prop-table="dataSpecification.table"
          :prop-first="dataSpecification.first"
          @rowClick="rowClick"
        />
      </div>
      <div class="data-specification__structure">
        <ValidationObserver ref="dataSpecificationObserver">
          <Structure
            :prop-entity="dataSpecification.entity"
            :prop-status="{
              isAdd: dataSpecification.isAdd,
              isEdit: dataSpecification.isEdit,
            }"
            @showDialogSpecification="showDialogSpecification"
            @removeSpecification="removeSpecification"
          />
        </ValidationObserver>

        <div class="actions">
          <Button
            :icon="
              buttons.prev.processing
                ? 'fa fa-spinner fa-spin'
                : 'pi pi-chevron-left'
            "
            class="p-button-rounded p-button-secondary"
            :disabled="buttons.prev.disabled"
            @click="prevItem"
          />
          <Button
            :icon="
              buttons.next.processing
                ? 'fa fa-spinner fa-spin'
                : 'pi pi-chevron-right'
            "
            class="p-button-rounded p-button-secondary"
            :disabled="buttons.next.disabled"
            @click="nextItem"
          />
        </div>
      </div>
    </div>
  </main-layout>
</template>
<script>
import MainLayout from "@/components/pages/layouts/admin/Main";
import DataTable from "./list/DataTable";
import Structure from "./list/Structure";
import DialogSpecification from "./list/Detail";
import DialogMessage from "@/components/commons/dialog/MessageBox";
import DialogConfirmation from "@/components/commons/dialog/Confirmation";
import Button from "@/components/commons/button/Button";
import processingDataFunc from "@/utils/functions/processingdata";
export default {
  components: {
    MainLayout,
    DataTable,
    Structure,
    DialogSpecification,
    Button,
    DialogMessage,
    DialogConfirmation,
  },
  data() {
    return {
      message: [],
      dataSpecification: {
        items: [],
        loading: false,
        isAdd: false,
        isEdit: false,
        first: -1,
        search: {
          name: null,
        },
        table: {
          selected: null,
          page: 0,
          size: 10,
        },
        entity: {
          data: [
            {
              active: true,
              format_data: null,
              key: null,
              name: null,
              pattern: null,
              unit: null,
            },
          ],
          id: null,
          name: null,
          script: null,
        },
      },
      buttons: {
        prev: {
          disabled: true,
          processing: false,
          visible: true,
        },
        next: {
          disabled: true,
          processing: false,
          visible: true,
        },
        delete: {
          disabled: true,
          processing: false,
          visible: true,
        },
        save: {
          disabled: true,
          processing: false,
          visible: true,
        },
        edit: {
          disabled: true,
          processing: false,
          visible: true,
        },
        add: {
          disabled: false,
          processing: false,
          visible: true,
        },
        filter: {
          disabled: false,
          processing: false,
          visible: true,
        },
        reload: {
          disabled: false,
          processing: false,
          visible: true,
        },
      },
      dialogSpecification: {
        show: false,
        value: null,
        width: "480px",
        button: {
          disabled: false,
          processing: false,
        },
        entity: {
          active: true,
          format_data: "String",
          key: null,
          name: null,
          pattern: null,
          unit: null,
          id: null,
          gen_id: null,
        },
      },

      confirmDelete: {
        message: null,
        show: false,
        title: null,
        icon: "fas fa-question",
        value: false,
      },
      confirmDeleteSpecification: {
        message: null,
        show: false,
        title: null,
        icon: "fas fa-question",
        value: false,
      },
    };
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
  },
  created() {
    this.getAllModel();
  },
  methods: {
    generateEntity() {
      return {
        data: [],
        id: null,
        name: null,
        script: null,
      };
    },
    async menuBarFunction(val) {
      switch (val.code) {
        case "SAVE":
          this.onSave();
          break;
        case "ADD":
          this.dataSpecification.isEdit = false;
          this.dataSpecification.isAdd = true;
          this.buttons.edit.disabled = true;
          this.buttons.edit.processing = false;
          this.buttons.save.disabled = false;
          this.buttons.save.processing = false;
          this.dataSpecification.table.selected = null;
          this.buttons.delete.disabled = true;
          this.buttons.delete.processing = false;
          this.dataSpecification.entity = this.generateEntity();
          break;
        case "EDIT":
          this.dataSpecification.isEdit = true;
          this.dataSpecification.isAdd = false;
          this.buttons.add.disabled = false;
          this.buttons.add.processing = false;
          this.buttons.save.disabled = false;
          this.buttons.save.processing = false;
          break;
        case "FILTER":
          break;
        case "RELOAD":
          this.buttons.reload.processing = true;
          if (!this.cancel) await this.onReload();
          this.buttons.reload.processing = false;
          break;
        case "DELETE":
          this.confirmDelete.value = this.dataSpecification.entity.id;
          this.confirmDelete.message = this.$t(
            "message_confirm_delete_data_specification",
            null,
            {
              name: this.dataSpecification.entity.name
                ? this.dataSpecification.entity.name.trim()
                : "",
            }
          );
          this.confirmDelete.show = true;
          break;
        default:
          break;
      }
    },
    async rowClick(val) {
      this.dataSpecification.isAdd = false;
      this.dataSpecification.isEdit = false;
      this.buttons.add.disabled = false;
      this.buttons.add.processing = false;
      this.buttons.edit.disabled = false;
      this.buttons.edit.processing = false;
      this.buttons.delete.disabled = false;
      this.buttons.delete.processing = false;
      this.buttons.save.disabled = true;
      this.buttons.save.processing = false;
      if (val.index === 0) {
        this.buttons.prev.disabled = true;
        if (this.dataSpecification.items.length > 1)
          this.buttons.next.disabled = false;
        else this.buttons.next.disabled = true;
      } else if (val.index === this.dataSpecification.items.length - 1) {
        if (this.dataSpecification.items.length > 1)
          this.buttons.prev.disabled = false;
        else this.buttons.prev.disabled = true;
        this.buttons.next.disabled = true;
      } else {
        this.buttons.prev.disabled = false;
        this.buttons.next.disabled = false;
      }
      if (val.data) {
        this.$store.dispatch("setSpinnerApp", {
          show: true,
        });
        this.dataSpecification.table.selected = val.data;
        this.dataSpecification.entity = this.generateEntity();
        await this.entityFromSelected(val.data);
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
      }
    },

    entityFromSelected(data) {
      if (data && Object.keys(data).length > 0) {
        this.dataSpecification.entity.id = data.id ? data.id : null;
        this.dataSpecification.entity.script = data.script ? data.script : null;
        this.dataSpecification.entity.name = data.name ? data.name : null;
        this.dataSpecification.entity.data = data.data
          ? Object.assign([], data.data)
          : [];
        if (
          this.dataSpecification.entity.data &&
          this.dataSpecification.entity.data.length > 0
        ) {
          for (let i = 0; i < this.dataSpecification.entity.data.length; i++) {
            this.dataSpecification.entity.data[i].id = new Date().getTime() + i;
          }
        }
      }
    },

    async prevItem() {
      if (this == undefined) return;
      if (this.$refs.dataSpecificationDatatable) {
        let data = this.$refs.dataSpecificationDatatable.$refs.dt.processedData;
        let selection =
          this.$refs.dataSpecificationDatatable.$refs.dt.selection;
        if (!selection) return;
        let index = data.findIndex((x) => x.id === selection.id);
        this.buttons.prev.processing = true;
        this.buttons.prev.disabled = true;
        this.$store.dispatch("setSpinnerApp", {
          show: true,
        });
        await this.entityFromSelected(data[index - 1]);
        this.dataSpecification.table.selected = data[index - 1];
        this.buttons.prev.processing = false;
        this.buttons.prev.disabled = false;

        if (index === 1) {
          this.buttons.prev.disabled = true;
          this.buttons.next.disabled = false;
        } else {
          this.buttons.next.disabled = false;
          this.buttons.prev.disabled = false;
        }

        this.dataSpecification.table.page = Math.floor((index - 1) / 10);
        this.dataSpecification.first = Math.floor((index - 1) / 10) * 10;
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
      }
    },
    async nextItem() {
      if (this == undefined) return;
      if (this.$refs.dataSpecificationDatatable) {
        let data = this.$refs.dataSpecificationDatatable.$refs.dt.processedData;
        let selection =
          this.$refs.dataSpecificationDatatable.$refs.dt.selection;
        if (!selection) return;
        let index = data.findIndex((x) => x.id === selection.id);
        this.buttons.next.processing = true;
        this.buttons.next.disabled = true;
        this.$store.dispatch("setSpinnerApp", {
          show: true,
        });
        await this.entityFromSelected(data[index + 1]);
        this.dataSpecification.table.selected = data[index + 1];
        this.buttons.next.processing = false;
        this.buttons.next.disabled = false;

        if (index === data.length - 2) {
          this.buttons.prev.disabled = false;
          this.buttons.next.disabled = true;
        } else {
          this.buttons.prev.disabled = false;
          this.buttons.next.disabled = false;
        }
        this.dataSpecification.table.page = Math.floor((index + 1) / 10);
        this.dataSpecification.first = Math.floor((index + 1) / 10) * 10;
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
      }
    },
    saveSpecification(entity) {
      if (
        this.dataSpecification.entity &&
        Object.keys(this.dataSpecification.entity).length > 0
      ) {
        if (!this.dataSpecification.entity.data)
          this.dataSpecification.entity.data = [];
        let index = this.dataSpecification.entity.data.findIndex(
          (x) =>
            (entity.id && x.id === entity.id) ||
            (entity.gen_id && x.gen_id === entity.gen_id)
        );
        if (index >= 0) {
          this.dataSpecification.entity.data[index].id = entity.id;
          this.dataSpecification.entity.data[index].active = entity.active
            ? entity.active
            : false;

          this.dataSpecification.entity.data[index].name = entity.name
            ? entity.name.trim().replace(/\s\s+/g, " ")
            : null;
          this.dataSpecification.entity.data[index].key = entity.key
            ? entity.key.trim().replace(/\s\s+/g, " ")
            : null;
          this.dataSpecification.entity.data[index].pattern = entity.pattern
            ? entity.pattern.trim().replace(/\s\s+/g, " ")
            : null;
          this.dataSpecification.entity.data[index].format_data =
            entity.format_data
              ? entity.format_data.trim().replace(/\s\s+/g, " ")
              : null;
          this.dataSpecification.entity.data[index].unit = entity.unit
            ? entity.unit.trim().replace(/\s\s+/g, " ")
            : null;
          this.dataSpecification.entity.data[index].gen_id = entity.gen_id
            ? entity.gen_id
            : new Date().getTime();
        } else {
          if (
            this.dataSpecification.entity.data.findIndex(
              (x) => x.key === entity.key
            ) >= 0
          ) {
            this.message.push(
              this.$t("message_val_already_exists", null, {
                name: entity.key,
              })
            );
            return;
          } else this.dataSpecification.entity.data.push(entity);
        }
        this.dialogSpecification.show = false;
        this.dialogSpecification.entity = {
          active: true,
          format_data: "String",
          key: null,
          name: null,
          pattern: null,
          unit: null,
          id: null,
          gen_id: null,
        };
      }
    },
    showDialogSpecification(data) {
      this.dialogSpecification.entity = {
        active: true,
        format_data: "String",
        key: null,
        name: null,
        pattern: null,
        unit: null,
        id: null,
        gen_id: null,
      };
      if (data && Object.keys(data).length > 0) {
        this.dialogSpecification.entity.id = data.id;
        this.dialogSpecification.entity.gen_id = data.gen_id
          ? data.gen_id
          : null;
        this.dialogSpecification.entity.active = data.active
          ? data.active
          : false;
        this.dialogSpecification.entity.key = data.key
          ? data.key.trim().replace(/\s\s+/g, " ")
          : null;
        this.dialogSpecification.entity.name = data.name
          ? data.name.trim().replace(/\s\s+/g, " ")
          : null;
        this.dialogSpecification.entity.format_data = data.format_data
          ? data.format_data.trim().replace(/\s\s+/g, " ")
          : null;

        this.dialogSpecification.entity.pattern = data.pattern
          ? data.pattern.trim().replace(/\s\s+/g, " ")
          : null;
        this.dialogSpecification.entity.unit = data.unit
          ? data.unit.trim().replace(/\s\s+/g, " ")
          : null;
      }
      this.dialogSpecification.show = true;
    },
    removeSpecification(item) {
      this.confirmDeleteSpecification.message = this.$t(
        "message_confirm_delete_data_specification_field",
        null,
        {
          name: item.name ? item.name.trim().replace(/\s\s+/g, " ") : null,
        }
      );
      this.confirmDeleteSpecification.value = item;
      this.confirmDeleteSpecification.show = true;
    },
    onCancelConfirmDeleteSpecification() {
      this.confirmDeleteSpecification.show = false;
      this.confirmDeleteSpecification.message = null;
      this.confirmDeleteSpecification.value = null;
    },
    async onConfirmedDeleteSpecification() {
      if (this.dataSpecification.entity && this.dataSpecification.entity.data) {
        let index = this.dataSpecification.entity.data.findIndex(
          (x) => x.key === this.confirmDeleteSpecification.value.key
        );
        if (index >= 0) this.dataSpecification.entity.data.splice(index, 1);
      }
      this.confirmDeleteSpecification.show = false;
      this.confirmDeleteSpecification.message = null;
      this.confirmDeleteSpecification.value = null;
    },
    onCancelConfirmDelete() {
      this.confirmDelete.show = false;
      this.confirmDelete.message = null;
      this.confirmDelete.value = null;
    },
    async onConfirmedDelete() {
      this.buttons.delete.disabled = true;
      this.buttons.delete.processing = true;
      this.$store.dispatch("setSpinnerApp", {
        show: true,
      });
      try {
        let response = await processingDataFunc.deleteModel(
          this.confirmDelete.value,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.$toast.success({
            title: this.$t("title_message_info"),
            message: this.$t(
              "message_delete_data_specification_success",
              null,
              {
                name: this.dataSpecification.entity.name
                  ? this.dataSpecification.entity.name.trim()
                  : "",
              }
            ),
            position: "top right",
          });
          this.onCancelConfirmDelete();
          this.getAllModel();
        } else {
          this.message.push([
            this.$t("message_delete_data_specification_error", null, {
              name: this.dataSpecification.entity.name
                ? this.dataSpecification.entity.name.trim()
                : "",
            }),
          ]);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$store.dispatch("setSpinnerApp", {
            show: false,
          });
          await this.refreshToken(this.onConfirmedDelete);
        } else {
          let msg =
            error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push([
            msg
              ? msg
              : this.$t("message_delete_data_specification_error", null, {
                  name: this.dataSpecification.entity.name
                    ? this.dataSpecification.entity.name.trim()
                    : "",
                }),
          ]);
        }
      }
      this.$store.dispatch("setSpinnerApp", {
        show: false,
      });
      this.buttons.delete.processing = false;
    },

    onSave() {
      this.message = [];
      var vm = this;
      this.$refs.dataSpecificationObserver.validate().then(async (success) => {
        if (!success) {
          setTimeout(() => {
            Object.keys(vm.$refs.dataSpecificationObserver.errors).forEach(
              function (key) {
                if (
                  vm.$refs.dataSpecificationObserver.errors[key] &&
                  vm.$refs.dataSpecificationObserver.errors[key].length
                )
                  vm.message.push(
                    vm.$refs.dataSpecificationObserver.errors[key].join(",\n")
                  );
              }
            );
          }, 100);
          return;
        } else {
          this.buttons.save.processing = true;
          this.buttons.save.disabled = true;
          this.buttons.add.disabled = true;
          this.buttons.edit.disabled = true;
          this.buttons.reload.disabled = true;
          this.$store.dispatch("setSpinnerApp", {
            show: true,
          });
          let dto = {
            id: this.dataSpecification.entity.id,
            name: this.dataSpecification.entity.name
              ? this.dataSpecification.entity.name.trim().replace(/\s\s+/g, " ")
              : null,
            script: this.dataSpecification.entity.script
              ? this.dataSpecification.entity.script
                  .trim()
                  .replace(/\s\s+/g, " ")
              : null,
            data: this.dataSpecification.entity.data,
          };
          if (dto.data && dto.data.length > 0) {
            for (let i = 0; i < dto.data.length; i++) {
              if (dto.data[i].id != undefined) delete dto.data[i].id;
            }
          }
          let response;
          try {
            if (!dto.id) {
              // Add new
              response = await processingDataFunc.createModel(
                dto,
                this.$store.getters.getAccessToken
              );
            } else {
              // Edit
              response = await processingDataFunc.updateModel(
                dto,
                this.$store.getters.getAccessToken
              );
            }
            if (
              response &&
              (response.status === 201 ||
                response.status === 200 ||
                response.status === 204)
            ) {
              this.$toast.success({
                title: this.$t("title_message_info"),
                message: this.$t(
                  "message_save_data_specification_success",
                  null,
                  {
                    txt:
                      this.dataSpecification.isEdit &&
                      this.dataSpecification.entity.id
                        ? this.$t("text_save")
                        : this.$t("text_add_new"),
                    name: dto.name,
                  }
                ),
                position: "top right",
              });
              this.getAllModel();

              this.buttons.save.disabled = true;
              this.buttons.save.processing = false;
              this.buttons.add.disabled = false;
              this.buttons.add.processing = false;
              this.buttons.edit.disabled = true;
              this.buttons.edit.processing = false;
              this.dataSpecification.isAdd = false;
              this.dataSpecification.isEdit = false;
              this.dataSpecification.table.selected = null;
              this.dataSpecification.entity = this.generateEntity();
            } else {
              this.buttons.save.processing = false;
              this.buttons.save.disabled = false;
              this.buttons.edit.disabled = !this.dataSpecification.isEdit;
              this.buttons.add.disabled = false;
              this.buttons.reload.disabled = false;
              let msg =
                error.response.data && error.response.data.message
                  ? error.response.data.message
                  : null;
              this.message.push(
                msg
                  ? msg
                  : this.$t("message_save_data_specification_error", null, {
                      txt:
                        this.dataSpecification.isEdit &&
                        this.dataSpecification.entity.id
                          ? this.$t("text_save")
                          : this.$t("text_add_new"),
                      name: dto.name,
                    })
              );
            }
          } catch (error) {
            if (error.response && error.response.status === 401) {
              this.$store.dispatch("setSpinnerApp", {
                show: false,
              });
              await this.refreshToken(this.onSave);
            } else {
              this.buttons.save.processing = false;
              this.buttons.save.disabled = false;
              this.buttons.edit.disabled = !this.dataSpecification.isEdit;
              this.buttons.add.disabled = false;
              this.buttons.reload.disabled = false;
              let msg =
                error.response.data && error.response.data.message
                  ? error.response.data.message
                  : null;
              this.message.push(
                msg
                  ? msg
                  : this.$t("message_save_data_specification_error", null, {
                      txt:
                        this.dataSpecification.isEdit &&
                        this.dataSpecification.entity.id
                          ? this.$t("text_save")
                          : this.$t("text_add_new"),
                      name: dto.name,
                    })
              );
            }
          }
          this.$store.dispatch("setSpinnerApp", {
            show: false,
          });
        }
      });
    },
    async getAllModel() {
      this.dataSpecification.entity = this.generateEntity();
      this.dataSpecification.table.selected = null;
      this.dataSpecification.items = [];
      this.dataSpecification.isAdd = false;
      this.dataSpecification.isEdit = false;
      this.buttons.filter.processing = true;
      this.buttons.filter.disabled = true;
      this.buttons.reload.disabled = true;
      this.buttons.reload.processing = true;
      try {
        let response = await processingDataFunc.getAllModel(
          {
            name: this.dataSpecification.search.name
              ? this.dataSpecification.search.name.trim().replace(/\s\s+/g, " ")
              : null,
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.dataSpecification.items =
            response.data && response.data.content_page
              ? response.data.content_page
              : [];

          this.dataSpecification.items.sort(function (a, b) {
            if (a.name < b.name) return -1;
            else if (a.name > b.name) return 1;
            return 0;
          });
          if (
            this.dataSpecification.items &&
            this.dataSpecification.items.length > 0
          ) {
            this.dataSpecification.first = 0;
          } else this.dataSpecification.first = -1;
        }
        this.buttons.reload.disabled = false;
        this.buttons.reload.processing = false;
        this.buttons.filter.disabled = false;
        this.buttons.filter.processing = false;
      } catch (error) {
        this.buttons.reload.disabled = false;
        this.buttons.reload.processing = false;
        this.buttons.filter.disabled = false;
        this.buttons.filter.processing = false;
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.getAllModel, null);
        }
      }
      this.buttons.reload.disabled = false;
      this.buttons.reload.processing = false;
      this.buttons.filter.disabled = false;
      this.buttons.filter.processing = false;
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
};
</script>
<style lang="scss" scoped>
.data-specification__content {
  padding: 0.5rem;
  padding-top: 0;
  .data-specification__datatable {
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .data-specification__structure {
    position: relative;
    margin-top: 0.5rem;
    .accordion {
      border-radius: 0.5rem;
    }
    .actions {
      position: absolute;
      right: 2.5rem;
      top: 0.5rem;
      button {
        border-radius: 50% !important;
        background: #009ef7;
        color: white;
        margin-right: 0.25rem;
        width: 1.6125rem;
        height: 1.6125rem;
        padding: 0.25rem;
        &:last-child {
          margin-right: 0;
        }
        &:disabled {
          background: #f4f4f4;
          color: #009ef7;
        }
        .pi {
          font-size: 0.75rem !important;
        }
      }
    }
  }
}
</style>