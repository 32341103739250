import axios from 'axios';
import basicApi from "@/utils/apis/basic";
import app from './../../main'
import { geoServiceApiURL } from "@/utils/commons/environment"
export default {
    async getStatistic(access_token){
        let headers = basicApi.statistic.get_statistic.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.statistic.get_statistic.method,
            url:  geoServiceApiURL + basicApi.statistic.get_statistic.url,
            headers:headers,
            params: {
                lang : app.$store.getters.getLang
            }
        });
        let response = await object;
        return response;
    },
    async getSummary(params,access_token){
        let headers = basicApi.statistic.get_summary.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        if (!params) params = {};
        params.lang = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.statistic.get_summary.method,
            url:  geoServiceApiURL + basicApi.statistic.get_summary.url,
            params: params,
            headers:headers,
        });
        let response = await object;
        return response;
    },
}