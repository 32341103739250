<template>
  <div id="app">
    <spinner
      :propLoading="loading"
      :propShape="'circle'"
      v-if="loading"
    ></spinner>
    <router-view v-else />
    <div
      class="HyiJ5emoe8Hs"
      v-if="isShowWidgetProgress"
      v-show="isWidgetUploadProgress"
    >
      <WidgetUploadProgressBar
        ref="wgUploadProgressBar"
        :isLayoutDefault="['/', ''].includes(routePath)"
        :prop-show="isShowWidgetProgress"
      />
    </div>

    <WidgetDownloadFiles
      v-if="widget.download.show"
      :prop-items="filesDownload"
      :prop-show="widget.download.show2"
      :isLayoutDefault="$route.path === '/' ? true : false"
      @close="closeWidgetDownload"
    />
  </div>
</template>
<script>
import Spinner from "@/components/commons/spinners/Index";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import WidgetUploadProgressBar from "@/components/commons/widget/UploadProgressBar";
import ProgressBar from "@/components/commons/progressbar/ProgressBar";
import WidgetDownloadFiles from "@/components/commons/widget/DownloadFiles";
export default {
  components: {
    Spinner,
    WidgetUploadProgressBar,
    ProgressBar,
    WidgetDownloadFiles,
  },
  data() {
    return {
      loading: true,
      counterTime: 0,
      intervelMethod: null,
      lockRefresh: null,
      isShowWidgetProgress: false,
      widget: {
        download: {
          show: false,
          show2: false,
        },
      },
    };
  },
  async created() {
    localStorage.removeItem("lock-refresh");
    // Spinner circle or square
    this.loading = true;
    let checkData = false;
    let data = await this.decryptDataOauth();
    if (data && Object.keys(data).length > 0) {
      let time = data.time ? data.time : null;
      let ttl = data.expires_in ? data.expires_in : null;
      if (time && ttl) {
        if (new Date().getTime() - time < ttl * 1000 - 5) {
          await this.$store.dispatch("setToken", data);
          await this.getMyInfo();
          checkData = true;
          await this.checkAccessRole();
          this.readLocalDataJson();
        }
      }
      if (!checkData) {
        let refreshToken = data.refresh_token ? data.refresh_token : null;
        if (refreshToken) {
          try {
            let check = await this.checkRefreshToken(refreshToken);
            if (check) {
              await this.getMyInfo();
              await this.checkAccessRole();
              this.readLocalDataJson();
            } else {
              if (this.$route.name != "Login") {
                this.$router.push({
                  name: "Login",
                  query: {
                    next: btoa(this.$route.path),
                  },
                });
              }
            }
          } catch (error) {
            this.$store.dispatch("clearToken").then((r) => {
              if (this.$route.name != "Login") {
                this.$router.push({
                  name: "Login",
                  query: {
                    next: btoa(this.$route.path),
                  },
                });
              }
            });
          }
        } else {
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      }
    } else {
      this.$store.dispatch("clearToken").then((r) => {
        if (this.$route.name != "Login") {
          this.$router.push({
            name: "Login",
            query: {
              next: btoa(this.$route.path),
            },
          });
        }
      });
    }
    setTimeout(() => {
      this.loading = false;
    }, 150);
    this.onResize();
  },
  mounted() {
    var vm = this;
    window.addEventListener("resize", vm.onResize, { passive: true });
    setTimeout(() => {
      Event.$on("imageProgressBar", (file) => {
        this.isShowWidgetProgress = true;
        setTimeout(() => {
          if (vm.$refs.wgUploadProgressBar) {
            vm.$refs.wgUploadProgressBar.addFile(file);
          }
        }, 50);
      });
    }, 2000);
  },

  watch: {
    loggedIn: {
      handler(val) {
        if (val) {
          if (this.intervelMethod) {
            clearInterval(this.intervelMethod);
            this.intervelMethod = null;
          }
          this.intervelMethod = setInterval(
            this.intervalCheckRefreshToken,
            10000
          );
          this.counterTime = 10;
        } else {
          if (this.intervelMethod) {
            clearInterval(this.intervelMethod);
            this.intervelMethod = null;
          }
        }
      },
      immediate: true,
    },
    isWidgetUploadProgress: {
      handler(val) {
        if (!val) {
          if (this.$refs.wgUploadProgressBar) {
            this.$refs.wgUploadProgressBar.clear();
            this.isShowWidgetProgress = false;
          }
        }
      },
      immediate: true,
    },
    filesDownload(val) {
      if (val && val.length > 0) {
        this.widget.download.show2 = true;
        this.widget.download.show = true;
      } else {
        this.closeWidgetDownload();
      }
    },
    lang(val) {},
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    spinner() {
      return this.$store.getters.getSpinner;
    },
    expiresIn() {
      return this.$store.getters.getExpiresIn;
    },
    routePath() {
      return this.$route.path;
    },
    isWidgetUploadProgress() {
      return this.$store.getters.getIsWidgetUploadProgress;
    },
    accessPaths() {
      return this.$store.getters.getAccessPaths;
    },
    userMe() {
      return this.$store.getters.getUser;
    },
    filesDownload() {
      return this.$store.getters.getFilesDownload;
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
  methods: {
    readLocalDataJson: function() {
      var vm = this;
      $.getJSON("/data/config.json", function(data) {
        if (data) {
          vm.$store.dispatch("setDataJson", data);
        }
      })
        .success(function(data) {})
        .error(function() {})
        .complete(function() {});
    },
    closeWidgetDownload() {
      this.widget.download.show2 = false;
      setTimeout(() => {
        this.widget.download.show = false;
      }, 200);
    },
    async checkAccessRole() {
      if (!this.accessPaths || this.accessPaths.length === 0) {
        if (!this.userMe || Object.keys(this.userMe).length === 0) {
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
              });
            }
          });
        } else {
          if (this.$route.name != "NotAuthorized") {
            this.$router.push({
              name: "NotAuthorized",
            });
          }
        }
      } else {
        let path;
        try {
          const urlParams = new URLSearchParams(window.location.search);
          path = urlParams.get("next");

          if (path) {
            try {
              path = await cryptoFunc.decrypt(atob(path));
            } catch (errorDecrypt) {
              path = null;
            }
          } else {
            path = this.$route.path;
          }
          if (path) {
            let resolved = this.$router.resolve(path);
            if (resolved.route.name || resolved.route.name != "404") {
              if (path === "/login") path = null;
            }
          }
        } catch (error) {
          path = null;
        }
        if (
          (path && this.accessPaths.includes(path)) ||
          this.accessPaths.filter(
            (x) => this.$route.matched.map((y) => x.path).length > 0
          )
        ) {
          if (path != this.$route.path) {
            this.$router.push({
              path: path,
            });
          }
        } else if (path && !this.accessPaths.includes(path) && path != "/") {
          this.$router.push({
            name: "NotFound",
          });
        } else {
          if (this.accessPaths[0] != this.$route.path) {
            this.$router.push({
              path: this.accessPaths[0],
            });
          }
        }
      }
    },
    async intervalCheckRefreshToken() {
      this.lockRefresh = localStorage.getItem("lockRefresh");
      if (this.lockRefresh) return;

      if (
        this.expiresIn === 0 ||
        (this.counterTime >= this.expiresIn - 29 &&
          this.counterTime <= this.expiresIn)
      ) {
        this.counterTime = this.expiresIn + 1;
        try {
          let str = localStorage.getItem("data");
          let plaintext = await cryptoFunc.decrypt(atob(str));
          if (plaintext) {
            let data = JSON.parse(plaintext);
            if (data && Object.keys(data).length > 0) {
              let time = data.time ? data.time : null;
              let ttl = data.expires_in ? data.expires_in : null;
              if (time && ttl) {
                if (new Date().getTime() - time < ttl * 1000 - 5) {
                  this.$store.dispatch("setToken", data);
                  this.counterTime = 0;
                  return;
                }
              }
            }
          }
        } catch (error) {}
        localStorage.setItem("lockRefresh", true);
        let check = await this.checkRefreshToken(
          this.$store.getters.getRefreshToken
        );
        localStorage.removeItem("lockRefresh");
        if (!check) {
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                next: btoa(cryptoFunc.encrypt(this.$route.path)),
              });
            }
          });
        }
      } else this.counterTime = this.counterTime + 10;
    },
    async decryptDataOauth() {
      try {
        let plaintext = await cryptoFunc.decrypt(
          atob(localStorage.getItem("data"))
        );
        if (plaintext) {
          let data = JSON.parse(plaintext);
          if (data) {
            return new Promise(function(resolve, reject) {
              resolve(data);
            });
          } else return {};
        } else return {};
      } catch (error) {
        return {};
      }
    },
    async checkRefreshToken(refreshToken) {
      var vm = this;
      return new Promise(async function(resolve, reject) {
        try {
          let lockRefresh = localStorage.getItem("lock-refresh");
          if (lockRefresh != null || lockRefresh != undefined) {
            if (
              lockRefresh &&
              (lockRefresh + "").trim().toLowerCase() === "true"
            ) {
              resolve(true);
              return;
            }
          }
          localStorage.setItem("lock-refresh", true);
          let response = await oauthFunc.refresh(refreshToken);
          if (response && response.status === 200) {
            vm.counterTime = 0;
            await vm.$store.dispatch("setToken", response.data);
            response.data.time = new Date().getTime();
            await localStorage.setItem(
              "data",
              btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
            );
            localStorage.removeItem("lock-refresh");
            resolve(true);
          } else {
            vm.$store.dispatch("clearToken");
            localStorage.removeItem("lock-refresh");
            resolve(false);
          }
        } catch (error) {
          vm.$store.dispatch("clearToken");

          localStorage.removeItem("lock-refresh");
          resolve(false);
        }

        localStorage.removeItem("lock-refresh");
      });
    },
    onResize() {
      this.$store.dispatch(
        "setDocumentWidth",
        document.documentElement.clientWidth
      );
      this.$store.dispatch(
        "setDocumentHeight",
        document.documentElement.clientHeight
      );
    },
    async getMyInfo() {
      try {
        let response = await oauthFunc.userMe(
          this.$store.getters.getAccessToken
        );
        if (response.status === 200) {
          if (response.data.roles && response.data.roles.length > 0) {
            this.$store.dispatch(
              "setAccessPaths",
              response.data.roles[0].urls ? response.data.roles[0].urls : []
            );
          }
          this.$store.dispatch("setUser", response.data);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getMyInfo);
        }
      }
    },

    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      var vm = this;
      window.removeEventListener("resize", vm.onResize, { passive: true });
    }

    if (this.intervelMethod) {
      clearInterval(this.intervelMethod);
      this.intervelMethod = null;
    }

    localStorage.removeItem("lock-refresh");
  },
};
</script>
<style lang="scss">
#app {
  font-family: "Helvetica Neue", Avenir, Helvetica, Arial, sans-serif;
  color: #1e1e2d !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px !important;
}

.mx-input {
  border-color: #a6a6a6;
  &:hover {
    border-color: #212121 !important;
  }
  &:disabled,
  .disabled {
    background-color: transparent;
  }
}
.datatable {
  .p-datatable {
    .p-datatable-thead > tr > th {
      text-align: left !important;
    }
    .p-datatable-tbody > tr:nth-child(even) {
      background: white;
    }
    .bg-gray {
      background: #f4f4f4cb !important;
    }
    .bg-danger {
      background: rgba($color: #dc3545, $alpha: 1) !important;
      color: white;
    }
    td {
      button {
        padding: 0.429em 0.6125em;
      }
    }
    td {
      &.p-frozen-column {
        position: sticky !important;
        button {
          width: 32px;
          height: 32px;
        }
      }
      &.left {
        left: 0;
      }
      &.right {
        right: 0;
      }
    }
    th {
      &.p-frozen-column {
        position: sticky !important;
        left: 0;
        button {
          width: 32px;
          height: 32px;
        }
      }
      &.left {
        left: 0;
      }
      &.right {
        right: 0;
      }
    }
  }
}
</style>
