import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/Home.vue";

import DashBoard from "@/components/pages/home/DashBoard.vue";

import Error404 from "@/views/Error";
import Error401 from "@/views/Error401";

import Login from "../views/Login.vue";

import Order from "@/views/Order";
import OrderList from "@/components/pages/order/Order";
import OrderConfirmed from "@/components/pages/order/Confirmed";
import OrderDetail from "@/components/pages/order/Detail";
import UserOrdered from "@/components/pages/ordered/Ordered";

import OrderTasking from "@/views/OrderTasking";

import GeoImage from "@/views/GeoImage";

import SharedByMe from "@/views/SharedByMe";
import Sharing from "@/views/Sharing";

import Upload from "@/views/Upload";
import Upload2 from "@/views/Upload2";
import UploadHistory from "@/views/UploadHistory";

import Customer from "@/views/Customer";

import Provider from "@/views/Provider";

import Satellite from "@/views/Model";

import User from "@/views/User";
import UserPage from "@/components/pages/user/User";

import Organisation from "@/views/Organisation";

import Authority from "@/views/Authority";

import Permission from "@/views/Permission";

import Category from "@/views/Category";
import CategoryDataList from "@/components/pages/category/DataList";
import PriorityLevel from "@/components/pages/category/PriorityLevel";
import OrderStatus from "@/components/pages/category/OrderStatus";
import ImageTypes from "@/components/pages/category/ImageTypes";
import ProcessingLevel from "@/components/pages/category/ProcessingLevel";

import ImagingMode from "@/components/pages/category/ImagingMode";
import Direction from "@/components/pages/category/Direction";
import PolLayer from "@/components/pages/category/PolLayer";
import OrganisationStatus from "@/components/pages/category/OrganisationStatus";
import ActionsLog from "@/components/pages/category/ActionsLog";
import CommonConfig from "@/components/pages/commonconfig/CommonConfig";

import StatisticalReport from "@/views/StatisticalReport";
import StatisticUpload from "@/components/pages/statisticalreport/StatisticUpload";
import StatisticDownload from "@/components/pages/statisticalreport/StatisticDownload";
import GeneralReport from "@/components/pages/statisticalreport/GeneralReport";

import UserMe from "@/components/pages/info/Information";

import DataSpecification from "@/views/DataSpecification";
import DataSpecificationList from "@/components/pages/category/dataspecification/List";

import Logs from "@/components/pages/logs/Logs";

Vue.use(VueRouter);
const routes = [
  {
    path: "/not-authorized",
    name: "NotAuthorized",
    component: Error401,
  },
  {
    path: "/not-found",
    name: "NotFound",
    component: Error404,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Đăng nhập",
    },
  },
  {
    path: "/",
    component: Home,
    redirect: {
      name: "DashBoard",
    },
    children: [
      {
        path: "",
        name: "DashBoard",
        component: DashBoard,
      },
      {
        path: "order",
        component: Order,
        children: [
          {
            path: "",
            name: "OrderList",
            component: OrderList,
          },
          {
            path: ":id",
            name: "UserOrdered",
            component: UserOrdered,
          },
        ],
      },

      {
        path: "order-tasking",
        name: "OrderTasking",
        component: OrderTasking,
      },
      {
        path: "satellite-image",
        name: "GeoImage",
        component: GeoImage,
      },
      {
        path: "shared-by-me",
        name: "SharedByMe",
        component: SharedByMe,
      },
      {
        path: "sharing",
        name: "Sharing",
        component: Sharing,
      },
      {
        path: "upload",
        name: "Upload",
        component: Upload,
      },
      {
        path: "upload-folder",
        name: "Upload2",
        component: Upload2,
      },

      {
        path: "upload-history",
        name: "UploadHistory",
        component: UploadHistory,
      },
      {
        path: "customer",
        name: "Customer",
        component: Customer,
      },
      {
        path: "role",
        name: "Role",
        component: Authority,
      },
      {
        path: "permission",
        name: "Permission",
        component: Permission,
      },
      {
        path: "user",
        name: "User",
        component: User,
        redirect: {
          name: "UserPage",
        },
        children: [
          {
            path: "",
            name: "UserPage",
            component: UserPage,
          },
        ],
      },
      {
        path: "statistical-report",
        component: StatisticalReport,
        children: [
          {
            path: "general",
            name: "GeneralReport",
            component: GeneralReport,
          },
          {
            path: "upload",
            name: "StatisticUpload",
            component: StatisticUpload,
          },
          {
            path: "download",
            name: "StatisticDownload",
            component: StatisticDownload,
          },
        ],
      },
      {
        path: "category",
        component: Category,
        redirect: {
          name: "Organisation",
        },
        children: [
          {
            path: "organisation",
            name: "Organisation",
            component: Organisation,
          },
          {
            path: "provider",
            name: "provider",
            component: Provider,
          },
          {
            path: "satellite",
            name: "Satellite",
            component: Satellite,
          },
          {
            path: "priority-level",
            name: "PriorityLevel",
            component: PriorityLevel,
          },
          {
            path: "order-status",
            name: "OrderStatus",
            component: OrderStatus,
          },
          {
            path: "image-types",
            name: "ImageTypes",
            component: ImageTypes,
          },
          {
            path: "data-catalog",
            name: "CategoryDataList",
            component: CategoryDataList,
          },
          {
            path: "processing-level",
            name: "ProcessingLevel",
            component: ProcessingLevel,
          },
          {
            path: "imaging-mode",
            name: "ImagingMode",
            component: ImagingMode,
          },
          {
            path: "direction",
            name: "Direction",
            component: Direction,
          },
          {
            path: "pol-layer",
            name: "PolLayer",
            component: PolLayer,
          },
          {
            path: "organisation-status",
            name: "OrganisationStatus",
            component: OrganisationStatus,
          },
          {
            path: "actions-log",
            name: "ActionsLog",
            component: ActionsLog,
          },
          {
            path: "data-specification",
            component: DataSpecification,
            redirect: {
              name: "DataSpecificationList",
            },
            children: [
              {
                path: "",
                name: "DataSpecificationList",
                component: DataSpecificationList,
                meta: {
                  title: "Thông số vệ tinh",
                },
              },
            ],
          },
          {
            path: "*",
            name: "Error404",
            component: Error404,
          },
        ],
      },
      {
        path: "me",
        component: UserMe,
        name: "UserMe",
      },
      {
        path: "common-config",
        component: CommonConfig,
        name: "CommonConfig",
      },
      {
        path: "logs",
        component: Logs,
        name: "Logs",
      },
      {
        path: "*",
        name: "Error404",
        component: Error404,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
