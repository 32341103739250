<template>
  <Dialog
    :header="propTitle"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :maximizable="false"
    :modal="true"
    :contentStyle="propStyle"
    @hide="onClose"
    @maximizedChage="maximizedChage"
  >
    <div class="Iybw1Bhj3Jhd8s update-metadata">
      <div>
        <table class="table">
          <tbody>
            <tr>
              <td
                align="left"
                width="30%"
                style="vertical-align: top"
                v-if="!maximized"
              >
                <div>
                  <table
                    class="table-images w-100"
                    cellspacing="0"
                    cellpadding="0"
                  >
                    <tbody>
                      <tr>
                        <td class="p-0">
                          <p class="border-radius-4 bg-A1A5B7">
                            {{ $t("label_icon_image") }}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="p-0 pt-2">
                          <div
                            class="
                              d-flex
                              justify-content-between
                              align-items-center
                            "
                          >
                            <Dropdown
                              class="w-100"
                              v-model="entity.icon_path"
                              :showClear="false"
                              :options="icons"
                              optionValue="path"
                              optionLabel="path"
                            />
                            <div class="upload-btn-wrapper" v-if="!iconFile">
                              <button class="btn btn-primary btn-sm ml-1 mr-2">
                                <i class="fas fa-plus"></i>
                              </button>
                              <input
                                type="file"
                                name="iconfile"
                                accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                @change="fileIconChanged"
                              />
                            </div>

                            <button
                              v-if="iconFile"
                              class="btn btn-danger btn-sm ml-1"
                              @click="
                                iconFile = null;
                                iconSrc = null;
                              "
                            >
                              <i class="fas fa-times"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="p-0 pt-2 pb-2">
                          <div class="preview-icon" v-if="iconSrc">
                            <img :src="iconSrc" class="preview-image" />
                          </div>
                          <ImagePreview
                            v-else
                            :propId="entity.icon_path ? entity.icon_path : null"
                            :propSrc="
                              entity.icon_path ? entity.icon_path : null
                            "
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="p-0">
                          <p class="border-radius-4 bg-A1A5B7">
                            {{ $t("label_preview_image") }}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="p-0 pt-2">
                          <div
                            class="
                              d-flex
                              justify-content-between
                              align-items-center
                            "
                          >
                            <Dropdown
                              class="w-100"
                              v-model="entity.preview_path"
                              :showClear="false"
                              :options="previews"
                              optionValue="path"
                              optionLabel="path"
                            />
                            <div class="upload-btn-wrapper" v-if="!previewFile">
                              <button class="btn btn-primary btn-sm ml-1 mr-2">
                                <i class="fas fa-plus"></i>
                              </button>
                              <input
                                type="file"
                                name="previewfile"
                                accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                @change="filePreviewChanged"
                              />
                            </div>
                            <button
                              v-if="previewFile"
                              class="btn btn-danger btn-sm ml-1"
                              @click="
                                previewFile = null;
                                previewSrc = null;
                              "
                            >
                              <i class="fas fa-times"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="p-0 pt-2">
                          <div class="preview-icon" v-if="previewSrc">
                            <img :src="previewSrc" class="preview-image" />
                          </div>
                          <ImagePreview
                            v-else
                            :propId="
                              entity.preview_path ? entity.preview_path : null
                            "
                            :propSrc="
                              entity.preview_path ? entity.preview_path : null
                            "
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
              <td
                align="left"
                style="vertical-align: top"
                :style="[maximized ? { width: '30%' } : { width: '70%' }]"
              >
                <p class="border-radius-4 bg-A1A5B7">
                  {{ $t("label_values") }}
                </p>
                <div>
                  <table
                    class="table-metadata w-100"
                    id="tableMetadata"
                    cellspacing="0"
                    cellpadding="0"
                  >
                    <tbody>
                      <tr>
                        <td class="pl-0 pr-0 pt-0" style="width: 10rem">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_source_id") }}</span
                          >
                        </td>
                        <td class="d-flex align-items-center">
                          <span
                            :title="
                              entity.scene_id && entity.scene_id.length > 48
                                ? entity.scene_id
                                : null
                            "
                            >{{
                              entity.scene_id && entity.scene_id.length > 48
                                ? entity.scene_id.substring(0, 45) + "..."
                                : entity.scene_id
                            }}</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_image_name") }}</span
                          >
                        </td>
                        <td>
                          <InputText v-model="entity.name" class="w-100" />
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_satellite") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <treeselect
                            class="w-50"
                            v-model="entity.satellite_id"
                            name="treeSatellite"
                            :multiple="false"
                            :options="propProvider"
                            :noChildrenText="$t('label_no_sub_options')"
                            :noOptionsText="$t('label_no_options_text')"
                            :noResultsText="$t('label_no_results_text')"
                            :disableBranchNodes="true"
                            placeholder=""
                            :default-expand-level="1"
                          >
                            <label slot="option-label" slot-scope="{ node }">{{
                              node.raw.label
                            }}</label>
                            <div slot="value-label" slot-scope="{ node }">
                              {{ node.raw.label }}
                            </div>
                          </treeselect>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_image_time") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <date-picker
                            class="w-50"
                            v-model="entity.imaging_date_timestamp"
                            :format="'DD/MM/YYYY HH:mm'"
                            valueType="timestamp"
                            :clearable="false"
                            :disabled-date="notAfterToday"
                            type="datetime"
                            :show-time-panel="showTimePanel"
                            @close="handleOpenChange"
                          >
                            <template v-slot:footer>
                              <button
                                class="mx-btn mx-btn-text"
                                @click="toggleTimePanel"
                              >
                                {{
                                  showTimePanel
                                    ? $t("button_text_select_date")
                                    : $t("button_text_select_time")
                                }}
                              </button>
                            </template>
                          </date-picker>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_image_type") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <span v-text="entity.image_type"></span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_incidence_angle") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <div class="row no-gutters">
                            <div class="col-8">
                              <InputText
                                v-model="entity.incidence_angle"
                                class="w-100"
                              />
                            </div>
                            <div class="col-4 pl-2">
                              <InputText value="°" readonly class="w-100" />
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_resolution") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <div class="row no-gutters">
                            <div class="col-8">
                              <InputText
                                v-model="entity.resolution"
                                class="w-100"
                                maxLength="50"
                              />
                            </div>

                            <div class="col-4 pl-2">
                              <InputText value="m" readonly class="w-100" />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_processing_level") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <Dropdown
                            class="w-50"
                            v-model="entity.processing_level_name"
                            :showClear="false"
                            :options="propProcessingLevel"
                            optionValue="name"
                            optionLabel="value"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_cloud_cove") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <div class="row no-gutters">
                            <div class="col-8">
                              <InputText
                                v-model="entity.cloud_coverage"
                                class="w-100"
                              />
                            </div>
                            <div class="col-4 pl-2">
                              <InputText
                                v-model="entity.cloud_coverage_unit"
                                class="w-100"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr class="pt-0">
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_snow_cove") }}
                          </span>
                        </td>
                        <td>
                          <div class="row no-gutters">
                            <div class="col-8">
                              <InputText
                                v-model="entity.snow_coverage"
                                class="w-100"
                              />
                            </div>
                            <div class="col-4 pl-2">
                              <InputText
                                v-model="entity.snow_coverage_unit"
                                class="w-100"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_area") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <div class="row no-gutters">
                            <div class="col-8">
                              <InputText v-model="entity.area" class="w-100" />
                            </div>
                            <div class="col-4 pl-2">
                              <InputText
                                v-model="entity.area_unit"
                                class="w-100"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_image_mode") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <AutoComplete
                            v-model="entity.beam_mode"
                            :suggestions="arrayBeamMode"
                            @complete="searchBeamMode($event)"
                            @item-select="itemSelectBeamMode"
                            @item-unselect="itemUnselectBeamMode"
                            @clear="clearItemBeamMode"
                            :dropdown="false"
                            class="w-100"
                          />
                          <!-- <InputText v-model="entity.beam_mode" class="w-100" /> -->
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_direction") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <AutoComplete
                            v-model="entity.direction"
                            :suggestions="arrayDirection"
                            @complete="searchDirection($event)"
                            :dropdown="false"
                            class="w-100"
                          />
                          <!-- <InputText v-model="entity.direction" class="w-100" /> -->
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              line-height-36
                              d-block
                              pl-2
                            "
                            >{{ $t("label_pol_layer") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <AutoComplete
                            v-model="entity.pol_layer"
                            :suggestions="arrayPolLayer"
                            @complete="searchPolLayer($event)"
                            :dropdown="false"
                            class="w-100"
                          />
                          <!-- <InputText v-model="entity.pol_layer" class="w-100" /> -->
                        </td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-0 pt-0">
                          <span
                            class="
                              border-radius-4
                              bg-009EF7
                              w-100
                              d-block
                              line-height-56
                              pl-2
                            "
                            >{{ $t("label_description") }}</span
                          >
                        </td>
                        <td class="pt-0">
                          <Textarea
                            v-model="entity.description"
                            :autoResize="true"
                            rows="2"
                            cols="30"
                            class="w-100"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <tile
        v-if="propItem.loading_data"
        :loading="propItem.loading_data"
        :height="'40'"
        :width="'40'"
        :color="'fd853e'"
        class="ehAbnmg4Jwb9BS"
      ></tile>
    </div>
    <template #footer>
      <Button
        :label="$t('button_text_close')"
        :icon="'fas fa-times'"
        :class="'p-button-danger mr-1'"
        @click="onClose"
      />
      <Button
        :label="$t('button_text_save')"
        :icon="'fas fa-save'"
        :class="'p-button-success mr-0'"
        @click="handleUpdateMetadata"
        :disabled="propItem.loading_data"
      />
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
import Button from "@/components/commons/button/Button";
import MapOpenlayer from "./updatemetadata/Map";
import QuickLook from "./updatemetadata/QuickLook";
import InputText from "@/components/commons/inputtext/InputText";
import Textarea from "@/components/commons/textarea/Textarea";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import ImagePreview from "./updatemetadata/ImagePreview.vue";
import DatePicker from "vue2-datepicker";
import AutoComplete from "@/components/commons/autocomplete/AutoComplete";
import Treeselect from "@riophae/vue-treeselect";
import { TileSpinner } from "vue-spinners";
import moment from "moment";
import { change_alias } from "@/utils/commons/common";
export default {
  components: {
    Dialog,
    Button,
    MapOpenlayer,
    QuickLook,
    Tile: TileSpinner,
    InputText,
    Textarea,
    Dropdown,
    ImagePreview,
    DatePicker,
    Treeselect,
    AutoComplete,
  },
  props: {
    propTitle: {
      type: String,
      default: "",
    },
    propStyle: {
      type: Object,
      default: () => {
        return {
          width: "400px",
        };
      },
    },
    propShow: {
      type: Boolean,
      default: false,
    },
    propItem: {
      type: Object,
      default: () => {},
    },

    propProvider: {
      type: Array,
      default: () => [],
    },
    propProcessingLevel: {
      type: Array,
      default: () => [],
    },
    propBeamMode: {
      type: Array,
      default: () => [],
    },
    propDirection: {
      type: Array,
      default: () => [],
    },
    propPolLayer: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: false,
      showTimePanel: false,
      maximized: false,
      isEdit: true,
      previews: [],
      icons: [],
      arrayBeamMode: [],
      arrayDirection: [],
      arrayPolLayer: [],
      entity: {
        area: null,
        area_unit: null,
        band: null,
        cloud_coverage: null,
        cloud_coverage_unit: null,
        description: null,
        google_earth_path: null,
        image_type: null,
        imaging_date: null,
        imaging_date_timestamp: null,
        incidence_angle: null,
        metadata: {},
        extend: {},
        name: null,
        resolution: null,
        snow_coverage: null,
        snow_coverage_unit: null,
        processing_level: null,
        processing_level_id: null,
        mode: null,
        beam_mode: null,
        direction: null,
        pol_layer: null,
        satellite: null,
        satellite_id: null,
        bounding_box: [],
        provider: null,
        provider_id: null,
        file_path: null,
        icon_path: null,
        icon_path_id: null,
        icon_file: null,
        icon_src: null,
        preview_images: [],
        preview_path: null,
        preview_file: null,
        preview_src: null,
        preview_path_id: null,
        price: 0,
        price_unit: null,
        processing_level_name: null,
        provider_name: null,
        satellite_name: null,
        size: 0,
        scene_id: null,
        preview: {
          preview_images: [],
        },
      },
      previewFile: null,
      previewSrc: null,
      iconFile: null,
      iconSrc: null,
    };
  },
  created() {
    this.show = this.propShow;
    if (!this.propProcessingLevel || this.propProcessingLevel.length === 0)
      this.$emit("getAllProcessingLevel");
    if (!this.propBeamMode || this.propBeamMode.length === 0)
      this.$emit("getAllBeamMode");
    if (!this.propDirection || this.propDirection.length === 0)
      this.$emit("getAllDirection");
    if (!this.propPolLayer || this.propPolLayer.length === 0)
      this.$emit("getAllPolLayer");
    if (this.propItem && Object.keys(this.propItem).length > 0) {
      if (
        this.propItem.entity &&
        Object.keys(this.propItem.entity).length > 0
      ) {
        this.entity = Object.assign({}, this.propItem.entity);
      } else {
        this.assignData(this.propItem.data_file);
      }
    }
  },
  watch: {},
  methods: {
    async filePreviewChanged(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.previewSrc = URL.createObjectURL(files[0]);
      this.previewFile = files[0];
    },
    async fileIconChanged(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.iconSrc = URL.createObjectURL(files[0]);
      this.iconFile = files[0];
    },
    searchPolLayer(e) {
      this.arrayPolLayer = this.propPolLayer
        .filter((x) =>
          change_alias(
            x.value
              .trim()
              .split(" ")
              .join("")
          ).startsWith(
            change_alias(
              e.query
                .toLowerCase()
                .split(" ")
                .join("")
            )
          )
        )
        .map((x) => x.value);
    },
    searchDirection(e) {
      this.arrayDirection = this.propDirection
        .filter((x) =>
          change_alias(
            x.value
              .trim()
              .split(" ")
              .join("")
          ).startsWith(
            change_alias(
              e.query
                .toLowerCase()
                .split(" ")
                .join("")
            )
          )
        )
        .map((x) => x.value);
    },
    searchBeamMode(e) {
      this.arrayBeamMode = this.propBeamMode
        .filter((x) =>
          change_alias(
            x.value
              .trim()
              .split(" ")
              .join("")
          ).startsWith(
            change_alias(
              e.query
                .toLowerCase()
                .split(" ")
                .join("")
            )
          )
        )
        .map((x) => x.value);
    },
    itemSelectBeamMode(item) {
      if (item && item.value) {
      }
    },
    itemUnselectBeamMode(event) {},
    clearItemBeamMode(e) {},

    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel;
    },
    handleOpenChange() {
      this.showTimePanel = false;
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    assignData(data) {
      this.entity = this.generateEntity();
      this.previews = [];
      this.icons = [];
      if (data && Object.keys(data).length > 0) {
        this.entity.scene_id = data.scene_id;
        this.entity.preview = data.preview
          ? data.preview
          : { preview_images: [] };
        this.entity.icon_path =
          data.preview && data.preview.icon_path
            ? data.preview.icon_path
            : null;
        this.entity.preview_path =
          data.preview && data.preview.preview_path
            ? data.preview.preview_path
            : null;
        this.entity.id = data.id;
        this.entity.preview_images =
          data.preview && data.preview.preview_images
            ? data.preview.preview_images
            : [];
        if (
          this.entity.preview_images &&
          this.entity.preview_images.length > 0
        ) {
          this.previews = this.entity.preview_images.filter(
            (x) => x.type === "PREVIEW"
          );
          this.icons = this.entity.preview_images.filter(
            (x) => x.type === "ICON"
          );
          let googleEarths = this.entity.preview_images.filter(
            (x) => x.type === "GOOGLE_EARTH"
          );
          if (googleEarths.length > 0)
            this.entity.google_earth_path = googleEarths[0].path_image;
        }
        this.entity.area =
          data.area != null && data.area != undefined ? data.area : null;
        this.entity.area_unit = data.area_unit ? data.area_unit.trim() : null;
        this.entity.band =
          data.band != null && data.band != undefined ? data.band : null;
        this.entity.cloud_coverage =
          data.cloud_coverage != null && data.cloud_coverage != undefined
            ? data.cloud_coverage
            : null;
        this.entity.cloud_coverage_unit = data.cloud_coverage_unit
          ? data.cloud_coverage_unit.trim()
          : null;

        this.entity.description = data.description
          ? data.description.trim()
          : null;
        this.entity.file_path = data.file_path ? data.file_path.trim() : null;

        this.entity.imaging_date = data.imaging_date ? data.imaging_date : null;
        if (this.entity.imaging_date) {
          try {
            this.entity.imaging_date_timestamp = new Date(
              this.entity.imaging_date
            ).getTime();
          } catch (error) {}
        }
        if (!this.entity.imaging_date && !this.entity.imaging_date_timestamp) {
          if (data.info && data.info.imaging_date) {
            this.entity.imaging_date = data.info.imaging_date;
            try {
              this.entity.imaging_date_timestamp = new Date(
                data.info.imaging_date
              ).getTime();
            } catch (error) {}
          }
        }
        this.entity.incidence_angle =
          data.incidence_angle != null && data.incidence_angle != undefined
            ? data.incidence_angle
            : null;
        this.entity.metadata = data.metadata ? data.metadata : {};

        this.entity.mode = data.beam_mode ? data.beam_mode.trim() : null;
        this.entity.beam_mode = data.beam_mode ? data.beam_mode.trim() : null;
        this.entity.name = data.name ? data.name.trim() : null;
        this.entity.pol_layer = data.pol_layer ? data.pol_layer.trim() : null;
        this.entity.price =
          data.price != null && data.price != undefined ? data.price : null;
        this.entity.price_unit = data.price_unit
          ? data.price_unit.trim()
          : null;
        this.entity.processing_level =
          data.metadata && data.metadata.processing_level
            ? data.metadata.processing_level
            : null;
        if (
          this.entity.processing_level &&
          Object.keys(this.entity.processing_level).length > 0
        ) {
          this.entity.processing_level_id = this.entity.processing_level.id;
          this.entity.processing_level_name = this.entity.processing_level.name;
        }

        this.entity.resolution =
          data.resolution != undefined && data.resolution != null
            ? data.resolution
            : null;
        this.entity.satellite =
          data.metadata.satellite && data.metadata.satellite
            ? data.metadata.satellite
            : null;

        if (!this.entity.satellite && data.info && data.info.satellite) {
          this.entity.satellite = data.info.satellite;
        }
        if (
          this.entity.satellite &&
          Object.keys(this.entity.satellite).length > 0
        ) {
          this.entity.satellite_id = this.entity.satellite.id;
          this.entity.satellite_name = this.entity.satellite.name;
        }

        this.entity.size =
          data.size != undefined && data.size != null ? data.size : null;

        this.entity.snow_coverage =
          data.snow_coverage != undefined && data.snow_coverage != null
            ? data.snow_coverage
            : null;
        this.entity.snow_coverage_unit = data.snow_coverage_unit
          ? data.snow_coverage_unit.trim()
          : null;

        if (data.image_type)
          this.entity.image_type =
            data.image_type && data.image_type.value
              ? data.image_type.value
              : null;
        if (data.metadata && Object.keys(data.metadata).length > 0) {
          if (this.entity.area === null || this.entity.area === undefined) {
            this.entity.area = data.metadata.area;
          }
          if (
            this.entity.area_unit === null ||
            this.entity.area_unit === undefined
          ) {
            this.entity.area_unit = data.metadata.area_unit;
          }
          if (this.entity.band === null || this.entity.band === undefined) {
            this.entity.band = data.metadata.band;
          }

          if (
            this.entity.cloud_coverage === null ||
            this.entity.cloud_coverage === undefined
          ) {
            this.entity.cloud_coverage = data.metadata.cloud_coverage;
          }
          if (
            this.entity.cloud_coverage_unit === null ||
            this.entity.cloud_coverage_unit === undefined
          ) {
            this.entity.cloud_coverage_unit = data.metadata.cloud_coverage_unit;
          }

          if (
            this.entity.snow_coverage === null ||
            this.entity.snow_coverage === undefined
          ) {
            this.entity.snow_coverage = data.metadata.snow_coverage;
          }
          if (
            this.entity.snow_coverage_unit === null ||
            this.entity.snow_coverage_unit === undefined
          ) {
            this.entity.snow_coverage_unit = data.metadata.snow_coverage_unit;
          }

          if (
            this.entity.imaging_date === null ||
            this.entity.imaging_date === undefined
          ) {
            this.entity.imaging_date = data.metadata.imaging_date;
            if (this.entity.imaging_date) {
              try {
                this.entity.imaging_date_timestamp = new Date(
                  this.entity.imaging_date
                ).getTime();
              } catch (error) {}
            }
          }

          if (this.entity.mode === null || this.entity.mode === undefined) {
            this.entity.mode = data.metadata.beam_mode;
            this.entity.beam_mode = data.metadata.beam_mode;
          }

          if (
            this.entity.incidence_angle === null ||
            this.entity.incidence_angle === undefined
          ) {
            this.entity.incidence_angle = data.metadata.incidence_angle;
          }
          if (
            this.entity.pol_layer === null ||
            this.entity.pol_layer === undefined
          ) {
            this.entity.pol_layer = data.metadata.pol_layer;
          }
          if (
            this.entity.direction === null ||
            this.entity.direction === undefined
          ) {
            this.entity.direction = data.metadata.direction;
          }

          if (
            this.entity.resolution === null ||
            this.entity.resolution === undefined
          ) {
            this.entity.resolution = data.metadata.resolution;
          }

          if (data.metadata.bounding_box) {
            let coors = data.metadata.bounding_box;
            if (!this.entity.bounding_box) this.entity.bounding_box = [];
            for (let i = 0; i < coors.length; i++) {
              this.entity.bounding_box.push([
                coors[i].longitude,
                coors[i].latitude,
              ]);
            }
            if (coors.length === 4) {
              this.entity.bounding_box.push([
                coors[0].longitude,
                coors[0].latitude,
              ]);
            }
          }
        }

        if (
          data.metadata_extra &&
          Object.keys(data.metadata_extra).length > 0
        ) {
          if (this.entity.area === null || this.entity.area === undefined) {
            this.entity.area = data.metadata_extra.area;
          }
          if (
            this.entity.area_unit === null ||
            this.entity.area_unit === undefined
          ) {
            this.entity.area_unit = data.metadata_extra.area_unit;
          }
          if (this.entity.band === null || this.entity.band === undefined) {
            this.entity.band = data.metadata_extra.band;
          }

          if (
            this.entity.cloud_coverage === null ||
            this.entity.cloud_coverage === undefined
          ) {
            this.entity.cloud_coverage = data.metadata_extra.cloud_coverage;
          }
          if (
            this.entity.cloud_coverage_unit === null ||
            this.entity.cloud_coverage_unit === undefined
          ) {
            this.entity.cloud_coverage_unit =
              data.metadata_extra.cloud_coverage_unit;
          }

          if (
            this.entity.snow_coverage === null ||
            this.entity.snow_coverage === undefined
          ) {
            this.entity.snow_coverage = data.metadata_extra.snow_coverage;
          }
          if (
            this.entity.snow_coverage_unit === null ||
            this.entity.snow_coverage_unit === undefined
          ) {
            this.entity.snow_coverage_unit =
              data.metadata_extra.snow_coverage_unit;
          }

          if (
            this.entity.imaging_date === null ||
            this.entity.imaging_date === undefined
          ) {
            this.entity.imaging_date = data.metadata_extra.imaging_date;
            if (this.entity.imaging_date) {
              try {
                this.entity.imaging_date_timestamp = new Date(
                  this.entity.imaging_date
                ).getTime();
              } catch (error) {}
            }
          }

          if (this.entity.mode === null || this.entity.mode === undefined) {
            this.entity.mode = data.metadata_extra.beam_mode;
            this.entity.beam_mode = data.metadata_extra.beam_mode;
          }

          if (
            this.entity.incidence_angle === null ||
            this.entity.incidence_angle === undefined
          ) {
            this.entity.incidence_angle = data.metadata_extra.incidence_angle;
          }
          if (
            this.entity.pol_layer === null ||
            this.entity.pol_layer === undefined
          ) {
            this.entity.pol_layer = data.metadata_extra.pol_layer;
          }
          if (
            this.entity.direction === null ||
            this.entity.direction === undefined
          ) {
            this.entity.direction = data.metadata_extra.direction;
          }

          if (
            this.entity.resolution === null ||
            this.entity.resolution === undefined
          ) {
            this.entity.resolution = data.metadata_extra.resolution;
          }
        }
        this.entity.cloud_coverage =
          this.entity.cloud_coverage != null &&
          this.entity.cloud_coverage != undefined &&
          (this.entity.cloud_coverage + "").trim() != "-1"
            ? this.entity.cloud_coverage
            : null;
        this.entity.snow_coverage =
          this.entity.snow_coverage != null &&
          this.entity.snow_coverage != undefined &&
          (this.entity.snow_coverage + "").trim() != "-1"
            ? this.entity.snow_coverage
            : null;
      }
    },
    generateEntity() {
      return {
        area: null,
        area_unit: null,
        band: null,
        cloud_coverage: null,
        cloud_coverage_unit: null,
        description: null,
        google_earth_path: null,
        image_type: null,
        imaging_date: null,
        imaging_date_timestamp: null,
        incidence_angle: null,
        metadata: {},
        extend: {},
        name: null,
        resolution: null,
        snow_coverage: null,
        snow_coverage_unit: null,
        processing_level: null,
        processing_level_id: null,
        mode: null,
        beam_mode: null,
        direction: null,
        pol_layer: null,
        satellite: null,
        satellite_id: null,
        bounding_box: [],
        provider: null,
        provider_id: null,
        file_path: null,
        icon_path: null,
        icon_path_id: null,
        icon_file: null,
        preview_images: [],
        preview_path: null,
        preview_path_id: null,
        preview_file: null,
        preview: {
          preview_images: [],
        },
        price: 0,
        price_unit: null,
        processing_level_name: null,
        provider_name: null,
        satellite_name: null,
        size: 0,
        scene_id: null,
      };
    },
    onClose() {
      this.show = false;
      this.$emit("close");
    },
    handleUpdateMetadata() {
      if (!this.entity.name || !this.entity.name.trim()) {
        this.$emit("message", [this.$t("message_image_name_required")]);
        return;
      }
      if (this.previewFile) this.entity.preview_file = this.previewFile;
      if (this.iconFile) {
        this.entity.icon_file = this.iconFile;
      }
      this.$emit("confirmMetadata", this.entity);
    },
    handleEdit() {
      this.isEdit = !this.isEdit;
    },
    maximizedChage(val) {
      this.maximized = val;
    },
    formatUnixDateTime2String(value) {
      let result = "";
      if (value) {
        try {
          let date = new Date(value);
          result = moment
            .unix(date.getTime() / 1000)
            .format("HH:mm DD/MM/YYYY");
          result = result
            .replace(":", this.lang === "vi" ? "h" : ":")
            .replace(" ", this.lang === "vi" ? " ngày " : " ");
        } catch (error) {}
      }
      return result;
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
.Iybw1Bhj3Jhd8s {
  position: relative;
  width: 100%;
  height: 100%;
  .ehAbnmg4Jwb9BS {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 3;
  }
}
table {
  margin-bottom: 0;
  td {
    border-top: none;
    p {
      background: #f0f0f0;
      font-size: 14px;
      padding: 0.5rem;
    }
    .map-preview {
      position: relative;
    }
  }
}
table.table-images {
  p {
    margin: 0 !important;
  }
}
.preview {
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: white;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    &:hover {
      cursor: pointer;
    }
  }
}
.bg-A1A5B7 {
  background: #a1a5b7;
  color: white;
}
.bg-009EF7 {
  background: #009ef7;
  color: white;
}
.bg-red {
  background: red;
  height: 36px;
  line-height: 35px;
}
.border-radius-4 {
  border-radius: 4px;
}
.line-height-36 {
  line-height: 36px;
}
.line-height-56 {
  line-height: 56px;
}
table.table-metadata {
  border-collapse: separate;
  border-spacing: 0 0.125rem;
  tr {
    margin-bottom: 0.25rem;
    td {
      padding: 0.5rem 0.75rem;
      border: 1px solid white;
      &:first-child {
        color: white;
        border-radius: 0.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.preview-icon {
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: white;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss">
.Iybw1Bhj3Jhd8s {
  .vue-treeselect__control {
    border-color: #a6a6a6;
    border-radius: 3px !important;
    &:hover {
      border-color: #212121 !important;
    }
  }
  .mx-input {
    border-color: #a6a6a6;
    border-radius: 3px !important;
    &:hover {
      border-color: #212121 !important;
    }
    &:disabled,
    .disabled {
      background-color: transparent;
    }
  }
  .p-autocomplete-input {
    width: 100%;
  }
}
</style>

<style lang="scss">
.update-metadata {
  .custom-file-label::after {
    background: #009ef7 !important;
    color: white;
  }
}
</style>
