<template>
  <div class="images-info">
    <CommonAccordion :isChevronEnd="true" :propTitle="$t('label_order_images')"
      ><div slot="content" class="accordion w-100">
        <div class="datatable">
          <ContextMenu
            :model="menuModel"
            ref="cmDlgOrderDetailDatatable"
            :appendTo="'dialogOrderDetail'"
          />
          <DataTable
            :value="propItems"
            ref="dt"
            :resizableColumns="true"
            columnResizeMode="expand"
            selectionMode="single"
            dataKey="id"
            contextMenu
            :selection.sync="selected"
            @row-contextmenu="onRowContextMenu"
            @row-click="rowClick"
          >
            <Column>
              <template #body="slotProps">
                <div class="d-flex">
                  <div v-if="propOrderStatusCompleted">
                    <div
                      class="img-shared text-center"
                      v-if="propShared.includes(slotProps.data.id)"
                    >
                      <i class="fas fa-check text-success"></i>
                    </div>
                    <Checkbox
                      class="pt-1 mr-2"
                      v-else
                      v-model="selectedIds"
                      :binary="false"
                      :value="slotProps.data.id"
                    />
                  </div>

                  <div class="media">
                    <icon-preview
                      :propId="
                        slotProps.data.preview &&
                        slotProps.data.preview.icon_path
                          ? slotProps.data.preview.icon_path
                          : slotProps.data.preview &&
                            slotProps.data.preview.preview_path
                          ? slotProps.data.preview.preview_path
                          : null
                      "
                      :prop-src="
                        slotProps.data.preview &&
                        slotProps.data.preview.icon_path
                          ? slotProps.data.preview.icon_path
                          : slotProps.data.preview &&
                            slotProps.data.preview.preview_path
                          ? slotProps.data.preview.preview_path
                          : null
                      "
                      :propPreviewImages="
                        slotProps.data.preview &&
                        slotProps.data.preview.preview_images
                          ? slotProps.data.preview.preview_images
                          : []
                      "
                    ></icon-preview>
                    <div class="media-body">
                      <ul>
                        <li class="lbl-geostore">
                          <strong>{{ slotProps.data.name }}</strong>
                        </li>
                        <li class="mt-1">
                          <div class="">
                            <span
                              ><strong>{{ $t("label_image_time") }}:</strong>
                              {{
                                (new Date(
                                  slotProps.data.imaging_date
                                    ? slotProps.data.imaging_date
                                    : slotProps.data.metadata &&
                                      slotProps.data.metadata.imaging_date
                                    ? slotProps.data.metadata.imaging_date
                                    : null
                                ) /
                                  1000)
                                  | formatUnixDateTime
                              }}
                            </span>
                            <span class="pl-2 pr-2">|</span>
                            <span
                              ><strong>{{ $t("label_satellite") }}: </strong>
                              {{
                                slotProps.data.satellite
                                  ? slotProps.data.satellite.value
                                  : slotProps.data.metadata &&
                                    slotProps.data.metadata.satellite
                                  ? slotProps.data.metadata.satellite.value
                                  : slotProps.data.metadata_extra
                                  ? (slotProps.data.metadata_extra.mission
                                      ? slotProps.data.metadata_extra.mission
                                      : "") +
                                    " " +
                                    (slotProps.data.metadata_extra.mission_index
                                      ? slotProps.data.metadata_extra
                                          .mission_index
                                      : "")
                                  : ""
                              }}
                            </span>
                          </div>
                        </li>
                        <li>
                          <div class="mt-1">
                            <span
                              ><strong>Res (m):</strong>
                              {{
                                slotProps.data.resolution
                                  ? parseFloat(
                                      slotProps.data.resolution
                                    ).toFixed(4)
                                  : slotProps.data.metadata &&
                                    slotProps.data.metadata.resolution
                                  ? parseFloat(
                                      slotProps.data.metadata.resolution
                                    ).toFixed(4)
                                  : ""
                              }}
                            </span>
                            <span class="pl-1 pr-1">|</span>
                            <span
                              ><strong>Inc Ang (°):</strong>
                              {{
                                slotProps.data.incidence_angle
                                  ? parseFloat(
                                      slotProps.data.incidence_angle
                                    ).toFixed(4)
                                  : slotProps.data.metadata &&
                                    slotProps.data.metadata.incidence_angle
                                  ? parseFloat(
                                      slotProps.data.metadata.incidence_angle
                                    ).toFixed(4)
                                  : ""
                              }}
                            </span>
                            <span class="pl-1 pr-1">|</span>
                            <span
                              ><strong>Cloud (%):</strong>
                              {{
                                slotProps.data.cloud_coverage &&
                                slotProps.data.cloud_coverage != -1
                                  ? parseFloat(
                                      slotProps.data.cloud_coverage
                                    ).toFixed(2)
                                  : slotProps.data.metadata &&
                                    slotProps.data.metadata.cloud_coverage &&
                                    slotProps.data.metadata.cloud_coverage != -1
                                  ? parseFloat(
                                      slotProps.data.metadata.cloud_coverage
                                    ).toFixed(2)
                                  : ""
                              }}
                            </span>
                            <span v-if="slotProps.data.type == 'RADAR'"
                              ><strong>Look dir:</strong>
                              {{ slotProps.data.direction }}</span
                            >
                            <span
                              class="pl-1 pr-1"
                              v-if="slotProps.data.type == 'RADAR'"
                              >|</span
                            >
                            <span v-if="slotProps.data.type == 'RADAR'"
                              ><strong>Pol:</strong>&nbsp;{{
                                slotProps.data.pol_layer
                              }}</span
                            >
                            <span
                              class="pl-1 pr-1"
                              v-if="slotProps.data.type == 'RADAR'"
                              >|</span
                            >
                            <span v-if="slotProps.data.type == 'RADAR'"
                              ><strong>Mode:</strong>&nbsp;{{
                                slotProps.data.mode
                              }}</span
                            >
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </CommonAccordion>
  </div>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import IconPreview from "./IconPreview.vue";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import ContextMenu from "@/components/commons/contextmenu/ContextMenu";
import Checkbox from "@/components/commons/checkbox/Checkbox";
import InputText from "@/components/commons/inputtext/InputText";
export default {
  components: {
    CommonAccordion,
    IconPreview,
    DataTable,
    Column,
    ContextMenu,
    Checkbox,
    InputText,
  },
  props: {
    propItems: {
      type: Object,
      default: () => {},
    },
  },
};
</script>