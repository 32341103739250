import { render, staticRenderFns } from "./QuickLook.vue?vue&type=template&id=f1bc6860&scoped=true&"
import script from "./QuickLook.vue?vue&type=script&lang=js&"
export * from "./QuickLook.vue?vue&type=script&lang=js&"
import style0 from "./QuickLook.vue?vue&type=style&index=0&id=f1bc6860&lang=scss&scoped=true&"
import style1 from "./QuickLook.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1bc6860",
  null
  
)

export default component.exports