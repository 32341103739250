<template>
  <Dialog
    :header="$t('title_cancel_share')"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :maximizable="false"
    :modal="true"
    :contentStyle="propStyle"
    @hide="onClose"
  >
    <div class="form-cancel-sharing">
      <div class="form-group">
        <label for="inputName">{{ $t("label_image_name") }}</label>
        <p class="font-weight-bold">{{ propItem.name }}</p>
      </div>
      <div class="datatable">
        <DataTable
          :value="propMember"
          ref="dt"
          columnResizeMode="expand"
          dataKey="id"
          :selection.sync="selected"
        >
          <template #header>
            <div class="tricks">
              <span>{{ $t("label_select_user_for_cancel_share") }}</span>
            </div>
          </template>
          <template #empty v-if="propMember && propMember.length === 0">
            <span class="text-center">{{ $t("label_no_data") }}</span>
          </template>
          <Column
            header="No"
            headerStyle="width: 3rem; text-align: center!important"
            bodyStyle="text-align: center;"
          >
            <template #body="slotProps">
              {{ slotProps.index + 1 }}
            </template></Column
          >
          <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
          <Column field="username" :header="$t('label_username')"></Column>
          <Column field="full_name" :header="$t('label_full_name')"></Column>
        </DataTable>
      </div>
    </div>
    <template #footer>
      <Button
        :label="$t('button_text_close')"
        :icon="'fas fa-times'"
        :class="'p-button-danger  p-button-sm'"
        @click="onClose"
      />
      <Button
        :disabled="!selected || selected.length === 0"
        :label="$t('button_text_cancel_share')"
        :icon="'fas fa-check'"
        :class="'p-button-success p-button-sm mr-0'"
        @click="onRemoveShare"
      />
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
import Button from "@/components/commons/button/Button";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
export default {
  components: {
    Dialog,
    Button,
    DataTable,
    Column,
  },
  props: {
    propItem: {
      type: Object,
      default: () => {},
    },
    propStyle: {
      type: Object,
      default: () => {
        return {
          width: "480px",
        };
      },
    },
    propShow: {
      type: Boolean,
      default: false,
    },
    propMember: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: null,
      show: false,
    };
  },
  created() {
    this.show = this.propShow;
  },
  watch: {
    propShow(val) {
      this.show = val;
    },
  },
  methods: {
    onClose() {
      this.show = false;
      this.$emit("close");
    },
    onRemoveShare() {
      this.$emit("onRemoveShare", this.selected);
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-gray {
  background: #f0f0f0;
  padding: 0.5rem 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.25);
}
.tricks {
  text-align: left;
  font-weight: normal;
}
</style>