
const SET_SPINNER_APP = "SET_SPINNER_APP";
const SET_HEIGHT_DOCUMENT = "SET_HEIGHT_DOCUMENT";
const SET_WIDTH_DOCUMENT = "SET_WIDTH_DOCUMENT";
const SET_MODELS = "SET_MODELS";
const SET_IS_SIDEBAR = "SET_IS_SIDEBAR";
const SET_LANG = "SET_LANG";
const SET_IMAGES_DOWNLOAD = "SET_IMAGES_DOWNLOAD"
const SET_FILES_DOWNLOAD = "SET_FILES_DOWNLOAD"
const SET_IS_WIDGET_UPLOAD_PROGRESS_BAR = "SET_IS_WIDGET_UPLOAD_PROGRESS_BAR"
const SET_BASEMAP_DEFAULT = "SET_BASEMAP_DEFAULT"
const SET_DATA_JSON = "SET_DATA_JSON";
import app from './../../main'
import { extend, localize } from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import ja from "vee-validate/dist/locale/ja.json";
import vi from "vee-validate/dist/locale/vi.json";
export default {
    state: {
        document:{
            width: 0,
            height: 0,
        },
        spinner: {
            show: false,
            shape: "tile"
        },
        models:[],
        isSidebar:true,
        lang: localStorage.getItem("language") ? localStorage.getItem("language") : "en",        
        imagesDownload:[],
        filesDownload: [],
        isWidgetUploadProgress : false,
        basemap:{},
        dataJson:{}
    },
    getters: {
        getSpinnerApp: state => {
            return state.spinner;
        },
        getDocumentHeight: state => {
            return state.document.height;
        },
        getDocumentWidth: state => {
            return state.document.width;
        },
        getModels:state=>{
            return state.models;
        },        
        getIsSidebar:state =>{            
            return state.isSidebar
        },
        getLang:state=>{
            return state.lang
        },
        getImagesDownload:state =>{
            return state.imagesDownload
        },
        getFilesDownload:state =>{
            return state.filesDownload
        },
        getIsWidgetUploadProgress: state => {
            return state.isWidgetUploadProgress
        },
        getBasemap: state => {
            return state.basemap
        },
        getDataJson: state => {
            return state.dataJson;
        },
    },
    mutations: {
        [SET_HEIGHT_DOCUMENT](state, payload) {
            state.document.height = payload;
        },
        [SET_WIDTH_DOCUMENT](state, payload) {
            state.document.width = payload;
        },
        [SET_SPINNER_APP](state, payload) {
            state.spinner.show = payload.show;
            if (payload.shape) state.spinner.shape = payload.shape;
            if(payload.text) state.spinner.text = payload.text;
            else state.spinner.text = ""
        },
        [SET_MODELS](state, payload) {
            state.models = payload;
        },
        [SET_IS_SIDEBAR](state, payload) {
            state.isSidebar = payload;
        },
        [SET_LANG] (state, payload) {
            state.lang = payload;
            app.$i18n.locale = payload;
            switch (payload) {
                case "vi":
                    localize("vi", vi);
                    break;
                case "en":
                    localize("en", en);
                    break;
                default:
                    localize("vi", vi);
                    break;
            }
        },
        [SET_IMAGES_DOWNLOAD](state, payload) {
            if(payload && payload.length > 0){
                for (let i = 0; i < payload.length; i++) {
                    if (payload[i].is_add) {
                        //if(state.imagesDownload.findIndex(x=>x.id === payload[i].image.id) < 0)
                        state.imagesDownload.push(payload[i].image)
                    } else {
                        let index = state.imagesDownload.findIndex(x => x.id === payload[i].image.id);
                        if (index >= 0) state.imagesDownload.splice(index, 1);
                    }
                }
            }
        },
        [SET_FILES_DOWNLOAD](state, payload) {
            if(payload && payload.length > 0){
                for (let i = 0; i < payload.length; i++) {
                    if (payload[i].is_add) {
                        state.filesDownload.push(payload[i].file)
                    } else {
                        let index = state.filesDownload.findIndex(x => x.id === payload[i].file.id);
                        if (index >= 0) state.filesDownload.splice(index, 1);
                    }
                }
            }
        },
        [SET_IS_WIDGET_UPLOAD_PROGRESS_BAR](state, payload) { state.isWidgetUploadProgress = payload; },
        [SET_BASEMAP_DEFAULT](state, payload) {
            state.basemap = payload;
        },
        [SET_DATA_JSON](state, payload) {
            state.dataJson = payload;
        },
    },
    actions: {
        setSpinnerApp({ commit }, payload) {
            commit(SET_SPINNER_APP, payload);
        },
        setDocumentHeight({ commit }, payload) {
            commit(SET_HEIGHT_DOCUMENT, payload);
        },
        setDocumentWidth({ commit }, payload) {
            commit(SET_WIDTH_DOCUMENT, payload);
        },
        setModels({ commit }, payload) {
            commit(SET_MODELS, payload);
        },
        setIsSidebar({ commit }, payload) {
            commit(SET_IS_SIDEBAR, payload);
        },
        setLang({commit}, payload) {
            commit(SET_LANG, payload)
        },
        setImagesDownload({ commit }, payload){
            commit(SET_IMAGES_DOWNLOAD, payload);
        },
        setFilesDownload({ commit }, payload){
            commit(SET_FILES_DOWNLOAD, payload);
        },
        showWidgetUploadProgressBar({ commit }, payload) {
            commit(SET_IS_WIDGET_UPLOAD_PROGRESS_BAR, payload);
        },
        setBasemapDefault({ commit }, payload){
            commit(SET_BASEMAP_DEFAULT, payload);
        },
        setDataJson({ commit }, payload) {
            commit(SET_DATA_JSON, payload);
        },
    }
}