var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-layout',{attrs:{"isToolbar":false,"isBreadcrumb":true,"breadcrumbitems":[
    { label: _vm.$t('menu_statistic_report') },
    { label: _vm.$t('menu_statistic_download') } ],"propButtonsState":{
    save: {
      visible: false,
    },
    add: {
      visible: false,
    },
    edit: {
      visible: false,
    },
    reload: {
      visible: true,
    },
    print: {
      visible: false,
    },
  },"propTitle":_vm.$t('menu_statistic_download')},on:{"emitFunction":_vm.menuHeaderClick}},[_c('div',{staticClass:"statistic-download"},[_c('div',{staticClass:"statistic-upload__dialog"},[(_vm.dialog.detail.show)?_c('DialogDetail',{attrs:{"prop-style":{ width: _vm.dialog.detail.width },"prop-show":_vm.dialog.detail.show,"prop-title":_vm.dialog.detail.title,"prop-items":_vm.dialog.detail.items},on:{"close":function($event){_vm.dialog.detail.show = false;
          _vm.dialog.detail.items = [];}}}):_vm._e(),(_vm.message.length > 0)?_c('DialogMessage',{attrs:{"propTitle":_vm.$t('title_message_info'),"prop-show":_vm.message.length > 0,"prop-message":_vm.message},on:{"close":function($event){_vm.message = []}}}):_vm._e()],1),_c('div',{staticClass:"statistic-download__search"},[_c('DownloadFilter',{attrs:{"prop-search":_vm.statisticDownload.search},on:{"onSearch":_vm.onSearch,"message":function($event){_vm.message = $event}}})],1),_c('div',{staticClass:"statistic-download__datatable"},[_c('DownloadDataTable',{attrs:{"prop-items":_vm.statisticDownload.items},on:{"shoDialogDetail":_vm.shoDialogDetail}})],1),_c('div',{staticClass:"statistic-download__chart"},[_c('DownloadChart',{attrs:{"prop-data":_vm.statisticDownload.items}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }