<template>
  <main-layout
    :isToolbar="false"
    :isBreadcrumb="true"
    :breadcrumbitems="[
      { label: $t('menu_data_management') },
      { label: $t('menu_data_upload_folder') },
    ]"
    :propButtonsState="{
      save: {
        visible: false,
      },
      add: {
        visible: false,
      },
      edit: {
        visible: false,
      },
      reload: buttons.reload,
      print: {
        visible: false,
      },
    }"
    :propTitle="$t('menu_data_upload_folder')"
    @emitFunction="menuHeaderClick"
  >
    <div
      class="myDropzone"
      id="myDropzone"
      :style="{ height: pageContentBodyHeight + 'px' }"
    >
      <div class="myDropzone__dialog">
        <DialogUploadMetadata
          v-if="dialogUploadMetadata.show"
          :prop-title="dialogUploadMetadata.title"
          :prop-show="dialogUploadMetadata.show"
          :prop-style="{ width: dialogUploadMetadata.width }"
          :propEntity="dialogUploadMetadata.entity"
          :prop-provider="provider.tree"
          :prop-processing-level="processingLevel.items"
          :prop-beam-mode="beamMode.items"
          :prop-direction="direction.items"
          :prop-pol-layer="polLayer.items"
          :prop-projects="project.items"
          :propDataSpecifications="dataSpecification.items"
          :prop-button="dialogUploadMetadata.button"
          :propDataCatalog="
            categoryDataList && categoryDataList.items
              ? categoryDataList.items.find((x) => x.id === categoryId)
              : null
          "
          @clear="clearEntity"
          @close="
            dialogUploadMetadata.show = false;
            dialogUploadMetadata.entity = {};
            dialogUploadMetadata.value = null;
            dialogUploadMetadata.button = {
              disabled: false,
              processing: false,
            };
          "
          @onUpload="onUpload"
          @metaFileChange="metaFileChange"
          @message="message = $event"
          @getAllProvider="getAllProvider"
          @getAllProcessingLevel="getAllProcessingLevel"
          @getAllBeamMode="getAllBeamMode"
          @getAllDirection="getAllDirection"
          @getAllPolLayer="getAllPolLayer"
          @getAllProject="getAllProject"
          @getAllModel="getAllModel"
        />
        <DialogUpdateMetadata
          v-if="dialogUpdateMetadata.show"
          :prop-style="{ width: dialogUpdateMetadata.width }"
          :prop-show="dialogUpdateMetadata.show"
          :prop-title="dialogUpdateMetadata.title"
          :prop-item="dialogUpdateMetadata.value"
          :prop-provider="provider.tree"
          :prop-processing-level="processingLevel.items"
          :prop-beam-mode="beamMode.items"
          :prop-direction="direction.items"
          :prop-pol-layer="polLayer.items"
          :prop-satellites="satellite.items"
          :prop-button="dialogUpdateMetadata.button"
          :propDataCatalog="
            categoryDataList && categoryDataList.items
              ? categoryDataList.items.find((x) => x.id === categoryId)
              : null
          "
          @close="
            dialogUpdateMetadata.show = false;
            dialogUpdateMetadata.value = null;
            dialogUpdateMetadata.button = {
              disabled: false,
              processing: false,
            };
          "
          @getAllProvider="getAllProvider"
          @getAllProcessingLevel="getAllProcessingLevel"
          @getAllBeamMode="getAllBeamMode"
          @getAllDirection="getAllDirection"
          @getAllPolLayer="getAllPolLayer"
          @confirmMetadata="handleUpdateMetadata"
          @message="message = $event"
        />
        <DialogMessage
          v-if="message.length > 0"
          :prop-show="message.length > 0"
          :prop-message="message"
          @close="message = []"
        />
      </div>
      <div class="row no-gutters upload-actions d-flex flex-row">
        <div class="add-files">
          <input
            type="file"
            id="add-files__input"
            webkitdirectory
            multiple
            hidden
            @change="inputFilesChange"
            ref="addFilesInput"
            :disabled="buttonUpload.status === 'UPLOADING'"
          />
          <label
            for="add-files__input"
            @click="labelFileClick"
            :disabled="buttonUpload.status === 'UPLOADING'"
            ><i class="fas fa-folder-plus"></i>&nbsp;{{
              $t("label_select_folder")
            }}</label
          >
        </div>
        <button
          :class="buttonUpload.class"
          style="width: 120px; height: 35px"
          :disabled="files.length === 0 || buttonUpload.status === 'UPLOADING'"
          @click="startUpload"
          class="btn btn-start-upload"
        >
          <i :class="buttonUpload.icon"></i>&nbsp;{{ buttonUpload.label }}
        </button>

        <div class="dropdown-satellite ml-2">
          <Dropdown
            style="width: 176px"
            v-model="selectedSatellite"
            :showClear="false"
            :options="supportSatellites"
            :scrollHeight="'272px'"
            optionValue="code"
            optionLabel="label"
            :disabled="buttonUpload.status === 'UPLOADING'"
          />
        </div>
        <div
          class="category-dropdown ml-2 d-flex"
          style="width: calc((100% - 456px) / 2)"
        >
          <!-- <select class="form-control w-100 data-category" v-model="categoryId">
            <option
              v-for="c in categoryDataList.items"
              :key="c.id"
              :value="c.id"
            >
              {{
                c &&
                c.translation &&
                c.translation[lang] &&
                c.translation[lang].description
                  ? c.translation[lang].description
                  : c && c.description
                  ? c.description
                  : ""
              }}
            </option>
          </select> -->
          <select
            class="form-control w-100 ml-2 data-specification"
            v-model="dataModel"
            :title="
              dataModel ? dataModel : $t('tooltip_select_data_specification')
            "
          >
            <option
              v-for="d in dataSpecification.items"
              :key="d.id"
              :value="d.name"
            >
              {{ d.name }}
            </option>
          </select>
        </div>
        <ProgressBar
          class="ml-2"
          :value="remainingTime.progressValue"
          style="width: calc((100% - 456px) / 2)"
        ></ProgressBar>
      </div>
      <div class="row no-gutters d-flex">
        <div class="upload-status d-flex" v-if="files.length > 0">
          <div class="status-item complete-status">
            <span
              >{{ $t("label_completed") }}:&nbsp;<strong>{{
                completeCounter
              }}</strong></span
            >
          </div>
          <div class="status-item incomplete-status">
            <span
              >{{ $t("label_incompleted") }}:&nbsp;<strong>{{
                inCompleteCounter
              }}</strong></span
            >
          </div>
          <div class="status-item error-status">
            <span
              >{{ $t("label_error") }}:&nbsp;<strong>{{
                errorCounter
              }}</strong></span
            >
          </div>
        </div>
      </div>
      <div class="row no-gutters upload-files">
        <div class="col" id="HJud83Hwbr">
          <div class="datatable">
            <ContextMenu
              :model="menuModel"
              ref="cmFilesDatatable"
              :appendTo="'HJud83Hwbr'"
            />
            <DataTable
              :value="files"
              ref="dt"
              :resizableColumns="true"
              columnResizeMode="expand"
              dataKey="id"
            >
              <template #empty v-if="files && files.length === 0">
                <span class="text-center">{{ $t("label_no_files") }}</span>
              </template>
              <Column
                :headerStyle="{
                  width: '3.75rem',
                  textAlign: 'center!important',
                }"
                bodyStyle="text-align: center;"
              >
                <template #body="slotProps">
                  <div>
                    {{ slotProps.index + 1 }}
                  </div>
                </template>
              </Column>
              <Column headerStyle="width: 3.5rem"
                ><template #body="slotProps">
                  <div
                    :class="'file-' + slotProps.data.type.toLowerCase()"
                  ></div> </template
              ></Column>
              <Column :header="$t('label_file_name')">
                <template #body="slotProps">
                  <div class="file-attachment">
                    <span class="innernametext">
                      <div v-if="!slotProps.data.is_folder" class="pl-2">
                        {{ slotProps.data.name }}
                        <p
                          v-if="slotProps.data.other_name"
                          class="mt-2 text-primary"
                        >
                          ({{ slotProps.data.other_name }})
                        </p>
                      </div>
                      <div v-else style="width: 100%; height: auto">
                        <TreeCommon
                          :value="slotProps.data.data_tree"
                          v-if="slotProps.data.data_tree.length"
                        ></TreeCommon>
                      </div>
                    </span>
                  </div>
                </template>
              </Column>
              <Column :header="$t('label_file_size')" headerStyle="width: 7rem">
                <template #body="slotProps">
                  {{ slotProps.data.size | formatSizeFile }}
                </template>
              </Column>
              <Column :header="$t('label_status')" headerStyle="width: 12rem">
                <template #body="slotProps">
                  <div
                    v-if="
                      slotProps.data.status === 'SUCCESS' &&
                        slotProps.data.read_metadata === 'SUCCESS'
                    "
                  >
                    <div class="status-item complete-status">
                      <span>{{ $t("label_completed") }}</span>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      slotProps.data.status === 'SUCCESS' &&
                        slotProps.data.read_metadata === 'ERROR'
                    "
                  >
                    <div class="status-item incomplete-status">
                      <span>{{ $t("label_incompleted") }}</span>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      slotProps.data.status === 'SUCCESS' &&
                        slotProps.data.read_metadata === 'PROCESSING'
                    "
                  >
                    <div class="status-item processing-status">
                      <span
                        ><i class="fas fa-spinner fa-spin"></i>&nbsp;{{
                          $t("label_processing")
                        }}</span
                      >
                    </div>
                  </div>
                  <div v-else-if="slotProps.data.status === 'ERROR'">
                    <div class="status-item error-status">
                      <span>{{ $t("label_error") }}</span>
                    </div>
                  </div>
                  <div v-else-if="slotProps.data.status === 'PROCESS'">
                    <div class="status-item processing-status">
                      <span
                        ><i class="fas fa-spinner fa-spin"></i>&nbsp;{{
                          $t("label_uploading")
                        }}</span
                      >
                    </div>
                  </div>
                </template>
              </Column>
              <Column
                headerStyle="width: 6rem; text-align: center!important"
                bodyStyle="text-align: center;"
              >
                <template #body="slotProps">
                  <Button
                    :title="$t('button_text_metadata')"
                    icon="fas fa-list-alt"
                    class="p-button-sm"
                    :class="'p-button-success'"
                    @click="openDlgConfirmMetadata(slotProps.data)"
                    v-if="
                      slotProps.data.status === 'SUCCESS' &&
                        slotProps.data.read_metadata === 'SUCCESS'
                    "
                  />
                  <Button
                    :title="$t('button_text_metadata')"
                    icon="fas fa-list-alt"
                    class="p-button-sm"
                    :class="'button-additional-information'"
                    @click="openDlgConfirmMetadata(slotProps.data)"
                    v-if="
                      slotProps.data.status != 'PROCESS' &&
                        (slotProps.data.read_metadata === 'ERROR' ||
                          slotProps.data.read_metadata === 'PENDING') &&
                        buttonUpload.status != 'UPLOADING'
                    "
                  />
                  <Button
                    :title="$t('button_text_remove')"
                    icon="fas fa-trash"
                    class="p-button-danger p-button-sm"
                    v-if="
                      slotProps.data.status === 'NEW' &&
                        buttonUpload.status != 'UPLOADING'
                    "
                    @click="removeItem(slotProps.data)"
                  />
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </main-layout>
</template>
<script>
Array.prototype.myJoin = function(seperator, start, end) {
  if (!start) start = 0;
  if (!end) end = this.length - 1;
  end++;
  return this.slice(start, end).join(seperator);
};
import MainLayout from "@/components/pages/layouts/admin/Main";
import DialogUploadMetadata from "./satellite/dialog/UploadMetadata";
import DialogUpdateMetadata from "./satellite/dialog/DialogUpdateMetadata";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import ContextMenu from "@/components/commons/contextmenu/ContextMenu";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import DialogMessage from "@/components/commons/dialog/MessageBox";
import ProgressBar from "@/components/commons/progressbar/ProgressBar.vue";
import TreeCommon from "@/components/commons/tree/Tree";
import InputText from "@/components/commons/inputtext/InputText";
import Treeselect from "@/components/commons/vue-treeselect/src/components/Treeselect";
import Button from "@/components/commons/button/Button";
import CryptoJS from "crypto-js";
import fileFunc from "@/utils/functions/file";
import imageFunc from "@/utils/functions/image";
import projectFunc from "@/utils/functions/project";
import metaKeyFunc from "@/utils/functions/metakey";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import processingDataFunc from "@/utils/functions/processingdata";
import { getMimeType } from "@/utils/commons/mimetypes";
import {
  change_alias,
  string_all_trim,
  getFileExtension1,
  getFileExtension2,
  getFileExtension3,
} from "@/utils/commons/common";
import { transform } from "ol/proj";
import Projection from "ol/proj/Projection";
import { toLonLat } from "ol/proj";
export default {
  components: {
    DialogUpdateMetadata,
    DialogMessage,
    TreeCommon,
    ProgressBar,
    Dropdown,
    DataTable,
    Column,
    MainLayout,
    InputText,
    Treeselect,
    Button,
    ContextMenu,
    DialogUploadMetadata,
  },
  data() {
    return {
      dataModel: null,
      categoryId: null,
      selected: null,
      files: [],
      filesLength: 0,
      intervalFiles: null, // dung de kiem tra file da dc add het chua,
      intervalTime: null,
      myDropzone: null,
      value: null,
      uploading: false,
      s3Client: null,
      temp: {
        files: [],
        length: 0,
      },
      remainingTime: {
        progressValue: 0,
        timeValue: null,
        oldUploadedSize: 0,
        count: 0,
        countHold: false,
      },
      buttonUpload: {
        label: "Upload",
        status: "NORMAL",
        icon: "fas fa-upload",
        class: "btn btn-primary btn-sm",
      },
      message: [],
      dialogUploadMetadata: {
        show: false,
        data: null,
        title: "Upload Metadata",
        width: "540px",
        entity: {},
        value: null,
        button: {
          disabled: false,
          processing: false,
        },
      },
      dialogUpdateMetadata: {
        show: false,
        title: "Update metadata",
        value: null,
        width: "540px",
        button: {
          disabled: false,
          processing: false,
        },
      },
      dialogMetadata: {
        show: false,
        title: "Metadata",
        width: 0,
        item: null,
      },
      dialogContinue: {
        exist_ids: [],
        show: false,
        title: this.$t("title_message_confirm"),
        width: 540 + "px",
        value: null,
        icon: "fas fa-question",
        message: null,
        callBack: {
          method: null,
          arguments: null,
        },
        advanceButton: {
          show: false,
          label:
            this.$t("button_remove_exist_files") +
            " " +
            this.$t("label_and") +
            " " +
            this.$t("button_text_close").toLowerCase(),
          icon: "fas fa-trash",
          class: "p-button-danger",
        },
      },
      pageContentBodyHeight: 0,
      menuModel: [
        {
          label: "Metadata",
          icon: "text-primary fas fa-check",
          command: () => this.openDlgConfirmMetadata(this.selected),
          visible: true,
        },
        {
          label: this.$t("button_text_remove"),
          icon: "text-danger fas fa-trash",
          command: () => this.removeItem(this.selected),
          visible: true,
        },
      ],
      provider: {
        items: [],
        tree: [],
      },
      processingLevel: {
        items: [],
      },
      beamMode: {
        items: [],
      },
      direction: {
        items: [],
      },
      polLayer: {
        items: [],
      },
      project: {
        items: [],
        tree: [],
      },
      satellite: {
        items: [],
      },
      dataSpecification: {
        items: [],
      },
      categoryDataList: {
        items: [],
      },
      projectSelected: null,

      time: new Date().getTime(),
      buttons: {
        reload: {
          disabled: false,
          processing: false,
          visible: true,
        },
      },
      selectedSatellite: "SPOT2",
      supportSatellites: [
        {
          id: "SPOT2",
          code: "SPOT2",
          label: "SPOT 2-5",
        },
      ],
    };
  },
  created() {
    if (this.dataConfigJson && Object.keys(this.dataConfigJson).length > 0) {
      if (
        this.dataConfigJson.support_default &&
        this.dataConfigJson.support_default != this.selectedSatellite
      )
        this.selectedSatellite = this.dataConfigJson.support_default;
      if (
        this.dataConfigJson.support_satellites &&
        this.dataConfigJson.support_satellites.length > 0
      ) {
        this.supportSatellites = this.dataConfigJson.support_satellites;
      }
    }

    this.getAllModel();
  },
  watch: {
    dataConfigJson: {
      handler(val) {
        if (
          this.dataConfigJson &&
          Object.keys(this.dataConfigJson).length > 0
        ) {
          if (
            this.dataConfigJson.support_default &&
            this.dataConfigJson.support_default != this.selectedSatellite
          )
            this.selectedSatellite = this.dataConfigJson.support_default;
          if (
            this.dataConfigJson.support_satellites &&
            this.dataConfigJson.support_satellites.length > 0
          ) {
            this.supportSatellites = this.dataConfigJson.support_satellites;
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    totalSize() {
      return this.files.reduce(
        (sum, record) => sum + parseFloat(record["size"]),
        0
      );
    },
    totalUploadSize() {
      return this.files
        .filter((x) => x.status != "SUCCESS")
        .reduce((sum, record) => sum + parseFloat(record["size"]), 0);
    },
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    completeCounter() {
      let result = 0;
      result = this.files.filter(
        (x) => x.status === "SUCCESS" && x.read_metadata === "SUCCESS"
      ).length;
      return result;
    },
    inCompleteCounter() {
      let result = 0;
      result = this.files.filter(
        (x) =>
          x.status != "ERROR" &&
          x.status != "PROCESS" &&
          x.read_metadata != "SUCCESS"
      ).length;
      return result;
    },
    errorCounter() {
      let result = 0;
      result = this.files.filter((x) => x.status === "ERROR").length;
      return result;
    },
    dataConfigJson() {
      return this.$store.getters.getDataJson;
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
  mounted() {
    var vm = this;
    if (vm.documentWidth < 576) {
      this.dialogUploadMetadata.width = this.documentWidth + "px";
      this.dialogUpdateMetadata.width = this.documentWidth + "px";
      this.dialogMetadata.width = this.documentWidth + "px";
    } else if (vm.documentWidth >= 576 && vm.documentWidth < 768) {
      this.dialogUploadMetadata.width = "540px";
      this.dialogUpdateMetadata.width = "540px";
      this.dialogMetadata.width = "540px";
    } else if (vm.documentWidth >= 768 && vm.documentWidth < 992) {
      this.dialogUploadMetadata.width = "720px";
      this.dialogUpdateMetadata.width = "720px";
      this.dialogMetadata.width = "720px";
    } else {
      this.dialogMetadata.width = "960px";
      this.dialogUploadMetadata.width = "960px";
      this.dialogUpdateMetadata.width = "960px";
    }
    this.myDropzone = new Dropzone("div#myDropzone", {
      // Make the whole body a dropzone
      url: "/url",
      autoProcessQueue: false,
      clickable: false,
      previewsContainer: false,
      autoQueue: false, // Make sure the files aren't queued until manually added
    });
    this.myDropzone.on("addedfile", function(file) {
      vm.temp.files.push(file);
      vm.temp.length = vm.temp.files.length;
    });
    this.myDropzone.on("drop", function(e) {
      vm.intervalFiles = setInterval(function() {
        vm.checkAddedFile();
      }, 500);
    });
    this.pageContentBodyHeight = this.documentHeight;
    let donHeader = document.getElementById("pageContentHeader");
    if (donHeader && Object.keys(donHeader).length > 0) {
      this.pageContentBodyHeight =
        this.pageContentBodyHeight - donHeader.offsetHeight;
    }
    this.getAllCategoryDataList();
    this.getAllProvider();
    // this.getAllBeamMode();
    this.getAllProcessingLevel();
    // this.getAllPolLayer();
    // this.getAllDirection();

    this.getAllSatellite();
  },
  methods: {
    menuHeaderClick(val) {
      switch (val.code) {
        case "RELOAD":
          this.files = [];
          (this.uploading = false),
            (this.temp = {
              files: [],
              length: 0,
            });
          this.remainingTime = {
            progressValue: 0,
            timeValue: null,
            oldUploadedSize: 0,
            count: 0,
            countHold: false,
          };
          break;
      }
    },
    onCancelContinue() {
      this.dialogContinue.exist_ids = [];
      this.dialogContinue.value = null;
      this.dialogContinue.message = null;
      this.dialogContinue.callBack = {
        method: null,
        arguments: null,
      };
      this.dialogContinue.show = false;
    },

    advanceButtonClick() {
      if (
        this.dialogContinue.exist_ids &&
        this.dialogContinue.exist_ids.length > 0
      ) {
        for (let i = 0; i < this.dialogContinue.exist_ids.length; i++) {
          let index = this.files.findIndex(
            (x) => x.id === this.dialogContinue.exist_ids[i]
          );
          if (index >= 0) this.files.splice(index, 1);
        }
        this.onCancelContinue();
      }
    },
    onConfirmedContinue() {
      try {
        this.isCallback = true;
        this[this.dialogContinue.callBack.method](
          this.dialogContinue.callBack.arguments
            ? this.dialogContinue.callBack.arguments
            : null
        );
      } catch (error) {}
    },
    rowClick(e) {
      if (this.selected) {
        if (e.data.id == this.selected.id) {
          if (this.time) {
            if (new Date().getTime() - this.time <= 300) {
              setTimeout(() => {
                this.selected = null;
              }, 100);
            }
          }
        }
      }
      this.time = new Date().getTime();
    },
    onRowContextMenu(event) {},
    labelFileClick() {
      this.$refs.addFilesInput.value = null;
    },
    inputFilesChange(e) {
      if (this.buttonUpload.status === "UPLOADING") {
        this.message.push(this.$t("message_please_wait_upload_completed"));
        return;
      }
      if (!this.selectedSatellite) {
        this.message.push(this.$t("message_select_satellite_first"));
        this.temp.files = [];
        this.temp.length = 0;
        return;
      }
      var vm = this;
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      else {
        let totalSize = 0;
        for (let i = 0; i < files.length; i++) {
          totalSize = files[i].size + totalSize;
        }
        let uploadMaxFileSize = 3;
        try {
          uploadMaxFileSize = parseFloat(
            this.dataConfigJson && this.dataConfigJson.upload_max_filesize
              ? this.dataConfigJson.upload_max_filesize
              : uploadMaxFileSize
          );
        } catch (error) {}
        if (totalSize > uploadMaxFileSize * 1024 * 1024 * 1024) {
          this.message.push(
            this.$t("message_upload_max_file_size", null, {
              val: uploadMaxFileSize + "G",
            })
          );
          return;
        } else {
          if (["SPOT6", "SPOT7"].includes(this.selectedSatellite)) {
            let listFolder = [];
            for (let i = 0; i < files.length; i++) {
              if (!files[i].temp_id) {
                files[i].temp_id =
                  new Date().getTime() -
                  Math.floor(Math.random() * 999999999) +
                  Math.floor(Math.random() * 888888888) +
                  i;
              }
              listFolder.push(files[i]);
            }
            let arrayRootFolder = [
              ...new Set(
                listFolder.map(function(x) {
                  return x.webkitRelativePath.split("/")[0];
                })
              ),
            ];
            for (let k = 0; k < arrayRootFolder.length; k++) {
              let sliceFiles = listFolder.filter(function(x) {
                return (
                  x.webkitRelativePath.split("/")[0] === arrayRootFolder[k]
                );
              });

              if (sliceFiles && sliceFiles.length > 0) {
                let pathFolders = [],
                  count = 0,
                  pathFiles = []; // lưu đường dẫn các folder
                let fileArray = [];
                for (let i = 0; i < sliceFiles.length; i++) {
                  count = count + 1;
                  // Là Folder
                  let arrayPath = sliceFiles[i].webkitRelativePath.split("/");
                  let index = arrayPath.findIndex(
                    (x) => x === sliceFiles[i].name
                  );
                  if (index >= 0 && index === arrayPath.length - 1) {
                    arrayPath.splice(index, 1);
                  }

                  // check folder trong folder ma k co file cùng cấp
                  for (let j = 0; j < arrayPath.length; j++) {
                    let check = false,
                      arrayPathCheck = [];
                    if (j === 0) {
                      arrayPathCheck = [arrayPath[0]];
                      check =
                        pathFolders.findIndex(
                          (x) => x.array_path.join("-") === arrayPath[0]
                        ) < 0;
                    } else if (j === 1) {
                      arrayPathCheck = [arrayPath[0], arrayPath[1]];
                      check =
                        pathFolders.findIndex(
                          (x) =>
                            x.array_path.join("-") ===
                            [arrayPath[0], arrayPath[1]].join("-")
                        ) < 0;
                    } else {
                      arrayPathCheck = arrayPath.slice(0, j + 1);
                      check =
                        pathFolders.findIndex(
                          (x) =>
                            x.array_path.join("-") === arrayPathCheck.join("-")
                        ) < 0;
                    }
                    if (check) {
                      // kiểm tra nếu chưa có đường dẫn đó thì thêm mới
                      // List folder
                      pathFolders.push({
                        id: CryptoJS.MD5(arrayPathCheck.join("-")).toString(),
                        key: CryptoJS.MD5(arrayPathCheck.join("-")).toString(),
                        label: arrayPath[j],
                        array_path: arrayPathCheck,
                      });
                      let parentIdOne = -1;
                      if (i > 0) {
                        parentIdOne = pathFiles.findIndex(function(x) {
                          return (
                            x.array_path.join("-") ===
                            arrayPathCheck
                              .slice(0, arrayPathCheck.length - 1)
                              .join("-")
                          );
                        });
                      }
                      pathFiles.push({
                        id: CryptoJS.MD5(
                          arrayPathCheck.join("-") + sliceFiles[i].name
                        ).toString(),
                        key: CryptoJS.MD5(
                          arrayPathCheck.join("-") + sliceFiles[i].name
                        ).toString(),
                        label: arrayPathCheck[arrayPathCheck.length - 1],
                        array_path: arrayPathCheck,
                        parent_id:
                          parentIdOne >= 0 ? pathFiles[parentIdOne].id : -1,
                        is_folder: true,
                      });
                    }
                  }
                  let parentIdTwo = -1;
                  parentIdTwo = pathFiles.findIndex(function(x) {
                    return x.array_path.join("-") === arrayPath.join("-");
                  });

                  let tempId = CryptoJS.MD5(
                    count +
                      Math.floor(Math.random() * 1000) +
                      "-" +
                      sliceFiles[i].name
                  ).toString();
                  sliceFiles[i].temp_id = tempId;
                  sliceFiles[i].uploaded_size = 0;
                  fileArray.push(sliceFiles[i]);
                  pathFiles.push({
                    id: tempId,
                    key: tempId,
                    label: sliceFiles[i].name,
                    full_path: sliceFiles[i].fullPath,
                    array_path: [],
                    parent_id:
                      parentIdTwo >= 0 ? pathFiles[parentIdTwo].id : -1,
                    is_folder: false,
                    size: sliceFiles[i].size,
                    icon: null,
                    uploaded_size: 0,
                  });
                }
                if (pathFiles && pathFiles.length > 0) {
                  this.files.push({
                    name: pathFiles[0].label,
                    files: fileArray,
                    data_tree: this.listToTree(pathFiles),
                    is_folder: true,
                    uploaded_size: 0,
                    size: fileArray.reduce(
                      (sum, record) => sum + parseFloat(record["size"]),
                      0
                    ),

                    other_name: null,
                    type: "FOLDER",
                    status: "NEW",
                    value: 0,
                    id: CryptoJS.MD5(
                      pathFiles[0].label +
                        Math.floor(
                          Math.random() * (1000 + new Date().getTime())
                        )
                    ).toString(),
                    key: CryptoJS.MD5(
                      pathFiles[0].label +
                        Math.floor(
                          Math.random() * (1000 + new Date().getTime())
                        )
                    ).toString(),
                    data_file: null,
                    loading_data: true,
                    entity: null,
                    read_metadata: "PENDING",
                    can_detect: false,
                    detect_by: null,
                  });
                  this.getMetadataFromXml(this.files[this.files.length - 1]);
                }
              }
            }
            this.temp.files = [];
            this.temp.length = 0;
            if (this.uploading) {
              this.addUpload(this.files.filter((x) => x.status === "NEW"));
            }
          } else if (
            ["SPOT1", "SPOT2", "SPOT3", "SPOT4", "SPOT5"].includes(
              this.selectedSatellite
            )
          ) {
            for (let i = 0; i < files.length; i++) {
              if (!files[i].temp_id) {
                files[i].temp_id =
                  new Date().getTime() -
                  Math.floor(Math.random() * 999999999) +
                  Math.floor(Math.random() * 888888888) +
                  i;
              }
              this.temp.files.push(files[i]);
            }
            this.createDataOldSPOT(this.temp.files, "webkitRelativePath");
          } else {
            this.createFolderFiles(files);
          }
        }
      }
    },
    createFolderFiles(files) {
      let listFolder = [];
      for (let i = 0; i < files.length; i++) {
        if (!files[i].temp_id) {
          files[i].temp_id =
            new Date().getTime() -
            Math.floor(Math.random() * 999999999) +
            Math.floor(Math.random() * 888888888) +
            i;
        }
        listFolder.push(files[i]);
      }
      let arrayRootFolder = [
        ...new Set(
          listFolder.map(function(x) {
            return x.webkitRelativePath.split("/")[0];
          })
        ),
      ];
      for (let k = 0; k < arrayRootFolder.length; k++) {
        let sliceFiles = listFolder.filter(function(x) {
          return x.webkitRelativePath.split("/")[0] === arrayRootFolder[k];
        });

        if (sliceFiles && sliceFiles.length > 0) {
          let pathFolders = [],
            count = 0,
            pathFiles = []; // lưu đường dẫn các folder
          let fileArray = [];
          for (let i = 0; i < sliceFiles.length; i++) {
            count = count + 1;
            // Là Folder
            let arrayPath = sliceFiles[i].webkitRelativePath.split("/");
            let index = arrayPath.findIndex((x) => x === sliceFiles[i].name);
            if (index >= 0 && index === arrayPath.length - 1) {
              arrayPath.splice(index, 1);
            }

            // check folder trong folder ma k co file cùng cấp
            for (let j = 0; j < arrayPath.length; j++) {
              let check = false,
                arrayPathCheck = [];
              if (j === 0) {
                arrayPathCheck = [arrayPath[0]];
                check =
                  pathFolders.findIndex(
                    (x) => x.array_path.join("-") === arrayPath[0]
                  ) < 0;
              } else if (j === 1) {
                arrayPathCheck = [arrayPath[0], arrayPath[1]];
                check =
                  pathFolders.findIndex(
                    (x) =>
                      x.array_path.join("-") ===
                      [arrayPath[0], arrayPath[1]].join("-")
                  ) < 0;
              } else {
                arrayPathCheck = arrayPath.slice(0, j + 1);
                check =
                  pathFolders.findIndex(
                    (x) => x.array_path.join("-") === arrayPathCheck.join("-")
                  ) < 0;
              }
              if (check) {
                // kiểm tra nếu chưa có đường dẫn đó thì thêm mới
                // List folder
                pathFolders.push({
                  id: CryptoJS.MD5(arrayPathCheck.join("-")).toString(),
                  key: CryptoJS.MD5(arrayPathCheck.join("-")).toString(),
                  label: arrayPath[j],
                  array_path: arrayPathCheck,
                });
                let parentIdOne = -1;
                if (i > 0) {
                  parentIdOne = pathFiles.findIndex(function(x) {
                    return (
                      x.array_path.join("-") ===
                      arrayPathCheck
                        .slice(0, arrayPathCheck.length - 1)
                        .join("-")
                    );
                  });
                }
                pathFiles.push({
                  id: CryptoJS.MD5(
                    arrayPathCheck.join("-") + sliceFiles[i].name
                  ).toString(),
                  key: CryptoJS.MD5(
                    arrayPathCheck.join("-") + sliceFiles[i].name
                  ).toString(),
                  label: arrayPathCheck[arrayPathCheck.length - 1],
                  array_path: arrayPathCheck,
                  parent_id: parentIdOne >= 0 ? pathFiles[parentIdOne].id : -1,
                  is_folder: true,
                });
              }
            }
            let parentIdTwo = -1;
            parentIdTwo = pathFiles.findIndex(function(x) {
              return x.array_path.join("-") === arrayPath.join("-");
            });

            let tempId = CryptoJS.MD5(
              count +
                Math.floor(Math.random() * 1000) +
                "-" +
                sliceFiles[i].name
            ).toString();
            sliceFiles[i].temp_id = tempId;
            sliceFiles[i].uploaded_size = 0;
            fileArray.push(sliceFiles[i]);
            pathFiles.push({
              id: tempId,
              key: tempId,
              label: sliceFiles[i].name,
              full_path: sliceFiles[i].fullPath,
              array_path: [],
              parent_id: parentIdTwo >= 0 ? pathFiles[parentIdTwo].id : -1,
              is_folder: false,
              size: sliceFiles[i].size,
              icon: null,
              uploaded_size: 0,
            });
          }
          if (pathFiles && pathFiles.length > 0) {
            this.files.push({
              name: pathFiles[0].label,
              files: fileArray,
              data_tree: this.listToTree(pathFiles),
              is_folder: true,
              uploaded_size: 0,
              size: fileArray.reduce(
                (sum, record) => sum + parseFloat(record["size"]),
                0
              ),

              other_name: null,
              type: "FOLDER",
              status: "NEW",
              value: 0,
              id: CryptoJS.MD5(
                pathFiles[0].label +
                  Math.floor(Math.random() * (1000 + new Date().getTime()))
              ).toString(),
              key: CryptoJS.MD5(
                pathFiles[0].label +
                  Math.floor(Math.random() * (1000 + new Date().getTime()))
              ).toString(),
              data_file: null,
              loading_data: true,
              entity: null,
              read_metadata: "PENDING",
              can_detect: false,
              detect_by: null,
            });
            this.getMetadataFromXml(this.files[this.files.length - 1]);
          }
        }
      }
      this.temp.files = [];
      this.temp.length = 0;
      if (this.uploading) {
        this.addUpload(this.files.filter((x) => x.status === "NEW"));
      }
    },
    openDlgConfirmMetadata(item) {
      if (item && Object.keys(item).length > 0) {
        if (item.status === "SUCCESS" && item.read_metadata === "SUCCESS") {
          this.dialogUpdateMetadata.show = true;
          this.dialogUpdateMetadata.value = item;
        } else if (
          item.status != "ERROR" &&
          item.status != "PROCESS" &&
          item.read_metadata != "SUCCESS"
        ) {
          // if (this.categoryDataList && this.categoryDataList.items) {
          //   if (
          //     !this.categoryId ||
          //     this.categoryDataList.items.findIndex(
          //       (x) => x.id === this.categoryId
          //     ) < 0
          //   ) {
          //     this.message.push(this.$t("message_data_catalog_required"));
          //     return;
          //   }
          this.dialogUploadMetadata.entity = Object.assign({}, item.entity);
          this.dialogUploadMetadata.value = item;
          this.dialogUploadMetadata.entity.file_path =
            this.$store.getters.getUserName +
            "/" +
            (this.dialogUploadMetadata.value
              ? this.dialogUploadMetadata.value.id
              : "");
          this.dialogUploadMetadata.show = true;
          //}
        }
      }
    },

    generateEntity() {
      return {
        area: null,
        area_unit: null,
        band: null,
        category_id: null,
        cloud_coverage: null,
        cloud_coverage_unit: null,
        description: null,
        google_earth_path: null,
        image_type: null,
        imaging_date: null,
        imaging_date_timestamp: null,
        incidence_angle: null,
        incidence_angle_unit: null,
        metadata: [],
        extend: {},
        name: null,
        resolution: null,
        resolution_unit: null,
        snow_coverage: null,
        snow_coverage_unit: null,
        processing_level: null,
        processing_level_id: null,
        mode: null,
        beam_mode: null,
        direction: null,
        pol_layer: null,
        satellite_type: null,
        satellite_id: null,
        bounding_box: [],
        provider: null,
        file_path:
          this.dialogUploadMetadata.entity &&
          Object.keys(this.dialogUploadMetadata.entity).length > 0 &&
          this.dialogUploadMetadata.entity.file_path
            ? this.dialogUploadMetadata.entity.file_path
            : null,
        icon_path: {},
        icon_path_id: null,
        icon_src: null,
        icon_file: null,
        icon_file_id: null,
        preview: {},
        preview_images: [],
        preview_path: {},
        preview_path_id: null,
        preview_src: null,
        preview_file: null,
        preview_file_id: null,
        price: 0,
        price_unit: null,
        processing_level_name: null,
        provider_name: null,
        satellite_name: null,
        size: 0,
        project_ids: [],
        source_id: null,
        is_success: false,
        scene_id: null,
        icon_files_4_select: [],
        preview_files_4_select: [],
        metadata_files_4_select: [],
        metadata_file: null,
        metadata_file_id: null,
        file_id: null,
        model_detect: null,
        data_specification: null,
      };
    },
    async getDataFromXml(file) {
      let icon_file = this.dialogUploadMetadata.entity.icon_file;
      let icon_file_id = this.dialogUploadMetadata.entity.icon_file_id;
      let icon_src = this.dialogUploadMetadata.entity.icon_src;
      let icon_files_4_select = this.dialogUploadMetadata.entity
        .icon_files_4_select;

      let preview_file = this.dialogUploadMetadata.entity.preview_file;
      let preview_file_id = this.dialogUploadMetadata.entity.preview_file_id;
      let preview_src = this.dialogUploadMetadata.entity.preview_src;
      let preview_files_4_select = this.dialogUploadMetadata.entity
        .preview_files_4_select;

      let metadata_file = this.dialogUploadMetadata.entity.metadata_file;
      let metadata_file_id = this.dialogUploadMetadata.entity.metadata_file_id;
      let metadata_files_4_select = this.dialogUploadMetadata.entity
        .metadata_files_4_select;
      let file_id = this.dialogUploadMetadata.entity.file_id;
      let category_id = this.dialogUploadMetadata.entity.category_id;

      this.dialogUploadMetadata.entity = this.generateEntity();
      this.dialogUploadMetadata.entity.icon_file = icon_file;
      this.dialogUploadMetadata.entity.icon_src = icon_src;
      this.dialogUploadMetadata.entity.icon_file_id = icon_file_id;
      this.dialogUploadMetadata.entity.icon_files_4_select = icon_files_4_select;

      this.dialogUploadMetadata.entity.preview_file = preview_file;
      this.dialogUploadMetadata.entity.preview_src = preview_src;
      this.dialogUploadMetadata.entity.preview_file_id = preview_file_id;
      this.dialogUploadMetadata.entity.preview_files_4_select = preview_files_4_select;

      this.dialogUploadMetadata.entity.metadata_file = metadata_file;
      this.dialogUploadMetadata.entity.metadata_file_id = metadata_file_id;
      this.dialogUploadMetadata.entity.metadata_files_4_select = metadata_files_4_select;
      this.dialogUploadMetadata.entity.file_id = file_id;
      this.dialogUploadMetadata.entity.category_id = category_id;
      try {
        let formData = new FormData();
        formData.append("file", file);
        let response = await fileFunc.dataFromXml(
          formData,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data && response.data.success) {
            this.dialogUploadMetadata.entity.is_success = true;
            await this.assignDataUpload(response.data.data);
            let index = this.files.findIndex((x) => x.id === file_id);
            if (index >= 0) {
              this.files[index].can_detect = false;
              this.files[index].detect_by = "client";
            }
          } else {
            this.message.push(
              this.$t("message_read_metadata_file_error", null, {
                name: file.name,
              })
            );
          }
        } else {
          this.message.push(
            this.$t("message_read_metadata_file_error", null, {
              name: file.name,
            })
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 401)
          this.refreshToken(this.getDataFromXml, file);
        else {
          this.message.push(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : this.$t("message_read_metadata_file_error", null, {
                  name: file.name,
                })
          );
        }
      }
    },

    assignDataUpload(data) {
      let previews = [];
      let icons = [];
      if (data && Object.keys(data).length > 0) {
        this.dialogUploadMetadata.entity.id = data.id;
        this.dialogUploadMetadata.entity.scene_id = data.scene_id
          ? data.scene_id
          : null;
        this.dialogUploadMetadata.entity.data_specification = data.model_detect
          ? data.model_detect
          : null;
        this.dialogUploadMetadata.entity.model_detect = data.model_detect
          ? data.model_detect
          : null;
        this.dialogUploadMetadata.entity.preview_images = data.preview_images;
        if (data.preview_images && data.preview_images.length > 0) {
          previews = data.preview_images.filter((x) => x.type === "PREVIEW");
          icons = data.preview_images.filter((x) => x.type === "ICON");
          let googleEarths = data.preview_images.filter(
            (x) => x.type === "GOOGLE_EARTH"
          );
          if (googleEarths.length > 0)
            this.dialogUploadMetadata.entity.google_earth_path =
              googleEarths[0].path_image;
        }
        this.dialogUploadMetadata.entity.area =
          data.area != null && data.area != undefined ? data.area : null;
        this.dialogUploadMetadata.entity.area_unit = data.area_unit
          ? data.area_unit.trim()
          : null;
        this.dialogUploadMetadata.entity.band =
          data.band != null && data.band != undefined ? data.band : null;
        this.dialogUploadMetadata.entity.cloud_coverage =
          data.cloud_coverage != null && data.cloud_coverage != undefined
            ? data.cloud_coverage
            : null;
        this.dialogUploadMetadata.entity.cloud_coverage_unit = data.cloud_coverage_unit
          ? data.cloud_coverage_unit.trim()
          : null;

        this.dialogUploadMetadata.entity.description = data.description
          ? data.description.trim()
          : null;
        this.dialogUploadMetadata.entity.file_path = data.file_path
          ? data.file_path.trim()
          : null;

        this.dialogUploadMetadata.entity.imaging_date = data.imaging_date
          ? data.imaging_date
          : null;
        if (this.dialogUploadMetadata.entity.imaging_date) {
          try {
            this.dialogUploadMetadata.entity.imaging_date_timestamp = new Date(
              this.dialogUploadMetadata.entity.imaging_date
            ).getTime();
          } catch (error) {}
        }
        this.dialogUploadMetadata.entity.incidence_angle =
          data.incidence_angle != null && data.incidence_angle != undefined
            ? data.incidence_angle
            : null;
        this.dialogUploadMetadata.entity.metadata = data.metadata
          ? data.metadata
          : [];

        this.dialogUploadMetadata.entity.mode = data.beam_mode
          ? data.beam_mode.trim()
          : null;
        this.dialogUploadMetadata.entity.beam_mode = data.beam_mode
          ? data.beam_mode.trim()
          : null;
        this.dialogUploadMetadata.entity.name = data.name
          ? data.name.trim()
          : null;
        this.dialogUploadMetadata.entity.pol_layer = data.pol_layer
          ? data.pol_layer.trim()
          : null;
        this.dialogUploadMetadata.entity.price =
          data.price != null && data.price != undefined ? data.price : null;
        this.dialogUploadMetadata.entity.price_unit = data.price_unit
          ? data.price_unit.trim()
          : null;

        this.dialogUploadMetadata.entity.processing_level_id = data.processing_level_id
          ? data.processing_level_id.trim()
          : null;
        if (this.dialogUploadMetadata.entity.processing_level_id) {
          this.dialogUploadMetadata.entity.processing_level = this.processingLevel.items.find(
            (x) => x.id === this.dialogUploadMetadata.entity.processing_level_id
          );
          if (
            this.dialogUploadMetadata.entity.processing_level &&
            Object.keys(this.dialogUploadMetadata.entity.processing_level)
              .length > 0
          ) {
            this.dialogUploadMetadata.entity.processing_level_name = this.dialogUploadMetadata.entity.processing_level.value;
          }
        }

        this.dialogUploadMetadata.entity.provider_id = data.provider_id
          ? data.provider_id.trim()
          : null;
        this.dialogUploadMetadata.entity.satellite_id = data.satellite_id
          ? data.satellite_id.trim()
          : null;

        this.dialogUploadMetadata.entity.resolution =
          data.resolution != undefined && data.resolution != null
            ? data.resolution
            : null;
        this.dialogUploadMetadata.entity.satellite_type = data.satellite_type
          ? data.satellite_type
          : null;

        if (
          this.dialogUploadMetadata.entity.satellite_type &&
          Object.keys(this.dialogUploadMetadata.entity.satellite_type).length >
            0
        ) {
          this.dialogUploadMetadata.entity.satellite_id = this.dialogUploadMetadata.entity.satellite_type.id;
          this.dialogUploadMetadata.entity.satellite_name = this.dialogUploadMetadata.entity.satellite_type.name;
        }

        if (!this.dialogUploadMetadata.entity.satellite_name) {
          if (this.dialogUploadMetadata.entity.provider_id) {
            let providerItem =
              this.provider && this.provider.items
                ? this.provider.items.find(
                    (x) => x.id === this.dialogUploadMetadata.entity.provider_id
                  )
                : null;
            if (providerItem && providerItem.sub_meta_key) {
              let satelliteItem = providerItem.sub_meta_key.find(
                (x) => x.id === this.dialogUploadMetadata.entity.satellite_id
              );
              if (satelliteItem)
                this.dialogUploadMetadata.entity.satellite_name = satelliteItem.name
                  ? satelliteItem.name
                  : null;
            }
          }
        }
        this.dialogUploadMetadata.entity.size =
          data.size != undefined && data.size != null ? data.size : null;

        this.dialogUploadMetadata.entity.snow_coverage =
          data.snow_coverage != undefined && data.snow_coverage != null
            ? data.snow_coverage
            : null;
        this.dialogUploadMetadata.entity.snow_coverage_unit = data.snow_coverage_unit
          ? data.snow_coverage_unit.trim()
          : null;

        if (previews && previews.length > 0) {
          this.dialogUploadMetadata.entity.preview_path = previews.find(
            (x) => x.path_image === data.preview_path
          );
        }
        if (
          !this.dialogUploadMetadata.entity.preview_path ||
          Object.keys(this.dialogUploadMetadata.entity.preview_path).length ===
            0
        ) {
          this.dialogUploadMetadata.entity.preview_path = {
            ext: null,
            path_image: data.preview_path ? data.preview_path : null,
            size: null,
            type: null,
          };
        }

        if (icons && icons.length > 0) {
          this.dialogUploadMetadata.entity.icon_path = icons.find(
            (x) => x.path_image === data.icon_path
          );
        }
        if (
          !this.dialogUploadMetadata.entity.icon_path ||
          Object.keys(this.dialogUploadMetadata.entity.icon_path).length === 0
        ) {
          this.dialogUploadMetadata.entity.icon_path = {
            ext: null,
            path_image: data.icon_path ? data.icon_path : null,
            size: null,
            type: null,
          };
        }
        this.dialogUploadMetadata.entity.icon_path_id = data.icon_path;
        this.dialogUploadMetadata.entity.preview_path_id = data.preview_path;

        if (data.image_type)
          this.dialogUploadMetadata.entity.image_type =
            data.image_type && data.image_type.value
              ? data.image_type.value
              : null;
        if (data.metadata && Object.keys(data.metadata).length > 0) {
          this.dialogUploadMetadata.entity.source_id = data.metadata.source_id
            ? data.metadata.source_id.trim()
            : data.metadata.product_id;
          if (
            this.dialogUploadMetadata.entity.area === null ||
            this.dialogUploadMetadata.entity.area === undefined
          ) {
            this.dialogUploadMetadata.entity.area = data.metadata.area;
          }
          if (
            this.dialogUploadMetadata.entity.area_unit === null ||
            this.dialogUploadMetadata.entity.area_unit === undefined
          ) {
            this.dialogUploadMetadata.entity.area_unit =
              data.metadata.area_unit;
          }
          if (
            this.dialogUploadMetadata.entity.band === null ||
            this.dialogUploadMetadata.entity.band === undefined
          ) {
            this.dialogUploadMetadata.entity.band = data.metadata.band;
          }

          if (
            this.dialogUploadMetadata.entity.cloud_coverage === null ||
            this.dialogUploadMetadata.entity.cloud_coverage === undefined
          ) {
            this.dialogUploadMetadata.entity.cloud_coverage =
              data.metadata.cloud_coverage;
          }
          if (
            this.dialogUploadMetadata.entity.cloud_coverage_unit === null ||
            this.dialogUploadMetadata.entity.cloud_coverage_unit === undefined
          ) {
            this.dialogUploadMetadata.entity.cloud_coverage_unit =
              data.metadata.cloud_coverage_unit;
          }

          if (
            this.dialogUploadMetadata.entity.snow_coverage === null ||
            this.dialogUploadMetadata.entity.snow_coverage === undefined
          ) {
            this.dialogUploadMetadata.entity.snow_coverage =
              data.metadata.snow_coverage;
          }
          if (
            this.dialogUploadMetadata.entity.snow_coverage_unit === null ||
            this.dialogUploadMetadata.entity.snow_coverage_unit === undefined
          ) {
            this.dialogUploadMetadata.entity.snow_coverage_unit =
              data.metadata.snow_coverage_unit;
          }

          if (
            this.dialogUploadMetadata.entity.imaging_date === null ||
            this.dialogUploadMetadata.entity.imaging_date === undefined
          ) {
            this.dialogUploadMetadata.entity.imaging_date =
              data.metadata.imaging_date;
            if (this.dialogUploadMetadata.entity.imaging_date) {
              try {
                this.dialogUploadMetadata.entity.imaging_date_timestamp = new Date(
                  this.dialogUploadMetadata.entity.imaging_date
                ).getTime();
              } catch (error) {}
            }
          }

          if (
            this.dialogUploadMetadata.entity.mode === null ||
            this.dialogUploadMetadata.entity.mode === undefined
          ) {
            this.dialogUploadMetadata.entity.mode = data.metadata.beam_mode;
            this.dialogUploadMetadata.entity.beam_mode =
              data.metadata.beam_mode;
          }

          if (
            this.dialogUploadMetadata.entity.incidence_angle === null ||
            this.dialogUploadMetadata.entity.incidence_angle === undefined
          ) {
            this.dialogUploadMetadata.entity.incidence_angle =
              data.metadata.incidence_angle;
          }
          if (
            this.dialogUploadMetadata.entity.pol_layer === null ||
            this.dialogUploadMetadata.entity.pol_layer === undefined
          ) {
            this.dialogUploadMetadata.entity.pol_layer =
              data.metadata.pol_layer;
          }
          if (
            this.dialogUploadMetadata.entity.direction === null ||
            this.dialogUploadMetadata.entity.direction === undefined
          ) {
            this.dialogUploadMetadata.entity.direction =
              data.metadata.direction;
          }

          if (
            this.dialogUploadMetadata.entity.resolution === null ||
            this.dialogUploadMetadata.entity.resolution === undefined
          ) {
            this.dialogUploadMetadata.entity.resolution =
              data.metadata.resolution;
          }
          if (data.bounding_box) {
            let coors = data.bounding_box;
            if (!this.dialogUploadMetadata.entity.bounding_box)
              this.dialogUploadMetadata.entity.bounding_box = [];
            for (let i = 0; i < coors.length; i++) {
              this.dialogUploadMetadata.entity.bounding_box.push([
                coors[i][0],
                coors[i][1],
              ]);
            }
            if (coors.length === 4) {
              this.dialogUploadMetadata.entity.bounding_box.push([
                coors[0][0],
                coors[0][1],
              ]);
            }
          }
        }
        this.dialogUploadMetadata.entity.cloud_coverage =
          this.dialogUploadMetadata.entity.cloud_coverage != null &&
          this.dialogUploadMetadata.entity.cloud_coverage != undefined &&
          (this.dialogUploadMetadata.entity.cloud_coverage + "").trim() != "-1"
            ? this.dialogUploadMetadata.entity.cloud_coverage
            : null;
        this.dialogUploadMetadata.entity.snow_coverage =
          this.dialogUploadMetadata.entity.snow_coverage != null &&
          this.dialogUploadMetadata.entity.snow_coverage != undefined &&
          (this.dialogUploadMetadata.entity.snow_coverage + "").trim() != "-1"
            ? this.dialogUploadMetadata.entity.snow_coverage
            : null;
      }
    },
    clearEntity() {
      this.dialogUploadMetadata.entity = this.generateEntity();
    },
    async metaFileChange(file) {
      await this.getDataFromXml(file);

      if (
        this.dialogUploadMetadata.entity &&
        Object.keys(this.dialogUploadMetadata.entity).length > 0 &&
        !this.dialogUploadMetadata.entity.file_path
      ) {
        this.dialogUploadMetadata.entity.file_path =
          this.$store.getters.getUserName +
          "/" +
          (this.dialogUploadMetadata.value
            ? this.dialogUploadMetadata.value.id
            : "");
      }
    },
    async onUpload(data) {
      this.dialogUploadMetadata.button.disabled = true;
      this.dialogUploadMetadata.button.processing = true;
      if (
        this.dialogUploadMetadata.entity &&
        Object.keys(this.dialogUploadMetadata.entity).length > 0
      ) {
        let index = this.files.findIndex(
          (x) => x.id === this.dialogUploadMetadata.entity.file_id
        );
        if (index >= 0) {
          if (this.files[index].status != "SUCCESS") {
            if (
              !["SUCCESS", "PROCESS", "ERROR"].includes(
                this.files[index].status
              )
            )
              this.files[index].status = "PROCESS";
            if (data && Object.keys(data).length > 0) {
              this.files[index].entity = data;
            }
            if (this.files[index].entity.processing_level_name) {
              this.files[
                index
              ].entity.processing_level = this.processingLevel.items.find(
                (x) => x.name === this.files[index].entity.processing_level_name
              );
              if (
                this.files[index].entity.processing_level &&
                Object.keys(this.files[index].entity.processing_level).length >
                  0
              ) {
                this.files[index].entity.processing_level_id = this.files[
                  index
                ].entity.processing_level.id;
              }
            }
            this.files[index].can_detect = true;
            this.files[index].detect_by = "server";
            await this.testZipFile(this.files[index]);
          }
        }
      }
      this.dialogUploadMetadata.button.disabled = false;
      this.dialogUploadMetadata.button.processing = false;
    },
    async addImage2Server(entity) {
      this.dialogUploadMetadata.button.disabled = true;
      this.dialogUploadMetadata.button.processing = true;
      let idx = this.files.findIndex((x) =>
        this.dialogUploadMetadata.show &&
        this.dialogUploadMetadata.value &&
        this.dialogUploadMetadata.value.id
          ? x.id === this.dialogUploadMetadata.value.id
          : x.id === entity.file_id
      );
      if (idx >= 0) this.files[idx].read_metadata = "PROCESSING";
      if (entity.imaging_date_timestamp) {
        try {
          entity.imaging_date = new Date(entity.imaging_date_timestamp);
        } catch (error) {}
      }
      if (entity.metadata && Object.keys(entity.metadata).length > 0) {
        entity.metadata.incidence_angle = entity.incidence_angle;
        entity.metadata.resolution = entity.resolution;
        entity.metadata.cloud_coverage = entity.cloud_coverage;
        entity.metadata.cloud_coverage_unit = entity.cloud_coverage_unit;
        entity.metadata.snow_coverage = entity.snow_coverage;
        entity.metadata.snow_coverage_unit = entity.snow_coverage_unit;
        entity.metadata.area = entity.area;
        entity.metadata.area_unit = entity.area_unit;
        entity.metadata.mode = entity.mode;
        entity.metadata.beam_mode = entity.mode;
        entity.metadata.direction = entity.direction;
        entity.metadata.pol_layer = entity.pol_layer;
        entity.metadata.imaging_date = entity.imaging_date;
      }
      let dto = Object.assign({}, entity);
      if (dto.preview_file) delete dto.preview_file;
      dto.preview_path = dto.preview.preview_path;
      if (dto.preview_src) delete dto.preview_src;
      if (dto.icon_file) delete dto.icon_file;
      dto.icon_path = dto.preview.icon_path;
      if (dto.icon_src) delete dto.icon_src;
      if (dto.metadata_file) delete dto.metadata_file;
      if (dto.metadata_files_4_select) delete dto.metadata_files_4_select;
      if (dto.preview_files_4_select) delete dto.preview_files_4_select;
      if (dto.icon_files_4_select) delete dto.icon_files_4_select;
      dto.preview_images = dto.preview.preview_images;
      try {
        if (dto.bounding_box && dto.bounding_box.length > 0) {
          for (let i = 0; i < dto.bounding_box.length; i++) {
            for (let j = 0; j < dto.bounding_box[i].length; j++) {
              if (!dto.bounding_box[i][j]) {
                dto.bounding_box[i][j] = parseFloat("0.0").toFixed(1) / 2;
              }
            }
          }
        }
      } catch (error) {}
      try {
        let response = await imageFunc.create(
          dto,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success) {
            if (idx >= 0) {
              this.files[idx].loading_data = false;
              this.files[idx].read_metadata = "SUCCESS";
              this.files[idx].data_file =
                response.data && response.data.data ? response.data.data : null;
              this.files[idx].data_file.icon_files_4_select =
                entity.icon_files_4_select;
              this.files[idx].data_file.icon_file = entity.icon_file;
              this.files[idx].data_file.icon_file_id = dto.icon_file_id;

              this.files[idx].data_file.preview_files_4_select =
                entity.preview_files_4_select;
              this.files[idx].data_file.preview_file = entity.preview_file;
              this.files[idx].data_file.preview_file_id = dto.preview_file_id;

              this.files[idx].data_file.metadata_files_4_select =
                entity.metadata_files_4_select;
              this.files[idx].data_file.metadata_file = entity.metadata_file;
              this.files[idx].data_file.metadata_file_id = dto.metadata_file_id;
              this.files[idx].entity = dto;
            }
            this.$toast.success({
              title: this.$t("title_message_info"),
              message: this.$t("message_additional_information_success", null, {
                name: entity.name,
              }),
              position: "top right",
            });
            if (entity.project_ids && entity.project_ids.length > 0) {
              for (let i = 0; i < entity.project_ids.length; i++) {
                this.addImage2Project({
                  project_id: entity.project_ids[i],
                  image_id: response.data.data ? response.data.data.id : null,
                });
              }
            }
            this.dialogUploadMetadata.show = false;
            this.dialogUploadMetadata.value = null;

            setTimeout(() => {
              this.dialogUploadMetadata.entity = {};
            }, 200);
          } else {
            if (idx >= 0) {
              this.files[idx].read_metadata = "ERROR";
            }
            this.message.push(
              this.$t("message_add_image_to_server_error", null, {
                name: entity.name,
              })
            );
          }
        } else {
          if (idx >= 0) {
            this.files[idx].read_metadata = "ERROR";
          }
          this.message.push(
            this.$t("message_add_image_to_server_error", null, {
              name: entity.name,
            })
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.addImage2Server, entity);
        } else {
          if (idx >= 0) {
            this.files[idx].read_metadata = "ERROR";
          }
          let msg =
            error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push(
            msg
              ? msg
              : this.$t("message_add_image_to_server_error", null, {
                  name: entity.name,
                })
          );
        }
      }
      this.dialogUploadMetadata.button.disabled = false;
      this.dialogUploadMetadata.button.processing = false;
    },
    async addImage2Project(data) {
      try {
        let response = await projectFunc.addImage(
          data.project_id,
          data.image_id,
          this.$store.getters.getAccessToken
        );
      } catch (error) {
        if (error.response && error.response.status === 401)
          this.refreshToken(this.addImage2Project, data);
      }
    },

    async handleUpdateMetadata(entity) {
      let dto = Object.assign({}, entity);
      if (dto.imaging_date_timestamp) {
        try {
          dto.imaging_date = new Date(dto.imaging_date_timestamp);
          dto.metadata.imaging_date = dto.imaging_date;
        } catch (error) {}
      }
      if (dto.processing_level_name) {
        dto.processing_level = this.processingLevel.items.find(
          (x) => x.name === dto.processing_level_name
        );
        if (
          dto.processing_level &&
          Object.keys(dto.processing_level).length > 0
        ) {
          dto.processing_level_id = dto.processing_level.id;
        }
      }

      if (dto.satellite_id) {
        if (dto.satellite_type && Object.keys(dto.satellite_type).length > 0) {
          if (dto.satellite_type.id != dto.satellite_id) {
            for (let i = 0; i < this.provider.items.length; i++) {
              if (this.provider.items[i].sub_meta_key) {
                dto.satellite_type = this.provider.items[i].sub_meta_key.find(
                  (x) => x.id === dto.satellite_id
                );
                if (
                  dto.satellite_type &&
                  Object.keys(dto.satellite_type).length > 0
                ) {
                  dto.provider = this.provider.items[i].id;
                  dto.provider_name = this.provider.items[i].name;
                  dto.satellite_name = dto.satellite_type.name;
                  break;
                }
              }
            }
          }
        } else {
          for (let i = 0; i < this.provider.items.length; i++) {
            if (this.provider.items[i].sub_meta_key) {
              dto.satellite_type = this.provider.items[i].sub_meta_key.find(
                (x) => x.id === dto.satellite_id
              );
              if (
                dto.satellite_type &&
                Object.keys(dto.satellite_type).length > 0
              ) {
                dto.provider = this.provider.items[i].id;
                dto.provider_name = this.provider.items[i].name;
                dto.satellite_name = dto.satellite_type.name;
                break;
              }
            }
          }
        }
      }
      if (!dto.metadata) dto.metadata = {};
      dto.metadata.imaging_date = dto.imaging_date;
      dto.metadata.resolution = dto.resolution;
      dto.metadata.incidence_angle = dto.incidence_angle;
      dto.metadata.band = dto.band;
      dto.metadata.area = dto.area;
      dto.metadata.area_unit = dto.area_unit;
      dto.metadata.snow_coverage = dto.snow_coverage;
      dto.metadata.snow_coverage_unit = dto.snow_coverage_unit;
      dto.metadata.cloud_coverage = dto.cloud_coverage;
      dto.metadata.cloud_coverage_unit = dto.cloud_coverage_unit;
      dto.mode = dto.beam_mode;
      dto.metadata.beam_mode = dto.beam_mode;
      dto.metadata.mode = dto.beam_mode;
      dto.metadata.direction = dto.direction;
      dto.metadata.pol_layer = dto.pol_layer;
      dto.extend = dto.metadata;

      let previewPath, iconPath, previewUrl, iconUrl;
      let mimeTypePreview;
      let mimeTypeIcon;
      if (
        dto.preview_file &&
        dto.preview_file_id != dto.preview_file_added_id
      ) {
        mimeTypePreview = getMimeType(
          dto.preview_file.name
            .split(".")
            .pop()
            .toLowerCase()
        );
        previewPath =
          "previews/" +
          CryptoJS.MD5(
            dto.preview_file.name +
              Math.floor(Math.random() * 9999) +
              new Date().getTime()
          ).toString();
        try {
          let responsePreview = await imageFunc.createLinkPreview(
            mimeTypePreview && mimeTypePreview.type
              ? mimeTypePreview.type
              : dto.preview_file.type
              ? dto.preview_file.type
              : "image/jpg",
            previewPath,
            this.$store.getters.getAccessToken
          );
          if (responsePreview && responsePreview.status === 200) {
            previewUrl = responsePreview.data ? responsePreview.data.url : null;
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            this.refreshToken(this.handleUpdateMetadata, entity);
            return;
          }
        }
      }
      if (dto.icon_file && dto.icon_file_id != dto.icon_file_added_id) {
        mimeTypeIcon = getMimeType(
          dto.icon_file.name
            .split(".")
            .pop()
            .toLowerCase()
        );
        iconPath =
          "previews/" +
          CryptoJS.MD5(
            dto.icon_file.name +
              Math.floor(Math.random() * 9999) +
              new Date().getTime()
          ).toString();
        try {
          let responseIcon = await imageFunc.createLinkPreview(
            mimeTypeIcon && mimeTypeIcon.type
              ? mimeTypeIcon.type
              : dto.icon_file.type
              ? dto.icon_file.type
              : "image/jpg",
            iconPath,
            this.$store.getters.getAccessToken
          );
          if (responseIcon && responseIcon.status === 200) {
            iconUrl = responseIcon.data ? responseIcon.data.url : null;
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            this.refreshToken(this.handleUpdateMetadata, entity);
            return;
          }
        }
      }
      if (dto.preview && dto.preview.preview_images === null)
        dto.preview.preview_images = [];
      if (!dto.preview_images) dto.preview_images = [];
      if (previewUrl) {
        try {
          let response = await imageFunc.uploadFilePresignedUrl(
            previewUrl,
            dto.preview_file,
            mimeTypePreview && mimeTypePreview.type
              ? mimeTypePreview.type
              : dto.preview_file.type
              ? dto.preview_file.type
              : "image/jpg"
          );
          if (response && response.status === 200) {
            dto.preview_path = previewPath;
            dto.preview.preview_path = previewPath;
            dto.preview.preview_images.push({
              type: "PREVIEW",
              path: previewPath,
              size: dto.preview_file.size,
              ext: dto.preview_file.name
                .split(".")
                .pop()
                .toLowerCase(),
            });
          }
        } catch (error) {}
      }
      if (iconUrl) {
        try {
          let response = await imageFunc.uploadFilePresignedUrl(
            iconUrl,
            dto.icon_file,
            mimeTypeIcon && mimeTypeIcon.type
              ? mimeTypeIcon.type
              : dto.icon_file.type
              ? dto.icon_file.type
              : "image/jpg"
          );
          if (response && response.status === 200) {
            dto.icon_path = iconPath;
            dto.preview.icon_path = iconPath;
            dto.preview.preview_images.push({
              type: "ICON",
              path: iconPath,
              size: dto.icon_file.size,
              ext: dto.icon_file.name
                .split(".")
                .pop()
                .toLowerCase(),
            });
          }
        } catch (error) {}
      }
      if (dto.preview_file) delete dto.preview_file;
      if (dto.preview_src) delete dto.preview_src;
      if (dto.icon_file) delete dto.icon_file;
      if (dto.icon_src) delete dto.icon_src;

      this.updateMetadata2DB(dto);
    },

    async updateMetadata2DB(entity) {
      this.dialogUpdateMetadata.button.disabled = true;
      this.dialogUpdateMetadata.button.processing = true;
      this.message = [];
      if (!entity.name || !entity.name.trim()) {
        this.message.push(this.$t("message_image_name_required"));
        return;
      }
      if (!entity.satellite_name || !entity.satellite_name.trim()) {
        this.message.push(this.$t("message_satellite_required"));
      }
      if (this.message.length > 0) {
        return;
      }
      if (!entity.provider) delete entity.provider;
      if (!entity.preview_images || entity.preview_images.length === 0) {
        entity.preview_images =
          entity.preview && entity.preview.preview_images
            ? entity.preview.preview_images
            : [];
      }
      try {
        let response = await imageFunc.update(
          entity,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data && response.data.data) {
            let index = this.files.findIndex(
              (x) => x.data_file && x.data_file.id === entity.id
            );
            if (index >= 0) {
              this.files[index].data_file = response.data.data;
              this.files[index].data_file.icon_files_4_select =
                entity.icon_files_4_select;
              this.files[index].data_file.icon_file = entity.icon_file;
              this.files[index].data_file.icon_file_id = entity.icon_file_id;

              this.files[index].data_file.preview_files_4_select =
                entity.preview_files_4_select;
              this.files[index].data_file.preview_file = entity.preview_file;
              this.files[index].data_file.preview_file_id =
                entity.preview_file_id;

              this.files[index].data_file.metadata_files_4_select =
                entity.metadata_files_4_select;
              this.files[index].data_file.metadata_file = entity.metadata_file;
              this.files[index].data_file.metadata_file_id =
                entity.metadata_file_id;
            }
          }
          this.dialogUpdateMetadata.show = false;
          this.dialogUpdateMetadata.value = null;
          this.$toast.success({
            title: this.$t("title_message_info"),
            message: this.$t("message_update_metadata_success", null, {
              name: entity.name,
            }),
            position: "top right",
          });
        } else {
          this.message.push(
            this.$t("message_update_metadata_error", null, {
              name: entity.name,
            })
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 401)
          this.refreshToken(this.updateMetadata2DB, entity);
        else {
          let msg =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push(
            msg
              ? msg
              : this.$t("message_update_metadata_error", null, {
                  name: entity.name,
                })
          );
        }
      }
      this.dialogUpdateMetadata.button.disabled = false;
      this.dialogUpdateMetadata.button.processing = false;
    },
    async confirmMetadata(entity) {
      let dto = Object.assign({}, entity);
      if (dto.imaging_date_timestamp) {
        try {
          dto.imaging_date = new Date(dto.imaging_date_timestamp);
          dto.metadata.imaging_date = dto.imaging_date;
        } catch (error) {}
      }
      if (dto.processing_level_name) {
        dto.processing_level = this.processingLevel.items.find(
          (x) => x.name === dto.processing_level_name
        );
        if (
          dto.processing_level &&
          Object.keys(dto.processing_level).length > 0
        ) {
          dto.processing_level_id = dto.processing_level.id;
        }
      }
      if (dto.icon_path_id && dto.preview_images) {
        dto.icon_path = dto.preview_images.find(
          (x) => x.path_image === dto.icon_path_id && x.type === "ICON"
        );
        if (dto.icon_path && dto.icon_path.src) delete dto.icon_path.src;
      }
      if (!dto.icon_path || Object.keys(dto.icon_path).length === 0) {
        // dto.icon_path = {
        //   ext: null,
        //   path_image: dto.icon_path_id ? dto.icon_path_id : null,
        //   size: null,
        //   type: null,
        // };
        dto.icon_path = null;
      }

      if (dto.preview_path_id && dto.preview_images) {
        dto.preview_path = dto.preview_images.find(
          (x) => x.path_image === dto.preview_path_id && x.type === "PREVIEW"
        );
        if (dto.preview_path && dto.preview_path.src)
          delete dto.preview_path.src;
      }
      if (!dto.preview_path || Object.keys(dto.preview_path).length === 0) {
        // dto.preview_path = {
        //   ext: null,
        //   path_image: dto.preview_path_id ? dto.preview_path_id : null,
        //   size: null,
        //   type: null,
        // };
        dto.preview_path = null;
      }

      if (dto.satellite_id) {
        if (dto.satellite_type && Object.keys(dto.satellite_type).length > 0) {
          if (dto.satellite_type.id != dto.satellite_id) {
            for (let i = 0; i < this.provider.items.length; i++) {
              if (this.provider.items[i].sub_meta_key) {
                dto.satellite_type = this.provider.items[i].sub_meta_key.find(
                  (x) => x.id === dto.satellite_id
                );
                if (
                  dto.satellite_type &&
                  Object.keys(dto.satellite_type).length > 0
                ) {
                  dto.provider = this.provider.items[i].id;
                  dto.provider_name = this.provider.items[i].name;
                  dto.satellite_name = dto.satellite_type.name;
                  break;
                }
              }
            }
          }
        } else {
          for (let i = 0; i < this.provider.items.length; i++) {
            if (this.provider.items[i].sub_meta_key) {
              dto.satellite_type = this.provider.items[i].sub_meta_key.find(
                (x) => x.id === dto.satellite_id
              );
              if (
                dto.satellite_type &&
                Object.keys(dto.satellite_type).length > 0
              ) {
                dto.provider = this.provider.items[i].id;
                dto.provider_name = this.provider.items[i].name;
                dto.satellite_name = dto.satellite_type.name;
                break;
              }
            }
          }
        }
      }
      if (!dto.metadata) dto.metadata = {};
      dto.metadata.imaging_date = dto.imaging_date;
      dto.metadata.resolution = dto.resolution;
      dto.metadata.incidence_angle = dto.incidence_angle;
      dto.metadata.band = dto.band;
      dto.metadata.area = dto.area;
      dto.metadata.area_unit = dto.area_unit;
      dto.metadata.snow_coverage = dto.snow_coverage;
      dto.metadata.snow_coverage_unit = dto.snow_coverage_unit;
      dto.metadata.cloud_coverage = dto.cloud_coverage;
      dto.metadata.cloud_coverage_unit = dto.cloud_coverage_unit;
      dto.mode = dto.beam_mode;
      dto.metadata.beam_mode = dto.beam_mode;
      dto.metadata.mode = dto.beam_mode;
      dto.metadata.direction = dto.direction;
      dto.metadata.pol_layer = dto.pol_layer;
      dto.extend = dto.metadata;

      this.message = [];
      if (!dto.name || !dto.name.trim()) {
        this.message.push(this.$t("message_image_name_required"));
        return;
      }
      if (!dto.satellite_name || !dto.satellite_name.trim()) {
        this.message.push(this.$t("message_satellite_required"));
      }
      if (this.message.length > 0) {
        return;
      }
      if (!dto.provider) delete dto.provider;
      if (dto.bounding_box != undefined) delete dto.bounding_box;
      try {
        let response = await imageFunc.update(
          dto,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data && response.data.success) {
            let index = this.files.findIndex(
              (x) => x.id === this.dialogMetadata.item.id
            );
            if (index >= 0) {
              this.files[index].entity = entity;
              this.files[index].data_file = response.data.data
                ? response.data.data
                : entity;
            }
            this.$toast.success({
              title: this.$t("title_message_info"),
              message: this.$t("message_update_metadata_success", null, {
                name: entity.name,
              }),
              position: "top right",
            });
            setTimeout(() => {
              this.dialogMetadata.show = false;
              this.dialogMetadata.item = null;
            }, 100);
          } else {
            this.message.push(
              this.$t("message_update_metadata_error", null, {
                name: entity.name,
              })
            );
          }
        } else {
          this.message.push(
            this.$t("message_update_metadata_error", null, {
              name: entity.name,
            })
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 401)
          this.refreshToken(this.confirmMetadata, entity);
        else {
          let msg =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push(
            msg
              ? msg
              : this.$t("message_update_metadata_error", null, {
                  name: entity.name,
                })
          );
        }
      }
    },
    removeItem(item) {
      if (item && Object.keys(item).length > 0) {
        let index = this.files.findIndex((x) => x.id === item.id);
        if (index >= 0) this.files.splice(index, 1);
      }
    },
    msToTime(millisec) {
      millisec = Math.ceil(millisec);
      var seconds = millisec / 1000;
      // 2- Extract hours:
      var hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
      seconds = seconds % 3600; // seconds remaining after extracting hours
      // 3- Extract minutes:
      var minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
      // 4- Keep only seconds not extracted to minutes:
      seconds = Math.ceil(seconds % 60);
      let result = "";
      if (hours + "" != "0")
        result = result + hours + " " + this.$t("label_hour").toLowerCase();
      if (minutes + "" != "0")
        result =
          result + " " + minutes + " " + this.$t("label_minute").toLowerCase();
      if (seconds + "" != "0")
        result =
          result + " " + seconds + " " + this.$t("label_second").toLowerCase();

      return result;
    },
    calculator() {
      var vm = this;
      this.remainingTime.count = this.remainingTime.count + 1;
      let uploadedSize = 0;
      for (let i = 0; i < this.files.length; i++) {
        uploadedSize =
          uploadedSize +
          this.files[i].files.reduce(
            (sum, record) => sum + parseFloat(record["uploaded_size"]),
            0
          );
      }
      let differenceValue = Math.abs(
        uploadedSize -
          (vm.remainingTime.oldUploadedSize
            ? vm.remainingTime.oldUploadedSize
            : 0)
      );
      if (!differenceValue) return;

      this.remainingTime.timeValue =
        (this.remainingTime.count * 500 * this.totalSize) / uploadedSize -
        this.remainingTime.count * 500;

      this.remainingTime.progressValue = Math.ceil(
        (uploadedSize * 100) / vm.totalSize
      );
      this.remainingTime.oldUploadedSize = uploadedSize;
      if (
        this.files.filter(function(x) {
          return x.status === "SUCCESS" || x.status === "ERROR";
        }).length === this.files.length
      ) {
        vm.remainingTime.countHold = false;
        vm.buttonUpload.label = vm.$t("button_text_upload");
        vm.buttonUpload.status = "NORMAL";
        vm.buttonUpload.icon = "fas fa-upload";
        vm.buttonUpload.class = "btn btn-primary btn-sm";
        this.uploading = false;
        if (this.intervalTime) clearInterval(this.intervalTime);
      }
    },
    async addUpload(items) {},
    async startUpload() {
      // if (this.categoryDataList && this.categoryDataList.items) {
      //   if (
      //     !this.categoryId ||
      //     this.categoryDataList.items.findIndex(
      //       (x) => x.id === this.categoryId
      //     ) < 0
      //   ) {
      //     this.message.push(this.$t("message_data_catalog_required"));
      //     return;
      //   }

      let totalUploadSizeConfig = 6;
      try {
        totalUploadSizeConfig = parseFloat(
          this.dataConfigJson && this.dataConfigJson.total_max_filesize
            ? this.dataConfigJson.total_max_filesize
            : totalUploadSizeConfig
        );
      } catch (error) {}
      if (this.totalUploadSize > totalUploadSizeConfig * 1024 * 1024 * 1024) {
        this.message.push(
          this.$t("message_total_max_file_size", null, {
            val: totalUploadSizeConfig + "G",
          })
        );
        return;
      } else {
        this.buttonUpload.status = "UPLOADING";
        for (let i = 0; i < this.files.length; i++) {
          if (this.files[i].status != "SUCCESS") {
            if (!["SUCCESS", "PROCESS", "ERROR"].includes(this.files[i].status))
              this.files[i].status = "PROCESS";
            await this.testZipFile(this.files[i]);
          }
        }
        this.buttonUpload.status = "NORMAL";
      }
      //}
    },
    async testZipFile(data) {
      const model = (() => {
        let zipWriter;
        return {
          addFile(file, options) {
            if (!zipWriter) {
              zipWriter = new zip.ZipWriter(
                new zip.BlobWriter("application/zip")
              );
            }
            return zipWriter.add(
              file.webkitRelativePath
                ? file.webkitRelativePath
                : file.fullPath
                ? file.fullPath
                : file.name,
              new zip.BlobReader(file),
              options
            );
          },
          async getBlobURL() {
            if (zipWriter) {
              const blobURL = URL.createObjectURL(await zipWriter.close());
              zipWriter = null;
              return blobURL;
            } else {
              throw new Error("Zip file closed");
            }
          },
          async getBlob() {
            if (zipWriter) {
              const blob = await zipWriter.close();
              zipWriter = null;
              return blob;
            } else {
              throw new Error("Zip file closed");
            }
          },
          async destroy() {
            if (zipWriter) zipWriter = null;
          },
        };
      })();
      for (let i = 0; i < data.files.length; i++) {
        const controller = new AbortController();
        const signal = controller.signal;
        try {
          const entry = await model.addFile(data.files[i], {
            bufferedWrite: true,
            password: null,
            signal,
            onprogress: (index, max) => {},
          });
        } catch (error) {
          if (error.message == zip.ERR_ABORT) {
          } else {
          }
        } finally {
        }
      }
      let dataUpload = {};
      try {
        dataUpload.blob = await model.getBlob();
        if (dataUpload.blob.size > 0) {
          dataUpload.fileUpload = new File(
            [dataUpload.blob],
            change_alias(string_all_trim(data.name)) + ".zip"
          );
          await this.uploadFileTest({
            id: data.id,
            file: dataUpload.fileUpload,
            entity: data.entity,
            can_detect: data.can_detect,
            detect_by: data.detect_by,
          });
        }
      } catch (error) {}
      /*var JSZip = require("jszip");
      var zip = new JSZip();
      for (let i = 0; i < data.files.length; i++) {
        zip.file(
          data.files[i].webkitRelativePath
            ? data.files[i].webkitRelativePath
            : data.files[i].fullPath
            ? data.files[i].fullPath
            : data.files[i].name,
          data.files[i]
        );
      }
      var vm = this;
      zip.generateAsync({ type: "blob" }).then(async function (content) {
        try {
          let dataUpload = {
            blob: content,
          };
          if (dataUpload.blob.size > 0) {
            dataUpload.fileUpload = new File(
              [dataUpload.blob],
              change_alias(string_all_trim(data.name)) + ".zip"
            );
            await vm.uploadFileTest({
              id: data.id,
              file: dataUpload.fileUpload,
              entity: data.entity,
              can_detect: data.can_detect,
              detect_by: data.detect_by,
            });
            for (let i = 0; i < data.files[i].length; i++) {
              zip.remove(
                data.files[i].webkitRelativePath
                  ? data.files[i].webkitRelativePath
                  : data.files[i].fullPath
                  ? data.files[i].fullPath
                  : data.files[i].name
              );
            }
            delete dataUpload.blob;
            delete dataUpload.fileUpload;
            dataUpload = {};
          }
        } catch (error) {
        }
      });
      */
    },
    async uploadFileTest(data) {
      var vm = this;
      let index = this.files.findIndex((x) => x.id === data.id);
      try {
        setTimeout(() => {
          Event.$emit("imageProgressBar", vm.files[index]);
        }, 100);
        let responseGetUrl = await imageFunc.uploadImage(
          "application/zip",
          this.$store.getters.getUserName + "/" + data.id,
          this.$store.getters.getAccessToken,
          data.file.name
        );
        if (responseGetUrl && responseGetUrl.status === 200) {
          if (responseGetUrl.data && responseGetUrl.data.url) {
            let res = await fileFunc.uploadFilePresignedUrl(
              responseGetUrl.data.url,
              data.file,
              "application/zip"
            );
            if (res && res.status === 200) {
              if (index >= 0) {
                this.files[index].status = "SUCCESS";
                data.entity.file_path =
                  this.$store.getters.getUserName + "/" + data.id;

                // user detect
                if (data.can_detect && data.detect_by === "server")
                  this.onUploadFile(data.entity);
                else this.getMetadata(data);
                //this.getMetadata(data);
              }
              this.remainingTime.progressValue = Math.ceil(
                (this.files.filter(
                  (x) => x.status === "SUCCESS" || x.status === "ERROR"
                ).length *
                  100) /
                  this.files.length
              );
            } else {
              if (index >= 0) this.files[index].status = "ERROR";

              this.remainingTime.progressValue = Math.ceil(
                (this.files.filter(
                  (x) => x.status === "SUCCESS" || x.status === "ERROR"
                ).length *
                  100) /
                  this.files.length
              );
            }
          } else {
            if (index >= 0) this.files[index].status = "ERROR";
            this.remainingTime.progressValue = Math.ceil(
              (this.files.filter(
                (x) => x.status === "SUCCESS" || x.status === "ERROR"
              ).length *
                100) /
                this.files.length
            );
          }
        } else {
          if (index >= 0) this.files[index].status = "ERROR";
          this.remainingTime.progressValue = Math.ceil(
            (this.files.filter(
              (x) => x.status === "SUCCESS" || x.status === "ERROR"
            ).length *
              100) /
              this.files.length
          );
        }

        Event.$emit("imageProgressBar", vm.files[index]);
        setTimeout(() => {
          if (
            vm.files.filter(
              (x) => x.status === "SUCCESS" || x.status === "ERROR"
            ).length === vm.files.length
          ) {
            setTimeout(() => {
              vm.$store.dispatch("showWidgetUploadProgressBar", false);
            }, 1000);
          }
        }, 300);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(uploadFileTest, data);
        } else {
          if (index >= 0) this.files[index].status = "ERROR";

          this.remainingTime.progressValue = Math.ceil(
            (this.files.filter(
              (x) => x.status === "SUCCESS" || x.status === "ERROR"
            ).length *
              100) /
              this.files.length
          );
        }
        Event.$emit("imageProgressBar", vm.files[index]);
        setTimeout(() => {
          if (
            vm.files.filter(
              (x) => x.status === "SUCCESS" || x.status === "ERROR"
            ).length === vm.files.length
          ) {
            setTimeout(() => {
              vm.$store.dispatch("showWidgetUploadProgressBar", false);
            }, 1000);
          }
        }, 300);
      }
    },

    async getMetadata(item) {
      var vm = this;
      let index = this.files.findIndex((x) => x.id === item.id);
      try {
        if (index >= 0) this.files[index].read_metadata = "PROCESSING";
        let response = await fileFunc.getMetadata(
          this.$store.getters.getUserName + "/" + item.id,
          this.$store.getters.getAccessToken,
          this.categoryId,
          this.dataModel
        );
        if (response && response.status === 200) {
          if (response.data && response.data.success) {
            if (index >= 0) {
              this.files[index].data_file =
                response.data && response.data.data ? response.data.data : null;
              this.files[index].loading_data = false;
              this.files[index].read_metadata = "SUCCESS";

              this.files[index].data_file.icon_files_4_select = this.files[
                index
              ].entity.icon_files_4_select;
              this.files[index].data_file.icon_file = this.files[
                index
              ].entity.icon_file;
              this.files[index].data_file.icon_file_id = this.files[
                index
              ].entity.icon_file_id;

              this.files[index].data_file.preview_files_4_select = this.files[
                index
              ].entity.preview_files_4_select;
              this.files[index].data_file.preview_file = this.files[
                index
              ].entity.preview_file;
              this.files[index].data_file.preview_file_id = this.files[
                index
              ].entity.preview_file_id;

              this.files[index].data_file.metadata_files_4_select = this.files[
                index
              ].entity.metadata_files_4_select;
              this.files[index].data_file.metadata_file = this.files[
                index
              ].entity.metadata_file;
              this.files[index].data_file.metadata_file_id = this.files[
                index
              ].entity.metadata_file_id;
            }
            if (this.projectSelected && this.projectSelected.length > 0) {
              for (let i = 0; i < this.projectSelected.length; i++) {
                this.addImage2Project({
                  project_id: this.projectSelected[i],
                  image_id: response.data.data ? response.data.data.id : null,
                });
              }
            }
          } else {
            if (index >= 0) {
              this.files[index].read_metadata = "ERROR";
              this.files[index].loading_data = false;
            }
          }
        } else {
          if (index >= 0) {
            this.files[index].read_metadata = "ERROR";
            this.files[index].loading_data = false;
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getMetadata, item);
        } else {
          if (index >= 0) {
            this.files[index].read_metadata = "ERROR";
            this.files[index].loading_data = false;
          }
        }
      }
    },
    checkAddedFile() {
      if (this.temp.length === this.temp.files.length) {
        if (this.intervalFiles) clearInterval(this.intervalFiles);
        this.intervalFiles = null;
        if (!this.selectedSatellite) {
          this.message.push(this.$t("message_select_satellite_first"));
          this.temp.files = [];
          this.temp.length = 0;
          return;
        }
        if (this.buttonUpload.status === "UPLOADING") {
          this.message.push(this.$t("message_please_wait_upload_completed"));
          return;
        }
        let totalSize = this.temp.files.reduce(
          (sum, record) => sum + parseFloat(record["size"]),
          0
        );

        let uploadMaxFileSize = 3;
        try {
          uploadMaxFileSize = parseFloat(
            this.dataConfigJson && this.dataConfigJson.upload_max_filesize
              ? this.dataConfigJson.upload_max_filesize
              : uploadMaxFileSize
          );
        } catch (error) {}
        if (totalSize > uploadMaxFileSize * 1024 * 1024 * 1024) {
          this.message.push(
            this.$t("message_upload_max_file_size", null, {
              val: uploadMaxFileSize + "G",
            })
          );
          this.temp.files = [];
          this.temp.length = 0;
          return;
        }

        for (let i = 0; i < this.temp.files.length; i++) {
          if (!this.temp.files[i].temp_id) {
            this.temp.files[i].temp_id =
              new Date().getTime() -
              Math.floor(Math.random() * 999999999) +
              Math.floor(Math.random() * 888888888) +
              i;
          }
        }
        if (["SPOT6", "SPOT7"].includes(this.selectedSatellite)) {
          this.createData(this.temp.files);
        } else if (
          ["SPOT1", "SPOT2", "SPOT3", "SPOT4", "SPOT5"].includes(
            this.selectedSatellite
          )
        ) {
          this.createDataOldSPOT(this.temp.files);
        } else {
          this.createData(this.temp.files);
        }
      }
    },
    createDataOldSPOT(items, strFullPath = "fullPath") {
      let arrayXmlFiles = items.filter(
        (x) => x.type && (x.type + "").toLowerCase() === "text/xml"
      );
      for (let i = 0; i < arrayXmlFiles.length; i++) {
        let previewFiles = items.filter(
          (x) =>
            x.type &&
            [
              "image/jpeg",
              "image/png",
              "image/tiff",
              "image/pipeg",
              "image/x-png",
              "image/gif",
              "image/jpg",
            ].includes((x.type + "").toLowerCase()) &&
            x.name.substring(
              0,
              x.name.length -
                (getFileExtension3(x.name).toLowerCase().length + 1) // +1 = .
            ) ===
              arrayXmlFiles[i].name.substring(
                0,
                arrayXmlFiles[i].name.length - 4
              )
        );
        if (previewFiles && previewFiles.length > 0) {
          let fileArray = [...[arrayXmlFiles[i]], ...previewFiles];
          let count = 1;
          let parentId = CryptoJS.MD5(
            count +
              Math.floor(Math.random() * 9999) +
              new Date().getTime() +
              "-" +
              arrayXmlFiles[i].name.substring(
                0,
                arrayXmlFiles[i].name.length - 4
              )
          ).toString();
          let pathFiles = [
            {
              id: parentId,
              key: parentId,
              label: arrayXmlFiles[i].name.substring(
                0,
                arrayXmlFiles[i].name.length - 4
              ),
              full_path: arrayXmlFiles[i].name,
              array_path: [arrayXmlFiles[i].name],
              parent_id: -1,
              is_folder: true,
            },
          ];
          count = count++;
          let tempId = CryptoJS.MD5(
            count +
              Math.floor(Math.random() * 9999) +
              new Date().getTime() +
              "-" +
              arrayXmlFiles[i].name.substring(
                0,
                arrayXmlFiles[i].name.length - 4
              )
          ).toString();
          pathFiles.push({
            id: tempId,
            key: tempId,
            label: arrayXmlFiles[i].name,
            full_path: arrayXmlFiles[i].strFullPath,
            array_path: [],
            parent_id: parentId,
            is_folder: false,
            size: arrayXmlFiles[i].size,
            icon: null,
            uploaded_size: 0,
          });
          for (let j = 0; j < previewFiles.length; j++) {
            count = count++;
            tempId = CryptoJS.MD5(
              count +
                Math.floor(Math.random() * 9999) +
                new Date().getTime() +
                "-" +
                previewFiles[j].name.substring(
                  0,

                  previewFiles[j].name.length -
                    (getFileExtension3(previewFiles[j].name).toLowerCase()
                      .length +
                      1)
                )
            ).toString();
            pathFiles.push({
              id: tempId,
              key: tempId,
              label: previewFiles[j].name,
              full_path: previewFiles[j].strFullPath,
              array_path: [],
              parent_id: parentId,
              is_folder: false,
              size: previewFiles[j].size,
              icon: null,
              uploaded_size: 0,
            });
          }
          this.files.push({
            name: arrayXmlFiles[i].name.substring(
              0,
              arrayXmlFiles[i].name.length - 4
            ),
            files: fileArray,
            data_tree: this.listToTree(pathFiles),
            is_folder: true,
            uploaded_size: 0,
            size: fileArray.reduce(
              (sum, record) => sum + parseFloat(record["size"]),
              0
            ),

            other_name: null,
            type: "FOLDER",
            status: "NEW",
            value: 0,
            id: CryptoJS.MD5(
              pathFiles[0].label +
                Math.floor(Math.random() * (1000 + new Date().getTime()))
            ).toString(),
            key: CryptoJS.MD5(
              pathFiles[0].label +
                Math.floor(Math.random() * (1000 + new Date().getTime()))
            ).toString(),
            data_file: null,
            loading_data: true,
            entity: null,
            read_metadata: "PENDING",
            entity: null,
            can_detect: false,
            detect_by: null,
          });
          this.getMetadataFromXml(
            this.files[this.files.length - 1],
            true,
            "client"
          );
        }
      }
      this.temp.files = [];
      this.temp.length = 0;
    },
    createData(items) {
      let listFolder = items.filter((x) => x.fullPath);
      let arrayRootFolder = [
        ...new Set(
          listFolder.map(function(x) {
            return x.fullPath.split("/")[0];
          })
        ),
      ];
      for (let k = 0; k < arrayRootFolder.length; k++) {
        let sliceFiles = listFolder.filter(function(x) {
          return x.fullPath.split("/")[0] === arrayRootFolder[k];
        });

        if (sliceFiles && sliceFiles.length > 0) {
          let pathFolders = [],
            count = 0,
            pathFiles = []; // lưu đường dẫn các folder
          let fileArray = [];
          for (let i = 0; i < sliceFiles.length; i++) {
            count = count + 1;
            // Là Folder
            let arrayPath = sliceFiles[i].fullPath.split("/");
            let index = arrayPath.findIndex((x) => x === sliceFiles[i].name);
            if (index >= 0 && index === arrayPath.length - 1) {
              arrayPath.splice(index, 1);
            }

            // check folder trong folder ma k co file cùng cấp
            for (let j = 0; j < arrayPath.length; j++) {
              let check = false,
                arrayPathCheck = [];
              if (j === 0) {
                arrayPathCheck = [arrayPath[0]];
                check =
                  pathFolders.findIndex(
                    (x) => x.array_path.join("-") === arrayPath[0]
                  ) < 0;
              } else if (j === 1) {
                arrayPathCheck = [arrayPath[0], arrayPath[1]];
                check =
                  pathFolders.findIndex(
                    (x) =>
                      x.array_path.join("-") ===
                      [arrayPath[0], arrayPath[1]].join("-")
                  ) < 0;
              } else {
                arrayPathCheck = arrayPath.slice(0, j + 1);
                check =
                  pathFolders.findIndex(
                    (x) => x.array_path.join("-") === arrayPathCheck.join("-")
                  ) < 0;
              }
              if (check) {
                // kiểm tra nếu chưa có đường dẫn đó thì thêm mới
                // List folder
                pathFolders.push({
                  id: CryptoJS.MD5(arrayPathCheck.join("-")).toString(),
                  key: CryptoJS.MD5(arrayPathCheck.join("-")).toString(),
                  label: arrayPath[j],
                  array_path: arrayPathCheck,
                });
                let parentIdOne = -1;
                if (i > 0) {
                  parentIdOne = pathFiles.findIndex(function(x) {
                    return (
                      x.array_path.join("-") ===
                      arrayPathCheck
                        .slice(0, arrayPathCheck.length - 1)
                        .join("-")
                    );
                  });
                }
                pathFiles.push({
                  id: CryptoJS.MD5(
                    arrayPathCheck.join("-") + sliceFiles[i].name
                  ).toString(),
                  key: CryptoJS.MD5(
                    arrayPathCheck.join("-") + sliceFiles[i].name
                  ).toString(),
                  label: arrayPathCheck[arrayPathCheck.length - 1],
                  array_path: arrayPathCheck,
                  parent_id: parentIdOne >= 0 ? pathFiles[parentIdOne].id : -1,
                  is_folder: true,
                });
              }
            }
            let parentIdTwo = -1;
            parentIdTwo = pathFiles.findIndex(function(x) {
              return x.array_path.join("-") === arrayPath.join("-");
            });

            let tempId = CryptoJS.MD5(
              count +
                Math.floor(Math.random() * 1000) +
                "-" +
                sliceFiles[i].name
            ).toString();
            sliceFiles[i].temp_id = tempId;
            sliceFiles[i].uploaded_size = 0;
            fileArray.push(sliceFiles[i]);
            pathFiles.push({
              id: tempId,
              key: tempId,
              label: sliceFiles[i].name,
              full_path: sliceFiles[i].fullPath,
              array_path: [],
              parent_id: parentIdTwo >= 0 ? pathFiles[parentIdTwo].id : -1,
              is_folder: false,
              size: sliceFiles[i].size,
              icon: null,
              uploaded_size: 0,
            });
          }
          if (pathFiles && pathFiles.length > 0) {
            this.files.push({
              name: pathFiles[0].label,
              files: fileArray,
              data_tree: this.listToTree(pathFiles),
              is_folder: true,
              uploaded_size: 0,
              size: fileArray.reduce(
                (sum, record) => sum + parseFloat(record["size"]),
                0
              ),

              other_name: null,
              type: "FOLDER",
              status: "NEW",
              value: 0,
              id: CryptoJS.MD5(
                pathFiles[0].label +
                  Math.floor(Math.random() * (1000 + new Date().getTime()))
              ).toString(),
              key: CryptoJS.MD5(
                pathFiles[0].label +
                  Math.floor(Math.random() * (1000 + new Date().getTime()))
              ).toString(),
              data_file: null,
              loading_data: true,
              entity: null,
              read_metadata: "PENDING",
              entity: null,
              can_detect: false,
            });
            this.getMetadataFromXml(this.files[this.files.length - 1]);
          }
        }
      }
      this.temp.files = [];
      this.temp.length = 0;
      if (this.uploading) {
        this.addUpload(this.files.filter((x) => x.status === "NEW"));
      }
    },
    searchTree(element, matchingId) {
      if (element.id == matchingId) {
        return element;
      } else if (element.children != null) {
        var i;
        var result = null;
        for (i = 0; result == null && i < element.children.length; i++) {
          result = this.searchTree(element.children[i], matchingId);
        }
        return result;
      }
      return null;
    },
    typeFromExtension(extension) {
      let result = "FILE";
      switch (extension) {
        case "txt":
          result = "TXT";
          break;
        case "html":
          result = "HTML";
          break;
        case "zip":
          result = "ZIP";
          break;
        case "rar":
          result = "RAR";
          break;
        case "png":
          result = "PNG";
          break;
        case "jpeg":
          result = "JPEG";
          break;
        case "jpg":
          result = "JPG";
          break;
        case "tif":
          result = "TIFF";
          break;
        case "svg":
        case "gif":
          result = "PICTURE";
          break;
        case "kmz":
          result = "KMZ";
          break;
        case "doc":
        case "docx":
          result = "DOCX";
          break;
        case "xls":
        case "xlsx":
          result = "EXCEL";
          break;
        case "ppt":
          result = "PPT";
          break;
        case "exe":
          result = "EXE";
          break;
        case "iso":
          result = "ISO";
          break;
        default:
          result = "FILE";
          break;
      }
      return result;
    },
    listToTree(list) {
      var map = {},
        node,
        roots = [],
        i;
      let items = [];
      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
        let obj = {
          parent_id: list[i].parent_id ? list[i].parent_id : -1,
          label:
            list[i].label +
            (!list[i].is_folder
              ? " ( " +
                this.$options.filters.formatSizeFile(list[i].size) +
                " )"
              : ""),
          text: list[i].label,
          name: list[i].label,
          id: list[i].id,
          key: list[i].key,
          is_folder: list[i].is_folder,
          children: [],
          size: list[i].size,
          icon: list[i].icon,
        };
        items.push(obj);
      }
      for (i = 0; i < items.length; i += 1) {
        try {
          node = items[i];
          if (node.parent_id != null && node.parent_id + "" !== "-1") {
            if (items[map[node.parent_id]]) {
              if (!items[map[node.parent_id]].children) {
                items[map[node.parent_id]].children = [];
              }
              items[map[node.parent_id]].children.push(node);
            } else roots.push(node);
          } else {
            roots.push(node);
          }
        } catch (error) {}
      }
      return roots;
    },

    listProjectToTree(list) {
      var map = {},
        node,
        roots = [],
        i;
      let items = [];
      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
        let obj = {
          parent_id: list[i].parent_id ? list[i].parent_id : -1,
          label:
            list[i].label +
            (!list[i].is_folder
              ? " ( " +
                this.$options.filters.formatSizeFile(list[i].size) +
                " )"
              : ""),
          text: list[i].label,
          name: list[i].label,
          id: list[i].id,
          key: list[i].key,
          is_folder: list[i].is_folder,
          children: [],
          size: list[i].size,
          icon: list[i].icon,
        };
        items.push(obj);
      }
      for (i = 0; i < items.length; i += 1) {
        try {
          node = items[i];
          if (node.parent_id != null && node.parent_id + "" !== "-1") {
            if (items[map[node.parent_id]]) {
              if (!items[map[node.parent_id]].children) {
                items[map[node.parent_id]].children = [];
              }
              items[map[node.parent_id]].children.push(node);
            } else roots.push(node);
          } else {
            roots.push(node);
          }
        } catch (error) {}
      }
      return roots;
    },

    async getMetadataFromXml(dataFile, canDetect = true, detectBy = "client") {
      if (dataFile && Object.keys(dataFile).length > 0) {
        if (dataFile.files && dataFile.files.length > 0) {
          let arrayMetadataAccept = [];
          if (
            this.dataConfigJson &&
            Object.keys(this.dataConfigJson).length > 0
          ) {
            if (
              this.dataConfigJson.satellites &&
              Object.keys(this.dataConfigJson.satellites).length > 0
            ) {
              if (
                this.dataConfigJson.satellites[this.selectedSatellite] &&
                this.dataConfigJson.satellites[this.selectedSatellite]
                  .metadata_accept
              )
                arrayMetadataAccept = this.dataConfigJson.satellites[
                  this.selectedSatellite
                ].metadata_accept;
            }
          }
          let arrayXmlFiles = dataFile.files.filter(
            (x) => x.type && (x.type + "").toLowerCase() === "text/xml"
          );
          if (!dataFile.entity) dataFile.entity = this.generateEntity();
          dataFile.entity.file_id = dataFile.id;
          if (
            arrayMetadataAccept &&
            arrayMetadataAccept.length > 0 &&
            dataFile.files.filter(
              (x) =>
                arrayMetadataAccept.includes(
                  getFileExtension3(x.name).toLowerCase()
                ) && !arrayXmlFiles.map((x) => x.name).includes(x.name)
            ).length > 0
          ) {
            arrayXmlFiles = [
              ...new Set([
                ...arrayXmlFiles,
                ...dataFile.files.filter(
                  (x) =>
                    arrayMetadataAccept.includes(
                      getFileExtension3(x.name).toLowerCase()
                    ) && !arrayXmlFiles.map((x) => x.name).includes(x.name)
                ),
              ]),
            ];
          }
          dataFile.entity.preview_files_4_select = dataFile.entity.icon_files_4_select = dataFile.files.filter(
            (x) =>
              x.type &&
              [
                "image/jpeg",
                "image/png",
                "image/tiff",
                "image/pipeg",
                "image/x-png",
                "image/gif",
                "image/jpg",
              ].includes((x.type + "").toLowerCase())
          );

          let iconPrefix, previewPrefix, googleEarthPrefix, metadataPrefix;
          if (
            this.dataConfigJson &&
            Object.keys(this.dataConfigJson).length > 0
          ) {
            if (
              this.dataConfigJson.satellites &&
              Object.keys(this.dataConfigJson.satellites).length > 0
            ) {
              let satelliteName = null;
              if (dataFile.entity && Object.keys(dataFile.entity).length > 0) {
                satelliteName = dataFile.entity.satellite_name
                  ? dataFile.entity.satellite_name
                  : this.selectedSatellite;
                if (satelliteName) {
                  if (
                    this.dataConfigJson.satellites[satelliteName] &&
                    Object.keys(this.dataConfigJson.satellites[satelliteName])
                      .length > 0
                  ) {
                    iconPrefix = this.dataConfigJson.satellites[satelliteName]
                      .icon_prefix
                      ? this.dataConfigJson.satellites[satelliteName]
                          .icon_prefix
                      : null;
                    previewPrefix = this.dataConfigJson.satellites[
                      satelliteName
                    ].preview_prefix
                      ? this.dataConfigJson.satellites[satelliteName]
                          .preview_prefix
                      : null;
                    googleEarthPrefix = this.dataConfigJson.satellites[
                      satelliteName
                    ].google_earth_prefix
                      ? this.dataConfigJson.satellites[satelliteName]
                          .google_earth_prefix
                      : null;
                    metadataPrefix = this.dataConfigJson.satellites[
                      satelliteName
                    ].metadata_prefix
                      ? this.dataConfigJson.satellites[satelliteName]
                          .metadata_prefix
                      : null;
                  }
                }
              }
            }
          }
          if (metadataPrefix) {
            let array = arrayXmlFiles.filter((x) =>
              x.name.startsWith(metadataPrefix)
            );
            if (array && array.length > 0) arrayXmlFiles = array;
          }
          dataFile.entity.metadata_files_4_select = arrayXmlFiles;
          for (let i = 0; i < arrayXmlFiles.length; i++) {
            try {
              let formData = new FormData();
              formData.append("file", arrayXmlFiles[i]);
              let response = await fileFunc.dataFromXml(
                formData,
                this.$store.getters.getAccessToken
              );
              if (response && response.status === 200) {
                if (response.data && response.data.success) {
                  if (
                    response.data.data.satellite_id &&
                    response.data.data.metadata &&
                    Object.keys(response.data.data.metadata).length > 0
                  ) {
                    dataFile.can_detect = canDetect;
                    dataFile.detect_by = detectBy;
                    dataFile.data_file = response.data.data;
                    await this.assignMetadataBeforUpload(dataFile);
                    dataFile.entity.metadata_file = arrayXmlFiles[i];
                    dataFile.entity.metadata_file_id = arrayXmlFiles[i].temp_id;
                    break;
                  }
                }
              }
            } catch (error) {}
          }
          let iconName =
            dataFile.data_file &&
            dataFile.data_file.metadata &&
            dataFile.data_file.metadata.icon_name
              ? dataFile.data_file.metadata.icon_name
              : dataFile.data_file &&
                dataFile.data_file.metadata_extra &&
                dataFile.data_file.metadata_extra.icon_name
              ? dataFile.data_file.metadata_extra.icon_name
              : null;

          if (iconName) {
            dataFile.entity.icon_file = dataFile.entity.icon_files_4_select
              ? dataFile.entity.icon_files_4_select.find(
                  (x) => x.name === iconName
                )
              : null;
          } else {
            if (iconPrefix) {
              dataFile.entity.icon_file = dataFile.entity.icon_files_4_select
                ? dataFile.entity.icon_files_4_select.find((x) =>
                    x.name.startsWith(iconPrefix)
                  )
                : null;
            }
          }
          if (!dataFile.entity.icon_file) {
            dataFile.entity.icon_file =
              dataFile.entity.icon_files_4_select &&
              dataFile.entity.icon_files_4_select.length > 0
                ? dataFile.entity.icon_files_4_select[0]
                : null;
          }
          let previewName =
            dataFile.data_file &&
            dataFile.data_file.metadata &&
            dataFile.data_file.metadata.preview_name
              ? dataFile.data_file.metadata.preview_name
              : dataFile.data_file &&
                dataFile.data_file.metadata_extra &&
                dataFile.data_file.metadata_extra.preview_name
              ? dataFile.data_file.metadata_extra.preview_name
              : null;
          if (previewName) {
            dataFile.entity.preview_file = dataFile.entity
              .preview_files_4_select
              ? dataFile.entity.preview_files_4_select.find(
                  (x) => x.name === previewName
                )
              : null;
          } else {
            if (previewPrefix) {
              dataFile.entity.preview_file = dataFile.entity
                .preview_files_4_select
                ? dataFile.entity.preview_files_4_select.find((x) =>
                    x.name.startsWith(previewPrefix)
                  )
                : null;
            }
          }
          if (!dataFile.entity.preview_file) {
            dataFile.entity.preview_file =
              dataFile.entity.preview_files_4_select &&
              dataFile.entity.preview_files_4_select.length > 0
                ? dataFile.entity.preview_files_4_select[0]
                : null;
          }

          dataFile.entity.icon_file_id = dataFile.entity.icon_file
            ? dataFile.entity.icon_file.temp_id
            : null;
          dataFile.entity.preview_file_id = dataFile.entity.preview_file
            ? dataFile.entity.preview_file.temp_id
            : null;
        }
      }
    },

    assignMetadataBeforUpload(dataFile) {
      let index = this.files.findIndex((x) => x.id === dataFile.id);
      if (index < 0) return;
      let data = dataFile.data_file ? dataFile.data_file : {};
      let previews = [];
      let icons = [];
      if (data && Object.keys(data).length > 0) {
        this.files[index].entity.id = data.id;
        this.files[index].entity.scene_id = data.scene_id
          ? data.scene_id
          : null;
        this.files[index].entity.preview_images = data.preview_images;
        if (data.preview_images && data.preview_images.length > 0) {
          previews = data.preview_images.filter((x) => x.type === "PREVIEW");
          icons = data.preview_images.filter((x) => x.type === "ICON");
          let googleEarths = data.preview_images.filter(
            (x) => x.type === "GOOGLE_EARTH"
          );
          if (googleEarths.length > 0)
            this.files[index].entity.google_earth_path =
              googleEarths[0].path_image;
        }
        this.files[index].entity.area =
          data.area != null && data.area != undefined ? data.area : null;
        this.files[index].entity.area_unit = data.area_unit
          ? data.area_unit.trim()
          : null;
        this.files[index].entity.band =
          data.band != null && data.band != undefined ? data.band : null;
        this.files[index].entity.cloud_coverage =
          data.cloud_coverage != null && data.cloud_coverage != undefined
            ? data.cloud_coverage
            : null;
        this.files[index].entity.cloud_coverage_unit = data.cloud_coverage_unit
          ? data.cloud_coverage_unit.trim()
          : null;

        this.files[index].entity.description = data.description
          ? data.description.trim()
          : null;
        this.files[index].entity.file_path = data.file_path
          ? data.file_path.trim()
          : null;

        this.files[index].entity.imaging_date = data.imaging_date
          ? data.imaging_date
          : null;
        if (this.files[index].entity.imaging_date) {
          try {
            this.files[index].entity.imaging_date_timestamp = new Date(
              this.files[index].entity.imaging_date
            ).getTime();
          } catch (error) {}
        }
        this.files[index].entity.incidence_angle =
          data.incidence_angle != null && data.incidence_angle != undefined
            ? data.incidence_angle
            : null;
        this.files[index].entity.metadata = data.metadata ? data.metadata : {};

        this.files[index].entity.mode = data.beam_mode
          ? data.beam_mode.trim()
          : null;
        this.files[index].entity.beam_mode = data.beam_mode
          ? data.beam_mode.trim()
          : null;
        this.files[index].entity.name = data.name ? data.name.trim() : null;
        this.files[index].entity.pol_layer = data.pol_layer
          ? data.pol_layer.trim()
          : null;
        this.files[index].entity.price =
          data.price != null && data.price != undefined ? data.price : null;
        this.files[index].entity.price_unit = data.price_unit
          ? data.price_unit.trim()
          : null;

        this.files[index].entity.processing_level_id = data.processing_level_id
          ? data.processing_level_id.trim()
          : null;
        if (this.files[index].entity.processing_level_id) {
          this.files[
            index
          ].entity.processing_level = this.processingLevel.items.find(
            (x) => x.id === this.files[index].entity.processing_level_id
          );
          if (
            this.files[index].entity.processing_level &&
            Object.keys(this.files[index].entity.processing_level).length > 0
          ) {
            this.files[index].entity.processing_level_name = this.files[
              index
            ].entity.processing_level.value;
          }
        }

        this.files[index].entity.provider_id = data.provider_id
          ? data.provider_id.trim()
          : null;
        this.files[index].entity.satellite_id = data.satellite_id
          ? data.satellite_id.trim()
          : null;

        this.files[index].entity.resolution =
          data.resolution != undefined && data.resolution != null
            ? data.resolution
            : null;
        this.files[index].entity.satellite_type = data.satellite_type
          ? data.satellite_type
          : null;

        if (
          this.files[index].entity.satellite_type &&
          Object.keys(this.files[index].entity.satellite_type).length > 0
        ) {
          this.files[index].entity.satellite_id = this.files[
            index
          ].entity.satellite_type.id;
          this.files[index].entity.satellite_name = this.files[
            index
          ].entity.satellite_type.name;
        }
        if (!this.files[index].entity.satellite_name) {
          if (this.files[index].entity.provider_id) {
            let providerItem =
              this.provider && this.provider.items
                ? this.provider.items.find(
                    (x) => x.id === this.files[index].entity.provider_id
                  )
                : null;
            if (providerItem && providerItem.sub_meta_key) {
              let satelliteItem = providerItem.sub_meta_key.find(
                (x) => x.id === this.files[index].entity.satellite_id
              );
              if (satelliteItem)
                this.files[index].entity.satellite_name = satelliteItem.name
                  ? satelliteItem.name
                  : null;
            }
          }
        }

        this.files[index].entity.size =
          data.size != undefined && data.size != null ? data.size : null;

        this.files[index].entity.snow_coverage =
          data.snow_coverage != undefined && data.snow_coverage != null
            ? data.snow_coverage
            : null;
        this.files[index].entity.snow_coverage_unit = data.snow_coverage_unit
          ? data.snow_coverage_unit.trim()
          : null;

        if (previews && previews.length > 0) {
          this.files[index].entity.preview_path = previews.find(
            (x) => x.path_image === data.preview_path
          );
        }
        if (
          !this.files[index].entity.preview_path ||
          Object.keys(this.files[index].entity.preview_path).length === 0
        ) {
          this.files[index].entity.preview_path = {
            ext: null,
            path_image: data.preview_path ? data.preview_path : null,
            size: null,
            type: null,
          };
        }

        if (icons && icons.length > 0) {
          this.files[index].entity.icon_path = icons.find(
            (x) => x.path_image === data.icon_path
          );
        }
        if (
          !this.files[index].entity.icon_path ||
          Object.keys(this.files[index].entity.icon_path).length === 0
        ) {
          this.files[index].entity.icon_path = {
            ext: null,
            path_image: data.icon_path ? data.icon_path : null,
            size: null,
            type: null,
          };
        }
        this.files[index].entity.icon_path_id = data.icon_path;
        this.files[index].entity.preview_path_id = data.preview_path;

        if (data.image_type)
          this.files[index].entity.image_type =
            data.image_type && data.image_type.value
              ? data.image_type.value
              : null;
        if (data.metadata && Object.keys(data.metadata).length > 0) {
          this.files[index].entity.source_id = data.metadata.source_id
            ? data.metadata.source_id.trim()
            : data.metadata.product_id;
          if (
            this.files[index].entity.area === null ||
            this.files[index].entity.area === undefined
          ) {
            this.files[index].entity.area = data.metadata.area;
          }
          if (
            this.files[index].entity.area_unit === null ||
            this.files[index].entity.area_unit === undefined
          ) {
            this.files[index].entity.area_unit = data.metadata.area_unit;
          }
          if (
            this.files[index].entity.band === null ||
            this.files[index].entity.band === undefined
          ) {
            this.files[index].entity.band = data.metadata.band;
          }

          if (
            this.files[index].entity.cloud_coverage === null ||
            this.files[index].entity.cloud_coverage === undefined
          ) {
            this.files[index].entity.cloud_coverage =
              data.metadata.cloud_coverage;
          }
          if (
            this.files[index].entity.cloud_coverage_unit === null ||
            this.files[index].entity.cloud_coverage_unit === undefined
          ) {
            this.files[index].entity.cloud_coverage_unit =
              data.metadata.cloud_coverage_unit;
          }

          if (
            this.files[index].entity.snow_coverage === null ||
            this.files[index].entity.snow_coverage === undefined
          ) {
            this.files[index].entity.snow_coverage =
              data.metadata.snow_coverage;
          }
          if (
            this.files[index].entity.snow_coverage_unit === null ||
            this.files[index].entity.snow_coverage_unit === undefined
          ) {
            this.files[index].entity.snow_coverage_unit =
              data.metadata.snow_coverage_unit;
          }

          if (
            this.files[index].entity.imaging_date === null ||
            this.files[index].entity.imaging_date === undefined
          ) {
            this.files[index].entity.imaging_date = data.metadata.imaging_date;
            if (this.files[index].entity.imaging_date) {
              try {
                this.files[index].entity.imaging_date_timestamp = new Date(
                  this.files[index].entity.imaging_date
                ).getTime();
              } catch (error) {}
            }
          }

          if (
            this.files[index].entity.mode === null ||
            this.files[index].entity.mode === undefined
          ) {
            this.files[index].entity.mode = data.metadata.beam_mode;
            this.files[index].entity.beam_mode = data.metadata.beam_mode;
          }

          if (
            this.files[index].entity.incidence_angle === null ||
            this.files[index].entity.incidence_angle === undefined
          ) {
            this.files[index].entity.incidence_angle =
              data.metadata.incidence_angle;
          }
          if (
            this.files[index].entity.pol_layer === null ||
            this.files[index].entity.pol_layer === undefined
          ) {
            this.files[index].entity.pol_layer = data.metadata.pol_layer;
          }
          if (
            this.files[index].entity.direction === null ||
            this.files[index].entity.direction === undefined
          ) {
            this.files[index].entity.direction = data.metadata.direction;
          }

          if (
            this.files[index].entity.resolution === null ||
            this.files[index].entity.resolution === undefined
          ) {
            this.files[index].entity.resolution = data.metadata.resolution;
          }

          if (data.metadata.bounding_box) {
            let coors = data.metadata.bounding_box;
            if (!this.files[index].entity.bounding_box)
              this.files[index].entity.bounding_box = [];
            for (let i = 0; i < coors.length; i++) {
              this.files[index].entity.bounding_box.push([
                coors[i].longitude,
                coors[i].latitude,
              ]);
            }
            if (coors.length === 4) {
              this.files[index].entity.bounding_box.push([
                coors[0].longitude,
                coors[0].latitude,
              ]);
            }
          }
        }
        this.files[index].entity.cloud_coverage =
          this.files[index].entity.cloud_coverage != null &&
          this.files[index].entity.cloud_coverage != undefined &&
          (this.files[index].entity.cloud_coverage + "").trim() != "-1"
            ? this.files[index].entity.cloud_coverage
            : null;
        this.files[index].entity.snow_coverage =
          this.files[index].entity.snow_coverage != null &&
          this.files[index].entity.snow_coverage != undefined &&
          (this.files[index].entity.snow_coverage + "").trim() != "-1"
            ? this.files[index].entity.snow_coverage
            : null;
      }
    },

    async onUploadFile(entity) {
      entity.preview = {
        preview_images: [],
      };
      let previewPath, iconPath, previewUrl, iconUrl;
      let mimeTypePreview;
      let mimeTypeIcon;
      if (entity.preview_file) {
        mimeTypePreview = getMimeType(
          entity.preview_file.name
            .split(".")
            .pop()
            .toLowerCase()
        );
        previewPath =
          "previews/" +
          CryptoJS.MD5(
            entity.preview_file.name +
              Math.floor(Math.random() * 9999) +
              new Date().getTime()
          ).toString();
        try {
          let responsePreview = await imageFunc.createLinkPreview(
            mimeTypePreview && mimeTypePreview.type
              ? mimeTypePreview.type
              : entity.preview_file.type
              ? entity.preview_file.type
              : "image/jpg",
            previewPath,
            this.$store.getters.getAccessToken
          );
          if (responsePreview && responsePreview.status === 200) {
            previewUrl = responsePreview.data ? responsePreview.data.url : null;
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            this.refreshToken(this.onUpload);
            return;
          }
        }
      }
      if (entity.icon_file) {
        mimeTypeIcon = getMimeType(
          entity.icon_file.name
            .split(".")
            .pop()
            .toLowerCase()
        );
        iconPath =
          "previews/" +
          CryptoJS.MD5(
            entity.icon_file.name +
              Math.floor(Math.random() * 9999) +
              new Date().getTime()
          ).toString();
        try {
          let responseIcon = await imageFunc.createLinkPreview(
            mimeTypeIcon && mimeTypeIcon.type
              ? mimeTypeIcon.type
              : entity.icon_file.type
              ? entity.icon_file.type
              : "image/jpg",
            iconPath,
            this.$store.getters.getAccessToken
          );
          if (responseIcon && responseIcon.status === 200) {
            iconUrl = responseIcon.data ? responseIcon.data.url : null;
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            this.refreshToken(this.onUpload);
            return;
          }
        }
      }
      if (previewUrl) {
        try {
          let response = await imageFunc.uploadFilePresignedUrl(
            previewUrl,
            entity.preview_file,
            mimeTypePreview && mimeTypePreview.type
              ? mimeTypePreview.type
              : entity.preview_file.type
              ? entity.preview_file.type
              : "image/jpg"
          );
          if (response && response.status === 200) {
            entity.icon_path = previewPath;
            entity.preview.preview_path = previewPath;
            entity.preview.preview_images.push({
              type: "PREVIEW",
              path: previewPath,
              size: entity.preview_file.size,
              ext: entity.preview_file.name
                .split(".")
                .pop()
                .toLowerCase(),
            });
          }
        } catch (error) {}
      }
      if (iconUrl) {
        try {
          let response = await imageFunc.uploadFilePresignedUrl(
            iconUrl,
            entity.icon_file,
            mimeTypeIcon && mimeTypeIcon.type
              ? mimeTypeIcon.type
              : entity.icon_file.type
              ? entity.icon_file.type
              : "image/jpg"
          );
          if (response && response.status === 200) {
            entity.icon_path = iconPath;
            entity.preview.icon_path = iconPath;
            entity.preview.preview_images.push({
              type: "ICON",
              path: iconPath,
              size: entity.icon_file.size,
              ext: entity.icon_file.name
                .split(".")
                .pop()
                .toLowerCase(),
            });
          }
        } catch (error) {}
      }
      this.addImage2Server(entity);
    },
    /*---------------- Post, Put, Delete ----------------*/
    async addImage2Project(data) {
      try {
        let response = await projectFunc.addImage(
          data.project_id,
          data.image_id,
          this.$store.getters.getAccessToken
        );
      } catch (error) {
        if (error.response && error.response.status === 401)
          this.refreshToken(this.addImage2Project, data);
      }
    },
    /*---------------- End Post, Put, Delete ----------------*/
    /* --------------- Get data from --------------- */
    async getAllProject() {
      this.project.items = [];
      try {
        let response = await projectFunc.getAll(
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.project.items =
            response.data && response.data.content_page
              ? response.data.content_page.filter((x) => !x.is_delete)
              : [];
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllProject);
        }
      }
    },

    async getAllBeamMode() {
      try {
        let response = await metaKeyFunc.getByKey(
          "BEAM_MODE",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success) {
            this.beamMode.items =
              response && response.data && response.data.data
                ? response.data.data
                : [];
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllBeamMode);
        }
      }
    },
    async getAllDirection() {
      try {
        let response = await metaKeyFunc.getByKey(
          "DIRECTION",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success) {
            this.direction.items =
              response && response.data && response.data.data
                ? response.data.data
                : [];
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllDirection);
        }
      }
    },
    async getAllPolLayer() {
      try {
        let response = await metaKeyFunc.getByKey(
          "POL_LAYER",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success) {
            this.polLayer.items =
              response && response.data && response.data.data
                ? response.data.data
                : [];
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllPolLayer);
        }
      }
    },
    async getAllCategoryDataList() {
      try {
        let response = await metaKeyFunc.getByKey(
          "CATEGORY",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success) {
            this.categoryDataList.items =
              response && response.data && response.data.data
                ? response.data.data
                : [];
            // if (
            //   this.categoryDataList.items &&
            //   this.categoryDataList.items.length > 0
            // )
            //this.categoryId = this.categoryDataList.items[0].id;
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllCategoryDataList);
        }
      }
    },
    async getAllProcessingLevel() {
      try {
        let response = await metaKeyFunc.getByKey(
          "PROCESSING_LEVEL",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success) {
            this.processingLevel.items =
              response && response.data && response.data.data
                ? response.data.data
                : [];
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllProcessingLevel);
        }
      }
    },
    async getAllProvider() {
      try {
        let response = await metaKeyFunc.getByKey(
          "PROVIDER",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.provider.items =
            response && response.data && response.data.data
              ? response.data.data
              : [];
          if (this.provider.items && this.provider.items.length > 0) {
            for (let i = 0; i < this.provider.items.length; i++) {
              if (
                this.provider.items[i].sub_meta_key &&
                this.provider.items[i].sub_meta_key.length > 0
              ) {
                let children = [];
                for (
                  let j = 0;
                  j < this.provider.items[i].sub_meta_key.length;
                  j++
                ) {
                  children.push({
                    id: this.provider.items[i].sub_meta_key[j].id,
                    key: this.provider.items[i].sub_meta_key[j].id,
                    name: this.provider.items[i].sub_meta_key[j].name,
                    code: this.provider.items[i].sub_meta_key[j].name,
                    label: this.provider.items[i].sub_meta_key[j].value,
                  });
                }
                this.provider.tree.push({
                  id: this.provider.items[i].id,
                  key: this.provider.items[i].id,
                  name: this.provider.items[i].name,
                  code: this.provider.items[i].name,
                  label: this.provider.items[i].value,
                  children: children,
                });
              }
            }
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllProvider);
        }
      }
    },

    async getAllModel() {
      try {
        let response = await processingDataFunc.getAllModel(
          {
            name: null,
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.dataSpecification.items =
            response.data && response.data.content_page
              ? response.data.content_page
              : [];

          this.dataSpecification.items.sort(function(a, b) {
            if (a.name < b.name) return -1;
            else if (a.name > b.name) return 1;
            return 0;
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.getAllModel, null);
        }
      }
    },

    async getAllSatellite() {
      try {
        let response = await metaKeyFunc.getByKey(
          "SATELLITE",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success) {
            this.satellite.items =
              response && response.data && response.data.data
                ? response.data.data
                : [];
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllSatellite);
        }
      }
    },
    /* --------------- End --------------- */
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
  beforeDestroy() {
    if (this.intervalTime) clearInterval(this.intervalTime);
    if (this.intervalFiles) clearInterval(this.intervalFiles);
    if (this.files.filter((x) => x.status === "PROCESS").length > 0)
      this.$store.dispatch("showWidgetUploadProgressBar", true);
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_icon.scss";
.file-attachment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  .innernametext {
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    display: table-cell;
    width: auto;
    p {
      margin-bottom: 0;
    }
  }
  .fileactions {
    position: inherit;
    float: right;
    button {
      background-color: transparent;
      border: 0;
      &:active {
        color: #000000;
        outline: 0;
        border: 0;
      }
      &:hover {
        color: #000000;
        outline: 0;
        border: 0;
      }
    }
  }
}
.myDropzone {
  width: 100%;
  .p-tree {
    border: none;
    padding: 0;
    width: 100%;
    .p-tree-toggler {
      position: relative;
      top: 4px;
    }
  }
}
.p-progressbar {
  height: 2.25rem;
}
.myDropzone {
  padding: 0.5rem;
  .upload-actions {
    padding: 0;
    .add-files {
      label {
        color: #fff;
        background-color: #007bff;
        border-color: #007bff;
        padding: 0.3rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1.85;
        border-radius: 0.2rem;
        margin-bottom: 0;
        margin-right: 0.5rem;
        width: 128px;
        cursor: pointer;
        &:hover {
          color: #fff;
          background-color: #0069d9;
          border-color: #0062cc;
        }
        &:disabled {
          opacity: 0.65;
        }
      }
    }
    .btn-start-upload {
      background: #107d11;
      border-color: #107d11;
    }
  }
  .upload-files {
    padding: 0;
    margin-top: 0.5rem;
  }
}
.upload-status {
  margin-top: 0.5rem;
  .status-item {
    height: 36px;
    min-width: 144px;
    margin-right: 0.375rem;
    padding: 0.375rem;
    &:last-child {
      margin: 0;
    }
    &.complete-status {
      border: 1px solid #009ef7;
      box-sizing: border-box;
      border-radius: 4px;
      background: #f1faff;

      color: #009ef7;
    }
    &.incomplete-status {
      border: 1px solid #f68e1e;
      box-sizing: border-box;
      border-radius: 4px;
      background: #fef5ed;

      color: #f68e1e;
    }
    &.error-status {
      border: 1px solid #f1416c;
      box-sizing: border-box;
      border-radius: 4px;
      background: #fdefef;

      color: #f1416c;
    }
    &.processing-status {
      border: 1px solid #0d6efd;
      box-sizing: border-box;
      border-radius: 4px;
      background: #f1faff;

      color: #0d6efd;
    }
  }
}

.upload-files {
  .status-item {
    height: 36px;
    min-width: 112px;
    text-align: center;
    padding: 0.375rem;
    &.complete-status {
      border: 1px solid #eaf7f0;
      box-sizing: border-box;
      border-radius: 4px;
      background: #eaf7f0;

      color: #50cd89;
    }
    &.incomplete-status {
      border: 1px solid #fef5ed;
      box-sizing: border-box;
      border-radius: 4px;
      background: #fef5ed;

      color: #f68e1e;
    }
    &.error-status {
      border: 1px solid #fdefef;
      box-sizing: border-box;
      border-radius: 4px;
      background: #fdefef;

      color: #f1416c;
    }
    &.processing-status {
      border: 1px solid #f1faff;
      box-sizing: border-box;
      border-radius: 4px;
      background: #f1faff;

      color: #0d6efd;
    }
    span {
      text-align: center;
      font-size: 12px;
    }
  }
}
.button-additional-information {
  background: #f68e1e;
  color: white;
  border: 1px solid #f68e1e;
  &:hover {
    color: #f68e1e !important;
    background: #fef5ed !important;
    border: 1px solid #f68e1e !important;
  }
}
.label-support-satellite {
  line-height: 36px;
  vertical-align: middle;
  padding: 0.4rem;
}
</style>
<style lang="scss">
.myDropzone {
  .p-tree {
    .p-tree-toggler {
      position: relative;
      top: 4px;
    }
  }
}
#HJud83Hwbr {
  // @import "@/assets/scss/_datatable.scss";
  .datatable {
    button {
      width: 1.825rem !important;
      height: 1.825rem !important;
      padding: 0.25rem !important;
      &:first-child {
        margin-right: 0.25rem;
      }
    }
  }
}
</style>
<style lang="scss">
.myDropzone {
  .upload-actions {
    .vue-treeselect__control {
      border-color: #a6a6a6 !important;
    }
  }
}
</style>
