<template>
  <CommonAccordion :propTitle="$t('title_permission_info')">
    <div slot="content" class="permission-structure">
      <div class="row no-gutters w-100">
        <div class="col-12 col-md-6 p-1">
          <div class="form-group row no-gutters">
            <label class="p-col-fixed" :style="styleLabel">
              {{ $t("label_permission_code") }}&nbsp;
              <span class="font-weight-bold required">*</span>
            </label>

            <div :style="styleControl">
              <ValidationProvider
                :name="$t('label_permission_code')"
                rules="required"
              >
                <InputText
                  type="text"
                  :style="styleControl"
                  maxlength="100"
                  v-model="propEntity.name"
                  :disabled="!propStatus.isAdd && !propStatus.isEdit"
                  class="w-100 new-style"
                />
              </ValidationProvider>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters w-100">
        <div class="col-12 col-md-6 p-1">
          <fieldset class="p-0">
            <legend class="w-auto">
              {{ $t("label_language_vietnamese") }}
            </legend>
            <div class="form-group row no-gutters">
              <label class="col-form-label" :style="styleLabel"
                >{{ $t("label_name_vietnamese") }}&nbsp;<span
                  class="font-weight-bold required"
                  >*</span
                ></label
              >
              <div :style="styleControl">
                <ValidationProvider
                  :name="$t('label_name_vietnamese')"
                  rules="required"
                >
                  <InputText
                    class="w-100 new-style"
                    type="text"
                    maxlength="255"
                    v-model="propEntity.translation.vi.description"
                    :disabled="!propStatus.isAdd && !propStatus.isEdit"
                  />
                </ValidationProvider>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="col-12 col-md-6 p-1">
          <fieldset class="p-0">
            <legend class="w-auto">{{ $t("label_language_english") }}</legend>
            <div class="form-group row no-gutters">
              <label class="col-form-label" :style="styleLabel"
                >{{ $t("label_name_english") }}&nbsp;<span
                  class="font-weight-bold required"
                  >*</span
                ></label
              >
              <div :style="styleControl">
                <ValidationProvider
                  :name="$t('label_name_english')"
                  rules="required"
                >
                  <InputText
                    class="w-100 new-style"
                    type="text"
                    maxlength="255"
                    v-model="propEntity.translation.en.description"
                    :disabled="!propStatus.isAdd && !propStatus.isEdit"
                  />
                </ValidationProvider>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import MultiSelect from "@/components/commons/multiselect/MultiSelect";
import InputText from "@/components/commons/inputtext/InputText";
import Button from "@/components/commons/button/Button";
import Checkbox from "@/components/commons/checkbox/Checkbox";
import Treeselect from "@riophae/vue-treeselect";
export default {
  components: {
    CommonAccordion,
    Dropdown,
    Treeselect,
    Button,
    InputText,
    Checkbox,
    MultiSelect,
  },
  props: {
    propEntity: {
      type: Object,
      default: () => {},
    },
    propStatus: {
      type: Object,
      default: () => {
        return {
          isAdd: false,
          isEdit: false,
        };
      },
    },
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    styleLabel() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:144px";
    },
    styleLabelCheckbox() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:128px";
    },
    styleControl() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:calc(100% - 144px)";
    },
  },
};
</script>
<style lang="scss" scoped>
.permission-content__structure {
  .p-md-4 {
    padding: 0.5rem !important;
  }
  fieldset {
    border-color: transparent;
    margin: 0;
    padding: 0;
    padding: 0.5rem;
    legend {
      font-size: 14px;
      font-weight: 700;
      margin: 0;
      padding: 0;
      margin-bottom: 1rem;
    }
  }
  fieldset {
    .form-group {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>