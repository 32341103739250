var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-layout',{attrs:{"isToolbar":false,"isBreadcrumb":true,"breadcrumbitems":[
    { label: _vm.$t('menu_order_management') },
    { label: _vm.$t('menu_order_list') } ],"propButtonsState":{
    save: {
      visible: false,
    },
    add: {
      visible: false,
    },
    edit: {
      visible: false,
    },
    reload: {
      visible: true,
    },
    print: {
      visible: false,
    },
  },"propTitle":_vm.$t('menu_order_list')},on:{"emitFunction":_vm.menuHeaderClick}},[_c('div',{staticClass:"order-content"},[_c('div',{staticClass:"order__dialog"},[_c('div',{staticClass:"order__dialog--images"},[(_vm.dlgOrderDetail.show)?_c('DialogOrderDetail',{attrs:{"prop-style":{ width: _vm.dlgOrderDetail.width },"prop-show":_vm.dlgOrderDetail.show,"prop-title":_vm.dlgOrderDetail.title,"prop-items":_vm.dlgOrderDetail.value.images,"prop-order":_vm.dlgOrderDetail.value,"propImageTypes":_vm.imageTypes.items,"prop-shared":_vm.dlgOrderDetail.value.shared
              ? _vm.dlgOrderDetail.value.shared.map(function (x) { return x.image_id; })
              : [],"propOrderStatusConfirmed":_vm.dlgOrderDetail.value.current_status.name === 'ORDER_CONFIRMED',"propOrderStatusCompleted":_vm.dlgOrderDetail.value.current_status.name === 'ORDER_COMPLETED',"propOrderStatusReject":_vm.dlgOrderDetail.value.current_status.name === 'ORDER_REJECT',"prop-buttons":_vm.buttons},on:{"close":function($event){_vm.dlgOrderDetail.show = false;
            _vm.dlgOrderDetail.value = null;},"showDialogShare":_vm.showDialogShare,"showDialogMetadata":_vm.showDialogMetadata,"onApprove":_vm.onApprove,"message":function($event){_vm.message = $event}}}):_vm._e()],1),(_vm.dlgShare.show)?_c('DialogShare',{attrs:{"prop-style":{ width: _vm.dlgShare.width },"prop-show":_vm.dlgShare.show,"prop-title":_vm.dlgShare.title,"prop-items":_vm.dlgShare.value,"propOrderBy":_vm.dlgOrderDetail.value.order_by,"propButtonShare":_vm.buttons.share},on:{"close":function($event){_vm.dlgShare.show = false;
          _vm.dlgShare.value = null;},"onShare":_vm.onShare}}):_vm._e(),(_vm.dlgUpdateStatus.show)?_c('DialogUpdateStatus',{attrs:{"prop-style":{
          width: _vm.dlgUpdateStatus.width,
          overflowY: 'inherit!important',
        },"prop-show":_vm.dlgUpdateStatus.show,"prop-title":_vm.dlgUpdateStatus.title,"prop-tab-id":_vm.dlgUpdateStatus.value.current_status
            ? _vm.dlgUpdateStatus.value.current_status.id
            : null,"prop-item":_vm.dlgUpdateStatus.value,"prop-button":_vm.order.table.buttons.update_status,"propOrderStatus":_vm.orderStatus.items},on:{"close":function($event){_vm.dlgUpdateStatus.show = false;
          _vm.dlgUpdateStatus.value = null;},"onUpdateStatus":_vm.onUpdateStatus}}):_vm._e(),(_vm.dlgDialogRemark.show)?_c('DialogRemark',{attrs:{"prop-style":{
          width: _vm.dlgDialogRemark.width,
        },"prop-show":_vm.dlgDialogRemark.show,"prop-title":_vm.dlgDialogRemark.title,"prop-value":_vm.dlgDialogRemark.value},on:{"close":function($event){_vm.dlgDialogRemark.show = false;
          _vm.dlgDialogRemark.value = null;
          _vm.dlgDialogRemark.title = null;}}}):_vm._e(),(_vm.dialogMetadata.show)?_c('DialogMetadata',{attrs:{"prop-style":{ width: _vm.dialogMetadata.width },"prop-show":_vm.dialogMetadata.show,"prop-title":_vm.dialogMetadata.title,"prop-item":_vm.dialogMetadata.value},on:{"close":function($event){_vm.dialogMetadata.show = false;
          _vm.dialogMetadata.value = null;}}}):_vm._e(),(_vm.dialogUploadFile.show)?_c('DialogUploadImages',{ref:"dlgUploadImages",attrs:{"prop-style":{ width: _vm.dialogUploadFile.width },"prop-show":_vm.dialogUploadFile.show,"prop-title":_vm.dialogUploadFile.title,"prop-value":_vm.dialogUploadFile.value},on:{"close":function($event){_vm.dialogUploadFile.show = false;
          _vm.dialogUploadFile.value = null;
          _vm.files = [];
          _vm.filesUploaded.success = [];
          _vm.filesUploaded.error = [];},"uploadFiles":_vm.onUploadFiles,"message":function($event){_vm.message = $event}}}):_vm._e(),(_vm.dlgAddImages.show)?_c('DialogAddImages',{attrs:{"prop-show":_vm.dlgAddImages.show,"prop-images":_vm.dlgAddImages.value.images,"prop-order":_vm.dlgAddImages.value,"prop-style":{
          width: _vm.dlgAddImages.width,
        },"prop-categories":_vm.categoryDataList && _vm.categoryDataList.items
            ? _vm.categoryDataList.items.filter(function (x) { return _vm.code4Add.includes(x.name); })
            : []},on:{"close":function($event){_vm.dlgAddImages.show = false;
          _vm.dlgAddImages.value = null;},"selected":_vm.onUpdateImagesAdded}}):_vm._e(),(_vm.message.length > 0)?_c('DialogMessage',{attrs:{"propTitle":_vm.$t('title_message_info'),"prop-show":_vm.message.length > 0,"prop-message":_vm.message},on:{"close":function($event){_vm.message = []}}}):_vm._e()],1),_c('div',{staticClass:"order__search",attrs:{"id":"elementSearch"}},[_c('OrderSearch',{attrs:{"prop-user":_vm.user.items,"prop-search":_vm.order.search,"prop-order-status":_vm.orderStatus.items,"propButtons":_vm.buttons},on:{"onSearch":function($event){return _vm.onSearch($event, true)},"message":function($event){_vm.message = $event},"accordionHeaderClick":_vm.accordionHeaderClick}})],1),_c('div',{staticClass:"order__datatable"},[_c('OrderDataTable',{attrs:{"prop-items":_vm.order.items,"prop-total":_vm.order.total_records,"prop-loading":_vm.order.loading,"prop-table":_vm.order.table,"propButtonDetail":_vm.order.table.buttons.detail},on:{"nextPage":_vm.nextPage,"showOrderDetail":_vm.showOrderDetail,"shoDialogUpdateStatus":_vm.shoDialogUpdateStatus,"shoDialogRemark":_vm.shoDialogRemark,"shoDialogUpload":_vm.shoDialogUpload,"shoDialogAddImages":_vm.shoDialogAddImages}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }