import axios from 'axios';
import basicApi from "@/utils/apis/basic";
import app from './../../main'
import { geoServiceApiURL } from "@/utils/commons/environment"
export default {
    async getList(params, access_token) {
      let headers = basicApi.organisation.get_all.headers;
      headers["Authorization"] = "bearer " + access_token;
      headers['Accept-Language'] = app.$store.getters.getLang;
      if (!params) params = {};
        params.lang = app.$store.getters.getLang;
        const object = axios({
          method: basicApi.organisation.get_all.method,
          url: geoServiceApiURL + basicApi.organisation.get_all.url,
          headers:headers,
          params:params
        });
        let response = await object;
        return response;
    },
    async getAll( data,access_token) {
        let headers = basicApi.organisation.get_all.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
          method: basicApi.organisation.get_all.method,
          url: geoServiceApiURL + basicApi.organisation.get_all.url,
          headers:headers,
          params:{
            page:0,
            size: 2147483647,
            lang : app.$store.getters.getLang
          },
          data:data
        });
        let response = await object;
        return response;
    },
    
  async getById(id, access_token) {
    let headers = basicApi.organisation.get_by_id.headers;
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.organisation.get_by_id.method,
      url: geoServiceApiURL + basicApi.organisation.get_by_id.url.replace('{id}',id),
      headers:headers,  
      params:{
        lang : app.$store.getters.getLang
      }      
    });
    let response = await object;
    return response;
  },
  async create(entity, access_token) {
    let headers = basicApi.organisation.create.headers;
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.organisation.create.method,
      url: geoServiceApiURL + basicApi.organisation.create.url,
      headers:headers,   
      data: entity,
      params:{
        lang : app.$store.getters.getLang
      }
    });
    let response = await object;
    return response;
  },
  async update(entity, access_token) {
    let headers = basicApi.organisation.update.headers;
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.organisation.update.method,
      url: geoServiceApiURL + basicApi.organisation.update.url.replace('{id}',entity.id),
      headers:headers,     
      data:entity,
      params:{
        lang : app.$store.getters.getLang
      }           
    });
    let response = await object;
    return response;
  },
  async delete(id, access_token) {
    let headers = basicApi.organisation.delete.headers;
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.organisation.delete.method,
      url: geoServiceApiURL + basicApi.organisation.delete.url.replace('{id}',id),
      headers:headers,
      params:{
        lang : app.$store.getters.getLang
      }       
    });
    let response = await object;
    return response;
  },
}