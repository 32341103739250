<template>
  <Dialog
    id="dialogOrderDetail"
    :header="$t('header_detail')"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :modal="true"
    :contentStyle="propStyle"
    @hide="onClose"
  >
    <OrderInfo :prop-item="propOrder" />

    <BuyerInfo :prop-item="propOrder" />
    <AoiInfo :prop-item="propOrder" :prop-images="propOrder.images" />
    <div class="order-images">
      <CommonAccordion
        :isChevronEnd="true"
        :propTitle="$t('label_order_images')"
        ><div slot="content" class="accordion w-100">
          <div class="datatable">
            <ContextMenu
              :model="menuModel"
              ref="cmDlgOrderDetailDatatable"
              :appendTo="'dialogOrderDetail'"
            />
            <DataTable
              :value="imagesOne"
              ref="dt"
              :resizableColumns="true"
              columnResizeMode="expand"
              selectionMode="single"
              dataKey="id"
              contextMenu
              :selection.sync="selected"
              @row-contextmenu="onRowContextMenu"
              @row-click="rowClick"
            >
              <Column>
                <template #body="slotProps">
                  <div class="d-flex">
                    <div class="media" style="width: calc(100% - 96px)">
                      <icon-preview
                        :propId="
                          slotProps.data.image &&
                          slotProps.data.image.preview &&
                          slotProps.data.image.preview.icon_path
                            ? slotProps.data.image.preview.icon_path
                            : slotProps.data.image &&
                              slotProps.data.image.preview &&
                              slotProps.data.image.preview.preview_path
                            ? slotProps.data.image.preview.preview_path
                            : null
                        "
                        :prop-src="
                          slotProps.data.image &&
                          slotProps.data.image.preview &&
                          slotProps.data.image.preview.icon_path
                            ? slotProps.data.image.preview.icon_path
                            : slotProps.data.image &&
                              slotProps.data.image.preview &&
                              slotProps.data.image.preview.preview_path
                            ? slotProps.data.image.preview.preview_path
                            : null
                        "
                        :propPreviewImages="
                          slotProps.data.image &&
                          slotProps.data.image.preview &&
                          slotProps.data.image.preview.preview_images
                            ? slotProps.data.image.preview.preview_images
                            : []
                        "
                      ></icon-preview>
                      <div class="media-body">
                        <ul>
                          <li class="lbl-geostore">
                            <strong>{{
                              slotProps.data &&
                              slotProps.data.image &&
                              slotProps.data.image.name
                                ? slotProps.data.image.name
                                : null
                            }}</strong>
                          </li>
                          <li class="mt-1">
                            <div class="">
                              <span
                                ><strong>{{ $t("label_image_time") }}:</strong>
                                {{
                                  slotProps.data.image &&
                                  slotProps.data.image.info &&
                                  slotProps.data.image.info.imaging_date
                                    ? slotProps.data.image.info.imaging_date
                                    : null
                                }}
                              </span>
                              <span class="pl-2 pr-2">|</span>
                              <span
                                ><strong>{{ $t("label_satellite") }}: </strong>
                                {{
                                  slotProps.data.image &&
                                  slotProps.data.image.info &&
                                  slotProps.data.image.info.satellite
                                    ? slotProps.data.image.info.satellite.value
                                    : ""
                                }}
                              </span>
                            </div>
                          </li>
                          <ItemMetadata
                            :prop-item="slotProps.data.image"
                            :propImageTypes="propImageTypes"
                          />
                        </ul>
                      </div>
                    </div>
                    <div style="width: 96px">
                      <button
                        class="btn btn-primary btn-sm w-100"
                        title="Export footprint to shape file (shp files archived)"
                        @click="exportShapeFile(slotProps.data.image)"
                      >
                        {{ $t("button_text_export") }}
                      </button>
                    </div>
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </CommonAccordion>
    </div>
    <div class="delivery-images" v-if="imagesTwo.length > 0">
      <CommonAccordion
        :isChevronEnd="true"
        :propTitle="$t('label_delivery_images')"
        ><div slot="content" class="accordion w-100">
          <div class="datatable">
            <ContextMenu
              :model="menuModel"
              ref="cmDeliveryDatatable"
              :appendTo="'dialogOrderDetail'"
            />
            <DataTable
              :value="imagesTwo"
              ref="dt"
              :resizableColumns="true"
              columnResizeMode="expand"
              selectionMode="single"
              dataKey="id"
              contextMenu
              :selection.sync="selected2"
              @row-contextmenu="onRowContextMenu2"
              @row-click="rowClick2"
            >
              <Column>
                <template #body="slotProps">
                  <div class="d-flex">
                    <div v-if="propOrderStatusCompleted">
                      <div
                        class="img-shared text-center"
                        v-if="propShared.includes(slotProps.data.image.id)"
                      >
                        <i class="fas fa-check text-success"></i>
                      </div>
                      <Checkbox
                        class="pt-1 mr-2"
                        v-else
                        v-model="selectedIds"
                        :binary="false"
                        :value="slotProps.data.image.id"
                      />
                    </div>

                    <div class="media">
                      <icon-preview
                        :propId="
                          slotProps.data.image &&
                          slotProps.data.image.preview &&
                          slotProps.data.image.preview.icon_path
                            ? slotProps.data.image.preview.icon_path
                            : slotProps.data.image &&
                              slotProps.data.image.preview &&
                              slotProps.data.image.preview.preview_path
                            ? slotProps.data.image.preview.preview_path
                            : null
                        "
                        :prop-src="
                          slotProps.data.image &&
                          slotProps.data.image.preview &&
                          slotProps.data.image.preview.icon_path
                            ? slotProps.data.image.preview.icon_path
                            : slotProps.data.image &&
                              slotProps.data.image.preview &&
                              slotProps.data.image.preview.preview_path
                            ? slotProps.data.image.preview.preview_path
                            : null
                        "
                        :propPreviewImages="
                          slotProps.data.image &&
                          slotProps.data.image.preview &&
                          slotProps.data.image.preview.preview_images
                            ? slotProps.data.image.preview.preview_images
                            : []
                        "
                      ></icon-preview>
                      <div class="media-body">
                        <ul>
                          <li class="lbl-geostore">
                            <strong>{{
                              slotProps.data &&
                              slotProps.data.image &&
                              slotProps.data.image.name
                                ? slotProps.data.image.name
                                : null
                            }}</strong>
                          </li>
                          <li class="mt-1">
                            <div class="">
                              <span
                                ><strong>{{ $t("label_image_time") }}:</strong>
                                {{
                                  slotProps.data.image &&
                                  slotProps.data.image.info &&
                                  slotProps.data.image.info.imaging_date
                                    ? slotProps.data.image.info.imaging_date
                                    : null
                                }}
                              </span>
                              <span class="pl-2 pr-2">|</span>
                              <span
                                ><strong>{{ $t("label_satellite") }}: </strong>
                                {{
                                  slotProps.data.image &&
                                  slotProps.data.image.info &&
                                  slotProps.data.image.info.satellite
                                    ? slotProps.data.image.info.satellite.value
                                    : ""
                                }}
                              </span>
                            </div>
                          </li>
                          <ItemMetadata
                            :prop-item="slotProps.data.image"
                            :propImageTypes="propImageTypes"
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </CommonAccordion>
    </div>
    <template #footer>
      <button
        v-if="
          propOrderStatusCompleted &&
          $store.getters.getPermissions.includes('SHARE_IMAGE_CREATE')
        "
        :label="$t('label_share')"
        :icon="'fas fa-share'"
        class="btn btn-geostore2 btn-share btn-w112-h36 mr-0"
        @click="onShareList"
        :disabled="
          !selectedIds ||
          selectedIds.length === 0 ||
          (propButtons && propButtons.share && propButtons.approve.share)
        "
      >
        <i
          :class="
            propButtons && propButtons.share && propButtons.share.processing
              ? 'fas fa-spinner fa-spin'
              : 'fas fa-share'
          "
        ></i
        >&nbsp;
        {{ $t("label_share") }}
      </button>
      <button
        v-if="
          !propOrderStatusConfirmed &&
          !propOrderStatusCompleted &&
          !propOrderStatusReject &&
          $store.getters.getPermissions.includes('ORDER_UPDATE_STATUS')
        "
        :label="$t('label_approve')"
        :icon="'fas fa-check'"
        class="btn btn-geostore2 btn-approve btn-w120-h36 mr-0"
        @click="onApprove"
        :disabled="
          propButtons && propButtons.approve && propButtons.approve.disabled
        "
      >
        <i
          :class="
            propButtons && propButtons.approve && propButtons.approve.processing
              ? 'fas fa-spinner fa-spin'
              : 'fas fa-check'
          "
        ></i
        >&nbsp;
        {{ $t("label_approve") }}
      </button>
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
import Button from "@/components/commons/button/Button";
import IconPreview from "./dialogorderdetail/IconPreview.vue";
import ItemMetadata from "./dialogorderdetail/ItemMetadata.vue";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import ContextMenu from "@/components/commons/contextmenu/ContextMenu";
import Checkbox from "@/components/commons/checkbox/Checkbox";
import InputText from "@/components/commons/inputtext/InputText";
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import OrderInfo from "./detail/OrderInfo";
import BuyerInfo from "./detail/BuyerInfo";
import AoiInfo from "./detail/AoiInfo";
import OrderImages from "./detail/Images";
import moment from "moment";
import shpwrite from "@/utils/commons/shp-write";
export default {
  components: {
    Dialog,
    Button,
    IconPreview,
    ItemMetadata,
    DataTable,
    Column,
    ContextMenu,
    Checkbox,
    InputText,
    CommonAccordion,
    OrderInfo,
    BuyerInfo,
    AoiInfo,
    OrderImages,
  },
  props: {
    propShared: {
      type: Array,
      default: () => [],
    },
    propOrder: {
      type: Object,
      default: () => {},
    },
    propItems: {
      type: Array,
      default: () => [],
    },
    propTitle: {
      type: String,
      default: "",
    },
    propStyle: {
      type: Object,
      default: () => {
        return {
          width: "480px",
        };
      },
    },
    propShow: {
      type: Boolean,
      default: false,
    },
    propOrderStatusCompleted: {
      type: Boolean,
      default: false,
    },
    propOrderStatusConfirmed: {
      type: Boolean,
      default: false,
    },
    propOrderStatusReject: {
      type: Boolean,
      default: false,
    },
    propButtons: {
      type: Object,
      default: () => {},
    },
    propImageTypes: {
      type: Array,
    },
  },
  data() {
    return {
      selectedIds: null,
      selected: null,
      selected2: null,
      menuModel: [
        {
          label: "Metadata",
          icon: "fas fa-list-alt",
          command: () =>
            this.openDialogMetadata(this.selected ? this.selected.image : null),
          visible: true,
        },
      ],
      time: new Date().getTime(),
    };
  },
  created() {
    this.show = this.propShow;
    this.selectedIds = this.propItems
      .filter((z) => z.user_add != this.propOrder.created_by)
      .map((x) => x.image.id)
      .filter((y) => !this.propShared.includes(y));
  },
  watch: {
    propShow: {
      handler(val) {
        this.show = val;
      },
      immediate: true,
    },
  },
  methods: {
    exportShapeFile(item) {
      if (item && Object.keys(item).length > 0) {
        let geoFeatures = {
          type: "FeatureCollection",
          features: [],
        };

        if (
          item.geom &&
          item.geom.coordinates &&
          item.geom.coordinates.length > 0
        ) {
          let coordinates = [];
          for (let j = 0; j < item.geom.coordinates[0].length; j++) {
            if (j >= 4) break;
            coordinates.push(item.geom.coordinates[0][j]);
          }
          if (coordinates && coordinates.length > 0) {
            coordinates.push(coordinates[0]);
          }
          let properties = {
            name: item.name ? item.name : "",
            scene_id: item.sceneId
              ? item.sceneId
              : item.scene_id
              ? item.scene_id
              : "",
          };
          let keysIgnore = ["boundary"];
          if (item.info) {
            if (
              item.info.satellite &&
              Object.keys(item.info.satellite).length > 0
            ) {
              properties.satellite = item.info.satellite.value
                ? item.info.satellite.value
                : null;
              keysIgnore.push("satellite");
            }
            if (item.info.imaging_date) {
              properties.imaging_date = item.info.imaging_date;
              keysIgnore.push("imaging_date");
            }
            if (item.info.incidence_angle) {
              properties.incidence_angle = item.info.incidence_angle;
              keysIgnore.push("incidence_angle");
            }
            if (item.info.resolution) {
              properties.resolution = item.info.resolution;
              keysIgnore.push("resolution");
            }
            if (
              item.info.processing_level &&
              Object.keys(item.info.processing_level).length > 0
            ) {
              properties.processing_level = item.info.processing_level.value
                ? item.info.processing_level.value
                : null;
              keysIgnore.push("processing_level");
            }
          }
          if (item.metadata) {
            for (let k = 0; k < item.metadata.length; k++) {
              if (
                item.metadata[k].key &&
                !keysIgnore.includes(item.metadata[k].key)
              ) {
                if (item.metadata[k].key in properties) {
                } else {
                  properties[item.metadata[k].key] = item.metadata[k].value
                    ? item.metadata[k].value
                    : null;
                }
              }
            }
          }

          let feature = {
            type: "Feature",
            properties: properties,
            geometry: {
              type: "Polygon",
              coordinates: coordinates,
            },
          };
          geoFeatures.features.push(feature);
        }

        if (geoFeatures.features) {
          var options = {
            folder: "shapes",
            types: {
              point: "POINTS",
              polygon: "POLYGONS",
              line: "LINES",
            },
          };
          shpwrite.download(geoFeatures, options);
        }
      }
    },
    onClose() {
      this.show = false;
      this.$emit("close");
    },
    openDialogMetadata(item) {
      this.$emit("showDialogMetadata", item);
    },
    onShare(item) {
      this.$emit("showDialogShare", [item]);
    },
    onShareList() {
      if (this.propShared.length === this.propItems.length) {
        this.$emit("message", [this.$t("message_images_has_been_shared")]);
        return;
      }

      this.$emit(
        "showDialogShare",
        this.propItems.filter((x) => this.selectedIds.includes(x.image.id))
      );
    },
    onApprove() {
      this.$emit("onApprove");
    },
    onRowContextMenu(event) {
      if (this.selected && Object.keys(this.selected).length > 0) {
        if (this.propOrderStatusCompleted)
          this.$refs.cmDlgOrderDetailDatatable.show(event.originalEvent);
      }
    },

    onRowContextMenu2(event) {
      if (this.selected2 && Object.keys(this.selected2).length > 0) {
        if (this.cmDeliveryDatatable)
          this.$refs.cmDeliveryDatatable.show(event.originalEvent);
      }
    },
    rowClick(e) {
      if (this.selected && this.selected.image) {
        if (e.data.image && e.data.image.id == this.selected.image.id) {
          if (this.time) {
            if (new Date().getTime() - this.time <= 300) {
              this.$emit(
                "showDialogMetadata",
                this.selected ? this.selected.image : null
              );
            }
          }
        }
      }
      this.time = new Date().getTime();
    },
    rowClick2(e) {
      if (this.selected2 && this.selected2.image) {
        if (e.data.image && e.data.image.id == this.selected2.image.id) {
          if (this.time) {
            if (new Date().getTime() - this.time <= 300) {
              this.$emit(
                "showDialogMetadata",
                this.selected2 ? this.selected2.image : null
              );
            }
          }
        }
      }
      this.time = new Date().getTime();
    },
    formatUnixDateTime2String(value) {
      let result = "";
      if (value) {
        try {
          let date = new Date(value);
          result = moment
            .unix(date.getTime() / 1000)
            .format("HH:mm DD/MM/YYYY");
          result = result
            .replace(":", this.lang === "vi" ? "h" : ":")
            .replace(" ", this.lang === "vi" ? " ngày " : " ");
        } catch (error) {}
      }
      return result;
    },
  },
  computed: {
    imagesOne() {
      // images of buyer
      let results = [];
      if (this.propItems && this.propItems.length > 0) {
        for (let i = 0; i < this.propItems.length; i++) {
          if (
            this.propItems[i].image &&
            this.propItems[i].user_add === this.propOrder.created_by
          ) {
            if (
              results.findIndex(
                (x) => x.image.id === this.propItems[i].image.id
              ) < 0
            )
              results.push(this.propItems[i]);
          }
        }
      }
      return results;
    },
    imagesTwo() {
      // images of manager
      let results = [];
      if (this.propItems && this.propItems.length > 0) {
        for (let i = 0; i < this.propItems.length; i++) {
          if (
            this.propItems[i].image &&
            this.propItems[i].user_add != this.propOrder.created_by
          ) {
            if (
              results.findIndex(
                (x) => x.image.id === this.propItems[i].image.id
              ) < 0
            )
              results.push(this.propItems[i]);
          }
        }
      }
      return results;
    },
  },
};
</script>
<style lang="scss" scoped>
.list-group {
  overflow-y: auto;
}
.list-group-item {
  padding: 0.5rem;
  border: 0;
  border-radius: 0;
  border-top: 1px solid rgba($color: #022259, $alpha: 0.4);
  &:first-child {
    border-top: 0;
  }
  &:hover {
    cursor: pointer;
    background-color: rgba($color: #c7e7f9, $alpha: 0.5);
  }
}
.media {
  position: relative;
  img {
    border: 1px solid rgba($color: #022259, $alpha: 0.5);
    width: 64px;
    height: 64px;
    &:hover {
      cursor: pointer;
      border: 1px solid rgba($color: #022259, $alpha: 0.8);
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 13px;
  }
  button.remove-item {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
.img-shared {
  width: 28px;
}
button {
  font-size: 14px;
  .btn-share {
    width: 110px !important;
  }
  .btn-approve {
    width: 110px !important;
  }
}
</style>
<style lang="scss">
.order__dialog--images {
  .datatable {
    .p-datatable-thead {
      display: none;
    }
  }
}
</style>
