<template>
    <satellite-list></satellite-list>
</template>
<script>
import SatelliteList from "./satellite/List"
export default {
    components:{
        SatelliteList
    }
}
</script>
<style lang="scss" scoped>

</style>