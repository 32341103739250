<template>
  <CommonAccordion :propTitle="$t('title_processing_level_structure')">
    <div slot="content">
      <div class="p-2">
        <div class="row no-gutters w-100">
          <div class="col-12 col-md-6">
            <div class="form-group row no-gutters p-1">
              <label class="col-form-label" :style="styleLabel"
                >{{ $t("label_code") }}&nbsp;<span
                  class="font-weight-bold required"
                  >*</span
                ></label
              >
              <div :style="styleControl">
                <ValidationProvider :name="$t('label_code')" rules="required">
                  <InputText
                    class="w-100 new-style"
                    type="text"
                    maxlength="100"
                    v-model="propEntity.name"
                    :disabled="!propStatus.isAdd && !propStatus.isEdit"
                  />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div class="row no-gutters w-100">
          <div class="col-12 col-md-6 p-1">
            <fieldset class="p-0">
              <legend class="w-auto">
                {{ $t("label_language_vietnamese") }}
              </legend>
              <div class="form-group row no-gutters">
                <label class="col-form-label" :style="styleLabel"
                  >{{ $t("label_name_vietnamese") }}&nbsp;<span
                    class="font-weight-bold required"
                    >*</span
                  ></label
                >
                <div :style="styleControl">
                  <ValidationProvider
                    :name="$t('label_name_vietnamese')"
                    rules="required"
                  >
                    <InputText
                      class="w-100 new-style"
                      type="text"
                      maxlength="255"
                      v-model="propEntity.translation.vi.value"
                      :disabled="!propStatus.isAdd && !propStatus.isEdit"
                    />
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group row no-gutters">
                <label class="col-form-label" :style="styleLabel">{{
                  $t("label_description")
                }}</label>
                <div :style="styleControl">
                  <Textarea
                    :autoResize="true"
                    rows="3"
                    class="w-100 new-style"
                    maxlength="255"
                    v-model="propEntity.translation.vi.description"
                    :disabled="!propStatus.isAdd && !propStatus.isEdit"
                  />
                </div>
              </div>
            </fieldset>
          </div>
          <div class="col-12 col-md-6 p-1">
            <fieldset class="p-0">
              <legend class="w-auto">{{ $t("label_language_english") }}</legend>
              <div class="form-group row no-gutters">
                <label class="col-form-label" :style="styleLabel"
                  >{{ $t("label_name_english") }}&nbsp;<span
                    class="font-weight-bold required"
                    >*</span
                  ></label
                >
                <div :style="styleControl">
                  <ValidationProvider
                    :name="$t('label_name_english')"
                    rules="required"
                  >
                    <InputText
                      class="w-100 new-style"
                      type="text"
                      maxlength="255"
                      v-model="propEntity.translation.en.value"
                      :disabled="!propStatus.isAdd && !propStatus.isEdit"
                    />
                  </ValidationProvider>
                </div>
              </div>
              <div class="form-group row no-gutters">
                <label class="col-form-label" :style="styleLabel">{{
                  $t("label_description")
                }}</label>
                <div :style="styleControl">
                  <Textarea
                    :autoResize="true"
                    rows="3"
                    class="w-100 new-style"
                    maxlength="255"
                    v-model="propEntity.translation.en.description"
                    :disabled="!propStatus.isAdd && !propStatus.isEdit"
                  />
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import InputText from "@/components/commons/inputtext/InputText";
import InputNumber from "@/components/commons/inputnumber/InputNumber";
import Textarea from "@/components/commons/textarea/Textarea";
export default {
  components: { InputText, Textarea, CommonAccordion, InputNumber },
  props: {
    propEntity: {
      type: Object,
      default: () => {},
    },
    propStatus: {
      type: Object,
      default: () => {
        return {
          isAdd: false,
          isEdit: false,
        };
      },
    },
  },
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    styleLabel() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:120px";
    },
    styleLabelCheckbox() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:80px";
    },
    styleControl() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:calc(100% - 120px)";
    },
  },
};
</script>
<style lang="scss">
.processing-level__structure {
  .p-md-4 {
    padding: 0.5rem !important;
  }
  fieldset {
    border-color: transparent;
    margin: 0;
    padding: 0;
    padding: 0.5rem;
    legend {
      font-size: 14px;
      font-weight: 700;
      margin: 0;
      padding: 0;
      margin-bottom: 1rem;
    }
  }
  fieldset {
    .form-group {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
