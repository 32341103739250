<template>
  <button
    :class="containerClass"
    type="button"
    v-ripple
    @click="changePageToNext"
  >
    <span class="p-paginator-icon pi pi-angle-right"></span>
  </button>
</template>

<script>
import Ripple from "@/utils/commons/primevue/ripple/Ripple";

export default {
  methods: {
    changePageToNext(e) {
      this.$emit("changePageToNext", e);
    },
  },
  computed: {
    containerClass() {
      return [
        "p-paginator-next p-paginator-element p-link",
        {
          "p-disabled": this.$attrs.disabled,
        },
      ];
    },
  },
  directives: {
    ripple: Ripple,
  },
};
</script>