<template>
  <div class="confirmation-tasking-time-radar">
    <div class="row no-gutters">
      <div class="col-12 col-md-6">
        <fieldset class="p-0">
          <legend class="w-auto">
            {{ $t("label_acceptable_date_range") }}
          </legend>
          <div class="row no-gutters">
            <div class="col-12 col-md-8 d-flex pr-2">
              <label style="width: 90px" class="range-time-label">{{
                $t("label_earliest")
              }}</label>
              <InputText
                style="width: calc(100% - 90px)"
                class="form-control new-style"
                :value="
                  formatUnixDateTime2String(earliest ? earliest.getTime() : '')
                "
                readonly
                maxlength="25"
              />
            </div>
            <div class="col-12 col-md-2 pr-1">
              <InputText
                class="form-control new-style"
                placeholder="HH"
                readonly
                :value="earliest ? earliest.getHours() : ''"
                maxlength="10"
              />
            </div>
            <div class="col-12 col-md-2 pl-1">
              <InputText
                class="form-control new-style"
                placeholder="MM"
                readonly
                :value="earliest ? earliest.getMinutes() : ''"
                maxlength="10"
              />
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-12 col-md-8 d-flex pr-2">
              <label style="width: 90px" class="range-time-label">{{
                $t("label_latest")
              }}</label>
              <InputText
                style="width: calc(100% - 90px)"
                class="form-control new-style"
                :value="
                  formatUnixDateTime2String(latest ? latest.getTime() : '')
                "
                readonly
                maxlength="25"
              />
            </div>
            <div class="col-12 col-md-2 pr-1">
              <InputText
                class="form-control new-style"
                placeholder="HH"
                readonly
                :value="latest ? latest.getHours() : ''"
                maxlength="10"
              />
            </div>
            <div class="col-12 col-md-2 pl-1">
              <InputText
                class="form-control new-style"
                placeholder="MM"
                readonly
                :value="latest ? latest.getMinutes() : ''"
                maxlength="10"
              />
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col-12 col-md-6">
        <fieldset class="p-0">
          <legend class="w-auto">
            {{ $t("label_incidence_angle") }} ( 20-59,
            {{ $t("label_or_leave_unspecified") }} )
          </legend>
          <div class="row no-gutters pl-2">
            <div class="col-3">
              <label class="col-form-label">{{ $t("label_minimum") }} </label>
            </div>
            <div class="col-9">
              <InputText
                type="text"
                class="form-control new-style"
                readonly
                :value="propDto.order_request_info.incidence_angle_min"
                maxlength="255"
              />
            </div>
          </div>
          <div class="row no-gutters pl-2">
            <div class="col-3">
              <label class="col-form-label">{{ $t("label_maximum") }}</label>
            </div>
            <div class="col-9">
              <InputText
                type="text"
                class="form-control new-style"
                readonly
                :value="propDto.order_request_info.incidence_angle_max"
                maxlength="255"
              />
            </div>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-2">
        <label class="col-form-label">{{ $t("label_look_direction") }}</label>
      </div>
      <div class="col-10 d-flex">
        <div
          class="form-group m-0"
          style="width: 10rem"
          v-for="(item, index) in propLookDirection"
          :key="'orbit' + index"
        >
          <Checkbox
            v-model="lookDirection"
            :value="item.id"
            :id="item.id"
            readonly
          />
          <label class="p-checkbox-label">{{ item.value }}</label>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-2">
        <label class="col-form-label">{{ $t("label_orbit_direction") }}</label>
      </div>
      <div class="col-10 d-flex">
        <div
          class="form-group m-0"
          style="width: 10rem"
          v-for="(item, index) in propOrbitDirection"
          :key="'orbit' + index"
        >
          <Checkbox
            v-model="orbitDirection"
            :value="item.id"
            :id="item.id"
            readonly
          />
          <label class="p-checkbox-label">{{ item.value }}</label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import InputText from "@/components/commons/inputtext/InputText";
import RadioButton from "@/components/commons/radiobutton/RadioButton";
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import AutoComplete from "@/components/commons/autocomplete/AutoComplete";
import Checkbox from "@/components/commons/checkbox/Checkbox";
import { change_alias } from "@/utils/commons/common";
import moment from "moment";
export default {
  components: {
    DatePicker,
    InputText,
    CommonAccordion,
    RadioButton,
    AutoComplete,
    Checkbox,
  },
  props: {
    propDto: {
      type: Object,
      default: () => {},
    },
    propLookDirection: {
      type: Array,
      default: () => [],
    },
    propOrbitDirection: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      lookDirection: [],
      orbitDirection: [],
    };
  },
  created() {
    if (this.propDto.order_request_info.look) {
      this.lookDirection.push(
        this.propDto.order_request_info.look
          ? this.propDto.order_request_info.look.id
          : null
      );
    }
    if (this.propDto.order_request_info.orbit) {
      this.orbitDirection.push(
        this.propDto.order_request_info.orbit
          ? this.propDto.order_request_info.orbit.id
          : null
      );
    }
  },
  methods: {
    formatUnixDateTime2String(value) {
      let result = "";
      if (value) {
        try {
          let date = new Date(value);
          result = moment.unix(date.getTime() / 1000).format("DD/MM/YYYY");
        } catch (error) {}
      }
      return result;
    },
  },
  computed: {
    earliest() {
      if (this.propDto.order_request_info.date_from)
        return new Date(this.propDto.order_request_info.date_from);
      else return null;
    },
    latest() {
      if (this.propDto.order_request_info.date_to)
        return new Date(this.propDto.order_request_info.date_to);
      else return null;
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
.confirmation-tasking-time-radar {
  .row {
    margin-bottom: 1rem;
  }
  legend {
    font-size: 14px;
  }
  .range-time-label {
    line-height: 2.105rem;
    text-align: center;
    background-color: #009ef7; //#eee;
    border: 1px solid #009ef7; //#ccc;
    color: white;
    border-right: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    color: #f1f4fa;
    font-size: 14px;
    margin: 0;
  }
  fieldset {
    border-color: transparent;
  }
}
</style>
