var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{style:({ zIndex: 1050 }),attrs:{"header":_vm.$t('title_update_status'),"visible":_vm.show,"modal":true,"contentStyle":_vm.propStyle},on:{"update:visible":function($event){_vm.show=$event},"hide":_vm.onClose},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('button',{class:'btn btn-geostore2  btn-w96-h36 btn-save mr-0',attrs:{"disabled":_vm.propButton && _vm.propButton.disable ? _vm.propButton.disable : false,"label":_vm.$t('button_text_save'),"icon":_vm.propButton && _vm.propButton.processing
          ? 'fas fa-spinner fa-spin'
          : 'fas fa-save'},on:{"click":_vm.onUpdateStatus}},[_c('i',{class:_vm.propButton && _vm.propButton.processing
            ? 'fas fa-spinner fa-spin'
            : 'fas fa-save'}),_vm._v(" "+_vm._s(_vm.$t("button_text_save"))+" ")])]},proxy:true}])},[_c('div',{staticClass:"p-grid m-0"},[_c('div',{staticClass:"form-row p-field p-col-12 m-0"},[_c('label',{staticClass:"p-col-fixed m-0 w-100"},[_vm._v(_vm._s(_vm.$t("label_order_code")))]),_c('InputText',{staticClass:"w-100 new-style",attrs:{"value":_vm.propItem ? _vm.propItem.order_code : '',"maxLength":"255","readonly":true}})],1),_c('div',{staticClass:"p-field p-col-12"},[_c('label',{staticClass:"p-col-fixed m-0 w-100"},[_vm._v(" "+_vm._s(_vm.$t("label_status"))+"   "),_c('span',{staticClass:"font-weight-bold required"},[_vm._v("*")])]),_c('Dropdown',{staticClass:"w-100 new-style",attrs:{"showClear":false,"options":_vm.propOrderStatus.filter(
            function (x) { return x.id != this$1.propTabId && x.name != 'ORDER_CANCELED'; }
          ),"optionValue":"id","optionLabel":"value","optionDisabled":"is_disable","dataKey":"id","disabled":_vm.propButton && _vm.propButton.disable ? _vm.propButton.disable : false,"placeholder":_vm.$t('placeholder_select_status')},scopedSlots:_vm._u([{key:"option",fn:function(slotProps){return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"square _18x18 pr-2",style:(_vm.orderColor(slotProps.option.name))},[_vm._v("   ")]),_c('span',[_vm._v(_vm._s(slotProps.option.value))])])]}}]),model:{value:(_vm.entity.status_id),callback:function ($$v) {_vm.$set(_vm.entity, "status_id", $$v)},expression:"entity.status_id"}})],1),_c('div',{staticClass:"form-row p-field p-col-12 m-0"},[_c('label',{staticClass:"p-col-fixed m-0 w-100"},[_vm._v(_vm._s(_vm.labelRemark)+"  "),_c('span',{staticClass:"font-weight-bold required"},[_vm._v("*")])]),_c('Textarea',{staticClass:"w-100 new-style",attrs:{"autoResize":true,"rows":"3","maxLength":"255","readonly":_vm.propButton && _vm.propButton.disable ? _vm.propButton.disable : false},model:{value:(_vm.entity.remark),callback:function ($$v) {_vm.$set(_vm.entity, "remark", $$v)},expression:"entity.remark"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }