<template>
  <Dialog
    :header="$t('title_share')"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :maximizable="false"
    :modal="true"
    :contentStyle="{
      width: propStyle.width,
      overflowY: isOpen ? 'inherit!important' : 'auto',
    }"
    @hide="onClose"
  >
    <form>
      <div class="form-share">
        <div class="form-group">
          <label for="inputName">{{ $t("label_image_name") }}</label>
          <p class="font-weight-bold" v-for="item in propItems" :key="item.id">
            {{ item.name }}
          </p>
        </div>
        <div class="form-group">
          <label
            >{{ $t("label_receiver")
            }}<span class="required">&nbsp;*</span></label
          >
          <treeselect
            v-model="entity.member_ids"
            :cacheOptions="false"
            :searchable="true"
            :multiple="true"
            :matchKeys="['full_name', 'username']"
            :options="propUsers"
            placeholder=""
            :noChildrenText="$t('label_no_sub_options')"
            :noOptionsText="$t('label_no_options_text')"
            :noResultsText="$t('label_no_results_text')"
            name="usersTree"
          >
            <label slot="option-label" slot-scope="{ node }"
              >{{ node.raw.full_name }} ( {{ node.raw.username }} )</label
            >
            <div slot="value-label" slot-scope="{ node }">
              {{ node.raw.full_name }} ( {{ node.raw.username }} )
            </div>
          </treeselect>
        </div>
        <div class="form-group">
          <label for="inputName"
            >{{ $t("label_content")
            }}<span class="required">&nbsp;*</span></label
          >
          <Textarea
            type="text"
            class="form-control"
            rows="2"
            v-model="entity.description"
            maxlength="255"
            autofocus
            :autoResize="true"
          ></Textarea>
        </div>
        <div class="form-row">
          <div class="col-6">
            <div class="form-group">
              <Checkbox
                v-model="entity.isPass"
                :binary="true"
                id="checkPassword"
              />
              <label class="p-checkbox-label" for="checkPassword">{{
                $t("label_is_password")
              }}</label>
            </div>
            <div class="form-group" v-show="entity.isPass">
              <InputText
                type="text"
                class="form-control"
                id="inputPassword"
                autocomplete="off"
                v-model="entity.password"
                maxlength="50"
              />
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <Checkbox
                v-model="entity.isExpired"
                :binary="true"
                id="checkExpiredTime"
              />
              <label class="p-checkbox-label" for="checkExpiredTime">{{
                $t("label_is_expired_time")
              }}</label>
            </div>
            <div class="form-group" v-show="entity.isExpired">
              <label for="inputExpiredTime" class="w-100">{{
                $t("label_expired_date")
              }}</label>
              <date-picker
                v-model="entity.expiredTime"
                :format="'DD/MM/YYYY'"
                valueType="timestamp"
                id="inputExpiredTime"
              >
              </date-picker>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <Checkbox
                v-model="entity.isNum"
                :binary="true"
                id="checkNumDownload"
              />
              <label class="p-checkbox-label" for="checkNumDownload">{{
                $t("label_is_number_download")
              }}</label>
            </div>
            <div class="form-group" v-show="entity.isNum">
              <label for="inputNumDownload">{{ $t("label_number") }}</label>
              <InputText
                type="number"
                class="form-control"
                id="inputNumDownload"
                v-model="entity.download_number"
                min="1"
                max="100"
                step="1"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
    <template #footer>
      <Button :disabled="propButtonShare && propButtonShare.disabled"
        :label="$t('button_text_close')"
        :icon="'fas fa-times'"
        :class="'p-button-danger p-button-sm'"
        @click="onClose"
      />
      <Button :disabled="propButtonShare && propButtonShare.disabled"
        :label="$t('button_text_share')"
        :icon="propButtonShare && propButtonShare.processing ? 'fas fa-spinner fa-spin' :'fas fa-check'"
        :class="'p-button-success p-button-sm mr-0'"
        @click="onShare"
      />
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
import Button from "@/components/commons/button/Button";
import DatePicker from "vue2-datepicker";
import Textarea from "@/components/commons/textarea/Textarea";
import Treeselect from "@/components/commons/vue-treeselect/src/components/Treeselect";
import InputText from "@/components/commons/inputtext/InputText";
import Checkbox from "@/components/commons/checkbox/Checkbox";
import MultiSelect from "@/components/commons/multiselect/MultiSelect";
export default {
  components: {
    Dialog,
    Button,
    DatePicker,
    Textarea,
    Treeselect,
    InputText,
    Checkbox,
    MultiSelect,
  },
  props: {
    propItems: {
      type: Array,
      default: () => [],
    },
    propTitle: {
      type: String,
      default: "",
    },
    propStyle: {
      type: Object,
      default: () => {
        return {
          width: "480px",
        };
      },
    },
    propShow: {
      type: Boolean,
      default: false,
    },
    propUsers: {
      type: Array,
      default: () => [],
    },
    propButtonShare:{
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isOpen: false,
      entity: {
        description: null,
        isNum: false,
        isPass: false,
        isExpired: false,
        download_number: 10,
        expired_time: null,
        expiredTime: null,
        image_id: null,
        is_public: true,
        member_ids: [],
        password: null,
      },
    };
  },
  created() {
    this.show = this.propShow;
  },
  watch: {
    propShow: {
      handler(val) {
        this.show = val;
      },
      immediate: true,
    },
  },
  methods: {
    openUserTree() {
      this.isOpen = true;
    },
    closeUserTree() {
      this.isOpen = false;
    },
    onClose() {
      this.show = false;
      this.$emit("close");
    },
    onShare() {
      this.$emit("onShare", this.entity);
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
p,
.form-group {
  margin-bottom: 0.5rem;
}
</style>

<style lang="scss">
.form-share {
  .vue-treeselect__control {
    border-color: #a6a6a6;
    &:hover {
      border-color: #212121 !important;
    }
  }
  .mx-input {
    border-color: #a6a6a6;
    &:hover {
      border-color: #212121 !important;
    }
    &:disabled,
    .disabled {
      background-color: transparent;
    }
  }
  .p-multiselect {
    width: 100%;
  }
}
</style>