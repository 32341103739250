<template>
  <main-layout
    :isToolbar="true"
    :isBreadcrumb="true"
    :breadcrumbitems="[{ label: 'Đặt hàng' }, { label: 'Chi tiết đơn hàng' }]"
    :propButtonsState="{
      save: {
        visible: false,
      },
      add: {
        visible: false,
      },
      edit: {
        visible: false,
      },
      reload: {
        visible: false,
      },
      print: {
        visible: false,
      },
    }"
    :propTitle="'Chi tiết đơn hàng'"
    :propDescription="{
      text: Object.keys(order).length > 0 && order.name ? order.name : '',
      style: 'color:red',
    }"
  >
    <div class="order-detail">
      <div class="order-detail__dialog">
        <DialogMetadata
          v-if="dialog.show"
          :prop-title="dialog.title"
          :prop-show="dialog.show"
          :prop-style="{ width: dialog.width }"
          :propData="{}"
          @close="dialog.show = false"
        />
      </div>
      <div class="order-detail__panel panel">
        <div class="panel-body">
          <ul class="list-group list-group-flush">
            <li
              class="list-group-item list-group-item-action"
              v-for="item in order.order_detail"
              :key="item._id"
              :class="item.status === 'SHARED' ? 'bg-light-success' : ''"
              @click="openDialog(item)"
            >
              <div class="media">
                <img
                  class="mr-3"
                  src="/img/PREVIEW_SPOT6_MS_201804210317029_SEN_4419716101.JPG"
                  alt="Generic placeholder image"
                  width="64"
                  height="64"
                />
                <div class="media-body">
                  <h6 class="font-weight-bold lbl-geostore">
                    {{
                      item.image_dto && item.image_dto.image_name
                        ? item.image_dto.image_name
                        : ""
                    }}
                  </h6>
                  <ul class="">
                    <li class="">
                      <div class="">
                        <span><strong>Ngày chụp:</strong> 25/05/2020</span>
                        <span class="pl-2 pr-2">|</span>
                        <span><strong>Vệ tinh: </strong>model</span>
                      </div>
                    </li>
                    <li>
                      <div class="mt-1">
                        <span
                          ><strong>Res:</strong>&nbsp;{{
                            item.image_dto && item.image_dto.resolution
                              ? item.image_dto.resolution
                              : ""
                          }}</span
                        >
                        <span class="pl-1 pr-1">|</span>
                        <span
                          ><strong>Inc Ang:</strong>&nbsp;{{
                            item.image_dto && item.image_dto.incidence_angle
                              ? item.image_dto.incidence_angle
                              : ""
                          }}</span
                        >
                        <span class="pl-1 pr-1">|</span>
                        <span
                          v-if="item.image_dto && item.image_type == 'OPTICAL'"
                          ><strong>Cloud:</strong>&nbsp;{{
                            item.image_dto && item.image_dto.cloud_coverange
                              ? item.image_dto.cloud_coverange
                              : ""
                          }}&nbsp;{{
                            item.image_dto &&
                            item.image_dto.cloud_coverange_unit
                              ? item.image_dto.cloud_coverange_unit
                              : ""
                          }}</span
                        >
                        <span
                          v-if="item.image_dto && item.image_type == 'RADAR'"
                          ><strong>Look dir:</strong>
                          {{
                            item.image_dto.direction
                              ? item.image_dto.direction
                              : ""
                          }}</span
                        >
                        <span
                          class="pl-1 pr-1"
                          v-if="item.image_dto && item.image_type == 'RADAR'"
                          >|</span
                        >
                        <span
                          v-if="item.image_dto && item.image_type == 'RADAR'"
                          ><strong>Pol:</strong>&nbsp;{{
                            item.image_dto.pol_layer
                              ? item.image_dto.pol_layer
                              : ""
                          }}</span
                        >
                        <span
                          class="pl-1 pr-1"
                          v-if="item.image_dto && item.image_type == 'RADAR'"
                          >|</span
                        >
                        <span
                          v-if="item.image_dto && item.image_type == 'RADAR'"
                          ><strong>Mode:</strong>&nbsp;{{
                            item.image_dto.mode ? item.image_dto.mode : ""
                          }}</span
                        >
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </main-layout>
</template>
<script>
import MainLayout from "@/components/pages/layouts/admin/Main";
import DialogMetadata from "@/components/commons/metadata/Metadata"; //"./detail/Metadata";
import DragResize from "@/components/commons/drag/DragResize";
import OutSide from "@/components/commons/drag/OutSide";
import sharingFunc from "@/utils/functions/sharing";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import orderFunc from "@/utils/functions/order";
export default {
  components: { MainLayout, DialogMetadata, DragResize, OutSide },
  data() {
    return {
      id: "",
      order: {},
      dialog: {
        show: false,
        selected: null,
        width: 0,
        title: "Chia sẻ ảnh".toUpperCase(),
        x: 284,
        y: 56,
        w: 450,
        h: 400,
      },
    };
  },
  created() {
    this.dialog.h = this.documentHeight - 100;
    let params = this.$route.params;
    if (params) {
      this.id = params.id ? params.id : "";
    }
    this.getById();
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
  },
  mounted() {
    if (this.documentWidth < 576) {
      this.dialog.width = this.documentWidth + "px";
    } else if (this.documentWidth >= 576 && this.documentWidth < 768) {
      this.dialog.width = "540px";
    } else if (this.documentWidth >= 768 && this.documentWidth < 992) {
      this.dialog.width = "720px";
    } else {
      this.dialog.width = "960px";
    }
  },
  methods: {
    async onShare(val) {
      let entity = {
        name: val.name,
        user_key: this.order.ordered_by,
        is_password: val.isPass,
        password: val.password,
        is_expired: val.isExpired,
        expired_time: val.isExpired ? val.expiredTime : null,
        is_num_downlad: val.isNum,
        num_download: val.numDownload,
        image_id: val.image_id,
        image_type: val.image_type,
        order_id: this.order._id,
      };
      try {
        let response = await sharingFunc.save(
          entity,
          this.$store.getters.getAccessToken
        );
        if (response.status === 200 || response.status === 201) {
          this.$toast.success({
            title: "Thông báo",
            message:
              "Chia sẻ ảnh cho người dùng " +
              this.order.ordered_by +
              " thành công.",
            position: "top right",
          });
          let item = this.order.order_detail.find(
            (x) => x.image_id === val.image_id
          );
          if (item) item.status = "SHARED";
          this.closeDialog();
        } else {
          this.$toast.error({
            title: "Thông báo",
            message:
              "Chia sẻ ảnh cho người dùng " +
              this.order.ordered_by +
              " không thành công.",
            position: "top right",
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 401)
          await this.refreshToken(this.onShare, val);
        else {
          this.$toast.error({
            title: "Thông báo",
            message:
              "Chia sẻ ảnh cho người dùng " +
              this.order.ordered_by +
              " không thành công.",
            position: "top right",
          });
        }
      }
    },
    dialogResizing(val) {
      if (val.height > 0) this.dialog.h = val.height;
      if (val.width > 0) this.dialog.w = val.width;
    },
    closeDialog() {
      this.dialog.show = false;
      $("#modalordermetadata").removeClass("in");
      $(".modal-backdrop").remove();
      $("#modalordermetadata").hide();
      $("#modalordermetadata").modal("hide");
    },
    openDialog(val) {
      switch (val.status) {
        case "NEW":
          this.dialog.h = this.documentHeight - 100;
          break;
        case "SHARED":
          this.dialog.h = this.documentHeight - 300;
          break;
        default:
          this.dialog.h = this.documentHeight - 100;
          break;
      }
      this.dialog.selected = val;
      this.dialog.show = true;
      setTimeout(() => {
        $("#modalordermetadata").modal("show");
      }, 100);
    },
    goOrderListPage() {
      this.$router.push({
        name: "OrderList",
      });
    },
    async getById() {
      this.order = {};
      try {
        let response = await orderFunc.getById(
          this.id,
          this.$store.getters.getAccessToken
        );
        if (response.status === 200) {
          this.order = response.data;
        }
      } catch (error) {
        if (error.response && error.response.status === 401)
          await this.refreshToken(this.getById);
      }
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            this.closeDialog();
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          this.closeDialog();
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
};
</script>
<style lang="scss" scoped>
.order-detail {
  background: white;
  // margin: 0 1rem;
  .order-title {
    font-size: 1.25rem;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0;
    .btn-group {
      button {
        margin-right: 0.25rem;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  .panel-body {
    font-size: 14px;
    border-radius: 0.25rem;
    overflow-y: auto;
    // margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    .media {
      -webkit-box-shadow: 0px 0px 2px 0px rgba(2, 34, 89, 1);
      -moz-box-shadow: 0px 0px 2px 0px rgba(2, 34, 89, 1);
      box-shadow: 0px 0px 2px 0px rgba(2, 34, 89, 1);
      padding: 0.5rem;
      &:hover {
        background: rgba(0, 0, 0, 0.0375);
      }
    }
  }
  .list-group {
    .list-group-item {
      border-color: transparent; // rgba(2, 34, 89, 0.25);
      padding: 0.5rem;
      &:first-child {
        border-top-left-radius: 0.375rem;
        border-top-right-radius: 0.375rem;
      }
      &:last-child {
        border-bottom-left-radius: 0.375rem;
        border-bottom-right-radius: 0.375rem;
      }
      &:hover {
        cursor: pointer;
      }
      &:hover {
        background: white;
      }
      h6 {
        font-size: 14px;
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }
    }
  }
}
// Extra small devices (portrait phones, less than 1200px)
@media (min-width: 1200px) {
  .panel-body {
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    box-shadow: none !important;
    .list-group {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .list-group-item {
        border-radius: 0;
        position: relative;
        width: 50%;
        &:nth-child(2) {
          border-top: 0;
        }
        &:nth-child(2n) {
          border-right: 0;
        }
        &:first-child {
          border-top-right-radius: 0;
        }
        &:last-child {
          border-bottom-right-radius: 0;
        }
        h6 {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
        }
      }
    }
  }
}
</style>


<style lang="scss" scoped>
.order-detail {
  padding: 0.25rem;
  padding-top: 0;
  .order-detail__search {
    margin-bottom: 0.25rem;
  }
  .order-detail__tree {
    margin-left: 0.25rem;
  }
  .order-detail__structure {
    position: relative;
    margin-top: 0.25rem;
    .actions {
      position: absolute;
      right: 0.25rem;
      top: 0.125rem;
      button {
        border-radius: 50% !important;
        margin-right: 0.25rem;
        width: 1.75rem;
        height: 1.75rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>