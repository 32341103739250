<template>
  <div class="page-wrapper theme toggled">
    <a
      id="show-sidebar"
      class="btn btn-sm btn-toggled"
      @click="show = !show"
      :style="show ? { zIndex: 1 } : { zIndex: 999 }"
      v-show="!show"
    >
      <i class="fas fa-bars"></i>
    </a>
    <sidebar-layout @closeSidebar="show = !show"></sidebar-layout>
    <div class="container-fluid">
      <slot name="default"></slot>

      <WidgetDownloadProgressBar
        v-if="widget.download.show"
        :prop-items="itemsDownload"
        :prop-show="widget.download.show2"
        @close="closeWidgetDownload"
      />
    </div>
  </div>
</template>
<script>
import SidebarLayout from "./admin/Sidebar";
import MainLayout from "./admin/Main";
import WidgetDownloadProgressBar from "@/components/commons/widget/DownloadProgressBar";
export default {
  components: {
    SidebarLayout,
    MainLayout,
    WidgetDownloadProgressBar,
  },
  props: {},
  data() {
    return {
      show: true,
      widget: {
        download: {
          show: false,
          show2: false,
        },
      },
    };
  },

  created() {
    this.$store.dispatch("setIsSidebar", true);
  },
  watch: {
    $route: {
      handler: function (val) {},
      deep: true,
      immediate: true,
    },
    show: {
      handler(val) {
        this.$store.dispatch("setIsSidebar", val);
      },
      immediate: true,
    },
    itemsDownload(val) {
      if (val && val.length > 0) {
        this.widget.download.show2 = true;
        this.widget.download.show = true;
      } else {
        this.closeWidgetDownload();
      }
    },
  },
  mounted() {
    if (this.documentWidth < 768 && this.documentWidth > 0)
      $(".page-wrapper").removeClass("toggled");
  },
  methods: {
    closeWidgetDownload() {
      this.widget.download.show2 = false;
      setTimeout(() => {
        this.widget.download.show = false;
      }, 200);
    },
  },
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    itemsDownload() {
      return this.$store.getters.getImagesDownload;
    },
  },
};
</script>
<style lang="scss" scoped>
.page-wrapper {
  height: 100vh;
}
.page-wrapper.toggled #show-sidebar {
  left: -40px;
}
#show-sidebar {
  border-radius: 0 4px 4px 0px;
  cursor: pointer;
  color: #fff;
  position: fixed;
  left: 0;
  top: 2rem;
  width: 35px;
  transition-delay: 0.3s;
  z-index: 999;
}
.btn-toggled {
  background: #1a1a27;
  color: white;
}
</style>