var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-layout',{attrs:{"isToolbar":true,"isBreadcrumb":true,"breadcrumbitems":[
    { label: 'Đặt hàng' },
    { label: 'Đơn hàng đã xác nhận' } ],"propButtonsState":{
    save: {
      visible: false,
    },
    add: {
      visible: false,
    },
    edit: {
      visible: false,
    },
    reload: {
      visible: false,
    },
    print: {
      visible: false,
    },
  },"propTitle":'Đơn hàng đã xác nhận'}},[_c('div',{staticClass:"order-list"},[_c('div',{staticClass:"order-list__dialog"},[_c('div',{staticClass:"order-list__dialog--images"},[(_vm.dlgOrderDetail.show)?_c('DialogOrderDetail',{attrs:{"prop-style":{ width: _vm.dlgOrderDetail.width },"prop-show":_vm.dlgOrderDetail.show,"prop-title":_vm.dlgOrderDetail.title,"prop-items":_vm.dlgOrderDetail.value.imageList},on:{"close":function($event){_vm.dlgOrderDetail.show = false;
            _vm.dlgOrderDetail.value = null;},"showDialogShare":_vm.showDialogShare}}):_vm._e()],1),(_vm.dlgShare.show)?_c('DialogShare',{attrs:{"prop-style":{ width: _vm.dlgShare.width },"prop-show":_vm.dlgShare.show,"prop-title":_vm.dlgShare.title,"prop-item":_vm.dlgShare.value,"propOrderBy":_vm.dlgOrderDetail.value.orderBy},on:{"close":function($event){_vm.dlgShare.show = false;
          _vm.dlgShare.value = null;},"onShare":_vm.onShare}}):_vm._e()],1),_c('div',{staticClass:"order-list__search"},[_c('list-search',{attrs:{"prop-data":{
          users: _vm.users,
        },"prop-search":_vm.search}})],1),_c('div',{staticClass:"order-list__table"},[_c('list-table',{attrs:{"prop-data":_vm.order,"prop-users":_vm.users},on:{"pagechanged":_vm.pagechanged,"pageSizechanged":_vm.pageSizechanged,"showOrderDetail":_vm.showOrderDetail}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }