<template>
  <main-layout
    :isToolbar="false"
    :isBreadcrumb="true"
    :breadcrumbitems="[
      { label: $t('menu_data_management') },
      { label: $t('menu_upload_history') },
    ]"
    :propButtonsState="{
      save: {
        visible: false,
      },
      add: {
        visible: false,
      },
      edit: {
        visible: false,
      },
      reload: {
        visible: true,
      },
      print: {
        visible: false,
      },
    }"
    :propTitle="$t('menu_upload_history')"
    @emitFunction="menuHeaderClick"
  >
    <div class="upload-history">
      <div class="upload-history__dialog">
        <DialogMetadata
          v-if="dialog.metadata.show"
          :prop-style="{ width: dialog.metadata.width }"
          :prop-show="dialog.metadata.show"
          :prop-title="dialog.metadata.title"
          :prop-item="dialog.metadata.value"
          @close="
            dialog.metadata.show = false;
            dialog.metadata.value = null;
          "
          @initSuccessPreviewImange="initSuccessPreviewImange"
        />
        <DialogUploadMetadata
          v-if="dialogUploadMetadata.show"
          :prop-title="dialogUploadMetadata.title"
          :prop-show="dialogUploadMetadata.show"
          :prop-style="{ width: dialogUploadMetadata.width }"
          :propEntity="dialogUploadMetadata.entity"
          :prop-provider="provider.tree"
          :prop-processing-level="processingLevel.items"
          :prop-beam-mode="beamMode.items"
          :prop-direction="direction.items"
          :prop-pol-layer="polLayer.items"
          :prop-projects="project.items"
          :propDataSpecifications="dataSpecification.items"
          :propCategoryDataList="categoryDataList.items"
          :prop-button="dialogUploadMetadata.button"
          @clear="clearEntity"
          @close="
            dialogUploadMetadata.show = false;
            dialogUploadMetadata.entity = {};
            dialogUploadMetadata.value = null;
            dialogUploadMetadata.button = {
              disabled: false,
              processing: false,
            };
          "
          @onUpload="onUpload"
          @metaFileChange="metaFileChange"
          @message="message = $event"
          @getAllProvider="getAllProvider"
          @getAllProcessingLevel="getAllProcessingLevel"
          @getAllBeamMode="getAllBeamMode"
          @getAllDirection="getAllDirection"
          @getAllPolLayer="getAllPolLayer"
          @getAllProject="getAllProject"
          @getAllModel="getAllModel"
        />
        <DialogMessage
          v-if="message.length > 0"
          :propTitle="$t('title_message_info')"
          :prop-show="message.length > 0"
          :prop-message="message"
          @close="message = []"
        />
      </div>
      <div class="upload-history__search" id="elementSearch">
        <UploadHistoryFilter
          :prop-search="uploadHistory.search"
          :prop-user="user.items"
          @onSearch="onSearch"
          @getAllMetadata="getAllMetadata"
          @message="message = $event"
          @accordionHeaderClick="accordionHeaderClick"
        />
      </div>
      <div class="upload-history__datatable">
        <UploadHistoryDataTable
          :prop-loading="uploadHistory.loading"
          :prop-items="uploadHistory.items"
          :prop-table="uploadHistory.table"
          :prop-total="uploadHistory.total_records"
          ref="historyDatatable"
          @shoDialogMetadata="shoDialogMetadata"
          @getMetadata="getMetadata"
          @showDialogAdditionalInformation="showDialogAdditionalInformation"
        />
      </div>
    </div>
  </main-layout>
</template>
<script>
import MainLayout from "@/components/pages/layouts/admin/Main";
import UploadHistoryFilter from "./Filter.vue";
import UploadHistoryDataTable from "./DataTable";
import DialogMessage from "@/components/commons/dialog/MessageBox";
import DialogMetadata from "@/components/commons/metadata/Metadata"; //"./dialog/Metadata";
import DialogUploadMetadata from "./dialog/UploadMetadata";
import logFunc from "@/utils/functions/log";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import imageFunc from "@/utils/functions/image";
import fileFunc from "@/utils/functions/file";
import userFunc from "@/utils/functions/user";
import metaKeyFunc from "@/utils/functions/metakey";
import projectFunc from "@/utils/functions/project";
import processingDataFunc from "@/utils/functions/processingdata";
import { getMimeType } from "@/utils/commons/mimetypes";
import CryptoJS from "crypto-js";
export default {
  components: {
    MainLayout,
    UploadHistoryFilter,
    UploadHistoryDataTable,
    DialogMessage,
    DialogMetadata,
    DialogUploadMetadata,
  },
  props: {},
  data() {
    return {
      message: [],
      uploadHistory: {
        items: [],
        page_size: 0,
        page_number: 0,
        total_page: 0,
        total_records: 0,
        search: {
          create_from: null,
          create_to: null,
          user_id: null,
          page: 0,
          size: 2147483647,
        },
        table: {
          selected: null,
          height: 400,
        },
        is_bottom: false,
        loading: false,
      },
      user: {
        items: [],
      },
      dialog: {
        metadata: {
          show: false,
          title: "Metadata",
          value: null,
          width: "480px",
        },
      },
      dialogUploadMetadata: {
        show: false,
        data: null,
        title: "Upload Metadata",
        width: "540px",
        entity: {},
        value: null,
        button: {
          disabled: false,
          processing: false,
        },
      },
      project: {
        items: [],
        tree: [],
      },
      provider: {
        items: [],
        tree: [],
      },
      processingLevel: {
        items: [],
      },
      beamMode: {
        items: [],
      },
      direction: {
        items: [],
      },
      polLayer: {
        items: [],
      },
      dataSpecification: {
        items: [],
      },
      categoryDataList: {
        items: [],
      },
    };
  },
  created() {
    var vm = this;
    if (vm.documentWidth < 576) {
      this.dialogUploadMetadata.width = this.documentWidth + "px";
      this.dialog.metadata.width = this.documentWidth + "px";
    } else if (vm.documentWidth >= 576 && vm.documentWidth < 768) {
      this.dialogUploadMetadata.width = "540px";
      this.dialog.metadata.width = "540px";
    } else if (vm.documentWidth >= 768 && vm.documentWidth < 992) {
      this.dialogUploadMetadata.width = "720px";
      this.dialog.metadata.width = "720px";
    } else {
      this.dialog.metadata.width = "960px";
      this.dialogUploadMetadata.width = "960px";
    }
    let today = new Date(),
      dateMinus7 = new Date();
    today.setHours(23);
    today.setMinutes(59);
    today.setSeconds(59);
    dateMinus7.setHours(0);
    dateMinus7.setMinutes(0);
    dateMinus7.setSeconds(0);
    this.uploadHistory.search.create_to = today;
    this.uploadHistory.search.create_from = new Date(
      dateMinus7.setDate(dateMinus7.getDate() - 7)
    );
    this.getAllUser();
    this.getHistory();
  },
  mounted() {
    setTimeout(() => {
      this.uploadHistory.table.height = this.calcTableHeight();
    }, 100);
    this.getAllCategoryDataList();
    // this.getAllProject();
    this.getAllProvider();
    // this.getAllBeamMode();
    this.getAllProcessingLevel();
    // this.getAllPolLayer();
    // this.getAllDirection();
  },
  methods: {
    menuHeaderClick(val) {
      switch (val.code) {
        case "RELOAD":
          this.uploadHistory.items = [];
          this.uploadHistory.search = {};
          let today = new Date(),
            dateMinus7 = new Date();
          today.setHours(23);
          today.setMinutes(59);
          today.setSeconds(59);
          dateMinus7.setHours(0);
          dateMinus7.setMinutes(0);
          dateMinus7.setSeconds(0);
          this.uploadHistory.search.create_to = today;
          this.uploadHistory.search.create_from = new Date(
            dateMinus7.setDate(dateMinus7.getDate() - 7)
          );
          this.uploadHistory.search.user_id = null;
          this.getHistory();
          break;
      }
    },
    accordionHeaderClick() {
      setTimeout(() => {
        this.uploadHistory.table.height = this.calcTableHeight();
      }, 100);
    },
    calcTableHeight() {
      let element = document.getElementById("elementSearch");
      return (
        this.documentHeight -
        3 * 0.5 * 16 -
        56 -
        (element ? element.offsetHeight : 0) -
        24 -
        2 * 0.5 * 16 -
        2
      );
    },
    initSuccessPreviewImange(data) {
      let index = this.uploadHistory.items.findIndex(
        (x) => x.data_image.id === data.id
      );
      if (index >= 0) {
        this.uploadHistory.items[index].data_image.preview_src = data.val;
      }
    },
    onSearch(data) {
      this.uploadHistory.items = [];
      this.uploadHistory.search.create_from = data.create_from;
      this.uploadHistory.search.create_to = data.create_to;
      this.uploadHistory.search.user_id = data.user_id;
      this.getHistory();
    },
    getAllMetadata() {
      if (this.$refs.historyDatatable) {
        this.$refs.historyDatatable.getAllMetadata();
      }
    },
    async getMetadata(item) {
      let index = this.uploadHistory.items.findIndex((x) => x.id === item.id);
      if (index >= 0) {
        this.uploadHistory.items[index].processing = true;
        try {
          let response = await fileFunc.getMetadata2(
            {
              path: item.data.path,
              log_id: this.uploadHistory.items[index].id,
            },
            this.$store.getters.getAccessToken
          );
          if (response && response.status === 200) {
            if (response.data.success) {
              this.uploadHistory.items[index].data.is_metadata = true;
              this.uploadHistory.items[index].data_image = response.data.data;
              this.uploadHistory.items[index].processing = false;
              this.uploadHistory.items[index].message = [];
              this.$toast.success({
                title: this.$t("title_message_info"),
                message: this.$t("message_get_metadata_file_success", null, {
                  name: item.data.file_name ? item.data.file_name.trim() : "",
                }),
                position: "top right",
              });
            } else {
              this.uploadHistory.items[index].message = [response.data.message];
              this.$toast.warn({
                title: this.$t("title_message_info"),
                message: this.$t("message_get_metadata_file_error", null, {
                  name: item.data.file_name ? item.data.file_name.trim() : "",
                }),
                position: "top right",
                color: "#f68e1e",
              });
            }
          } else {
            this.$toast.warn({
              title: this.$t("title_message_info"),
              message: this.$t("message_get_metadata_file_error", null, {
                name: item.data.file_name ? item.data.file_name.trim() : "",
              }),
              position: "top right",
              color: "#f68e1e",
            });
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            this.refreshToken(this.getMetadata, item);
          } else {
            let msg =
              error.response &&
              error.response.data &&
              error.response.data.message
                ? error.response.data.message
                : null;
            if (msg) this.uploadHistory.items[index].message = [msg];
            this.$toast.error({
              title: this.$t("title_message_info"),
              message: this.$t("message_get_metadata_file_error", null, {
                name: item.data.file_name ? item.data.file_name.trim() : "",
              }),
              position: "top right",
            });
          }
        }
        this.uploadHistory.items[index].processing = false;
      }
    },
    shoDialogMetadata(item) {
      if (item && Object.keys(item).length > 0) {
        if (!item.data_image) {
          if (item.data && item.data.image_id) {
            let index = this.uploadHistory.items.findIndex(
              (x) => x.id === item.id
            );
            this.getImageById({ id: item.data.image_id, index: index });
          }
        } else {
          this.dialog.metadata.value = item.data_image;
          this.dialog.metadata.show = true;
        }
      }
    },
    async getImageById(data) {
      try {
        let response = await imageFunc.getById(
          data.id,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success) {
            if (data.index >= 0) {
              this.uploadHistory.items[data.index].data_image =
                response.data.data;
              this.dialog.metadata.value =
                this.uploadHistory.items[data.index].data_image;
              this.dialog.metadata.show = true;
            }
          } else this.message.push(msg ? msg : this.$t("label_no_data"));
        } else this.message.push(msg ? msg : this.$t("label_no_data"));
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getImageById, id);
        } else {
          let msg =
            error.response.data && error.response.data.message
              ? error.response.data.message
              : "";
          this.message.push(msg ? msg : this.$t("label_no_data"));
        }
      }
    },
    showDialogAdditionalInformation(item) {
      this.dialogUploadMetadata.entity = this.generateEntity();
      this.dialogUploadMetadata.value = item;
      this.dialogUploadMetadata.entity.file_path = item.data
        ? item.data.path
        : null;
      this.dialogUploadMetadata.show = true;
    },
    generateEntity() {
      return {
        area: null,
        area_unit: null,
        band: null,
        category_id: null,
        cloud_coverage: null,
        cloud_coverage_unit: null,
        description: null,
        google_earth_path: null,
        image_type: null,
        imaging_date: null,
        imaging_date_timestamp: null,
        incidence_angle: null,
        metadata: [],
        extend: {},
        name: null,
        resolution: null,
        snow_coverage: null,
        snow_coverage_unit: null,
        processing_level: null,
        processing_level_id: null,
        mode: null,
        beam_mode: null,
        direction: null,
        pol_layer: null,
        satellite_type: null,
        satellite_id: null,
        bounding_box: [],
        provider: null,
        file_path:
          this.dialogUploadMetadata.entity &&
          Object.keys(this.dialogUploadMetadata.entity).length > 0 &&
          this.dialogUploadMetadata.entity.file_path
            ? this.dialogUploadMetadata.entity.file_path
            : null,
        icon_path: {},
        icon_path_id: null,
        icon_src: null,
        icon_file: null,
        preview: {},
        preview_images: [],
        preview_path: {},
        preview_path_id: null,
        preview_src: null,
        preview_file: null,
        price: 0,
        price_unit: null,
        processing_level_name: null,
        provider_name: null,
        satellite_name: null,
        size: 0,
        project_ids: [],
        source_id: null,
        is_success: false,
        scene_id: null,
      };
    },
    async getDataFromXml(file) {
      let icon_file = this.dialogUploadMetadata.entity.icon_file;
      let icon_src = this.dialogUploadMetadata.entity.icon_src;
      let preview_file = this.dialogUploadMetadata.entity.preview_file;
      let preview_src = this.dialogUploadMetadata.entity.preview_src;

      this.dialogUploadMetadata.entity = this.generateEntity();
      this.dialogUploadMetadata.entity.icon_file = icon_file;
      this.dialogUploadMetadata.entity.icon_src = icon_src;
      this.dialogUploadMetadata.entity.preview_file = preview_file;
      this.dialogUploadMetadata.entity.preview_src = preview_src;
      try {
        let formData = new FormData();
        formData.append("file", file);
        let response = await fileFunc.dataFromXml(
          formData,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data && response.data.success) {
            this.dialogUploadMetadata.entity.is_success = true;
            await this.assignDataUpload(response.data.data);
          } else {
            this.message.push(
              this.$t("message_read_metadata_file_error", null, {
                name: file.name,
              })
            );
          }
        } else {
          this.message.push(
            this.$t("message_read_metadata_file_error", null, {
              name: file.name,
            })
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 401)
          this.refreshToken(this.getDataFromXml, file);
        else {
          this.message.push(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : this.$t("message_read_metadata_file_error", null, {
                  name: file.name,
                })
          );
        }
      }
    },

    assignDataUpload(data) {
      let previews = [];
      let icons = [];
      if (data && Object.keys(data).length > 0) {
        this.dialogUploadMetadata.entity.id = data.id;
        this.dialogUploadMetadata.entity.scene_id = data.scene_id
          ? data.scene_id
          : null;
        this.dialogUploadMetadata.entity.preview_images = data.preview_images;
        if (data.preview_images && data.preview_images.length > 0) {
          previews = data.preview_images.filter((x) => x.type === "PREVIEW");
          icons = data.preview_images.filter((x) => x.type === "ICON");
          let googleEarths = data.preview_images.filter(
            (x) => x.type === "GOOGLE_EARTH"
          );
          if (googleEarths.length > 0)
            this.dialogUploadMetadata.entity.google_earth_path =
              googleEarths[0].path_image;
        }
        this.dialogUploadMetadata.entity.area =
          data.area != null && data.area != undefined ? data.area : null;
        this.dialogUploadMetadata.entity.area_unit = data.area_unit
          ? data.area_unit.trim()
          : null;
        this.dialogUploadMetadata.entity.band =
          data.band != null && data.band != undefined ? data.band : null;
        this.dialogUploadMetadata.entity.cloud_coverage =
          data.cloud_coverage != null && data.cloud_coverage != undefined
            ? data.cloud_coverage
            : null;
        this.dialogUploadMetadata.entity.cloud_coverage_unit =
          data.cloud_coverage_unit ? data.cloud_coverage_unit.trim() : null;

        this.dialogUploadMetadata.entity.description = data.description
          ? data.description.trim()
          : null;
        this.dialogUploadMetadata.entity.file_path = data.file_path
          ? data.file_path.trim()
          : null;

        this.dialogUploadMetadata.entity.imaging_date = data.imaging_date
          ? data.imaging_date
          : null;
        if (this.dialogUploadMetadata.entity.imaging_date) {
          try {
            this.dialogUploadMetadata.entity.imaging_date_timestamp = new Date(
              this.dialogUploadMetadata.entity.imaging_date
            ).getTime();
          } catch (error) {}
        }
        this.dialogUploadMetadata.entity.incidence_angle =
          data.incidence_angle != null && data.incidence_angle != undefined
            ? data.incidence_angle
            : null;
        this.dialogUploadMetadata.entity.metadata = data.metadata
          ? data.metadata
          : {};

        this.dialogUploadMetadata.entity.mode = data.beam_mode
          ? data.beam_mode.trim()
          : null;
        this.dialogUploadMetadata.entity.beam_mode = data.beam_mode
          ? data.beam_mode.trim()
          : null;
        this.dialogUploadMetadata.entity.name = data.name
          ? data.name.trim()
          : null;
        this.dialogUploadMetadata.entity.pol_layer = data.pol_layer
          ? data.pol_layer.trim()
          : null;
        this.dialogUploadMetadata.entity.price =
          data.price != null && data.price != undefined ? data.price : null;
        this.dialogUploadMetadata.entity.price_unit = data.price_unit
          ? data.price_unit.trim()
          : null;

        this.dialogUploadMetadata.entity.processing_level_id =
          data.processing_level_id ? data.processing_level_id.trim() : null;
        if (this.dialogUploadMetadata.entity.processing_level_id) {
          this.dialogUploadMetadata.entity.processing_level =
            this.processingLevel.items.find(
              (x) =>
                x.id === this.dialogUploadMetadata.entity.processing_level_id
            );
          if (
            this.dialogUploadMetadata.entity.processing_level &&
            Object.keys(this.dialogUploadMetadata.entity.processing_level)
              .length > 0
          ) {
            this.dialogUploadMetadata.entity.processing_level_name =
              this.dialogUploadMetadata.entity.processing_level.value;
          }
        }

        this.dialogUploadMetadata.entity.provider_id = data.provider_id
          ? data.provider_id.trim()
          : null;
        this.dialogUploadMetadata.entity.satellite_id = data.satellite_id
          ? data.satellite_id.trim()
          : null;

        this.dialogUploadMetadata.entity.resolution =
          data.resolution != undefined && data.resolution != null
            ? data.resolution
            : null;
        this.dialogUploadMetadata.entity.satellite_type = data.satellite_type
          ? data.satellite_type
          : null;

        if (
          this.dialogUploadMetadata.entity.satellite_type &&
          Object.keys(this.dialogUploadMetadata.entity.satellite_type).length >
            0
        ) {
          this.dialogUploadMetadata.entity.satellite_id =
            this.dialogUploadMetadata.entity.satellite_type.id;
          this.dialogUploadMetadata.entity.satellite_name =
            this.dialogUploadMetadata.entity.satellite_type.name;
        }
        this.dialogUploadMetadata.entity.size =
          data.size != undefined && data.size != null ? data.size : null;

        this.dialogUploadMetadata.entity.snow_coverage =
          data.snow_coverage != undefined && data.snow_coverage != null
            ? data.snow_coverage
            : null;
        this.dialogUploadMetadata.entity.snow_coverage_unit =
          data.snow_coverage_unit ? data.snow_coverage_unit.trim() : null;

        if (previews && previews.length > 0) {
          this.dialogUploadMetadata.entity.preview_path = previews.find(
            (x) => x.path_image === data.preview_path
          );
        }
        if (
          !this.dialogUploadMetadata.entity.preview_path ||
          Object.keys(this.dialogUploadMetadata.entity.preview_path).length ===
            0
        ) {
          this.dialogUploadMetadata.entity.preview_path = {
            ext: null,
            path_image: data.preview_path ? data.preview_path : null,
            size: null,
            type: null,
          };
        }

        if (icons && icons.length > 0) {
          this.dialogUploadMetadata.entity.icon_path = icons.find(
            (x) => x.path_image === data.icon_path
          );
        }
        if (
          !this.dialogUploadMetadata.entity.icon_path ||
          Object.keys(this.dialogUploadMetadata.entity.icon_path).length === 0
        ) {
          this.dialogUploadMetadata.entity.icon_path = {
            ext: null,
            path_image: data.icon_path ? data.icon_path : null,
            size: null,
            type: null,
          };
        }
        this.dialogUploadMetadata.entity.icon_path_id = data.icon_path;
        this.dialogUploadMetadata.entity.preview_path_id = data.preview_path;

        if (data.image_type)
          this.dialogUploadMetadata.entity.image_type =
            data.image_type && data.image_type.value
              ? data.image_type.value
              : null;
        if (data.metadata && Object.keys(data.metadata).length > 0) {
          this.dialogUploadMetadata.entity.source_id = data.metadata.source_id
            ? data.metadata.source_id.trim()
            : data.metadata.product_id;
          if (
            this.dialogUploadMetadata.entity.area === null ||
            this.dialogUploadMetadata.entity.area === undefined
          ) {
            this.dialogUploadMetadata.entity.area = data.metadata.area;
          }
          if (
            this.dialogUploadMetadata.entity.area_unit === null ||
            this.dialogUploadMetadata.entity.area_unit === undefined
          ) {
            this.dialogUploadMetadata.entity.area_unit =
              data.metadata.area_unit;
          }
          if (
            this.dialogUploadMetadata.entity.band === null ||
            this.dialogUploadMetadata.entity.band === undefined
          ) {
            this.dialogUploadMetadata.entity.band = data.metadata.band;
          }

          if (
            this.dialogUploadMetadata.entity.cloud_coverage === null ||
            this.dialogUploadMetadata.entity.cloud_coverage === undefined
          ) {
            this.dialogUploadMetadata.entity.cloud_coverage =
              data.metadata.cloud_coverage;
          }
          if (
            this.dialogUploadMetadata.entity.cloud_coverage_unit === null ||
            this.dialogUploadMetadata.entity.cloud_coverage_unit === undefined
          ) {
            this.dialogUploadMetadata.entity.cloud_coverage_unit =
              data.metadata.cloud_coverage_unit;
          }

          if (
            this.dialogUploadMetadata.entity.snow_coverage === null ||
            this.dialogUploadMetadata.entity.snow_coverage === undefined
          ) {
            this.dialogUploadMetadata.entity.snow_coverage =
              data.metadata.snow_coverage;
          }
          if (
            this.dialogUploadMetadata.entity.snow_coverage_unit === null ||
            this.dialogUploadMetadata.entity.snow_coverage_unit === undefined
          ) {
            this.dialogUploadMetadata.entity.snow_coverage_unit =
              data.metadata.snow_coverage_unit;
          }

          if (
            this.dialogUploadMetadata.entity.imaging_date === null ||
            this.dialogUploadMetadata.entity.imaging_date === undefined
          ) {
            this.dialogUploadMetadata.entity.imaging_date =
              data.metadata.imaging_date;
            if (this.dialogUploadMetadata.entity.imaging_date) {
              try {
                this.dialogUploadMetadata.entity.imaging_date_timestamp =
                  new Date(
                    this.dialogUploadMetadata.entity.imaging_date
                  ).getTime();
              } catch (error) {}
            }
          }

          if (
            this.dialogUploadMetadata.entity.mode === null ||
            this.dialogUploadMetadata.entity.mode === undefined
          ) {
            this.dialogUploadMetadata.entity.mode = data.metadata.beam_mode;
            this.dialogUploadMetadata.entity.beam_mode =
              data.metadata.beam_mode;
          }

          if (
            this.dialogUploadMetadata.entity.incidence_angle === null ||
            this.dialogUploadMetadata.entity.incidence_angle === undefined
          ) {
            this.dialogUploadMetadata.entity.incidence_angle =
              data.metadata.incidence_angle;
          }
          if (
            this.dialogUploadMetadata.entity.pol_layer === null ||
            this.dialogUploadMetadata.entity.pol_layer === undefined
          ) {
            this.dialogUploadMetadata.entity.pol_layer =
              data.metadata.pol_layer;
          }
          if (
            this.dialogUploadMetadata.entity.direction === null ||
            this.dialogUploadMetadata.entity.direction === undefined
          ) {
            this.dialogUploadMetadata.entity.direction =
              data.metadata.direction;
          }

          if (
            this.dialogUploadMetadata.entity.resolution === null ||
            this.dialogUploadMetadata.entity.resolution === undefined
          ) {
            this.dialogUploadMetadata.entity.resolution =
              data.metadata.resolution;
          }

          if (data.bounding_box) {
            let coors = data.bounding_box;
            if (!this.dialogUploadMetadata.entity.bounding_box)
              this.dialogUploadMetadata.entity.bounding_box = [];
            for (let i = 0; i < coors.length; i++) {
              this.dialogUploadMetadata.entity.bounding_box.push([
                coors[i][0],
                coors[i][1],
              ]);
            }
            if (coors.length === 4) {
              this.dialogUploadMetadata.entity.bounding_box.push([
                coors[0][0],
                coors[0][1],
              ]);
            }
          }
        }
        this.dialogUploadMetadata.entity.cloud_coverage =
          this.dialogUploadMetadata.entity.cloud_coverage != null &&
          this.dialogUploadMetadata.entity.cloud_coverage != undefined &&
          (this.dialogUploadMetadata.entity.cloud_coverage + "").trim() != "-1"
            ? this.dialogUploadMetadata.entity.cloud_coverage
            : null;
        this.dialogUploadMetadata.entity.snow_coverage =
          this.dialogUploadMetadata.entity.snow_coverage != null &&
          this.dialogUploadMetadata.entity.snow_coverage != undefined &&
          (this.dialogUploadMetadata.entity.snow_coverage + "").trim() != "-1"
            ? this.dialogUploadMetadata.entity.snow_coverage
            : null;
      }
    },
    clearEntity() {
      this.dialogUploadMetadata.entity = this.generateEntity();
    },
    async metaFileChange(file) {
      await this.getDataFromXml(file);
      if (
        this.dialogUploadMetadata.entity &&
        Object.keys(this.dialogUploadMetadata.entity).length > 0 &&
        !this.dialogUploadMetadata.entity.file_path
      ) {
        this.dialogUploadMetadata.entity.file_path =
          this.dialogUploadMetadata.value &&
          this.dialogUploadMetadata.value.data
            ? this.dialogUploadMetadata.value.data.path
            : this.dialogUploadMetadata.value.path_image;
      }
    },
    async onUpload() {
      this.dialogUploadMetadata.entity.preview = {
        preview_images: [],
      };
      let previewPath, iconPath, previewUrl, iconUrl;
      let mimeTypePreview;
      let mimeTypeIcon;
      if (this.dialogUploadMetadata.entity.preview_file) {
        mimeTypePreview = getMimeType(
          this.dialogUploadMetadata.entity.preview_file.name
            .split(".")
            .pop()
            .toLowerCase()
        );
        previewPath =
          "previews/" +
          CryptoJS.MD5(
            this.dialogUploadMetadata.entity.preview_file.name +
              Math.floor(Math.random() * 9999) +
              new Date().getTime()
          ).toString();
        try {
          let responsePreview = await imageFunc.createLinkPreview(
            mimeTypePreview && mimeTypePreview.type
              ? mimeTypePreview.type
              : this.dialogUploadMetadata.entity.preview_file.type
              ? this.dialogUploadMetadata.entity.preview_file.type
              : "image/jpg",
            previewPath,
            this.$store.getters.getAccessToken
          );
          if (responsePreview && responsePreview.status === 200) {
            previewUrl = responsePreview.data ? responsePreview.data.url : null;
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            this.refreshToken(this.onUpload);
            return;
          }
        }
      }
      if (this.dialogUploadMetadata.entity.icon_file) {
        mimeTypeIcon = getMimeType(
          this.dialogUploadMetadata.entity.icon_file.name
            .split(".")
            .pop()
            .toLowerCase()
        );
        iconPath =
          "previews/" +
          CryptoJS.MD5(
            this.dialogUploadMetadata.entity.icon_file.name +
              Math.floor(Math.random() * 9999) +
              new Date().getTime()
          ).toString();
        try {
          let responseIcon = await imageFunc.createLinkPreview(
            mimeTypeIcon && mimeTypeIcon.type
              ? mimeTypeIcon.type
              : this.dialogUploadMetadata.entity.icon_file.type
              ? this.dialogUploadMetadata.entity.icon_file.type
              : "image/jpg",
            iconPath,
            this.$store.getters.getAccessToken
          );
          if (responseIcon && responseIcon.status === 200) {
            iconUrl = responseIcon.data ? responseIcon.data.url : null;
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            this.refreshToken(this.onUpload);
            return;
          }
        }
      }
      if (previewUrl) {
        try {
          let response = await imageFunc.uploadFilePresignedUrl(
            previewUrl,
            this.dialogUploadMetadata.entity.preview_file,
            mimeTypePreview && mimeTypePreview.type
              ? mimeTypePreview.type
              : this.dialogUploadMetadata.entity.preview_file.type
              ? this.dialogUploadMetadata.entity.preview_file.type
              : "image/jpg"
          );
          if (response && response.status === 200) {
            this.dialogUploadMetadata.entity.icon_path = previewPath;
            this.dialogUploadMetadata.entity.preview.preview_path = previewPath;
            this.dialogUploadMetadata.entity.preview.preview_images.push({
              type: "PREVIEW",
              path: previewPath,
              size: this.dialogUploadMetadata.entity.preview_file.size,
              ext: this.dialogUploadMetadata.entity.preview_file.name
                .split(".")
                .pop()
                .toLowerCase(),
            });
          }
        } catch (error) {}
      }
      if (iconUrl) {
        try {
          let response = await imageFunc.uploadFilePresignedUrl(
            iconUrl,
            this.dialogUploadMetadata.entity.icon_file,
            mimeTypeIcon && mimeTypeIcon.type
              ? mimeTypeIcon.type
              : this.dialogUploadMetadata.entity.icon_file.type
              ? this.dialogUploadMetadata.entity.icon_file.type
              : "image/jpg"
          );
          if (response && response.status === 200) {
            this.dialogUploadMetadata.entity.icon_path = iconPath;
            this.dialogUploadMetadata.entity.preview.icon_path = iconPath;
            this.dialogUploadMetadata.entity.preview.preview_images.push({
              type: "ICON",
              path: iconPath,
              size: this.dialogUploadMetadata.entity.icon_file.size,
              ext: this.dialogUploadMetadata.entity.icon_file.name
                .split(".")
                .pop()
                .toLowerCase(),
            });
          }
        } catch (error) {}
      }
      if (this.dialogUploadMetadata.entity.processing_level_name) {
        this.dialogUploadMetadata.entity.processing_level =
          this.processingLevel.items.find(
            (x) =>
              x.name === this.dialogUploadMetadata.entity.processing_level_name
          );
        if (
          this.dialogUploadMetadata.entity.processing_level &&
          Object.keys(this.dialogUploadMetadata.entity.processing_level)
            .length > 0
        ) {
          this.dialogUploadMetadata.entity.processing_level_id =
            this.dialogUploadMetadata.entity.processing_level.id;
        }
      }
      this.addImage2Server(this.dialogUploadMetadata.entity);
    },
    async addImage2Server(entity) {
      this.dialogUploadMetadata.button.disabled = true;
      this.dialogUploadMetadata.button.processing = true;
      if (entity.imaging_date_timestamp) {
        try {
          entity.imaging_date = new Date(entity.imaging_date_timestamp);
        } catch (error) {}
      }
      if (entity.metadata && Object.keys(entity.metadata).length > 0) {
        entity.metadata.incidence_angle = entity.incidence_angle;
        entity.metadata.resolution = entity.resolution;
        entity.metadata.cloud_coverage = entity.cloud_coverage;
        entity.metadata.cloud_coverage_unit = entity.cloud_coverage_unit;
        entity.metadata.snow_coverage = entity.snow_coverage;
        entity.metadata.snow_coverage_unit = entity.snow_coverage_unit;
        entity.metadata.area = entity.area;
        entity.metadata.area_unit = entity.area_unit;
        entity.metadata.mode = entity.mode;
        entity.metadata.beam_mode = entity.mode;
        entity.metadata.direction = entity.direction;
        entity.metadata.pol_layer = entity.pol_layer;
        entity.metadata.imaging_date = entity.imaging_date;
      }
      let dto = Object.assign({}, entity);
      if (dto.preview_file) delete dto.preview_file;
      dto.preview_path = dto.preview.preview_path;
      if (dto.preview_src) delete dto.preview_src;
      if (dto.icon_file) delete dto.icon_file;
      dto.icon_path = dto.preview.icon_path;
      if (dto.icon_src) delete dto.icon_src;
      dto.preview_images = dto.preview.preview_images;
      try {
        if (dto.bounding_box && dto.bounding_box.length > 0) {
          for (let i = 0; i < dto.bounding_box.length; i++) {
            for (let j = 0; j < dto.bounding_box[i].length; j++) {
              if (!dto.bounding_box[i][j]) {
                dto.bounding_box[i][j] = parseFloat("0.0");
              }
            }
          }
        }
      } catch (error) {}
      try {
        let response = await imageFunc.create(
          dto,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success) {
            let idx = this.uploadHistory.items.findIndex(
              (x) =>
                this.dialogUploadMetadata.value &&
                x.id === this.dialogUploadMetadata.value.id
            );
            if (idx >= 0) {
              this.uploadHistory.items[idx].data.is_metadata = true;
              this.uploadHistory.items[idx].data_image = response.data.data;
              this.uploadHistory.items[idx].processing = false;
              this.uploadHistory.items[idx].message = [];
            }
            this.$toast.success({
              title: this.$t("title_message_info"),
              message: this.$t("message_additional_information_success", null, {
                name: entity.name,
              }),
              position: "top right",
            });
            if (entity.project_ids && entity.project_ids.length > 0) {
              for (let i = 0; i < entity.project_ids.length; i++) {
                this.addImage2Project({
                  project_id: entity.project_ids[i],
                  image_id: response.data.data ? response.data.data.id : null,
                });
              }
            }
            this.dialogUploadMetadata.show = false;
            this.dialogUploadMetadata.value = null;

            setTimeout(() => {
              this.dialogUploadMetadata.entity = {};
            }, 200);
          } else {
            this.message.push(
              this.$t("message_add_image_to_server_error", null, {
                name: entity.name,
              })
            );
          }
        } else {
          this.message.push(
            this.$t("message_add_image_to_server_error", null, {
              name: entity.name,
            })
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.addImage2Server, entity);
        } else {
          let msg =
            error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push(
            msg
              ? msg
              : this.$t("message_add_image_to_server_error", null, {
                  name: entity.name,
                })
          );
        }
      }
      this.dialogUploadMetadata.button.disabled = false;
      this.dialogUploadMetadata.button.processing = false;
    },

    async addImage2Project(data) {
      try {
        let response = await projectFunc.addImage(
          data.project_id,
          data.image_id,
          this.$store.getters.getAccessToken
        );
      } catch (error) {
        if (error.response && error.response.status === 401)
          this.refreshToken(this.addImage2Project, data);
      }
    },

    async getHistory() {
      this.uploadHistory.loading = true;
      try {
        let response = await logFunc.getLogs(
          {
            action: "UPLOAD_IMAGE",
            create_from: this.uploadHistory.search.create_from
              ? new Date(this.uploadHistory.search.create_from)
              : null,
            create_to: this.uploadHistory.search.create_to
              ? new Date(this.uploadHistory.search.create_to)
              : null,
            user_id: this.uploadHistory.search.user_id,
            page: 0,
            size: 2147483647,
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.uploadHistory.loading = false;
          this.uploadHistory.total_page = response.data.total_page
            ? response.data.total_page
            : 0;
          this.uploadHistory.total_records = response.data.total_records
            ? response.data.total_records
            : 0;
          if (response.data && response.data.content_page) {
            let dataResponse = response.data.content_page;
            dataResponse = dataResponse.filter(
              (x) => x.action.toLowerCase() === "UPLOAD_IMAGE".toLowerCase()
            );
            dataResponse.sort(function (a, b) {
              if (a.created_at < b.created_at) return 1;
              else if (a.created_at > b.created_at) return -1;
              return 0;
            });
            for (let i = 0; i < dataResponse.length; i++) {
              let dateStr = new Date(dataResponse[i].created_at);
              let obj = Object.assign({}, dataResponse[i]);
              obj.date_str =
                (dateStr.getDate() < 10
                  ? "0" + dateStr.getDate()
                  : dateStr.getDate()) +
                "/" +
                (dateStr.getMonth() + 1 < 10
                  ? "0" + (dateStr.getMonth() + 1)
                  : dateStr.getMonth() + 1) +
                "/" +
                dateStr.getFullYear();
              if (!obj.data.is_metadata) {
                obj.processing = false;
                obj.message = [];
              }
              obj.data_image = null;
              this.uploadHistory.items.push(obj);

              this.uploadHistory.items.sort(function (a, b) {
                if (
                  new Date(a.created_at).getTime() <
                  new Date(b.created_at).getTime()
                )
                  return 1;
                else if (
                  new Date(a.created_at).getTime() >
                  new Date(b.created_at).getTime()
                )
                  return -1;
                return 0;
              });
            }
          }
        }
      } catch (error) {
        this.uploadHistory.loading = false;
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.getHistory);
        }
      }
      this.uploadHistory.loading = false;
    },
    async getAllUser() {
      try {
        let response = await userFunc.search(
          {
            page: 0,
            size: 2147483647,
            order: "full_name",
            sort: "asc",
          },
          {
            is_active: null,
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.user.items = response.data.content_page
            ? response.data.content_page.filter((x) => x.is_active)
            : [];
          this.user.items.sort(function (a, b) {
            if (a.full_name < b.full_name) return -1;
            else if (a.full_name > b.full_name) return 1;
            return 0;
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.getAllUser);
        }
      }
    },
    async getAllProject() {
      this.project.items = [];
      try {
        let response = await projectFunc.getAll(
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.project.items =
            response.data && response.data.content_page
              ? response.data.content_page.filter((x) => !x.is_delete)
              : [];
          //this.project.tree = this.listProjectToTree(this.project.items);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllProject);
        }
      }
    },
    async getAllBeamMode() {
      try {
        let response = await metaKeyFunc.getByKey(
          "BEAM_MODE",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success) {
            this.beamMode.items =
              response && response.data && response.data.data
                ? response.data.data
                : [];
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllBeamMode);
        }
      }
    },
    async getAllDirection() {
      try {
        let response = await metaKeyFunc.getByKey(
          "DIRECTION",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success) {
            this.direction.items =
              response && response.data && response.data.data
                ? response.data.data
                : [];
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllDirection);
        }
      }
    },
    async getAllPolLayer() {
      try {
        let response = await metaKeyFunc.getByKey(
          "POL_LAYER",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success) {
            this.polLayer.items =
              response && response.data && response.data.data
                ? response.data.data
                : [];
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllPolLayer);
        }
      }
    },
    async getAllCategoryDataList() {
      try {
        let response = await metaKeyFunc.getByKey(
          "CATEGORY",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success) {
            this.categoryDataList.items =
              response && response.data && response.data.data
                ? response.data.data
                : [];
            if (
              this.categoryDataList.items &&
              this.categoryDataList.items.length > 0
            )
              this.categoryId = this.categoryDataList.items[0].id;
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllCategoryDataList);
        }
      }
    },
    async getAllProcessingLevel() {
      try {
        let response = await metaKeyFunc.getByKey(
          "PROCESSING_LEVEL",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success) {
            this.processingLevel.items =
              response && response.data && response.data.data
                ? response.data.data
                : [];
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllProcessingLevel);
        }
      }
    },
    async getAllProvider() {
      try {
        let response = await metaKeyFunc.getByKey(
          "PROVIDER",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.provider.items =
            response && response.data && response.data.data
              ? response.data.data
              : [];
          if (this.provider.items && this.provider.items.length > 0) {
            for (let i = 0; i < this.provider.items.length; i++) {
              if (
                this.provider.items[i].sub_meta_key &&
                this.provider.items[i].sub_meta_key.length > 0
              ) {
                let children = [];
                for (
                  let j = 0;
                  j < this.provider.items[i].sub_meta_key.length;
                  j++
                ) {
                  children.push({
                    id: this.provider.items[i].sub_meta_key[j].id,
                    key: this.provider.items[i].sub_meta_key[j].id,
                    name: this.provider.items[i].sub_meta_key[j].name,
                    code: this.provider.items[i].sub_meta_key[j].name,
                    label: this.provider.items[i].sub_meta_key[j].value,
                  });
                }
                this.provider.tree.push({
                  id: this.provider.items[i].id,
                  key: this.provider.items[i].id,
                  name: this.provider.items[i].name,
                  code: this.provider.items[i].name,
                  label: this.provider.items[i].value,
                  children: children,
                });
              }
            }
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllProvider);
        }
      }
    },

    async getAllModel() {
      try {
        let response = await processingDataFunc.getAllModel(
          {
            name: null,
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.dataSpecification.items =
            response.data && response.data.content_page
              ? response.data.content_page
              : [];

          this.dataSpecification.items.sort(function (a, b) {
            if (a.name < b.name) return -1;
            else if (a.name > b.name) return 1;
            return 0;
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.getAllModel, null);
        }
      }
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    tableHeight() {
      return this.documentHeight - 56 - 48 - 105 - 38 - 1 * 4 - 4;
    },
  },
};
</script>
<style lang="scss" scoped>
.upload-history {
  padding: 0.5rem;
  .upload-history__search {
    padding: 0;
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .upload-history__datatable {
    padding: 0;
    margin-top: 0.5rem;
    .accordion {
      border-radius: 0.5rem;
    }
  }
}
</style>
