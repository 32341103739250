<template>
  <main-layout
    :isToolbar="false"
    :isBreadcrumb="true"
    :breadcrumbitems="[
      { label: $t('menu_order_management') },
      { label: $t('menu_order_tasking') },
    ]"
    :propButtonsState="{
      save: {
        visible: false,
      },
      add: {
        visible: false,
      },
      edit: {
        visible: false,
      },
      reload: buttons.reload,
      print: {
        visible: false,
      },
    }"
    :propTitle="$t('menu_order_tasking')"
  >
    <div class="order-content">
      <div class="order__dialog">
        <DialogOrderDetailEgeo
          v-if="dlgOrderDetailEgeo.show"
          :prop-style="{ width: dlgOrderDetailEgeo.width }"
          :prop-show="dlgOrderDetailEgeo.show"
          :prop-title="dlgOrderDetailEgeo.title"
          :prop-item="dlgOrderDetailEgeo.value"
          :propGranularities="granularity.items"
          :propLookDirection="lookDirection.items"
          :propOrbitDirection="orbitDirection.items"
          :propProgramingServices="programingService.items"
          :propSatelliteType="satelliteType.items"
          @close="
            dlgOrderDetailEgeo.show = false;
            dlgOrderDetailEgeo.value = null;
          "
          @getAllDirection="getAllDirection"
          @getAllTaskingProgrammingService="getAllTaskingProgrammingService"
          @getAllTaskingGranularity="getAllTaskingGranularity"
          @getAllSatelliteType="getAllSatelliteType"
        />

        <DialogImageList
          v-if="dlgImageList.show"
          :prop-style="{ width: dlgImageList.width }"
          :prop-show="dlgImageList.show"
          :prop-title="dlgImageList.title"
          :prop-items="dlgImageList.value.images"
          :propImageTypes="satelliteType.items"
          @close="
            dlgImageList.show = false;
            dlgImageList.value = null;
          "
          @getAllSatelliteType="getAllSatelliteType"
          @showDialogMetadata="showDialogMetadata"
        />

        <DialogMetadata
          v-if="dialogMetadata.show"
          :prop-style="{ width: dialogMetadata.width }"
          :prop-show="dialogMetadata.show"
          :prop-title="dialogMetadata.title"
          :prop-item="dialogMetadata.value"
          @close="
            dialogMetadata.show = false;
            dialogMetadata.value = null;
          "
        />

        <DialogUpdateStatus
          v-if="dlgUpdateStatus.show"
          :prop-style="{
            width: dlgUpdateStatus.width,
            overflowY: 'inherit!important',
          }"
          :prop-show="dlgUpdateStatus.show"
          :prop-title="dlgUpdateStatus.title"
          :prop-tab-id="
            dlgUpdateStatus.value.current_status
              ? dlgUpdateStatus.value.current_status.id
              : null
          "
          :prop-button="buttons.update_status"
          :prop-item="dlgUpdateStatus.value"
          :propOrderStatus="orderStatus.items"
          @close="
            dlgUpdateStatus.show = false;
            dlgUpdateStatus.value = null;
          "
          @onUpdateStatus="onUpdateStatus"
        />

        <DialogRemark
          v-if="dlgDialogRemark.show"
          :prop-style="{
            width: dlgDialogRemark.width,
          }"
          :prop-show="dlgDialogRemark.show"
          :prop-title="dlgDialogRemark.title"
          :prop-value="dlgDialogRemark.value"
          @close="
            dlgDialogRemark.show = false;
            dlgDialogRemark.value = null;
            dlgDialogRemark.title = null;
          "
        />

        <DialogUploadImages
          v-if="dialogUploadFile.show"
          ref="dlgUploadImages"
          :prop-style="{ width: dialogUploadFile.width }"
          :prop-show="dialogUploadFile.show"
          :prop-title="dialogUploadFile.title"
          :prop-value="dialogUploadFile.value"
          @close="
            dialogUploadFile.show = false;
            dialogUploadFile.value = null;
            files = [];
            filesUploaded.success = [];
            filesUploaded.error = [];
          "
          @uploadFiles="onUploadFiles"
          @message="message = $event"
        />
        <DialogAddImages
          v-if="dlgAddImages.show"
          :prop-show="dlgAddImages.show"
          :prop-images="dlgAddImages.value.images"
          :prop-style="{
            width: dlgAddImages.width,
          }"
          @close="
            dlgAddImages.show = false;
            dlgAddImages.value = null;
          "
          @selected="onUpdateImagesAdded"
        />
        <DialogMessage
          v-if="message.length > 0"
          :propTitle="$t('title_message_info')"
          :prop-show="message.length > 0"
          :prop-message="message"
          @close="message = []"
        />
      </div>
      <div class="order__search" id="elementSearch">
        <OrderSearch
          :prop-user="user.items"
          :prop-order-status="orderStatus.items"
          :prop-search="order.search"
          :propButtons="buttons"
          @onSearch="onSearch"
          @message="message = $event"
          @accordionHeaderClick="accordionHeaderClick"
        ></OrderSearch>
      </div>
      <div class="order__datatable">
        <OrderDataTable
          :prop-items="order.items"
          :prop-total="order.total_records"
          :prop-loading="order.loading"
          :prop-table="order.table"
          :propButtonDetail="order.table.buttons.detail"
          @nextPage="nextPage"
          @showOrderDetail="showOrderDetail"
          @showImageList="showImageList"
          @shoDialogUpdateStatus="shoDialogUpdateStatus"
          @shoDialogRemark="shoDialogRemark"
          @shoDialogUpload="shoDialogUpload"
          @shoDialogAddImages="shoDialogAddImages"
        />
      </div>
    </div>
  </main-layout>
</template>
<script>
import MainLayout from "@/components/pages/layouts/admin/Main";
import CommonAccordion from "@/components/commons/accordion/Accordion";
import OrderSearch from "./Search";
import OrderDataTable from "./DataTable";
import DialogMessage from "@/components/commons/dialog/MessageBox";
import DialogOrderDetailEgeo from "./detail/DialogOrderDetailEgeo.vue";
import DialogUpdateStatus from "./detail/DialogUpdateStatus.vue";
import DialogRemark from "./detail/DialogRemark.vue";
import DialogUploadImages from "./detail/DialogUpload.vue";
import DialogImageList from "./detail/DialogImageList.vue";
import DialogAddImages from "./detail/AddImages.vue";
import DialogMetadata from "@/components/commons/metadata/Metadata";
import orderFunc from "@/utils/functions/order";
import userFunc from "@/utils/functions/user";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import metaKeyFunc from "@/utils/functions/metakey";
import fileFunc from "@/utils/functions/file";
import imageFunc from "@/utils/functions/image";
import { getMimeType } from "@/utils/commons/mimetypes";
import { minioPrefix } from "@/utils/commons/environment";
import projectFunc from "@/utils/functions/project";
export default {
  components: {
    MainLayout,
    CommonAccordion,
    OrderSearch,
    OrderDataTable,
    DialogMessage,
    DialogOrderDetailEgeo,
    DialogUpdateStatus,
    DialogRemark,
    DialogUploadImages,
    DialogImageList,
    DialogMetadata,
    DialogAddImages,
  },
  data() {
    return {
      message: [],
      files: [],
      filesUploaded: {
        success: [],
        error: [],
      },
      order: {
        search: {
          size: 15,
          page: 0,
          user_id: null,
          status_id: null,
          create_from: null,
          create_to: null,
        },
        items: [],
        is_bottom: false,
        loading: false,
        table: {
          selected: null,
          height: 400,
          buttons: {
            detail: {
              disable: false,
              processing: false,
              id: null,
            },
            update_status: {
              disable: false,
              processing: false,
              id: null,
            },
          },
        },
      },
      user: {
        items: [],
      },
      orderStatus: {
        items: [],
        data: [],
      },
      buttons: {
        filter: {
          disabled: false,
          processing: false,
          visible: true,
        },
        reload: {
          disabled: false,
          processing: false,
          visible: true,
        },
        update_status: {
          disabled: false,
          processing: false,
        },
      },

      dlgUpdateStatus: {
        show: false,
        title: this.$t("title_update_status"),
        value: null,
        width: "540px",
      },
      dlgOrderDetailEgeo: {
        show: false,
        title: this.$t("header_detail"),
        value: null,
        width: "480px",
      },
      dlgImageList: {
        show: false,
        title: this.$t("title_image_list"),
        value: null,
        width: "480px",
      },
      dlgDialogRemark: {
        show: false,
        title: null,
        value: null,
        width: "540px",
      },
      dialogUploadFile: {
        show: false,
        title: this.$t("title_upload_images"),
        value: null,
        width: "480px",
      },
      dialogMetadata: {
        show: false,
        title: "Metadata",
        value: null,
        width: "540px",
      },
      dlgAddImages: {
        show: false,
        title: this.$t("title_add_images"),
        value: null,
        width: "540px",
      },

      granularity: {
        items: [],
      },
      programingService: {
        items: [],
      },
      lookDirection: {
        items: [],
      },
      orbitDirection: {
        items: [],
      },
      satelliteType: {
        items: [],
      },
    };
  },
  created() {
    this.order.search.size = Math.ceil(this.tableHeight / 45) + 5;
    this.getAllOrder(true);
    this.getAllCustomer();
    this.getAllOrderStatus();
    var vm = this;
    if (vm.documentWidth < 576) {
      this.dlgOrderDetailEgeo.width = this.documentWidth + "px";
      this.dlgUpdateStatus.width = this.documentWidth + "px";
      this.dlgDialogRemark.width = this.documentWidth + "px";
      this.dialogUploadFile.width = this.documentWidth + "px";
      this.dlgImageList.width = this.documentWidth + "px";
      this.dialogMetadata.width = this.documentWidth + "px";
      this.dlgAddImages.width = this.documentWidth + "px";
    } else if (vm.documentWidth >= 576 && vm.documentWidth < 768) {
      this.dlgOrderDetailEgeo.width = "540px";
      this.dlgUpdateStatus.width = "480px";
      this.dlgDialogRemark.width = "480px";
      this.dialogUploadFile.width = "540px";
      this.dlgImageList.width = "540px";
      this.dialogMetadata.width = "540px";
      this.dlgAddImages.width = "540px";
    } else if (vm.documentWidth >= 768 && vm.documentWidth < 992) {
      this.dlgOrderDetailEgeo.width = "720px";
      this.dlgUpdateStatus.width = "480px";
      this.dlgDialogRemark.width = "480px";
      this.dialogUploadFile.width = "720px";
      this.dlgImageList.width = "720px";
      this.dialogMetadata.width = "720px";
      this.dlgAddImages.width = "720px";
    } else {
      this.dlgOrderDetailEgeo.width = "960px";
      this.dlgUpdateStatus.width = "480px";
      this.dlgDialogRemark.width = "480px";
      this.dialogUploadFile.width = "960px";
      this.dlgImageList.width = "960px";
      this.dialogMetadata.width = "960px";
      this.dlgAddImages.width = "960px";
    }
  },
  watch: {
    lang(val) {
      this.getOrderStatusByLang();
    },
  },
  mounted() {
    setTimeout(() => {
      this.order.table.height = this.calcTableHeight();
    }, 100);
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    tableHeight() {
      return this.documentHeight - 56 - 104 - 38;
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
  methods: {
    /*---------------- Emit ----------------*/
    shoDialogAddImages(item) {
      this.dlgAddImages.value = item;
      this.dlgAddImages.show = true;
    },
    showDialogMetadata(item) {
      this.dialogMetadata.value = item;
      this.dialogMetadata.show = true;
    },
    showImageList(item) {
      this.dlgImageList.value = item;
      this.dlgImageList.show = true;
    },
    showOrderDetail(item) {
      this.showOrderDetailEgeo(item);
    },
    accordionHeaderClick() {
      setTimeout(() => {
        this.order.table.height = this.calcTableHeight();
      }, 100);
    },
    calcTableHeight() {
      let element = document.getElementById("elementSearch");
      return (
        this.documentHeight -
        3 * 0.5 * 16 -
        56 -
        (element ? element.offsetHeight : 0) -
        24 -
        2 * 0.5 * 16
      );
    },
    async menuBarFunction(val) {
      switch (val.code) {
        case "FILTER":
          break;
        case "RELOAD":
          this.buttons.filter.disabled = true;
          this.buttons.filter.processing = true;
          this.buttons.reload.processing = true;
          this.buttons.reload.disabled = true;
          await this.onReload();
          this.buttons.reload.processing = false;
          this.buttons.reload.disabled = false;
          this.buttons.filter.disabled = false;
          this.buttons.filter.processing = false;
          break;

        default:
          break;
      }
    },

    nextPage() {
      if (!this.order.is_bottom) {
        this.order.search.page = this.order.search.page + 1;
        this.getAllOrder();
      }
    },
    onSearch(data, loading) {
      this.order.search.create_from = data.create_from
        ? data.create_from
        : null;
      this.order.search.create_to = data.create_to ? data.create_to : null;
      this.order.search.user_id = data.user_id ? data.user_id : null;
      this.order.search.status_id = data.status_id ? data.status_id : null;
      this.order.items = [];
      this.order.page = 0;
      this.order.is_bottom = false;
      this.getAllOrder(loading);
    },

    async onReload() {
      this.order.items = [];
      this.order.page = 0;
      this.order.is_bottom = false;
      await this.getAllOrder();
    },
    shoDialogUpload(item) {
      this.dialogUploadFile.value = item;
      this.dialogUploadFile.show = true;
    },
    shoDialogRemark(data) {
      this.dlgDialogRemark.value = data.remark;
      this.dlgDialogRemark.title = data.title;
      this.dlgDialogRemark.show = true;
    },
    shoDialogUpdateStatus(item) {
      this.dlgUpdateStatus.value = item;
      this.dlgUpdateStatus.show = true;
    },
    async onUploadFiles(data) {
      this.files = Object.assign([], data);
      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].status != "SUCCESS") {
          if (!["SUCCESS", "PROCESS", "ERROR"].includes(this.files[i].status)) {
            this.files[i].status = "PROCESS";
            if (this.$refs.dlgUploadImages) {
              this.$refs.dlgUploadImages.updateStatus(this.files[i]);
            }
          }
          for (let j = 0; j < this.files[i].files.length; j++) {
            if (this.files[i].files[j].status != "SUCCESS") {
              this.uploadFileTest({
                id: this.files[i].id,
                file: this.files[i].files[j],
              });
            }
          }
        }
      }
    },

    async uploadFileTest(data) {
      var vm = this;
      let index = this.files.findIndex((x) => x.id === data.id);
      try {
        // let formData = new FormData();
        // formData.append("file", data.file, data.file.name);
        setTimeout(() => {
          Event.$emit("imageProgressBar", vm.files[index]);
        }, 100);
        // let responseGetUrl = await fileFunc.generateUrlUpload(
        //   this.$store.getters.getUserName + "/" + data.file.name,
        //   this.$store.getters.getAccessToken
        // );

        let mimeType = getMimeType(
          data.file.name
            .split(".")
            .pop()
            .toLowerCase()
        );
        let responseGetUrl = await imageFunc.uploadImage(
          mimeType ? mimeType.type : "application/zip",
          this.$store.getters.getUserName + "/" + data.id, //data.file.name,
          this.$store.getters.getAccessToken
        );
        if (responseGetUrl && responseGetUrl.status === 200) {
          if (responseGetUrl.data && responseGetUrl.data.url) {
            let res = await fileFunc.uploadFilePresignedUrl(
              responseGetUrl.data.url,
              data.file,
              mimeType ? mimeType.type : "application/zip"
            );
            if (res && res.status === 200) {
              vm.$toast.success({
                title: vm.$t("title_message_info"),
                message: vm.$t("message_upload_file_success", null, {
                  name: data.file.name,
                }),
                position: "top right",
              });
              if (index >= 0) {
                this.files[index].status = "SUCCESS";
                if (this.$refs.dlgUploadImages) {
                  this.$refs.dlgUploadImages.updateStatus(this.files[index]);
                }
                this.getMetadata(vm.files[index]);
              }
            } else {
              this.filesUploaded.error.push(data.id);
              if (index >= 0) {
                this.files[index].status = "ERROR";
                if (this.$refs.dlgUploadImages) {
                  this.$refs.dlgUploadImages.updateStatus(this.files[index]);
                }
              }

              setTimeout(() => {
                if (
                  vm.files.filter(
                    (x) => x.status === "SUCCESS" || x.status === "ERROR"
                  ).length === vm.files.length
                ) {
                  if (
                    this.filesUploaded.success.filter((x) => x != null).length >
                    0
                  ) {
                    this.onUpdateImagesOrder({
                      image_ids: [
                        ...this.filesUploaded.success,
                        ...(this.dialogUploadFile.value.images
                          ? this.dialogUploadFile.value.images.map((x) => x.id)
                          : []),
                      ],
                      order_id: this.dialogUploadFile.value
                        ? this.dialogUploadFile.value.id
                        : null,
                    });
                  }
                }
              }, 300);

              vm.$toast.error({
                title: vm.$t("title_message_info"),
                message: vm.$t("message_upload_file_error", null, {
                  name: data.file.name,
                }),
                position: "top right",
              });
            }
            let progressValue = Math.ceil(
              (this.files.filter(
                (x) => x.status === "SUCCESS" || x.status === "ERROR"
              ).length *
                100) /
                this.files.length
            );
            if (this.$refs.dlgUploadImages) {
              this.$refs.dlgUploadImages.updateRemainingTime({
                progressValue: progressValue,
              });
            }
          } else {
            this.filesUploaded.error.push(data.id);
            if (index >= 0) {
              this.files[index].status = "ERROR";
              if (this.$refs.dlgUploadImages) {
                this.$refs.dlgUploadImages.updateStatus(this.files[index]);
              }
            }

            setTimeout(() => {
              if (
                vm.files.filter(
                  (x) => x.status === "SUCCESS" || x.status === "ERROR"
                ).length === vm.files.length
              ) {
                if (
                  this.filesUploaded.success.filter((x) => x != null).length > 0
                ) {
                  this.onUpdateImagesOrder({
                    image_ids: [
                      ...this.filesUploaded.success,
                      ...(this.dialogUploadFile.value.images
                        ? this.dialogUploadFile.value.images.map((x) => x.id)
                        : []),
                    ],
                    order_id: this.dialogUploadFile.value
                      ? this.dialogUploadFile.value.id
                      : null,
                  });
                }
              }
            }, 300);

            let progressValue = Math.ceil(
              (this.files.filter(
                (x) => x.status === "SUCCESS" || x.status === "ERROR"
              ).length *
                100) /
                this.files.length
            );
            if (this.$refs.dlgUploadImages) {
              this.$refs.dlgUploadImages.updateRemainingTime({
                progressValue: progressValue,
              });
            }
            vm.$toast.error({
              title: vm.$t("title_message_info"),
              message: vm.$t("message_upload_file_error", null, {
                name: data.file.name,
              }),
              position: "top right",
            });
          }
        } else {
          this.filesUploaded.error.push(data.id);
          if (index >= 0) {
            this.files[index].status = "ERROR";
            if (this.$refs.dlgUploadImages) {
              this.$refs.dlgUploadImages.updateStatus(this.files[index]);
            }
          }

          setTimeout(() => {
            if (
              vm.files.filter(
                (x) => x.status === "SUCCESS" || x.status === "ERROR"
              ).length === vm.files.length
            ) {
              if (
                this.filesUploaded.success.filter((x) => x != null).length > 0
              ) {
                this.onUpdateImagesOrder({
                  image_ids: [
                    ...this.filesUploaded.success,
                    ...(this.dialogUploadFile.value.images
                      ? this.dialogUploadFile.value.images.map((x) => x.id)
                      : []),
                  ],
                  order_id: this.dialogUploadFile.value
                    ? this.dialogUploadFile.value.id
                    : null,
                });
              }
            }
          }, 300);

          let progressValue = Math.ceil(
            (this.files.filter(
              (x) => x.status === "SUCCESS" || x.status === "ERROR"
            ).length *
              100) /
              this.files.length
          );
          if (this.$refs.dlgUploadImages) {
            this.$refs.dlgUploadImages.updateRemainingTime({
              progressValue: progressValue,
            });
          }
          vm.$toast.error({
            title: vm.$t("title_message_info"),
            message: vm.$t("message_upload_file_error", null, {
              name: data.file.name,
            }),
            position: "top right",
          });
        }
        /*
        let response = await fileFunc.putObject(
          formData,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success) {
            vm.$toast.success({
              title: vm.$t("title_message_info"),
              message: vm.$t("message_upload_file_success", null, {
                name: data.file.name,
              }),
              position: "top right",
            });
            if (index >= 0) {
              this.files[index].status = "SUCCESS";
              if (this.$refs.dlgUploadImages) {
                this.$refs.dlgUploadImages.updateStatus(this.files[index]);
              }
              this.getMetadata(vm.files[index]);
            }
          } else {
            this.filesUploaded.error.push(data.id);
            if (index >= 0) {
              this.files[index].status = "ERROR";
              if (this.$refs.dlgUploadImages) {
                this.$refs.dlgUploadImages.updateStatus(this.files[index]);
              }
            }

            setTimeout(() => {
              if (
                vm.files.filter(
                  (x) => x.status === "SUCCESS" || x.status === "ERROR"
                ).length === vm.files.length
              ) {
                if (
                  this.filesUploaded.success.filter((x) => x != null).length > 0
                ) {
                  this.onUpdateImagesOrder({
                    image_ids: this.filesUploaded.success,
                    order_id: this.dialogUploadFile.value
                      ? this.dialogUploadFile.value.id
                      : null,
                  });
                }
              }
            }, 300);

            vm.$toast.error({
              title: vm.$t("title_message_info"),
              message: vm.$t("message_upload_file_error", null, {
                name: data.file.name,
              }),
              position: "top right",
            });
          }
          let progressValue = Math.ceil(
            (this.files.filter(
              (x) => x.status === "SUCCESS" || x.status === "ERROR"
            ).length *
              100) /
              this.files.length
          );
          if (this.$refs.dlgUploadImages) {
            this.$refs.dlgUploadImages.updateRemainingTime({
              progressValue: progressValue,
            });
          }
        } else {
          this.filesUploaded.error.push(data.id);
          if (index >= 0) {
            this.files[index].status = "ERROR";
            if (this.$refs.dlgUploadImages) {
              this.$refs.dlgUploadImages.updateStatus(this.files[index]);
            }
          }

          setTimeout(() => {
            if (
              vm.files.filter(
                (x) => x.status === "SUCCESS" || x.status === "ERROR"
              ).length === vm.files.length
            ) {
              if (
                this.filesUploaded.success.filter((x) => x != null).length > 0
              ) {
                this.onUpdateImagesOrder({
                  image_ids: this.filesUploaded.success,
                  order_id: this.dialogUploadFile.value
                    ? this.dialogUploadFile.value.id
                    : null,
                });
              }
            }
          }, 300);

          let progressValue = Math.ceil(
            (this.files.filter(
              (x) => x.status === "SUCCESS" || x.status === "ERROR"
            ).length *
              100) /
              this.files.length
          );
          if (this.$refs.dlgUploadImages) {
            this.$refs.dlgUploadImages.updateRemainingTime({
              progressValue: progressValue,
            });
          }
          vm.$toast.error({
            title: vm.$t("title_message_info"),
            message: vm.$t("message_upload_file_error", null, {
              name: data.file.name,
            }),
            position: "top right",
          });
        }
        */
        Event.$emit("imageProgressBar", vm.files[index]);
        setTimeout(() => {
          if (
            vm.files.filter(
              (x) => x.status === "SUCCESS" || x.status === "ERROR"
            ).length === vm.files.length
          ) {
            setTimeout(() => {
              vm.$store.dispatch("showWidgetUploadProgressBar", false);
            }, 1000);
          }
        }, 300);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(uploadFileTest, data);
        } else {
          this.filesUploaded.error.push(data.id);
          if (index >= 0) {
            this.files[index].status = "ERROR";
            if (this.$refs.dlgUploadImages) {
              this.$refs.dlgUploadImages.updateStatus(this.files[index]);
            }
          }

          let progressValue = Math.ceil(
            (this.files.filter(
              (x) => x.status === "SUCCESS" || x.status === "ERROR"
            ).length *
              100) /
              this.files.length
          );
          if (this.$refs.dlgUploadImages) {
            this.$refs.dlgUploadImages.updateRemainingTime({
              progressValue: progressValue,
            });
          }

          let msg =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          vm.$toast.error({
            title: vm.$t("title_message_info"),
            message: msg
              ? msg
              : vm.$t("message_upload_file_error", null, {
                  name: data.file.name,
                }),
            position: "top right",
          });
        }
        Event.$emit("imageProgressBar", vm.files[index]);
        setTimeout(() => {
          if (
            vm.files.filter(
              (x) => x.status === "SUCCESS" || x.status === "ERROR"
            ).length === vm.files.length
          ) {
            setTimeout(() => {
              vm.$store.dispatch("showWidgetUploadProgressBar", false);
            }, 1000);
            if (
              this.filesUploaded.success.filter((x) => x != null).length > 0
            ) {
              this.onUpdateImagesOrder({
                image_ids: [
                  ...this.filesUploaded.success,
                  ...(this.dialogUploadFile.value.images
                    ? this.dialogUploadFile.value.images.map((x) => x.id)
                    : []),
                ],
                order_id: this.dialogUploadFile.value
                  ? this.dialogUploadFile.value.id
                  : null,
              });
            }
          }
        }, 300);
      }
    },
    onUpdateImagesAdded(items) {
      if (items && items.length > 0) {
        this.onUpdateImagesOrder({
          image_ids: [...new Set(items.map((x) => x.id))],
          order_id: this.dlgAddImages.value ? this.dlgAddImages.value.id : null,
        });
      }
    },
    async onUpdateImagesOrder(data) {
      try {
        let response = await orderFunc.updateImagesOrder(
          {
            image_ids: data.image_ids,
            order_id: data.order_id,
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data && response.data.success) {
            this.$toast.success({
              title: this.$t("title_message_info"),
              message: this.$t(
                "message_upload_images_order_tasking_success",
                null,
                {
                  name: this.dialogUploadFile.value
                    ? this.dialogUploadFile.value.order_code
                    : null,
                }
              ),
              position: "top right",
            });
            let indexCheck = this.order.items.findIndex(
              (x) =>
                x.id ===
                (this.dialogUploadFile.show && this.dialogUploadFile.value
                  ? this.dialogUploadFile.value.id
                  : this.dlgAddImages.show && this.dlgAddImages.value
                  ? this.dlgAddImages.value.id
                  : null)
            );
            if (indexCheck >= 0) {
              if (
                response.data &&
                response.data.data &&
                response.data.data.images
              ) {
                if (
                  this.order.items[indexCheck].images === null ||
                  this.order.items[indexCheck].images === undefined
                )
                  this.order.items[indexCheck].images = [];
                this.order.items[indexCheck].images = response.data.data.images;
              }
            }
            this.dialogUploadFile.value = null;
            this.files = [];
            this.filesUploaded.success = [];
            this.filesUploaded.error = [];
            this.dialogUploadFile.show = false;
            this.dlgAddImages.show = false;
            this.dlgAddImages.value = null;
          } else {
            this.message.push(
              this.$t("message_upload_images_order_tasking_error", null, {
                name: this.dialogUploadFile.value
                  ? this.dialogUploadFile.value.order_code
                  : null,
              })
            );
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.onUpdateImagesOrder, data);
        } else {
          let msg =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push(
            msg
              ? msg
              : this.$t("message_upload_images_order_tasking_error", null, {
                  name: this.dialogUploadFile.value
                    ? this.dialogUploadFile.value.order_code
                    : null,
                })
          );
        }
      }
    },
    async getMetadata(item) {
      var vm = this;
      try {
        item.read_metadata = "PROCESSING";
        if (this.$refs.dlgUploadImages) {
          this.$refs.dlgUploadImages.updateStatusMetadata(item);
        }
        let response = await fileFunc.getMetadata(
          this.$store.getters.getUserName + "/" + item.id,
          //"/" +
          //item.name +
          //(item.is_folder ? "/" : ""),
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data && response.data.success) {
            item.data_file =
              response.data && response.data.data ? response.data.data : null;
            item.loading_data = false;
            item.read_metadata = "SUCCESS";
            this.filesUploaded.success.push(
              response.data.data ? response.data.data.id : null
            );
            if (this.$refs.dlgUploadImages) {
              this.$refs.dlgUploadImages.updateStatusMetadata(item);
            }
          } else {
            item.loading_data = false;
            item.read_metadata = "ERROR";
            if (this.$refs.dlgUploadImages) {
              this.$refs.dlgUploadImages.updateStatusMetadata(item);
            }
            vm.$toast.error({
              title: vm.$t("title_message_info"),
              message: vm.$t("message_get_metadata_file_error", null, {
                name: item.name,
              }),
              position: "top right",
            });
          }
        } else {
          item.loading_data = false;
          item.read_metadata = "ERROR";
          if (this.$refs.dlgUploadImages) {
            this.$refs.dlgUploadImages.updateStatusMetadata(item);
          }
          vm.$toast.error({
            title: vm.$t("title_message_info"),
            message: vm.$t("message_get_metadata_file_error", null, {
              name: item.name,
            }),
            position: "top right",
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getMetadata, item);
        } else {
          item.loading_data = false;
          item.read_metadata = "ERROR";
          if (this.$refs.dlgUploadImages) {
            this.$refs.dlgUploadImages.updateStatusMetadata(item);
          }
          let msg =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          vm.$toast.error({
            title: vm.$t("title_message_info"),
            message: msg
              ? msg
              : vm.$t("message_get_metadata_file_error", null, {
                  name: item.name,
                }),
            position: "top right",
          });
        }
      }
      setTimeout(() => {
        if (
          this.files.filter(
            (x) => x.status === "SUCCESS" || x.status === "ERROR"
          ).length === this.files.length
        ) {
          if (
            this.filesUploaded.success.filter(
              (x) => x != null && x != undefined
            ).length > 0
          ) {
            this.onUpdateImagesOrder({
              image_ids: [
                ...this.filesUploaded.success,
                ...(this.dialogUploadFile.value.images
                  ? this.dialogUploadFile.value.images.map((x) => x.id)
                  : []),
              ],
              order_id: this.dialogUploadFile.value
                ? this.dialogUploadFile.value.id
                : null,
            });
          }
        }
      }, 300);
    },
    async showOrderDetailEgeo(item) {
      try {
        this.order.table.buttons.detail.disable = true;
        this.order.table.buttons.detail.processing = true;
        this.order.table.buttons.detail.id = item.id;
        let response = await orderFunc.getById(
          item.id,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success) {
            this.dlgOrderDetailEgeo.value = response.data.data
              ? response.data.data
              : {};
            this.dlgOrderDetailEgeo.show = true;
            let currentStatus = item.current_status;
            if (currentStatus && Object.keys(currentStatus).length > 0) {
              if (currentStatus.name === "ORDER_CREATED") {
                let index = this.orderStatus.items.findIndex(
                  (x) => x.name === "ORDER_PROCESSING"
                );
                if (index >= 0) {
                  this.onUpdateStatus(
                    {
                      status_id: this.orderStatus.items[index].id
                        ? this.orderStatus.items[index].id
                        : null,
                      remark: "..",
                    },
                    false,
                    item.id,
                    false
                  );
                  this.onSearch(this.order.search, false);
                }
              }
            }
          } else {
            this.message.push(this.$t("message_not_found_request"));
          }
        }

        this.order.table.buttons.detail.disable = false;
        this.order.table.buttons.detail.processing = false;
        this.order.table.buttons.detail.id = null;
      } catch (error) {
        this.order.table.buttons.detail.disable = false;
        this.order.table.buttons.detail.processing = false;
        this.order.table.buttons.detail.id = null;
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.showOrderDetailEgeo, item);
        } else {
          this.message.push(this.$t("message_not_found_request"));
        }
      }
    },

    async onUpdateStatus(
      entity,
      showMsg = true,
      orderId = null,
      isUpdateDialog = false,
      isLoadData = true
    ) {
      entity.remark = entity.remark ? entity.remark.trim() : null;
      if (!entity.status_id) {
        if (showMsg) this.message.push(this.$t("message_status_is_required"));
      }
      if (!entity.remark) {
        if (showMsg) {
          try {
            let itemStatus = this.orderStatus.items.find(
              (x) => x.id === entity.status_id
            );
            if (itemStatus && Object.keys(itemStatus).length > 0) {
              switch (itemStatus.name) {
                case "ORDER_REJECTED":
                  this.message.push(this.$t("message_reason_is_required"));
                  break;
                case "ORDER_PROCESSING":
                  this.message.push(this.$t("message_note_is_required"));
                  break;

                case "ORDER_COMPLETED":
                  this.message.push(this.$t("message_note_is_required"));
                  break;
                case "ORDER_CANCELED":
                  this.message.push(this.$t("message_reason_is_required"));
                  break;
                default:
                  this.message.push(this.$t("message_note_is_required"));
                  break;
              }
            } else {
              this.message.push(this.$t("message_note_is_required"));
            }
          } catch (error) {
            this.message.push(this.$t("message_note_is_required"));
          }
        }
      }
      if (this.message.length > 0) return;
      this.buttons.update_status.disable = true;
      this.buttons.update_status.processing = true;
      try {
        let response = await orderFunc.addStatus(
          {
            order_id: orderId ? orderId : this.dlgUpdateStatus.value.id,
            remark: entity.remark,
            status_id: entity.status_id,
          },
          this.$store.getters.getAccessToken
        );
        if (response.status === 200 || response.status === 201) {
          if (showMsg) {
            this.$toast.success({
              title: this.$t("title_message_info"),
              message: this.$t("message_update_order_status_success"),
              position: "top right",
            });
          }
          this.dlgUpdateStatus.value = null;
          this.dlgUpdateStatus.show = false;
          if (isLoadData) {
            this.order.items = [];
            this.order.page = 0;
            this.order.is_bottom = false;
            this.order.search.status_id = null;
            this.onSearch(this.order.search);
          }
          if (isUpdateDialog) {
            this.dlgOrderDetail.value.current_status = this.orderStatus.items.find(
              (x) => x.id === entity.status_id
            );
          }
        } else {
          if (showMsg)
            this.message.push(this.$t("message_update_order_status_error"));
        }
        this.buttons.update_status.disable = false;
        this.buttons.update_status.processing = false;
      } catch (error) {
        this.buttons.update_status.disable = false;
        this.buttons.update_status.processing = false;
        if (error.response && error.response.status === 401)
          await this.refreshToken(this.onUpdateStatus, entity);
        else {
          if (showMsg) {
            let msg =
              error.response &&
              error.response.data &&
              error.response.data.message
                ? error.response.data.message
                : null;
            this.message.push(
              msg ? msg : this.$t("message_update_order_status_error")
            );
          }
        }
      }
    },
    /*---------------- End emit ----------------*/
    /*---------------- Meta key ----------------*/
    getOrderStatusByLang() {
      this.orderStatus.items = [];
      for (let i = 0; i < this.orderStatus.data.length; i++) {
        if (
          this.orderStatus.data[i].translation &&
          this.orderStatus.data[i].translation[this.lang] &&
          Object.keys(this.orderStatus.data[i].translation[this.lang]).length >
            0
        ) {
          this.orderStatus.items.push({
            id: this.orderStatus.data[i].id,
            key: this.orderStatus.data[i].key,
            name: this.orderStatus.data[i].name,
            value: this.orderStatus.data[i].translation[this.lang].value
              ? this.orderStatus.data[i].translation[this.lang].value
              : this.orderStatus.data[i].value,
            description: this.orderStatus.data[i].translation[this.lang]
              .description
              ? this.orderStatus.data[i].translation[this.lang].description
              : this.orderStatus.data[i].description,
          });
        } else {
          this.orderStatus.items.push({
            id: this.orderStatus.data[i].id,
            key: this.orderStatus.data[i].key,
            name: this.orderStatus.data[i].name,
            value: this.orderStatus.data[i].value,
            description: this.orderStatus.data[i].description,
          });
        }
      }
    },
    /*---------------- End metakey ------------------*/
    async getAllOrder(loading) {
      if (this.order.is_bottom) return;
      if (loading)
        this.$store.dispatch("setSpinnerApp", {
          show: true,
        });
      this.buttons.filter.disabled = true;
      this.buttons.filter.processing = true;
      try {
        let response = await orderFunc.getAllPage(
          {
            create_from: this.order.search.create_from
              ? new Date(this.order.search.create_from)
              : null,
            create_to: this.order.search.create_to
              ? new Date(this.order.search.create_to)
              : null,
            page: this.order.search.page,
            size: this.order.search.size,
            status_id: this.order.search.status_id
              ? this.order.search.status_id
              : null,
            user_id: this.order.search.user_id
              ? this.order.search.user_id
              : null,
            is_order_request: true,
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.order.items = [
            ...this.order.items,
            ...response.data.content_page,
          ];
          this.order.page_number = response.data.page_number;
          this.order.page_size = response.data.page_size;
          this.order.total_page = response.data.total_page;
          this.order.total_records = response.data.total_records;
          if (
            (this.order.items.length === this.order.total_records &&
              this.order.total_page > 1) ||
            (response.data.total_page === 1 &&
              this.order.items.length <= response.data.page_size)
          )
            this.order.is_bottom = true;
        }
        this.buttons.filter.disabled = false;
        this.buttons.filter.processing = false;
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
      } catch (error) {
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
        this.buttons.filter.disabled = false;
        this.buttons.filter.processing = false;
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.getAllOrder);
        }
      }
    },
    async getAllCustomer() {
      try {
        let response = await userFunc.search(
          {
            page: 0,
            size: 2147483647,
            order: "full_name",
            sort: "asc",
          },
          {
            is_active: null,
            role_id: "ROLE_CUSTOMER",
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.user.items = response.data.content_page;
        }
      } catch (error) {
        if (error.response && error.response.status === 401)
          await this.refreshToken(this.getAllCustomer);
      }
    },
    async getAllOrderStatus() {
      try {
        let response = await metaKeyFunc.getByKey(
          "ORDER_STATUS",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.orderStatus.data =
            response.data && response.data.data ? response.data.data : [];
          this.getOrderStatusByLang();
        }
      } catch (error) {
        if (error.response && error.response.status === 401)
          this.refreshToken(this.getAllOrderStatus);
      }
    },

    async getAllTaskingGranularity() {
      try {
        let response = await metaKeyFunc.getByKey(
          "GRANULARITY",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.granularity.items = response.data.data ? response.data.data : [];
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllTaskingGranularity);
        }
      }
    },

    async getAllTaskingProgrammingService() {
      try {
        let response = await metaKeyFunc.getByKey(
          "PROGRAMMING_SERVICE",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.programingService.items = response.data.data
            ? response.data.data
            : [];
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllTaskingProgrammingService);
        }
      }
    },

    async getAllDirection() {
      try {
        let response = await metaKeyFunc.getByKey(
          "DIRECTION",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          let data =
            response.data && response.data.data ? response.data.data : [];
          this.lookDirection.items = data.filter(
            (x) => x.name != "ASCENDING" && x.name != "DESCENDING"
          );
          this.orbitDirection.items = data.filter(
            (x) => x.name != "LEFT" && x.name != "RIGHT"
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllDirection);
        }
      }
    },

    async getAllSatelliteType() {
      try {
        let response = await metaKeyFunc.getByKey(
          "IMAGE_TYPES",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.satelliteType.items =
            response.data && response.data.data ? response.data.data : [];
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllSatelliteType);
        }
      }
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          this.$router.push({
            name: "Login",
            query: {
              next: btoa(this.$route.path),
            },
          });
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
};
</script>

<style lang="scss" scoped>
.order-content {
  padding: 0.5rem;
  .order__search {
    padding: 0;
  }
  .order__datatable {
    padding: 0;
    padding-top: 0.5rem;
  }
}
</style>

<style lang="scss" scoped>
.order-content {
  .order__search {
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .order__datatable {
    .accordion {
      border-radius: 0.5rem;
    }
  }
}
</style>
