<template>
  <main class="page-content" id="J9hGshgaw2">
    <div
      id="pageContentHeader"
      class="page-content__header"
      :style="[
        isSidebar
          ? { width: 'calc(100% - 261px)', left: 260 + 'px' }
          : { width: 100 + '%', left: 0 },
        !isBreadcrumb && !isToolbar ? { paddingBottom: 0 } : {},
      ]"
    >
      <div class="page-content__header-menubar" v-if="isBreadcrumb">
        <div class="breadcrumb" v-if="isBreadcrumb">
          <Breadcrumb :home="home" :model="breadcrumbitems" />
        </div>
        <div class="topbar-menu">
          <div class="actions d-flex">
            <Button
              :icon="
                propButtonsState.reload && propButtonsState.reload.processing
                  ? 'pi pi-spinner pi-spin'
                  : 'pi pi-refresh'
              "
              class="p-button-success p-button-rounded p-button-sm"
              @click="onReload"
              :title="$t('title_refresh')"
              :disabled="propButtonsState.reload.disabled"
              v-if="propButtonsState.reload && propButtonsState.reload.visible"
            />
          </div>
          <div class="dropdown-language mr-2">
            <Dropdown
              class="w-100"
              v-model="language"
              :showClear="false"
              :options="arrayLanguage"
              optionLabel="label"
              dataKey="id"
            >
              <template #value="slotProps">
                <div
                  class="country-item country-item-value"
                  v-if="slotProps.value"
                >
                  <img
                    style="margin-bottom: 1px"
                    :src="
                      slotProps.value.code === 'en'
                        ? '/img/icons/united-kingdom16x16.png'
                        : '/img/icons/vietnam16x16.png'
                    "
                  />
                  {{ slotProps.value.label }}
                </div>
              </template>
              <template #option="slotProps">
                <div>
                  <img
                    :src="
                      slotProps.option.code === 'en'
                        ? '/img/icons/united-kingdom16x16.png'
                        : '/img/icons/vietnam16x16.png'
                    "
                    :class="'flag flag-' + slotProps.option.code.toLowerCase()"
                  />
                  {{ slotProps.option.label }}
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="dropdown">
            <a
              class="dropdown-toggle dropdown-info"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="true"
            >
              <span class="nav-label"
                ><span>{{
                  userMe && userMe.full_name ? userMe.full_name : ""
                }}</span>
                <img
                  class="avatar img-rounded ml-2"
                  src="/img/user.jpg"
                  alt="User picture"
              /></span>

              <span class="caret"></span
            ></a>
            <div class="dropdown-menu" aria-labelledby="dropdownInfo">
              <a class="dropdown-item" @click="itemClick('USER_INFO')"
                ><i class="fas fa-info-circle">&nbsp;</i
                >{{ this.$t("label_account_info") }}</a
              >
              <a class="dropdown-item" @click="itemClick('CHANGE_PASSWORD')"
                ><i class="fas fa-unlock-alt">&nbsp;</i
                >{{ this.$t("button_text_changepass") }}</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="page-content__header-toolbar" v-if="isToolbar">
        <div class="actions d-flex">
          <Button
            :icon="
              propButtonsState.delete && propButtonsState.delete.processing
                ? 'pi pi-spinner pi-spin'
                : 'pi pi-trash'
            "
            class="p-button-danger p-button-sm"
            @click="onDelete"
            :title="$t('title_delete')"
            :disabled="propButtonsState.delete.disabled"
            v-if="propButtonsState.delete && propButtonsState.delete.visible"
            :label="$t('title_delete')"
          />
          <Button
            :icon="
              propButtonsState.save && propButtonsState.save.processing
                ? 'fa fa-spinner fa-spin'
                : 'pi pi-save'
            "
            class="p-button-success p-button-sm"
            @click="onSave"
            :title="$t('title_save')"
            :disabled="propButtonsState.save.disabled"
            v-if="propButtonsState.save.visible"
            :label="$t('title_save')"
          />
          <Button
            v-if="propButtonsState.cancel && propButtonsState.cancel.visible"
            :icon="
              propButtonsState.cancel && propButtonsState.cancel.processing
                ? 'fa fa-spinner fa-spin'
                : 'pi pi-times'
            "
            class="p-button-danger p-button-sm"
            @click="onCancel"
            :title="$t('title_cancel')"
            :disabled="
              propButtonsState.cancel && propButtonsState.cancel.disabled
            "
            :label="$t('title_cancel')"
          />
          <Button
            icon="pi pi-plus"
            class="p-button-primary p-button-sm"
            @click="onAdd"
            :title="$t('title_add')"
            :disabled="
              (propButtonsState.add && propButtonsState.add.disabled) ||
              (propButtonsState.save && propButtonsState.save.processing)
            "
            v-if="propButtonsState.add.visible"
            :label="$t('title_add')"
          />
          <Button
            class="p-button-info p-button-sm"
            @click="onEdit"
            :title="$t('title_edit')"
            :icon="
              propButtonsState.reload && propButtonsState.edit.processing
                ? 'fa fa-spinner fa-spin'
                : 'pi pi-pencil'
            "
            :disabled="
              (propButtonsState.edit && propButtonsState.edit.disabled) ||
              (propButtonsState.save && propButtonsState.save.processing)
            "
            v-if="propButtonsState.edit.visible"
            :label="$t('title_edit')"
          />
        </div>
      </div>
    </div>
    <div class="dialog-change-password">
      <DialogChangePass
        v-if="dialog.changePass.show"
        :prop-show="dialog.changePass.show"
        @close="dialog.changePass.show = false"
        @message="message = $event"
      />
    </div>
    <DialogMessage
      v-if="message.length > 0"
      :propTitle="$t('title_message_info')"
      :prop-show="message.length > 0"
      :prop-message="message"
      @close="message = []"
    />
    <div
      class="page-content__body"
      :style="{ paddingTop: contentBodyPaddingTop }"
    >
      <slot name="default"></slot>
    </div>
  </main>
</template>
<script>
import Breadcrumb from "@/components/commons/breadcrumb/Breadcrumb";
import Button from "@/components/commons/button/Button";
import DialogChangePass from "@/components/commons/changepass/ChangePass";
import DialogMessage from "@/components/commons/dialog/MessageBox";
import Dropdown from "@/components/commons/dropdown/Dropdown";
export default {
  components: {
    Breadcrumb,
    Button,
    DialogChangePass,
    DialogMessage,
    Dropdown,
  },
  props: {
    isBreadcrumb: {
      type: Boolean,
      default: true,
    },
    isToolbar: {
      type: Boolean,
      default: true,
    },
    breadcrumbitems: {
      type: Array,
      default: () => [],
    },
    propButtonsState: {
      type: Object,
      default: () => {
        return {
          delete: {
            disabled: false,
            processing: false,
            visible: false,
          },
          save: {
            disabled: false,
            processing: false,
            visible: true,
          },
          cancel: {
            disabled: false,
            processing: false,
            visible: false,
          },
          add: {
            disabled: false,
            processing: false,
            visible: true,
          },
          edit: {
            disabled: false,
            processing: false,
            visible: true,
          },
          reload: {
            disabled: false,
            processing: false,
            visible: true,
          },
          print: {
            show: false,
            disabled: false,
            processing: false,
            visible: false,
          },
          search: {
            disabled: false,
            processing: false,
            visible: false,
          },
        };
      },
    },
    propTitle: {
      type: String,
      default: "",
    },
    propTextSearch: {
      type: String,
      default: "",
    },
    propDescription: {
      type: Object,
      default: () => {
        return {
          text: "",
        };
      },
    },
    propMenusVisible: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      message: [],
      language: null,
      home: { icon: "fas fa-home", to: "/" },
      arrayLanguage: [
        {
          code: "en",
          label: "English",
          icon: "",
          id: 1,
        },
        {
          code: "vi",
          label: "Vietnamese",
          icon: "",
          id: 2,
        },
      ],
      dialog: {
        changePass: {
          show: false,
        },
      },
    };
  },
  created() {
    if (this.lang) {
      if (this.arrayLanguage.findIndex((x) => x.code === this.lang) >= 0)
        this.language = this.arrayLanguage.find((x) => x.code === this.lang);
      else {
        this.language = this.arrayLanguage.find((x) => x.code === "en");
      }
    } else {
      this.language = this.arrayLanguage.find((x) => x.code === this.lang);
    }
  },
  watch: {
    $route: {
      handler(val) {},
      deep: true,
    },
    language(val) {
      localStorage.setItem("language", val.code);
      this.$store.dispatch("setLang", val.code);
    },
  },
  methods: {
    clearTextSearch() {
      this.textSearch = "";
      this.strSearchChange("");
    },
    itemClick(code) {
      switch (code) {
        case "CHANGE_PASSWORD":
          this.dialog.changePass.show = true;
          break;
        case "USER_INFO":
          if (this.$route.path != "/me") {
            this.$router.push({
              path: "/me",
            });
          }
          break;
      }
    },
    onSearch() {
      this.$emit("onSearch", this.textSearch);
    },
    onDelete() {
      this.$emit("emitFunction", {
        code: "DELETE",
      });
    },
    onSave() {
      this.$emit("emitFunction", {
        code: "SAVE",
      });
    },
    onCancel() {
      this.$emit("emitFunction", {
        code: "CANCEL",
      });
    },
    onAdd() {
      this.$emit("emitFunction", {
        code: "ADD",
      });
    },
    onEdit() {
      this.$emit("emitFunction", {
        code: "EDIT",
      });
    },
    onFilter() {
      this.$emit("emitFunction", {
        code: "FILTER",
      });
    },
    onReload() {
      this.$emit("emitFunction", {
        code: "RELOAD",
      });
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },

    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },

  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    isSidebar() {
      return this.$store.getters.getIsSidebar;
    },
    contentBodyPaddingTop() {
      let result = null;
      if (this.isBreadcrumb && this.isToolbar) {
        result = 6.5; //"6.5rem";
      } else if (!this.isBreadcrumb && this.isToolbar) {
        result = 3; // "3rem";
      } else if (this.isBreadcrumb && !this.isToolbar) {
        result = 3.5; // "3.5rem";
      } else result = 6.5; //"6.5rem";
      if (this.documentWidth < 768) result = result + 2.375;
      return result + "rem";
    },
    lang() {
      return this.$store.getters.getLang;
    },
    userMe() {
      return this.$store.getters.getUser;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.page-content__body {
  min-height: 100vh;
  background: #f3f6f8;
}

.breadcrumb {
  background: white;
  margin: 0;
  padding: 0;
  width: 100%;
  .p-breadcrumb {
    border: 0;
    margin: 0;
    padding: 1rem 0.5rem;
    line-height: 22px;
    vertical-align: middle;
    .p-menuitem-text {
      font-size: 14px !important;
    }
  }
}
.page-content__header {
  position: fixed;
  top: 0;
  background: white;
  z-index: 99;
}
.page-content__title {
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
  font-weight: 300;
  font-size: 1.5rem;
  padding: 0.75rem 0.75rem;
  text-transform: uppercase;
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .page-wrapper.toggled .page-content {
    padding-left: 260px;
  }
}
.page-wrapper .page-content {
  display: inline-block;
  width: 100%;
}
#show-sidebar {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-content__header-menubar {
  background: white;
  border-bottom: 1px solid white;

  display: flex;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
  h6.title {
    color: $background-color;
    font-size: 14px;
    vertical-align: middle;
    margin: 0;
    padding: 0 0.75rem;
  }

  .actions {
    padding: 0 0.5rem;
    line-height: 2px;
    button {
      margin-right: 0.5rem;
      padding: 0.5rem;
      &:last-child {
        margin: 0;
      }
    }
  }
  .dropdown-language {
    width: auto;
    background-color: #eef3f7;
    padding: 0.075rem 0.375rem;
    border-radius: 20px;
  }
  .dropdown {
    background-color: #eef3f7;
    padding: 0.075rem 0.375rem;
    border-radius: 20px;
    a {
      &.dropdown-info {
        text-align: center;
        vertical-align: text-top;
        &:hover {
          cursor: pointer;
        }
        .nav-label {
          font-weight: 500;
          padding-top: 2px;
        }
      }
    }
    .dropdown-menu {
      .dropdown-item {
        font-size: 14px;
        padding: 0.375rem 1rem;
        &:hover {
          cursor: pointer;
        }
        &:active {
          color: white;
        }
      }
    }
  }
  img {
    &.img-rounded {
      border-radius: 50%;
    }
    &.avatar {
      width: 32px;
      height: 32px;
    }
  }
}
.Kh8sdf2bs {
  display: flex;
  .breadcrumb {
    width: calc(100% - 7rem);
  }
  .dropdown-language {
    width: 7rem;
    margin: auto 0;
    select {
      width: calc(100% - 0.5rem);
    }
  }
}
.page-content__header-toolbar {
  background: #f3f6f8;
  padding: 0.5rem 0;
  .actions {
    padding: 0 0.5rem;
    line-height: 2px;
    button {
      margin-right: 0.5rem;
      padding: 0.5rem;
      width: 5.5rem;
      &:last-child {
        margin: 0;
      }
    }
  }
}
.topbar-menu {
  display: flex;
  float: right;
  margin-right: 2px;
}
@media (max-width: 767.98px) {
  .page-content__header-menubar {
    display: flex;
    flex-direction: column;
    .topbar-menu {
      width: 100%;
    }
  }
}
</style>
<style lang="scss">
.page-content__header-menubar {
  .dropdown-language {
    .p-dropdown {
      border: transparent;
      background: transparent;
      &:active {
        outline: 0;
        border: 0;
        box-shadow: none;
      }
      &:hover {
        outline: 0;
        border: 0;
        box-shadow: none;
      }
      .p-dropdown-items-wrapper {
        width: 9rem;
        li {
          padding: 0.375rem 1rem;
        }
      }
      .p-dropdown-label {
        padding-right: 0.25rem;
      }
    }
    .p-dropdown-trigger {
      display: none;
    }
  }
  .p-button-rounded {
    width: 2.375rem !important;
  }
}
.dialog-change-password {
  .p-dialog-content {
    border-radius: 0.5rem;
  }
}
</style>
