<template>
  <div slot="slot-content" class="category-page">
    <spinner :propLoading="loading.show" :propShape="loading.shape" v-if="loading.show"  :prop-text="loading.text"></spinner>
    <CategoryPage/>
  </div>
</template>
<script>
import Spinner from "@/components/commons/spinners/Index";
import CategoryPage from "@/components/pages/category/Category"
export default {
  name: 'category',
  components: {
    CategoryPage,Spinner
  },
  created(){},
  data(){
    return{}
  },
  computed:{
    loading(){
      return this.$store.getters.getSpinnerApp
    }
  }
}
</script>
